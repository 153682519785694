import { genericService, get, put, remove } from '../../../helpers/api';

export const SERVICE_URI = '/chat';
export const BASE_URI = '/api/v1/chat';

export const { findAll: findAllChats } = genericService(SERVICE_URI, BASE_URI);

export const registerConnection = (targetId) =>
  put(`${SERVICE_URI}${BASE_URI}/${targetId}`);

export const removeConnection = (targetId) =>
  remove(`${SERVICE_URI}${BASE_URI}/${targetId}`);

export const checkStatus = (users) =>
  get(`${SERVICE_URI}${BASE_URI}/check/${users}`);
