import React, { useEffect, useState } from 'react';
import { Alert, Button, notification } from 'antd';
import {
  ArrowDownOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  MenuOutlined,
  ReloadOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import Icon from '../../Icon';

const ChatHeader = ({
  isConnected = true,
  chatReconnect = () => {},
  reloadChats = () => {},
  menu,
  handleMenu,
  totalMessages,
  onClose,
  handleRightChatBtn,
  rightSideChat,
  handleRightSideSmallChat,
  rightSideSmallChat,
  userInfoSide,
  handleMinify,
}) => {
  const [connected, setConnected] = useState(isConnected);

  useEffect(() => {
    setConnected(isConnected);
  }, [isConnected]);

  const onConnect = () => {
    notification.success({
      message: 'Conexiune reușită',
      description: 'Chatul a fost conectat cu succes.',
    });
    setConnected(true);
    reloadChats();
  };

  const onFailReconnect = () => {
    notification.error({
      message: 'Conexiune eșuată',
      description:
        'Chatul nu a putut fi reconectat. Vă rugăm să încercați din nou mai târziu.',
    });
  };

  const handleReconnect = () => {
    chatReconnect(onConnect, onFailReconnect);
  };
  const renderAlertContent = () => {
    return (
      <>
        <WarningOutlined style={{ color: 'red', marginRight: '8px' }} />
        <span style={{ marginRight: '8px' }}>
          Chatul nu este conectat. Apasă butonul pentru a te reconecta.
        </span>
        <Button
          type="primary"
          onClick={handleReconnect}
          icon={<ReloadOutlined />}
          style={{ marginRight: 'auto' }}
        >
          Reconectează-te
        </Button>
      </>
    );
  };

  return (
    <header className="chat__header">
      {!connected ? (
        <Alert
          className="chat__connection-alert"
          type="warning"
          message={renderAlertContent()}
        />
      ) : (
        <>
          <Button
            icon={menu ? <ArrowLeftOutlined /> : <MenuOutlined />}
            type="primary"
            className="chat__menu-btn"
            onClick={handleMenu}
          />

          <Button
            icon={
              rightSideSmallChat ? (
                <ArrowLeftOutlined />
              ) : (
                <ArrowRightOutlined />
              )
            }
            type="primary"
            className={`chat__right-side ${rightSideChat ? 'right' : ''}`}
            onClick={
              rightSideChat ? handleRightSideSmallChat : handleRightChatBtn
            }
          />

          {totalMessages > 0 ? (
            <div className="chat__total-message--container">
              <div className="chat__total-messages">{totalMessages}</div>
              <div
                className={`chat__total-background ${menu ? 'active' : ''}`}
              />
            </div>
          ) : null}
          <Button
            icon={<ArrowDownOutlined />}
            type="primary"
            className="chat__minify"
            onClick={handleMinify}
          />
        </>
      )}

      <button
        type="submit"
        className={`chat__close ${userInfoSide ? 'active' : ''}`}
        onClick={onClose}
      >
        <Icon
          name="close"
          className="calculator__close-icon"
          width="15"
          height="15"
        />
      </button>

      <ul className="exchange__drag" />
    </header>
  );
};

export default ChatHeader;
