import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import RiskPersonsList from '../../../../components/admin/taxonomies/riskPersons/RiskPersonsList';

const RiskPersons = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="riskPersons">
      <Tabs.TabPane
        tab={t('entity.admin.riskPersons._plural')}
        key="riskPersons"
      >
        <RiskPersonsList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default RiskPersons;
