import React from 'react';

import { useEditPage } from '../../../hooks/useEditPage';

import {
  findOne,
  create,
  update,
} from '../../../services/admin/users/customs-office';
import EditCustomsOfficeForm from '../../../components/admin/customsOffices/EditCustomsOfficeForm';
import AppLoader from '../../../components/auth/AppLoader';

const initial = {};

const EditCustomsOffice = () => {
  const [post, handleSubmit, handleCancel, errors, loading] = useEditPage({
    initial,
    existent: findOne,
    goBackPath: '/admin/customs-posts/offices',
    onCreate: create,
    onUpdate: update,
  });

  return (
    <>
      <div className={`loaderOverlay ${loading ? 'active' : ''}`}>
        <AppLoader />
      </div>
      {post !== undefined && (
        <EditCustomsOfficeForm
          office={post}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          errors={errors}
        />
      )}
    </>
  );
};

export default EditCustomsOffice;
