import React, { useContext } from 'react';
import { Row, Col, Divider } from 'antd';
import AuthContext, { hasPermission } from '../auth';
import ActDeControlParametersModal from '../admin/modals/ActDeControlParametersModal';
import BonDePlataParametersModal from '../admin/modals/BonDePlataParametersModal';
import ReturModal from '../admin/modals/ReturModal';
import SesizariModal from '../admin/modals/SesizariModal';
import FraudReportModal from '../admin/modals/FraudReportModal';
import IrregularitiesModal from '../admin/modals/IrregularitiesModal';

const ControlButtons = ({
  vehicleNumber,
  readOnly,
  customsAttributesTransaction,
  transactionId,
  verificationMethod,
  verificationParameters,
  receiptMethod,
  receiptParameters,
  isWeighingAmount,
  isAntaAmount,
  isAnsaAmount,
  returModalParameters,
  sesizariModalParameters,
  fraudReportParameters,
  irregularities,
  reload,
}) => {
  const { user } = useContext(AuthContext);
  const { permissions } = user;

  return transactionId ? (
    <Col className="controll__buttons-container">
      <Divider>Acțiuni</Divider>
      <Row justify="start" style={{ gap: '10px' }}>
        <Col xl={6} xxl={2} style={{ maxWidth: '100%' }}>
          <ActDeControlParametersModal
            readOnly={readOnly}
            parameters={{
              transactionId: `${transactionId}`,
              userFullname: user.fullname || '',
              customsPost: user?.customsPost || {},
              customsRegimeId: verificationParameters.customsRegimeId,
            }}
            modalTitle={verificationParameters.parametersModalTitle}
            buttonCaption={verificationParameters.buttonCaption}
            submitMethod={verificationMethod}
            inputsBasicDetails={verificationParameters.parametersTypes}
          />
        </Col>
        {(user.customsPost?.hasAnta ||
          user.customsPost?.hasAnsa ||
          user.customsPost?.hasScale) && (
          <Col xl={6} xxl={2} style={{ maxWidth: '100%' }}>
            <BonDePlataParametersModal
              readOnly={readOnly}
              parameters={{
                transactionId: `${transactionId}`,
                hasScale:
                  customsAttributesTransaction?.find(
                    (el) => el === 'HAS_SCALE_AMOUNT',
                  ) !== undefined || isWeighingAmount,
                hasAnta:
                  customsAttributesTransaction?.find(
                    (el) => el === 'HAS_ANTA_AMOUNT',
                  ) !== undefined || isAntaAmount,
                hasAnsa:
                  customsAttributesTransaction?.find(
                    (el) => el === 'HAS_ANSA_AMOUNT',
                  ) !== undefined || isAnsaAmount,
                postCode: user?.customsPost?.code,
              }}
              modalTitle={receiptParameters.parametersModalTitle}
              buttonCaption={receiptParameters.buttonCaption}
              submitMethod={receiptMethod}
              inputsBasicDetails={receiptParameters.parametersTypes}
              reload={reload}
            />
          </Col>
        )}
        {hasPermission(permissions, 'ADD_IRREGULARITIES') && (
          <Col xl={6} xxl={2} style={{ maxWidth: '100%' }}>
            <IrregularitiesModal
              transactionId={transactionId}
              initialValue={irregularities}
              readOnly={readOnly}
              reload={reload}
            />
          </Col>
        )}

        {hasPermission(permissions, 'ADD_IRREGULARITIES') && (
          <Col xl={6} xxl={2} style={{ maxWidth: '100%' }}>
            <SesizariModal
              key="sesizariParameters"
              readOnly={readOnly}
              transactionId={transactionId}
              sesizariData={sesizariModalParameters?.sesizariData}
              person={sesizariModalParameters?.person}
            />
          </Col>
        )}
        <Col xl={6} xxl={2} style={{ maxWidth: '100%' }}>
          <FraudReportModal
            vehicleNumber={vehicleNumber}
            transactionId={transactionId}
            parameters={fraudReportParameters}
            readOnly={readOnly}
            reload={reload}
          />
        </Col>

        <Col xl={6} xxl={2} style={{ maxWidth: '100%' }}>
          <ReturModal
            key="returParameters"
            readOnly={readOnly}
            transactionId={transactionId}
            returnData={returModalParameters.returnData}
          />
        </Col>
      </Row>
    </Col>
  ) : (
    <></>
  );
};

export default ControlButtons;
