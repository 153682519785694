import React, { useCallback, useState, useEffect } from 'react';
import { Col, Row, Form, Input, Modal, notification } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import useDatasource from '../../../../hooks/useDatasource';
import {
  remove as removeMortuary,
  update as updateMortuary,
  create as createMortuary,
  findAll as findAllMortuaries,
} from '../../../../services/taxonomies/mortuary';
import CountrySelect from '../../../CountrySelect';
import MortuaryListTable from './MortuaryListTable';
import { findAll as findAllCountries } from '../../../../services/taxonomies/countries';
import useDictionaries from '../../../../hooks/useDictionaries';
import RequiredLabel from '../../../../services/utils/RequiredLabel';

const dictionaries = {
  countries: findAllCountries,
};

const MortuaryList = ({ transactionId }) => {
  const [formMortuary] = Form.useForm();
  const [loadingMortuaries, setLoadingMortuaries] = useState(false);
  const [isMortuaryModalVisible, setMortuaryModalVisible] = useState(false);

  const handler = useCallback(
    (...params) => {
      const spec = params[0];
      spec.sort = ['id', 'desc'];
      spec.criterias = transactionId
        ? { transactionId }
        : { transactionId: -1 };

      return findAllMortuaries(spec);
    },
    [transactionId],
  );
  const { loading, content, reload, handleChange } = useDatasource(handler);
  const [{ countries }] = useDictionaries(dictionaries);

  useEffect(() => {
    setLoadingMortuaries(loading);
  }, [loading]);

  const handleModalOk = useCallback(async () => {
    if (!formMortuary.getFieldValue('mortuaryFullname')) {
      notification.warning({
        message: 'Numele, Prenumele este obligatoriu',
      });
    } else if (!formMortuary.getFieldValue('mortuaryFromCountryId')) {
      notification.warning({
        message: 'Țara de plecare este obligatorie',
      });
    } else if (!formMortuary.getFieldValue('mortuaryToCountryId')) {
      notification.warning({
        message: 'Țara destinație este obligatorie',
      });
    } else {
      try {
        await formMortuary.validateFields();

        const currentId = formMortuary.getFieldValue('mortuaryId');

        const data = {
          transactionId,
          fullname: formMortuary.getFieldValue('mortuaryFullname'),
          personalCode: formMortuary.getFieldValue('mortuaryPersonalCode'),
          fromCountryId: formMortuary.getFieldValue('mortuaryFromCountryId'),
          toCountryId: formMortuary.getFieldValue('mortuaryToCountryId'),
          description: formMortuary.getFieldValue('mortuaryDescription'),
        };

        setLoadingMortuaries(true);

        if (currentId === undefined || currentId === null) {
          await createMortuary(data);
        } else {
          await updateMortuary({
            id: currentId,
            ...data,
          });
        }

        setLoadingMortuaries(false);
        reload();
        setMortuaryModalVisible(false);
      } catch (error) {
        notification.error({ message: 'Eroare la salvarea datelor' });
      }
    }
  }, [transactionId, formMortuary, reload]);

  const handleEditModal = (record) => {
    if (record) {
      formMortuary.setFieldsValue({
        mortuaryId: record.id,
        mortuaryFullname: record.fullname,
        mortuaryPersonalCode: record.personalCode,
        mortuaryFromCountryId: record.fromCountryId,
        mortuaryToCountryId: record.toCountryId,
        mortuaryDescription: record.description,
      });
    } else {
      formMortuary.resetFields();
    }
  };

  return (
    <>
      <Form form={formMortuary}>
        <Modal
          maskClosable={false}
          centered
          width={600}
          title="Adăugare mortuar"
          open={isMortuaryModalVisible}
          onOk={handleModalOk}
          onCancel={() => setMortuaryModalVisible(false)}
          cancelText="Înapoi"
        >
          <Row gutter={15}>
            <Col span={12}>
              <Form.Item
                name="mortuaryFullname"
                label={<RequiredLabel title="Nume, Prenume" />}
                labelCol={{ span: 24 }}
              >
                <Input
                  style={{
                    textTransform: 'uppercase',
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="mortuaryPersonalCode"
                label="Nr. Pașaport"
                labelCol={{ span: 24 }}
              >
                <Input
                  style={{
                    textTransform: 'uppercase',
                  }}
                  maxLength={17}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="mortuaryFromCountryId"
                label={<RequiredLabel title="Țara plecare" />}
                labelCol={{ span: 24 }}
              >
                <CountrySelect id="mortuaryFromCountryId" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="mortuaryToCountryId"
                label={<RequiredLabel title="Țara destinație" />}
                labelCol={{ span: 24 }}
              >
                <CountrySelect id="mortuaryToCountryId" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="mortuaryDescription"
                label="Descriere"
                labelCol={{ span: 24 }}
              >
                <TextArea showCount maxLength={2000} rows={3} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item name="mortuaryId" noStyle />
        </Modal>
      </Form>

      <MortuaryListTable
        handleChange={handleChange}
        content={content}
        countries={countries}
        handleModalVisible={setMortuaryModalVisible}
        loadingMortuaries={loadingMortuaries}
        removeMortuary={removeMortuary}
        transactionId={transactionId}
        reload={reload}
        handleEditModal={handleEditModal}
      />
    </>
  );
};
export default MortuaryList;
