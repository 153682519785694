import { genericService, postWithRawResponse, put } from '../../../helpers/api';

export const SERVICE_URI = '/backend/api';
export const BASE_URI = '/v1/report-files';

export const { findAll, remove, findOne } = genericService(
  SERVICE_URI,
  BASE_URI,
);

export const changeReportActiveStatus = (id, parameters) =>
  put(`${SERVICE_URI}${BASE_URI}/active-status/${id}`, parameters);

export const openPdfTemplateNewTab = async (fileName, path) => {
  const response = await postWithRawResponse(
    `${SERVICE_URI}${BASE_URI}${path}`,
    {
      headers: {
        Accept: 'application/octet-stream',
      },
    },
  );
  const responseBlob = await response.blob();
  const blob = new Blob([responseBlob], {
    type: 'application/pdf',
  });
  const objectUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = objectUrl;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  window.open(window.URL.createObjectURL(blob), fileName);
  return responseBlob;
};
