import React from 'react';

import { useEditPage } from '../../../../hooks/useEditPage';

import {
  findOne,
  create,
  update,
} from '../../../../services/taxonomies/control-reasons';
import EditControlReasonForm from '../../../../components/admin/taxonomies/controlReasons/EditControlReasonForm';
import AppLoader from '../../../../components/auth/AppLoader';

const initial = {};

const EditControlReason = () => {
  const [post, handleSubmit, handleCancel, errors, loading] = useEditPage({
    initial,
    existent: findOne,
    goBackPath: '/admin/taxonomies/control-reason',
    onCreate: create,
    onUpdate: update,
  });

  return (
    <>
      <div className={`loaderOverlay ${loading ? 'active' : ''}`}>
        <AppLoader />
      </div>
      {post !== undefined && (
        <EditControlReasonForm
          post={post}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          errors={errors}
        />
      )}
    </>
  );
};

export default EditControlReason;
