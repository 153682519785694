import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'antd';
import { RollbackOutlined } from '@ant-design/icons';

const BackButton = ({ onCancel, style }) => {
  const { t } = useTranslation();

  return (
    <Button style={style} icon={<RollbackOutlined />} onClick={onCancel}>
      {t('actions.back')}
    </Button>
  );
};

export default BackButton;
