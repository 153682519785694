import React, { useEffect, useState } from 'react';
import { Button, Image } from 'antd';
import ImageUploader from './Chat/components/ImageUploader';
import Icon from './Icon';
import { getBlobFile } from '../services/admin/files';

const AvatarComponent = ({
  imageId,
  base64,
  src,
  removable = false,
  onUpload = () => {},
  onClear = () => {},
  size,
  preview = false,
  radius = '50%',
  styles,
  onClick = false,
  width,
  height,
}) => {
  const [imageUrl, setImageUrl] = useState(null);

  const clearImage = () => {
    onClear();
    setImageUrl(null);
  };

  useEffect(() => {
    let imageUrlRef = null;
    if (base64 && !src) {
      try {
        const arrayBuffer = Uint8Array.from(atob(base64), (c) =>
          c.charCodeAt(0),
        ).buffer;
        const blob = new Blob([arrayBuffer]);
        const objectUrl = URL.createObjectURL(blob);
        imageUrlRef = objectUrl;
        setImageUrl(objectUrl);
      } catch (e) {
        console.error(
          "Couldn't decode image base64, string not correctly encoded",
        );
        setImageUrl(null);
      }
    } else if (src) {
      setImageUrl(src);
    } else if (imageId) {
      getBlobFile(imageId)
        .then((res) => {
          const objectUrl = URL.createObjectURL(res);
          setImageUrl(objectUrl);
          imageUrlRef = objectUrl;
        })
        .catch((error) => {
          console.error('Error fetching blob file:', error);
          setImageUrl(null);
        });
    } else if (!base64) {
      setImageUrl(null);
      URL.revokeObjectURL(imageUrlRef);
    }

    return () => {
      if (imageUrlRef) {
        URL.revokeObjectURL(imageUrlRef);
      }
    };
    // eslint-disable-next-line
  }, [base64, src, imageId]);

  return (
    <>
      {imageUrl ? (
        <div
          className="avatar__component"
          style={{ width: width || size, height: height || size, ...styles }}
        >
          {removable ? (
            <div className="avatar__clear">
              <Button
                type="button"
                className="chat__group-image--delete avatar__clear-button"
                onClick={clearImage}
                style={{ width: width || size, height: height || size }}
              >
                <Icon
                  name="trash"
                  style={{ width: '40px', height: '40px', fill: '#1890ff' }}
                />
              </Button>
            </div>
          ) : null}
          <Image
            src={imageUrl}
            preview={preview}
            width={width || size}
            height={height || size}
            style={{
              borderRadius: radius,
              objectFit: 'cover',
              objectPosition: 'center',
              cursor: onClick ? 'pointer' : undefined,
            }}
            onClick={onClick || undefined}
          />
        </div>
      ) : (
        <>
          {removable ? (
            <ImageUploader onImageUpload={onUpload} imageUrl={imageUrl} />
          ) : null}
        </>
      )}
    </>
  );
};

export default AvatarComponent;
