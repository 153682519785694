import React from 'react';
import { Button, Tooltip } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';

const GeneratePdfReportIcon = ({
  path,
  fileName,
  method,
  parameters,
  hintText,
  disabled = false,
}) => {
  return (
    <Tooltip title={hintText}>
      <Button
        shape="circle"
        type="link"
        icon={<FilePdfOutlined />}
        onClick={() => {
          method(fileName, path, parameters);
        }}
        disabled={disabled}
      />
    </Tooltip>
  );
};

export default GeneratePdfReportIcon;
