import { get, post, put, remove } from '../../helpers/api';

const BASE_PATH = '/backend/api/v1/transactions-files/';

export const saveTransactioFiles = async (file) => {
  return post(`${BASE_PATH}`, file);
};

export const saveDriverImage = async (transactionId, file) =>
  put(`${BASE_PATH}driver-image/${transactionId}`, file);

export const getTransactioFiles = (transactionId) =>
  get(`${BASE_PATH}/${transactionId}`);

export const removeTransactioFile = async (fileId) =>
  remove(`${BASE_PATH}/${fileId}`);
