import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { notification, Tabs } from 'antd';
import {
  CarOutlined,
  DatabaseOutlined,
  ExperimentOutlined,
  HomeOutlined,
  ProfileOutlined,
  ScanOutlined,
  VideoCameraOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import GeneralData from './generalData/GeneralData';
import { useEditPage } from '../../hooks/useEditPage';
import AuthContext, { hasPermission } from '../auth';
import {
  create,
  findOne,
  saveActControl,
  saveBonPlata,
  update,
} from '../../services/transactions/transactions';
import { findOne as findPostById } from '../../services/admin/users/customs-posts';
import Vicos from './Vicos';
import Weighing from './Weighing';
import Anta from './Anta';
import Ansa from './Ansa';
import Deposit from './Deposit';
import Scan from './Scan';
import PortalInfo from './PortalInfo';
import ControlButtons from './ControlButtons';
import AppLoader from '../auth/AppLoader';
import AditionalData from './aditionalData/AditionalData';
import CarColorChecker from '../../pages/CarColorChecker';
import TransactionStatus from '../../enums/TransactionStatus';
import UserFunctions from '../../enums/UserFunctions';
import { convertKilosToTons } from '../../services/utils/convertors/weigth-converter';
import Vignette from './Vignette';
import Icon from '../Icon';
import RequiredLabel from '../../services/utils/RequiredLabel';
import PhysicalPersonHeader from './PhysicalPersonHeader';
import DeclarationsList from './Declarations';

const initial = {};

const TABS = {
  GENERA_DATA: { id: 1, tab: '#GENERAL_DATA' },
  SCALE: { id: 2, tab: '#HAS_SCALE' },
  ANTA: { id: 3, tab: '#HAS_ANTA' },
  MD_UA: { id: 4, tab: '#HAS_MD_UA' },
  ADDITIONAL: { id: 5, tab: '#HAS_ADITIONAL' },
  SCAN: { id: 6, tab: '#HAS_SCAN' },
  VICOS: { id: 7, tab: '#HAS_VICOS' },
  ANSA: { id: 8, tab: '#HAS_ANSA' },
  WAREHOUSE: { id: 9, tab: '#HAS_WAREHOUSE' },
  VIGNETTE: { id: 10, tab: '#HAS_VIGNETTE' },
  DECLARATIONS: { id: 11, tab: '#DECLARATIONS' },
};
const PersonPhysicalEntryComponent = () => {
  const { user } = useContext(AuthContext);

  const { permissions } = user;
  const [loadingLoader, setLoadingLoader] = useState(false);
  const [isWeighingAmount, setIsWeighingAmount] = useState(false);
  const [isAntaAmount, setIsAntaAmount] = useState(false);
  const [isAnsaAmount, setIsAnsaAmount] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const { t } = useTranslation();
  const [updatePage, setUpdate] = useState(0);
  const [currentPost, setCurrentPost] = useState({});
  const [readOnly, setReadOnly] = useState(true);
  const tabs = useRef([]);

  const doUpdate = () => {
    setUpdate((val) => val + 1);
  };

  const history = useHistory();
  const location = useLocation();

  const currentTab = useMemo(
    () =>
      location.hash ||
      (user?.function !== UserFunctions.SCANN_OPERATOR
        ? '#GENERAL_DATA'
        : '#HAS_SCAN'),
    [location.hash, user?.function],
  );

  const handleTabChange = useCallback(
    (tab) => {
      history.push({
        ...location,
        hash: tab,
      });
    },
    [history, location],
  );

  const [transaction, handleSubmit, handleCancel, errors] = useEditPage({
    initial,
    dependency: updatePage,
    existent: findOne,
    goBackPath: '/home',
    onCreate: create,
    onUpdate: update,
  });

  const isReadOnlyUser = useCallback(() => {
    return (
      !user?.function ||
      user.function === UserFunctions.OFFICE_HEAD ||
      user.function === UserFunctions.CENTRAL ||
      user.function === UserFunctions.MB_TEAM
    );
  }, [user.function]);

  useEffect(() => {
    if (transaction?.id) {
      const isEnabled =
        user?.function === UserFunctions.SCANN_OPERATOR &&
        transaction?.status === TransactionStatus.PENDING;
      const readOnlyCheck =
        transaction?.status !== TransactionStatus.PENDING ||
        isReadOnlyUser() ||
        (user.id !== transaction?.inspectorId && !isEnabled);
      setReadOnly(readOnlyCheck);
    } else {
      setReadOnly(false);
    }
  }, [
    transaction?.inspectorId,
    transaction?.id,
    transaction?.status,
    isReadOnlyUser,
    user.id,
    user?.function,
  ]);

  useEffect(() => {
    if (transaction?.postId) {
      findPostById(transaction?.postId)
        .then((res) => setCurrentPost(res))
        .catch(() => {
          notification.error({
            message: 'A apărut o eroare la extragerea datelor postului vamal',
            description: 'Reporniți pagina.',
          });
        });
    }
  }, [transaction?.postId]);

  const isAccessRejected = useCallback(() => {
    return !user?.function || user?.function === UserFunctions.SCANN_OPERATOR;
  }, [user.function]);

  const isScaleRequired = useMemo(() => {
    if (transaction?.scale) {
      const {
        vehicleWeight,
        goodsWeight,
        totalWeight,
        axisCount,
        axisDistance,
        distance,
      } = transaction?.scale;

      return [
        vehicleWeight,
        goodsWeight,
        totalWeight,
        axisCount,
        axisDistance,
        distance,
      ].some((value) => value !== null && value !== undefined);
    }
    return false;
  }, [transaction?.scale]);

  // const isAccessDenied = useCallback(() => {
  //   if (
  //     transaction?.postId &&
  //     (user?.function === UserFunctions.SCANN_OPERATOR ||
  //       user?.function === UserFunctions.INSPECTOR ||
  //       user.function === UserFunctions.POST_HEAD ||
  //       user.function === UserFunctions.HEAD)
  //   ) {
  //     // return user.postId !== transaction?.postId;
  //   }
  //   return false;
  //   // eslint-disable-next-line
  // }, [user?.function, user.postId, transaction?.postId]);

  const transformTransaction = useCallback(
    (trans = transaction) => {
      if (!trans?.id) {
        trans.consemne = [];
      }
      const obj = { ...trans };
      const persons = trans.personList;
      const { vehicles, scale } = trans;
      obj.directions = trans.direction;
      obj.irregularities = trans.irregularities;
      obj.sesizari = trans.sesizari;
      if (persons && persons.length > 0) {
        const crossingPersonPhysical = persons?.find(
          (el) => el.isCrossing && el.isPhysicalPerson,
        );
        if (crossingPersonPhysical) {
          obj.personType = crossingPersonPhysical.personType === 'REZIDENT';
          obj.personalCode = crossingPersonPhysical.personalCode;
          obj.fullName = crossingPersonPhysical.fullName;
          obj.drivingLicence = crossingPersonPhysical.drivingLicence;
          obj.drivingLicenceDate = crossingPersonPhysical.drivingLicenceDate
            ? dayjs(crossingPersonPhysical.drivingLicenceDate)
            : null;
          obj.drivingLicenceCountryId =
            crossingPersonPhysical.drivingLicenceCountryId;
          obj.personCountry = crossingPersonPhysical.countryId;
          obj.isVehicleOwner = true;
          obj.isDiplomat = crossingPersonPhysical.isDiplomat;
        }
        const crossingPersonLegal = persons?.find(
          (el) => el.isCrossing && !el.isPhysicalPerson,
        );
        if (crossingPersonLegal) {
          obj.typeCompany = crossingPersonLegal.personType === 'REZIDENT';
          obj.idno = crossingPersonLegal.personalCode;
          obj.nameCompany = crossingPersonLegal.fullName;
          obj.companyCountry = crossingPersonLegal.countryId;
          obj.transporterIsPhysicalPerson =
            crossingPersonLegal?.transporterIsPhysicalPerson;
          obj.isAeo = crossingPersonLegal.isAeo;
        }
        const otherOwner = persons?.find((el) => !el.isCrossing && el.isOwner);
        if (otherOwner) {
          obj.ownerType = otherOwner.personType === 'REZIDENT';
          obj.ownerPersonalCode = otherOwner.personalCode;
          obj.ownerFullName = otherOwner.fullName;
          obj.ownerCountry = otherOwner.countryId;
          obj.isVehicleOwner = false;
        }
      }

      if (vehicles && vehicles.length > 0) {
        const vehicle = vehicles?.find((el) => !el.isTrailer);
        if (vehicle) {
          obj.vehicleType = vehicle.category;
          obj.vehicleCountry = vehicle.countryId;
          obj.typeOfVehicle = vehicle.vehicleTypeId;
          obj.vehicleNumber = vehicle.plateNumber;
          obj.vehicleVinCode = vehicle.vinCode;
          obj.color = vehicle.color;
          obj.mark = vehicle.mark;
          obj.model = vehicle.model;
          obj.typeOfVehicle = vehicle.vehicleTypeId;
          obj.hadDangerousGoods = vehicle.hasDangerousGoods;
          obj.hasPerishable = vehicle.hasPerishable;
        }

        const trailers = vehicles.filter((el) => el.isTrailer);
        trailers.sort(
          (a, b) =>
            (!!b.container1PlateNumber ||
              !!b.container2PlateNumber ||
              !!b.container3PlateNumber) -
            (!!a.container1PlateNumber ||
              !!a.container2PlateNumber ||
              !!a.container3PlateNumber),
        );
        if (trailers && trailers.length > 0) {
          obj.trail1_country = trailers[0].countryId;
          obj.trail1_number = trailers[0].plateNumber;
          obj.trail1_vin = trailers[0].vinCode;
          obj.trail1_body_number = trailers[0].bodyNumber;
          obj.trail1_chassis_number = trailers[0].chassisNumber;
          obj.trailerTypeId1 = trailers[0].trailerTypeId;
          obj.container1PlateNumber = trailers[0].container1PlateNumber;
          obj.container2PlateNumber = trailers[0].container2PlateNumber;
          obj.container3PlateNumber = trailers[0].container3PlateNumber;
        }
        if (trailers && trailers.length > 1) {
          obj.trail2_country = trailers[1].countryId;
          obj.trail2_number = trailers[1].plateNumber;
          obj.trail2_vin = trailers[1].vinCode;
          obj.trail2_body_number = trailers[1].bodyNumber;
          obj.trail2_chassis_number = trailers[1].chassisNumber;
          obj.trailerTypeId2 = trailers[1].trailerTypeId;
        }
      }

      if (scale) {
        obj.agentIdno = scale.agentIdno;
        obj.agentName = scale.agentName;
        obj.agentCountry = scale.agentCountryId;
        obj.agentType = scale.agentType === 'REZIDENT';
        obj.isGroupage = scale.isGroupage;

        obj.vehicleWeight = convertKilosToTons(scale.vehicleWeight);
        obj.goodsWeight = convertKilosToTons(scale.goodsWeight);
        obj.totalWeight = convertKilosToTons(scale.totalWeight);

        obj.axisCount = scale.axisCount;
        obj.axisDistance = scale.axisDistance;
        obj.distance = scale.distance;
      }
      return obj;
    },
    [transaction],
  );

  const verificationDocumentParametersMap = [
    {
      type: 'text',
      label: 'Numarul actului',
      name: 'document_number',
    },
    {
      type: 'text',
      label: 'Număr de inregistrare',
      name: 'declaration_number',
    },
    {
      type: 'text',
      label: 'A expediat lotul de marfuri',
      name: 'act_goods_owner',
    },
    {
      type: 'text',
      label: 'Adresa de expediere a marfurilor',
      name: 'act_owner_address',
    },
    {
      type: 'text',
      label: 'Au asistat la control',
      name: 'act_control_assisted',
    },
    {
      type: 'number',
      label: 'Numarul locurilor si coletelor verificate',
      name: 'act_nbr_places',
    },
    {
      type: 'text',
      label: 'Scopul controlului fizic vamal',
      name: 'act_customs_purpose',
    },
    {
      type: 'text',
      label: 'Rezultatul controlului',
      name: 'act_result',
    },
    {
      type: 'text',
      label: 'Reprezentantul agentului economic',
      name: 'economical_agent_representative',
    },
    {
      type: 'text',
      label: 'Reprezentantul transportatorului',
      name: 'transporter_representative',
    },
  ];

  const verificationDocumentParametersModalTitle = t(
    'entity.admin.reports.parametersForReport',
    {
      report: t('entity.admin.constant.documents.verification'),
    },
  );

  const paymentReceiptParametersModalTitle = t(
    'entity.admin.reports.parametersForReport',
    {
      report: t('entity.admin.constant.documents.paymentReceipt'),
    },
  );

  const documentParameters = (caption, modalTitle, parametersTypesMap) => {
    return {
      buttonCaption: caption,
      parametersModalTitle: modalTitle,
      parametersTypes: parametersTypesMap,
      customsRegimeId: transaction?.customsRegimeId,
    };
  };
  const getVehicleCategory = () => {
    return transaction?.vehicles?.find((el) => !el.isTrailer)?.category;
  };
  const getVehicleColor = () => {
    return transaction?.vehicles?.find((el) => !el.isTrailer)?.color;
  };
  const getVehicleDirection = () => {
    return transaction?.vehicles?.find((el) => !el.isTrailer)?.direction;
  };

  const handleAddTab = useCallback((tab) => {
    if (!tabs.current.some((item) => item.id === tab.id)) {
      tabs.current = [...tabs.current, tab];
    }
    return true;
  }, []);

  const handleRemoveTab = useCallback((tab) => {
    tabs.current = [...tabs.current.filter((el) => el.id !== tab.id)];
  }, []);

  const moveNext = useCallback(() => {
    const currentTabID = tabs.current.find(
      (item) => item.tab === location.hash,
    )?.id;

    const filteredList = tabs.current.filter((item) => item.id > currentTabID);
    const nextTab =
      filteredList.length > 0
        ? filteredList.reduce((min, item) => (item.id < min.id ? item : min))
            .tab || '#GENERAL_DATA'
        : '#GENERAL_DATA';

    const modifiedLink = `/physical-person-entry/${transaction.id}${nextTab}`;
    history.push(modifiedLink);
  }, [transaction?.id, location.hash, history]);

  const moveBack = useCallback(() => {
    const currentTabID = tabs.current.find(
      (item) => item.tab === location.hash,
    )?.id;

    if (!tabs.current.some((item) => item.tab === '#GENERAL_DATA')) {
      return history.push('/home');
    }

    const filteredList = tabs.current.filter((item) => item.id < currentTabID);
    const prevTab =
      filteredList.length > 0
        ? filteredList.reduce((max, item) => (item.id > max.id ? item : max))
            .tab || '#GENERAL_DATA'
        : '#GENERAL_DATA';
    const modifiedLink = `/physical-person-entry/${transaction.id}${prevTab}`;
    return history.push(modifiedLink);
  }, [transaction?.id, location.hash, history]);

  return (
    <div className="transaction__tabs-content">
      <div className={`editmode__background ${editMode ? 'active' : ''}`} />
      <div className={`loaderOverlay ${loadingLoader ? 'active' : ''}`}>
        <AppLoader />
      </div>

      {transaction?.id && transaction?.status !== TransactionStatus.PENDING ? (
        <PhysicalPersonHeader
          inspectorId={transaction?.inspectorId}
          post={currentPost}
        />
      ) : null}
      {
        // eslint-disable-next-line
        <>
          <div
            className="general__car-number"
            style={{ float: 'right', transform: 'translateY(-20px)' }}
          >
            {transaction?.allPlateNumbers?.toUpperCase()}
          </div>
          {transaction?.id && (
            <div className="general__id-container">
              <div className="general__id-number">{transaction?.id}</div>
            </div>
          )}

          <div style={{ float: 'right' }} className="general__icon">
            <CarColorChecker
              color={getVehicleColor()}
              category={getVehicleCategory()}
              direction={getVehicleDirection()}
            />
          </div>
          <Tabs activeKey={currentTab} onChange={handleTabChange}>
            {transaction &&
            !isAccessRejected() &&
            handleAddTab(TABS.GENERA_DATA) ? (
              <Tabs.TabPane
                tab={
                  <span
                    style={
                      // eslint-disable-next-line
                      transaction.isGeneralDataConfirmed
                        ? { color: '#24a052' }
                        : !transaction.isGeneralDataConfirmed
                        ? { color: '#e6484b' }
                        : null
                    }
                  >
                    <RequiredLabel
                      title={
                        <>
                          <Icon
                            name="global"
                            className="anticon tab__general-data--icon"
                            style={
                              // eslint-disable-next-line
                              transaction.isGeneralDataConfirmed
                                ? { fill: '#24a052' }
                                : !transaction.isGeneralDataConfirmed
                                ? { fill: '#e6484b' }
                                : null
                            }
                          />
                          Date Generale
                        </>
                      }
                    />
                  </span>
                }
                // tab="Date Generale"
                key="#GENERAL_DATA"
              >
                <GeneralData
                  transaction={transformTransaction()}
                  onSubmit={handleSubmit}
                  onCancel={handleCancel}
                  errors={errors}
                  readOnly={readOnly}
                  editMode={editMode}
                  reload={doUpdate}
                  setEditMode={setEditMode}
                  transformTransaction={transformTransaction}
                  moveNext={moveNext}
                />
              </Tabs.TabPane>
            ) : (
              handleRemoveTab(TABS.GENERA_DATA)
            )}
            {transaction &&
            transaction.id &&
            !isAccessRejected() &&
            currentPost?.hasScale &&
            handleAddTab(TABS.SCALE) ? (
              <Tabs.TabPane
                className="tab__scale"
                disabled={editMode}
                tab={
                  <span
                    style={
                      // eslint-disable-next-line
                      transaction.isScaleConfirmed && transaction.hasScale
                        ? { color: '#24a052' }
                        : !transaction.isScaleConfirmed && transaction.hasScale
                        ? { color: '#e6484b' }
                        : null
                    }
                  >
                    {transaction?.hasGoods ? (
                      <RequiredLabel
                        required={isScaleRequired}
                        title={
                          <>
                            <Icon
                              name="scale"
                              className="anticon tab__scale-icon"
                              style={
                                // eslint-disable-next-line
                                transaction.isScaleConfirmed &&
                                transaction.hasScale
                                  ? { fill: '#24a052' }
                                  : !transaction.isScaleConfirmed &&
                                    transaction.hasScale
                                  ? { fill: '#e6484b' }
                                  : null
                              }
                            />
                            Cântar
                          </>
                        }
                      />
                    ) : (
                      <>
                        <Icon
                          name="scale"
                          className="anticon tab__scale-icon"
                          style={
                            // eslint-disable-next-line
                            transaction.isScaleConfirmed && transaction.hasScale
                              ? { fill: '#24a052' }
                              : !transaction.isScaleConfirmed &&
                                transaction.hasScale
                              ? { fill: '#e6484b' }
                              : null
                          }
                        />
                        Cântar
                      </>
                    )}
                  </span>
                }
                key="#HAS_SCALE"
              >
                <Weighing
                  setIsWeighingAmount={setIsWeighingAmount}
                  transaction={transaction}
                  onCancel={handleCancel}
                  errors={errors}
                  skipTab={handleTabChange}
                  readOnly={readOnly}
                  reload={doUpdate}
                  moveNext={moveNext}
                  moveBack={moveBack}
                />
              </Tabs.TabPane>
            ) : (
              handleRemoveTab(TABS.SCALE)
            )}
            {transaction &&
            transaction?.hasGoods &&
            transaction.id &&
            !isAccessRejected() &&
            currentPost?.hasAnta &&
            handleAddTab(TABS.ANTA) ? (
              <Tabs.TabPane
                disabled={editMode}
                tab={
                  <span
                    style={
                      // eslint-disable-next-line
                      transaction.isAntaConfirmed && transaction.hasAnta
                        ? { color: '#24a052' }
                        : !transaction.isAntaConfirmed && transaction.hasAnta
                        ? { color: '#e6484b' }
                        : null
                    }
                  >
                    <RequiredLabel
                      title={
                        <>
                          <CarOutlined
                            color={
                              // eslint-disable-next-line
                              transaction.isAntaConfirmed && transaction.hasAnta
                                ? { fill: '#24a052' }
                                : !transaction.isAntaConfirmed &&
                                  transaction.hasAnta
                                ? { fill: '#e6484b' }
                                : null
                            }
                          />
                          ANTA
                        </>
                      }
                    />
                  </span>
                }
                key="#HAS_ANTA"
              >
                <Anta
                  setIsAntaAmount={setIsAntaAmount}
                  transaction={transaction}
                  onCancel={handleCancel}
                  errors={errors}
                  skipTab={handleTabChange}
                  readOnly={readOnly}
                  reload={doUpdate}
                  moveNext={moveNext}
                  moveBack={moveBack}
                />
              </Tabs.TabPane>
            ) : (
              handleRemoveTab(TABS.ANTA)
            )}
            {transaction &&
            transaction?.hasGoods &&
            transaction.id &&
            transaction.direction === 'IN' &&
            !isAccessRejected() &&
            currentPost?.hasMdUa &&
            handleAddTab(TABS.MD_UA) ? (
              <Tabs.TabPane
                disabled={editMode}
                tab={
                  <span
                    style={
                      // eslint-disable-next-line
                      transaction.isMdUaConfirmed && transaction.hasMdUa
                        ? { color: '#24a052' }
                        : !transaction.isMdUaConfirmed && transaction.hasMdUa
                        ? { color: '#e6484b' }
                        : null
                    }
                  >
                    <RequiredLabel
                      title={
                        <>
                          <ProfileOutlined
                            color={
                              // eslint-disable-next-line
                              transaction.isMdUaConfirmed && transaction.hasMdUa
                                ? { color: '#24a052' }
                                : !transaction.isMdUaConfirmed &&
                                  transaction.hasMdUa
                                ? { color: '#e6484b' }
                                : null
                            }
                          />
                          MDUA
                        </>
                      }
                    />
                  </span>
                }
                key="#HAS_MD_UA"
              >
                <PortalInfo
                  transaction={transaction}
                  onCancel={handleCancel}
                  errors={errors}
                  vehicles={transaction.vehicles}
                  skipTab={handleTabChange}
                  readOnly={readOnly}
                  reload={doUpdate}
                  moveNext={moveNext}
                  moveBack={moveBack}
                />
              </Tabs.TabPane>
            ) : (
              handleRemoveTab(TABS.MD_UA)
            )}
            {transaction &&
            transaction.id &&
            !isAccessRejected() &&
            handleAddTab(TABS.ADDITIONAL) ? (
              <Tabs.TabPane
                disabled={editMode}
                tab={
                  <span>
                    <DatabaseOutlined />
                    Date Adiționale
                  </span>
                }
                key="#HAS_ADITIONAL"
              >
                <AditionalData
                  transactionId={transaction.id}
                  onSubmit={handleSubmit}
                  onCancel={handleCancel}
                  errors={errors}
                  readOnly={readOnly}
                  moveNext={moveNext}
                  moveBack={moveBack}
                />
              </Tabs.TabPane>
            ) : (
              handleRemoveTab(TABS.ADDITIONAL)
            )}
            {transaction &&
            transaction.id &&
            currentPost?.hasScan &&
            handleAddTab(TABS.SCAN) ? (
              <Tabs.TabPane
                disabled={editMode}
                tab={
                  <span
                    style={
                      // eslint-disable-next-line
                      transaction.isScanConfirmed && transaction.hasScan
                        ? { color: '#24a052' }
                        : !transaction.isScanConfirmed && transaction.hasScan
                        ? { color: '#e6484b' }
                        : null
                    }
                  >
                    <ScanOutlined
                      color={
                        // eslint-disable-next-line
                        transaction.isScanConfirmed && transaction.hasScan
                          ? { color: '#24a052' }
                          : !transaction.isScanConfirmed && transaction.hasScan
                          ? { color: '#e6484b' }
                          : null
                      }
                    />
                    SCAN
                  </span>
                }
                key="#HAS_SCAN"
              >
                <Scan
                  transaction={transaction}
                  onCancel={handleCancel}
                  errors={errors}
                  skipTab={handleTabChange}
                  readOnly={readOnly}
                  reload={doUpdate}
                  moveNext={moveNext}
                  moveBack={moveBack}
                />
              </Tabs.TabPane>
            ) : (
              handleRemoveTab(TABS.SCAN)
            )}
            {hasPermission(permissions, 'SEARCH_VICOSES') &&
            transaction &&
            transaction.id &&
            !isAccessRejected() &&
            currentPost?.hasVicos &&
            handleAddTab(TABS.VICOS) ? (
              <Tabs.TabPane
                tab={
                  <span>
                    <VideoCameraOutlined />
                    VICOS
                  </span>
                }
                key="#HAS_VICOS"
                disabled={editMode}
              >
                <Vicos
                  transaction={transaction}
                  onCancel={handleCancel}
                  errors={errors}
                  readOnly={readOnly}
                  moveNext={moveNext}
                  moveBack={moveBack}
                />
              </Tabs.TabPane>
            ) : (
              handleRemoveTab(TABS.VICOS)
            )}

            {transaction &&
            transaction?.hasGoods &&
            transaction.id &&
            !isAccessRejected() &&
            currentPost?.hasAnsa &&
            handleAddTab(TABS.ANSA) ? (
              <Tabs.TabPane
                disabled={editMode}
                tab={
                  <span
                    style={
                      // eslint-disable-next-line
                      transaction.isAnsaConfirmed && transaction.hasAnsa
                        ? { color: '#24a052' }
                        : !transaction.isAnsaConfirmed && transaction.hasAnsa
                        ? { color: '#e6484b' }
                        : null
                    }
                  >
                    <ExperimentOutlined
                      color={
                        // eslint-disable-next-line
                        transaction.isAnsaConfirmed && transaction.hasAnsa
                          ? { color: '#24a052' }
                          : !transaction.isAnsaConfirmed && transaction.hasAnsa
                          ? { color: '#e6484b' }
                          : null
                      }
                    />
                    ANSA
                  </span>
                }
                key="#HAS_ANSA"
              >
                <Ansa
                  setIsAnsaAmount={setIsAnsaAmount}
                  transaction={transaction}
                  onCancel={handleCancel}
                  errors={errors}
                  skipTab={handleTabChange}
                  readOnly={readOnly}
                  reload={doUpdate}
                  moveNext={moveNext}
                  moveBack={moveBack}
                />
              </Tabs.TabPane>
            ) : (
              handleRemoveTab(TABS.ANSA)
            )}
            {transaction &&
            transaction.id &&
            !isAccessRejected() &&
            currentPost?.hasWarehouse &&
            handleAddTab(TABS.WAREHOUSE) ? (
              <Tabs.TabPane
                disabled={editMode}
                tab={
                  <span>
                    <HomeOutlined />
                    Transbordare
                  </span>
                }
                key="#HAS_WAREHOUSE"
              >
                <Deposit
                  transaction={transaction}
                  onCancel={handleCancel}
                  errors={errors}
                  skipTab={handleTabChange}
                  readOnly={readOnly}
                  reload={doUpdate}
                  moveNext={moveNext}
                  moveBack={moveBack}
                />
              </Tabs.TabPane>
            ) : (
              handleRemoveTab(TABS.WAREHOUSE)
            )}

            {transaction &&
            transaction.id &&
            !isAccessRejected() &&
            currentPost?.hasVignette &&
            hasPermission(permissions, 'ASD_WS') &&
            handleAddTab(TABS.VIGNETTE) ? (
              <Tabs.TabPane
                disabled={editMode}
                tab={
                  <span>
                    <Icon
                      name="money"
                      className="anticon"
                      style={{
                        width: '15px',
                        height: '15px',
                      }}
                    />
                    Vinieta
                  </span>
                }
                key="#HAS_VIGNETTE"
              >
                <Vignette
                  transactionId={transaction?.id}
                  vehicle={transaction?.vehicles?.find((el) => !el.isTrailer)}
                  onCancel={handleCancel}
                  errors={errors}
                  readOnly={readOnly}
                  reload={doUpdate}
                  moveNext={moveNext}
                  moveBack={moveBack}
                />
              </Tabs.TabPane>
            ) : (
              handleRemoveTab(TABS.VIGNETTE)
            )}
            {transaction &&
            !isAccessRejected() &&
            handleAddTab(TABS.DECLARATIONS) ? (
              <Tabs.TabPane
                tab={
                  <span>
                    <FileTextOutlined />
                    Declarații ale călătorilor
                  </span>
                }
                // tab="Declarații calotorului"
                key="#DECLARATIONS"
              >
                <DeclarationsList
                  transactionId={transaction.id}
                  moveNext={moveNext}
                  moveBack={moveBack}
                  readOnly={readOnly}
                />
              </Tabs.TabPane>
            ) : (
              handleRemoveTab(TABS.DECLARATIONS)
            )}
          </Tabs>

          {!isAccessRejected() && (
            <ControlButtons
              vehicleNumber={transaction?.plateNumber}
              loadingLoader={loadingLoader}
              readOnly={readOnly}
              setLoadingLoader={setLoadingLoader}
              isWeighingAmount={isWeighingAmount}
              isAntaAmount={isAntaAmount}
              isAnsaAmount={isAnsaAmount}
              customsAttributesUser={user?.customsAttributes || []}
              customsAttributesTransaction={
                transaction?.customsAttributes || []
              }
              transactionId={transaction?.id}
              skipTab={handleTabChange}
              verificationMethod={saveActControl}
              verificationParameters={documentParameters(
                t('actions.controlDocument'),
                verificationDocumentParametersModalTitle,
                verificationDocumentParametersMap,
              )}
              receiptMethod={saveBonPlata}
              receiptParameters={documentParameters(
                t('actions.receiptNote'),
                paymentReceiptParametersModalTitle,
                [],
              )}
              returModalParameters={{
                returnData: transaction?.returnParameters,
              }}
              sesizariModalParameters={{
                sesizariData: transaction?.sesizari,
                person: transaction?.personList?.find(
                  (el) => el.isPhysicalPerson && el.isCrossing,
                ),
              }}
              fraudReportParameters={transaction?.suspicionFraudReport}
              reload={doUpdate}
              irregularities={transaction?.irregularities}
            />
          )}
        </>
      }
    </div>
  );
};

export default PersonPhysicalEntryComponent;
