import React, { useEffect, useState } from 'react';
import { useEditPage } from '../../../../hooks/useEditPage';
import AppLoader from '../../../../components/auth/AppLoader';
import LegalPersonsRisksEditForm from '../../../../components/lucrative/risks-actions/legal-persons/LegalPersonsRisksEditForm';
import {
  findOne,
  create,
  update,
} from '../../../../services/risks/risk-actions/legal-persons-risks';
import { useRouteParams } from '../../../../hooks/useRouteParams';

const initial = {};
const LegalPersonsRisk = () => {
  const { copyId } = useRouteParams('copyId');
  const [copy, setCopy] = useState(undefined);

  const [risk, handleSubmit, handleCancel, errors, loading] = useEditPage({
    initial,
    existent: findOne,
    goBackPath: `/lucrative/risk-analysis/legal-persons`,
    onCreate: create,
    onUpdate: update,
  });

  useEffect(() => {
    if (copyId && typeof copyId === 'number') {
      findOne(copyId).then(setCopy).catch().finally();
    }
  }, [copyId]);

  return (
    <>
      <div className={`loaderOverlay ${loading ? 'active' : ''}`}>
        <AppLoader />
      </div>
      {risk !== undefined && (
        <LegalPersonsRisksEditForm
          risk={risk}
          copy={copy}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          errors={errors}
        />
      )}
    </>
  );
};

export default LegalPersonsRisk;
