import React from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import VicosList from './VicosList';

const Vicoses = () => {
  return (
    <>
      <PageHeader title="VICOS" />
      <VicosList />
    </>
  );
};

export default Vicoses;
