import { get } from '../../../helpers/api';

export const SERVICE_URI = '/mdua';
export const BASE_URI = '/api/v1';

export const getDetailsByPlateNumber = (
  plateNumber,
  direction,
  searchFrom,
  searchTo,
) =>
  get(
    `${SERVICE_URI}${BASE_URI}/search/mdua/by-plate-number/${plateNumber}/${direction}/${searchFrom}/${searchTo}`,
  );

export const getDetailsByTIRNumber = (
  tirNumber,
  direction,
  searchFrom,
  searchTo,
) =>
  get(
    `${SERVICE_URI}${BASE_URI}/search/mdua/by-tir-number/${tirNumber}/${direction}/${searchFrom}/${searchTo}`,
  );

export const getDetailsByDeclarationNumber = (
  declarationNumber1,
  declarationNumber2,
  declarationNumber3,
  direction,
) =>
  get(
    `${SERVICE_URI}${BASE_URI}/search/mdua/by-declaration-number/${declarationNumber1}/${declarationNumber2}/${declarationNumber3}/${direction}`,
  );

export const getDetailsByDeclarationNumberOneParameter = (
  declarationNumber,
  direction,
) =>
  get(
    `${SERVICE_URI}${BASE_URI}/search/mdua/by-declaration-number/${declarationNumber}/${direction}`,
  );

export const getDetailsByDeclarationIds = (declarationIds, direction) =>
  get(
    `${SERVICE_URI}${BASE_URI}/search/mdua/by-id/${declarationIds}/${direction}`,
  );
