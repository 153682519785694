import { genericService, get } from '../../../helpers/api';

export const SERVICE_URI = '/mconnect-sfs-organizatii';
export const BASE_URI = '/api/v1';
export const BASE_URI_SEARCH_ORGANIZATION = '/search/organization';

export const { findAll } = genericService(
  SERVICE_URI,
  BASE_URI_SEARCH_ORGANIZATION,
);

export const getOrganizationByIdno = (idno) =>
  get(`${SERVICE_URI}/search/organization/${idno}`);

export const getPersonByIdnp = (idnp) =>
  get(`${SERVICE_URI}/search/person/${idnp}`);
