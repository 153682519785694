import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Table, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useTableScroll } from '../../../../hooks/useTableScroll';
import useDatasource from '../../../../hooks/useDatasource';
import { findAll } from '../../chat-service/chat-management';
import Column from '../../../../helpers/Columns';
import ViewItemIcon from '../../../buttons/ViewItemIcon';
import { findAllByIds } from '../../../../services/admin/users/users';

const chatType = {
  content: [
    { id: 'GROUP', code: 'GROUP', name: 'GRUP' },
    { id: 'USER', code: 'USER', name: 'USER' },
  ],
};
const USERS_BATCH_SIZE = 100;
const ChatList = () => {
  const { t } = useTranslation();
  const [usersIds, setUsersIds] = useState([]);
  const [users, setUsers] = useState([]);

  useTableScroll();
  const { loading, pagination, content, handleChange } = useDatasource(findAll);

  useEffect(() => {
    if (content?.length > 0) {
      const userIds = new Set(content.flatMap((item) => item.userIds));
      setUsersIds(Array.from(userIds));
    }
  }, [content]);

  useEffect(() => {
    if (usersIds?.length > 0) {
      const batchSize = USERS_BATCH_SIZE;
      for (let i = 0; i < usersIds.length; i += batchSize) {
        const batch = usersIds.slice(i, i + batchSize);
        findAllByIds(batch).then((res) =>
          setUsers((prev) => [
            ...prev,
            ...res.filter(
              (item) => !prev.some((prevItem) => prevItem.id === item.id),
            ),
          ]),
        );
      }
    }
  }, [usersIds]);

  const renderUsers = useCallback(
    (ids = []) => {
      if (users?.length > 0) {
        const present = users.filter((el) => ids.includes(el.id));
        const stringResult = present?.map((el) => el.fullname).join(', ');
        if (stringResult?.length > 50) {
          return (
            <Tooltip title={stringResult}>
              {`${stringResult.substring(0, 30)}...`}
            </Tooltip>
          );
        }
        return stringResult;
      }
      return ' ';
    },
    [users],
  );

  const columns = useMemo(
    () => [
      Column.text('id', 'ID', {
        filter: true,
        width: 100,
      }),
      Column.dictionary('type', 'Tip', chatType, {
        width: 100,
        filter: true,
      }),
      Column.other(
        'usersLikeName',
        'Membri',
        (code, row) => {
          return renderUsers(row.userIds);
        },
        {
          sort: false,
          filter: true,
          width: 250,
        },
      ),
      Column.date('createdAt', t('entity._.createdAt'), {
        filter: true,
        width: 150,
      }),
      Column.bool('deleted', t('entity._.deleted._'), {
        width: 100,
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity._.deleted.true'),
          t('entity._.deleted.false'),
          t('entity._.deleted.unknown'),
        ],
      }),
      Column.actions('Acțiune', (record) => (
        <>
          <ViewItemIcon path={`/admin/chat-management/${record.id}`} />
        </>
      )),
    ],
    [t, renderUsers],
  );

  return (
    <>
      <Table
        scroll={{ x: 500 }}
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
      />
    </>
  );
};

export default ChatList;
