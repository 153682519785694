import React from 'react';

import { useEditPage } from '../../../../hooks/useEditPage';

import {
  findOne,
  create,
  update,
} from '../../../../services/taxonomies/vehicle-types';
import EditVehicleTypeForm from '../../../../components/admin/taxonomies/vehicleTypes/EditVehicleTypeForm';
import AppLoader from '../../../../components/auth/AppLoader';

const initial = {};

const EditVehicleType = () => {
  const [type, handleSubmit, handleCancel, errors, loading] = useEditPage({
    initial,
    existent: findOne,
    goBackPath: '/admin/taxonomies/vehicle-types',
    onCreate: create,
    onUpdate: update,
  });

  return (
    <>
      <div className={`loaderOverlay ${loading ? 'active' : ''}`}>
        <AppLoader />
      </div>
      {type !== undefined && (
        <EditVehicleTypeForm
          type={type}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          errors={errors}
        />
      )}
    </>
  );
};

export default EditVehicleType;
