import { get, getWithParams } from '../../helpers/api';

export const SERVICE_URI = '/backend';
export const BASE_URI = '/api/v1/vehicle';

export const getTimeline = (timelineSearchRequest) =>
  getWithParams(`${SERVICE_URI}${BASE_URI}/timeline`, timelineSearchRequest);

export const getCrossing = (crossingSearchRequest) =>
  getWithParams(`${SERVICE_URI}${BASE_URI}/crossing`, crossingSearchRequest);

export const getOwnerCrossings = (vinCode) =>
  get(`${SERVICE_URI}${BASE_URI}/timeline/owner/get/${vinCode}`);
