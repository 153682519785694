import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@ant-design/pro-layout';
import { Form, Input, InputNumber, Row, Col, Space, Select } from 'antd';

import SaveButton from '../../buttons/SaveButton';
import CancelButton from '../../buttons/BackButton';

import useFormErrors from '../../../hooks/useFormErrors';

const directions = {
  content: [
    { id: 'IN', code: 'IN', name: 'INTRARE' },
    { id: 'OUT', code: 'OUT', name: 'IEȘIRE' },
  ],
};

const EditCustomsTrackForm = ({ track, onSubmit, onCancel, errors }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  useFormErrors(form, errors);

  useEffect(() => {
    form.setFieldsValue(track);
  }, [track, form]);

  return (
    <>
      <PageHeader title={t('entity.admin.customsTrack._singular')} />
      <Form
        form={form}
        layout="vertical"
        initialValues={track}
        onFinish={onSubmit}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label={t('entity.admin.customsTrack.code')} name="code">
              <InputNumber min={1} disabled={track.deleted} />
            </Form.Item>

            <Form.Item label={t('entity.admin.customsTrack.name')} name="name">
              <Input disabled={track.deleted} />
            </Form.Item>

            <Form.Item
              label={t('entity.admin.customsTrack.direction')}
              name="direction"
            >
              <Select
                allowClear
                disabled={track.deleted}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) !==
                  -1
                }
              >
                {directions.content.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {`${option.name}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Space>
            <SaveButton disabled={track.deleted} />
            <CancelButton onCancel={onCancel} />
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default EditCustomsTrackForm;
