import { postFiles, downloadFile } from '../../../helpers/api';

const BASE_PATH = '/chat/api/v1/files';

export const uploadAttachments = async (files) => {
  if (files && files.length === 0) {
    return [];
  }
  const data = new FormData();
  files.forEach((file) => data.append('files', file, file.name));
  return postFiles(`${BASE_PATH}/`, data).then((res) => res.json());
};

export const downloadAttachment = async (messageId, fileName) =>
  downloadFile(`${BASE_PATH}/${messageId}/${fileName}?download-single`)
    .then((res) => res.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      a.remove();
    });

export const downloadAttachments = async (messageId) =>
  downloadFile(`${BASE_PATH}/${messageId}?download`)
    .then((res) => res.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
