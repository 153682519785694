import { genericService } from '../../../helpers/api';

export const SERVICE_URI = '/users';
export const BASE_URI = '/v1/security-cameras';
export const BASE_URI_UUID = '/v1/security-cameras/uuid';

export const { findAll, findOne, create, update, remove } = genericService(
  SERVICE_URI,
  BASE_URI,
);

export const { generate } = genericService(SERVICE_URI, BASE_URI_UUID);
