import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useEditPage } from '../../../hooks/useEditPage';
import {
  findOne,
  setDecision,
} from '../../../services/lucrative/special-services-request';
import AppLoader from '../../../components/auth/AppLoader';
import EditSpecialServiceRequestForm from '../../../components/lucrative/special-services/EditSpecialServiceRequestForm';
import SERVICE_REQUEST_STATUS from '../../../enums/SpecialServiceRequestStatus';

const initial = {};

const SpecialServiceRequest = () => {
  const history = useHistory();
  const [service, handleSubmit, , errors, loading, reload] = useEditPage({
    initial,
    existent: findOne,
    pushBackOnError: false,
    onUpdate: setDecision,
  });

  const handleCancel = useCallback(() => {
    history.push('/lucrative/special-services-requests');
  }, [history]);

  return (
    <>
      <div className={`loaderOverlay ${loading ? 'active' : ''}`}>
        <AppLoader />
      </div>
      {service !== undefined && (
        <EditSpecialServiceRequestForm
          service={{ ...service, ...service?.decision }}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          errors={errors}
          disabled={service?.status !== SERVICE_REQUEST_STATUS.APPROVED}
          reload={reload}
        />
      )}
    </>
  );
};

export default SpecialServiceRequest;
