import React from 'react';
import { useEditPage } from '../../../hooks/useEditPage';
import { findOne, create, update } from '../../../services/admin/users/roles';
import EditRoleForm from '../../../components/admin/users/EditRoleForm';
import AppLoader from '../../../components/auth/AppLoader';

const initial = {};

const EditRole = () => {
  const [role, handleSubmit, handleCancel, , loading] = useEditPage({
    initial,
    existent: findOne,
    goBackPath: `/admin/users/${'roles'}`,
    onCreate: create,
    onUpdate: update,
  });

  return (
    <>
      <div className={`loaderOverlay ${loading ? 'active' : ''}`}>
        <AppLoader />
      </div>
      {role !== undefined && (
        <EditRoleForm
          role={role}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </>
  );
};

export default EditRole;
