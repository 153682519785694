import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import VehicleCategoryTravelersList from '../../../../components/admin/taxonomies/vehicleCategoryTraveler/VehicleCategoryTravelersList';

const VehicleCategoryTravelers = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="vehicleCategoriesTraveler">
      <Tabs.TabPane
        tab={t('entity.admin.fuelTypes._plural')}
        key="vehicleCategoriesTraveler"
      >
        <VehicleCategoryTravelersList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default VehicleCategoryTravelers;
