import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import MappingSvmPostToWeighingPostsList from '../../../../components/admin/taxonomies/mappingSvmPostToWeighingPost/MappingSvmPostToWeighingPostsList';

const MappingSvmPostToWeighingPosts = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="mappingSvmPostToWeighingPost">
      <Tabs.TabPane
        tab={t('entity.admin.mappingSvmPostToWeighingPost._plural')}
        key="mappingSvmPostToWeighingPost"
      >
        <MappingSvmPostToWeighingPostsList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default MappingSvmPostToWeighingPosts;
