import { get } from '../../../helpers/api';

export const SERVICE_URI = '/mconnect-asd-old-vinieta';
export const BASE_URI = '/api/v1';

export const getVignetteByVehicleRegistrationNumber = (regNr, transactionId) =>
  get(`${SERVICE_URI}${BASE_URI}/vignette/by-reg-nr/${regNr}/${transactionId}`);

export const getUncoveredPeriodByVehicleRegistrationNumber = (regNr) =>
  get(`${SERVICE_URI}${BASE_URI}/uncovered-period/by-reg-nr/${regNr}`);
