import React, { useCallback, useMemo, useState, useContext } from 'react';

import {
  Form,
  Row,
  Col,
  Space,
  Divider,
  Button,
  Input,
  Table,
  notification,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { FormContextProvider } from '../../hooks/useFormContext';
import Column from '../../helpers/Columns';
import {
  getOrganizationByIdno,
  getPersonByIdnp,
} from '../../services/admin/web-services/sfs-organizatii';
import AuthContext, { hasPermission } from '../auth';
import AppLoader from '../auth/AppLoader';
import { useTableScroll } from '../../hooks/useTableScroll';
import AvatarComponent from '../AvatarComponent';

const SFSOrganizatiiForm = () => {
  const [form] = Form.useForm();
  const [organization, setOrganization] = useState(null);
  const [person, setPerson] = useState(null);
  const [loadingLoader, setLoadingLoader] = useState(false);
  const { user } = useContext(AuthContext);
  const { permissions } = user;

  useTableScroll();
  const columns = useMemo(
    () => [
      {
        title: 'IDNO',
        dataIndex: 'idno',
        key: 'idno',
      },
      {
        title: 'Denumire',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Denumire prescurtată',
        dataIndex: 'shortName',
        key: 'shortName',
      },
      Column.date('registrationDate', 'Data inregistrare', {
        width: 250,
      }),
      {
        title: 'Regiune',
        dataIndex: 'region',
        key: 'region',
      },
      {
        title: 'Localitate',
        dataIndex: 'locality',
        key: 'locality',
      },
      {
        title: 'Strada',
        dataIndex: 'street',
        key: 'street',
      },
      {
        title: 'Casa',
        dataIndex: 'house',
        key: 'house',
      },
      {
        title: 'Bloc',
        dataIndex: 'block',
        key: 'block',
      },
    ],
    [],
  );

  const columnsPerson = useMemo(
    () => [
      {
        title: 'IDNP',
        dataIndex: 'idnp',
        key: 'idnp',
      },
      {
        title: 'Nume',
        dataIndex: 'lastName',
        key: 'lastName',
      },
      {
        title: 'Prenume',
        dataIndex: 'firstName',
        key: 'firstName',
      },
      {
        title: 'Serie document',
        dataIndex: 'docSeries',
        key: 'docSeries',
      },
      {
        title: 'Număr document',
        dataIndex: 'docNumber',
        key: 'docNumber',
      },
      Column.date('docIssueDate', 'Data emitere', {
        width: 250,
      }),
      Column.date('docExpirationDate', 'Data expirare', {
        width: 250,
      }),
      {
        title: 'Emis de',
        dataIndex: 'docIssuedBy',
        key: 'docIssuedBy',
      },
      {
        title: 'Imagine',
        key: 'photo',
        width: 100,
        dataIndex: 'photo',
        render: (row) => {
          return row ? (
            <AvatarComponent base64={row} size={100} preview radius={0} />
          ) : (
            <span>Imaginea lipseste</span>
          );
        },
      },
    ],
    [],
  );

  const handleSearchOrganizationByIDNO = useCallback(() => {
    if (form.getFieldValue(['idno'])) {
      setPerson(null);
      setLoadingLoader(true);
      getOrganizationByIdno(form.getFieldValue(['idno']))
        .then((org) => {
          setLoadingLoader(false);
          if (!org || !org.idno) {
            setLoadingLoader(false);
            notification.warning({ message: 'Nu au fost găsite date' });
            return;
          }
          setOrganization([org]);
        })
        .catch(() => {
          setLoadingLoader(false);
          notification.error({
            message: 'Eroare la căutarea datelor.',
          });
        });
    } else {
      notification.error({ message: 'Introduceți IDNO' });
    }
  }, [form]);

  const handleSearchPersonByIDNP = useCallback(() => {
    if (form.getFieldValue(['idnp'])) {
      setOrganization(null);
      setLoadingLoader(true);
      getPersonByIdnp(form.getFieldValue(['idnp']))
        .then((pers) => {
          setLoadingLoader(false);
          if (!pers || !pers.idnp) {
            setLoadingLoader(false);
            notification.warning({ message: 'Nu au fost găsite date' });
            return;
          }
          setPerson([pers]);
        })
        .catch(() => {
          setLoadingLoader(false);
          notification.error({
            message: 'Eroare la căutarea datelor.',
          });
        });
    } else {
      notification.error({ message: 'Introduceți IDNP' });
    }
  }, [form]);

  return (
    <>
      <div className={`loaderOverlay ${loadingLoader ? 'active' : ''}`}>
        <AppLoader />
      </div>

      <FormContextProvider form={form}>
        <Form form={form} layout="vertical">
          {hasPermission(permissions, 'GET_ORGANIZATION_SFS_WS') && (
            <>
              <Divider plain>Căutare organizație in baza IDNO</Divider>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
                <Col xs={24} sm={24} md={10} lg={8} xl={8}>
                  <Form.Item
                    label="IDNO"
                    name="idno"
                    rules={[
                      {
                        pattern: new RegExp('^[0-9]*$'),
                        message: 'Format greșit',
                      },
                    ]}
                  >
                    <Input maxLength={13} />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item>
                <Space>
                  <Button
                    type="primary"
                    onClick={() => handleSearchOrganizationByIDNO()}
                    icon={<SearchOutlined />}
                  >
                    Căutare
                  </Button>
                </Space>
              </Form.Item>
            </>
          )}
          {hasPermission(permissions, 'GET_PERSON_SFS_WS') && (
            <>
              <Divider plain>Căutare persoana in baza IDNP-ului</Divider>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
                <Col xs={24} sm={24} md={10} lg={8} xl={8}>
                  <Form.Item
                    label="IDNP"
                    name="idnp"
                    rules={[
                      {
                        pattern: new RegExp('^[0-9]*$'),
                        message: 'Format greșit',
                      },
                    ]}
                  >
                    <Input maxLength={20} />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item>
                <Space>
                  <Button
                    type="primary"
                    onClick={() => handleSearchPersonByIDNP()}
                    icon={<SearchOutlined />}
                  >
                    Căutare
                  </Button>
                </Space>
              </Form.Item>
            </>
          )}
          {Array.isArray(organization) && organization.length > 0 && (
            <>
              <Divider plain>Rezultat căutare organizație</Divider>
              <Table
                columns={columns}
                dataSource={organization}
                pagination={false}
                rowKey="idno"
                size="small"
              />
            </>
          )}
          {Array.isArray(person) && person.length > 0 && (
            <>
              <Divider plain>Rezultat căutare persoana</Divider>
              <Table
                columns={columnsPerson}
                dataSource={person}
                pagination={false}
                rowKey="idnp"
                size="small"
              />
            </>
          )}
        </Form>
      </FormContextProvider>
    </>
  );
};

export default SFSOrganizatiiForm;
