import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@ant-design/pro-layout';
import { Form, Input, Row, Col, Space } from 'antd';

import SaveButton from '../../buttons/SaveButton';
import CancelButton from '../../buttons/BackButton';
import Column from '../../../helpers/Columns';
import TableFormItemWrapper from '../../TableFormItemWrapper';
import useDatasource from '../../../hooks/useDatasource';

import useFormErrors from '../../../hooks/useFormErrors';
import { findAll as findAllCustomsPosts } from '../../../services/admin/users/customs-posts';
import { useTableScroll } from '../../../hooks/useTableScroll';
import AppLoader from '../../auth/AppLoader';
import { maxLengthValidator } from '../../../services/utils/validator/Validator';

const EditCustomsOfficeForm = ({ office, onSubmit, onCancel, errors }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [loadingLoader, setLoadingLoader] = useState(false);
  useTableScroll();
  const { loading, handleChange, content } = useDatasource((criterias) =>
    findAllCustomsPosts({ ...criterias, pageSize: 500 }),
  );

  const handleFinish = (value) => {
    const includePosts = form.getFieldValue('posts');
    let deletePosts = [];

    if (office.id) {
      if (includePosts && office.id) {
        const allCurentPostsIDs = content
          .filter((el) => el.officeId === office.id)
          .map((el) => el.id);

        deletePosts = allCurentPostsIDs.filter(
          (el) => !includePosts.includes(el),
        );
      }
    }

    const valueToSubmit = { ...value };
    valueToSubmit.includePosts = includePosts;
    valueToSubmit.deletePosts = deletePosts;

    setLoadingLoader(true);
    onSubmit(valueToSubmit).finally(() => setLoadingLoader(false));
  };
  useFormErrors(form, errors);

  useEffect(() => {
    form.setFieldsValue(office);
  }, [office, form]);

  const postColumns = useMemo(
    () => [
      Column.text('newCode', t('entity.admin.customsPost.code'), {
        width: 100,
        sort: (prev, next) => {
          return prev?.newCode.localeCompare(next?.newCode);
        },
      }),
      Column.text('name', t('entity.admin.customsPost.name'), {
        width: 300,
        sort: (prev, next) => {
          return prev?.name.localeCompare(next?.name);
        },
      }),
      Column.bool('deleted', t('entity._.deleted._'), {
        width: 100,
        inverted: true,
        sort: false,
        labels: [
          t('table.filter.all'),
          t('entity._.deleted.true'),
          t('entity._.deleted.false'),
          t('entity._.deleted.unknown'),
        ],
      }),
    ],
    [t],
  );

  return (
    <>
      <div className={`loaderOverlay ${loadingLoader ? 'active' : ''}`}>
        <AppLoader />
      </div>
      <PageHeader title={t('entity.admin.customsOffice._singular')} />
      <Form
        form={form}
        layout="vertical"
        initialValues={office}
        onFinish={handleFinish}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
          <Col xs={24} sm={24} md={10} lg={8} xl={8}>
            <Form.Item
              label={t('entity.admin.customsOffice.code')}
              name="code"
              rules={[
                {
                  required: true,
                  message: 'Câmpul este obligatoriu',
                },
              ]}
            >
              <Input
                min={1}
                disabled={office.deleted}
                onChange={(e) => {
                  form.setFieldsValue({
                    code: maxLengthValidator(e.target.value, 10),
                  });
                }}
              />
            </Form.Item>

            <Form.Item
              label={t('entity.admin.customsOffice.newCode')}
              name="newCode"
              rules={[
                {
                  required: true,
                  message: 'Câmpul este obligatoriu',
                },
              ]}
            >
              <Input
                disabled={office.deleted}
                onChange={(e) => {
                  form.setFieldsValue({
                    newCode: maxLengthValidator(e.target.value, 10),
                  });
                }}
              />
            </Form.Item>

            <Form.Item label={t('entity.admin.customsOffice.name')} name="name">
              <Input
                disabled={office.deleted}
                onChange={(e) => {
                  form.setFieldsValue({
                    name: maxLengthValidator(e.target.value, 400),
                  });
                }}
              />
            </Form.Item>
            <Form.Item>
              <Space>
                <SaveButton disabled={office.deleted} />
                <CancelButton onCancel={onCancel} />
              </Space>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={10} lg={16} xl={16}>
            <span className="ant-page-header-heading-title">
              {t('entity.admin.customsPost._plural')}
            </span>
            <Form.Item name="posts" noStyle>
              <TableFormItemWrapper
                values={office?.customsPosts?.map((el) => el?.id)}
                columns={postColumns}
                rowKey="id"
                pagination={false}
                loading={loading}
                dataSource={content}
                onTableChange={handleChange}
                scroll={{ y: 580 }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default EditCustomsOfficeForm;
