import React from 'react';
import notFoundImage from '../assets/404.png';

const NotFound = () => (
  <div style={{ textAlign: 'center' }}>
    <img src={notFoundImage} alt="Page not found" />
  </div>
);

export default NotFound;
