import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Divider, Empty, notification } from 'antd';
import { Picker } from 'emoji-mart-lite';
import TextArea from 'antd/lib/input/TextArea';
import { useClickOutside } from '../../../hooks/useClickOutside';
import Icon from '../../Icon';
import AuthContext from '../../auth';
import MessageTypes from '../enums/MessageTypes';
import ChatUploader from './ChatUploader';
import { downloadAttachments } from '../chat-service/attachment-service';
import UsersList from '../UsersList';
import GroupsList from '../GroupsList';
import EntityTypes from '../enums/EntityTypes';
import 'emoji-mart-lite/css/emoji-mart.css';

const ChatFooter = ({
  target,
  onSend = () => {},
  isMenuVisible,
  messageMore,
  onRemoveMore = () => {},
  users = [],
  groups = [],
}) => {
  const { user } = useContext(AuthContext);
  const [inputValue, setInputValue] = useState('');
  const [emojiPicker, setEmojiPicker] = useState(false);
  const [replyMessage, setReplyMessage] = useState(null);
  const [redirectChatIds, setRedirectedChatIds] = useState([]);

  const emojiTranslate = {
    search: 'Căutare',
    categories: {
      search: 'Rezultatele căutării',
      recent: 'Folosite frecvent',
      people: 'Emoticoane și oameni',
      nature: 'Animale și natură',
      foods: 'Mâncare și băutură',
      activity: 'Activitate',
      places: 'Călătorii și locuri',
      objects: 'Obiecte',
      symbols: 'Simboluri',
      flags: 'Steaguri',
    },
  };

  const handleEmojiPicker = () => {
    setEmojiPicker(false);
  };
  const outside = useClickOutside(handleEmojiPicker, emojiPicker);
  const handleEmojiItem = (e) => {
    if (setEmojiPicker.current && setEmojiPicker.current.contains(e.target)) {
      return;
    }
    setEmojiPicker((prev) => !prev);
  };
  // eslint-disable-next-line
  const handleSelectEmojy = (emoji) => {
    setInputValue((prev) => prev.concat(emoji));
  };

  // is automatically triggered when delete in more is pressed
  const handleDelete = useCallback(
    (messageDTO) => {
      if (!!onSend && typeof onSend === 'function') {
        onSend({ ...messageDTO, messageType: MessageTypes.DELETE });
      }
    },
    [onSend],
  );

  const handleRemoveMore = () => {
    setReplyMessage(null);
    setRedirectedChatIds([]);
    onRemoveMore();
  };

  const handleDownload = (message) => {
    downloadAttachments(message.id).catch(() => {
      notification.error({ message: 'Fișierele nu pot fi descărcate' });
    });
  };

  useEffect(() => {
    if (messageMore) {
      const { messageType, message } = messageMore;
      if (messageType === MessageTypes.DELETE) {
        handleDelete(message);
      } else if (messageType === MessageTypes.REPLY) {
        setInputValue('');
        setReplyMessage(message);
      } else if (messageType === MessageTypes.DOWNLOAD) {
        handleDownload(message);
      } else {
        setInputValue(message.messageBody);
      }
    }
    // eslint-disable-next-line
  }, [messageMore]);

  const handleSendMessage = useCallback(() => {
    setReplyMessage(null);
    if (!inputValue) {
      return;
    }
    const messageDTO = {
      id:
        messageMore?.messageType !== MessageTypes.REPLY
          ? messageMore?.message?.id
          : null,
      sourceId: user.id,
      senderFullName: user.fullname,
      chatId: target.id,
      messageBody: inputValue,
      messageType: messageMore?.messageType || MessageTypes.TEXT,
      targetType: target.type,
      reply:
        messageMore?.messageType === MessageTypes.REPLY
          ? messageMore?.message
          : null,
    };

    setInputValue('');
    if (!!onSend && typeof onSend === 'function') {
      onSend(messageDTO);
    }
  }, [
    target,
    inputValue,
    onSend,
    user.id,
    user.fullname,
    messageMore?.messageType,
    messageMore?.message,
  ]);

  const handleSendAttachments = useCallback(
    (messageAttachment) => {
      if (!messageAttachment) {
        return;
      }
      const messageDTO = {
        ...messageAttachment,
        chatId: target?.id,
        senderFullName: user.fullname,
        targetType: target?.type,
      };

      setInputValue('');
      if (!!onSend && typeof onSend === 'function') {
        onSend(messageDTO);
      }
    },
    [onSend, target?.id, target?.type, user.fullname],
  );

  const handleKeyPress = (event) => {
    const nonWhiteSpaceRegex = /[^\s]/;

    if (event.key === 'Enter') {
      if (event.shiftKey) {
        // Prevent the default behavior of the Enter key
        event.preventDefault();

        // Insert a newline character at the current cursor position
        const cursorPos = event.target.selectionStart;
        const newValue = `${inputValue.substring(
          0,
          cursorPos,
        )} \n ${inputValue.substring(cursorPos)}`;

        setInputValue(newValue);

        const newCursorPos = cursorPos + 1;
        event.target.setSelectionRange(newCursorPos, newCursorPos);
      } else {
        event.preventDefault();
        // eslint-disable-next-line no-lonely-if
        if (inputValue?.length > 0 && nonWhiteSpaceRegex.test(inputValue)) {
          handleSendMessage();
          setInputValue('');
        }
      }
    }
  };

  const replyChatMessageFunc = () => {
    if (replyMessage?.messageBody) {
      if (replyMessage?.messageBody?.length > 45) {
        return `${replyMessage?.messageBody?.slice(0, 45)}...`;
      }
      return replyMessage?.messageBody;
    }
    if (replyMessage?.attachments?.length === 1) {
      return replyMessage?.attachments[0]?.fileName?.slice(0, 45);
    }

    if (replyMessage?.attachments?.length > 1) {
      return `Răspundeți la ${replyMessage?.attachments?.length} fișiere`;
    }

    return null;
  };

  const handleRedirect = useCallback(() => {
    redirectChatIds?.forEach((id) => {
      const messageDTO = {
        ...messageMore?.message,
        id:
          messageMore?.messageType !== MessageTypes.FORWARD
            ? messageMore?.message?.id
            : null,
        sourceId: user.id,
        senderFullName: user.fullname,
        chatId: id,
        messageType: MessageTypes.FORWARD,
        targetType: users.map((el) => el.id).includes(id)
          ? EntityTypes.USER
          : EntityTypes.GROUP,
        reply: messageMore?.message,
      };

      setInputValue('');
      if (!!onSend && typeof onSend === 'function') {
        onSend(messageDTO);
      }
      setRedirectedChatIds([]);
    });
  }, [
    redirectChatIds,
    messageMore?.message,
    messageMore?.messageType,
    user.id,
    user.fullname,
    users,
    onSend,
  ]);

  return (
    <>
      <div
        className={`chat__participants-overlay ${
          messageMore?.messageType === MessageTypes.FORWARD ? 'active' : ''
        }`}
      >
        <div className="chat__participants-popup chat__forward">
          <button
            type="button"
            className="chat__close chat__settings-close"
            onClick={handleRemoveMore}
          >
            <Icon name="close" width="15" height="15" />
          </button>
          <Divider>Utilizatori</Divider>
          {users.length === 0 ? (
            <Empty description="Lista de utilizatori este goală" />
          ) : (
            <div className="chat__forward-participants">
              <UsersList
                onSelect={(e) =>
                  setRedirectedChatIds((prev) => {
                    if (prev.includes(e.id)) {
                      return prev.filter((el) => el !== e.id);
                    }
                    return [e.id, ...prev];
                  })
                }
                selectedEntityIds={redirectChatIds}
                content={users}
              />
            </div>
          )}
          <Divider>Grupuri</Divider>
          {groups.length === 0 ? (
            <Empty description="Lista de grupuri este goală" />
          ) : null}
          <div className="chat__forward-participants">
            <GroupsList
              onSelect={(e) =>
                setRedirectedChatIds((prev) => {
                  if (prev.includes(e.id)) {
                    return prev.filter((el) => el !== e.id);
                  }
                  return [e.id, ...prev];
                })
              }
              selectedEntityIds={redirectChatIds}
              content={groups}
              canCreate={false}
            />
          </div>
          <div className="chat__settings-confirmation">
            <Button
              style={{ marginLeft: 'auto' }}
              type="primary"
              onClick={handleRedirect}
            >
              Redirecționează
            </Button>
          </div>
        </div>
      </div>
      <footer
        className={`chat__footer ${isMenuVisible ? 'small' : ''} selected`}
      >
        <div
          className={`chat__emoji-picker ${emojiPicker ? 'active' : ''}`}
          ref={outside}
        >
          <Picker
            className="chat__picker-component"
            i18n={emojiTranslate}
            previewPosition="none"
            onClick={(emojy) => handleSelectEmojy(emojy?.native)}
          />
        </div>
        <div className="chat__footer-icons">
          <ChatUploader onUpload={handleSendAttachments} />
          <button
            type="button"
            className="chat__footer-emoji"
            onClick={handleEmojiItem}
            style={{ pointerEvents: emojiPicker ? 'none' : 'initial' }}
          >
            <Icon name="emoji" className="chat__footer-emoji--icon" />
          </button>
        </div>
        <div className="chat__footer-area--container">
          {replyMessage ? (
            <div className="chat__footer-reply">
              <div className="chat__footer-reply--left">
                <Icon className="chat__footer-reply--icon" name="chatReply" />
                <div className="chat__footer-reply--content">
                  <div className="chat__footer-reply--user">Răspunde la:</div>
                  <div className="chat__footer-reply--message">
                    {replyChatMessageFunc()}
                  </div>
                </div>
              </div>
              <button
                className="chat__footer-reply--close"
                type="button"
                onClick={handleRemoveMore}
              >
                <Icon name="close" />
              </button>
            </div>
          ) : null}

          <TextArea
            autoSize
            placeholder="Scrie un mesaj"
            resize={false}
            style={{
              height: 'unset',
              minHeight: '100%',
            }}
            type="pure"
            onChange={(e) => setInputValue(e.target.value)}
            value={inputValue}
            onKeyPress={handleKeyPress}
          />
        </div>
        <Button
          className="chat__footer-send"
          type="primary"
          onClick={handleSendMessage}
        >
          <Icon name="chatSend" className="chat__footer-send--icon" />
        </Button>
      </footer>
    </>
  );
};
export default ChatFooter;
