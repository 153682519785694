import { get } from '../../../helpers/api';

export const SERVICE_URI = '/mconnect-asd-vinieta';
export const BASE_URI = '/api/v1';

export const getVignetteByVehicleVIN = (vin, transactionId) =>
  get(`${SERVICE_URI}${BASE_URI}/vignette/by-vin/${vin}/${transactionId}`);

export const getVignettesByVehicleVIN = (vin, fromDate, toDate) =>
  get(
    `${SERVICE_URI}${BASE_URI}/vignettes/by-vin/${vin}/${fromDate}/${toDate}`,
  );

export const getVignetteByVehicleRegistrationNumber = (regNr, transactionId) =>
  get(`${SERVICE_URI}${BASE_URI}/vignette/by-reg-nr/${regNr}/${transactionId}`);

export const getVignettesByVehicleRegistrationNumber = (
  regNr,
  fromDate,
  toDate,
) =>
  get(
    `${SERVICE_URI}${BASE_URI}/vignettes/by-reg-nr/${regNr}/${fromDate}/${toDate}`,
  );

export const getUncoveredPeriodByVehicleVIN = (vin) =>
  get(`${SERVICE_URI}${BASE_URI}/uncovered-period/by-vin/${vin}`);

export const getUncoveredPeriodByVehicleRegistrationNumber = (regNr) =>
  get(`${SERVICE_URI}${BASE_URI}/uncovered-period/by-reg-nr/${regNr}`);
