import React, { useEffect, useContext, useState } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { Row, Col, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import LoginForm from '../components/auth/LoginForm';
import TitleLogo from '../components/TitleLogo';
import { mpassUserBySession, getCurrentUserDetails2 } from '../services/auth';
import AuthContext, { hasPermission } from '../components/auth';
import AppLoader from '../components/auth/AppLoader';
import UserFunctions from '../enums/UserFunctions';

const Login = () => {
  const { user, setUser } = useContext(AuthContext);
  const { permissions } = user || { permissions: [] };
  const [loadingLoader, setLoadingLoader] = useState(false);
  const { sessionIndex } = { ...useParams() };
  const history = useHistory();
  const { t } = useTranslation();

  const location = useLocation();

  useEffect(() => {
    const { search, hash } = location;
    if (search === '?error' && !hash) {
      notification.error({
        message: 'A apărut o eroare la logare',
      });
    } else if (search === '?invalid' && hash) {
      notification.error({
        message: t(`auth.${hash.replace('#', '')}`),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user && user.id !== undefined && user.id !== null) {
      if (hasPermission(permissions, 'SEARCH_TRANSACTIONS')) {
        history.push('/home');
      } else if (
        hasPermission(permissions, 'RISK_MANAGEMENT') &&
        user?.customsPost?.allowSARA &&
        (user?.posts?.some((item) => item.allowSARA) ||
          user?.customsPost?.allowSARA ||
          user?.function === UserFunctions.CENTRAL ||
          user?.function === UserFunctions.OFFICE_HEAD)
      ) {
        history.push('/lucrative/risk-managemen');
      } else if (hasPermission(permissions, 'SEARCH_OPERATIONS')) {
        history.push('/work-history');
      } else {
        history.push('/admin/guides');
      }
    } else {
      history.push('/');
    }
    // eslint-disable-next-line
  }, [user?.id]);

  useEffect(() => {
    if (sessionIndex) {
      setLoadingLoader(true);
      mpassUserBySession(sessionIndex)
        .then((result) => {
          const now = new Date();
          now.setTime(now.getTime() + 86400 * 1000);
          document.cookie = `sid=${result};path=/;expires=${now.toUTCString()}`;
          getCurrentUserDetails2(result).then((details) => {
            setUser({ ...user, ...details });
            history.push('/home');
          });
        })
        .catch((error) => {
          if (error.status === 401) {
            history.push('/');
          } else
            notification.error({
              message:
                'A apărut o eroare la autentificarea MPass. Contactați administratorul.',
            });
        })
        .finally(() => {
          setLoadingLoader(false);
        });
    }
  }, [history, sessionIndex, user, setUser]);

  return (
    <>
      <div className={`loaderOverlay ${loadingLoader ? 'active' : ''}`}>
        <AppLoader />
      </div>
      <TitleLogo />
      <Row>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 16, offset: 4 }}
          md={{ span: 12, offset: 6 }}
          lg={{ span: 8, offset: 8 }}
          xl={{ span: 6, offset: 9 }}
        >
          <LoginForm />
        </Col>
      </Row>
    </>
  );
};

export default Login;
