import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  notification,
  Radio,
  Row,
  Space,
  Typography,
} from 'antd';
import { RightCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { FormContextProvider } from '../../hooks/useFormContext';
import { addScan } from '../../services/admin/web-services/scan-transactions';
import useFormErrors from '../../hooks/useFormErrors';
import {
  confirmStep,
  enableSteps,
} from '../../services/transactions/transactions';
import AuthContext, { hasPermission } from '../auth';
import AppLoader from '../auth/AppLoader';
import CancelButton from '../buttons/BackButton';
import TransactionStatus from '../../enums/TransactionStatus';
import UserFunctions from '../../enums/UserFunctions';

const { Text } = Typography;
const { TextArea } = Input;

const statusValues = {
  Compliant: 0,
  Non_Compliant: 1,
};

const scanResultValues = {
  NoSuspicions: 0,
  Suspicions: 1,
};

const scanBasisValues = {
  OwnRiskAnalysis: 0,
  OperationalPlan: 1,
  AR_Products: 2,
  RandomSelection: 3,
};

const Scan = ({
  transaction,
  errors,
  skipTab,
  readOnly,
  reload,
  moveNext = () => {},
  moveBack = () => {},
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { permissions } = user;
  const [isUserScannerOperator, setIsUserScannerOperator] = useState(false);
  const [loadingLoader, setLoadingLoader] = useState(false);

  useEffect(() => {
    setIsUserScannerOperator(user?.function === 'SCANN_OPERATOR');
  }, [user?.function]);

  const [scanValue, setScanValue] = useState(0);
  const [scanResultValue, setScanResultValue] = useState(
    transaction?.scan?.scanResult
      ? scanResultValues[transaction.scan.scanResult]
      : 0,
  );
  const [statusValue, setStatusValue] = useState(0);
  const [productDisabled, setProductDisabled] = useState(
    transaction?.scan?.product ? transaction.scan.product : false,
  );
  const [driverDisabled, setDriverDisabled] = useState(
    transaction?.scan?.driver ? transaction.scan.driver : false,
  );
  const [passengerDisabled, setPassengerDisabled] = useState(
    transaction?.scan?.passenger ? transaction.scan.passenger : false,
  );
  const [routeDisabled, setRouteDisabled] = useState(
    transaction?.scan?.route ? transaction.scan.route : false,
  );
  const [utTypeDisabled, setUtTypeDisabled] = useState(
    transaction?.scan?.utType ? transaction.scan.utType : false,
  );
  const [utStateDisabled, setUtStateDisabled] = useState(
    transaction?.scan?.utState ? transaction.scan.utState : false,
  );
  const [productCompartmentDisabled, setProductCompartmentDisabled] = useState(
    transaction?.scan?.productCompartment
      ? transaction.scan.productCompartment
      : false,
  );
  const [crossingHistoryDisabled, setCrossingHistoryDisabled] = useState(
    transaction?.scan?.crossingHistory
      ? transaction.scan.crossingHistory
      : false,
  );
  const [fraudHistoryDisabled, setFraudHistoryDisabled] = useState(
    transaction?.scan?.fraudHistory ? transaction.scan.fraudHistory : false,
  );
  const [otherIndicatorsDisabled, setOtherIndicatorsDisabled] = useState(
    transaction?.scan?.otherIndicators
      ? transaction.scan.otherIndicators
      : false,
  );

  useFormErrors(form, errors);
  const scanNavigateWithoutSave = () => {
    moveNext();
  };

  useEffect(() => {
    const scan = {
      ...transaction.scan,
      status: transaction?.scan?.status
        ? statusValues[transaction.scan.status]
        : 0,
      scanResult: transaction?.scan?.scanResult
        ? scanResultValues[transaction.scan.scanResult]
        : 0,
      scanBasis: transaction?.scan?.scanBasis
        ? scanBasisValues[transaction.scan.scanBasis]
        : 0,
    };
    form.setFieldsValue(scan);
  }, [transaction, form]);

  const onChangeScan = (e) => {
    setScanValue(e.target.value);
  };

  const onChangeScanResult = (e) => {
    const formSuspicions = { suspicions: '' };
    form.setFieldsValue(formSuspicions);
    setScanResultValue(e.target.value);
  };

  const onChangeStatus = (e) => {
    setStatusValue(e.target.value);
  };

  const onChangeProduct = (e) => {
    const productDescription = { productDescription: '' };
    form.setFieldsValue(productDescription);
    setProductDisabled(e.target.checked);
  };

  const onChangeDriver = (e) => {
    const driverDescription = { driverDescription: '' };
    form.setFieldsValue(driverDescription);
    setDriverDisabled(e.target.checked);
  };

  const onChangePassenger = (e) => {
    const passengerDescription = { passengerDescription: '' };
    form.setFieldsValue(passengerDescription);
    setPassengerDisabled(e.target.checked);
  };

  const onChangeRoute = (e) => {
    const routeDescription = { routeDescription: '' };
    form.setFieldsValue(routeDescription);
    setRouteDisabled(e.target.checked);
  };

  const onChangeUtType = (e) => {
    const upTypeDescription = { upTypeDescription: '' };
    form.setFieldsValue(upTypeDescription);
    setUtTypeDisabled(e.target.checked);
  };

  const onChangeUtState = (e) => {
    const upStateDescription = { upStateDescription: '' };
    form.setFieldsValue(upStateDescription);
    setUtStateDisabled(e.target.checked);
  };

  const onChangeProductCompartment = (e) => {
    const productCompartmentDescription = { productCompartmentDescription: '' };
    form.setFieldsValue(productCompartmentDescription);
    setProductCompartmentDisabled(e.target.checked);
  };

  const onChangeCrossingHistory = (e) => {
    const crossingHistoryDescription = { crossingHistoryDescription: '' };
    form.setFieldsValue(crossingHistoryDescription);
    setCrossingHistoryDisabled(e.target.checked);
  };

  const onChangeFraudHistory = (e) => {
    const fraudHistoryDescription = { fraudHistoryDescription: '' };
    form.setFieldsValue(fraudHistoryDescription);
    setFraudHistoryDisabled(e.target.checked);
  };

  const onChangeOtherIndicators = (e) => {
    const otherIndicatorsDescription = { otherIndicatorsDescription: '' };
    form.setFieldsValue(otherIndicatorsDescription);
    setOtherIndicatorsDisabled(e.target.checked);
  };

  const confirmScan = useCallback(() => {
    setLoadingLoader(true);
    const step = isUserScannerOperator ? 'HAS_SCAN_OPERATOR' : 'HAS_SCAN';
    confirmStep(transaction.id, step)
      .then(() => {
        notification.success({
          message: t('actions.infirm'),
          duration: 3,
        });
        skipTab('#HAS_SCAN');
      })
      .catch(() => {
        notification.error({ message: t('actions.infirmErr') });
      })
      .finally(() => {
        setLoadingLoader(false);
        reload();
      });
  }, [t, transaction.id, skipTab, reload, isUserScannerOperator]);

  const handleSaveData = useCallback(() => {
    if (isUserScannerOperator && transaction.isScanConfirmedByOperator) {
      confirmScan();
      return;
    }
    // setLoadingLoader(true);
    if (form.getFieldsValue()) {
      const scan = {
        ...form.getFieldsValue(),
        transactionId: transaction.id,
        userFunction: user?.function,
      };
      addScan(scan)
        .then(() => {
          if (user?.function !== UserFunctions.SCANN_OPERATOR) {
            moveNext();
          }
        })
        .catch(() => {
          notification.error({
            message: t('actions.confirmErr'),
          });
        })
        .finally(() => {
          setLoadingLoader(false);
          reload();
        });
    }
  }, [
    t,
    confirmScan,
    form,
    transaction.id,
    transaction.isScanConfirmedByOperator,
    isUserScannerOperator,
    user?.function,
    reload,
    moveNext,
  ]);

  const handleEnableSteps = useCallback(() => {
    setLoadingLoader(true);
    enableSteps(transaction.id, 'HAS_SCAN')
      .catch(() => {
        notification.error({
          message: 'A apărut o eroare. Contactați administratorul',
        });
      })
      .finally(() => {
        setLoadingLoader(false);
        reload();
      });
  }, [reload, transaction.id]);

  return (
    <>
      <div className={`loaderOverlay ${loadingLoader ? 'active' : ''}`}>
        <AppLoader />
      </div>
      <div style={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
        {transaction?.isScanConfirmedByOperator && (
          <div
            style={{ display: 'inline-block', borderRadius: '10px' }}
            className="confirmed__message"
          >
            Datele au fost confirmate de operator scaner
          </div>
        )}
        {transaction?.status === TransactionStatus.PENDING ? (
          <Button
            disabled={
              (readOnly && user.function !== UserFunctions.SCANN_OPERATOR) ||
              transaction?.isScanConfirmedByOperator
            }
            onClick={handleEnableSteps}
            type="primary"
            danger={transaction.hasScan}
          >
            {!transaction.hasScan ? 'Inițiere control' : 'Anulare control'}
          </Button>
        ) : null}
      </div>
      <FormContextProvider form={form} initialValues={transaction.scan}>
        <Form form={form} layout="vertical" className="tab__scan">
          <Row>
            <Col
              xs={48}
              sm={48}
              md={48}
              lg={48}
              xl={24}
              xxl={24}
              style={{ paddingRight: 20 }}
            >
              <Row align="top">
                <Col span={16} style={{ paddingRight: 10 }}>
                  <Divider plain>
                    <Text strong>Codul Amendării</Text>
                  </Divider>
                </Col>
                <Col span={8} style={{ paddingRight: 10 }}>
                  <Divider plain>
                    <Text strong>Indicatori de risc legat de</Text>
                  </Divider>
                </Col>
              </Row>

              <Row align="top">
                <Col span={8} style={{ paddingRight: 10 }}>
                  <Form.Item
                    name="alcoholDrinks"
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Checkbox
                      disabled={
                        readOnly ||
                        !transaction.hasScan ||
                        isUserScannerOperator
                      }
                    >
                      Băuturi alcoolice
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ paddingRight: 10, paddingBottom: 15 }}>
                  <Form.Item
                    name="product"
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Checkbox
                      onChange={onChangeProduct}
                      disabled={
                        readOnly ||
                        !transaction.hasScan ||
                        isUserScannerOperator
                      }
                    >
                      Marfă
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ paddingRight: 10 }}>
                  <Form.Item name="productDescription">
                    <TextArea
                      showCount
                      maxLength={320}
                      rows={1}
                      placeholder="Descriere"
                      size="small"
                      disabled={!productDisabled || isUserScannerOperator}
                      style={{
                        textTransform: 'uppercase',
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row align="top">
                <Col span={8} style={{ paddingRight: 10 }}>
                  <Form.Item
                    name="tobaccoProduct"
                    valuePropName="checked"
                    initialValue={false}
                    style={{ paddingBottom: 15 }}
                  >
                    <Checkbox
                      disabled={
                        readOnly ||
                        !transaction.hasScan ||
                        isUserScannerOperator
                      }
                    >
                      Produs tutungerie
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ paddingRight: 10, paddingBottom: 15 }}>
                  <Form.Item
                    name="driver"
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Checkbox
                      onChange={onChangeDriver}
                      disabled={
                        readOnly ||
                        !transaction.hasScan ||
                        isUserScannerOperator
                      }
                    >
                      Șofer
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ paddingRight: 10 }}>
                  <Form.Item name="driverDescription">
                    <TextArea
                      showCount
                      maxLength={320}
                      rows={1}
                      placeholder="Descriere"
                      size="small"
                      disabled={!driverDisabled || isUserScannerOperator}
                      style={{
                        textTransform: 'uppercase',
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row align="top">
                <Col span={8} style={{ paddingRight: 10 }}>
                  <Form.Item
                    name="cites"
                    valuePropName="checked"
                    initialValue={false}
                    style={{ paddingBottom: 15 }}
                  >
                    <Checkbox
                      disabled={
                        readOnly ||
                        !transaction.hasScan ||
                        isUserScannerOperator
                      }
                    >
                      CITES
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ paddingRight: 10, paddingBottom: 15 }}>
                  <Form.Item
                    name="passenger"
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Checkbox
                      onChange={onChangePassenger}
                      disabled={
                        readOnly ||
                        !transaction.hasScan ||
                        isUserScannerOperator
                      }
                    >
                      Pasager
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ paddingRight: 10 }}>
                  <Form.Item name="passengerDescription">
                    <TextArea
                      showCount
                      maxLength={320}
                      rows={1}
                      placeholder="Descriere"
                      size="small"
                      disabled={!passengerDisabled || isUserScannerOperator}
                      style={{
                        textTransform: 'uppercase',
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8} style={{ paddingRight: 10 }}>
                  <Form.Item
                    name="currency"
                    valuePropName="checked"
                    initialValue={false}
                    style={{ paddingBottom: 15 }}
                  >
                    <Checkbox
                      disabled={
                        readOnly ||
                        !transaction.hasScan ||
                        isUserScannerOperator
                      }
                    >
                      Valută
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ paddingRight: 10, paddingBottom: 15 }}>
                  <Form.Item
                    name="route"
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Checkbox
                      onChange={onChangeRoute}
                      disabled={
                        readOnly ||
                        !transaction.hasScan ||
                        isUserScannerOperator
                      }
                    >
                      Itinerar
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ paddingRight: 10 }}>
                  <Form.Item name="routeDescription">
                    <TextArea
                      showCount
                      maxLength={320}
                      rows={1}
                      placeholder="Descriere"
                      size="small"
                      disabled={!routeDisabled || isUserScannerOperator}
                      style={{
                        textTransform: 'uppercase',
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8} style={{ paddingRight: 10 }}>
                  <Form.Item
                    name="drugs"
                    valuePropName="checked"
                    initialValue={false}
                    style={{ paddingBottom: 15 }}
                  >
                    <Checkbox
                      disabled={
                        readOnly ||
                        !transaction.hasScan ||
                        isUserScannerOperator
                      }
                    >
                      Droguri, precursori, anabolizante
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ paddingRight: 10, paddingBottom: 15 }}>
                  <Form.Item
                    name="utType"
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Checkbox
                      onChange={onChangeUtType}
                      disabled={
                        readOnly ||
                        !transaction.hasScan ||
                        isUserScannerOperator
                      }
                    >
                      Tipul UT
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ paddingRight: 10 }}>
                  <Form.Item name="upTypeDescription">
                    <TextArea
                      showCount
                      maxLength={320}
                      rows={1}
                      placeholder="Descriere"
                      size="small"
                      disabled={!utTypeDisabled || isUserScannerOperator}
                      style={{
                        textTransform: 'uppercase',
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8} style={{ paddingRight: 10 }}>
                  <Form.Item
                    name="brokeTaxRules"
                    valuePropName="checked"
                    initialValue={false}
                    style={{ paddingBottom: 15 }}
                  >
                    <Checkbox
                      disabled={
                        readOnly ||
                        !transaction.hasScan ||
                        isUserScannerOperator
                      }
                    >
                      Nerespectarea regulamentelor tarifare și netarifare
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ paddingRight: 10, paddingBottom: 15 }}>
                  <Form.Item
                    name="utState"
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Checkbox
                      onChange={onChangeUtState}
                      disabled={
                        readOnly ||
                        !transaction.hasScan ||
                        isUserScannerOperator
                      }
                    >
                      Stare UT
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ paddingRight: 10 }}>
                  <Form.Item name="upStateDescription">
                    <TextArea
                      showCount
                      maxLength={320}
                      rows={1}
                      placeholder="Descriere"
                      size="small"
                      disabled={!utStateDisabled || isUserScannerOperator}
                      style={{
                        textTransform: 'uppercase',
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                {' '}
                <Col span={8} style={{ paddingRight: 10 }}>
                  <Form.Item
                    name="brokeIntellectualRights"
                    valuePropName="checked"
                    initialValue={false}
                    style={{ paddingBottom: 15 }}
                  >
                    <Checkbox
                      disabled={
                        readOnly ||
                        !transaction.hasScan ||
                        isUserScannerOperator
                      }
                    >
                      Încălcarea drepturilor de proprietate intelectuală
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ paddingRight: 10, paddingBottom: 15 }}>
                  <Form.Item
                    name="productCompartment"
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Checkbox
                      onChange={onChangeProductCompartment}
                      disabled={
                        readOnly ||
                        !transaction.hasScan ||
                        isUserScannerOperator
                      }
                    >
                      Compartimentul Marfă
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ paddingRight: 10 }}>
                  <Form.Item name="productCompartmentDescription">
                    <TextArea
                      showCount
                      maxLength={320}
                      rows={1}
                      placeholder="Descriere"
                      size="small"
                      disabled={
                        !productCompartmentDisabled || isUserScannerOperator
                      }
                      style={{
                        textTransform: 'uppercase',
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8} style={{ paddingRight: 10 }}>
                  <Form.Item
                    name="nuclearMaterials"
                    valuePropName="checked"
                    initialValue={false}
                    style={{ paddingBottom: 15 }}
                  >
                    <Checkbox
                      disabled={
                        readOnly ||
                        !transaction.hasScan ||
                        isUserScannerOperator
                      }
                    >
                      Materiale radioactive și nucleare
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ paddingRight: 10, paddingBottom: 15 }}>
                  <Form.Item
                    name="crossingHistory"
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Checkbox
                      onChange={onChangeCrossingHistory}
                      disabled={
                        readOnly ||
                        !transaction.hasScan ||
                        isUserScannerOperator
                      }
                    >
                      Istoricul Traversărilor
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ paddingRight: 10 }}>
                  <Form.Item name="crossingHistoryDescription">
                    <TextArea
                      showCount
                      maxLength={320}
                      rows={1}
                      placeholder="Descriere"
                      size="small"
                      disabled={
                        !crossingHistoryDisabled || isUserScannerOperator
                      }
                      style={{
                        textTransform: 'uppercase',
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8} style={{ paddingRight: 10 }}>
                  <Form.Item
                    name="arms"
                    valuePropName="checked"
                    initialValue={false}
                    style={{ paddingBottom: 15 }}
                  >
                    <Checkbox
                      disabled={
                        readOnly ||
                        !transaction.hasScan ||
                        isUserScannerOperator
                      }
                    >
                      Arme, muniții și dublă destinație
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ paddingRight: 10, paddingBottom: 15 }}>
                  <Form.Item
                    name="fraudHistory"
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Checkbox
                      onChange={onChangeFraudHistory}
                      disabled={
                        readOnly ||
                        !transaction.hasScan ||
                        isUserScannerOperator
                      }
                    >
                      Istoricul Fraudării
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ paddingRight: 10 }}>
                  <Form.Item name="fraudHistoryDescription">
                    <TextArea
                      showCount
                      maxLength={320}
                      rows={1}
                      placeholder="Descriere"
                      size="small"
                      disabled={!fraudHistoryDisabled || isUserScannerOperator}
                      style={{
                        textTransform: 'uppercase',
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8} style={{ paddingRight: 10 }}>
                  <Form.Item
                    name="rubbish"
                    valuePropName="checked"
                    initialValue={false}
                    style={{ paddingBottom: 15 }}
                  >
                    <Checkbox
                      disabled={
                        readOnly ||
                        !transaction.hasScan ||
                        isUserScannerOperator
                      }
                    >
                      Deșeuri
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ paddingRight: 10, paddingBottom: 15 }}>
                  <Form.Item
                    name="otherIndicators"
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Checkbox
                      onChange={onChangeOtherIndicators}
                      disabled={
                        readOnly ||
                        !transaction.hasScan ||
                        isUserScannerOperator
                      }
                    >
                      Alți indicatori și descrierea acestora
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ paddingRight: 10 }}>
                  <Form.Item name="otherIndicatorsDescription">
                    <TextArea
                      showCount
                      maxLength={320}
                      rows={1}
                      placeholder="Descriere"
                      size="small"
                      disabled={
                        !otherIndicatorsDisabled || isUserScannerOperator
                      }
                      style={{
                        textTransform: 'uppercase',
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={10} style={{ paddingRight: 20 }}>
              <Divider plain>
                <Text strong>Temei pentru scanare</Text>
              </Divider>

              <Row>
                <Form.Item name="scanBasis">
                  <Radio.Group
                    onChange={onChangeScan}
                    value={scanValue}
                    disabled={
                      readOnly || !transaction.hasScan || isUserScannerOperator
                    }
                  >
                    <Space direction="vertical">
                      <Radio value={0}>Analiză de risc Propriu</Radio>
                      <Radio value={1}>Plan Operațional</Radio>
                      <Radio value={2}>
                        Produse de AR (Consemne, criterii de selecție, etc)
                      </Radio>
                      <Radio value={3}>Selecție Aleatorie</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Row>
            </Col>

            <Col span={14} style={{ paddingRight: 20 }}>
              <Divider plain>
                <Text strong>Rezultatul scanării</Text>
              </Divider>

              <Row>
                <Form.Item name="scanResult">
                  <Radio.Group
                    onChange={onChangeScanResult}
                    value={scanResultValue}
                    disabled={
                      transaction.isScanConfirmedByOperator ||
                      readOnly ||
                      !transaction.hasScan ||
                      !isUserScannerOperator
                    }
                  >
                    <Space direction="vertical">
                      <Radio value={0}>Fără Suspiciuni</Radio>
                      <Radio value={1}>Suspiciuni</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Row>
              <Row>
                <Col span={24} style={{ paddingRight: 10 }}>
                  <Form.Item name="suspicions">
                    <TextArea
                      showCount
                      maxLength={320}
                      rows={1}
                      placeholder="Descriere"
                      size="small"
                      disabled={
                        scanResultValue !== 1 ||
                        transaction.isScanConfirmedByOperator ||
                        !isUserScannerOperator
                      }
                      style={{
                        textTransform: 'uppercase',
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={10} style={{ paddingRight: 20 }}>
              <Divider plain>
                <Text strong>Rezultatul controlului Final</Text>
              </Divider>

              <Row>
                <Form.Item
                  name="status"
                  rules={[
                    {
                      required: true,
                      message: 'Alegeți o optiune',
                    },
                  ]}
                >
                  <Radio.Group
                    onChange={onChangeStatus}
                    value={statusValue}
                    disabled={
                      transaction.isScanConfirmedByOperator ||
                      readOnly ||
                      !transaction.hasScan ||
                      !isUserScannerOperator
                    }
                  >
                    <Space direction="vertical">
                      <Radio value={0}>Conform</Radio>
                      <Radio value={1}>Neconform</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Row>
            </Col>

            <Col span={14} style={{ paddingRight: 20 }}>
              <Divider plain>
                <Text strong>Acțiuni Întreprinse</Text>
              </Divider>
              <Row>
                <Col span={24} style={{ paddingRight: 10 }}>
                  <Form.Item name="actions">
                    <TextArea
                      showCount
                      maxLength={320}
                      rows={1}
                      placeholder="Descriere"
                      size="small"
                      disabled={
                        transaction.isScanConfirmedByOperator ||
                        readOnly ||
                        !transaction.hasScan ||
                        !isUserScannerOperator
                      }
                      style={{
                        textTransform: 'uppercase',
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Form.Item>
            <Space>
              {!readOnly && (
                <>
                  <Col span={8} style={{ width: '100%' }}>
                    <CancelButton disabled={readOnly} onCancel={moveBack} />
                  </Col>

                  {
                    // eslint-disable-next-line
                    isUserScannerOperator ? (
                      // <SaveButton
                      //   disabled={
                      //     !transaction.hasScan ||
                      //     !hasPermission(permissions, 'SCAN_ADD')
                      //   }
                      //   buttonText={
                      //     // eslint-disable-next-line

                      //     transaction.isScanConfirmedByOperator
                      //       ? 'Infirmare date scan (operator)'
                      //       : 'Confirmare date scan (operator)'
                      //   }
                      // />
                      <Button
                        type="primary"
                        onClick={handleSaveData}
                        icon={<SaveOutlined />}
                        disabled={
                          !transaction.hasScan ||
                          !hasPermission(permissions, 'SCAN_ADD')
                        }
                      >
                        {transaction.isScanConfirmedByOperator
                          ? 'Infirmare date scan (operator)'
                          : 'Confirmare date scan (operator)'}
                      </Button>
                    ) : transaction.hasScan ? (
                      <Button
                        type="primary"
                        icon={<RightCircleOutlined />}
                        onClick={handleSaveData}
                      >
                        {t('actions.next')}
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        icon={<RightCircleOutlined />}
                        onClick={scanNavigateWithoutSave}
                      >
                        {t('actions.next')}
                      </Button>
                    )
                  }
                </>
              )}
            </Space>
          </Form.Item>
        </Form>
      </FormContextProvider>
    </>
  );
};

export default Scan;
