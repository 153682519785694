import React, { useMemo } from 'react';

import { Table } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

import useDatasource from '../../hooks/useDatasource';

import Column from '../../helpers/Columns';
import { findAll as findAllDebts } from '../../services/admin/web-services/sfs-debts';
import { BUDGET_CODE } from '../../components/admin/system/constants';
import { useTableScroll } from '../../hooks/useTableScroll';

const SFSDebtsList = () => {
  const { loading, pagination, content, handleChange } = useDatasource(
    findAllDebts,
    { showNotification: true },
  );
  useTableScroll();
  const columns = useMemo(
    () => [
      Column.text('idno', 'IDNO', {
        width: 300,
        filter: true,
      }),
      Column.date('requestDate', 'Data interogarii', {
        width: 250,
      }),
    ],
    [],
  );

  const columnsDebts = useMemo(
    () => [
      {
        title: 'Tip buget',
        key: 'budgetCode',
        width: 400,
        dataIndex: 'budgetCode',
        render: (value) => {
          return <>{BUDGET_CODE[value]?.text}</>;
        },
      },
      {
        title: 'Suma totală a restanței',
        dataIndex: 'totalDebt',
        key: 'totalDebt',
      },
    ],
    [],
  );

  return (
    <>
      <PageHeader title="Datornici" />
      <Table
        columns={columns}
        rowKey="idno"
        loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ x: 500 }}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <PageHeader title="Datorii" />
              <Table
                columns={columnsDebts}
                dataSource={record.details}
                pagination={false}
                rowKey="id"
                size="small"
              />
            </>
          ),
          rowExpandable: (record) =>
            record && record.details && record.details.length > 0,
        }}
      />
    </>
  );
};

export default SFSDebtsList;
