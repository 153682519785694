import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EyeOutlined } from '@ant-design/icons';
import { Button, Table, Tooltip } from 'antd';
import dayjs from 'dayjs';
import Column from '../../../helpers/Columns';
import { getDocuments } from '../../../services/transactions/transaction-documents';
import SesizariModal from '../../admin/modals/SesizariModal';
import ActDeControlParametersModal from '../../admin/modals/ActDeControlParametersModal';
import FraudReportModal from '../../admin/modals/FraudReportModal';
import DocumentCodes from '../../../enums/DocumentCodes';
import WarehouseDocumentModal from '../../admin/warehouse/WarehouseDocumentModal';
import ReturModal from '../../admin/modals/ReturModal';

const TransactionDocumentsTable = ({
  transactionId,
  idnp,
  vinCode,
  vehicleNumber,
  ...rest
}) => {
  const { t } = useTranslation();
  const [documents, setDocuments] = useState([]);
  const [documentsFull, setDocumentsFull] = useState({});

  const [viewDocument, setViewDocument] = useState({});
  const [viewRSFModal, setViewRSFModal] = useState(false);
  const [viewPVTModal, setViewPVTModal] = useState(false);
  const [viewReturModal, setViewReturModal] = useState(false);
  const [viewSesizariModal, setViewSesizariModal] = useState(false);
  const [viewActControlModal, setViewActControlModal] = useState(false);

  const getConvertedRetur = (retur) => {
    const converted = {};

    converted.createdAt = retur.documentFormattedDate
      ? dayjs(retur.documentFormattedDate, 'DD.MM.YYYY')
      : null;
    converted.docNumber = retur.returnNumber;
    converted.entityId = retur.id;
    converted.documentType = DocumentCodes.RETUR;
    converted.id = String(converted.entityId).concat(DocumentCodes.RETUR);
    return converted;
  };

  const getConvertedSesizare = (sesizare) => {
    const converted = {};

    converted.createdAt = sesizare.createdAt;
    converted.docNumber = sesizare.sesizNumber;
    converted.entityId = sesizare.id;
    converted.documentType = DocumentCodes.SESIZARI;
    converted.id = String(converted.entityId).concat(DocumentCodes.SESIZARI);
    return converted;
  };

  const getConvertedRSF = (rsf) => {
    const converted = {};

    converted.createdAt = rsf.createdAt;
    converted.docNumber = rsf.reportNumber;
    converted.entityId = rsf.id;
    converted.documentType = DocumentCodes.SUSPICION_FRAUD;
    converted.id = String(converted.entityId).concat(
      DocumentCodes.SUSPICION_FRAUD,
    );
    return converted;
  };

  const getConvertedAct = (act) => {
    const converted = {};

    converted.createdAt = act.createdAt;
    converted.docNumber = act.actNumber;
    converted.entityId = act.id;
    converted.documentType = DocumentCodes.ACT_CONTROL;
    converted.id = String(converted.entityId).concat(DocumentCodes.ACT_CONTROL);
    return converted;
  };

  const getConvertedPVT = (pvt) => {
    const converted = {};
    converted.createdAt = pvt.documentDate;
    converted.docNumber = pvt.documentNumber;
    converted.entityId = pvt.id;
    converted.documentType = DocumentCodes.TRANSSHIPMENT_PERMISSION;
    converted.id = String(converted.entityId).concat(
      DocumentCodes.TRANSSHIPMENT_PERMISSION,
    );
    return converted;
  };

  const setDocumentList = useCallback((response) => {
    setDocumentsFull(response);
    const { sesizariList, rsfList, actControlList, pvtList, returList } =
      response;

    const datasource = [];

    if (returList) {
      returList.forEach((returDTO) => {
        datasource.push(getConvertedRetur(returDTO));
      });
    }

    if (sesizariList) {
      sesizariList.forEach((sesizariDTO) => {
        datasource.push(getConvertedSesizare(sesizariDTO));
      });
    }

    if (rsfList) {
      rsfList.forEach((rsfDTO) => {
        datasource.push(getConvertedRSF(rsfDTO));
      });
    }

    if (actControlList) {
      actControlList.forEach((act) => {
        datasource.push(getConvertedAct(act));
      });
    }

    if (pvtList) {
      pvtList.forEach((pvt) => {
        datasource.push(getConvertedPVT(pvt));
      });
    }

    setDocuments(datasource);
  }, []);

  useEffect(() => {
    if (idnp && vinCode) {
      getDocuments(transactionId, idnp, vinCode, vehicleNumber)
        .then((res) => setDocumentList(res))
        .catch()
        .finally();
    }
  }, [transactionId, idnp, vinCode, vehicleNumber, setDocumentList]);

  const handleShowDocument = useCallback(
    (record) => {
      const { documentType } = record;
      let currentDocument = null;
      if (documentType === DocumentCodes.RETUR) {
        currentDocument = documentsFull?.returList?.find(
          (el) => el.id === record.entityId,
        );
        setViewReturModal(true);
      }
      if (documentType === DocumentCodes.ACT_CONTROL) {
        currentDocument = documentsFull?.actControlList?.find(
          (el) => el.id === record.entityId,
        );
        setViewActControlModal(true);
      }
      if (documentType === DocumentCodes.SESIZARI) {
        currentDocument = documentsFull?.sesizariList?.find(
          (el) => el.id === record.entityId,
        );
        setViewSesizariModal(true);
      }
      if (documentType === DocumentCodes.SUSPICION_FRAUD) {
        currentDocument = documentsFull?.rsfList?.find(
          (el) => el.id === record.entityId,
        );
        setViewRSFModal(true);
      }
      if (documentType === DocumentCodes.TRANSSHIPMENT_PERMISSION) {
        currentDocument = documentsFull?.pvtList?.find(
          (el) => el.id === record.entityId,
        );
        setViewPVTModal(true); // here stopped
      }
      setViewDocument(currentDocument);
    },
    [documentsFull],
  );

  const columns = useMemo(
    () => [
      Column.text('docNumber', 'Număr Document', {
        width: 100,
        sort: false,
      }),
      Column.date('createdAt', 'Creat la', {
        width: 100,
        format: 'DD-MM-YYYY HH:mm:ss',
        sort: false,
      }),
      Column.text('documentType', 'Tip Document', {
        width: 100,
        sort: false,
      }),
      Column.actions(t('table.actions'), (record) => (
        <span style={{ textAlign: 'right' }}>
          <Tooltip title="Vizualizează document">
            <Button
              shape="circle"
              type="link"
              icon={<EyeOutlined />}
              onClick={() => handleShowDocument(record)}
            />
          </Tooltip>
        </span>
      )),
    ],
    [t, handleShowDocument],
  );

  return documents && documents.length > 0 ? (
    <>
      <h1
        style={{
          fontWeight: 600,
          fontSize: '18px',
          lineHeight: '32px',
        }}
      >
        Documente
      </h1>
      <Table columns={columns} dataSource={documents} {...rest} />

      {viewReturModal && viewDocument ? (
        <ReturModal
          returnData={viewDocument}
          initialVisible
          onClose={() => setViewRSFModal(false)}
          readOnly="true"
          allowGenerate="true"
        />
      ) : null}
      {viewRSFModal && viewDocument ? (
        <FraudReportModal
          initialVisible
          parameters={viewDocument}
          onClose={() => setViewRSFModal(false)}
          readOnly="true"
          allowGenerate="true"
        />
      ) : null}
      {viewSesizariModal && viewDocument ? (
        <SesizariModal
          initialVisible
          sesizariData={viewDocument}
          onClose={() => setViewSesizariModal(false)}
          readOnly="true"
          allowGenerate="true"
        />
      ) : null}
      {viewActControlModal && viewDocument ? (
        <ActDeControlParametersModal
          modalTitle="Act de control"
          actToShow={viewDocument}
          onClose={() => setViewActControlModal(false)}
          readOnly="true"
          allowGenerate="true"
        />
      ) : null}
      {viewPVTModal && viewDocument ? (
        <WarehouseDocumentModal
          initialVisible="true"
          document={viewDocument}
          onClose={() => setViewPVTModal(false)}
          readOnly="true"
          allowGenerate="true"
        />
      ) : null}
    </>
  ) : null;
};

export default TransactionDocumentsTable;
