import React from 'react';

import { useHistory } from 'react-router-dom';

import { Button, Tooltip } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

const CopyItemButton = ({ path, action = null, disabled = false }) => {
  const history = useHistory();

  return (
    <Tooltip title="Dublează risc">
      <Button
        title="Copie"
        shape="circle"
        type="link"
        icon={<CopyOutlined />}
        onClick={() => (action != null ? action() : history.push(path))}
        disabled={disabled}
      />
    </Tooltip>
  );
};

export default CopyItemButton;
