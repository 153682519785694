import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@ant-design/pro-layout';
import { Col, Form, Input, notification, Row, Space } from 'antd';
import dayjs from 'dayjs';
import SaveButton from '../../../buttons/SaveButton';
import CancelButton from '../../../buttons/BackButton';
import useFormErrors from '../../../../hooks/useFormErrors';
import GenericRiskEditForm from '../GenericRiskEditForm';
import RequiredLabel from '../../../../services/utils/RequiredLabel';
import CountrySelect from '../../../CountrySelect';
import { maxLengthValidator } from '../../../../services/utils/validator/Validator';
import { validateTransportUnitsRisk } from './TransportsUnitsValidation';
import { validateGenericRisk } from '../GenericRiskValidation';

const TransportUnitsRisksEditForm = ({
  risk,
  copy,
  onSubmit,
  onCancel,
  errors,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isRiskDeleted = useMemo(() => risk?.deleted, [risk?.deleted]);

  useFormErrors(form, errors);

  const handleFinish = useCallback(
    (values) => {
      if (!validateTransportUnitsRisk(form)) {
        return;
      }
      if (!validateGenericRisk(form)) {
        return;
      }

      if (values.isSms && !values.smsText) {
        notification.error({ message: 'Adăugați textul mesajului SMS' });
        return;
      }
      const persons = values.persons?.map((id) => ({ smsPersonId: id }));
      const posts = values.posts?.map((code) => ({ postCode: code }));
      onSubmit({
        ...values,
        persons,
        posts,
      });
    },
    [onSubmit, form],
  );

  useEffect(() => {
    if (!risk?.id && copy?.id) {
      form.setFieldsValue({
        ...copy,
        posts: copy.posts?.map((el) => el.postCode),
        persons: copy.persons?.map((el) => el.smsPersonId),
        validFrom: copy?.validFrom ? dayjs(copy.validFrom) : undefined,
        validTo: copy?.validTo ? dayjs(copy.validTo) : undefined,
        createdAt: copy?.createdAt ? dayjs(copy.createdAt) : undefined,
        updatedAt: copy?.updatedAt ? dayjs(copy.updatedAt) : undefined,
      });
    }
  }, [form, risk?.id, copy]);

  return (
    <>
      <PageHeader title={t('entity.lucrative.transport-units._singular')} />
      <Form
        form={form}
        onFinish={handleFinish}
        layout="vertical"
        initialValues={{
          ...risk,
          posts: risk.posts?.map((el) => el.postCode),
          persons: risk.persons?.map((el) => el.smsPersonId),
          validFrom: risk?.validFrom ? dayjs(risk.validFrom) : undefined,
          validTo: risk?.validTo ? dayjs(risk.validTo) : undefined,
          createdAt: risk?.createdAt ? dayjs(risk.createdAt) : undefined,
          updatedAt: risk?.updatedAt ? dayjs(risk.updatedAt) : undefined,
        }}
      >
        <Row gutter={15}>
          <Col span={8}>
            <Form.Item
              name="plateNumber"
              labelCol={{ span: 24 }}
              label={
                <RequiredLabel
                  title={t('entity.lucrative.transport-units.plateNumber')}
                />
              }
            >
              <Input
                disabled={risk.deleted}
                onChange={(e) => {
                  form.setFieldsValue({
                    plateNumber: maxLengthValidator(e.target.value, 10),
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="vinCode"
              labelCol={{ span: 24 }}
              label={t('entity.lucrative.transport-units.vinCode')}
            >
              <Input
                disabled={risk.deleted}
                onChange={(e) => {
                  form.setFieldsValue({
                    vinCode: maxLengthValidator(e.target.value, 20),
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="countryId"
              labelCol={{ span: 24 }}
              label={
                <RequiredLabel
                  title={t('entity.lucrative.transport-units.countryId')}
                />
              }
            >
              <CountrySelect disabled={risk.deleted} />
            </Form.Item>
          </Col>
        </Row>
        <GenericRiskEditForm
          existent={risk}
          disabled={risk?.deleted}
          form={form}
        />

        <Form.Item>
          <Space>
            <CancelButton onCancel={onCancel} />
            <SaveButton disabled={isRiskDeleted} />
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default TransportUnitsRisksEditForm;
