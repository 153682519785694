import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import RiskLevelList from '../../../../components/admin/taxonomies/riskLevels/RiskLevelList';

const RiskLevels = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="riskLevels">
      <Tabs.TabPane tab={t('entity.admin.riskLevels._plural')} key="riskLevels">
        <RiskLevelList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default RiskLevels;
