import { genericService, post } from '../../helpers/api';

export const SERVICE_URI = '/backend';
export const BASE_URI = '/api/v1/client-balance';

export const { create, update, findAll, findOne } = genericService(
  SERVICE_URI,
  BASE_URI,
);

export const editBalance = (operation, id, suma) =>
  post(`${SERVICE_URI}${BASE_URI}/add/${operation}/${id}/${suma}`);

// export const subtractBalance = (id) =>
//   post(`${SERVICE_URI}${BASE_URI}/subtract-balance/${id}`);
