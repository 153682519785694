import { notification } from 'antd';

const containsOnlyNumbers = (str) => {
  return /^\d+$/.test(str);
};
export const validate = (
  form,
  isFromPF,
  isCargo,
  hasGoods,
  hasScale,
  physicalCarrier,
  requiredTransporter,
  stoppedAt,
  continuedAt,
  // eslint-disable-next-line
) => {
  if (stoppedAt != null && continuedAt == null) {
    notification.warning({
      message: 'Nu puteți confirma datele până când mașina este reținută',
    });
    return false;
  }
  if (!isFromPF) {
    if (!form || !form.getFieldValue('directions')) {
      notification.warning({
        message: 'Alegeți direcția',
      });
      return false;
    }
    if (!form || !form.getFieldValue('personalCode')) {
      notification.warning({
        message: 'Introduceți Pașaport',
      });
      return false;
    }
    if ((!form || !form.getFieldValue('personCountry')) && !isFromPF) {
      notification.warning({
        message: 'Selectați țara persoanei',
      });
      return false;
    }
    if (
      !form ||
      ((form.getFieldValue('nameCompany') || form.getFieldValue('idno')) &&
        !form.getFieldValue('companyCountry'))
    ) {
      notification.warning({
        message: 'Selectați țara transportatorului',
      });
      return false;
    }
    if (
      !form ||
      ((form.getFieldValue('ownerPersonalCode') ||
        form.getFieldValue('ownerFullName')) &&
        !form.getFieldValue('ownerCountry'))
    ) {
      notification.warning({
        message: 'Selectați țara proprietarului',
      });
      return false;
    }

    if (form.getFieldValue('vehicleVinCode')?.length < 17) {
      notification.warning({
        message: 'Introduceți 17 simboluri pentru VIN',
      });
      return false;
    }
    if (
      form.getFieldValue('idno') &&
      !containsOnlyNumbers(form.getFieldValue('idno')) &&
      form.getFieldValue('idno')?.length > 0
    ) {
      notification.warning({
        message: 'Introduceți doar cifre pentru IDNO-ul transportatorului',
      });
      return false;
    }
    if (!form || !form.getFieldValue('fullName')) {
      notification.warning({
        message: 'Introduceți Numele Șoferului',
      });
      return false;
    }
    if (!form || !form.getFieldValue('vehicleNumber')) {
      notification.warning({
        message: 'Introduceți numărul automobilului',
      });
      return false;
    }
    if (!form || !form.getFieldValue('vehicleVinCode')) {
      notification.warning({
        message: 'Introduceți VIN codul automobilului',
      });
      return false;
    }
    if (!form || !form.getFieldValue('vehicleCountry')) {
      notification.warning({
        message: 'Introduceți Țara automobilului',
      });
      return false;
    }

    if (!form || !form.getFieldValue('vehicleType')) {
      notification.warning({
        message: 'Selectați categoria automobilului',
      });
      return false;
    }
  }

  if (isCargo && (!form || !form.getFieldValue('idno'))) {
    notification.warning({
      message: ` ${
        physicalCarrier
          ? 'Introduceți IDNP - ul transportatorului'
          : 'Introduceți IDNO - ul transportatorului'
      }`,
    });
    return false;
  }
  // if (
  //   form.getFieldValue('trail1_vin')?.length > 0 &&
  //   form.getFieldValue('trail1_vin')?.length < 17
  // ) {
  //   notification.warning({
  //     message: 'Numărul caroseriei/VIN trebuie sa fie minim de 17 simboluri',
  //   });
  //   return false;
  // }
  // if (
  //   form.getFieldValue('trail2_vin')?.length > 0 &&
  //   form.getFieldValue('trail2_vin')?.length < 17
  // ) {
  //   notification.warning({
  //     message: 'Numărul caroseriei/VIN trebuie sa fie minim de 17 simboluri',
  //   });
  //   return false;
  // }
  if (isCargo && (!form || !form.getFieldValue('typeOfVehicle'))) {
    notification.warning({
      message: 'Selectați tipul vehicolului',
    });
    return false;
  }

  if (hasGoods && (!form || !form.getFieldValue('loadCountryId'))) {
    notification.warning({
      message: 'Selectați Țara de Încărcare',
    });
    return false;
  }
  if (hasGoods && (!form || !form.getFieldValue('unloadCountryId'))) {
    notification.warning({
      message: 'Selectați Țara de Descărcare',
    });
    return false;
  }
  if (hasGoods && (!form || !form.getFieldValue('agentIdno'))) {
    notification.warning({
      message: 'Introduceți IDNO - ul agentului economic',
    });
    return false;
  }
  if (hasGoods && (!form || !form.getFieldValue('agentName'))) {
    notification.warning({
      message: 'Câmpul Denumire agent economic este obligatoriu',
    });
    return false;
  }
  if (!form || !form.getFieldValue('customsRegimeId')) {
    notification.warning({
      message: 'Câmpul Tip operațiune este obligatoriu',
    });
    return false;
  }
  if (hasGoods && (!form || !form.getFieldValue('agentCountry'))) {
    notification.warning({
      message: 'Selectați Țara agentului economic',
    });
    return false;
  }
  if (hasScale && (!form || !form.getFieldValue('vehicleWeight'))) {
    notification.warning({
      message: 'Câmpul Masa transportului este obligatoriu',
    });
    return false;
  }
  if (hasScale && (!form || !form.getFieldValue('goodsWeight'))) {
    notification.warning({
      message: 'Câmpul Masa încărcăturii este obligatoriu',
    });
    return false;
  }
  if (hasScale && (!form || !form.getFieldValue('totalWeight'))) {
    notification.warning({
      message: 'Câmpul Masa totală este obligatoriu',
    });
    return false;
  }
  if (hasScale && (!form || !form.getFieldValue('axisCount'))) {
    notification.warning({
      message: 'Câmpul Număr de axe este obligatoriu',
    });
    return false;
  }
  if (hasScale && (!form || !form.getFieldValue('axisDistance'))) {
    notification.warning({
      message: 'Câmpul Distanța dintre axe este obligatoriu',
    });
    return false;
  }
  if (hasScale && (!form || !form.getFieldValue('distance'))) {
    notification.warning({
      message: 'Câmpul Distanța(km) este obligatoriu',
    });
    return false;
  }

  if (requiredTransporter && (!form || !form.getFieldValue('idno'))) {
    notification.warning({
      message: 'Introduceți idno-ul transportatorului',
    });
    return false;
  }
  if (requiredTransporter && (!form || !form.getFieldValue('nameCompany'))) {
    notification.warning({
      message: 'Introduceți numele transportatorului',
    });
    return false;
  }
  if (requiredTransporter && (!form || !form.getFieldValue('companyCountry'))) {
    notification.warning({
      message: 'Introduceți țara transportatorului',
    });
    return false;
  }
  return true;
};
