import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Button, Input, Space, Row, Col, Alert } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { useTranslation } from 'react-i18next';
import { SERVICE_URI, BASE_URI } from '../../services/auth';
import MPassLoginForm from './MPassLoginForm';

const LoginForm = () => {
  const [, setMpassLoginError] = useState();

  const { t } = useTranslation();
  const { search } = useLocation();
  const isInvalid = useMemo(() => search === '?invalid', [search]);

  const handleMPassLogin = () => {
    setMpassLoginError(null);
  };

  return (
    <>
      <h3
        style={{
          display: 'flex',
          justifyContent: 'center',
          fontSize: '1.2rem',
        }}
      >
        Autentificare
      </h3>
      <MPassLoginForm onLogin={handleMPassLogin} />
      <div className="text-divider">Autentificare ca utilizator</div>

      <form
        name="log-in"
        id="log-in"
        method="POST"
        action={`${window._env_.API_BACKEND_URL}${SERVICE_URI}${BASE_URI}/local`}
      >
        <Space
          direction="vertical"
          size="large"
          style={{ width: '100%' }}
          className="log__form-content"
        >
          <Input
            name="username"
            size="large"
            placeholder="Nume utilizator"
            prefix={<UserOutlined />}
          />

          <Input.Password
            name="password"
            size="large"
            placeholder="Parola"
            prefix={<UserOutlined />}
          />

          {isInvalid && (
            <Alert description={t('errors.loginError')} type="error" />
          )}

          {/* <Row gutter={16}>
            <Col span={12}>
              <Checkbox name="remember">Ține-mă minte</Checkbox>
            </Col>
            <Col span={12}>
              <Checkbox name="remember">Ține-mă minte</Checkbox>
            </Col>
            <Col span={24} style={{ textAlign: 'right', marginTop: '-5px' }}>
              <Button type="link" onClick={handleForgotPassword}>
                Ați uitat parola?
              </Button>
            </Col>
          </Row> */}

          <Row gutter={16}>
            <Col span={24}>
              <Button type="primary" block htmlType="submit">
                Autentificare
              </Button>
            </Col>
            {/* <Col span={12}></Col> */}
          </Row>

          {/* <Button type="link" block onClick={handleRegisterClick}>
            Înregistrare
          </Button> */}
        </Space>
      </form>
    </>
  );
};

export default LoginForm;
