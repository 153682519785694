import React, { useCallback, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import ChatPopupUsersEdit from './ChatPopupUsersEdit';
import Icon from '../../Icon';
import { update } from '../chat-service/group-service';
import EntityTypes from '../enums/EntityTypes';

const GroupAddUsersPopup = ({ group, onSave, settingsButton = false }) => {
  const [participantsPopup, setParticipantsPopup] = useState(false);
  const [selectedUsersIds, setSelectedUsersIds] = useState(
    group?.chat?.userIds || [],
  );

  const handleParticipantsPopup = () => {
    setParticipantsPopup((prev) => !prev);
  };

  const closeParticipantsPopup = () => {
    setParticipantsPopup(false);
  };

  const handleSelectUserId = (userId) => {
    setSelectedUsersIds((prev) => {
      if (prev.includes(userId)) {
        return [...prev.filter((el) => el !== userId)];
      }
      return [userId, ...prev];
    });
  };

  const handleSave = useCallback(() => {
    const data = {
      ...group,
      chat: {
        ...group.chat,
        type: EntityTypes.GROUP,
        userIds: selectedUsersIds,
      },
    };

    update(data)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (onSave && typeof onSave === 'function') {
          onSave(json);
        }
      })
      .catch(() => {
        notification.error({
          message: 'A apărut o eroare la adăugarea utilizatorilor',
        });
      })
      .finally(() => closeParticipantsPopup());
  }, [selectedUsersIds, onSave, group]);

  return (
    <>
      {settingsButton ? (
        <button
          type="button"
          className="chat__settings-list--item"
          onClick={handleParticipantsPopup}
        >
          <div className="chat__settings-item--title">
            Modifică participanții
          </div>
          <Icon name="addUser" style={{ width: '15px', height: '15px' }} />
        </button>
      ) : (
        <Button
          className="chat__user-participants--add"
          type="primary"
          icon={<PlusOutlined />}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={handleParticipantsPopup}
        >
          Adaugă participanți
        </Button>
      )}
      <div
        className={`chat__participants-overlay ${
          participantsPopup ? 'active' : ''
        }`}
      >
        <div className="chat__participants-popup">
          <button
            type="button"
            className="chat__close chat__settings-close"
            onClick={closeParticipantsPopup}
          >
            <Icon name="close" width="15" height="15" />
          </button>
          <ChatPopupUsersEdit
            onSelect={handleSelectUserId}
            selectedUsersIds={selectedUsersIds}
            initialValue={group?.users}
            selectByPost={false}
          />
          <div className="chat__settings-confirmation">
            <Button
              style={{ marginLeft: 'auto' }}
              type="primary"
              onClick={handleSave}
            >
              Confirmă
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupAddUsersPopup;
