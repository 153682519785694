import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import MeasuringUnitsList from './MeasuringUnitsList';

const MeasuringUnits = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="measuringUnits">
      <Tabs.TabPane
        tab={t('entity.admin.measuringUnits._plural')}
        key="measuringUnits"
      >
        <MeasuringUnitsList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default MeasuringUnits;
