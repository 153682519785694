import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Button, Row, Table } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import Column from '../../../helpers/Columns';
import useDatasource from '../../../hooks/useDatasource';
import { getOperations as findAllRisks } from '../../../services/risks/risks';
import AuthContext from '../../../components/auth';
import UserFunctions from '../../../enums/UserFunctions';
import CustomsPostSelect from '../../../components/CustomsPostSelect';
import { findAll as findAllCustomsOffices } from '../../../services/admin/users/customs-office';
import { findAll as findAllCustomsPosts } from '../../../services/admin/users/customs-posts';
import useDictionaries from '../../../hooks/useDictionaries';
import ExpandedRisk from '../../../components/lucrative/risks/ExpandedRisk';
import { useTableScroll } from '../../../hooks/useTableScroll';

const dictionaries = {
  customsOffices: findAllCustomsOffices,
  customsPosts: findAllCustomsPosts,
};

const RisksActions = () => {
  const { user } = useContext(AuthContext);
  const [{ customsPosts, customsOffices }] = useDictionaries(dictionaries);

  const [postCode, setPostCode] = useState(null);
  const [allowedPosts, setAllowedPosts] = useState([]);

  useEffect(() => {
    if (user?.officeId && customsOffices?.content?.length > 0) {
      setAllowedPosts(
        customsOffices?.content
          ?.find((el) => el?.id === user.officeId)
          ?.customsPosts?.filter((post) => post.allowSARA),
      );
    } else if (user?.posts?.length > 1) {
      const allowedPostsIds = user?.posts?.map((el) => el.id);
      setAllowedPosts(
        customsPosts?.content?.filter(
          (el) => allowedPostsIds?.includes(el.id) && el.allowSARA,
        ),
      );
    } else if (user?.posts?.length === 1) {
      const post = user?.posts?.[0];
      if (post?.allowSARA) {
        setAllowedPosts([post]);
        setPostCode(post.code);
      }
    } else if (user?.customsPost) {
      if (user?.customsPost) {
        setAllowedPosts([user?.customsPost]);
        setPostCode(user?.customsPost?.code);
      }
    } else if (customsPosts?.content?.length > 0) {
      const post = customsPosts?.content?.find(
        (el) => el.id === user?.postId && el.allowSARA,
      );
      setAllowedPosts([post]);
      setPostCode(post?.code);
    }
  }, [
    customsOffices?.content,
    customsPosts?.content,
    user?.customsPost,
    user?.postId,
    user?.officeId,
    user?.posts,
  ]);

  const renderPostDetails = useCallback(() => {
    let selectedPost = {};
    if (postCode) {
      selectedPost = customsPosts?.content?.find((el) => el.code === postCode);
    } else if (user?.posts?.length === 1) {
      selectedPost = user?.customsPost;
    }
    return (
      <>
        {selectedPost?.name && selectedPost?.code ? (
          <div>
            {`Post Vamal: ${selectedPost?.name}`}
            {`(${selectedPost?.newCode})`}
          </div>
        ) : null}
        <div className="header__curs">
          <div>{`Curs valutar (EUR): ${user?.currency || ''} Lei`}</div>
        </div>
      </>
    );
  }, [
    postCode,
    user?.posts,
    user?.currency,
    user?.customsPost,
    customsPosts?.content,
  ]);

  const handler = useCallback(
    (...params) => {
      const spec = params[0];
      if (!spec.sort) {
        spec.sort = ['statusDate', 'desc'];
      }
      spec.criterias = {
        ...spec.criterias,
        postCode:
          postCode ||
          allowedPosts?.map((el) => el?.code) ||
          user?.customsPost?.code,
      };
      return findAllRisks(spec);
    },
    [allowedPosts, postCode, user?.customsPost?.code],
  );

  const { content, reload, loading, handleChange, pagination } = useDatasource(
    handler,
    { allowFetcher: allowedPosts.length > 0 || !!postCode },
  );

  const idnpPresent = useMemo(() => {
    return content?.some((item) => item.idnp !== null);
  }, [content]);

  useTableScroll();

  const columns = useMemo(
    () =>
      [
        Column.date('date', 'Data', {
          width: 150,
          format: 'DD.MM.YYYY HH:mm:ss',
          filter: true,
        }),
        Column.text('verifiedBy', 'Verificat de', {
          width: 150,
          filter: true,
        }),
        Column.date('statusDate', 'Data acțiune', {
          width: 150,
          format: 'DD.MM.YYYY HH:mm:ss',
          filter: true,
        }),
        Column.text('firstName', 'Nume', {
          width: 100,
          sort: (prev, next) => {
            return prev?.firstName.localeCompare(next?.firstName);
          },
          filter: true,
        }),
        Column.text('lastName', 'Prenume', {
          width: 100,
          sort: (prev, next) => {
            return prev?.lastName.localeCompare(next?.lastName);
          },
          filter: true,
        }),
        Column.text('idnp', 'IDNP', {
          width: 100,
          sort: false,
          filter: true,
          hidden: !idnpPresent,
        }),
        Column.text('route', 'Ruta', {
          width: 150,
          sort: false,
          filter: true,
        }),
        Column.text('place', 'Ghișeul', {
          width: 150,
          sort: false,
          filter: true,
        }),
      ].filter((item) => !item.hidden),
    [idnpPresent],
  );

  const handleChangePost = useCallback(
    (val) => {
      setPostCode(val);
      reload();
    },
    [reload],
  );

  return (
    <>
      <div className="post__vamal">{renderPostDetails()}</div>
      <Row justify="space-between">
        <PageHeader title="Acțiuni S.A.R.A" />
        {user?.function === UserFunctions.OFFICE_HEAD ||
        user.function === UserFunctions.CENTRAL ||
        user.posts?.length > 1 ? (
          <div className="home__post">
            <CustomsPostSelect
              onChange={handleChangePost}
              postList={allowedPosts}
              keys="code"
            />
          </div>
        ) : null}
      </Row>
      <Table
        columns={columns}
        dataSource={content}
        pagination={pagination}
        onChange={handleChange}
        expandable={{
          columnTitle: 'Date Adiționale',
          columnWidth: '130px',
          rowExpandable: () => true,
          expandedRowRender: (record) => (
            <ExpandedRisk risk={record} isOperation />
          ),
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <Button
                type="primary"
                icon={<MinusOutlined />}
                onClick={(e) => onExpand(record, e)}
              >
                Ascunde
              </Button>
            ) : (
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={(e) => onExpand(record, e)}
              >
                Afișează
              </Button>
            ),
        }}
        rowKey="id"
        rowClassName="animated-row"
        scroll={{ x: 1200, drag: true }}
        sticky={{ offsetHeader: 63 }}
        loading={loading}
      />
    </>
  );
};
export default RisksActions;
