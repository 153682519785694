import { genericService, post, search } from '../../helpers/api';

export const SERVICE_URI = '/backend';
export const BASE_URI = '/api/v1/risk';

export const { findAll } = genericService(SERVICE_URI, BASE_URI);

export const remove = (riskId, message) =>
  post(`${SERVICE_URI}${BASE_URI}/${riskId}`, message);

export const getOperations = (data) =>
  search(`${SERVICE_URI}${BASE_URI}/operations`, data);
