import React, { useContext, useMemo } from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import useDatasource from '../../../../hooks/useDatasource';
import Column from '../../../../helpers/Columns';
import {
  changeStatus,
  findAll,
  remove,
} from '../../../../services/risks/risk-actions/transport-units-risks';
import { getActionColumn, riskColumns } from '../GenericRiskColumns';
import { useTableScroll } from '../../../../hooks/useTableScroll';
import AuthContext from '../../../auth';

const TransportUnitsRisksList = () => {
  const { user } = useContext(AuthContext);
  const { permissions } = user || { permissions: [] };

  useTableScroll();

  const { t } = useTranslation();
  const { loading, pagination, content, handleChange, reload } =
    useDatasource(findAll);

  const columns = useMemo(() => {
    const genericColumns = riskColumns(t);
    return [
      Column.text('id', t('entity.lucrative.transport-units.id'), {
        width: 70,
        filter: true,
      }),
      Column.text(
        'plateNumber',
        t('entity.lucrative.transport-units.plateNumber'),
        {
          width: 150,
          filter: true,
        },
      ),
      Column.text('vinCode', t('entity.lucrative.transport-units.vinCode'), {
        width: 150,
        filter: true,
      }),
      Column.text(
        'countryId',
        t('entity.lucrative.transport-units.countryId'),
        {
          width: 150,
        },
      ),
      ...genericColumns,
      Column.actions(t('table.actions'), (record) =>
        getActionColumn({
          t,
          path: `/lucrative/risks-analisys/transport-units/${record.id}`,
          trail: `/lucrative/risks-analisys/transport-units/${record.id}/new`,
          record,
          reload,
          remove,
          permissions,
          changeStatus,
        }),
      ),
    ];
  }, [t, permissions, reload]);

  return (
    <Table
      scroll={{ x: 500 }}
      columns={columns}
      rowKey="id"
      loading={loading}
      pagination={pagination}
      dataSource={content}
      onChange={handleChange}
    />
  );
};

export default TransportUnitsRisksList;
