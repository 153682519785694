import React, { useEffect, useState } from 'react';
import { VIDEO_SERVICE_URI } from '../services/videos/videos';

const VideoPlayer = ({ path, width = '600px', height = 'auto', ...props }) => {
  const [videoURL, setVideURL] = useState(null);

  useEffect(() => {
    if (path) {
      setVideURL(`${window._env_.API_BACKEND_URL}${VIDEO_SERVICE_URI}${path}`);
    }
  }, [path]);

  return (
    <>
      <video
        muted
        playsInline
        loop
        controls
        src={videoURL}
        style={{ width, height, borderRadius: '10px' }}
        {...props}
      />
    </>
  );
};

export default VideoPlayer;
