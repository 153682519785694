import React from 'react';

import { useEditPage } from '../../../hooks/useEditPage';

import { findOne, create, update } from '../../../services/admin/declaration';
import EditDeclarationForm from '../../../components/admin/declaration/EditDeclarationForm';
import AppLoader from '../../../components/auth/AppLoader';

const initial = {
  havingPreciousProperty: false,
  havingWeapon: false,
  havingDrugs: false,
  havingArt: false,
  havingInformationCarriers: false,
  havingLiving: false,
  havingRadioactiveSubstances: false,
  havingPoisons: false,
  havingProducts: false,
  havingPersonalProducts: false,
  havingComercialProducts: false,
  accompaniedBag: false,
  unaccompaniedBag: false,
};

const EditDeclaration = () => {
  const [post, handleSubmit, handleCancel, errors, loading] = useEditPage({
    initial,
    existent: findOne,
    pushOnEntityPage: true,
    goBackPath: '/declaration',
    onCreate: create,
    onUpdate: update,
  });

  return (
    <>
      <div className={`loaderOverlay ${loading ? 'active' : ''}`}>
        <AppLoader />
      </div>
      {post !== undefined && (
        <EditDeclarationForm
          post={post}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          errors={errors}
        />
      )}
    </>
  );
};

export default EditDeclaration;
