import React, { useEffect, useMemo, useState } from 'react';
import { Col, Image, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { getBlobFile } from '../../../services/admin/files';
import ImageFallback from '../../../assets/base64/falback';

const ExpandedRisk = ({ risk, isOperation = false }) => {
  const [imageUrl, setImageUrl] = useState('');
  // const [blob, setBlob] = useState();
  // const onlyFace = useFaceDetection(blob);

  useEffect(() => {
    let isMounted = true;
    if (risk?.docImageId) {
      getBlobFile(risk.docImageId)
        .then((res) => {
          if (isMounted) {
            const imgUrl = URL.createObjectURL(res);
            // setBlob(res);
            setImageUrl(imgUrl);
          }
        })
        .catch((error) => {
          console.error('Error fetching blob file:', error);
        });
    }

    return () => {
      isMounted = false;
      URL.revokeObjectURL(imageUrl);
      // if (onlyFace) {
      //   URL.revokeObjectURL(onlyFace);
      // }
    };
    // eslint-disable-next-line
  }, [risk.docImageId]);

  const irregularityMessage = useMemo(() => {
    const reason = risk.reason ? `Motiv:\n${risk.reason}\n\n` : '';
    const prf = risk.riskPrf ? `${risk.riskPrf}\n\n` : '';
    const dsc = risk.steDsc ? `${risk.steDsc}\n\n` : '';

    let reaction = '';
    if (isOperation) {
      reaction = risk.reaction
        ? `Acțiuni interprinse:\n${risk.reaction}\n\n`
        : '';
    }

    return `${reason}${prf}${dsc}${reaction}`;
  }, [risk.reason, risk.riskPrf, risk.steDsc, risk.reaction, isOperation]);

  const textLabelRender = (name, value) => {
    if (value === null || value === undefined) {
      return null;
    }

    return (
      <div
        className="sara__person-info"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '50px',
        }}
      >
        <span style={{ fontWeight: 400 }}>{`${name}:\u00A0`}</span>
        <span style={{ fontWeight: 600, marginBottom: '5px' }}>{value}</span>
      </div>
    );
  };

  return (
    <Row justify="start" style={{ flexWrap: 'wrap' }} gutter={15}>
      <Col
        xs={24}
        sm={24}
        md={8}
        lg={8}
        xl={6}
        xxl={6}
        style={{ marginBottom: '20px' }}
      >
        <Image
          src={imageUrl}
          style={{ borderRadius: '10px' }}
          fallback={ImageFallback}
        />
        {/* <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
          <div>
            Frontend
            <AvatarComponent src={onlyFace} size={115} radius={10} />
          </div>
        </div> */}
      </Col>

      <Col
        xs={24}
        sm={24}
        md={16}
        lg={16}
        xl={8}
        xxl={8}
        style={{ marginBottom: '20px' }}
      >
        {textLabelRender(
          'Codul Postului',
          risk.postCodeSG || risk.postCode
            ? `${risk.postCode ? 'SVM: '.concat(risk.postCode) : ''} ${
                risk.postCodeSG ? 'SG: '.concat(risk.postCodeSG) : ''
              }`
            : null,
        )}
        {textLabelRender('Ruta', risk.route)}
        {textLabelRender('Ghișeul', risk.place)}
        {textLabelRender('Nume Prenume', `${risk.lastName} ${risk.firstName}`)}
        {textLabelRender('Data Nașterii', risk.birthDate)}
        {textLabelRender('Codul Țării', risk.country)}
      </Col>
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={24}
        xl={10}
        xxl={10}
        style={{ marginBottom: '20px' }}
      >
        {irregularityMessage?.length > 0 ? (
          <TextArea
            className="area_irregularity"
            readOnly
            rows={9}
            value={irregularityMessage}
          />
        ) : null}
      </Col>
    </Row>
  );
};

export default ExpandedRisk;
