import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Input, Row, Col, Space } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

import SaveButton from '../../../buttons/SaveButton';
import CancelButton from '../../../buttons/BackButton';

import useFormErrors from '../../../../hooks/useFormErrors';
import { useTableScroll } from '../../../../hooks/useTableScroll';
import {
  maxLengthValidator,
  numberValidator,
} from '../../../../services/utils/validator/Validator';

const EditServiceDescriptionANSAForm = ({
  post,
  onSubmit,
  onCancel,
  errors,
}) => {
  const { t } = useTranslation();
  useTableScroll();
  const [form] = Form.useForm();
  useFormErrors(form, errors);

  useEffect(() => {
    form.setFieldsValue(post);
  }, [post, form]);

  return (
    <>
      <PageHeader title={t('entity.admin.serviceDescriptionANSA._singular')} />
      <Form
        form={form}
        layout="vertical"
        initialValues={post}
        onFinish={onSubmit}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={t('entity.admin.serviceDescriptionANSA.code')}
              name="code"
            >
              <Input
                disabled={post.deleted}
                onChange={(e) => {
                  form.setFieldsValue({
                    code: numberValidator(e.target.value, 6),
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label={t('entity.admin.serviceDescriptionANSA.name')}
              name="name"
            >
              <Input
                disabled={post.deleted}
                onChange={(e) => {
                  form.setFieldsValue({
                    name: maxLengthValidator(e.target.value, 256),
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Space>
            <SaveButton disabled={post.deleted} />
            <CancelButton onCancel={onCancel} />
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default EditServiceDescriptionANSAForm;
