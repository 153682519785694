import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Layout, Menu, notification, Switch } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import AuthContext, { hasPermission } from '../components/auth';
import { BASE_URI, mpassLogout, SERVICE_URI } from '../services/auth';
import Sidebar from '../components/Sidebar';
import lightBg from '../assets/headerBg.jpg';
import darkBg from '../assets/headerBgDark.jpg';
import Timer from './Timer';
import { findAll as findAllConstants } from '../services/taxonomies/constants';
import useDictionaries from '../hooks/useDictionaries';
import usePageRefresh from '../hooks/usePageRefresh';
import AvatarComponent from '../components/AvatarComponent';
import ChatWrapper from '../components/Chat/ChatWrapper';

const dictionaries = {
  constants: findAllConstants,
};

const LOGOUT_FORM_ID = 'saml-logout-form';
const CLIENT_VERSION = 'CLIENT_VERSION';

const Default = ({ children }) => {
  const { user } = useContext(AuthContext);
  const { permissions } = user ?? [];

  const [{ constants }, reload] = useDictionaries(dictionaries, {
    allowFetcher: hasPermission(permissions, 'TAXONOMIES_LIST'),
  });
  const { content } = constants ?? [];

  const setCurrentVersion = () => {
    const version = content?.find((el) => el.code === CLIENT_VERSION)?.value;
    if (version) {
      localStorage.setItem('client_version', version);
    }
  };

  usePageRefresh(setCurrentVersion);

  useEffect(() => {
    const MS = 10 * 60 * 1000;
    const intervalId = setInterval(() => {
      reload();
    }, MS);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (content?.length > 0) {
      const currentVersion = localStorage.getItem('client_version');
      const version = content?.find((el) => el.code === CLIENT_VERSION)?.value;
      if (currentVersion !== version) {
        notification.destroy('clientVersionNotification');
        notification.warning({
          key: 'clientVersionNotification',
          duration: 0,
          message:
            'A fost gasită o versiune nouă a interfeței, pentru a aplica modificările faceți refresh la pagină',
          description: (
            <>
              <Button
                onClick={() => {
                  notification.destroy('clientVersionNotification');
                }}
              >
                Mai târziu
              </Button>
              <Button
                type="primary"
                onClick={() => window.location.reload()}
                style={{ marginLeft: '15px' }}
                icon={<ReloadOutlined />}
              >
                Ok
              </Button>
            </>
          ),
        });
      }
    }
  }, [content]);

  const [collapsed, setCollapsed] = useState(
    localStorage.getItem('collapsed')
      ? JSON.parse(localStorage.getItem('collapsed'))
      : false,
  );

  const [samlLogoutReq, setSamlLogoutReq] = useState('');
  const [layoutRightPadding, setLayoutRightPadding] = useState(false);
  const [layoutRightSideSmallPadding, setLayoutrightSideSmallPadding] =
    useState(false);

  const [theme, setTheme] = useState(
    localStorage.getItem('them') ? localStorage.getItem('them') : true,
  );
  const body = document.querySelector('body');
  const localTheme = localStorage.getItem('them');

  const loadTheme = useMemo(() => {
    if (localTheme && localTheme === 'true') {
      body.classList.add('light');
      body.classList.remove('dark');
      setTheme(true);
    } else if (localTheme && localTheme === 'false') {
      body.classList.add('dark');
      body.classList.remove('light');
      setTheme(false);
    } else {
      localStorage.setItem('them', true);
      body.classList.add('light');
      body.classList.remove('dark');
      setTheme(true);
    }
    document.body.classList.add('notranslate');
  }, [body.classList, localTheme]);

  const collapsedState = useMemo(() => {
    localStorage.setItem('collapsed', collapsed);
  }, [collapsed]);

  useEffect(() => {
    // eslint-disable-next-line
    loadTheme;
    // eslint-disable-next-line
    collapsedState;
    // eslint-disable-next-line
  }, []);

  const handleChangeTheme = (check) => {
    if (check) {
      localStorage.setItem('them', true);
      body.classList.add('light');
      body.classList.remove('dark');
      setTheme(true);
    } else if (!check) {
      localStorage.setItem('them', false);
      body.classList.add('dark');
      body.classList.remove('light');
      setTheme(false);
    }
  };

  useEffect(() => {
    if (samlLogoutReq) {
      const form = document.getElementById(LOGOUT_FORM_ID);
      form.submit();
      setSamlLogoutReq('');
    }
  }, [samlLogoutReq]);

  const handleLogout = () => {
    if (user?.id && user?.id === -1) {
      mpassLogout().then((saml) => {
        setSamlLogoutReq(saml);
      });
    }
    window.location.href = `${window._env_.API_BACKEND_URL}${SERVICE_URI}${BASE_URI}/logout`;
  };

  return (
    <>
      <Layout>
        <Layout.Header
          style={{
            backgroundImage: `url(${theme ? lightBg : darkBg})`,
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignContent: 'space-between',
              justifyContent: 'space-between',
              position: 'relative',
            }}
          >
            <div
              className="header__leftside"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '40px',
              }}
            />

            <div className="header__rightside" style={{ display: 'flex' }}>
              <div className="header__logo">
                Customs Service <br /> of Republic of Moldova
              </div>
              <div className="header__time">
                <Timer />
              </div>
              <Menu
                className="header__user"
                mode="horizontal"
                style={{ flex: 'auto', gap: '20px' }}
              >
                {user?.id ? (
                  <Menu.SubMenu
                    key="user"
                    className="header__user"
                    title={
                      (
                        <div style={{ display: 'flex', gap: '10px' }}>
                          <div>{user?.fullname}</div>
                          <AvatarComponent
                            imageId={user.imageId}
                            base64={user.base64Image}
                            size={40}
                          />
                        </div>
                      ) || 'cont'
                    }
                    style={{ fontSize: '16px' }}
                    // popupOffset={[0, -10]}
                  >
                    <Menu.Item key="logout" onClick={handleLogout}>
                      Deconecteaza-te
                    </Menu.Item>
                  </Menu.SubMenu>
                ) : null}
              </Menu>

              {hasPermission(permissions, 'USE_CHAT') && (
                <>
                  <ChatWrapper
                    setLayoutRightPadding={setLayoutRightPadding}
                    setLayoutrightSideSmallPadding={
                      setLayoutrightSideSmallPadding
                    }
                  />
                </>
              )}

              <div className="theme__switcher">
                <Switch checked={theme} onChange={handleChangeTheme} />
              </div>
            </div>
          </div>
        </Layout.Header>

        <Layout>
          {user?.id ? (
            <Layout.Sider width="auto">
              <Sidebar
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                setLayoutRightPadding={setLayoutRightPadding}
                setLayoutrightSideSmallPadding={setLayoutrightSideSmallPadding}
              />
            </Layout.Sider>
          ) : null}
          <Layout
            style={{
              height: '100vh',
              minHeight: '400px',
              borderRight: 0,
              paddingTop: '64px',
              // eslint-disable-next-line
              paddingLeft: user?.id ? (collapsed ? '90px' : '220px') : '0px',
            }}
            className={`chat__layout ${layoutRightPadding ? 'right' : ''} ${
              layoutRightSideSmallPadding ? 'smallRight' : ''
            }`}
          >
            <div
              style={{
                width: '100%',
                padding: 24,
                margin: 0,
                height: '100%',
              }}
            >
              <Layout.Content
                style={{
                  background: 'white',
                  minHeight: '100%',
                  padding: 24,
                }}
              >
                {children}
              </Layout.Content>
            </div>
          </Layout>
        </Layout>
      </Layout>
      {user?.id === -1 && (
        <form
          id={LOGOUT_FORM_ID}
          method="POST"
          action={window._env_.MPASS_LOGOUT_URL}
          // onSubmit={mpassSubmit}
          onSubmit={() => {}}
        >
          <input type="hidden" name="SAMLRequest" value={samlLogoutReq} />
          <input
            type="hidden"
            name="RelayState"
            value="Sample AuthnRequest Relay State"
          />

          {/* <PrimaryButton text="MPass" type="button" onClick={mpassSubmit} /> */}
        </form>
      )}
    </>
  );
};

export default Default;
