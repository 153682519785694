import { genericService, put, search, post } from '../../helpers/api';

export const SERVICE_URI = '/backend/api';
export const BASE_URI = '/v1/declaration';

export const { create, update, findAll, remove, findOne } = genericService(
  SERVICE_URI,
  BASE_URI,
);

export const changeStatus = (id, status) =>
  put(`${SERVICE_URI}${BASE_URI}/${status}/${id}`);

export const connectTransaction = (transactionId, declarationNumber) =>
  put(
    `${SERVICE_URI}${BASE_URI}/${declarationNumber}/transaction/${transactionId}`,
  );

export const disConnectTransaction = (id) =>
  put(`${SERVICE_URI}${BASE_URI}/${id}/unwire-transaction`);

export const updateAndCaculate = (id, entity) =>
  put(`${SERVICE_URI}${BASE_URI}/${id}/calculate`, entity);

export const findAllForTransaction = (id) => (data) =>
  search(`${SERVICE_URI}/v1/transactions/${id}/declaration`, data);
export const createForTransaction = (id) => (data) =>
  post(`${SERVICE_URI}/v1/transactions/${id}/declaration`, data);

export const clearCustoms = (id) => (data) =>
  put(`${SERVICE_URI}${BASE_URI}/${id}/clear-customs`, data);

export const deleteUndeclaredVehicle = (id) => (data) =>
  put(`${SERVICE_URI}${BASE_URI}/${id}/delete-undeclared-vehicle`, data);
