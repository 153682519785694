import React from 'react';

import SFSPersoaneList from './SFSPersoaneList';

const SFSPersoane = () => {
  return (
    <>
      <SFSPersoaneList />
    </>
  );
};

export default SFSPersoane;
