import React from 'react';

export const generateTrafficLight = (transaction, organisation) => {
  const {
    hasAnsa,
    hasAnta,
    hasMdUa,
    hasScale,
    hasScan,
    hasWarehouse,
    isAnsaConfirmed,
    isAntaConfirmed,
    isMdUaConfirmed,
    isScaleConfirmed,
    isScanConfirmed,
    isScanConfirmedByOperator,
    isWarehouseConfirmed,
  } = transaction;

  // eslint-disable-next-line consistent-return
  const generateIcons = (type) => {
    if (organisation) {
      switch (type) {
        case 'ansa':
          if (!hasAnsa) {
            return <div className="icon-warning " />;
          }
          if (hasAnsa && !isAnsaConfirmed) {
            return <div className="icon-danger" />;
          }
          if (hasAnsa && isAnsaConfirmed) {
            return <div className="icon-success" />;
          }
          break;
        case 'anta':
          if (!hasAnta) {
            return <div className="icon-warning " />;
          }
          if (hasAnta && !isAntaConfirmed) {
            return <div className="icon-danger" />;
          }
          if (hasAnta && isAntaConfirmed) {
            return <div className="icon-success" />;
          }
          break;
        case 'mdua':
          if (!hasMdUa) {
            return <div className="icon-warning " />;
          }
          if (hasMdUa && !isMdUaConfirmed) {
            return <div className="icon-danger" />;
          }
          if (hasMdUa && isMdUaConfirmed) {
            return <div className="icon-success" />;
          }
          break;
        case 'scale':
          if (!hasScale) {
            return <div className="icon-warning " />;
          }
          if (hasScale && !isScaleConfirmed) {
            return <div className="icon-danger" />;
          }
          if (hasScale && isScaleConfirmed) {
            return <div className="icon-success" />;
          }
          break;
        case 'scan':
          if (!hasScan) {
            return <div className="icon-warning " />;
          }
          if (hasScan && (!isScanConfirmed || !isScanConfirmedByOperator)) {
            return <div className="icon-danger" />;
          }
          if (hasScan && isScanConfirmed && isScanConfirmedByOperator) {
            return <div className="icon-success" />;
          }
          break;
        case 'warehouse':
          if (!hasWarehouse) {
            return <div className="icon-warning " />;
          }
          if (hasWarehouse && !isWarehouseConfirmed) {
            return <div className="icon-danger" />;
          }
          if (hasWarehouse && isWarehouseConfirmed) {
            return <div className="icon-success" />;
          }
          break;
        default:
          break;
      }
    } else {
      switch (type) {
        case 'ansa':
          if (hasAnsa && !isAnsaConfirmed) {
            return (
              <>
                <span style={{ marginRight: '10px' }}>ANSA</span>
                <div className="icon-danger " />
              </>
            );
          }
          if (hasAnsa && isAnsaConfirmed) {
            return (
              <>
                <span style={{ marginRight: '10px' }}>ANSA</span>
                <div className="icon-success" />
              </>
            );
          }
          break;
        case 'anta':
          if (hasAnta && !isAntaConfirmed) {
            return (
              <>
                <span style={{ marginRight: '10px' }}>ANTA</span>
                <div className="icon-danger " />
              </>
            );
          }
          if (hasAnta && isAntaConfirmed) {
            return (
              <>
                <span style={{ marginRight: '10px' }}>ANTA</span>
                <div className="icon-success" />
              </>
            );
          }
          break;
        case 'mdua':
          if (hasMdUa && !isMdUaConfirmed) {
            return (
              <>
                <span style={{ marginRight: '10px' }}>MDUA</span>
                <div className="icon-danger " />
              </>
            );
          }
          if (hasMdUa && isMdUaConfirmed) {
            return (
              <>
                <span style={{ marginRight: '10px' }}>MDUA</span>
                <div className="icon-success" />
              </>
            );
          }
          break;
        case 'scale':
          if (hasScale && !isScaleConfirmed) {
            return (
              <>
                <span style={{ marginRight: '10px' }}>CÂNTAR</span>

                <div className="icon-danger " />
              </>
            );
          }
          if (hasScale && isScaleConfirmed) {
            return (
              <>
                <span style={{ marginRight: '10px' }}>CÂNTAR</span>

                <div className="icon-success" />
              </>
            );
          }
          break;
        case 'scan':
          if (hasScan && (!isScanConfirmed || !isScanConfirmedByOperator)) {
            return (
              <>
                <span style={{ marginRight: '10px' }}>SCAN</span>
                <div className="icon-danger " />
              </>
            );
          }
          if (hasScan && isScanConfirmed && isScanConfirmedByOperator) {
            return (
              <>
                <span style={{ marginRight: '10px' }}>SCAN</span>
                <div className="icon-success" />
              </>
            );
          }
          break;
        case 'warehouse':
          if (hasWarehouse && !isWarehouseConfirmed) {
            return (
              <>
                <span style={{ marginRight: '10px' }}>Transbordare</span>

                <div className="icon-danger " />
              </>
            );
          }
          if (hasWarehouse && isWarehouseConfirmed) {
            return (
              <>
                <span style={{ marginRight: '10px' }}>Transbordare</span>

                <div className="icon-success" />
              </>
            );
          }
          break;
        default:
          break;
      }
    }
  };

  return (
    <>
      {!organisation ? (
        <div style={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: '.5rem',
            }}
          >
            {generateIcons('ansa')}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: '.5rem',
            }}
          >
            {generateIcons('anta')}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: '.5rem',
            }}
          >
            {generateIcons('mdua')}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: '.5rem',
            }}
          >
            {generateIcons('scale')}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: '.5rem',
            }}
          >
            {generateIcons('scan')}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: '.5rem',
            }}
          >
            {generateIcons('warehouse')}
          </div>
        </div>
      ) : (
        <>{generateIcons(organisation)}</>
      )}
    </>
  );
};

export const generateTrafficLightIcon = (value) => {
  if (value === true) {
    return <div className="icon-success" />;
  }
  if (value === false) {
    return <div className="icon-danger" />;
  }
  return <div className="icon-warning " />;
};
