const UserFunctions = {
  POST_HEAD: 'POST_HEAD', // sef post vamal
  DEPUTY_HEAD: 'DEPUTY_HEAD',
  HEAD: 'HEAD', // sef tura
  INSPECTOR_SUPERIOR: 'INSPECTOR_SUPERIOR',
  INSPECTOR_MAIN: 'INSPECTOR_MAIN',
  INSPECTOR: 'INSPECTOR',
  OFFICE_HEAD: 'OFFICE_HEAD', // sef birou vamal
  CENTRAL: 'CENTRAL',
  SCANN_OPERATOR: 'SCANN_OPERATOR',
  MB_TEAM: 'MB_TEAM', // echipa mobila
};

export default UserFunctions;
