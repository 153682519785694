import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import DocumentStatusesList from '../../../../components/admin/taxonomies/documentStatuses/DocumentStatusesList';

const DocumentStatuses = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="documentStatuses">
      <Tabs.TabPane
        tab={t('entity.admin.documentStatus._plural')}
        key="documentStatuses"
      >
        <DocumentStatusesList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default DocumentStatuses;
