import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import ControlLocationsList from '../../../../components/admin/taxonomies/controlLocations/ControlLocationsList';

const ControlLocations = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="controlLocation">
      <Tabs.TabPane
        tab={t('entity.admin.controlLocation._plural')}
        key="controlLocation"
      >
        <ControlLocationsList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default ControlLocations;
