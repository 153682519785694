import React, { useCallback, useEffect, useState } from 'react';
import {
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useTranslation } from 'react-i18next';
import RequiredLabel from '../../../services/utils/RequiredLabel';
import CustomsPostSelect from '../../CustomsPostSelect';
import useDictionaries from '../../../hooks/useDictionaries';
import { findAll as findAllApplicants } from '../../../services/taxonomies/risk-actions/risk-applicants';
import { findAll as findAllPersons } from '../../../services/taxonomies/risk-actions/risk-persons';
import { findAllTypes } from '../../../services/taxonomies/risk-actions/risk-types';
import { findAll as findAllLevels } from '../../../services/taxonomies/risk-actions/risk-level';
import {
  maxLengthValidator,
  numberValidator,
} from '../../../services/utils/validator/Validator';

const SMS_TEXT =
  'La postul @POST@ pe direcția @DIRECTIA@ se află unitatea de transport @AUTO_MARCA@ @AUTO_MODEL@ de culoare @AUTO_CULOARE@ cu numerele de înmatriculare @AUTO_NUMAR@ @AUTO_TARA@. Șoferul: @PERSOANA@.';

const dictionaries = {
  riskApplicants: findAllApplicants,
  riskPersons: findAllPersons,
  riskLevels: findAllLevels,
  riskTypes: findAllTypes,
};

const directions = {
  content: [
    { id: 'IN', code: 'IN', name: 'INTRARE' },
    { id: 'OUT', code: 'OUT', name: 'IEȘIRE' },
    { id: 'ALL', code: 'ALL', name: 'INTRARE/ IEȘIRE' },
  ],
};

const RISK_T = {
  DETERMINED: 'DETERMINED',
  POTENTIAL: 'POTENTIAL',
};

const risks = {
  content: [
    { id: RISK_T.DETERMINED, name: 'DETERMINAT' },
    { id: RISK_T.POTENTIAL, name: 'POTENȚIAL' },
  ],
};

const GenericRiskEditForm = ({ existent, disabled, form }) => {
  const { t } = useTranslation();
  const [{ riskApplicants, riskPersons, riskLevels, riskTypes }] =
    useDictionaries(dictionaries);

  const [selectedRiskType, setSelectedRiskType] = useState();
  const [selectedSubType, setSelectedSubType] = useState();
  const [selectedRisk, setSelectedRisk] = useState();

  const [isSMS, setIsSMS] = useState(existent.isSMS);

  useEffect(() => {
    if (!existent.id) {
      const type = selectedSubType || selectedRiskType;
      const risk = selectedRisk;

      if (risk === RISK_T.DETERMINED && type?.reactionsDetermined) {
        form.setFieldsValue({ reactions: type?.reactionsDetermined });
      } else if (risk === RISK_T.POTENTIAL && type?.reactionsPotential) {
        form.setFieldsValue({ reactions: type?.reactionsPotential });
      } else {
        form.setFieldsValue({ reactions: undefined });
      }
    } else {
      form.setFieldsValue({ reactions: existent.reactions });
    }
  }, [form, selectedRiskType, selectedSubType, selectedRisk, existent]);

  const handleSelectRiskType = useCallback(
    (typeId) => {
      const type = riskTypes?.content?.find((el) => el.id === typeId);
      setSelectedRiskType(type);
    },
    [riskTypes?.content],
  );

  const handleSelectRiskSubType = useCallback(
    (typeId) => {
      if (selectedRiskType) {
        const subType = selectedRiskType.subTypes?.find(
          (el) => el.id === typeId,
        );

        setSelectedSubType(subType);
      }
    },
    [selectedRiskType],
  );

  useEffect(() => {
    if (existent) {
      setIsSMS(existent.isSms);
      handleSelectRiskType(existent.riskTypeId);
    }
  }, [existent, handleSelectRiskType]);

  const onSmsChange = useCallback(
    (checked) => {
      setIsSMS(checked);
      if (checked) {
        form.setFieldsValue({ smsText: SMS_TEXT });
      } else {
        form.resetFields(['smsText']);
      }
    },
    [form],
  );

  return (
    <>
      <Divider />
      <Row gutter={15}>
        <Col span={8}>
          <Form.Item
            name="applicantId"
            label={
              <RequiredLabel
                title={t('entity.lucrative.risk_generic.applicantId')}
              />
            }
            labelCol={{ span: 24 }}
          >
            <Select disabled={disabled} allowClear>
              {riskApplicants.content
                ?.filter((el) => !el.deleted)
                ?.map((row) => (
                  <Select.Option value={row.id} key={row.id}>
                    {`${row.name}`}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="risk"
            label={
              <RequiredLabel title={t('entity.lucrative.risk_generic.risk')} />
            }
            labelCol={{ span: 24 }}
          >
            <Select
              disabled={disabled}
              allowClear
              onSelect={setSelectedRisk}
              onClear={() => setSelectedRisk(undefined)}
            >
              {risks.content.map((row) => (
                <Select.Option value={row.id} key={row.id}>
                  {`${row.name}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name="isFrontiera" valuePropName="checked" label=" ">
            <Checkbox defaultChecked="false" disabled={disabled}>
              {t('entity.lucrative.risk_generic.isFrontiera')}
            </Checkbox>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name="isSms" valuePropName="checked" label=" ">
            <Checkbox
              defaultChecked="false"
              disabled={disabled}
              onChange={(e) => onSmsChange(e.target.checked)}
            >
              {t('entity.lucrative.risk_generic.isSms')}
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      {isSMS ? (
        <>
          <Form.Item
            name="smsText"
            labelCol={{ span: 24 }}
            label={
              <RequiredLabel
                title={`${t(
                  'entity.lucrative.risk_generic.smsText',
                )} (textul marcat între '@' va fi înlocuit automat de către sistem)`}
              />
            }
          >
            <TextArea showCount maxLength={1000} disabled={disabled} rows={4} />
          </Form.Item>
          <Form.Item
            name="persons"
            label={t('entity.lucrative.risk_generic.risckPersonIds')}
            labelCol={{ span: 24 }}
          >
            <Select disabled={disabled} allowClear mode="multiple">
              {riskPersons.content
                ?.filter((el) => !el.deleted)
                ?.map((row) => (
                  <Select.Option value={row.id} key={row.id}>
                    {`${row.phone}${row.name ? ' - '.concat(row.name) : ''}`}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </>
      ) : null}
      <Row gutter={15}>
        <Col span={8}>
          <Form.Item
            name="direction"
            label={
              <RequiredLabel
                title={t('entity.lucrative.risk_generic.direction')}
              />
            }
            labelCol={{ span: 24 }}
          >
            <Select disabled={disabled} allowClear>
              {directions.content.map((row) => (
                <Select.Option value={row.id} key={row.id}>
                  {`${row.name}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="riskLevelId"
            label={
              <RequiredLabel
                title={t('entity.lucrative.risk_generic.riskLevelId')}
              />
            }
            labelCol={{ span: 24 }}
          >
            <Select disabled={disabled} allowClear>
              {riskLevels.content
                ?.filter((el) => !el.deleted)
                ?.map((row) => (
                  <Select.Option value={row.id} key={row.id}>
                    {`${row.description}`}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="document"
            labelCol={{ span: 24 }}
            label={t('entity.lucrative.risk_generic.document')}
          >
            <Input
              disabled={disabled}
              onChange={(e) => {
                form.setFieldsValue({
                  document: maxLengthValidator(e.target.value, 150),
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="posts"
            label={t('entity.lucrative.risk_generic.postCode')}
            labelCol={{ span: 24 }}
          >
            <CustomsPostSelect
              disabled={disabled}
              keys="code"
              mode="multiple"
              allowClear={false}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="riskTypeId"
            label={t('entity.lucrative.risk_generic.riskTypeId')}
            labelCol={{ span: 24 }}
          >
            <Select
              disabled={disabled}
              allowClear
              onSelect={handleSelectRiskType}
              onClear={() => setSelectedRiskType(undefined)}
              onChange={() => form.setFieldsValue({ riskSubtypeId: undefined })}
            >
              {riskTypes.content
                ?.filter((el) => !el.deleted)
                ?.map((row) => (
                  <Select.Option value={row.id} key={row.id}>
                    {`${row.name}`}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        {selectedRiskType &&
        selectedRiskType?.subTypes?.some((el) => !el.deleted) ? (
          <Col span={8}>
            <Form.Item
              name="riskSubtypeId"
              label={t('entity.lucrative.risk_generic.riskSubtypeId')}
              labelCol={{ span: 24 }}
            >
              <Select
                disabled={disabled}
                allowClear
                onSelect={handleSelectRiskSubType}
                onClear={() => setSelectedSubType(undefined)}
              >
                {selectedRiskType?.subTypes
                  ?.filter((el) => !el.deleted)
                  ?.map((row) => (
                    <Select.Option value={row.id} key={row.id}>
                      {`${row.name}`}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        ) : null}
      </Row>
      <Form.Item
        name="action"
        labelCol={{ span: 24 }}
        label={t('entity.lucrative.risk_generic.action')}
        rules={[
          {
            required: true,
            message: 'Câmpul este obligatoriu',
          },
        ]}
      >
        <TextArea showCount maxLength={2000} disabled={disabled} rows={6} />
      </Form.Item>
      <Row gutter={15}>
        <Col span={8}>
          <Form.Item
            label={
              <RequiredLabel
                title={t('entity.lucrative.risk_generic.validFrom')}
              />
            }
            name="validFrom"
            labelCol={{ span: 24 }}
          >
            <DatePicker
              showTime
              disabled={disabled}
              format="DD-MM-YYYY HH:mm:ss"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={t('entity.lucrative.risk_generic.validTo')}
            name="validTo"
            labelCol={{ span: 24 }}
          >
            <DatePicker
              showTime
              disabled={disabled}
              format="DD-MM-YYYY HH:mm:ss"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="reactions"
            labelCol={{ span: 24 }}
            label={t('entity.lucrative.risk_generic.reactions')}
          >
            <Input
              disabled={disabled}
              onChange={(e) => {
                form.setFieldsValue({
                  reactions: numberValidator(e.target.value, 10),
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      {existent?.id ? (
        <>
          <Divider />
          <Row gutter={15}>
            <Col span={6}>
              <Form.Item
                label={t('entity.lucrative.risk_generic.createdBy')}
                name="createdBy"
                labelCol={{ span: 24 }}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={t('entity.lucrative.risk_generic.createdAt')}
                name="createdAt"
                labelCol={{ span: 24 }}
              >
                <DatePicker disabled format="DD-MM-YYYY HH:mm:ss" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={t('entity.lucrative.risk_generic.updatedBy')}
                name="updatedBy"
                labelCol={{ span: 24 }}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={t('entity.lucrative.risk_generic.updatedAt')}
                name="updatedAt"
                labelCol={{ span: 24 }}
              >
                <DatePicker disabled format="DD-MM-YYYY HH:mm:ss" />
              </Form.Item>
            </Col>
          </Row>
        </>
      ) : null}
    </>
  );
};

export default GenericRiskEditForm;
