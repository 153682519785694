import React, { useCallback } from 'react';
import dayjs from 'dayjs';
import { Timeline } from 'antd';

const EntranceExitTimeline = ({
  timelines,
  show = false,
  isFromMd = false,
}) => {
  const timeFormatter = (dateStr) => {
    if (dateStr) {
      return dayjs(dateStr).format('DD.MM.YYYY HH:mm:ss');
    }
    return '-';
  };

  const totalTimelineColor = useCallback(() => {
    return timelines?.some((el) => !(!!el.inDate && !!el.outDate))
      ? 'red'
      : 'blue';
  }, [timelines]);

  return show ? (
    <Timeline id="entranceExitTimeline" mode="left">
      <Timeline.Item
        label={isFromMd ? 'Vehicul Ieșiri' : 'Intrări Vehicul'}
        color={totalTimelineColor()}
      >
        {!isFromMd ? 'Vehicul Ieșiri' : 'Intrări Vehicul'}
      </Timeline.Item>
      {timelines && timelines?.length > 0
        ? timelines.map((timeline) => (
            <Timeline.Item
              key={timeline.id}
              label={
                // eslint-disable-next-line
                isFromMd
                  ? timeline.outDate
                    ? `${timeline.outName?.toUpperCase()} - ${
                        timeline.outPersonalCode
                      } - ${timeFormatter(new Date(timeline.outDate))}`
                    : null
                  : timeline.inDate
                  ? `${timeline.inName?.toUpperCase()} - ${timeline.inPersonalCode?.toUpperCase()} - ${timeFormatter(
                      new Date(timeline.inDate),
                    )}`
                  : null
              }
              color={timeline.inDate && timeline.outDate ? 'blue' : 'red'}
            >
              {
                // eslint-disable-next-line
                !isFromMd
                  ? timeline.outDate
                    ? `${timeFormatter(new Date(timeline.outDate))} - ${
                        timeline.outPersonalCode
                      } - ${timeline.outName?.toUpperCase()}`
                    : null
                  : timeline.inDate
                  ? `${timeFormatter(new Date(timeline.inDate))} - ${
                      timeline.inPersonalCode
                    } - ${timeline.inName?.toUpperCase()}`
                  : null
              }
            </Timeline.Item>
          ))
        : null}
    </Timeline>
  ) : null;
};

export default EntranceExitTimeline;
