import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Popconfirm,
  Row,
  Select,
  Space,
  Switch,
} from 'antd';
import dayjs from 'dayjs';
import {
  AuditOutlined,
  DollarOutlined,
  FilePdfOutlined,
} from '@ant-design/icons';
import { debounce } from 'lodash';
import AppLoader from '../auth/AppLoader';
import SaveButton from '../buttons/SaveButton';
import CancelButton from '../buttons/BackButton';
import useFormErrors from '../../hooks/useFormErrors';
import SpecialServicesTable from './SpecialServicesTable';
import CustomsPostSelect from '../CustomsPostSelect';
import declarationImage from '../../assets/declarationImage.png';

import {
  getOrganizationByIdno,
  getPersonByIdnp,
} from '../../services/admin/web-services/sfs-organizatii';
import { findAll as findAllCustomsPosts } from '../../services/admin/users/customs-posts';
import { findAll as findAllDocumentTypes } from '../../services/taxonomies/document-type-payment';
import {
  getClientBalance,
  updateClientBalance,
} from '../../services/admin/trezoraria-document';

import useDictionaries from '../../hooks/useDictionaries';
import { generatePayment } from '../../services/payments/payment-notice';
import { generatePdfInNewTab } from '../../services/transactions/transactions';
import RequiredLabel from '../../services/utils/RequiredLabel';
import AuthContext from '../auth';
import { maxLengthValidator } from '../../services/utils/validator/Validator';

const PAYMENT_STATUS = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  DRAFT: 'DRAFT',
};

const MRN_REQUIRED_TYPES = ['T1', 'DV', 'TIR'];

const dictionaries = {
  customsPosts: findAllCustomsPosts,
  documentTypes: findAllDocumentTypes,
};
const PaymentNoticeEditForm = ({
  paymentNotice,
  serviceRequest,
  onSubmit,
  onCancel,
  changeStatus,
  errors,
  reload = () => {},
}) => {
  const [{ customsPosts, documentTypes }] = useDictionaries(dictionaries);
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [specialServices, setSpecialServices] = useState([]);
  const [payerSwitch, setPayerSwitch] = useState(true);
  const [loadingLoader, setLoadingLoader] = useState(false);
  const [mrnRequired, setMRNrequired] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [isButtonBeneficiaryDisabled, setIsButtonBeneficiaryDisabled] =
    useState(false);
  const [isButtonPayerDisabled, setIsButtonPayerDisabled] = useState(false);

  const handleModal = () => {
    if (!modalVisible) {
      const personalCodeBeneficiary = form.getFieldValue('codPersonal2');
      getClientBalance(personalCodeBeneficiary).then((element) => {
        const beneficiarySold = element?.dt || 0;
        if (beneficiarySold < paymentNotice?.totalAmount) {
          setIsButtonBeneficiaryDisabled(true);
        }
        form.setFieldValue('beneficiarySold', beneficiarySold);
      });

      const codPayer = form.getFieldValue('payerIdentificationNumber');
      getClientBalance(codPayer).then((element) => {
        const payerSold = element?.dt || 0;
        if (!payerSwitch && payerSold < paymentNotice?.totalAmount) {
          setIsButtonPayerDisabled(true);
        }
        form.setFieldValue('payerSold', payerSold);
      });
    }

    setModalVisible((prev) => !prev);
  };
  const handleChangePayer = (checked) => {
    setPayerSwitch(checked);
    if (checked) {
      const payerIdentificationNumber = form.getFieldValue('codPersonal2');
      const payerName = form.getFieldValue('beneficiary');

      form.setFieldsValue({
        payerIdentificationNumber,
        payerName,
      });
    } else {
      form.setFieldsValue({
        payerIdentificationNumber: '',
        payerName: '',
      });
    }
  };

  const handleExtractPaymentsBeneficiary = () => {
    const personalCodeBeneficiary = form.getFieldValue('codPersonal2');
    if (paymentNotice?.id !== null && paymentNotice?.id !== undefined) {
      updateClientBalance(personalCodeBeneficiary, paymentNotice.id)
        .then(() => {
          notification.success({ message: 'Achitare servicii cu succes' });
          setModalVisible(false);
          reload();
        })
        .catch(() => {
          notification.error({ message: 'Eroare la achitare' });
          setModalVisible(false);
          reload();
        });
    }
  };

  const handleExtractPaymentsPayer = () => {
    const personalCodePayer = form.getFieldValue('payerIdentificationNumber');
    if (paymentNotice?.id) {
      updateClientBalance(personalCodePayer, paymentNotice.id)
        .then(() => {
          notification.success({ message: 'Achitare servicii cu succes' });
          setModalVisible(false);
          reload();
        })
        .catch(() => {
          notification.error({ message: 'Eroare la achitare' });
          setModalVisible(false);
          reload();
        });
    }
  };

  const handleDocumentTypeChange = useCallback(
    (value) => {
      const selectedOption = documentTypes?.content.find(
        (option) => option.id === value,
      );

      if (selectedOption) {
        const documentTypeCode = selectedOption.code;
        if (MRN_REQUIRED_TYPES.includes(documentTypeCode)) {
          setMRNrequired(true);
          form.setFieldValue('onDemandDate', null);
          form.setFieldValue('onDemandNr', null);
          form.setFieldValue('customDeclarationNr', '0000000000');
        } else {
          setMRNrequired(false);
        }
      }
    },
    [documentTypes, form],
  );

  useFormErrors(form, errors);

  useEffect(() => {
    if (payerSwitch) {
      const payerIdentificationNumber = form.getFieldValue('codPersonal2');
      const payerName = form.getFieldValue('beneficiary');

      form.setFieldsValue({
        payerIdentificationNumber,
        payerName,
      });
    }
  }, [form, payerSwitch]);

  useEffect(() => {
    if (paymentNotice?.documentTypeId) {
      const docTypeObj = documentTypes?.content.find(
        (el) => el.id === paymentNotice?.documentTypeId,
      );
      if (docTypeObj) {
        setMRNrequired(MRN_REQUIRED_TYPES.includes(docTypeObj?.code));
      }
    }
  }, [paymentNotice, documentTypes]);

  useEffect(() => {
    if (paymentNotice?.id) {
      form.resetFields();
    }
  }, [paymentNotice, form]);

  useEffect(() => {
    setPayerSwitch(
      paymentNotice?.payerIdentificationNumber ===
        paymentNotice?.codPersonal2 &&
        paymentNotice?.payerName === paymentNotice?.beneficiary,
    );
  }, [
    paymentNotice?.payerIdentificationNumber,
    paymentNotice?.codPersonal2,
    paymentNotice?.payerName,
    paymentNotice?.beneficiary,
  ]);

  useEffect(() => {
    if (serviceRequest) {
      console.log('in effect', customsPosts?.content);
      if (customsPosts?.content?.length > 0) {
        const postId = customsPosts?.content?.find(
          (el) => el?.code === serviceRequest.postCode,
        )?.id;
        form.setFieldsValue({ customsPostId: postId });
      }

      form.setFieldsValue({
        payerIdentificationNumber: serviceRequest.declarantIdnoEori,
        payerName: `${serviceRequest.declarantSurname} ${serviceRequest.declarantName}`,
        codPersonal2: serviceRequest.declarantIdnoEori,
        beneficiary: `${serviceRequest.declarantSurname} ${serviceRequest.declarantName}`,
        providedServicesPlace: serviceRequest.zipAddress,
        onDemandNr: serviceRequest.seqNumber,
        annexedDocuments: serviceRequest.preferredTimeControl,
        releaseDate: dayjs(serviceRequest.startDate),
        documentDate: dayjs(serviceRequest.applicationDateTime),
        documentYear: dayjs(serviceRequest.applicationDateTime).year(),
        onDemandDate: dayjs(serviceRequest.startDate),
      });
      setPayerSwitch(true);
    }
  }, [serviceRequest, form, customsPosts?.content]);

  const disabled = useMemo(() => {
    return (
      paymentNotice?.status === PAYMENT_STATUS.REJECTED ||
      paymentNotice?.status === PAYMENT_STATUS.APPROVED
    );
  }, [paymentNotice?.status]);

  const handleFinish = useCallback(
    (values, after = () => {}) => {
      onSubmit({
        ...values,
        specialServices,
        serviceRequestId: serviceRequest?.id || null,
        docDate: values.docDate ? dayjs(values.docDate) : null,
        documentDate: values.documentDate ? dayjs(values.documentDate) : null,
        releaseDate: values.releaseDate ? dayjs(values.releaseDate) : null,
        onDemandDate: values.onDemandDate ? dayjs(values.onDemandDate) : null,
      }).then(after);
    },
    [onSubmit, specialServices, serviceRequest?.id],
  );

  const onServiceRecordsUpdate = useCallback((updatedServices) => {
    setSpecialServices(updatedServices);
  }, []);

  const changeStatusCalback = useCallback(
    (status) => {
      changeStatus(paymentNotice?.id, status)
        .then(() => {
          notification.destroy();
          notification.success({
            message: 'Statutul a fost modificat cu succes',
            duration: 3,
          });
          form.resetFields();
          reload();
        })
        .catch((err) => {
          if (err)
            notification.error({
              message: 'A apărut o eroare',
            });
        });
    },
    [changeStatus, paymentNotice?.id, reload, form],
  );

  const setStatus = useCallback(
    (status) => {
      if (status === PAYMENT_STATUS.APPROVED) {
        const formValues = form.getFieldsValue();
        handleFinish(formValues, () => changeStatusCalback(status));
        return;
      }
      changeStatusCalback(status);
    },
    [form, handleFinish, changeStatusCalback],
  );

  const handleGeneratePayment = useCallback(
    (id) => {
      generatePayment(id)
        .then(() => {
          notification.success({
            message: 'Chitanța a fost generată cu succes.',
          });
        })
        .catch(() =>
          notification.error({ message: 'Erroare la generarea chitanței.' }),
        )
        .finally(() => reload());
    },
    [reload],
  );

  const handleGeneratePaymentNoticePdf = () => {
    const noticeId = paymentNotice.id;

    generatePdfInNewTab(`/payment-notice-report-pdf/${noticeId}`)
      .then(() => {
        notification.success({
          message: t('messages.report.generated.success'),
          duration: 3,
        });
      })
      .catch((err) => {
        if (err)
          notification.error({
            message: t('errors.report.generation.failed'), // Localized error message
          });
      });
  };

  const searchPerson = useCallback((personalCode) => {
    if (personalCode?.startsWith('1')) {
      return getOrganizationByIdno(personalCode);
    }
    return getPersonByIdnp(personalCode);
  }, []);

  const handleSearchPerson = useCallback(
    (personalCode, nameOfField) => {
      if (personalCode?.length !== 13) {
        return;
      }
      setLoadingLoader(true);
      searchPerson(personalCode)
        .then((person) => {
          if (person && (person.idno || person.idnp)) {
            setLoadingLoader(false);
            let nameFieldValue = '';
            if (personalCode?.startsWith('1')) {
              nameFieldValue = person.shortName;
            } else {
              nameFieldValue = `${person.lastName} ${person.firstName}`;
            }

            const fieldsValue = {
              [nameOfField]: nameFieldValue?.toUpperCase(),
            };

            if (payerSwitch) {
              fieldsValue.payerName = nameFieldValue;
            }

            form.setFieldsValue(fieldsValue);
          }
        })
        .catch(() => {
          setLoadingLoader(false);
          notification.warning({
            message: 'Lipsă date',
            description: `Nu au fost găsite date`,
          });
        });
    },
    [searchPerson, form, payerSwitch],
  );

  const lazyPersonSearch = debounce(handleSearchPerson, 1500);

  return (
    <>
      <div className={`loaderOverlay ${loadingLoader ? 'active' : ''}`}>
        <AppLoader />
      </div>
      <Form
        form={form}
        onFinish={handleFinish}
        layout="vertical"
        initialValues={{
          ...paymentNotice,
          payerIdentificationNumber:
            serviceRequest?.declarantIdnoEori ||
            paymentNotice?.payerIdentificationNumber,
          payerName:
            `${serviceRequest?.declarantSurname} ${serviceRequest?.declarantName}` ||
            paymentNotice?.payerName,
          codPersonal2:
            serviceRequest?.declarantIdnoEori || paymentNotice?.codPersonal2,
          beneficiary:
            `${serviceRequest?.declarantSurname} ${serviceRequest?.declarantName}` ||
            paymentNotice?.beneficiary,
          providedServicesPlace:
            serviceRequest?.zipAddress || paymentNotice?.providedServicesPlace,
          onDemandNr:
            serviceRequest?.seqNumber || paymentNotice?.documentNumber,
          supplementaryInfo: paymentNotice?.supplementaryInfo,
          annexedDocuments:
            serviceRequest?.preferredTimeControl ||
            paymentNotice?.annexedDocuments,
          releaseDate: paymentNotice?.releaseDate
            ? dayjs(paymentNotice.releaseDate)
            : null,
          documentDate: paymentNotice?.documentDate
            ? dayjs(paymentNotice.documentDate)
            : null,
          documentYear: paymentNotice?.documentYear,
          customsPostId: paymentNotice?.customsPostId,
          onDemandDate: paymentNotice?.onDemandDate
            ? dayjs(paymentNotice.onDemandDate)
            : null,
          provider: 'Serviciul Vamal',
          codPersonal1: '1006601000185',
          deliveredServices: user.fullname,
          documentTypeId: 1,
        }}
      >
        <div className="payments">
          <div className="payments__header">
            <img
              className="payments__header-image"
              src={declarationImage}
              alt="declarationImage"
            />
            <h3 className="payments__header-title">
              AVIZ DE PLATA PENTRU SERVICII SPECIALE
            </h3>
            {paymentNotice.id && (
              <h3 className="payments__header-title">
                Nr. {paymentNotice.docNumber}
              </h3>
            )}
          </div>
          <div className="payments__fields">
            <Row gutter={5}>
              <Col span={4}>
                <div
                  style={{ color: 'transparent' }}
                  className="declaration__field"
                >
                  .
                </div>
              </Col>
              <Col span={12}>
                <div
                  className="declaration__field"
                  style={{ textAlign: 'center', marginBottom: '5px' }}
                >
                  MRN document
                </div>
              </Col>
              <Col span={8}>
                <div
                  className="declaration__field"
                  style={{ textAlign: 'center' }}
                >
                  Cerere solicitant/protocol de corecție
                </div>
              </Col>
            </Row>
            <Row className="payments__row" gutter={5}>
              <Col span={4}>
                <div className="declaration__field">
                  <div className="declaration__field-head">
                    <div className="declaration__field-title">
                      <RequiredLabel
                        title={t('entity.payments.documentType')}
                      />
                    </div>
                  </div>
                  <Form.Item
                    name="documentTypeId"
                    rules={[
                      { required: true, message: 'Câmpul este obligatoriu' },
                    ]}
                  >
                    <Select
                      allowClear
                      showSearch
                      style={{ width: '100%' }}
                      disabled={!!serviceRequest || disabled}
                      onChange={(value, option) =>
                        handleDocumentTypeChange(value, option)
                      }
                    >
                      {documentTypes?.content.map((option) => (
                        <Select.Option key={option.id} value={option.id}>
                          {`${option.name}`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col span={4}>
                <div className="declaration__field">
                  <div className="declaration__field-head">
                    <div className="declaration__field-title">
                      {t('entity.payments.documentYear')}
                    </div>
                  </div>
                  <Form.Item name="documentYear">
                    <InputNumber
                      min={0}
                      precision={0}
                      disabled
                      defaultValue={new Date().getFullYear()}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={4}>
                <div className="declaration__field">
                  <div className="declaration__field-head">
                    <div className="declaration__field-title">
                      <RequiredLabel title={t('entity.payments.customsPost')} />
                    </div>
                  </div>
                  <Form.Item
                    name="customsPostId"
                    rules={[
                      { required: true, message: 'Câmpul este obligatoriu' },
                    ]}
                  >
                    <CustomsPostSelect
                      keys="id"
                      allowClear={false}
                      disabled={
                        !!serviceRequest || paymentNotice?.docNumber || disabled
                      }
                      postList={customsPosts?.content}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={4}>
                <div className="declaration__field">
                  <div className="declaration__field-head">
                    {mrnRequired ? (
                      <RequiredLabel title={t('entity.payments.mrnNumber')} />
                    ) : (
                      t('entity.payments.mrnNumber')
                    )}
                  </div>
                  <Form.Item
                    name="customDeclarationNr"
                    rules={[
                      {
                        validator: (_, value) =>
                          (mrnRequired && value === null) ||
                          (mrnRequired && value === '0000000000')
                            ? Promise.reject(
                                new Error('Câmpul este obligatoriu'),
                              )
                            : Promise.resolve(),
                      },
                    ]}
                  >
                    <Input
                      defaultValue={!mrnRequired ? '0000000000' : null}
                      disabled={!mrnRequired || disabled}
                      style={{ textTransform: 'uppercase', fontWeight: '600' }}
                      onChange={(e) => {
                        const { value: inputValue } = e.target;
                        if (inputValue.length > 10) {
                          form.setFieldsValue({
                            customDeclarationNr: inputValue.substring(1, 11),
                          });
                        }
                        if (inputValue.length < 10) {
                          form.setFieldsValue({
                            customDeclarationNr: inputValue.padStart(10, '0'),
                          });
                        }
                      }}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={4}>
                <div className="declaration__field">
                  <div className="declaration__field-head">
                    <div className="declaration__field-title">
                      {mrnRequired ? (
                        t('entity.payments.documentNumber')
                      ) : (
                        <RequiredLabel
                          title={t('entity.payments.documentNumber')}
                        />
                      )}
                    </div>
                  </div>
                  <Form.Item
                    name="onDemandNr"
                    rules={[
                      {
                        validator: (_, value) => {
                          return !mrnRequired && (!value || value.trim() === '')
                            ? Promise.reject(
                                new Error('Câmpul este obligatoriu'),
                              )
                            : Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      disabled={!!serviceRequest || mrnRequired || disabled}
                      style={{ textTransform: 'uppercase', fontWeight: '600' }}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={4}>
                <div className="declaration__field">
                  <div className="declaration__field-head">
                    <div className="declaration__field-title">
                      {mrnRequired ? (
                        t('entity.payments.documentDate')
                      ) : (
                        <RequiredLabel
                          title={t('entity.payments.documentDate')}
                        />
                      )}
                    </div>
                  </div>
                  <Form.Item
                    name="onDemandDate"
                    rules={[
                      {
                        validator: (_, value) =>
                          !mrnRequired && value === null
                            ? Promise.reject(
                                new Error('Câmpul este obligatoriu'),
                              )
                            : Promise.resolve(),
                      },
                    ]}
                  >
                    <DatePicker
                      format="DD.MM.YYYY"
                      disabled={!!serviceRequest || mrnRequired || disabled}
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row className="payments__row" gutter={5}>
              <Col span={6}>
                <div className="declaration__field">
                  <div className="declaration__field-head">
                    <div className="declaration__field-title">
                      <RequiredLabel
                        title={t(
                          'entity.payments.benificiaryIdentificationNumber',
                        )}
                      />
                    </div>
                  </div>
                  <Form.Item
                    name="codPersonal2"
                    rules={[
                      { required: true, message: 'Câmpul este obligatoriu' },
                    ]}
                  >
                    <Input
                      disabled={disabled}
                      onChange={(e) => {
                        form.setFieldsValue({
                          codPersonal2: maxLengthValidator(
                            e.target.value.trim(),
                            20,
                          ),
                        });
                        lazyPersonSearch(e.target.value, 'beneficiary');
                        if (payerSwitch) {
                          form.setFieldsValue({
                            payerIdentificationNumber: maxLengthValidator(
                              e.target.value,
                              20,
                            ),
                          });
                        }
                      }}
                      style={{ textTransform: 'uppercase' }}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={6}>
                <div className="declaration__field">
                  <div className="declaration__field-head">
                    <div className="declaration__field-title">
                      <RequiredLabel
                        title={t('entity.payments.beneficiaryName')}
                      />
                    </div>
                  </div>
                  <Form.Item
                    name="beneficiary"
                    rules={[
                      {
                        required: true,
                        message: 'Completeaza Denumire beneficiar',
                      },
                    ]}
                  >
                    <Input
                      disabled={disabled}
                      onChange={(e) => {
                        form.setFieldsValue({
                          beneficiary: maxLengthValidator(e.target.value, 20),
                        });
                        if (payerSwitch) {
                          form.setFieldsValue({
                            payerName: maxLengthValidator(e.target.value, 20),
                          });
                        }
                      }}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={6}>
                <div className="declaration__field">
                  <div className="declaration__field-head">
                    <div className="declaration__field-title">
                      {t('entity.payments.serviceIdentificationNumber')}{' '}
                    </div>
                  </div>
                  <Form.Item name="codPersonal1">
                    <Input disabled defaultValue="1006601000185" />
                  </Form.Item>
                </div>
              </Col>
              <Col span={6}>
                <div className="declaration__field">
                  <div className="declaration__field-head">
                    <div className="declaration__field-title">
                      {t('entity.payments.serviceIdentificationName')}{' '}
                    </div>
                  </div>
                  <Form.Item name="provider">
                    <Input disabled defaultValue="Serviciul Vamal" />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row>
              <Space style={{ marginBottom: '5px', marginTop: '5px' }}>
                <Switch checked={payerSwitch} onChange={handleChangePayer} />
                <div> Plătitorul coincide cu beneficiarul</div>
              </Space>
            </Row>
            <Row className="payments__row" gutter={5}>
              <Col span={6}>
                <div className="declaration__field">
                  <div className="declaration__field-head">
                    <div className="declaration__field-title">
                      <RequiredLabel
                        title={t('entity.payments.payerIdentificationNumber')}
                      />
                    </div>
                  </div>
                  <Form.Item
                    name="payerIdentificationNumber"
                    rules={[
                      {
                        required: !payerSwitch,
                        message: 'Completează IDNO/IDNP plătitor',
                      },
                    ]}
                  >
                    <Input
                      disabled={!!serviceRequest || payerSwitch || disabled}
                      onChange={(e) => {
                        form.setFieldsValue({
                          payerIdentificationNumber: maxLengthValidator(
                            e.target.value.trim(),
                            20,
                          ),
                        });
                        lazyPersonSearch(e.target.value, 'payerName');
                      }}
                      style={{ textTransform: 'uppercase', fontWeight: '600' }}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={6}>
                <div className="declaration__field">
                  <div className="declaration__field-head">
                    <div className="declaration__field-title">
                      <RequiredLabel title={t('entity.payments.payerName')} />
                    </div>
                  </div>
                  <Form.Item
                    name="payerName"
                    rules={[
                      {
                        required: !payerSwitch,
                        message: 'Completeaza Denumire plătitor',
                      },
                    ]}
                  >
                    <Input
                      disabled={!!serviceRequest || payerSwitch || disabled}
                      style={{ textTransform: 'uppercase', fontWeight: '600' }}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={6}>
                <div className="declaration__field">
                  <div className="declaration__field-head">
                    <div className="declaration__field-title">
                      {t('entity.payments.place')}{' '}
                    </div>
                  </div>
                  <Form.Item name="providedServicesPlace">
                    <Input
                      disabled={!!serviceRequest || disabled}
                      style={{ textTransform: 'uppercase', fontWeight: '600' }}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={6}>
                <div className="declaration__field">
                  <div className="declaration__field-head">
                    <div className="declaration__field-title">
                      {t('entity.payments.annexedDocuments')}{' '}
                    </div>
                  </div>
                  <Form.Item name="annexedDocuments">
                    <Input
                      disabled={!!serviceRequest || disabled}
                      style={{ textTransform: 'uppercase', fontWeight: '600' }}
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row className="payments__row" gutter={5}>
              <Col span={18}>
                <div className="declaration__field">
                  <div className="declaration__field-head">
                    <div className="declaration__field-title">
                      {t('entity.payments.suplementInformation')}
                    </div>
                  </div>
                  <Form.Item name="supplementaryInfo">
                    <Input
                      disabled={disabled}
                      style={{
                        textTransform: 'uppercase',
                        fontWeight: '600',
                      }}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={6}>
                <div className="declaration__field">
                  <div className="declaration__field-head">
                    <div className="declaration__field-title">
                      {t('entity.payments.createdBy')}
                    </div>
                  </div>
                  <Form.Item name="deliveredServices">
                    <Input
                      disabled
                      style={{ textTransform: 'uppercase', fontWeight: '600' }}
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <SpecialServicesTable
              initial={paymentNotice.specialServices}
              onServiceRecordsUpdate={onServiceRecordsUpdate}
              disabled={disabled}
            />
            <Row className="payments__row" gutter={5}>
              <Col span={12}>
                <div className="declaration__field">
                  <div className="declaration__field-head">
                    <div className="declaration__field-title">
                      {t('entity.payments.receivedServices')}{' '}
                    </div>
                  </div>
                  <Form.Item name="receivedServices">
                    <Input
                      disabled={disabled}
                      style={{ textTransform: 'uppercase', fontWeight: '600' }}
                    />
                  </Form.Item>
                </div>
              </Col>
              {paymentNotice?.paymentStatus === 'PAID' &&
                (paymentNotice?.bankId || paymentNotice?.receiptNumber) && (
                  <Col span={12}>
                    <div className="declaration__field">
                      <div className="declaration__field-head">
                        <div className="declaration__field-title">
                          {paymentNotice?.bankId
                            ? `Este achitat prin MPAY la data de ${dayjs(
                                paymentNotice?.paymentDate,
                              ).format('DD.MM.YYYY')} (identificator MPAY:${
                                paymentNotice?.bankId
                              })`
                            : `Achitare confirmata manual de catre ${
                                paymentNotice?.updatedByFullName
                              } la data ${dayjs(
                                paymentNotice?.paymentDate,
                              ).format('DD.MM.YYYY')} (Numar bon de plata:${
                                paymentNotice?.receiptNumber
                              })`}
                        </div>
                      </div>
                    </div>
                  </Col>
                )}
              {(paymentNotice?.paymentStatus === 'PAID' ||
                paymentNotice?.paymentStatus === 'CONFIRMED') &&
                !paymentNotice?.bankId &&
                !paymentNotice?.receiptNumber && (
                  <Col span={12}>
                    <div className="declaration__field">
                      <div className="declaration__field-head">
                        <div className="declaration__field-title">
                          Este achitat prin extragerea din soldul{' '}
                          {payerSwitch ? 'beneficiarului' : 'platitorului'} la
                          data de{' '}
                          {dayjs(paymentNotice?.paymentDate).format(
                            'DD.MM.YYYY',
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                )}
              {paymentNotice?.paymentStatus === 'CONFIRMED' &&
                (paymentNotice?.bankId || paymentNotice?.receiptNumber) && (
                  <Col span={12}>
                    <div className="declaration__field">
                      <div className="declaration__field-head">
                        <div className="declaration__field-title">
                          {paymentNotice?.bankId
                            ? `Este achitat prin MPAY la data de ${dayjs(
                                paymentNotice?.paymentDate,
                              ).format('DD.MM.YYYY')} (identificator MPAY:${
                                paymentNotice?.bankId
                              }) Confirmat trezorerial`
                            : `Achitare confirmata manual de catre ${
                                paymentNotice?.updatedByFullName
                              } la data ${dayjs(
                                paymentNotice?.paymentDate,
                              ).format('DD.MM.YYYY')} (Numar bon de plata:${
                                paymentNotice?.receiptNumber
                              }) Confirmat trezorerial`}
                        </div>
                      </div>
                    </div>
                  </Col>
                )}
            </Row>
          </div>
        </div>
        <Form.Item>
          <Space>
            <CancelButton onCancel={onCancel} />
            <SaveButton />
            <Space hidden={!paymentNotice?.id}>
              {paymentNotice?.status === PAYMENT_STATUS.DRAFT ||
              !paymentNotice?.status ? (
                <>
                  <Popconfirm
                    placement="topLeft"
                    title="Sunteți sigur ca doriți sa marcați avizul ca APROBAT ?"
                    onConfirm={() => setStatus(PAYMENT_STATUS.APPROVED)}
                    okText="Ok"
                    cancelText="Înapoi"
                  >
                    <Button type="primary">Aprobare</Button>
                  </Popconfirm>
                  <Popconfirm
                    placement="topLeft"
                    title="Sunteți sigur ca doriți sa marcați avizul ca RESPINS ?"
                    onConfirm={() => setStatus(PAYMENT_STATUS.REJECTED)}
                    okText="Ok"
                    cancelText="Înapoi"
                  >
                    <Button type="primary" danger>
                      Respingere
                    </Button>
                  </Popconfirm>
                  <Button
                    type="primary"
                    onClick={() => handleGeneratePaymentNoticePdf()}
                    icon={<FilePdfOutlined />}
                  >
                    Exportă Aviz PDF
                  </Button>
                </>
              ) : (
                <>
                  {paymentNotice?.status === PAYMENT_STATUS.APPROVED && (
                    <>
                      <Button
                        type="primary"
                        icon={<DollarOutlined />}
                        disabled={
                          !!paymentNotice?.paymentStatus ||
                          !paymentNotice.specialServices ||
                          paymentNotice.specialServices.length === 0
                        }
                        onClick={handleModal}
                      >
                        Verificare sold
                      </Button>
                      <Modal
                        title="Informații sold"
                        visible={modalVisible}
                        centered
                        footer={null}
                        onCancel={handleModal}
                      >
                        <Row className="payments__row" gutter={5}>
                          <Col span={8}>
                            <div className="declaration__field">
                              <div className="declaration__field-head">
                                <div className="declaration__field-title">
                                  {t(
                                    'entity.payments.benificiaryIdentificationNumber',
                                  )}
                                </div>
                              </div>
                              <Form.Item name="codPersonal2">
                                <Input
                                  disabled={disabled}
                                  style={{ textTransform: 'uppercase' }}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={8}>
                            <div className="declaration__field">
                              <div className="declaration__field-head">
                                <div className="declaration__field-title">
                                  {t('entity.payments.beneficiaryName')}{' '}
                                </div>
                              </div>
                              <Form.Item name="beneficiary">
                                <Input disabled={disabled} />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={8}>
                            <div className="declaration__field">
                              <div className="declaration__field-head">
                                <div className="declaration__field-title">
                                  Sold beneficiar
                                </div>
                              </div>
                              <Form.Item name="beneficiarySold">
                                <Input disabled={disabled} />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={24}>
                            <Button
                              key="beneficiaryPayment"
                              onClick={handleExtractPaymentsBeneficiary}
                              style={{ marginTop: '10px' }}
                              disabled={isButtonBeneficiaryDisabled}
                            >
                              Extrage plata beneficiar
                            </Button>
                          </Col>
                        </Row>
                        {!payerSwitch && (
                          <Row className="payments__row" gutter={5}>
                            <Col span={8}>
                              <div className="declaration__field">
                                <div className="declaration__field-head">
                                  <div className="declaration__field-title">
                                    {t(
                                      'entity.payments.payerIdentificationNumber',
                                    )}
                                  </div>
                                </div>
                                <Form.Item name="payerIdentificationNumber">
                                  <Input disabled={disabled} />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col span={8}>
                              <div className="declaration__field">
                                <div className="declaration__field-head">
                                  <div className="declaration__field-title">
                                    {t('entity.payments.payerName')}{' '}
                                  </div>
                                </div>
                                <Form.Item name="payerName">
                                  <Input disabled={disabled} />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col span={8}>
                              <div className="declaration__field">
                                <div className="declaration__field-head">
                                  <div className="declaration__field-title">
                                    Sold plătitor
                                  </div>
                                </div>
                                <Form.Item name="payerSold">
                                  <Input disabled={disabled} />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col span={24}>
                              <Button
                                key="paymentPayer"
                                onClick={handleExtractPaymentsPayer}
                                style={{ marginTop: '10px' }}
                                disabled={isButtonPayerDisabled}
                              >
                                Extrage plata plătitor
                              </Button>
                            </Col>
                          </Row>
                        )}
                      </Modal>
                    </>
                  )}

                  <Popconfirm
                    placement="topLeft"
                    title="Sunteți sigur ca doriți sa generați chitanța ?"
                    onConfirm={() => handleGeneratePayment(paymentNotice?.id)}
                    okText="Ok"
                    cancelText="Înapoi"
                    disabled={!!paymentNotice?.paymentStatus}
                  >
                    {paymentNotice?.status === PAYMENT_STATUS.APPROVED && (
                      <>
                        <Button
                          type="primary"
                          icon={<AuditOutlined />}
                          disabled={
                            !!paymentNotice?.paymentStatus ||
                            !paymentNotice.specialServices ||
                            paymentNotice.specialServices.length === 0
                          }
                        >
                          Trimitere la achitare MPAY
                        </Button>
                      </>
                    )}
                  </Popconfirm>
                  <Button
                    type="primary"
                    onClick={() => handleGeneratePaymentNoticePdf()}
                    icon={<FilePdfOutlined />}
                  >
                    Exportă Aviz PDF
                  </Button>
                  {paymentNotice?.paymentStatus !== 'PAID' && (
                    <Popconfirm
                      placement="topLeft"
                      title="Sunteți sigur ca doriți sa marcați avizul ca DRAFT ?"
                      onConfirm={() => setStatus(PAYMENT_STATUS.DRAFT)}
                      okText="Ok"
                      cancelText="Înapoi"
                    >
                      <Button>Marchează ca DRAFT</Button>
                    </Popconfirm>
                  )}
                </>
              )}
            </Space>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default PaymentNoticeEditForm;
