import {
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
} from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';

const EditModal = ({
  visible,
  onSave,
  onCancel,
  initialData,
  measuringUnits,
  customsDocumentTypes,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (initialData) {
      const formattedInitialData = {
        ...initialData,
        docDate: initialData.docDate ? dayjs(initialData.docDate) : null,
      };
      form.setFieldsValue(formattedInitialData);
    }
  }, [initialData, form]);

  const filteredMeasuringUnits = useMemo(
    () => measuringUnits?.content?.filter((unit) => unit.deleted === false),
    [measuringUnits?.content],
  );

  const filteredCustomsDocumentTypes = useMemo(
    () => customsDocumentTypes?.filter((type) => type.deleted === false),
    [customsDocumentTypes],
  );

  const handleSave = useCallback(() => {
    const docToSave = form.getFieldsValue();

    if (!docToSave?.numberDocHistory) {
      form.setFields([
        {
          name: 'numberDocHistory',
          errors: ['Număr act este obligatoriu'],
        },
      ]);
      return;
    }

    if (!initialData?.id && !initialData?.tempId) {
      docToSave.tempId = 'temp'.concat(new Date().getTime().toString());
    }

    if (onSave && typeof onSave === 'function') {
      onSave({ ...initialData, ...docToSave });
    }
  }, [form, initialData, onSave]);

  return (
    <Modal
      visible={visible}
      title={
        initialData.id || initialData.tempId ? 'Editare act' : 'Adăugare act'
      }
      okText="Ok"
      cancelText="Înapoi"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={handleSave}
    >
      <Form form={form} layout="vertical">
        <Divider plain>Act de însoțire</Divider>
        <Row gutter={15}>
          <Col span={12}>
            <Form.Item
              name="sender"
              label="Expeditor"
              rules={[
                {
                  max: 49,
                  message: 'Numărul maxim de caractere a fost depășit',
                },
              ]}
            >
              <Input style={{ textTransform: 'uppercase' }} maxLength={50} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="recipient"
              label="Destinatar"
              rules={[
                {
                  max: 49,
                  message: 'Numărul maxim de caractere a fost depășit',
                },
              ]}
            >
              <Input style={{ textTransform: 'uppercase' }} maxLength={50} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="docType" label="Tip act">
              <Select>
                {filteredCustomsDocumentTypes.map((type) => (
                  <Select.Option key={type.id} value={type.id}>
                    {type.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="numberDocHistory"
              label="Număr act"
              rules={[{ required: true, message: 'Câmpul este obligatoriu' }]}
            >
              <Input style={{ textTransform: 'uppercase' }} maxLength={20} />
            </Form.Item>
          </Col>
        </Row>

        <Divider plain>Date despre marfă</Divider>
        <Row gutter={15}>
          <Col span={12}>
            <Form.Item
              name="goodsCode"
              label="Cod marfă"
              rules={[
                {
                  max: 49,
                  message: 'Numărul maxim de caractere a fost depășit',
                },
              ]}
            >
              <Input style={{ textTransform: 'uppercase' }} maxLength={50} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="goodsName"
              label="Denumire marfă"
              rules={[
                {
                  max: 99,
                  message: 'Numărul maxim de caractere a fost depășit',
                },
              ]}
            >
              <Input style={{ textTransform: 'uppercase' }} maxLength={100} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="amountLei"
              label="Suma (Lei)"
              rules={[
                {
                  pattern: /^\d*(\.\d+)?$/,
                  message: 'Introduceți o valoare numerică validă',
                },
                () => ({
                  validator(_, value) {
                    const numericValue = parseFloat(value);

                    if (
                      value === undefined ||
                      value === '' ||
                      (numericValue >= 0 && value.length <= 100)
                    ) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        'Suma trebuie să fie un număr pozitiv sau zero și să nu depășească 100 de caractere',
                      ),
                    );
                  },
                }),
              ]}
            >
              <Input placeholder="Introduceți suma" maxLength={100} />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              name="quantity"
              label="Cantitate marfă"
              rules={[
                {
                  pattern: /^\d*(\.\d+)?$/,
                  message: 'Introduceți o valoare numerică validă',
                },
                () => ({
                  validator(_, value) {
                    if (
                      value === undefined ||
                      value === '' ||
                      value.length <= 100
                    ) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error('Numărul maxim de caractere a fost depășit'),
                    );
                  },
                }),
              ]}
            >
              <Input placeholder="Introduceți cantitatea" maxLength={100} />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item name="measuringUnit" label="Unitate">
              <Select style={{ width: '100%' }}>
                {filteredMeasuringUnits.map((unit) => (
                  <Select.Option key={unit.id} value={unit.id}>
                    {unit.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Divider plain>Raportul de expertiza</Divider>
        <Row gutter={15}>
          <Col span={12}>
            <Form.Item
              name="numberExpertReport"
              label="Număr raport"
              rules={[
                {
                  max: 19,
                  message: 'Numărul maxim de caractere a fost depășit',
                },
              ]}
            >
              <Input style={{ textTransform: 'uppercase' }} maxLength={20} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="docDate" label="Data raport">
              <DatePicker format="DD-MM-YYYY" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default EditModal;
