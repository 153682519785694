import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import useDebounce from '../hooks/useDebounce';

const InputDebounce = ({
  initialValue,
  defaultValue,
  delay = 300,
  onChange,
  onDebounceChange = () => {},
  loading,
  ...rest
}) => {
  const [value, setValue] = useState(initialValue);
  const debouncedValue = useDebounce(value, delay);

  useEffect(() => {
    onDebounceChange(value);
    // eslint-disable-next-line
  }, [debouncedValue]);

  const handleChange = (e) => {
    if (typeof onChange !== 'undefined' && typeof onChange === 'function') {
      onChange(e);
    }
    setValue(e.target.value);
  };

  return (
    <Input
      className="input-debounce"
      value={initialValue}
      onChange={handleChange}
      loading={loading}
      defaultValue={defaultValue}
      allowClear
      {...rest}
    />
  );
};

export default InputDebounce;
