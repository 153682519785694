import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import WithholdingReasonsList from '../../../../components/admin/taxonomies/withholdingReasons/WithholdingReasonsList';

const WithholdingReasons = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="withholdingReasons">
      <Tabs.TabPane
        tab={t('entity.admin.withholdingReasons._plural')}
        key="withholdingReasons"
      >
        <WithholdingReasonsList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default WithholdingReasons;
