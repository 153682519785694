import { useEffect } from 'react';

function usePageRefresh(callback) {
  useEffect(() => {
    const handleBeforeUnload = () => {
      if (callback && typeof callback === 'function') {
        callback();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [callback]);
}

export default usePageRefresh;
