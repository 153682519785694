import { get } from '../../helpers/api';

export const BASE_URI = '/backend/api';
export const SERVICE_URI = '/v1/xref';

export const getVehiclesRef = (code) =>
  get(`${BASE_URI}${SERVICE_URI}/vehicle/${code}`);

export const getPersonsRef = (code) =>
  get(`${BASE_URI}${SERVICE_URI}/person/${code}`);
export const getPlatesRef = (number) =>
  get(`${BASE_URI}${SERVICE_URI}/plate/${number}`);

export const getPerson = (id) =>
  get(`${BASE_URI}${SERVICE_URI}/get-person/${id}`);

export const getVehicle = (id) =>
  get(`${BASE_URI}${SERVICE_URI}/get-vehicle/${id}`);
