import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import VignetteErrorCodeList from '../../../../components/admin/taxonomies/vignetteErrorCodes/VignetteErrorCodeList';

const VignetteErrorCode = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="vignetteErrorCode">
      <Tabs.TabPane
        tab={t('entity.admin.vignetteErrorCode._plural')}
        key="vignetteErrorCode"
      >
        <VignetteErrorCodeList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default VignetteErrorCode;
