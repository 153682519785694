import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Divider, Switch, Table, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { findAll as findAllMeasuringUnits } from '../../../../services/taxonomies/measuring-units';
import { findAll as findAllCustomsDocumentTypes } from '../../../../services/taxonomies/customs-document-type';
import useDictionaries from '../../../../hooks/useDictionaries';
import Column from '../../../../helpers/Columns';
import DeleteItemIcon from '../../../buttons/DeleteItemIcon';
import EditItemIcon from '../../../buttons/EditItemIcon';
import EditModal from './EditGoodsDocumentModal';

const dictionaries = {
  customsDocumentTypes: findAllCustomsDocumentTypes,
  measuringUnits: findAllMeasuringUnits,
};

const GoodsDocumentTable = ({
  initial = [],
  onGoodsDocumentsUpdate,
  dividerRender = true,
  buttonRender = true,
  actionColRender = true,
  disabled,
}) => {
  const [goodsDocuments, setGoodsDocuments] = useState(initial);
  const [editingGoodsDocument, setEditingGoodsDocument] = useState(null);
  const [showActTable, setShowActTable] = useState(initial?.length > 0);

  const [{ measuringUnits, customsDocumentTypes }] =
    useDictionaries(dictionaries);

  useEffect(() => {
    if (onGoodsDocumentsUpdate) {
      onGoodsDocumentsUpdate(goodsDocuments);
    }
  }, [goodsDocuments, onGoodsDocumentsUpdate]);

  useEffect(() => {
    if (
      onGoodsDocumentsUpdate &&
      typeof onGoodsDocumentsUpdate === 'function'
    ) {
      onGoodsDocumentsUpdate(goodsDocuments);
    }
  }, [goodsDocuments, onGoodsDocumentsUpdate]);

  const addGoodsDocument = () => {
    setEditingGoodsDocument({});
  };
  const handleEdit = (document) => {
    setEditingGoodsDocument(document);
  };

  const saveGoodsDocument = useCallback((entity) => {
    setGoodsDocuments((prev) => [
      ...prev.filter(
        (el) => el.id !== entity.id || el.tempId !== entity.tempId,
      ),
      entity,
    ]);
    setEditingGoodsDocument(null);
  }, []);

  const deleteGoodsDocument = useCallback((id) => {
    setGoodsDocuments((prev) =>
      prev.filter((el) => el.id !== id && el.tempId !== id),
    );
  }, []);

  const columns = useMemo(() => {
    const baseColumns = [
      {
        title: 'Nr.',
        key: 'nr',
        render: (_, __, index) => index + 1,
        width: 50,
      },
      Column.text('sender', 'Expeditor', {
        width: 100,
        sort: true,
        filter: true,
      }),
      Column.text('recipient', 'Destinatar', {
        width: 100,
        sort: true,
      }),
      {
        sort: false,
        title: 'Tip act',
        dataIndex: 'docType',
        key: 'docType',
        width: 100,
        render: (docTypeId) => {
          const docType = customsDocumentTypes?.content?.find(
            (type) => type.id === docTypeId,
          );
          return docType ? `${docType.name}` : null;
        },
      },
      Column.text('numberDocHistory', 'Număr act', {
        width: 100,
        sort: true,
      }),
      Column.text('goodsCode', 'Cod marfă', {
        width: 100,
        sort: true,
      }),
      Column.text('goodsName', 'Denumire marfă ', {
        width: 100,
        sort: true,
      }),
      Column.text('amountLei', 'Suma (Lei)', {
        width: 100,
        sort: true,
      }),
      Column.text('quantity', 'Cantitate marfă', {
        width: 100,
        sort: true,
      }),
      {
        sort: false,
        title: 'Unitate de măsură',
        dataIndex: 'measuringUnit',
        key: 'measuringUnit',
        width: 100,
        render: (measuringUnitId) => {
          const unit = measuringUnits?.content?.find(
            (u) => u.id === measuringUnitId,
          );
          return unit ? `${unit.name}` : null;
        },
      },
      Column.text('numberExpertReport', 'Număr raport', {
        width: 100,
        sort: true,
        sorter: (a, b) => a.sender.localeCompare(b.sender),
      }),
      Column.date('docDate', 'Data raport', {
        width: 100,
        sort: true,
        format: 'DD-MM-YYYY',
      }),
    ];
    if (actionColRender) {
      baseColumns.push(
        Column.actions(
          'Acțiuni',
          (record) => (
            <span style={{ textAlign: 'right' }}>
              <Tooltip title="Editare">
                <EditItemIcon
                  action={() =>
                    handleEdit({
                      ...record,
                      docDate: record.docDate ? dayjs(record.docDate) : null,
                    })
                  }
                  disabled={record.deleted}
                />
              </Tooltip>
              <Tooltip>
                <DeleteItemIcon
                  title="Sunteți sigur(ă) că doriți să ștergeți actul?"
                  isPromise={false}
                  action={() => deleteGoodsDocument(record.id || record.tempId)}
                />
              </Tooltip>
            </span>
          ),
          {
            width: 100,
          },
        ),
      );
    }

    return baseColumns;
  }, [
    deleteGoodsDocument,
    customsDocumentTypes,
    measuringUnits,
    actionColRender,
  ]);

  return (
    <>
      {dividerRender && (
        <Divider>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <Switch
              onChange={() => setShowActTable(!showActTable)}
              checked={showActTable}
            />
            <span>Acte de însoțire și mărfuri</span>
          </div>
        </Divider>
      )}
      {showActTable && (
        <>
          {buttonRender && (
            <Button
              onClick={addGoodsDocument}
              type="primary"
              style={{ marginBottom: 10, marginLeft: 'auto', display: 'block' }}
              icon={<PlusOutlined />}
              disabled={disabled}
            >
              Adaugă act
            </Button>
          )}
          <Table
            columns={columns}
            dataSource={goodsDocuments}
            rowKey="id"
            pagination={false}
          />
        </>
      )}
      {editingGoodsDocument && (
        <EditModal
          visible={!!editingGoodsDocument}
          initialData={editingGoodsDocument}
          onSave={saveGoodsDocument}
          onCancel={() => setEditingGoodsDocument(null)}
          measuringUnits={measuringUnits}
          customsDocumentTypes={customsDocumentTypes.content}
        />
      )}
    </>
  );
};

export default GoodsDocumentTable;
