export const Directions = {
  IN: 'INTRARE',
  OUT: 'IEȘIRE',
  ALL: 'INTRARE/ IEȘIRE',
};

export const directionsContent = {
  content: [
    { id: 'IN', code: 'IN', name: Directions.IN },
    { id: 'OUT', code: 'OUT', name: Directions.OUT },
    { id: 'ALL', code: 'ALL', name: Directions.ALL },
  ],
};
