import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import CustomsPaymentsList from '../../../../components/admin/taxonomies/customsPayments/CustomsPaymentsList';

const CustomsPayments = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="customsPayments">
      <Tabs.TabPane
        tab={t('entity.admin.customsPayments._plural')}
        key="customsPayments"
      >
        <CustomsPaymentsList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default CustomsPayments;
