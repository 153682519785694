import React, { useEffect, useState } from 'react';
import { useEditPage } from '../../../hooks/useEditPage';
import AppLoader from '../../../components/auth/AppLoader';
import PaymentNoticeEditForm from '../../../components/payment-notice/PaymentNoticeEditForm';
import {
  changeStatus,
  create,
  findOne,
  update,
} from '../../../services/payments/payment-notice';
import { findOne as findServiceRequest } from '../../../services/lucrative/special-services-request';
import { useRouteParams } from '../../../hooks/useRouteParams';

const initial = {};
const PaymentNotice = () => {
  const { serviceRequestId } = useRouteParams('serviceRequestId');
  const [serviceRequest, setServiceRequest] = useState(null);

  const [paymentNotice, handleSubmit, handleCancel, errors, loading, reload] =
    useEditPage({
      initial,
      entityPath: '/payments/payments-notice',
      goBackPath: '/payments',
      pushOnEntityPage: true,
      existent: findOne,
      onCreate: create,
      onUpdate: update,
    });

  useEffect(() => {
    if (!paymentNotice?.id && serviceRequestId) {
      findServiceRequest(serviceRequestId).then((res) =>
        setServiceRequest(res),
      );
    } else {
      setServiceRequest(null);
    }
  }, [paymentNotice?.id, serviceRequestId]);

  return (
    <>
      <div className={`loaderOverlay ${loading ? 'active' : ''}`}>
        <AppLoader />
      </div>
      {paymentNotice !== undefined && (
        <PaymentNoticeEditForm
          paymentNotice={paymentNotice}
          serviceRequest={serviceRequest}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          errors={errors}
          changeStatus={changeStatus}
          reload={reload}
        />
      )}
    </>
  );
};

export default PaymentNotice;
