import React, { useCallback, useState } from 'react';
import { PageHeader } from '@ant-design/pro-layout';

import { Form } from 'antd';
import { useTranslation } from 'react-i18next';

import WebServiceSelect from '../WebServiceSelect';
import SFSOrganizatiiForm from './SFSOrganizatiiForm';
import ANTAServicesForm from './ANTAServicesForm';
import SFSDebtsForm from './SFSDebtsForm';
import VignetteForm from './VignetteForm';

const WebServicesSection = () => {
  const { t } = useTranslation();
  const [wsmState, setWsmState] = useState('');

  const Example = () => {
    switch (wsmState) {
      case 'SFSORGAN':
        return <SFSOrganizatiiForm />;
      case 'ANTA':
        return <ANTAServicesForm />;
      case 'SFSDEBTS':
        return <SFSDebtsForm />;
      case 'VIGNETTE':
        return <VignetteForm />;
      default:
        return null;
    }
  };

  const handleServiceChange = useCallback((e) => {
    setWsmState(e);
  }, []);

  return (
    <>
      <PageHeader title="Interpelare manuală" />
      <Form.Item
        name="serviceType"
        label={t('entity.frontiera.serviceType')}
        labelCol={{ span: 24 }}
      >
        <WebServiceSelect onSelect={handleServiceChange} />
        {Example()}
      </Form.Item>
    </>
  );
};

export default WebServicesSection;
