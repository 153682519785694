import React, { useCallback, useContext } from 'react';
import { Divider } from 'antd';
import { ChatEntity } from './components/ChatEntity';
import AuthContext from '../auth';
import EntityTypes from './enums/EntityTypes';

const MicroUserList = ({
  onSelect,
  selectedEntityId,
  content,
  rightSideSmallChat,
}) => {
  const { user } = useContext(AuthContext);
  const handleSelectUser = useCallback(
    (val) => {
      if (onSelect && typeof onSelect === 'function') {
        onSelect({ ...val, type: EntityTypes.USER });
      }
    },
    [onSelect],
  );

  const renderUsers = useCallback(() => {
    return content.slice(0, 10).map((chat) => {
      const el = chat?.chatter || {};
      return (
        <ChatEntity
          key={chat.id}
          entityId={chat.id}
          onClick={() => handleSelectUser(chat)}
          messageCount={chat.unreadMessages}
          entityName={el.id !== user.id ? el.name : 'Mesaje salvate'}
          isSelected={chat.id === selectedEntityId}
          status={chat.status}
          entityType={EntityTypes.USER}
          base64Image={el.base64Image}
          imageId={el.imageId}
          rightSideSmallChat={rightSideSmallChat}
        />
      );
    });
  }, [
    content,
    selectedEntityId,
    handleSelectUser,
    user.id,
    rightSideSmallChat,
  ]);

  return (
    <div className="chat__small-userlist--container">
      {content.length > 0 ? <Divider /> : null}
      <div className="chat__small-userlist">
        {content ? renderUsers() : null}
      </div>
    </div>
  );
};
export default MicroUserList;
