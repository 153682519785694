import React from 'react';
import { Button, notification, Tooltip } from 'antd';
import { CheckOutlined, ReloadOutlined } from '@ant-design/icons';
import ViewItemIcon from '../buttons/ViewItemIcon';
import EditItemIcon from '../buttons/EditItemIcon';
import { hasPermission } from '../auth';
import GeneratePdfReportIcon from '../buttons/GeneratePdfReportIcon';
import { generatePdfInNewTab } from '../../services/transactions/transactions';

export const getActionColumn = ({
  path = '',
  record = {},
  permissions = [],
  reloadPaymentStatus = () => {},
  markAsPaid = () => {},
  t = () => {},
}) => {
  const handleGeneratePaymentNoticePdf = () => {
    const noticeId = record.id;

    generatePdfInNewTab(`/payment-notice-report-pdf/${noticeId}`)
      .then(() => {
        notification.success({
          message: t('messages.report.generated.success'),
          duration: 3,
        });
      })
      .catch((err) => {
        if (err)
          notification.error({
            message: t('errors.report.generation.failed'), // Localized error message
          });
      });
  };
  if (record.deleted) {
    return (
      <span style={{ textAlign: 'right' }}>
        <ViewItemIcon path={path} />
      </span>
    );
  }
  if (hasPermission(permissions, 'PAYMENT_NOTICE')) {
    return (
      <span style={{ textAlign: 'right' }}>
        {record.status !== 'DRAFT' && (
          <>
            <Tooltip title="Marcheaza ca PLATIT" placement="topLeft">
              <Button
                onClick={() => markAsPaid(record?.id)}
                shape="circle"
                type="link"
                icon={<CheckOutlined />}
                hidden={
                  record?.paymentStatus !== 'TO_PAY' ||
                  record.status === 'REJECTED' ||
                  record.status === 'DRAFT'
                }
              />
            </Tooltip>
            <Tooltip title="Verifică statutul plății" placement="topLeft">
              <Button
                shape="circle"
                type="link"
                icon={<ReloadOutlined />}
                hidden={
                  !record?.paymentStatus ||
                  record?.paymentStatus === 'PAID' ||
                  record?.paymentStatus === 'CONFIRMED'
                }
                onClick={() => reloadPaymentStatus(record.id)}
              />
            </Tooltip>
            {/* <Tooltip title="Verifică statut trezorerie" placement="topLeft">
              <Button
                shape="circle"
                type="link"
                icon={<ReloadOutlined />}
                hidden={record?.paymentStatus !== 'PAID'}
                onClick={() => reloadConfirmStatus(record.id)}
              />
            </Tooltip> */}
          </>
        )}
        <EditItemIcon path={path} />
        <GeneratePdfReportIcon
          fileName={`${record.name}.pdf`}
          path={path}
          method={() => handleGeneratePaymentNoticePdf()}
          hintText={t('actions.exportPdfReport')}
        />
      </span>
    );
  }
  return <></>;
};
