import React, { useContext, useState } from 'react';
import { defaultStyles, FileIcon } from 'react-file-icon';
import { useClickOutside } from '../../../hooks/useClickOutside';
import ChatMessageMore from './ChatMessageMore';
import Icon from '../../Icon';
import MessageTypes from '../enums/MessageTypes';
import AuthContext from '../../auth';
import { stringLengthValidator } from '../../../services/utils/validator/Validator';
import { downloadAttachment } from '../chat-service/attachment-service';

const ChatMessage = ({ message, onClick }) => {
  const { user } = useContext(AuthContext);
  const [messageMore, setMessageMore] = useState(false);

  const checkLink = (mess) => {
    const regex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    if (regex.test(mess)) {
      return true;
    }
    return false;
  };
  const closeMessage = () => {
    setMessageMore(false);
  };
  const outside = useClickOutside(closeMessage, messageMore);

  const handleMessageMore = () => {
    setMessageMore((prev) => !prev);
  };

  const containsOnlyEmojis = (text) => {
    const emojiRegex =
      /^(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])+$/;
    return emojiRegex.test(text);
  };

  const handleClickOnMessageFunctions = (selectedFunction) => {
    onClick({ messageType: selectedFunction, message });
    setMessageMore(false);
  };

  const displayMessageTime = (time) => {
    const formattedTime = new Date(time).toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
    return formattedTime;
  };

  const handleDownloadFile = (attach) => {
    if (attach) {
      downloadAttachment(attach.messageId, attach.fileName);
    }
  };

  return (
    <div
      className={`chat__messages-${
        user.id === message.sourceId ? 'me' : 'friend'
      } ${containsOnlyEmojis(message.messageBody) ? 'only-icons' : ''} ${
        message.isDeleted ? 'deleted' : ''
      } ${message.attachments ? 'attachments' : ''} `}
    >
      <div className="chat__message-user--name">{message.senderFullName}</div>
      {message?.messageType === MessageTypes.REPLY &&
      message.reply?.messageBody &&
      !message.isDeleted ? (
        <div className="chat__messages-reply--text">
          <Icon name="chatReply" className="chat__messages-icon--reply" />
          <div>{stringLengthValidator(message.reply?.messageBody, 40)}</div>
          <div>
            {stringLengthValidator(
              message.reply?.messageBody?.attachmentComment,
              40,
            )}
          </div>
        </div>
      ) : null}
      {message?.messageType === MessageTypes.FORWARD && !message.isDeleted ? (
        <div className="chat__messages-reply--text">
          <Icon name="messageReply" className="chat__messages-icon--reply" />
          <div>{stringLengthValidator(message.reply?.senderFullName, 40)}</div>
          <div>
            {stringLengthValidator(
              message.reply?.messageBody?.attachmentComment,
              40,
            )}
          </div>
        </div>
      ) : null}
      {checkLink(message.messageBody) ? (
        <a target="_blank" rel="noreferrer" href={`${message.messageBody}`}>
          {message.messageBody}
        </a>
      ) : (
        <div style={{ whiteSpace: 'pre-line' }}>{message.messageBody}</div>
      )}
      {message.attachments ? (
        <div className="chat__messages-filetype">
          {message?.attachments?.map((attachment) => {
            const lastDotIndex = attachment.fileName?.lastIndexOf('.');
            const ext = attachment.fileName?.substring(lastDotIndex + 1);
            return (
              <div className="chat__messages-file" key={attachment.fileName}>
                <FileIcon
                  className="chat__upload-type"
                  extension={ext}
                  {...defaultStyles[ext]}
                />
                <div className="chat__upload-attachment--file">
                  <button
                    type="button"
                    onClick={() => handleDownloadFile(attachment)}
                  >
                    <Icon
                      name="messageDownload"
                      className="chat__upload-attachment--download"
                    />
                  </button>
                </div>
                <div className="chat__messages-file--title">
                  {stringLengthValidator(attachment.fileName, 40)}
                </div>
              </div>
            );
          })}

          <div className="chat__messages-attachment--comment">
            {message.attachmentComment}
          </div>
        </div>
      ) : null}
      <>
        {!message.isDeleted ? (
          <>
            <div className="chat__message-info">
              {message?.editDate ? (
                <div className="chat__message-edited">editat</div>
              ) : null}

              <div className="chat__message-time">
                {displayMessageTime(message.messageDate)}
              </div>
              {user.id === message.sourceId ? (
                <div className="chat__message-read">
                  <Icon name="check" className="chat__message-check" />
                  {message.readBy?.length > 0 ? (
                    <Icon
                      name="check"
                      className="chat__message-check read-check"
                    />
                  ) : null}
                </div>
              ) : null}
            </div>

            <div className="chat__messages-more--btns  ">
              <button
                type="button"
                className="chat__messages-more"
                onClick={handleMessageMore}
              >
                <Icon name="chatDots" className="chat__messages-more--icon" />
              </button>
              <button
                type="button"
                className="chat__messages-reply"
                onClick={() =>
                  handleClickOnMessageFunctions(MessageTypes.REPLY)
                }
              >
                <Icon name="chatReply" className="chat__messages-reply--icon" />
              </button>
            </div>
            <div
              className={`chat__message-more ${messageMore ? 'active' : ''}`}
              ref={outside}
            >
              <ChatMessageMore
                key={message.id}
                onSelect={handleClickOnMessageFunctions}
                sourceId={message.sourceId}
                isAttachment={message.messageType === MessageTypes.ATTACHMENT}
                messageBody={message.messageBody}
              />
            </div>
          </>
        ) : null}
      </>
    </div>
  );
};

export default ChatMessage;
