import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Input,
  Row,
  Col,
  Space,
  Select,
  Checkbox,
  InputNumber,
  notification,
  Button,
  Radio,
  DatePicker,
  Modal,
} from 'antd';
import { FileTextOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import RequiredLabel from '../../../services/utils/RequiredLabel';

import SaveButton from '../../buttons/SaveButton';
import CancelButton from '../../buttons/BackButton';

import useFormErrors from '../../../hooks/useFormErrors';
import CountrySelect from '../../CountrySelect';
import EditPreciousPropertyRecordsForm from './EditPreciousProductRecordsForm';
import EditProductsForm from './EditProductsForm';
import EditBagProductsForm from './EditBagProductsForm';
import { useRouteParams } from '../../../hooks/useRouteParams';
import { generatePdfInNewTab } from '../../../services/transactions/transactions';
import useDictionaries from '../../../hooks/useDictionaries';
import declarationImage from '../../../assets/declarationImage.png';
import Icon from '../../Icon';
import {
  changeStatus,
  updateAndCaculate,
  clearCustoms,
  deleteUndeclaredVehicle,
} from '../../../services/admin/declaration';
import CustomsPostSelect from '../../CustomsPostSelect';
import { findAll as findAllFuelTypes } from '../../../services/taxonomies/fuel-types';
import { findAll as findAllFinalDestinations } from '../../../services/taxonomies/final-destinations';
import { findAll as findAllVehicleCategories } from '../../../services/taxonomies/vehicle-category-declaration';
import { findAll as findAllVehicleBrands } from '../../../services/taxonomies/vehicle-brands';
import AppLoader from '../../auth/AppLoader';
import AccizeCalculator from './AccizeCalculator';

const dictionaries = {
  fuelTypes: findAllFuelTypes,
  finalDestinations: findAllFinalDestinations,
  vehicleCategories: findAllVehicleCategories,
  vehicleBrands: findAllVehicleBrands,
};

const EditDeclarationForm = ({ post, onSubmit, onCancel, errors }) => {
  const [load, setLoad] = useState(false);
  const [kwAccepted, setKwAccepted] = useState(false);
  const [cmAccepted, setCmAccepted] = useState(false);
  const [{ fuelTypes, finalDestinations, vehicleCategories, vehicleBrands }] =
    useDictionaries(dictionaries);
  const finalDestinationSubs = useMemo(() => {
    return finalDestinations?.content?.flatMap((fd) =>
      fd.finalDestinationSubs.map((fds) => ({ ...fds, superCode: fd.code })),
    );
  }, [finalDestinations]);
  const { t } = useTranslation();
  const { id, isNew } = useRouteParams();
  const [directionValue, setDirectionValue] = useState(1);
  const [status, setStatus] = useState(post.status);
  const [showModal, setShowModal] = useState(false);
  const [showCalculations, setShowCalculations] = useState(false);
  const [showCustomsClearance, setShowCustomsClearance] = useState(false);
  const [enableCustomsClearanceFields, setEnableCustomsClearanceFields] =
    useState(true);
  const [form] = Form.useForm();
  const onChangeFuelType = useCallback(
    (value) => {
      const fuelType = fuelTypes?.content.find((it) => it.id === value);
      setCmAccepted(fuelType?.cmCapacity);
      setKwAccepted(fuelType?.kwCapacity);
      if (!fuelType?.cmCapacity) {
        form.setFieldValue('capacity', null);
        form.setFieldValue('hybridType', null);
      }
      if (!fuelType?.kwCapacity) {
        form.setFieldValue('capacityKW', null);
        form.setFieldValue('hybridType', null);
      }
    },
    [fuelTypes, form],
  );
  const handleCalculateTransportTax = useCallback(() => {
    form.validateFields().then(() => {
      updateAndCaculate(post.id, form.getFieldValue())
        .then((val) => {
          notification.success({
            message: 'Acciza a fost calculată',
            duration: 3,
          });
          return val.json();
        })
        .then((val) => {
          form.setFieldsValue({
            ...val,
            firstRegistration: dayjs(val?.firstRegistration),
            finalDestination: finalDestinationSubs.find(
              (fds) =>
                fds.type === 'IM' && fds.superCode === 'A' && fds.code === 'H1',
            )?.id,
            products: val?.products?.map((value) => ({
              ...value,
              permissionDate: dayjs(value.permissionDate),
            })),
          });

          setShowCalculations(true);
        })
        .catch((err) => {
          if (err)
            notification.error({
              message: 'A apărut o eroare la caclularea accizei',
            });
        });
    });
  }, [form, post, finalDestinationSubs]);

  const handleClearCustoms = useCallback(() => {
    form.validateFields().then(() => {
      clearCustoms(post.id)(form.getFieldValue())
        .then((res) => {
          res.json().then((obj) => {
            if (obj?.isVehicleRegistered) {
              setEnableCustomsClearanceFields(false);
            }
          });
          notification.success({
            message: 'Vămuirea a fost efectuată cu succes',
            duration: 3,
          });
        })
        .catch((err) => {
          if (err)
            notification.error({
              message: 'A apărut o eroare la efectuarea vămuirii',
            });
        });
    });
  }, [form, post.id]);

  const handleDeleteUndeclaredVehicle = useCallback(() => {
    form.validateFields().then(() => {
      deleteUndeclaredVehicle(post.id)(form.getFieldValue())
        .then((res) => {
          res.json().then((obj) => {
            if (!obj?.isVehicleRegistered) {
              setEnableCustomsClearanceFields(true);
            }
          });

          notification.success({
            message: 'Vamuirea a fost anulată',
            duration: 3,
          });
        })
        .catch((err) => {
          if (err)
            notification.error({
              message: 'A apărut o eroare la anularea vămuirii',
            });
        });
    });
  }, [form, post]);

  useFormErrors(form, errors);
  const handlePdf = useCallback(() => {
    setLoad(true);
    generatePdfInNewTab(`/declaration-pdf/${id}`, {})
      .then(() => {
        notification.success({
          message: t('messages.report.generated.success'),
          duration: 3,
        });
        setLoad(false);
      })
      .catch((err) => {
        if (err)
          notification.error({
            message: 'A apărut o eroare la generarea declarației',
          });
        setLoad(false);
      });
  }, [t, id]);
  const approve = useCallback(async () => {
    form.validateFields().then(async () => {
      await onSubmit(form.getFieldValue());
      changeStatus(id, 'APPROVED')
        .then(() => {
          form.setFieldsValue({ status: 'APPROVED' });
          setStatus('APPROVED');
          notification.success({
            message: 'Declarația călătorului a fost aprobată',
            duration: 3,
          });
        })
        .catch((err) => {
          if (err)
            notification.error({
              message: 'A apărut o eroare la schimbarea statutului',
            });
        });
    });
  }, [form, id, onSubmit]);
  const reject = useCallback(() => {
    form.validateFields().then(async () => {
      await onSubmit(form.getFieldValue());
      changeStatus(id, 'REJECTED')
        .then(() => {
          form.setFieldsValue({ status: 'REJECTED' });
          setStatus('REJECTED');
          notification.success({
            message: 'Declarația călătorului a fost respinsă',
            duration: 3,
          });
        })
        .catch((err) => {
          if (err)
            notification.error({
              message: 'A apărut o eroare la schimbarea statutului',
            });
        });
    });
  }, [form, id, onSubmit]);

  useEffect(() => {
    if (post?.isVehicleRegistered) {
      setEnableCustomsClearanceFields(false);
    }
    if (
      post?.customsClearanceIDNP ||
      post?.customsClearancePlateNumber ||
      post?.customsClearanceVehicleVin
    ) {
      setShowCustomsClearance(true);
    }

    if (post.finalDestination) {
      const tempDestination = finalDestinations?.content?.find((item) =>
        item.finalDestinationSubs
          .map((it) => it.id)
          .includes(post.finalDestination),
      );
      form.setFieldsValue({ finalDestinationType: tempDestination?.id });
    }
    form.setFieldsValue({
      ...post,
      showTransport: post.transportBrand,
      firstRegistration: post?.firstRegistration
        ? dayjs(post?.firstRegistration)
        : null,
      finalDestination: finalDestinationSubs.find(
        (fds) =>
          fds.type === 'IM' && fds.superCode === 'A' && fds.code === 'H1',
      )?.id,
      products: post?.products?.map((value) => ({
        ...value,
        permissionDate: dayjs(value.permissionDate),
      })),
    });

    const fuelType = fuelTypes?.content.find((it) => it.id === post.fuelType);
    setCmAccepted(fuelType?.cmCapacity);
    setKwAccepted(fuelType?.kwCapacity);
    if (!fuelType?.cmCapacity) {
      form.setFieldValue('capacity', null);
    }
    if (!fuelType?.kwCapacity) {
      form.setFieldValue('capacityKW', null);
    }
  }, [post, form, finalDestinationSubs, finalDestinations, fuelTypes]);

  const onProductChange = () => {
    if (
      !(
        form.getFieldValue('havingWeapon') ||
        form.getFieldValue('havingDrugs') ||
        form.getFieldValue('havingArt') ||
        form.getFieldValue('havingInformationCarriers') ||
        form.getFieldValue('havingLiving') ||
        form.getFieldValue('havingRadioactiveSubstances') ||
        form.getFieldValue('havingPoisons') ||
        form.getFieldValue('havingProducts') ||
        form.getFieldValue('havingPersonalProducts') ||
        form.getFieldValue('havingComercialProducts')
      )
    ) {
      form.setFieldsValue({ products: undefined });
    }
  };
  const onDirectionChange = (e) => {
    setDirectionValue(e.target.value);
  };
  const onBagProductChange = () => {
    if (
      !(
        form.getFieldValue('unaccompaniedBag') ||
        form.getFieldValue('accompaniedBag')
      )
    ) {
      form.setFieldsValue({ bagProducts: undefined });
    }
  };

  return (
    <>
      <div className={`loaderOverlay ${load ? 'active' : ''}`}>
        <AppLoader />
      </div>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          ...post,
          showTransport:
            post.transportBrand ||
            post.fuelType ||
            post.firstRegistration ||
            post.transportModel ||
            post.motorNumber ||
            post.mileage ||
            post.transportVariant ||
            post.bodyNumber ||
            post.placeNumber ||
            post.transportVersion ||
            post.capacity ||
            post.capacityKW ||
            post.placeNumberLegs ||
            post.fabricationYear ||
            post.colour ||
            post.massMax ||
            post.vehicleCategory ||
            post.finalDestination ||
            post.weight ||
            post.price ||
            post.transportTaxation ||
            post.hybridType,
          firstRegistration: dayjs(post?.firstRegistration),
          products: post?.products?.map((value) => ({
            ...value,
            permissionDate: dayjs(value.permissionDate),
          })),
          finalDestination: finalDestinationSubs.find(
            (fds) =>
              fds.type === 'IM' && fds.superCode === 'A' && fds.code === 'H1',
          )?.id,
        }}
        onFinish={onSubmit}
      >
        <div className="declaration">
          <div className="declaration__header">
            <img
              className="declaration__header-image"
              src={declarationImage}
              alt="declarationImage"
            />
            <h3 className="declaration__header-title">
              DECLARAȚIA CĂLĂTORULUI
            </h3>
            <div className="declaration__header-direction">
              <Form.Item
                name="direction"
                disabled={status !== 'DRAFT' && !isNew}
                rules={[
                  {
                    required: true,
                    message: 'Câmpul este obligatoriu',
                  },
                ]}
              >
                <Radio.Group
                  onChange={onDirectionChange}
                  value={directionValue}
                  disabled={status !== 'DRAFT' && !isNew}
                >
                  <Radio value="IN">Intrare</Radio>
                  <Radio value="OUT">Ieșire</Radio>
                  <Radio value="ALL">Tranzit</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
          <div className="declaration__fields">
            <Row className="declaration__row" gutter={5}>
              <Col span={12}>
                <div className="declaration__field">
                  <div className="declaration__field-head">
                    <div className="declaration__field-number">1</div>
                    <div className="declaration__field-title">
                      <RequiredLabel
                        title={t('entity.admin.declaration.fullname')}
                      />
                    </div>
                  </div>
                  <Form.Item
                    name="fullname"
                    rules={[
                      {
                        required: true,
                        message: 'Câmpul este obligatoriu',
                      },
                    ]}
                  >
                    <Input
                      disabled={status !== 'DRAFT' && !isNew}
                      style={{
                        textTransform: 'uppercase',
                        fontWeight: '600',
                      }}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <Row className="declaration__row" gutter={5}>
                  <Col span={12}>
                    <div className="declaration__field">
                      <div className="declaration__field-head">
                        <div className="declaration__field-number">2</div>
                        <div className="declaration__field-title">
                          {t('entity.admin.declaration.citizenship')}
                        </div>
                      </div>
                      <Form.Item name="citizenship">
                        <Input
                          disabled={status !== 'DRAFT' && !isNew}
                          style={{
                            textTransform: 'uppercase',
                            fontWeight: '600',
                          }}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="declaration__field">
                      <div className="declaration__field-head">
                        <div className="declaration__field-number">4</div>
                        <div className="declaration__field-title">
                          <RequiredLabel
                            title={t('entity.admin.declaration.customPost')}
                          />
                        </div>
                      </div>
                      <Form.Item
                        name="postCode"
                        rules={[
                          {
                            required: true,
                            message: 'Câmpul este obligatoriu',
                          },
                        ]}
                      >
                        <CustomsPostSelect
                          disabled={!isNew || post.postCode}
                          keys="code"
                          allowClear={false}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="declaration__row" gutter={5}>
              <Col span={6}>
                <div className="declaration__field">
                  <div className="declaration__field-head">
                    <div className="declaration__field-number">3</div>
                    <div className="declaration__field-title">
                      <RequiredLabel
                        title={t('entity.admin.declaration.idnp')}
                      />
                    </div>
                  </div>
                  <Form.Item
                    name="idnp"
                    rules={[
                      {
                        required: true,
                        message: 'Câmpul este obligatoriu',
                      },
                    ]}
                  >
                    <Input
                      disabled={status !== 'DRAFT' && !isNew}
                      style={{
                        textTransform: 'uppercase',
                        fontWeight: '600',
                      }}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={6}>
                <div className="declaration__field">
                  <div className="declaration__field-head">
                    <div className="declaration__field-number">4</div>
                    <div className="declaration__field-title">
                      {t('entity.admin.declaration.documentTypeAndNumber')}
                    </div>
                  </div>
                  {/* modificat documentType & documentNumber in documentTypeAndNumber */}
                  <Form.Item name="documentTypeAndNumber">
                    <Input
                      disabled={status !== 'DRAFT' && !isNew}
                      style={{
                        textTransform: 'uppercase',
                        fontWeight: '600',
                      }}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.direction !== currentValues.direction
                  }
                >
                  {({ getFieldValue }) => (
                    <div>
                      <div className="declaration__field">
                        <div className="declaration__field-head">
                          <div className="declaration__field-number">4</div>
                          <div className="declaration__field-title">
                            {t('entity.admin.declaration.destinationCountryId')}
                            <span>/</span>
                            {t('entity.admin.declaration.departureCountryId')}
                          </div>
                        </div>
                        <Row gutter={5}>
                          <Col span={12}>
                            {['ALL', 'IN'].includes(
                              getFieldValue('direction'),
                            ) && (
                              <Form.Item name="departureCountryId">
                                <CountrySelect
                                  disabled={status !== 'DRAFT' && !isNew}
                                />
                              </Form.Item>
                            )}
                          </Col>
                          <Col span={12}>
                            {['ALL', 'OUT'].includes(
                              getFieldValue('direction'),
                            ) && (
                              <Form.Item name="destinationCountryId">
                                <CountrySelect
                                  disabled={status !== 'DRAFT' && !isNew}
                                />
                              </Form.Item>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row className="declaration__row" gutter={5}>
              <Col span={12}>
                <div className="declaration__field">
                  <div className="declaration__field-head">
                    <div className="declaration__field-number">6</div>
                    <div className="declaration__field-title">
                      {t('entity.admin.declaration.address')}
                    </div>
                  </div>
                  <Form.Item name="address">
                    <Input
                      disabled={status !== 'DRAFT' && !isNew}
                      style={{
                        textTransform: 'uppercase',
                        fontWeight: '600',
                      }}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <div className="declaration__field">
                  <div className="declaration__field-head">
                    <div className="declaration__field-number">7</div>
                    <div className="declaration__field-title">
                      <RequiredLabel
                        title={t('entity.admin.declaration.transportType._')}
                      />
                      <span>/</span>
                      {t('entity.admin.declaration.transportNumber')}
                    </div>
                  </div>
                  <Row gutter={5}>
                    <Col span={12}>
                      <Form.Item
                        name="transportType"
                        rules={[
                          {
                            required: true,
                            message: 'Câmpul este obligatoriu',
                          },
                        ]}
                      >
                        <Select
                          allowClear
                          disabled={status !== 'DRAFT' && !isNew}
                        >
                          {['AIR', 'ROAD', 'RAILWAY', 'MARINE', 'ANOTHER'].map(
                            (row) => (
                              <Select.Option value={row} key={row}>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                  }}
                                >
                                  <Icon
                                    name={row.toLowerCase()}
                                    style={{ width: '20px', height: '20px' }}
                                  />
                                  {t(
                                    `entity.admin.declaration.transportType.${row}`,
                                  )}
                                </div>
                              </Select.Option>
                            ),
                          )}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item name="transportNumber">
                        <Input
                          disabled={status !== 'DRAFT' && !isNew}
                          style={{
                            textTransform: 'uppercase',
                            fontWeight: '600',
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row className="declaration__row" gutter={5}>
              <Col span={24}>
                <div className="declaration__field">
                  <div className="declaration__field-head">
                    <div className="declaration__field-number">8</div>
                    <div className="declaration__field-title">
                      Numerarul în valuta națională, în alte valute, instrumente
                      monetare, valori culturale, obiecte din metale prețioase
                    </div>
                    <Form.Item
                      name="havingPreciousProperties"
                      valuePropName="checked"
                    >
                      <Checkbox
                        defaultChecked="false"
                        disabled={status !== 'DRAFT' && !isNew}
                        onChange={(value) =>
                          value.target.checked
                            ? null
                            : form.setFieldValue('preciousProperties', null)
                        }
                      />
                    </Form.Item>
                  </div>
                </div>
              </Col>
            </Row>
            <Col span={24}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.havingPreciousProperties !==
                  currentValues.havingPreciousProperties
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue('havingPreciousProperties') && (
                    <EditPreciousPropertyRecordsForm
                      disabled={status !== 'DRAFT' && !isNew}
                    />
                  )
                }
              </Form.Item>
            </Col>
            <Row className="declaration__row" gutter={5}>
              <div className="declaration__field">
                <div className="declaration__field-head">
                  <div className="declaration__field-number">9</div>
                  <div className="declaration__field-title">
                    Declar, că dețin mărfuri,alte obiecte şi valori, deplasarea
                    cărora peste frontiera vamală a Republicii Moldova se
                    autorizează în baza actului permisiv al organelor de
                    competenţă.
                  </div>
                </div>
              </div>
            </Row>
            <Row className="declaration__row" gutter={5}>
              <div className="declaration__field">
                <div className="declaration__field-head">
                  <div className="declaration__field-number">9.1</div>
                  <div className="declaration__field-title">
                    Mărfuri, transportarea cărora necesită act permisiv
                  </div>
                </div>
                <Col span={24}>
                  <Row className="declaration__row" gutter={5}>
                    <Col span={12}>
                      <Form.Item name="havingWeapon" valuePropName="checked">
                        <Checkbox
                          defaultChecked="false"
                          disabled={status !== 'DRAFT' && !isNew}
                          onChange={onProductChange}
                        >
                          {t('entity.admin.declaration.havingWeapon')}
                        </Checkbox>
                      </Form.Item>
                      <Form.Item name="havingDrugs" valuePropName="checked">
                        <Checkbox
                          defaultChecked="false"
                          disabled={status !== 'DRAFT' && !isNew}
                          onChange={onProductChange}
                        >
                          {t('entity.admin.declaration.havingDrugs')}
                        </Checkbox>
                      </Form.Item>
                      <Form.Item name="havingArt" valuePropName="checked">
                        <Checkbox
                          defaultChecked="false"
                          disabled={status !== 'DRAFT' && !isNew}
                          onChange={onProductChange}
                        >
                          {t('entity.admin.declaration.havingArt')}
                        </Checkbox>
                      </Form.Item>
                      <Form.Item
                        name="havingInformationCarriers"
                        valuePropName="checked"
                      >
                        <Checkbox
                          defaultChecked="false"
                          disabled={status !== 'DRAFT' && !isNew}
                          onChange={onProductChange}
                        >
                          {t(
                            'entity.admin.declaration.havingInformationCarriers',
                          )}
                        </Checkbox>
                      </Form.Item>
                      <Form.Item name="havingLiving" valuePropName="checked">
                        <Checkbox
                          defaultChecked="false"
                          disabled={status !== 'DRAFT' && !isNew}
                          onChange={onProductChange}
                        >
                          {t('entity.admin.declaration.havingLiving')}
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="havingRadioactiveSubstances"
                        valuePropName="checked"
                      >
                        <Checkbox
                          defaultChecked="false"
                          disabled={status !== 'DRAFT' && !isNew}
                          onChange={onProductChange}
                        >
                          {t(
                            'entity.admin.declaration.havingRadioactiveSubstances',
                          )}
                        </Checkbox>
                      </Form.Item>
                      <Form.Item name="havingPoisons" valuePropName="checked">
                        <Checkbox
                          defaultChecked="false"
                          disabled={status !== 'DRAFT' && !isNew}
                          onChange={onProductChange}
                        >
                          {t('entity.admin.declaration.havingPoisons')}
                        </Checkbox>
                      </Form.Item>
                      <Form.Item name="havingProducts" valuePropName="checked">
                        <Checkbox
                          defaultChecked="false"
                          disabled={status !== 'DRAFT' && !isNew}
                          onChange={onProductChange}
                        >
                          {t('entity.admin.declaration.havingProducts')}
                        </Checkbox>
                      </Form.Item>
                      <Form.Item
                        name="havingPersonalProducts"
                        valuePropName="checked"
                      >
                        <Checkbox
                          defaultChecked="false"
                          disabled={status !== 'DRAFT' && !isNew}
                          onChange={onProductChange}
                        >
                          {t('entity.admin.declaration.havingPersonalProducts')}
                        </Checkbox>
                      </Form.Item>
                      <Form.Item
                        name="havingComercialProducts"
                        valuePropName="checked"
                      >
                        <Checkbox
                          defaultChecked="false"
                          disabled={status !== 'DRAFT' && !isNew}
                          onChange={onProductChange}
                        >
                          {t(
                            'entity.admin.declaration.havingComercialProducts',
                          )}
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </div>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.havingWeapon !== currentValues.havingWeapon ||
                    prevValues.havingDrugs !== currentValues.havingDrugs ||
                    prevValues.havingArt !== currentValues.havingArt ||
                    prevValues.havingInformationCarriers !==
                      currentValues.havingInformationCarriers ||
                    prevValues.havingLiving !== currentValues.havingLiving ||
                    prevValues.havingRadioactiveSubstances !==
                      currentValues.havingRadioactiveSubstances ||
                    prevValues.havingPoisons !== currentValues.havingPoisons ||
                    prevValues.havingProducts !==
                      currentValues.havingProducts ||
                    prevValues.havingPersonalProducts !==
                      currentValues.havingPersonalProducts ||
                    prevValues.havingComercialProducts !==
                      currentValues.havingComercialProducts
                  }
                >
                  {({ getFieldValue }) =>
                    (getFieldValue('havingWeapon') ||
                      getFieldValue('havingDrugs') ||
                      getFieldValue('havingArt') ||
                      getFieldValue('havingInformationCarriers') ||
                      getFieldValue('havingLiving') ||
                      getFieldValue('havingRadioactiveSubstances') ||
                      getFieldValue('havingPoisons') ||
                      getFieldValue('havingProducts') ||
                      getFieldValue('havingPersonalProducts') ||
                      getFieldValue('havingComercialProducts')) && (
                      <>
                        <Row className="declaration__row" gutter={5}>
                          <div className="declaration__field">
                            <div className="declaration__field-head">
                              <div className="declaration__field-number">
                                9.2
                              </div>
                              <div
                                className="declaration__field-title"
                                style={{ justifyContent: 'center' }}
                              >
                                Lista detaliată a mărfurilor specificate la
                                p.9.1
                              </div>
                            </div>
                          </div>
                        </Row>
                        <EditProductsForm
                          disabled={status !== 'DRAFT' && !isNew}
                        />
                        <Row className="declaration__row" gutter={5}>
                          <div className="declaration__field">
                            <div className="declaration__field-head">
                              <div
                                className="declaration__field-title"
                                style={{ justifyContent: 'center' }}
                              >
                                La fel declar și alte bunuri
                              </div>
                            </div>
                          </div>
                        </Row>
                      </>
                    )
                  }
                </Form.Item>
              </Col>
            </Row>
            <Col span={24}>
              <Row className="declaration__row" gutter={5}>
                <Col span={12}>
                  <div className="declaration__field">
                    <Row className="declaration__row" gutter={5}>
                      <Col span={12}>
                        <Form.Item
                          name="accompaniedBag"
                          valuePropName="checked"
                        >
                          <Checkbox
                            defaultChecked="false"
                            disabled={status !== 'DRAFT' && !isNew}
                            onChange={(value) => {
                              if (!value.target.checked) {
                                form.setFieldsValue({
                                  accompaniedBagNumber: undefined,
                                });
                              }
                              onBagProductChange();
                            }}
                          >
                            {t('entity.admin.declaration.accompaniedBag')}
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          style={{ width: '100%' }}
                          noStyle
                          shouldUpdate={(prevValues, currentValues) =>
                            prevValues.accompaniedBag !==
                            currentValues.accompaniedBag
                          }
                        >
                          {({ getFieldValue }) =>
                            getFieldValue('accompaniedBag') && (
                              <div className="declaration__field-head">
                                <div className="declaration__field-title">
                                  {t(
                                    'entity.admin.declaration.accompaniedBagNumber',
                                  )}
                                </div>
                                <Form.Item
                                  labelCol={{ span: 24 }}
                                  key="accompaniedBagNumber"
                                  name="accompaniedBagNumber"
                                >
                                  <InputNumber
                                    min={0}
                                    precision={0}
                                    disabled={status !== 'DRAFT' && !isNew}
                                  />
                                </Form.Item>
                              </div>
                            )
                          }
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="declaration__field">
                    <Row className="declaration__row" gutter={5}>
                      <Col span={12}>
                        <Form.Item
                          name="unaccompaniedBag"
                          valuePropName="checked"
                          onChange={(value) => {
                            if (!value.target.checked) {
                              form.setFieldsValue({
                                unaccompaniedBagNumber: undefined,
                              });
                            }
                            onBagProductChange();
                          }}
                        >
                          <Checkbox
                            defaultChecked="false"
                            disabled={status !== 'DRAFT' && !isNew}
                          >
                            {t('entity.admin.declaration.unaccompaniedBag')}
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) =>
                            prevValues.unaccompaniedBag !==
                            currentValues.unaccompaniedBag
                          }
                        >
                          {({ getFieldValue }) =>
                            getFieldValue('unaccompaniedBag') && (
                              <div className="declaration__field-head">
                                <div className="declaration__field-title">
                                  {t(
                                    'entity.admin.declaration.accompaniedBagNumber',
                                  )}
                                </div>
                                <Form.Item
                                  key="unaccompaniedBagNumber"
                                  name="unaccompaniedBagNumber"
                                >
                                  <InputNumber
                                    min={0}
                                    precision={0}
                                    disabled={status !== 'DRAFT' && !isNew}
                                  />
                                </Form.Item>
                              </div>
                            )
                          }
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col span={24}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.unaccompaniedBag !==
                        currentValues.unaccompaniedBag ||
                      prevValues.accompaniedBag !== currentValues.accompaniedBag
                    }
                  >
                    {({ getFieldValue }) =>
                      (getFieldValue('unaccompaniedBag') ||
                        getFieldValue('accompaniedBag')) && (
                        <EditBagProductsForm
                          disabled={status !== 'DRAFT' && !isNew}
                        />
                      )
                    }
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Row className="declaration__row" gutter={5}>
              <Col span={24}>
                <div className="declaration__field">
                  <div className="declaration__field-head">
                    <div className="declaration__field-number">11</div>
                    <div className="declaration__field-title">
                      Date despre unitatea de transport(în cazul vămuirii
                      mijlocului de transport)
                    </div>
                    <Form.Item name="showTransport" valuePropName="checked">
                      <Checkbox
                        disabled={status !== 'DRAFT' && !isNew}
                        onChange={(e) => {
                          if (!e.target.checked) {
                            const fieldsToReset = {
                              showTransport: null,
                              transportBrand: null,
                              fuelType: null,
                              firstRegistration: null,
                              transportModel: null,
                              motorNumber: null,
                              mileage: null,
                              transportVariant: null,
                              bodyNumber: null,
                              placeNumber: null,
                              transportVersion: null,
                              capacity: null,
                              capacityKW: null,
                              placeNumberLegs: null,
                              fabricationYear: null,
                              colour: null,
                              massMax: null,
                              vehicleCategory: null,
                              finalDestination: null,
                              weight: null,
                              price: null,
                              transportTaxation: null,
                              hybridType: null,
                            };
                            form.setFieldsValue(fieldsToReset);
                          }
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>
              </Col>
            </Row>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.showTransport !== currentValues.showTransport ||
                prevValues.fuelType !== currentValues.fuelType
              }
            >
              {({ getFieldValue }) => (
                <div
                  style={{
                    display: getFieldValue('showTransport') || 'none',
                  }}
                >
                  <Row className="declaration__row" gutter={5}>
                    <Col span={8}>
                      <div className="declaration__field">
                        <div className="declaration__field-head">
                          <div className="declaration__field-title">
                            <RequiredLabel
                              title={t(
                                'entity.admin.declaration.transportBrand',
                              )}
                            />
                          </div>
                          <Form.Item
                            labelCol={{ span: 24 }}
                            key="transportBrand"
                            name="transportBrand"
                            rules={[
                              {
                                required: getFieldValue('showTransport'),
                                message: 'Câmpul este obligatoriu',
                              },
                            ]}
                          >
                            <Select
                              allowClear
                              showSearch
                              style={{ width: '100%' }}
                              disabled={status !== 'DRAFT' && !isNew}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) !== -1
                              }
                            >
                              {vehicleBrands?.content.map((option) => (
                                <Select.Option
                                  key={option.id}
                                  value={option.id}
                                >
                                  {option.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                    <Col span={8}>
                      <div className="declaration__field">
                        <div className="declaration__field-head">
                          <div className="declaration__field-title">
                            <RequiredLabel
                              title={t('entity.admin.declaration.fuelType')}
                            />
                          </div>
                          <Form.Item
                            labelCol={{ span: 24 }}
                            key="fuelType"
                            name="fuelType"
                            rules={[
                              {
                                required: getFieldValue('showTransport'),
                                message: 'Câmpul este obligatoriu',
                              },
                            ]}
                          >
                            <Select
                              allowClear
                              showSearch
                              style={{ width: '100%' }}
                              disabled={status !== 'DRAFT' && !isNew}
                              onChange={onChangeFuelType}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) !== -1
                              }
                            >
                              {fuelTypes?.content.map((option) => (
                                <Select.Option
                                  key={option.id}
                                  value={option.id}
                                >
                                  {`${option.code} - ${option.name}`}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                    <Col span={8}>
                      <div className="declaration__field">
                        <div className="declaration__field-head">
                          <div className="declaration__field-title">
                            {t('entity.admin.declaration.firstRegistration')}
                          </div>
                          <Form.Item
                            labelCol={{ span: 24 }}
                            key="firstRegistration"
                            name="firstRegistration"
                          >
                            <DatePicker
                              format="DD.MM.YYYY"
                              disabled={status !== 'DRAFT' && !isNew}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="declaration__row" gutter={5}>
                    <Col span={8}>
                      <div className="declaration__field">
                        <div className="declaration__field-head">
                          <div className="declaration__field-title">
                            <RequiredLabel
                              title={t(
                                'entity.admin.declaration.transportModel',
                              )}
                            />
                          </div>
                          <Form.Item
                            labelCol={{ span: 24 }}
                            key="transportModel"
                            name="transportModel"
                            rules={[
                              {
                                required: getFieldValue('showTransport'),
                                message: 'Câmpul este obligatoriu',
                              },
                            ]}
                          >
                            <Input
                              disabled={status !== 'DRAFT' && !isNew}
                              style={{
                                textTransform: 'uppercase',
                                fontWeight: '600',
                              }}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                    <Col span={8}>
                      <div className="declaration__field">
                        <div className="declaration__field-head">
                          <div className="declaration__field-title">
                            {t('entity.admin.declaration.motorNumber')}
                          </div>
                          <Form.Item
                            labelCol={{ span: 24 }}
                            key="motorNumber"
                            name="motorNumber"
                          >
                            <Input
                              disabled={status !== 'DRAFT' && !isNew}
                              style={{
                                textTransform: 'uppercase',
                                fontWeight: '600',
                              }}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                    <Col span={8}>
                      <div className="declaration__field">
                        <div className="declaration__field-head">
                          <div className="declaration__field-title">
                            {t('entity.admin.declaration.mileage')}
                          </div>
                          <Form.Item
                            labelCol={{ span: 24 }}
                            key="mileage"
                            name="mileage"
                          >
                            <InputNumber
                              min={0}
                              precision={0}
                              disabled={status !== 'DRAFT' && !isNew}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="declaration__row" gutter={5}>
                    <Col span={8}>
                      <div className="declaration__field">
                        <div className="declaration__field-head">
                          <div className="declaration__field-title">
                            {t('entity.admin.declaration.transportVariant')}
                          </div>
                          <Form.Item
                            labelCol={{ span: 24 }}
                            key="transportVariant"
                            name="transportVariant"
                          >
                            <Input
                              disabled={status !== 'DRAFT' && !isNew}
                              style={{
                                textTransform: 'uppercase',
                                fontWeight: '600',
                              }}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                    <Col span={8}>
                      <div className="declaration__field">
                        <div className="declaration__field-head">
                          <div className="declaration__field-title">
                            {t('entity.admin.declaration.bodyNumber')}
                          </div>
                          <Form.Item
                            labelCol={{ span: 24 }}
                            key="bodyNumber"
                            name="bodyNumber"
                          >
                            <Input
                              disabled={status !== 'DRAFT' && !isNew}
                              style={{
                                textTransform: 'uppercase',
                                fontWeight: '600',
                              }}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                    <Col span={8}>
                      <div className="declaration__field">
                        <div className="declaration__field-head">
                          <div className="declaration__field-title">
                            {t('entity.admin.declaration.placeNumber')}
                          </div>
                          <Form.Item
                            labelCol={{ span: 24 }}
                            key="placeNumber"
                            name="placeNumber"
                          >
                            <InputNumber
                              min={0}
                              precision={0}
                              disabled={status !== 'DRAFT' && !isNew}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="declaration__row" gutter={5}>
                    <Col span={8}>
                      <div className="declaration__field">
                        <div className="declaration__field-head">
                          <div className="declaration__field-title">
                            {t('entity.admin.declaration.transportVersion')}
                          </div>
                          <Form.Item
                            labelCol={{ span: 24 }}
                            key="transportVersion"
                            name="transportVersion"
                          >
                            <Input
                              disabled={status !== 'DRAFT' && !isNew}
                              style={{
                                textTransform: 'uppercase',
                                fontWeight: '600',
                              }}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Col>

                    <Col span={8}>
                      <div className="declaration__field">
                        <div className="declaration__field-head">
                          <div className="declaration__field-title">
                            {t('entity.admin.declaration.capacity')}
                          </div>
                          {cmAccepted && (
                            <>
                              <div className="declaration__field-title">
                                <RequiredLabel title="cm3-" />
                              </div>
                              <Form.Item
                                labelCol={{ span: 24 }}
                                key="capacity"
                                name="capacity"
                                rules={[
                                  {
                                    required:
                                      cmAccepted &&
                                      getFieldValue('showTransport'),
                                    message: 'Câmpul este obligatoriu',
                                  },
                                ]}
                              >
                                <InputNumber
                                  min={0}
                                  precision={0}
                                  disabled={status !== 'DRAFT' && !isNew}
                                />
                              </Form.Item>
                            </>
                          )}
                          {kwAccepted && (
                            <>
                              <div className="declaration__field-title">
                                <RequiredLabel title="KW-" />
                              </div>
                              <Form.Item
                                labelCol={{ span: 24 }}
                                key="capacityKW"
                                name="capacityKW"
                                rules={[
                                  {
                                    required:
                                      kwAccepted &&
                                      getFieldValue('showTransport'),
                                    message: 'Câmpul este obligatoriu',
                                  },
                                ]}
                              >
                                <InputNumber
                                  min={0}
                                  precision={0}
                                  disabled={status !== 'DRAFT' && !isNew}
                                />
                              </Form.Item>
                            </>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col span={8}>
                      <div className="declaration__field">
                        <div className="declaration__field-head">
                          <div className="declaration__field-title">
                            {t('entity.admin.declaration.placeNumberLegs')}
                          </div>
                          <Form.Item
                            labelCol={{ span: 24 }}
                            key="placeNumberLegs"
                            name="placeNumberLegs"
                          >
                            <InputNumber
                              min={0}
                              precision={0}
                              disabled={status !== 'DRAFT' && !isNew}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="declaration__row" gutter={5}>
                    <Col span={8}>
                      <div className="declaration__field">
                        <div className="declaration__field-head">
                          <div className="declaration__field-title">
                            <RequiredLabel
                              title={t(
                                'entity.admin.declaration.fabricationYear',
                              )}
                            />
                          </div>
                          <Form.Item
                            labelCol={{ span: 24 }}
                            key="fabricationYear"
                            name="fabricationYear"
                            rules={[
                              {
                                required: getFieldValue('showTransport'),
                                message: 'Câmpul este obligatoriu',
                              },
                            ]}
                          >
                            <InputNumber
                              min={0}
                              precision={0}
                              disabled={status !== 'DRAFT' && !isNew}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                    <Col span={8}>
                      <div className="declaration__field">
                        <div className="declaration__field-head">
                          <div className="declaration__field-title">
                            <RequiredLabel
                              title={t('entity.admin.declaration.colour')}
                            />
                          </div>
                          <Form.Item
                            labelCol={{ span: 24 }}
                            key="colour"
                            name="colour"
                            rules={[
                              {
                                required: getFieldValue('showTransport'),
                                message: 'Câmpul este obligatoriu',
                              },
                            ]}
                          >
                            <Input
                              disabled={status !== 'DRAFT' && !isNew}
                              style={{
                                textTransform: 'uppercase',
                                fontWeight: '600',
                              }}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                    <Col span={8}>
                      <div className="declaration__field">
                        <div className="declaration__field-head">
                          <div className="declaration__field-title">
                            {t('entity.admin.declaration.massMax')}
                          </div>
                          <Form.Item
                            labelCol={{ span: 24 }}
                            key="massMax"
                            name="massMax"
                          >
                            <InputNumber
                              min={0}
                              precision={0}
                              disabled={status !== 'DRAFT' && !isNew}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="declaration__row" gutter={5}>
                    <Col span={8}>
                      <div className="declaration__field">
                        <div className="declaration__field-head">
                          <div className="declaration__field-title">
                            <RequiredLabel
                              title={t(
                                'entity.admin.declaration.vehicleCategory',
                              )}
                            />
                          </div>
                          <Col span={12}>
                            <Form.Item
                              labelCol={{ span: 24 }}
                              key="vehicleCategory"
                              name="vehicleCategory"
                              rules={[
                                {
                                  required: getFieldValue('showTransport'),
                                  message: 'Câmpul este obligatoriu',
                                },
                              ]}
                            >
                              <Select
                                allowClear
                                showSearch
                                style={{ width: '100%' }}
                                disabled={status !== 'DRAFT' && !isNew}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) !== -1
                                }
                              >
                                {vehicleCategories?.content.map((option) => (
                                  <Select.Option
                                    key={option.id}
                                    value={option.id}
                                  >
                                    {`${option.code} - ${option.name}`}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </div>
                      </div>
                    </Col>
                    <Col span={8}>
                      <div className="declaration__field">
                        <div className="declaration__field-head">
                          <div className="declaration__field-title">
                            <RequiredLabel
                              title={t(
                                'entity.admin.declaration.finalDestination',
                              )}
                            />
                          </div>

                          <Col span={12}>
                            <Form.Item
                              key="finalDestination"
                              style={{ width: '100%' }}
                              name="finalDestination"
                              rules={[
                                {
                                  required: getFieldValue('showTransport'),
                                  message: 'Câmpul este obligatoriu',
                                },
                              ]}
                            >
                              <Select
                                allowClear
                                showSearch
                                style={{ width: '100%' }}
                                disabled
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) !== -1
                                }
                              >
                                {finalDestinationSubs?.map((option) => (
                                  <Select.Option
                                    key={option.id}
                                    value={option.id}
                                  >
                                    {`${option.type} - ${option.name}`}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </div>
                      </div>
                    </Col>
                    <Col span={8}>
                      <div className="declaration__field">
                        <div className="declaration__field-head">
                          <div className="declaration__field-title">
                            {t('entity.admin.declaration.weight')}
                          </div>
                          <Form.Item
                            labelCol={{ span: 24 }}
                            key="weight"
                            name="weight"
                          >
                            <InputNumber
                              min={0}
                              precision={0}
                              disabled={status !== 'DRAFT' && !isNew}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="declaration__row" gutter={5}>
                    <Col span={8}>
                      <Button
                        type="primary"
                        disabled={isNew}
                        onClick={() => {
                          form.validateFields().then(() => {
                            setShowModal(true);
                          });
                        }}
                      >
                        Caculează acciza
                      </Button>
                      <Checkbox
                        checked={showCustomsClearance}
                        onChange={(e) => {
                          setShowCustomsClearance(e.target.checked);
                          if (e.target.checked) {
                            if (!form.getFieldValue('customsClearanceIDNP')) {
                              form.setFieldsValue({
                                customsClearanceIDNP:
                                  form.getFieldValue('idnp'),
                              });
                            }
                            if (
                              !form.getFieldValue('customsClearanceVehicleVin')
                            ) {
                              form.setFieldsValue({
                                customsClearanceVehicleVin:
                                  form.getFieldValue('bodyNumber'),
                              });
                            }
                          }
                        }}
                        style={{ marginLeft: '10px' }}
                      >
                        Vămuirea mijlocului de transport
                      </Checkbox>
                    </Col>
                  </Row>
                  {showCustomsClearance ? (
                    <div>
                      <Row className="declaration__row" gutter={5}>
                        <Col span={24}>
                          <div className="declaration__field">
                            <div className="declaration__field-head">
                              <div
                                className="declaration__field-title"
                                style={{ justifyContent: 'center' }}
                              >
                                Date necesare pentru vămuirea mijlocului de
                                transport
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row className="declaration__row" gutter={5}>
                        <Col span={8}>
                          <div className="declaration__field">
                            <div className="declaration__field-head">
                              <div className="declaration__field-title">
                                <RequiredLabel
                                  title={t(
                                    'entity.admin.declaration.customsClearance.IDNP',
                                  )}
                                />
                              </div>
                              <Form.Item
                                labelCol={{ span: 24 }}
                                key="customsClearanceIDNP"
                                name="customsClearanceIDNP"
                                rules={[
                                  {
                                    required: showCustomsClearance,
                                    message: 'Câmpul este obligatoriu',
                                  },
                                ]}
                              >
                                <Input
                                  disabled={
                                    (status !== 'DRAFT' && !isNew) ||
                                    !enableCustomsClearanceFields
                                  }
                                  style={{
                                    textTransform: 'uppercase',
                                    fontWeight: '600',
                                  }}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>
                        <Col span={8}>
                          <div className="declaration__field">
                            <div className="declaration__field-head">
                              <div className="declaration__field-title">
                                <RequiredLabel
                                  title={t(
                                    'entity.admin.declaration.customsClearance.plateNumber',
                                  )}
                                />
                              </div>
                              <Form.Item
                                labelCol={{ span: 24 }}
                                key="customsClearancePlateNumber"
                                name="customsClearancePlateNumber"
                                rules={[
                                  {
                                    required: showCustomsClearance,
                                    message: 'Câmpul este obligatoriu',
                                  },
                                ]}
                              >
                                <Input
                                  disabled={
                                    (status !== 'DRAFT' && !isNew) ||
                                    !enableCustomsClearanceFields
                                  }
                                  style={{
                                    textTransform: 'uppercase',
                                    fontWeight: '600',
                                  }}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>
                        <Col span={8}>
                          <div className="declaration__field">
                            <div className="declaration__field-head">
                              <div className="declaration__field-title">
                                <RequiredLabel
                                  title={t(
                                    'entity.admin.declaration.customsClearance.vinCode',
                                  )}
                                />{' '}
                              </div>
                              <Form.Item
                                labelCol={{ span: 24 }}
                                key="customsClearanceVehicleVin"
                                name="customsClearanceVehicleVin"
                                rules={[
                                  {
                                    required: showCustomsClearance,
                                    message: 'Câmpul este obligatoriu',
                                  },
                                ]}
                              >
                                <Input
                                  disabled={
                                    (status !== 'DRAFT' && !isNew) ||
                                    !enableCustomsClearanceFields
                                  }
                                  style={{
                                    textTransform: 'uppercase',
                                    fontWeight: '600',
                                  }}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row className="declaration__row" gutter={5}>
                        <Button
                          type="primary"
                          disabled={isNew || !enableCustomsClearanceFields}
                          onClick={handleClearCustoms}
                        >
                          Vămuire
                        </Button>
                        <Button
                          type="primary"
                          danger
                          disabled={isNew || enableCustomsClearanceFields}
                          onClick={handleDeleteUndeclaredVehicle}
                          style={{ marginLeft: '10px' }}
                        >
                          Anulare vămuire
                        </Button>
                      </Row>
                    </div>
                  ) : null}
                </div>
              )}
            </Form.Item>

            <Row className="declaration__row" gutter={5}>
              <Col span={24}>
                <div className="declaration__field">
                  <div className="declaration__field-head">
                    <div
                      className="declaration__field-title"
                      style={{ justifyContent: 'center' }}
                    >
                      Sunt informat, că declararea datelor incorecte atrage după
                      sine răspundereaconform prevederilor legislației naționale
                      a Republicii Moldova
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="declaration__row" gutter={5}>
              <Col span={24}>
                <div className="declaration__field">
                  <div className="declaration__field-head">
                    <div className="declaration__field-number">13</div>
                    <div className="declaration__field-title">
                      {t('entity.admin.declaration.complitedFormularNr')}
                    </div>
                  </div>
                  <Form.Item name="complitedFormularNr">
                    <Input
                      disabled={status !== 'DRAFT' && !isNew}
                      style={{
                        textTransform: 'uppercase',
                        fontWeight: '600',
                      }}
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
          <Col span={24}>
            {/* <Form.Item */}
            {/*   key="valute" */}
            {/*   name="valute" */}
            {/*   label={t('entity.admin.declaration.valute._')} */}
            {/* > */}
            {/*   <Select allowClear> */}
            {/*     {['LEU', 'EURO'].map((row) => ( */}
            {/*       <Select.Option value={row} key={row}> */}
            {/*         {t(`entity.admin.declaration.valute.${row}`)} */}
            {/*       </Select.Option> */}
            {/*     ))} */}
            {/*   </Select> */}
            {/* </Form.Item> */}
          </Col>
        </Row>

        <Form.Item>
          <Space>
            <Space>
              {(isNew || status === 'DRAFT') && (
                <>
                  <SaveButton disabled={status !== 'DRAFT' && !isNew} />
                </>
              )}
              {status === 'DRAFT' && (
                <>
                  <Button type="primary" onClick={() => approve()}>
                    Aprobare
                  </Button>

                  <Button type="primary" danger onClick={() => reject()}>
                    Respingere
                  </Button>
                </>
              )}
            </Space>

            {!isNew && (
              <Button
                type="primary"
                icon={<FileTextOutlined />}
                onClick={() => handlePdf()}
              >
                Generare declarație PDF
              </Button>
            )}
            <CancelButton onCancel={onCancel} />
          </Space>
        </Form.Item>
        <Modal
          maskClosable={false}
          centered
          visible={showModal}
          onOk={() => {
            handleCalculateTransportTax();
          }}
          okText="Calculează"
          cancelText="Închide"
          onCancel={() => {
            setShowModal(false);
            setShowCalculations(false);
          }}
          width="70em"
        >
          <Row className="declaration__row" gutter={5}>
            <Col span={8}>
              <div className="declaration__field">
                <div className="declaration__field-head">
                  <div className="declaration__field-title">
                    <RequiredLabel
                      title={t('entity.admin.declaration.price')}
                    />
                  </div>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    key="price"
                    name="price"
                    rules={[
                      {
                        required: showModal,
                        message: 'Câmpul este obligatoriu',
                      },
                    ]}
                  >
                    <InputNumber
                      min={0}
                      precision={0}
                      disabled={status !== 'DRAFT' && !isNew}
                    />
                  </Form.Item>
                </div>
              </div>
            </Col>
            <Col
              span={8}
              style={{
                display: (cmAccepted && kwAccepted) || 'none',
              }}
            >
              <div className="declaration__field">
                <div className="declaration__field-head">
                  <div className="declaration__field-title">
                    {t('entity.admin.declaration.hybridType._')}
                  </div>

                  <Col span={12}>
                    <Form.Item
                      key="hybridType"
                      style={{ width: '100%' }}
                      name="hybridType"
                    >
                      <Select
                        allowClear
                        showSearch
                        style={{ width: '100%' }}
                        disabled={status !== 'DRAFT' && !isNew}
                      >
                        {[
                          'PLUG_IN_HYBRID',
                          'HYBRID',
                          'MICRO_HYBRID',
                          'MILD_HYBRID',
                        ].map((option) => (
                          <Select.Option key={option} value={option}>
                            {t(`entity.admin.declaration.hybridType.${option}`)}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </div>
              </div>
            </Col>
          </Row>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.fabricationYear !== currentValues.fabricationYear ||
              prevValues.capacity !== currentValues.capacity ||
              prevValues.transportTaxation !== currentValues.transportTaxation
            }
          >
            {() => (showCalculations ? <AccizeCalculator form={form} /> : null)}
          </Form.Item>
        </Modal>
        <div style={{ display: 'none' }}>
          <Form.Item
            labelCol={{ span: 24 }}
            key="transportTaxation"
            name="transportTaxation"
          >
            <InputNumber min={0} precision={2} disabled />
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default EditDeclarationForm;
