const DocumentCodes = {
  ACT_CONTROL: 'ACF',
  SESIZARI: 'SZ',
  SUSPICION_FRAUD: 'RSF',
  IRREGULARITIES: 'IR',
  TRANSSHIPMENT_PERMISSION: 'PVT',
  RETUR: 'RET',
};

export default DocumentCodes;
