import { downloadFile } from '../../helpers/api';

export const SERVICE_URI = '/videos';
export const BASE_URI = '/api/v1/grab/rtsp';

export const VIDEO_SERVICE_URI = `${SERVICE_URI}/api/v1/watch/media`;
export const RTSP_STREAMING_URI = `${SERVICE_URI}${BASE_URI}`;

export const getBlobImage = async (cameraId) => {
  const downloadUrl = `${SERVICE_URI}${BASE_URI}/${
    Array.isArray(cameraId) ? cameraId.join(',') : cameraId
  }?current`;

  try {
    const blob = await downloadFile(downloadUrl).then((res) => res.blob());
    return blob;
  } catch (error) {
    console.error('Error occurred while fetching the file:', error);
    throw error;
  }
};

export const getPartialContent = async (streamIp, streamName) => {
  try {
    const apiUrl = `${window._env_.API_BACKEND_URL}${RTSP_STREAMING_URI}/${streamIp}/${streamName}`;
    const response = await fetch(apiUrl, {
      method: 'GET',
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const blob = await response.blob();
    const videoBlob = new Blob([blob], { type: 'video/mp4' });
    return videoBlob;
  } catch (error) {
    console.error('Error fetching video:', error);
    throw error;
  }
};
