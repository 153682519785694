import React from 'react';

import { useEditPage } from '../../../../hooks/useEditPage';

import {
  findOne,
  create,
  update,
} from '../../../../services/taxonomies/vignette-error-code';
import EditVignetteErrorCodesForm from '../../../../components/admin/taxonomies/vignetteErrorCodes/EditVignetteErrorCodesForm';
import AppLoader from '../../../../components/auth/AppLoader';

const initial = {};

const EditVignetteErrorCode = () => {
  const [code, handleSubmit, handleCancel, errors, loading] = useEditPage({
    initial,
    existent: findOne,
    goBackPath: '/admin/taxonomies/vignette-error-code',
    onCreate: create,
    onUpdate: update,
  });

  return (
    <>
      <div className={`loaderOverlay ${loading ? 'active' : ''}`}>
        <AppLoader />
      </div>
      {code !== undefined && (
        <EditVignetteErrorCodesForm
          vignetteErrorCode={code}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          errors={errors}
        />
      )}
    </>
  );
};

export default EditVignetteErrorCode;
