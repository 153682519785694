import React, { useEffect, useState } from 'react';
import { Col, Form, Modal, notification, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { sendWithholdingReason } from '../../../services/transactions/transactions';
import { findAll as findAllWithholdingReasons } from '../../../services/taxonomies/withholding-reasons';
import useDictionaries from '../../../hooks/useDictionaries';
import RequiredLabel from '../../../services/utils/RequiredLabel';

const dictionaries = {
  withholdingReasons: findAllWithholdingReasons,
};

const WithholdingReasonModal = ({
  transactionId,
  setLoadingLoader = () => {},
  showModal = () => {},
  visible,
  setVisible = () => {},
}) => {
  const [form] = Form.useForm();
  const [isOtherSelected, setIsOtherSelected] = useState(false);

  const [{ withholdingReasons }] = useDictionaries(dictionaries);

  useEffect(() => {
    withholdingReasons.content.push({
      id: -1,
      code: 'OTHER',
      name: 'ALTELE',
    });
  }, [withholdingReasons.content]);

  const handleOkReasons = () => {
    const reasonCode = form.getFieldValue('reason');
    const reasonOthers = form.getFieldValue('reasonOthers');
    if (reasonCode === 'OTHER' && reasonOthers) {
      setLoadingLoader(true);
      sendWithholdingReason(transactionId, { reason: reasonOthers })
        .then(() => {
          notification.success({ message: 'Motivul a fost înregistrat' });
          setVisible(false);
          showModal();
        })
        .catch(() =>
          notification.error({
            message: 'A apărut o eroare la înregistrea motivului',
          }),
        )
        .finally(() => setLoadingLoader(false));
    } else if (reasonCode && reasonCode !== 'OTHER') {
      const reason = withholdingReasons?.content.find(
        (el) => el.code === reasonCode,
      )?.name;
      setLoadingLoader(true);
      sendWithholdingReason(transactionId, { reason })
        .then(() => {
          notification.success({ message: 'Motivul a fost înregistrat' });
          setVisible(false);
          showModal();
        })
        .catch(() =>
          notification.error({
            message: 'A apărut o eroare la înregistrea motivului',
          }),
        )
        .finally(() => setLoadingLoader(false));
    } else {
      notification.warning({ message: 'Introduceți motivul reținerii' });
    }
  };

  const handleChange = (value) => {
    if (value === 'OTHER') {
      setIsOtherSelected(true);
    } else {
      setIsOtherSelected(false);
    }
  };

  return (
    <>
      <Form form={form} layout="vertical">
        <Modal
          maskClosable={false}
          centered
          key="withholdingReasonsModal"
          width={900}
          title="Motivul întârzierii controlului"
          visible={visible}
          onCancel={() => setVisible(false)}
          onOk={handleOkReasons}
        >
          <Col>
            <label>Motiv</label>
            <Form.Item name="reason">
              <Select
                style={{ width: '200px' }}
                onChange={handleChange}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) !==
                  -1
                }
              >
                {withholdingReasons?.content.map(
                  (option) =>
                    !option.deleted && (
                      <Select.Option key={option.id} value={option.code}>
                        {`${option.name}`}
                      </Select.Option>
                    ),
                )}
              </Select>
            </Form.Item>
            {isOtherSelected && (
              <>
                <label>
                  <RequiredLabel title="Motiv Altele" />
                </label>
                <Form.Item name="reasonOthers" style={{ marginBottom: '25px' }}>
                  <TextArea showCount maxLength={1000} />
                </Form.Item>
              </>
            )}
          </Col>
        </Modal>
      </Form>
    </>
  );
};

export default WithholdingReasonModal;
