import React, { useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

import useDatasource from '../../../../hooks/useDatasource';

import Column from '../../../../helpers/Columns';
import { findAll } from '../../../../services/admin/web-services/warehouse-transshipments';

import EditItemIcon from '../../../buttons/EditItemIcon';
import AddEntityButton from '../../../buttons/AddEntityButton';
import AuthContext, { hasPermission } from '../../../auth';
import { useTableScroll } from '../../../../hooks/useTableScroll';

const TransshipmentsList = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { permissions } = user;
  useTableScroll();
  const { loading, pagination, content, handleChange } = useDatasource(findAll);

  const columns = useMemo(
    () => [
      Column.text('id', 'ID', {
        width: 70,
      }),
      // Column.text('transactionID', 'ID Rută', {
      //   width: 70,
      // }),
      Column.text('vehicleNumber', 'Număr de înmatriculare', {
        width: 150,
        filter: true,
      }),
      Column.text('quantity', 'Cantitatea (tone)', {
        width: 150,
        filter: true,
      }),
      Column.date('createdAt', t('entity._.createdAt'), {
        width: 150,
      }),
      Column.text('createdBy', t('entity._.createdBy'), {
        width: 150,
      }),
      Column.date('updatedAt', t('entity._.updatedAt'), {
        width: 150,
      }),
      Column.text('updatedBy', t('entity._.updatedBy'), {
        width: 150,
      }),
      Column.actions(t('table.actions'), (record) => (
        <span style={{ textAlign: 'right' }}>
          {hasPermission(permissions, 'WAREHOUSE_ADD') && (
            <EditItemIcon path={`/warehouse/${record.id}`} />
          )}
        </span>
      )),
    ],
    [t, permissions],
  );

  return (
    <>
      <PageHeader
        title="Transbordari"
        extra={[
          <AddEntityButton
            key="new"
            entity="Transbordare"
            path="warehouse/new"
          />,
        ]}
      />
      <Table
        scroll={{ x: 500 }}
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
      />
    </>
  );
};

export default TransshipmentsList;
