import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeader } from '@ant-design/pro-layout';

import CustomsOfficesList from '../../../components/admin/customsOffices/CustomsOfficesList';

const CustomsOffices = () => {
  const { t } = useTranslation();
  return (
    (<PageHeader title={t('entity.admin.customsOffice._plural')} />),
    (<CustomsOfficesList />)
  );
};

export default CustomsOffices;
