import { get, postScheduler } from '../../../helpers/api';

const BASE_PATH = '/scheduler/api/v1';

export const getAvailableJobs = async () =>
  get(`${BASE_PATH}/jobs`).then((res) => res);

export const activateJob = async (data) =>
  postScheduler(`${BASE_PATH}/activate-job/${data.id}/${data.group}`);

export const deactivateJob = async (data) => {
  postScheduler(`${BASE_PATH}/deactivate-job/${data.id}/${data.group}`);
};

export const runJob = async (data) =>
  postScheduler(`${BASE_PATH}/run-job/${data.id}/${data.group}`);

export const interruptJob = async (data) =>
  postScheduler(`${BASE_PATH}/interrupt-job/${data.id}/${data.group}`);

export const getJobDetails = async (data) =>
  get(`${BASE_PATH}/job-details/${data.id}/${data.group}`).then((res) =>
    res.json(),
  );

export const rescheduleJob = async (data) =>
  postScheduler(`${BASE_PATH}/reschedule-job`, {
    ...data,
    name: data.id,
  });
