import {
  Button,
  Modal,
  Input,
  Form,
  Row,
  Col,
  notification,
  Select,
  DatePicker,
  Checkbox,
  Popconfirm,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useState, useContext, useEffect } from 'react';
import {
  FileAddOutlined,
  FileTextOutlined,
  RollbackOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  findByTransactionId as getActControlByTransactionId,
  getNrAct,
  getVehiclePlateNr,
} from '../../../services/admin/act-control-parameters';
import { generatePdfInNewTab } from '../../../services/transactions/transactions';
import { findAll as findAllCustomsRegimes } from '../../../services/taxonomies/customs-regime';
import { findAll as findAllCustomsDocumentTypes } from '../../../services/taxonomies/customs-document-type';
import { findAll as findAllControlLocations } from '../../../services/taxonomies/control-locations';
import { findAll as findAllControlReasons } from '../../../services/taxonomies/control-reasons';
import useDictionaries from '../../../hooks/useDictionaries';
import AppLoader from '../../auth/AppLoader';
import AuthContext from '../../auth';
import { maxLengthValidator } from '../../../services/utils/validator/Validator';

const dictionaries = {
  customsRegimes: findAllCustomsRegimes,
  customsDocumentTypes: findAllCustomsDocumentTypes,
  controlLocations: findAllControlLocations,
  controlReasons: findAllControlReasons,
};

const controlTypeEnum = {
  content: [
    { id: 'DETAILED', code: 'DETAILED', name: 'Detaliat' },
    { id: 'SELECTIVE', code: 'SELECTIVE', name: 'Selectiv' },
  ],
};

const suspicionTypeEnum = {
  content: [
    { id: 'CONF', code: 'CONFIRMED', name: 'Confirmată' },
    { id: 'NOTCONF', code: 'NOTCONFIRMED', name: 'Neconfirmată' },
  ],
};

const inAddressEnum = {
  content: [
    {
      id: '1',
      code: 'REZIDENT_ECONOMIC_AGENT',
      name: 'Agent economic rezident',
    },
    {
      id: '2',
      code: 'NONREZIDENT_ECONOMIC_AGENT',
      name: 'Agent economic nerezident',
    },
    {
      id: '3',
      code: 'REZIDENT_PHYSICAL_PERSON',
      name: 'Persoană fizică rezidentă',
    },
    {
      id: '4',
      code: 'NONREZIDENT_PHYSICAL_PERSON',
      name: 'Persoană fizică nerezidentă',
    },
  ],
};

const ActDeControlParametersModal = ({
  readOnly,
  allowGenerate = false, // only on readOnly mode
  actToShow,
  parameters,
  modalTitle,
  buttonCaption,
  submitMethod,
  inputsBasicDetails,
  successMessage,
  onClose = () => {},
}) => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [loadingLoader, setLoadingLoader] = useState(false);
  const { t } = useTranslation();
  const [additionalValues, setAdditionalValues] = useState({});
  const [actId, setActId] = useState(null);
  const [transactionTypeState, setTransactionTypeState] = useState(null);
  const [customsDocumentNotDeclaredState, setCustomsDocumentNotDeclaredState] =
    useState(false);
  const { user } = useContext(AuthContext);
  const [allowDictionaries, setAllowDictionaries] = useState(false);

  const [
    { customsRegimes, customsDocumentTypes, controlLocations, controlReasons },
    ,
    isLoadingDictionary,
  ] = useDictionaries(dictionaries, { allowFetcher: allowDictionaries });

  const completeFields = (actControl) => {
    if (actControl.id) {
      getVehiclePlateNr(
        parameters?.transactionId || actControl.transactionId,
      ).then((plateNr) => {
        setActId(actControl.id);
        setCustomsDocumentNotDeclaredState(
          actControl.customsDocumentNotDeclared,
        );
        const ceva = customsRegimes.content.find(
          (el) => el.name === actControl.transactionType,
        )?.code;
        setTransactionTypeState(ceva);
        form.setFieldsValue({
          vehicle: plateNr?.plateNr,
          actNumber: actControl?.actNumber,
          actDate: actControl?.actDate ? dayjs(actControl.actDate) : null,
          customsPost:
            actControl?.customsPost || `${parameters?.customsPost.code}`,
          postCode: parameters?.customsPost.code,
          transactionType: actControl.transactionType,
          expeditor: actControl.expeditor,
          expeditorAddress: actControl.expeditorAddress,
          controlMethods: actControl.controlMethods,
          controlScop: actControl.controlScop,
          controlPlaceNumber: actControl.controlPlaceNumber,
          controlAdditionalUsers: actControl.controlAdditionalUsers,
          expeditorDetails: actControl.expeditorDetails,
          carriageData: actControl.carriageData,
          expeditorRepresentative: actControl.expeditorRepresentative,
          customsDocument: actControl.customsDocument,
          documentNumber: actControl.documentNumber,
          documentDate: actControl?.documentDate
            ? dayjs(actControl.documentDate)
            : null,
          vehicleLocation: actControl.vehicleLocation,
          customsDocumentNotDeclared: actControl.customsDocumentNotDeclared,
          controlAuthor:
            actControl.controlAuthor || parameters.userFullname || '',
          controlPlace: actControl?.controlPlace,
          controlReason: actControl?.controlReason,
          controlMethod: actControl?.controlMethod,
          isUnload: actControl?.isUnload,
          isScale: actControl?.isScale,
          isCounting: actControl?.isCounting,
          isMeasured: actControl?.isMeasured,
          isScan: actControl?.isScan,
          isCanineGroup: actControl.isCanineGroup,
          controlResult: actControl.controlResult,
          suspicion: actControl.suspicion,
          inspector:
            actControl.inspector ||
            parameters?.userFullname ||
            actToShow?.inspector ||
            ' ',
          economicalAgentRepresentative:
            actControl?.economicalAgentRepresentative,
          transporterRepresentative: actControl?.transporterRepresentative,
        });
      });
    } else if (parameters?.customsPost?.code) {
      getVehiclePlateNr(parameters.transactionId).then((plateNr) => {
        getNrAct(parameters?.customsPost.code).then((actResponse) =>
          form.setFieldsValue({
            actNumber: actResponse.nrAct,
            vehicle: plateNr?.plateNr,
            actDate: dayjs(actResponse.actDate),
            transactionType: customsRegimes?.content?.find(
              (el) => el.id === parameters.customsRegimeId,
            )?.name,
          }),
        );
      });
    }
  };

  const showModal = async () => {
    getActControlByTransactionId(parameters.transactionId).then((actControl) =>
      completeFields(actControl),
    );

    setVisible(true);
  };

  useEffect(() => {
    if (actToShow?.id) {
      completeFields(actToShow);
      setAllowDictionaries(true);
      setVisible(true);
    } // eslint-disable-next-line
  }, [actToShow?.id]);

  useEffect(
    () => {
      if (
        customsRegimes?.content?.length > 0 ||
        customsDocumentTypes?.content?.length > 0 ||
        controlLocations?.content?.length > 0 ||
        controlReasons?.content?.length > 0
      )
        if (parameters) {
          showModal();
        }
    }, // eslint-disable-next-line
    [allowDictionaries, isLoadingDictionary],
  );

  const handleRender = () => {
    setAllowDictionaries((prev) => !prev);
  };

  const onSubmit = () => {
    const submitParameters = { ...parameters };
    inputsBasicDetails.forEach((element) => {
      if (form.getFieldValue(element.name)) {
        submitParameters[`${element.name}`] = form.getFieldValue(element.name);
      }
    });

    if (additionalValues) {
      Object.keys(additionalValues).forEach((k) => {
        if (!submitParameters[k]) {
          submitParameters[k] = additionalValues[k];
        }
      });
    }

    submitParameters.actNumber = form.getFieldValue('actNumber');
    submitParameters.actDate = form.getFieldValue('actDate');
    submitParameters.customsPost = form.getFieldValue('customsPost');
    submitParameters.postCode = user?.customsPost?.code;
    submitParameters.transactionType = form.getFieldValue('transactionType');
    submitParameters.expeditor = form.getFieldValue('expeditor');
    submitParameters.expeditorAddress = form.getFieldValue('expeditorAddress');
    submitParameters.controlMethods = form.getFieldValue('controlMethods');
    submitParameters.controlScop = form.getFieldValue('controlScop');
    submitParameters.controlPlaceNumber =
      form.getFieldValue('controlPlaceNumber');
    submitParameters.controlAdditionalUsers = form.getFieldValue(
      'controlAdditionalUsers',
    );
    submitParameters.expeditorDetails = form.getFieldValue('expeditorDetails');
    submitParameters.carriageData = form.getFieldValue('carriageData');
    submitParameters.expeditorRepresentative = form.getFieldValue(
      'expeditorRepresentative',
    );
    submitParameters.vehicle = form.getFieldValue('vehicle');
    submitParameters.vehicleLocation = form.getFieldValue('vehicleLocation');

    submitParameters.controlAuthor = form.getFieldValue('controlAuthor');
    submitParameters.controlPlace = form.getFieldValue('controlPlace');
    submitParameters.controlMethod = form.getFieldValue('controlMethod');
    submitParameters.isUnload = form.getFieldValue('isUnload');
    submitParameters.isScale = form.getFieldValue('isScale');
    submitParameters.isCounting = form.getFieldValue('isCounting');
    submitParameters.isMeasured = form.getFieldValue('isMeasured');
    submitParameters.isScan = form.getFieldValue('isScan');
    submitParameters.isCanineGroup = form.getFieldValue('isCanineGroup');
    submitParameters.controlResult = form.getFieldValue('controlResult');
    submitParameters.suspicion = form.getFieldValue('suspicion');
    submitParameters.inspector = form.getFieldValue('inspector');
    submitParameters.customsDocumentNotDeclared = form.getFieldValue(
      'customsDocumentNotDeclared',
    );
    submitParameters.economicalAgentRepresentative = form.getFieldValue(
      'economicalAgentRepresentative',
    );
    submitParameters.transporterRepresentative = form.getFieldValue(
      'transporterRepresentative',
    );
    submitParameters.documentDate = form.getFieldValue('documentDate');
    submitParameters.customsDocument = form.getFieldValue('customsDocument');
    submitParameters.controlReason = form.getFieldValue('controlReason');
    submitParameters.documentNumber = form.getFieldValue('documentNumber');
    setLoadingLoader(true);
    submitMethod(submitParameters)
      .then(() => {
        setActId(1);
        setAdditionalValues({});
        notification.success({
          message: successMessage || t('messages.success'),
          duration: 3,
        });
        setVisible(false);
        form.resetFields();
      })
      .catch((err) => {
        form.resetFields();
        notification.error({
          message: `${err}`,
        });
        setVisible(false);
      })
      .finally(() => setLoadingLoader(false));
  };

  const handleCancel = () => {
    form.resetFields();
    setAdditionalValues({});
    setVisible(false);
    onClose();
  };

  const handleGeneratePdf = () => {
    setLoadingLoader(true);
    let submitParameters = {};
    if (!actToShow) {
      submitParameters = { ...parameters };

      if (inputsBasicDetails) {
        inputsBasicDetails.forEach((element) => {
          if (form.getFieldValue(element.name)) {
            submitParameters[`${element.name}`] = form.getFieldValue(
              element.name,
            );
          }
        });
      }

      if (additionalValues) {
        Object.keys(additionalValues).forEach((k) => {
          if (!submitParameters[k]) {
            submitParameters[k] = additionalValues[k];
          }
        });
      }

      submitParameters.isPhysicalPerson = transactionTypeState === 'TPF';
      submitParameters.actNumber = form.getFieldValue('actNumber');
      submitParameters.actDate = form.getFieldValue('actDate');
      submitParameters.customsPost = form.getFieldValue('customsPost');
      submitParameters.postCode = user?.customsPost?.code;
      submitParameters.transactionType = form.getFieldValue('transactionType');
      submitParameters.expeditor = form.getFieldValue('expeditor');
      submitParameters.expeditorAddress =
        form.getFieldValue('expeditorAddress');
      submitParameters.controlMethods = form.getFieldValue('controlMethods');
      submitParameters.controlScop = form.getFieldValue('controlScop');
      submitParameters.controlPlaceNumber =
        form.getFieldValue('controlPlaceNumber');
      submitParameters.controlAdditionalUsers = form.getFieldValue(
        'controlAdditionalUsers',
      );
      submitParameters.expeditorDetails =
        form.getFieldValue('expeditorDetails');
      submitParameters.carriageData = form.getFieldValue('carriageData');
      submitParameters.expeditorRepresentative = form.getFieldValue(
        'expeditorRepresentative',
      );
      submitParameters.vehicle = form.getFieldValue('vehicle');
      submitParameters.vehicleLocation = form.getFieldValue('vehicleLocation');

      submitParameters.controlAuthor = form.getFieldValue('controlAuthor');
      submitParameters.controlPlace = form.getFieldValue('controlPlace');
      submitParameters.controlMethod = form.getFieldValue('controlMethod');
      submitParameters.isUnload = form.getFieldValue('isUnload');
      submitParameters.isScale = form.getFieldValue('isScale');
      submitParameters.isCounting = form.getFieldValue('isCounting');
      submitParameters.isMeasured = form.getFieldValue('isMeasured');
      submitParameters.isScan = form.getFieldValue('isScan');
      submitParameters.isCanineGroup = form.getFieldValue('isCanineGroup');
      submitParameters.controlResult = form.getFieldValue('controlResult');
      submitParameters.suspicion = form.getFieldValue('suspicion');
      submitParameters.inspector = form.getFieldValue('inspector');
      submitParameters.customsDocumentNotDeclared = form.getFieldValue(
        'customsDocumentNotDeclared',
      );
      submitParameters.economicalAgentRepresentative = form.getFieldValue(
        'economicalAgentRepresentative',
      );
      submitParameters.transporterRepresentative = form.getFieldValue(
        'transporterRepresentative',
      );
      submitParameters.documentDate = form.getFieldValue('documentDate');
      submitParameters.customsDocument = form.getFieldValue('customsDocument');
      submitParameters.controlReason = form.getFieldValue('controlReason');
      submitParameters.documentNumber = form.getFieldValue('documentNumber');
    } else {
      submitParameters = {
        ...actToShow,
        actDate: form.getFieldValue('actDate'),
        documentDate: form.getFieldValue('documentDate'),
      };
    }
    generatePdfInNewTab('/report/act-control', submitParameters)
      .then(() => {
        setActId(1);
        notification.success({
          message: t('messages.report.generated.success'),
          duration: 3,
        });
      })
      .catch((err) => {
        notification.error({ message: err });
      })
      .finally(() => setLoadingLoader(false));
  };

  // const handleGeneratePdfFull = () => {
  //   setLoadingLoader(true);
  //   const submitParameters = { ...parameters };
  //   inputsBasicDetails.forEach((element) => {
  //     if (form.getFieldValue(element.name)) {
  //       submitParameters[`${element.name}`] = form.getFieldValue(element.name);
  //     }
  //   });

  //   if (additionalValues) {
  //     Object.keys(additionalValues).forEach((k) => {
  //       if (!submitParameters[k]) {
  //         submitParameters[k] = additionalValues[k];
  //       }
  //     });
  //   }

  //   submitParameters.isPhysicalPerson = transactionTypeState === 'TPF';
  //   submitParameters.actNumber = form.getFieldValue('actNumber');
  //   submitParameters.actDate = form.getFieldValue('actDate');
  //   submitParameters?.customsPost = form.getFieldValue('customsPost');
  //   submitParameters.postCode = user?.customsPost?.code;
  //   submitParameters.transactionType = form.getFieldValue('transactionType');
  //   submitParameters.expeditor = form.getFieldValue('expeditor');
  //   submitParameters.expeditorAddress = form.getFieldValue('expeditorAddress');
  //   submitParameters.controlMethods = form.getFieldValue('controlMethods');
  //   submitParameters.controlScop = form.getFieldValue('controlScop');
  //   submitParameters.controlPlaceNumber =
  //     form.getFieldValue('controlPlaceNumber');
  //   submitParameters.controlAdditionalUsers = form.getFieldValue(
  //     'controlAdditionalUsers',
  //   );
  //   submitParameters.expeditorDetails = form.getFieldValue('expeditorDetails');
  //   submitParameters.carriageData = form.getFieldValue('carriageData');
  //   submitParameters.expeditorRepresentative = form.getFieldValue(
  //     'expeditorRepresentative',
  //   );
  //   submitParameters.vehicle = form.getFieldValue('vehicle');
  //   submitParameters.vehicleLocation = form.getFieldValue('vehicleLocation');

  //   submitParameters.controlAuthor = form.getFieldValue('controlAuthor');
  //   submitParameters.controlPlace = form.getFieldValue('controlPlace');
  //   submitParameters.controlMethod = form.getFieldValue('controlMethod');
  //   submitParameters.isUnload = form.getFieldValue('isUnload');
  //   submitParameters.isScale = form.getFieldValue('isScale');
  //   submitParameters.isCounting = form.getFieldValue('isCounting');
  //   submitParameters.isMeasured = form.getFieldValue('isMeasured');
  //   submitParameters.isScan = form.getFieldValue('isScan');
  //   submitParameters.isCanineGroup = form.getFieldValue('isCanineGroup');
  //   submitParameters.controlResult = form.getFieldValue('controlResult');
  //   submitParameters.suspicion = form.getFieldValue('suspicion');
  //   submitParameters.inspector = form.getFieldValue('inspector');
  //   submitParameters.customsDocumentNotDeclared = form.getFieldValue(
  //     'customsDocumentNotDeclared',
  //   );
  //   submitParameters.economicalAgentRepresentative = form.getFieldValue(
  //     'economicalAgentRepresentative',
  //   );
  //   submitParameters.transporterRepresentative = form.getFieldValue(
  //     'transporterRepresentative',
  //   );
  //   submitParameters.documentDate = form.getFieldValue('documentDate');
  //   submitParameters.customsDocument = form.getFieldValue('customsDocument');
  //   submitParameters.controlReason = form.getFieldValue('controlReason');
  //   submitParameters.documentNumber = form.getFieldValue('documentNumber');

  //   generatePdfInNewTab('/report/act-control-full', submitParameters)
  //     .then(() => {
  //       setLoadingLoader(false);
  //       setActId(1);
  //       notification.success({
  //         message: t('messages.report.generated.success'),
  //         duration: 3,
  //       });
  //     })
  //     .catch((err) => {
  //       setLoadingLoader(false);
  //       notification.error({ message: err });
  //     });
  // };

  const handleTransactionTypeChange = (name) => {
    if (name) {
      const ceva = customsRegimes.content.find((el) => el.name === name)?.code;
      setTransactionTypeState(ceva);
    } else setTransactionTypeState(null);
  };

  const handleCustomsDocumentNotDeclared = (value) => {
    setCustomsDocumentNotDeclaredState(value?.target?.checked);
    if (value?.target?.checked) {
      form.setFieldsValue({
        customsDocument: null,
        documentNumber: null,
        documentDate: null,
      });
    }
  };

  return (
    <>
      <div className={`loaderOverlay ${loadingLoader ? 'active' : ''}`}>
        <AppLoader />
      </div>

      {parameters ? (
        <Button
          type="primary"
          onClick={handleRender}
          style={{ width: '100%', marginBottom: '.5rem' }}
          icon={<FileTextOutlined />}
        >
          {buttonCaption}
        </Button>
      ) : null}
      <Modal
        maskClosable={false}
        width={modalTitle.includes('Act de control') ? 1000 : 600}
        visible={visible}
        title={modalTitle}
        centered
        onOk={form.submit}
        onCancel={handleCancel}
        cancelText="Anulați"
        style={{ marginTop: '20px', marginBottom: '20px' }}
        footer={[
          <Button
            key="back"
            onClick={handleCancel}
            style={{ float: 'left' }}
            icon={<RollbackOutlined />}
          >
            Înapoi
          </Button>,
          <Popconfirm
            key="generateReportPop2"
            disabled={readOnly && !actId && !allowGenerate}
            placement="topRight"
            title={
              actId
                ? 'Sunteți sigur?'
                : 'La generarea actului va fi generat număr unic care va fi inclus in Registrul actelor de control. Sunteți sigur?'
            }
            onConfirm={handleGeneratePdf}
            okText="Ok"
            cancelText={t('actions.cancel')}
          >
            <Button
              key="generateReport"
              style={{ marginLeft: '10px' }}
              disabled={readOnly && !actId && !allowGenerate}
              icon={<FileAddOutlined />}
            >
              Generează act simplu
            </Button>
          </Popconfirm>,
          parameters ? (
            <Popconfirm
              key="generateReportPop"
              placement="topRight"
              disabled={readOnly}
              title={
                actId
                  ? 'Sunteți sigur?'
                  : 'La salvarea datelor va fi generat număr unic care va fi inclus in Registrul actelor de control. Sunteți sigur?'
              }
              onConfirm={form.submit}
              okText="Ok"
              cancelText={t('actions.cancel')}
            >
              <Button
                key="submit"
                type="primary"
                style={{ marginLeft: '10px' }}
                disabled={readOnly}
                icon={<SaveOutlined />}
              >
                Salvare date
              </Button>
            </Popconfirm>
          ) : null,
        ]}
      >
        <Form form={form} layout="vertical" onFinish={onSubmit}>
          <h2 style={{ textAlign: 'center' }}>ACTUL</h2>
          <h3 style={{ textAlign: 'center' }}>de control fizic vamal</h3>
          <Row id="nrAndDate">
            <Col span={6} style={{ paddingRight: '10px' }}>
              <Form.Item label="nr." name="actNumber">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={6} style={{ paddingRight: '10px' }}>
              <Form.Item label="din" name="actDate">
                <DatePicker disabled format="DD.MM.YYYY" />
              </Form.Item>
            </Col>
            <Col span={6} style={{ paddingRight: '10px' }}>
              <Form.Item
                label="Postul vamal"
                name="customsPost"
                initialValue={
                  actToShow
                    ? actToShow?.customsPost
                    : `${parameters?.customsPost.newCode} - ${parameters?.customsPost.name}`
                }
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={6} style={{ paddingRight: '10px' }}>
              <Form.Item label="Tip operațiune" name="transactionType">
                <Select
                  allowClear
                  showSearch
                  disabled={readOnly}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) !== -1
                  }
                  onChange={handleTransactionTypeChange}
                >
                  {customsRegimes?.content?.map(
                    (option) =>
                      !option.deleted && (
                        <Select.Option key={option.id} value={option.name}>
                          {`${option.name}`}
                        </Select.Option>
                      ),
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {transactionTypeState !== 'TPF' && (
            <Row id="expeditorRow">
              <Col span={24} style={{ paddingRight: '10px' }}>
                <Form.Item
                  label="Lotul de mărfuri expediat de către"
                  name="expeditor"
                >
                  <TextArea
                    showCount
                    maxLength={4000}
                    rows={3}
                    style={{ width: '100%' }}
                    disabled={readOnly}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row id="expeditorAddressRow">
            <Col span={24} style={{ paddingRight: '10px' }}>
              <Form.Item label="În adresa" name="expeditorAddress">
                <Select
                  allowClear
                  showSearch
                  disabled={readOnly}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) !== -1
                  }
                >
                  {inAddressEnum?.content?.map((option) => (
                    <Select.Option key={option.id} value={option.name}>
                      {`${option.name}`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ paddingRight: '10px' }}>
              <Form.Item name="expeditorDetails">
                <TextArea
                  showCount
                  maxLength={2000}
                  rows={3}
                  style={{ width: '100%' }}
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
          </Row>

          {transactionTypeState !== 'TPF' && (
            <Row id="expeditorRepresentativeKey">
              <Col style={{ paddingRight: '10px' }} span={12}>
                <Form.Item
                  label="Reprezentantul"
                  name="expeditorRepresentative"
                >
                  <Input disabled={readOnly} maxLength={255} />
                </Form.Item>
              </Col>
              <Col style={{ paddingRight: '10px' }} span={12}>
                <Form.Item
                  label="Conform actelor de trasura"
                  name="carriageData"
                >
                  <Input disabled={readOnly} maxLength={255} />
                </Form.Item>
              </Col>
            </Row>
          )}

          {transactionTypeState !== 'TPF' && (
            <>
              <Row>
                <Col span={8} style={{ paddingRight: '10px' }}>
                  <Form.Item
                    name="customsDocumentNotDeclared"
                    valuePropName="checked"
                  >
                    <Checkbox
                      defaultChecked="false"
                      disabled={readOnly}
                      onChange={handleCustomsDocumentNotDeclared}
                    >
                      Nu doreste sa declare documente vamale
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8} style={{ paddingRight: '10px' }}>
                  <Form.Item label="Document vamal" name="customsDocument">
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) !== -1
                      }
                      disabled={customsDocumentNotDeclaredState || readOnly}
                    >
                      {customsDocumentTypes?.content?.map((option) => (
                        <Select.Option key={option.id} value={option.name}>
                          {`${option.name}`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ paddingRight: '10px' }}>
                  <Form.Item label=" Nr. " name="documentNumber">
                    <Input
                      onChange={(e) => {
                        form.setFieldsValue({
                          documentNumber: maxLengthValidator(
                            e.target.value,
                            20,
                          ),
                        });
                      }}
                      disabled={customsDocumentNotDeclaredState || readOnly}
                    />
                  </Form.Item>
                </Col>
                <Col span={8} style={{ paddingRight: '10px' }}>
                  <Form.Item label=" din " name="documentDate">
                    <DatePicker
                      disabled={customsDocumentNotDeclaredState || readOnly}
                      format="DD.MM.YYYY"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          <Row id="transportUnit">
            <Col span={8} style={{ paddingRight: '10px' }}>
              <Form.Item
                label="S-a efectuat controlul unității de transport"
                name="vehicle"
              >
                <Input disabled style={{ textTransform: 'uppercase' }} />
              </Form.Item>
            </Col>
            <Col span={8} style={{ paddingRight: '10px' }}>
              <Form.Item label="Locația" name="vehicleLocation">
                <Select
                  allowClear
                  showSearch
                  disabled={readOnly}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) !== -1
                  }
                >
                  {controlLocations?.content?.map((option) => (
                    <Select.Option key={option.id} value={option.name}>
                      {`${option.name}`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8} style={{ paddingRight: '10px' }}>
              <Form.Item
                label="Controlul a efectuat"
                name="controlAuthor"
                initialValue={actToShow?.inspector || parameters?.userFullname}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24} style={{ paddingRight: '10px' }}>
              <Form.Item
                label="La control au asistat"
                name="controlAdditionalUsers"
              >
                <TextArea
                  showCount
                  maxLength={2000}
                  rows={3}
                  style={{ width: '100%' }}
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row id="controlPlaceKey">
            <Col span={12} style={{ paddingRight: '10px' }}>
              <Form.Item
                label="Locul efectuarii controlului"
                name="controlPlace"
              >
                <Input disabled={readOnly} maxLength={255} />
              </Form.Item>
            </Col>
            <Col span={12} style={{ paddingRight: '10px' }}>
              <Form.Item
                label="Numarul locurilor si coletelor verificate"
                name="controlPlaceNumber"
              >
                <Input disabled={readOnly} maxLength={150} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24} style={{ paddingRight: '10px' }}>
              <Form.Item
                label="Scopul controlului fizic vamal"
                name="controlScop"
              >
                <TextArea
                  showCount
                  maxLength={2000}
                  rows={3}
                  style={{ width: '100%' }}
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12} style={{ paddingRight: '10px' }}>
              <Form.Item label="Motivul controlului fizic" name="controlReason">
                <Select
                  allowClear
                  showSearch
                  disabled={readOnly}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) !== -1
                  }
                >
                  {controlReasons?.content?.map((option) => (
                    <Select.Option key={option.id} value={option.name}>
                      {`${option.name}`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12} style={{ paddingRight: '10px' }}>
              <Form.Item label="Metoda de control" name="controlMethod">
                <Select
                  allowClear
                  showSearch
                  disabled={readOnly}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) !== -1
                  }
                >
                  {controlTypeEnum?.content?.map((option) => (
                    <Select.Option key={option.id} value={option.name}>
                      {`${option.name}`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row className="act__check" style={{ margin: '10px 0' }}>
            <Col span={4}>
              <Form.Item
                name="isUnload"
                valuePropName="checked"
                label="Descărcare"
              >
                <Checkbox
                  style={{ paddingRight: '10px' }}
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="isScale"
                valuePropName="checked"
                label="Cântărire"
              >
                <Checkbox
                  style={{ paddingRight: '10px' }}
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="isCounting"
                valuePropName="checked"
                label="Numărare"
              >
                <Checkbox
                  style={{ paddingRight: '10px' }}
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="isMeasured"
                valuePropName="checked"
                label="Măsurare"
              >
                <Checkbox
                  style={{ paddingRight: '10px' }}
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="isScan" valuePropName="checked" label="Scanare">
                <Checkbox
                  style={{ paddingRight: '10px' }}
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="isCanineGroup"
                valuePropName="checked"
                label="Echipa canina"
                float="right"
              >
                <Checkbox
                  style={{ paddingRight: '10px' }}
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12} style={{ paddingRight: '10px' }}>
              <Form.Item
                label="Metode si mijloace tehnice de control utilizate in cadrul controlului"
                name="controlMethods"
              >
                <Input disabled={readOnly} maxLength={255} />
              </Form.Item>
            </Col>
            <Col span={12} style={{ paddingRight: '10px' }}>
              <Form.Item label="Suspiciune" name="suspicion">
                <Select
                  allowClear
                  showSearch
                  disabled={readOnly}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) !== -1
                  }
                >
                  {suspicionTypeEnum?.content?.map((option) => (
                    <Select.Option key={option.id} value={option.name}>
                      {`${option.name}`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ paddingRight: '10px' }}>
              <Form.Item label="Rezultatul controlului" name="controlResult">
                <TextArea
                  showCount
                  maxLength={4000}
                  rows={6}
                  style={{ width: '100%' }}
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8} style={{ paddingRight: '10px' }}>
              <Form.Item
                label="Inspector vamal"
                name="inspector"
                initialValue={actToShow?.inspector || parameters?.userFullname}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8} style={{ paddingRight: '10px' }}>
              <Form.Item
                label="Reprezentantul agentului economic"
                name="economicalAgentRepresentative"
              >
                <Input disabled={readOnly} maxLength={255} />
              </Form.Item>
            </Col>
            <Col span={8} style={{ paddingRight: '10px' }}>
              <Form.Item
                label="Reprezentantul transportatorului"
                name="transporterRepresentative"
              >
                <Input disabled={readOnly} maxLength={255} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ActDeControlParametersModal;
