import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Image,
  Input,
  InputNumber,
  Modal,
  notification,
  Row,
  Select,
  Spin,
  Switch,
} from 'antd';

import { RightCircleOutlined, SaveOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import TextArea from 'antd/lib/input/TextArea';
import useDictionaries from '../../../hooks/useDictionaries';
import { FormContextProvider } from '../../../hooks/useFormContext';
import {
  getOrganizationByIdno,
  getPersonByIdnp,
} from '../../../services/admin/web-services/sfs-organizatii';
import CancelButton from '../../buttons/BackButton';
import { getCountryByAlpha3 } from '../../../services/taxonomies/countries';
import {
  correctTransaction,
  getConsemneBy,
  saveAndSendTransaction,
  saveTransaction,
} from '../../../services/admin/web-services/backend';
import {
  getPerson,
  getPersonsRef as getPersonsReference,
  getPlatesRef,
  getVehicle,
  getVehiclesRef as getVehiclesReference,
} from '../../../services/admin/reference';
import { getLastVicosCroppedByPlaterNumbe } from '../../../services/admin/vicos';
import { findAll as findAllVehicleCategories } from '../../../services/taxonomies/vehicle-categories';
import { findAll as findAllTrailerTypes } from '../../../services/taxonomies/trailer-types';
import { checkDaysInRM } from '../../../services/transactions/transactions';
import useFormErrors from '../../../hooks/useFormErrors';
import AuthContext, { hasPermission } from '../../auth';
import { findAll as findAllCountries } from '../../../services/admin/geo/countries';
import { findAll as findAllConstants } from '../../../services/taxonomies/constants';
import { findAll as findAllVehicleTypes } from '../../../services/taxonomies/vehicle-types';
import { findAll as findAllCustomsRegimes } from '../../../services/taxonomies/customs-regime';
import { findAeoPresence } from '../../../services/admin/web-services/aeo';
import CorrectionTable from '../physical-person-components/CorrectionTable';
import AppLoader from '../../auth/AppLoader';
import { useTableScroll } from '../../../hooks/useTableScroll';
import aeoLogo from '../../../assets/aeo-logo.png';
import EditTransaction from '../../buttons/EditTransaction';
import CountryDropDown from '../../DropDownComponent';
import {
  dotValidator,
  floatValidator,
  maxLengthValidator,
  maxNumberValidator,
  nameValidator,
  numberValidator,
  vinNumberValidator,
} from '../../../services/utils/validator/Validator';
import InputHelper from '../../../services/utils/helper/InputHelper';
import TransactionStatus from '../../../enums/TransactionStatus';
import ImpoundedCarCheckbox from '../../admin/checkboxes/ImpoundedCarCheckbox';
import { convertTonesToKilos } from '../../../services/utils/convertors/weigth-converter';
import LiberModal from '../../admin/modals/LiberModal';
import GeneralDataTimeLine from './TimeLine';
import HistoricalDataAndAnalysis from './HistoricalDataAndAnalysis';
import CopyTransactionModal from './CopyTransactionModal';
import PreSearchModal from './PreSearchModal';
import { validate } from './GeneralDataValidation';
import CorrectionReasonModal from './CorrectionReasonModal';
import TransactionDocumentsTable from './TransactionDocumentsTable';
import RequiredLabel from '../../../services/utils/RequiredLabel';
import DriverImage from './DriverImage';
import GoodsDocumentTable from './goodsDocuments/GoodsDocumentsTable';
import CopyTransactionTable from './CopyTransactionTable';

const directions = [
  { id: 'IN', name: 'INTRARE' },
  { id: 'OUT', name: 'IEȘIRE' },
];

const dictionaries = {
  vehicleCategories: findAllVehicleCategories,
  customsRegimes: findAllCustomsRegimes,
  vehicleTypes: findAllVehicleTypes,
  trailerTypes: findAllTrailerTypes,
  countries: findAllCountries,
  constants: findAllConstants,
};

const GeneralData = ({
  transaction,
  errors,
  readOnly = false,
  editMode,
  reload,
  setEditMode,
  transformTransaction,
  moveNext = () => {},
}) => {
  const [form] = Form.useForm();
  const [goodsDocuments, setGoodsDocuments] = useState([]);
  const [image, setImage] = useState(null);
  const [isCargo, setIsCargo] = useState(false);
  const [isSeedSent, setSeedSent] = useState(false);
  const [consemneStr, setConsemneStr] = useState(null);
  const [consemneList, setConsemneList] = useState(null);
  const [loadingLoader, setLoadingLoader] = useState(false);
  const [hideContainers, setHideContainers] = useState(true);
  const [irregularities, setIrregularities] = useState(null);
  const [cargoCategories, setCargoCategories] = useState([]);
  const [daysInRMLoader, setDaysInRMLoader] = useState(false);
  const [defaultCountryId, setDefaultCountryId] = useState(-1);
  const [timelineDependency, setTimelineDependency] = useState(0);
  const [hasGoods, setHasGoods] = useState(transaction?.hasGoods);
  const [hasScale, setHasScale] = useState();
  const [allowedVehicleCategories, setAllowedVehicleCategories] = useState([]);
  const [consemnResponse, setConsemnResponse] = useState(null);
  const [isVehicleOwner, setIsVehicleOwner] = useState(
    !transaction.ownerPersonalCode,
  );
  const [physicalCarrier, setPhysicalCarrier] = useState(
    transaction?.transporterIsPhysicalPerson,
  );
  const [requiredTransporter, setRequiredTransporter] = useState(
    transaction?.idno,
  );
  const [requiredOwner, setRequiredOwner] = useState(
    transaction?.ownerPersonalCode,
  );
  const [daysInRMObj, setDaysInRMObj] = useState({
    daysInRM: transaction.daysInRM || 0,
    daysInRMFromLastEntry: transaction.daysInRMFromLastEntry || 0,
    isOverstaying: transaction.isOverstaying,
  });
  const [personIdnp, setPersonIdnp] = useState(transaction?.idnp);
  const [isSaveAndSendModalVisible, setIsSaveAndSendModalVisible] =
    useState(false);
  const [isAeo, setIsAeo] = useState(
    transaction?.personList?.find((el) => !el.isPhysicalPerson)?.isAeo,
  );
  const [hasDrivingLicence, setHasDrivingLicence] = useState(
    transaction?.drivingLicence !== null && transaction?.id,
  );
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const { permissions } = user;

  const reloadTimelines = useCallback(() => {
    setTimelineDependency((prev) => prev + 1);
  }, []);

  const { t } = useTranslation();
  const [
    {
      countries,
      vehicleCategories,
      vehicleTypes,
      trailerTypes,
      constants,
      customsRegimes,
    },
  ] = useDictionaries(dictionaries);
  const { loading, content } = countries;

  useMemo(() => {
    if (transaction?.id) {
      history.push(`/physical-person-entry/${transaction?.id}#GENERAL_DATA`);
    }
    // eslint-disable-next-line
  }, []);

  useTableScroll();

  useEffect(() => {
    if (transaction?.scale) {
      const {
        vehicleWeight,
        goodsWeight,
        totalWeight,
        axisCount,
        axisDistance,
        distance,
      } = transaction.scale;

      const isScaleActive = [
        vehicleWeight,
        goodsWeight,
        totalWeight,
        axisCount,
        axisDistance,
        distance,
      ].some((value) => value !== null && value !== undefined);
      setHasScale(isScaleActive);
    } else {
      setHasScale(false);
    }
  }, [transaction.scale]);

  const handleSearchConsemne = useCallback(
    (personCode, isPJ) => {
      if (form.getFieldValue(['directions'])) {
        getConsemneBy(
          personCode || '-1',
          user.postId,
          form.getFieldValue(['directions']),
        ).then((arr) => {
          const hz = [];
          if (consemneList) {
            if (isPJ) {
              hz.push(...consemneList.filter((el) => !el.idno));
            } else {
              hz.push(...consemneList.filter((el) => !el.idnp));
            }
          }
          if (arr) {
            const currentConsemneIds =
              hz.map((el) => el.id).filter((el) => el) || [];
            hz.push(...arr.filter((el) => !currentConsemneIds.includes(el.id)));
          }
          const actions = (hz || [])
            .map(
              (action) =>
                `(${dayjs(action.validFrom).format('DD.MM.YY')} - ${
                  action.riskLevelDescription
                }) ${action.action}\n`,
            )
            .join('');

          setConsemneList(hz);
          setConsemneStr(actions);
          form.setFieldsValue({ commentsForm: actions });
        });
      }
    },
    [form, consemneList, user.postId],
  );

  useEffect(() => {
    getCountryByAlpha3('MDA').then((res) => setDefaultCountryId(res?.id));
  }, []);

  useEffect(() => {
    const categories =
      constants?.content
        ?.find((el) => el.code === 'TERMINAL_MARFA_CATEGORII')
        ?.value?.split(',') || [];

    setCargoCategories(categories);

    if (categories.includes(transaction?.vehicleType)) {
      setIsCargo(true);
    } else {
      setIsCargo(false);
    }
  }, [constants?.content, transaction?.vehicleType]);

  useEffect(() => {
    if (transaction?.isDuplicated) {
      notification.warning({
        key: 'duplicatedNotification',
        message: 'Atenție această rută este dublată',
        duration: 0,
      });
    }
    return () => notification.destroy('duplicatedNotification');
  }, [transaction?.isGeneralDataConfirmed, transaction?.isDuplicated]);

  useEffect(() => {
    form.setFieldsValue(transaction);
    // eslint-disable-next-line
  }, [form, editMode]);

  const onVehicleTypeChange = useCallback(
    (value) => {
      const withCargo = cargoCategories.includes(value);
      setIsCargo(withCargo);
      if (!transaction?.id) {
        setHasGoods(withCargo);
        form.setFieldsValue({ hasGoods: withCargo });
      }
    },
    [form, transaction?.id, cargoCategories],
  );

  useFormErrors(form, errors);
  useEffect(() => {
    setAllowedVehicleCategories(
      vehicleCategories?.content.filter((el) => !el?.deleted),
    );
  }, [vehicleCategories?.content]);

  const handleChangeTypeTrailer = useCallback(
    (value) => {
      const type = trailerTypes?.content?.find((el) => el.id === value);
      if (type?.name === 'Container') {
        setHideContainers(false);
      } else {
        setHideContainers(true);
      }
    },
    [trailerTypes?.content],
  );

  useEffect(() => {
    if (transaction?.trailerTypeId1) {
      handleChangeTypeTrailer(transaction?.trailerTypeId1);
    }
  }, [handleChangeTypeTrailer, transaction?.trailerTypeId1]);

  useEffect(
    () => {
      if (transaction?.id) {
        form.setFieldsValue(transaction);
        setIsVehicleOwner(transaction?.isVehicleOwner);

        let irregularitiesMessage = '';
        if (transaction?.irregularities) {
          if (transaction?.irregularities.length > 0) {
            irregularitiesMessage = `Iregularități: ${transaction?.irregularities}`;
            setIrregularities(irregularitiesMessage);
          } else {
            setIrregularities(null);
          }
        }

        if (transaction?.consemne && transaction?.consemne?.length > 0) {
          const actions = (transaction?.consemne || [])
            .map(
              (action) =>
                `(${
                  action.validFromStr ||
                  dayjs(action.validFrom).format('DD.MM.YYYY')
                } - ${action.riskLevelDescription}) ${action.actiune}\n`,
            )
            .join('');
          setConsemneStr(actions);
          setConsemneList(transaction?.consemne);
        }
      }
    }, // eslint-disable-next-line
    [
      form,
      transaction?.id,
      transaction?.consemne,
      transaction?.irregularities,
      transaction?.message?.message,
    ],
  );

  const calculateMasaTotala = useCallback(() => {
    if (
      form.getFieldValue(['vehicleWeight']) &&
      form.getFieldValue(['goodsWeight'])
    ) {
      const vehicleW = Number(form.getFieldValue('vehicleWeight'));
      const goodsW = Number(form.getFieldValue('goodsWeight'));

      const totalWeight = (vehicleW + goodsW).toFixed(3).toString();

      form.setFieldsValue({
        totalWeight,
      });
    } else {
      form.setFieldsValue({
        totalWeight: null,
      });
    }
  }, [form]);

  const searchCompany = useCallback(
    (val) => {
      if (physicalCarrier) {
        return getPersonByIdnp(val);
      }
      return getOrganizationByIdno(val);
    },
    [physicalCarrier],
  );

  const debouncedCheckAeoPresence = useCallback(
    (idnoInput) => {
      if (idnoInput) {
        findAeoPresence(idnoInput)
          .then((res) => {
            setIsAeo(true);
            form.setFieldsValue({
              nameCompany: res.companyName,
              companyCountry: res.resident ? defaultCountryId : undefined,
              typeCompany: res.resident,
            });
          })
          .catch(() => setIsAeo(false));
        handleSearchConsemne(idnoInput, true);
      }
    },
    [handleSearchConsemne, form, defaultCountryId],
  );

  const handleSearchOrganizationByIDNO = useCallback(() => {
    let idnoInput = form.getFieldValue(['idno']);
    const title = `Căutare ${physicalCarrier ? 'persoană' : 'organizație'}`;
    const personalCodeInput = form.getFieldValue(['personalCode']);
    if (idnoInput && idnoInput.trim().length === 13) {
      idnoInput = idnoInput.trim();
      setLoadingLoader(true);
      searchCompany(idnoInput)
        .then((org) => {
          if (org && (org.idno || org.idnp)) {
            setLoadingLoader(false);
            if (physicalCarrier) {
              form.setFieldsValue({
                nameCompany: `${org.lastName} ${org.firstName}`,
                typeCompany: org.personType === 'REZIDENT',
                companyCountry: defaultCountryId,
              });
            } else {
              form.setFieldsValue({
                nameCompany: org.shortName,
                typeCompany: org.typeCompany === 'REZIDENT',
                companyCountry: defaultCountryId,
              });
            }
          } else {
            setLoadingLoader(false);
            notification.warning({
              message: title,
              description: `Nu a fost gasită ${
                physicalCarrier
                  ? 'persoană cu IDNP-ul'
                  : 'organizație cu IDNO-ul'
              } dat.`,
            });
          }
        })
        .catch(() => {
          setLoadingLoader(false);
          notification.error({
            message: title,
            description: `Eroare la căutarea ${
              physicalCarrier ? 'persoanei' : 'organizației'
            }.`,
          });
        });
    } else if (personalCodeInput) {
      const arr = consemneList?.filter((el) => el.idnp === personalCodeInput);
      const actions = (arr || [])
        .map(
          (action) =>
            `(${action.validFromStr} - ${action.riskLevelDescription}) ${action.actiune}\n`,
        )
        .join('');
      setConsemneStr(actions);
      setConsemneList(arr);
      form.setFieldsValue({
        commentsForm: actions,
        nameCompany: null,
        typeCompany: false,
        companyCountry: null,
      });
    } else {
      setConsemneStr(null);
      setConsemneList([]);
    }
  }, [form, defaultCountryId, consemneList, searchCompany, physicalCarrier]);

  const handleSearchAgentOrganizationByIDNO = useCallback(() => {
    let idnoInput = form.getFieldValue(['agentIdno']);
    const title = 'Căutare organizație';
    const personalCodeInput = form.getFieldValue(['personalCode']);
    if (idnoInput && idnoInput.trim().length === 13) {
      idnoInput = idnoInput.trim();
      setLoadingLoader(true);
      getOrganizationByIdno(idnoInput)
        .then((org) => {
          if (org && org.idno) {
            setLoadingLoader(false);
            form.setFieldsValue({
              agentName: org.shortName,
              agentType: org.typeCompany === 'REZIDENT',
              agentCountry: defaultCountryId,
            });
          } else {
            setLoadingLoader(false);
            notification.warning({
              message: title,
              description: 'Nu a fost gasită organizație cu IDNO-ul dat.',
            });
          }
        })
        .catch(() => {
          setLoadingLoader(false);
          notification.error({
            message: title,
            description: 'Eroare la căutarea organizației.',
          });
        });
    } else if (personalCodeInput) {
      const arr = consemneList?.filter((el) => el.idnp === personalCodeInput);
      const actions = (arr || [])
        .map(
          (action) =>
            `(${action.validFromStr} - ${action.riskLevelDescription}) ${action.actiune}\n`,
        )
        .join('');
      setConsemneStr(actions);
      setConsemneList(arr);
      form.setFieldsValue({
        commentsForm: actions,
        agentName: null,
        agentType: false,
        agentCountry: null,
      });
    } else {
      setConsemneStr(null);
      setConsemneList([]);
    }
  }, [form, defaultCountryId, consemneList]);

  const handleSearchPersonByIDNP = useCallback(
    () => {
      let personalCodeInput = form.getFieldValue(['personalCode']);
      const idnoInput = form.getFieldValue(['idno']);
      const title = 'Căutare persoană';
      if (personalCodeInput) {
        handleSearchConsemne(personalCodeInput, false);
      }

      setPersonIdnp(personalCodeInput);
      if (personalCodeInput && personalCodeInput.trim().length === 13) {
        setLoadingLoader(true);
        personalCodeInput = personalCodeInput.trim();
        getPersonByIdnp(personalCodeInput)
          .then((pers) => {
            setLoadingLoader(false);
            if (pers && pers.idnp) {
              form.setFieldsValue({
                fullName: `${pers.lastName} ${pers.firstName}`,
                personType: pers.personType === 'REZIDENT',
                personCountry: defaultCountryId,
              });
              reloadTimelines();
            } else {
              setLoadingLoader(false);
              notification.warning({
                message: title,
                description: 'Nu a fost gasită persoană cu IDNP-ul introdus.',
              });
            }
          })
          .catch(() => {
            setLoadingLoader(false);
            notification.error({
              message: title,
              description: 'Eroare la căutarea persoanei.',
            });
          });
      } else if (idnoInput) {
        const arr = consemneList?.filter(
          (el) => el.idno === idnoInput || el.idno === idnoInput.trim(),
        );
        const actions = (arr || [])
          .map(
            (action) =>
              `(${action.validFromStr} - ${action.riskLevelDescription}) ${action.actiune}\n`,
          )
          .join('');
        setConsemneStr(actions);
        setConsemneList(arr);
      } else {
        setConsemneStr(null);
        setConsemneList([]);
      }
    }, // eslint-disable-next-line
    [form, defaultCountryId, consemneList, handleSearchConsemne],
  );

  const handleSearchOwnerByIDNP = useCallback(() => {
    let ownerPersonalCodeInput = form.getFieldValue(['ownerPersonalCode']);

    const title = 'Căutare proprietar';

    if (ownerPersonalCodeInput && ownerPersonalCodeInput.trim().length === 13) {
      ownerPersonalCodeInput = ownerPersonalCodeInput.trim();
      setLoadingLoader(true);
      getPersonByIdnp(ownerPersonalCodeInput)
        .then((pers) => {
          if (pers && pers.idnp) {
            setLoadingLoader(false);
            form.setFieldsValue({
              ownerFullName: `${pers.lastName} ${pers.firstName}`,
              ownerType: pers.personType === 'REZIDENT',
              ownerCountry: defaultCountryId,
            });
          } else {
            setLoadingLoader(false);
            notification.warning({
              message: title,
              description: 'Nu a fost gasită persoana cu IDNP-ul introdus.',
            });
          }
        })
        .catch(() => {
          setLoadingLoader(false);
          notification.error({
            message: title,
            description: 'Eroare la căutarea persoanei.',
          });
        });
    } else {
      setLoadingLoader(false);
      form.setFieldsValue({
        ownerFullName: null,
        ownerType: false,
        ownerCountry: null,
      });
    }
  }, [form, defaultCountryId]);

  // TODO NU STERGE METODA DE MAI JOS (cind vom implementa mconnect-asp-transport vom dizabla metoda)
  // const handleSearchVehicleByNumber = useCallback(() => {
  //   let vehicleNumberInput = form.getFieldValue(['vehicleNumber']);
  //   const title = 'Căutare vehicul';
  //   if (vehicleNumberInput && vehicleNumberInput.trim().length >= 6) {
  //     setLoadingLoader(true);
  //     vehicleNumberInput = vehicleNumberInput.trim();
  //     getVehicleByPlateNumber(vehicleNumberInput)
  //       .then((vehicle) => {
  //         if (vehicle && vehicle.plateNumber) {
  //           setLoadingLoader(false);
  //           form.setFieldsValue({
  //             vehicleVinCode: vehicle?.vinCode,
  //             vehicleCountry: defaultCountryId,
  //             vehicleType: vehicle?.category,
  //           });
  //           if (vehicle?.vinCode) {
  //             setLoadingLoader(false);
  //             getTimeline(vehicle?.vinCode).then((response) => {
  //               setTimelines(response);
  //             });
  //           }
  //           if (
  //             vehicle?.vinCode &&
  //             form.getFieldValue(['personalCode']) &&
  //             form.getFieldValue(['personalCode']).length === 13
  //           ) {
  //             setLoadingLoader(false);
  //             getCrossing(
  //               vehicle?.vinCode,
  //               form.getFieldValue(['personalCode']),
  //             ).then((response) => {
  //               setLoadingLoader(false);
  //               setCrossings(response);
  //             });
  //           } else {
  //             setLoadingLoader(false);
  //             setCrossings([]);
  //           }
  //         } else {
  //           setLoadingLoader(false);
  //           notification.warning({
  //             message: title,
  //             description: 'Nu a fost gasit vehicul.',
  //           });
  //         }
  //       })
  //       .catch(() => {
  //         setLoadingLoader(false);
  //         notification.error({
  //           message: title,
  //           description: 'Eroare la căutarea vehicul.',
  //         });
  //       });
  //   } else {
  //     setLoadingLoader(false);
  //     setTimelines([]);
  //     setCrossings([]);
  //   }
  // }, [form, defaultCountryId]);

  // TODO NU STERGE METODA DE MAI JOS (cind vom implementa mconnect-asp-transport vom dizabla metoda)
  // const handleSearchVehicleByVIN = useCallback(() => {
  //   const title = 'Căutare vehicul';
  //   const vehicleNumberInput = form.getFieldValue(['vehicleNumber']);
  //   let vehicleVinCodeInput = form.getFieldValue(['vehicleVinCode']);
  //   if (
  //     (!vehicleNumberInput || vehicleNumberInput.trim().length < 6) &&
  //     vehicleVinCodeInput &&
  //     vehicleVinCodeInput.trim().length >= 17
  //   ) {
  //     setLoadingLoader(true);
  //     vehicleVinCodeInput = vehicleVinCodeInput.trim();

  //     getVehicleByVIN(vehicleVinCodeInput)
  //       .then((vehicle) => {
  //         if (vehicle && vehicle.vinCode) {
  //           setLoadingLoader(false);
  //           form.setFieldsValue({
  //             vehicleNumber: vehicle?.plateNumber,
  //             vehicleCountry: defaultCountryId,
  //             vehicleType: vehicle?.category,
  //           });
  //           getTimeline(vehicle.vinCode).then((response) => {
  //             setLoadingLoader(false);
  //             setTimelines(response);
  //           });

  //           let personalCodeInput = form.getFieldValue(['personalCode']);

  //           if (personalCodeInput && personalCodeInput.trim().length === 13) {
  //             setLoadingLoader(false);
  //             personalCodeInput = personalCodeInput.trim();
  //             getCrossing(vehicle.vinCode, personalCodeInput).then(
  //               (response) => {
  //                 setCrossings(response);
  //               },
  //             );
  //           } else {
  //             setLoadingLoader(false);
  //             setCrossings([]);
  //           }
  //         } else {
  //           setLoadingLoader(false);
  //           notification.warning({
  //             message: title,
  //             description: 'Nu a fost gasit vehicul.',
  //           });
  //         }
  //       })
  //       .catch(() => {
  //         setLoadingLoader(false);
  //         notification.error({
  //           message: title,
  //           description: 'Eroare la căutarea vehiculului.',
  //         });
  //       });
  //   } else {
  //     setTimelines([]);
  //     setCrossings([]);
  //   }
  // }, [form, defaultCountryId]);

  // TODO NU STERGE METODA DE MAI JOS (cind vom implementa mconnect-asp-transport vom dizabla metoda)
  // const handleSearchTrail1ByNumber = useCallback(() => {
  //   let trail1NumberInput = form.getFieldValue(['trail1_number']);
  //   const title = 'Căutare remorca 1';
  //   if (trail1NumberInput && trail1NumberInput.trim().length >= 6) {
  //     setLoadingLoader(true);
  //     trail1NumberInput = trail1NumberInput.trim();
  //     getVehicleByPlateNumber(trail1NumberInput)
  //       .then((vehicle) => {
  //         if (vehicle && vehicle.plateNumber) {
  //           setLoadingLoader(false);
  //           form.setFieldsValue({
  //             trail1_vin: vehicle?.vinCode,
  //             trail1_country: defaultCountryId,
  //           });
  //         } else {
  //           setLoadingLoader(false);
  //           notification.warning({
  //             message: title,
  //             description: 'Nu au fost găsite detalii remorca 1.',
  //           });
  //         }
  //       })
  //       .catch(() => {
  //         setLoadingLoader(false);
  //         notification.error({
  //           message: title,
  //           description: 'Eroare la căutarea remorcii.',
  //         });
  //       });
  //   }
  // }, [form, defaultCountryId]);

  // TODO NU STERGE METODA DE MAI JOS (cind vom implementa mconnect-asp-transport vom dizabla metoda)
  // const handleSearchTrail1ByVIN = useCallback(() => {
  //   let trail1VinInput = form.getFieldValue('trail1_vin');
  //   const title = 'Căutare remorca 1';

  //   if (trail1VinInput && trail1VinInput.trim().length >= 17) {
  //     setLoadingLoader(true);
  //     trail1VinInput = trail1VinInput.trim();

  //     getVehicleByVIN(trail1VinInput)
  //       .then((vehicle) => {
  //         if (vehicle && vehicle.vinCode) {
  //           setLoadingLoader(false);
  //           form.setFieldsValue({
  //             trail1_number: vehicle?.plateNumber,
  //             trail1_country: defaultCountryId,
  //           });
  //         } else {
  //           setLoadingLoader(false);
  //           notification.warning({
  //             message: title,
  //             description: 'Nu au fost găsite detalii remorca 1.',
  //           });
  //         }
  //       })
  //       .catch(() => {
  //         setLoadingLoader(false);
  //         notification.error({
  //           message: title,
  //           description: 'Eroare la căutarea remorcii.',
  //         });
  //       });
  //   }
  // }, [form, defaultCountryId]);

  // TODO NU STERGE METODA DE MAI JOS (cind vom implementa mconnect-asp-transport vom dizabla metoda)
  // const handleSearchTrail2ByNumber = useCallback(() => {
  //   let trail2PlateNumberInput = form.getFieldValue(['trail2_number']);
  //   const title = 'Căutare remorca 2';

  //   if (trail2PlateNumberInput && trail2PlateNumberInput.trim().length >= 6) {
  //     setLoadingLoader(true);
  //     trail2PlateNumberInput = trail2PlateNumberInput.trim();

  //     getVehicleByPlateNumber(trail2PlateNumberInput)
  //       .then((vehicle) => {
  //         if (vehicle && vehicle.plateNumber) {
  //           setLoadingLoader(false);
  //           form.setFieldsValue({
  //             trail2_vin: vehicle?.vinCode,
  //             trail2_country: defaultCountryId,
  //           });
  //         } else {
  //           setLoadingLoader(false);
  //           notification.warning({
  //             message: title,
  //             description: 'Nu au fost găsite detalii remorca 2.',
  //           });
  //         }
  //       })
  //       .catch(() => {
  //         setLoadingLoader(false);
  //         notification.error({
  //           message: title,
  //           description: 'Eroare la căutarea remorcii.',
  //         });
  //       });
  //   }
  // }, [form, defaultCountryId]);

  // TODO NU STERGE METODA DE MAI JOS (cind vom implementa mconnect-asp-transport vom dizabla metoda)
  // const handleSearchTrail2ByVIN = useCallback(() => {
  //   let trail2VinCodeInput = form.getFieldValue(['trail2_vin']);
  //   const title = 'Căutare remorca 2';
  //   if (trail2VinCodeInput && trail2VinCodeInput.trim().length >= 17) {
  //     trail2VinCodeInput = trail2VinCodeInput.trim();
  //     setLoadingLoader(true);
  //     getVehicleByVIN(trail2VinCodeInput)
  //       .then((vehicle) => {
  //         if (vehicle && vehicle.vinCode) {
  //           setLoadingLoader(false);
  //           form.setFieldsValue({
  //             trail2_number: vehicle?.plateNumber,
  //             trail2_country: defaultCountryId,
  //           });
  //         } else {
  //           setLoadingLoader(false);
  //           notification.warning({
  //             message: title,
  //             description: 'Nu au fost găsite detalii remorca 2.',
  //           });
  //         }
  //       })
  //       .catch(() => {
  //         setLoadingLoader(false);
  //         notification.error({
  //           message: title,
  //           description: 'Eroare la căutarea remorcii.',
  //         });
  //       });
  //   }
  // }, [form, defaultCountryId]);
  const handleSetIsVehicleOwner = useCallback(() => {
    if (form.getFieldsValue(['ownerPersonalCode'])) {
      form.resetFields(['ownerPersonalCode']);
    }
    if (form.getFieldsValue(['ownerFullName'])) {
      form.resetFields(['ownerFullName']);
    }
    if (form.getFieldsValue(['ownerCountry'])) {
      form.resetFields(['ownerCountry']);
    }
    if (form.getFieldsValue(['ownerType'])) {
      form.resetFields(['ownerType']);
    }
  }, [form]);

  const getTransactionData = useCallback(
    (skipValidation = false) => {
      if (
        !skipValidation &&
        !validate(
          form,
          transaction?.isFromPF,
          isCargo,
          hasGoods,
          hasScale,
          physicalCarrier,
          requiredTransporter,
          transaction.stoppedAt,
          transaction.continuedAt,
        )
      ) {
        return {};
      }

      let transactionDTO = {
        ...transaction,
        isPaid: false,
        persons: form?.getFieldValue('persons') || 0,
      };

      if (
        !skipValidation &&
        transactionDTO?.consemne?.length > 0 &&
        transactionDTO?.instructed !== 'SUCCESS' &&
        transactionDTO?.message === null &&
        consemnResponse === null
      ) {
        notification.error({
          message: 'Confirmați vizualizarea consemnelor',
        });
        return {};
      }
      transactionDTO = {
        ...transactionDTO,
        personList: [],
        vehicles: [],
      };

      if (form.getFieldValue('personalCode')) {
        const licenseDate = form?.getFieldValue('drivingLicenceDate');
        transactionDTO.personList.push({
          personType: form.getFieldValue('personType')
            ? 'REZIDENT'
            : 'NEREZIDENT',
          personalCode:
            form?.getFieldValue('personalCode')?.toUpperCase() || '',
          fullName: form?.getFieldValue('fullName')?.toUpperCase() || '',
          countryId: form.getFieldValue('personCountry'),
          isOwner: isVehicleOwner,
          isPhysicalPerson: true,
          isCrossing: true,
          drivingLicence: form?.getFieldValue('drivingLicence'),
          drivingLicenceDate: licenseDate
            ? new Date(licenseDate.format('YYYY/MM/DD'))
            : null,
          drivingLicenceCountryId: form?.getFieldValue(
            'drivingLicenceCountryId',
          ),
          isDiplomat: form?.getFieldValue('isDiplomat'),
        });
        transactionDTO.personPersonalCode = form.getFieldValue('personalCode');
      }

      if (!isVehicleOwner) {
        transactionDTO.personList.push({
          personType: form.getFieldValue('ownerType')
            ? 'REZIDENT'
            : 'NEREZIDENT',
          personalCode: form?.getFieldValue('ownerPersonalCode')?.toUpperCase(),
          fullName: form?.getFieldValue('ownerFullName')?.toUpperCase(),
          countryId: form.getFieldValue('ownerCountry'),
          isOwner: true,
        });
      }

      transactionDTO.loadCountryId = form.getFieldValue('loadCountryId');
      transactionDTO.unloadCountryId = form.getFieldValue('unloadCountryId');
      transactionDTO.customsRegimeId = form.getFieldValue('customsRegimeId');
      transactionDTO.hasGoods = form.getFieldValue('hasGoods');
      transactionDTO.vinCode = form
        .getFieldValue('vehicleVinCode')
        ?.toUpperCase();
      transactionDTO.transportBorderType = form.getFieldValue(
        'transportBorderType',
      );
      transactionDTO.transportBorderMode = form.getFieldValue(
        'transportBorderMode',
      );

      transactionDTO.inspectorId = user.id;
      if (!transaction?.id) {
        transactionDTO.postId = user.postId;
        transactionDTO.direction = form?.getFieldValue('directions');
      }

      if (form.getFieldValue('idno')) {
        transactionDTO.personList.push({
          personType: form.getFieldValue('typeCompany')
            ? 'REZIDENT'
            : 'NEREZIDENT',
          personalCode: form?.getFieldValue('idno')?.toUpperCase(),
          fullName: form?.getFieldValue('nameCompany')?.toUpperCase(),
          countryId: form.getFieldValue('companyCountry'),
          transporterIsPhysicalPerson: physicalCarrier,
          isPhysicalPerson: false,
          isCrossing: true,
          isAeo,
        });
      }

      if (
        form.getFieldValue('vehicleNumber') ||
        form.getFieldValue('vehicleVinCode')
      ) {
        transactionDTO.vehicles.push({
          category: form?.getFieldValue('vehicleType'),
          vehicleTypeId: form?.getFieldValue('typeOfVehicle'),
          countryId: form?.getFieldValue('vehicleCountry'),
          plateNumber: form?.getFieldValue('vehicleNumber')?.toUpperCase(),
          vinCode: form?.getFieldValue('vehicleVinCode')?.toUpperCase(),
          mark: form?.getFieldValue('mark')?.toUpperCase(),
          model: form?.getFieldValue('model')?.toUpperCase(),
          color: form?.getFieldValue('color')?.toUpperCase(),
          hasDangerousGoods: form.getFieldValue('hasGoods')
            ? form.getFieldValue('hasDangerousGoods')
            : false,
          hasPerishable: form.getFieldValue('hasGoods')
            ? form.getFieldValue('hasPerishable')
            : false,
          withCargo: form.getFieldValue('hasGoods'),
          isTrailer: false,
        });
        const category = form?.getFieldValue('vehicleType');
        transactionDTO.category = category;
        transactionDTO.needRegistration =
          vehicleCategories?.content?.find((el) => el.code === category)
            ?.needRegistration || false;
        transactionDTO.plateNumber = form
          ?.getFieldValue('vehicleNumber')
          ?.toUpperCase();
        transactionDTO.vinCode = form
          ?.getFieldValue('vehicleVinCode')
          ?.toUpperCase();
      }
      if (hasGoods || transactionDTO.hasGoods) {
        transactionDTO.scale = {
          transactionId: transaction?.id,
          ...(hasScale || transactionDTO.hasScale
            ? {
                vehicleWeight: convertTonesToKilos(
                  form?.getFieldValue('vehicleWeight'),
                ),
                goodsWeight: convertTonesToKilos(
                  form?.getFieldValue('goodsWeight'),
                ),
                totalWeight: convertTonesToKilos(
                  form?.getFieldValue('totalWeight'),
                ),
                axisCount: form?.getFieldValue('axisCount'),
                axisDistance: form?.getFieldValue('axisDistance'),
                distance: form?.getFieldValue('distance'),
              }
            : null),
          hasContainer: false,
          isGroupage: form?.getFieldValue('isGroupage'),
          agentIdno: form?.getFieldValue('agentIdno')?.toUpperCase(),
          agentName: form?.getFieldValue('agentName')?.toUpperCase(),
          agentCountryId: form?.getFieldValue('agentCountry'),
          agentType: form?.getFieldValue('agentType')
            ? 'REZIDENT'
            : 'NEREZIDENT',
          goodsDocuments,
        };
      }
      if (
        form.getFieldValue('trail1_number') ||
        form.getFieldValue('trail1_vin')
      ) {
        transactionDTO.vehicles.push({
          countryId: form?.getFieldValue('trail1_country'),
          plateNumber: form?.getFieldValue('trail1_number')?.toUpperCase(),
          vinCode: form?.getFieldValue('trail1_vin')?.toUpperCase(),
          // bodyNumber: form?.getFieldValue('trail1_body_number'),
          // chassisNumber: form?.getFieldValue('trail1_chassis_number'),
          trailerTypeId: form?.getFieldValue('trailerTypeId1'),
          container1PlateNumber: form?.getFieldValue('container1PlateNumber'),
          container2PlateNumber: form?.getFieldValue('container2PlateNumber'),
          container3PlateNumber: form?.getFieldValue('container3PlateNumber'),
          isTrailer: true,
        });
      }

      if (
        form.getFieldValue('trail2_number') ||
        form.getFieldValue('trail2_vin')
      ) {
        transactionDTO.vehicles.push({
          countryId: form?.getFieldValue('trail2_country'),
          plateNumber: form?.getFieldValue('trail2_number')?.toUpperCase(),
          vinCode: form?.getFieldValue('trail2_vin')?.toUpperCase(),
          // bodyNumber: form?.getFieldValue('trail2_body_number'),
          // chassisNumber: form?.getFieldValue('trail2_chassis_number'),
          trailerTypeId: form?.getFieldValue('trailerTypeId2'),
          isTrailer: true,
        });
      }

      if (consemneList) {
        transactionDTO = {
          ...transactionDTO,
          consemne: [],
        };

        transactionDTO.consemne.push(...consemneList);
      }

      return transactionDTO;
    },
    [
      form,
      user,
      isAeo,
      isCargo,
      hasGoods,
      hasScale,
      transaction,
      consemneList,
      isVehicleOwner,
      consemnResponse,
      physicalCarrier,
      vehicleCategories,
      requiredTransporter,
      goodsDocuments,
    ],
  );

  const getCorrections = useCallback(() => {
    if (
      !form.getFieldValue('correctionReason') ||
      form.getFieldValue('correctionReason').length < 3
    ) {
      notification.error({
        message: 'Motivul, care a stat la baza corecțiilor este obligatoriu',
      });
      return {};
    }
    const corrections = {};
    corrections.id = transaction.id;
    corrections.correctionLetterNumber = form.getFieldValue(
      'correctionLetterNumber',
    );
    corrections.correctionLetterDate = form.getFieldValue(
      'correctionLetterDate',
    );
    corrections.correctionReason = form.getFieldValue('correctionReason');
    corrections.personalCode = form.getFieldValue('personalCode');
    corrections.direction = form.getFieldValue('directions');
    corrections.personName = form.getFieldValue('fullName') || '';
    corrections.personCountryId = form.getFieldValue('personCountry');
    corrections.companyIDNO = form.getFieldValue('idno');
    corrections.companyCountryId = form.getFieldValue('companyCountry');
    corrections.vehicleNumber = form.getFieldValue('vehicleNumber');
    corrections.vehicleVin = form.getFieldValue('vehicleVinCode');
    corrections.vehicleCountryId = form.getFieldValue('vehicleCountry');
    corrections.trailerNumber = form.getFieldValue('trail1_number');
    corrections.trailerVin = form?.getFieldValue('trail1_vin');
    return corrections;
  }, [form, transaction.id]);

  const handleChangeOwnerCountry = (value) => {
    if (value !== defaultCountryId || value === null)
      form.setFieldsValue({ ownerType: false });
    else form.setFieldsValue({ ownerType: true });
  };

  const handleChangePersonCountry = (value) => {
    if (value !== defaultCountryId || value === null)
      form.setFieldsValue({ personType: false });
    else form.setFieldsValue({ personType: true });
  };

  const handleChangeCompanyCountry = (value) => {
    if (value !== defaultCountryId || value === null)
      form.setFieldsValue({ typeCompany: false });
    else form.setFieldsValue({ typeCompany: true });
  };

  const handleChangeAgentCountry = (value) => {
    if (value !== defaultCountryId || value === null)
      form.setFieldsValue({ agentType: false });
    else form.setFieldsValue({ agentType: true });
  };

  const handleCorrectTransaction = useCallback(() => {
    const data = getCorrections();

    if (Object.entries(data).length === 0) {
      setLoadingLoader(false);
      return;
    }

    correctTransaction(data)
      .then((res) => {
        if (res.id) {
          notification.success({
            message: 'Datele au fost salvate.',
            duration: 3,
          });
          setEditMode(false);
        } else {
          notification.error({
            message: 'Datele nu au putut fi salvate',
            duration: 3,
          });
        }
      })
      .catch(() => {
        notification.error({
          message: 'Datele nu au putut fi salvate. 2',
          duration: 3,
        });
      })
      .finally(() => {
        reload();
        setLoadingLoader(false);
        form.setFieldsValue({
          correctionReason: null,
        });
      });
  }, [reload, getCorrections, setEditMode, form]);

  const handleMoveNext = () => {
    if (transaction?.id && !editMode) {
      moveNext();
    }
  };
  const refreshPage = () => {
    window.location.reload();
  };

  const handleSaveTransaction = useCallback(async () => {
    return new Promise((resolve, reject) => {
      if (editMode && transaction.id) {
        handleCorrectTransaction();
        resolve();
        refreshPage();
        return;
      }

      const transactionDTO = getTransactionData();
      if (Object.entries(transactionDTO).length === 0) {
        setLoadingLoader(false);
        reject();
        return;
      }

      if (!transactionDTO.id) {
        setLoadingLoader(true);
      }

      saveTransaction(transactionDTO)
        .then((res) => {
          if (!transactionDTO.id) {
            history.push(`/physical-person-entry/${res.id}/#GENERAL_DATA`);
          }
          setEditMode(false);
          resolve();
          // setDataConfirmed(res.isGeneralDataConfirmed);
        })
        .catch(() => {
          notification.error({
            message: t('actions.confirmErr'),
            duration: 3,
          });
          reject();
        })
        .finally(() => {
          reload();
          setLoadingLoader(false);
        });
    });
  }, [
    t,
    history,
    getTransactionData,
    handleCorrectTransaction,
    transaction.id,
    reload,
    setEditMode,
    editMode,
  ]);

  const handleSaveAndSendTransaction = useCallback(() => {
    setLoadingLoader(true);
    const transactionDTO = getTransactionData();
    if (Object.entries(transactionDTO).length === 0) {
      return;
    }

    saveAndSendTransaction(transactionDTO)
      .then((res) => {
        setSeedSent(true);
        notification.success({
          message: 'Datele au fost trimise.',
        });
        if (!transactionDTO.id) {
          history.push(`/physical-person-entry/${res.id}`);
        }
      })
      .catch(() => {
        notification.error({
          message: 'Datele nu au putut fi trimise.',
        });
      })
      .finally(() => setLoadingLoader(false));
  }, [history, getTransactionData]);

  const handleChangePersonType = useCallback(() => {
    form.setFieldsValue({
      personCountry: form.getFieldValue('personType') ? defaultCountryId : null,
    });
  }, [form, defaultCountryId]);

  const handleChangeAgentType = useCallback(() => {
    form.setFieldsValue({
      agentCountry: form.getFieldValue('agentType') ? defaultCountryId : null,
    });
  }, [form, defaultCountryId]);

  const handleSaveAndSendModalOk = () => {
    setIsSaveAndSendModalVisible(false);
    handleSaveAndSendTransaction();
  };

  const handleSaveAndSendModalCancel = () => {
    setIsSaveAndSendModalVisible(false);
  };

  const handleSetPersonFields = (person) => {
    if (
      !!person.drivingLicence ||
      !!person.drivingLicenceCountryId ||
      !!person.drivingLicenceDate
    ) {
      setHasDrivingLicence(true);
    } else {
      setHasDrivingLicence(false);
    }
    form.setFieldsValue({
      personalCode: person.personalCode,
      drivingLicenceDate: person.drivingLicenceDate
        ? dayjs(person.drivingLicenceDate)
        : null,
      personType: person.personType === 'REZIDENT',
      fullName: person.fullName,
      personCountry: person.countryId ? Number(person.countryId) : null,
      drivingLicence: person.drivingLicence,
      drivingLicenceCountryId: person.drivingLicenceCountryId
        ? Number(person.drivingLicenceCountryId)
        : null,
    });
    handleSearchPersonByIDNP();
  };

  const handleSetOwnerFields = (person) => {
    form.setFieldsValue({
      ownerPersonalCode: person.personalCode,
      ownerType: person.personType === 'REZIDENT',
      ownerFullName: person.fullName,
      ownerCountry: person.countryId ? Number(person.countryId) : null,
    });
    handleSearchOwnerByIDNP();
  };

  const handleSetAgentFields = (person) => {
    form.setFieldsValue({
      agentIdno: person.personalCode,
      agentType: person.personType === 'REZIDENT',
      agentName: person.fullName,
      agentCountry: person.countryId ? Number(person.countryId) : null,
    });
    handleSearchAgentOrganizationByIDNO();
  };

  const handleSetCompanyFields = (company) => {
    form.setFieldsValue({
      typeCompany: company.personType === 'REZIDENT',
      idno: company.personalCode,
      nameCompany: company.fullName,
      companyCountry: company.countryId ? Number(company.countryId) : null,
    });
    setIsAeo(company.isAeo);
  };

  const handleSetVehicleFields = useCallback(
    (vehicle) => {
      onVehicleTypeChange(vehicle.category);

      form.setFieldsValue({
        vehicleVinCode: vehicle.vinCode,
        typeOfVehicle: vehicle.vehicleTypeId
          ? Number(vehicle.vehicleTypeId)
          : null,
        vehicleType: vehicle.category,
        vehicleCountry: vehicle.countryId ? Number(vehicle.countryId) : null,
        vehicleNumber: vehicle.plateNumber,
        mark: vehicle.mark,
        model: vehicle.model,
        color: vehicle.color,
      });
    },
    [onVehicleTypeChange, form],
  );

  const handleCheckDaysInRm = useCallback(() => {
    const licenceCountry = form.getFieldValue('drivingLicenceCountryId');
    const vehicleCountry = form.getFieldValue('vehicleCountry');
    if (transaction?.id && (vehicleCountry || licenceCountry)) {
      const params = {
        id: transaction?.id,
        licenceCountry: licenceCountry || -1,
        vehicleCountry: vehicleCountry || -2,
      };
      setDaysInRMLoader(true);
      checkDaysInRM(params)
        .then((res) => {
          const { daysInRM, daysInRMFromLastEntry, isOverstaying } = res;
          setDaysInRMObj({
            daysInRM: Number(daysInRM),
            daysInRMFromLastEntry: Number(daysInRMFromLastEntry),
            isOverstaying: isOverstaying === 'true',
          });
        })
        .catch()
        .finally(() => setDaysInRMLoader(false));
    }
  }, [form, transaction?.id]);

  useEffect(() => {
    if (
      transaction?.id &&
      !daysInRMObj?.daysInRM &&
      !daysInRMObj?.daysInRMFromLastEntry
    ) {
      handleCheckDaysInRm();
    }
    // eslint-disable-next-line
  }, [
    transaction?.id,
    daysInRMObj?.daysInRM,
    daysInRMObj?.daysInRMFromLastEntry,
  ]);

  useEffect(() => {
    if (transaction?.id) {
      getLastVicosCroppedByPlaterNumbe(
        transaction?.id,
        transaction?.plateNumber,
      )
        .then((res) => {
          if (res) setImage(res);
        })
        .catch(() => setImage(null));
      handleCheckDaysInRm();
    }
  }, [transaction?.id, handleCheckDaysInRm, transaction?.plateNumber]);

  const handleChangePhysicalCarrier = () => {
    setPhysicalCarrier((prev) => !prev);
    form.setFieldsValue({
      idno: null,
      typeCompany: false,
      companyCountry: null,
      nameCompany: null,
    });
  };

  const handleTransporterChange = () => {
    if (form.getFieldValue('nameCompany') || form.getFieldValue('idno')) {
      setRequiredTransporter(true);
    } else {
      setRequiredTransporter(false);
    }
  };

  const handleChangeOwner = () => {
    if (
      form.getFieldValue('ownerPersonalCode') ||
      form.getFieldValue('ownerFullName')
    ) {
      setRequiredOwner(true);
    } else {
      setRequiredOwner(false);
    }
  };

  const handleHasGoodsChange = (checked) => {
    setHasGoods(checked);
    if (!checked) {
      form.setFieldsValue({
        hadDangerousGoods: null,
        unloadCountryId: null,
        loadCountryId: null,
        agentIdno: null,
        agentName: null,
        agentCountry: null,
        agentType: null,
        vehicleWeight: null,
        goodsWeight: null,
        totalWeight: null,
        axisCount: null,
        axisDistance: null,
        distance: null,
        isGroupage: null,
      });
      setHasScale(false);
    } else {
      setHasScale(true);
      form.setFieldsValue({
        hadDangerousGoods: transaction.hadDangerousGoods,
        unloadCountryId: transaction.unloadCountryId,
        loadCountryId: transaction.loadCountryId,
        agentIdno: transaction.agentIdno,
        agentName: transaction.agentName,
        agentCountry: transaction.agentCountry,
        agentType: transaction.agentType,
        vehicleWeight: transaction.vehicleWeight,
        goodsWeight: transaction.goodsWeight,
        totalWeight: transaction.totalWeight,
        axisCount: transaction.axisCount,
        axisDistance: transaction.axisDistance,
        distance: transaction.distance,
        isGroupage: transaction.isGroupage,
      });
    }
  };

  return (
    <>
      <div className={`loaderOverlay ${loadingLoader ? 'active' : ''}`}>
        <AppLoader />
      </div>

      <div className={`editmode__text ${editMode ? 'active' : ''}`}>
        Regimul de corectare activ
      </div>

      {transaction?.id &&
      hasPermission(permissions, 'CORRECT_TRANSACTION') &&
      transaction?.status !== TransactionStatus.PENDING ? (
        <EditTransaction
          setEditMode={setEditMode}
          editMode={editMode}
          disabled={transaction?.status === TransactionStatus.PENDING}
        />
      ) : null}

      {transaction?.id &&
      hasPermission(permissions, 'CREATE_TRANSACTION') &&
      !editMode ? (
        <>
          <CopyTransactionModal
            setLoadingLoader={setLoadingLoader}
            reloadTimelines={reloadTimelines}
            getTransactionData={getTransactionData}
            directions={directions}
            isGeneralData={transaction?.status === TransactionStatus.PENDING}
          />
        </>
      ) : null}
      <FormContextProvider
        form={form}
        initialValues={{
          ...transaction,
          hasGoods,
          hasScale,
        }}
      >
        <Form
          form={form}
          layout="vertical"
          className={`generaldata__content ${transaction?.id ? '' : 'new'}`}
        >
          <Row gutter={15}>
            <Col className="splitter" span={12}>
              <div style={{ display: 'flex', gap: '20px', marginTop: '10px' }}>
                {isAeo && (
                  <Image
                    preview={false}
                    src={aeoLogo}
                    width={150}
                    height={70}
                    style={{ borderRadius: '5px', marginTop: '5px' }}
                  />
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '20px',
                }}
              >
                <DriverImage idnp={personIdnp} />
                {transaction?.id && daysInRMLoader ? (
                  <Spin
                    style={{
                      fontSize: 18,
                      margintBottom: '10px',
                    }}
                    tip=""
                  />
                ) : (
                  <div style={{ fontWeight: '600' }}>
                    <div
                      style={{
                        color: daysInRMObj.isOverstaying
                          ? '#e6484b'
                          : '#24a052',
                      }}
                    >{`Zile în RM (într-un an):  ${
                      daysInRMObj.daysInRM || '0'
                    }`}</div>
                    <div
                      style={{
                        color:
                          daysInRMObj.daysInRMFromLastEntry > 180
                            ? '#e6484b'
                            : '#24a052',
                      }}
                    >{`Zile în RM (de la ultima intrare):  ${
                      daysInRMObj.daysInRMFromLastEntry || '0'
                    }`}</div>
                  </div>
                )}
              </div>

              <Divider
                plain
                style={{
                  paddingRight: '20px',
                }}
              >
                Persoane
              </Divider>
              <Row gutter={15}>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={12}
                  xxl={6}
                  style={{ float: 'right' }}
                >
                  <Form.Item
                    name="directions"
                    label="Direcția"
                    initialValue={
                      user.direction !== null && user.function === 'INSPECTOR'
                        ? user.direction
                        : transaction.direction
                    }
                    rules={[
                      {
                        required: true,
                        message: 'Câmpul este obligatoriu',
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      disabled={
                        (transaction.id && !editMode) ||
                        (user.direction !== null &&
                          user.function === 'INSPECTOR') ||
                        (readOnly && !editMode)
                      }
                      style={{ width: '100%' }}
                    >
                      {directions.map((option) => (
                        <Select.Option key={option.id} value={option.id}>
                          {`${option.name}`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {(user?.function === 'OFFICE_HEAD' ||
                  user?.function === 'CENTRAL') &&
                transaction?.postId ? (
                  <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
                    <Form.Item
                      autoComplete="none"
                      name="postDescription"
                      label="Post vamal"
                    >
                      <Input
                        autoComplete="off"
                        disabled
                        style={{
                          textTransform: 'uppercase',
                          fontWeight: '600',
                        }}
                      />
                    </Form.Item>
                  </Col>
                ) : null}
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={6}>
                  <Form.Item
                    label="Tip operațiune"
                    name="customsRegimeId"
                    rules={[
                      {
                        required: true,
                        message: 'Câmpul este obligatoriu',
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      showSearch
                      loading={loading}
                      style={{ width: '100%' }}
                      disabled={readOnly || editMode}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) !== -1
                      }
                    >
                      {customsRegimes?.content
                        ?.filter((el) => !el.deleted)
                        ?.map((option) => (
                          <Select.Option key={option.id} value={option.id}>
                            {`${option.name}`}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                {transaction?.id ? (
                  <Col xl={12} xxl={6}>
                    <ImpoundedCarCheckbox
                      transactionId={transaction.id}
                      disabled={readOnly}
                      wasStopped={transaction.stoppedAt != null}
                      checked={
                        transaction.stoppedAt != null &&
                        transaction.continuedAt == null
                      }
                      reload={reload}
                    />
                  </Col>
                ) : null}
                <Col xl={12} xxl={6}>
                  <Form.Item label="" name="isDiplomat" valuePropName="checked">
                    <Checkbox
                      className={`${
                        user?.function === 'OFFICE_HEAD' ||
                        user?.function === 'CENTRAL'
                          ? 'general__rezident-first--head'
                          : 'general__rezident-first'
                      }`}
                      disabled={readOnly || editMode}
                    >
                      Diplomat
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={15}>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={6}>
                  <Form.Item
                    label="Nr. Pașaport"
                    name="personalCode"
                    rules={[
                      {
                        required: true,
                        message: 'Câmpul este obligatoriu',
                      },
                    ]}
                  >
                    <InputHelper
                      className="pasaport__number"
                      onSearch={getPersonsReference}
                      getReference={getPerson}
                      onSelect={handleSetPersonFields}
                      onDebounceChange={() => {
                        reloadTimelines();
                        handleSearchPersonByIDNP();
                      }}
                      disabled={
                        (transaction?.isFromPF && !editMode) ||
                        (readOnly && !editMode)
                      }
                      maxLength={13}
                      style={{
                        textTransform: 'uppercase',
                        fontWeight: '600',
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={6}>
                  <Form.Item
                    autoComplete="none"
                    label="Nume prenume (Șofer)"
                    name="fullName"
                    rules={[
                      {
                        required: true,
                        message: 'Câmpul este obligatoriu',
                      },
                    ]}
                  >
                    <Input
                      autoComplete="off"
                      onChange={(e) => {
                        form.setFieldsValue({
                          fullName: maxLengthValidator(
                            nameValidator(e.target.value, 255),
                            255,
                          ),
                        });
                      }}
                      disabled={
                        (transaction?.isFromPF && !editMode) ||
                        (readOnly && !editMode)
                      }
                      style={{
                        textTransform: 'uppercase',
                        fontWeight: '600',
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={6}>
                  <Form.Item
                    label="Țară"
                    name="personCountry"
                    rules={[
                      {
                        required: !transaction?.isFromPF,
                        message: 'Câmpul este obligatoriu',
                      },
                    ]}
                  >
                    <CountryDropDown
                      onChange={(value) => handleChangePersonCountry(value)}
                      disabled={
                        (readOnly && !editMode) ||
                        (transaction?.isFromPF && !editMode)
                      }
                      loading={loading}
                      content={content || []}
                      id="personCountryId"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={6}>
                  <Form.Item label="" name="personType" valuePropName="checked">
                    <Checkbox
                      className="general__rezident-first"
                      onChange={handleChangePersonType}
                      disabled={
                        (readOnly && !editMode) ||
                        (transaction?.isFromPF && !editMode)
                      }
                    >
                      Rezident
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={7}>
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <label>Permis de conducere</label>

                    <Switch
                      disabled={readOnly}
                      checked={hasDrivingLicence}
                      onChange={() => setHasDrivingLicence((prev) => !prev)}
                      style={{ marginBottom: '10px' }}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={7}>
                  {!editMode && (
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <label>Proprietar</label>
                      <Form.Item name="isVehicleOwner">
                        <Switch
                          style={{ transform: 'translateY(-5px)' }}
                          disabled={readOnly}
                          checked={!isVehicleOwner}
                          onChange={() => {
                            setIsVehicleOwner(!isVehicleOwner);
                            handleSetIsVehicleOwner();
                          }}
                        />
                      </Form.Item>
                    </div>
                  )}
                </Col>
                <Row
                  hidden={!hasDrivingLicence}
                  style={{ width: '100%', maxWidth: '100%' }}
                  gutter={15}
                >
                  <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
                    <Form.Item label="Serie și număr" name="drivingLicence">
                      <Input
                        autoComplete="off"
                        onChange={(e) => {
                          form.setFieldsValue({
                            drivingLicence: maxLengthValidator(
                              e.target.value,
                              20,
                            ),
                          });
                        }}
                        disabled={readOnly || editMode}
                        style={{
                          textTransform: 'uppercase',
                          fontWeight: '600',
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
                    <Form.Item name="drivingLicenceDate" label="Data primirii">
                      <DatePicker
                        disabled={readOnly || editMode}
                        format="DD.MM.YYYY"
                        placeholder="Selectați data"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
                    <Form.Item
                      name="drivingLicenceCountryId"
                      label="Țara primirii"
                    >
                      <CountryDropDown
                        loading={loading}
                        content={content || []}
                        disabled={readOnly || editMode}
                        onChange={handleCheckDaysInRm}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Row>
              {!editMode && (
                <>
                  <Divider
                    plain
                    style={{ paddingRight: '20px' }}
                    hidden={isVehicleOwner}
                  >
                    Proprietar
                  </Divider>
                  <Row gutter={15} hidden={isVehicleOwner}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={8}>
                      <Form.Item
                        label="Nr. Pașaport"
                        name="ownerPersonalCode"
                        rules={[
                          {
                            required: requiredOwner,
                            message: 'Câmpul este obligatoriu',
                          },
                        ]}
                      >
                        <InputHelper
                          onSearch={getPersonsReference}
                          getReference={getPerson}
                          onSelect={handleSetOwnerFields}
                          onChange={(e) => {
                            handleChangeOwner();
                            if (e.target.value.length === 13) {
                              handleSearchOwnerByIDNP();
                            }
                          }}
                          disabled={readOnly}
                          maxLength={13}
                          style={{
                            textTransform: 'uppercase',
                            fontWeight: '600',
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
                      <Form.Item
                        label="Nume prenume"
                        name="ownerFullName"
                        rules={[
                          {
                            required: requiredOwner,
                            message: 'Câmpul este obligatoriu',
                          },
                        ]}
                      >
                        <Input
                          autoComplete="off"
                          onChange={(e) => {
                            handleChangeOwner();
                            form.setFieldsValue({
                              ownerFullName: maxLengthValidator(
                                nameValidator(e.target.value),
                                255,
                              ),
                            });
                          }}
                          disabled={readOnly}
                          style={{
                            textTransform: 'uppercase',
                            fontWeight: '600',
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
                      <Form.Item
                        label="Țară"
                        name="ownerCountry"
                        rules={[
                          {
                            required: requiredOwner,
                            message: 'Câmpul este obligatoriu',
                          },
                        ]}
                      >
                        <CountryDropDown
                          loading={loading}
                          content={content || []}
                          disabled={readOnly}
                          id="ownerCountryId"
                          onChange={(value) => handleChangeOwnerCountry(value)}
                        />
                      </Form.Item>
                    </Col>
                    <Col xl={6} xxl={3}>
                      <Form.Item
                        label=" "
                        name="ownerType"
                        valuePropName="checked"
                      >
                        <Checkbox
                          disabled={readOnly || editMode}
                          onChange={() => {
                            form.setFieldsValue({
                              ownerCountry: form.getFieldValue('ownerType')
                                ? defaultCountryId
                                : null,
                            });
                          }}
                        >
                          Rezident
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}

              <Divider plain style={{ paddingRight: '20px' }}>
                Transportator
              </Divider>
              <Col>
                <div
                  style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}
                >
                  <label>Transportator persoană fizică</label>

                  <Switch
                    checked={physicalCarrier}
                    disabled={readOnly}
                    onChange={handleChangePhysicalCarrier}
                  />
                </div>
              </Col>
              <Row gutter={15}>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={6}>
                  <Form.Item
                    label={physicalCarrier ? 'IDNP' : 'IDNO'}
                    name="idno"
                    rules={[
                      {
                        required: isCargo || requiredTransporter,
                        message: 'Câmpul este obligatoriu',
                      },
                    ]}
                  >
                    <InputHelper
                      className="pasaport__number"
                      onSearch={getPersonsReference}
                      getReference={getPerson}
                      onSelect={handleSetCompanyFields}
                      disabled={readOnly || editMode}
                      maxLength={20}
                      style={{
                        textTransform: 'uppercase',
                        fontWeight: '600',
                      }}
                      onDebounceChange={debouncedCheckAeoPresence}
                      debouceDelay={750}
                      onChange={(e) => {
                        handleTransporterChange();
                        if (!editMode && e.target.value.length === 13) {
                          handleSearchOrganizationByIDNO();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={6}>
                  <Form.Item
                    label="Denumire transportator"
                    name="nameCompany"
                    rules={[
                      {
                        required: requiredTransporter || isCargo,
                        message: 'Câmpul este obligatoriu',
                      },
                    ]}
                  >
                    <Input
                      autoComplete="off"
                      onChange={(e) => {
                        handleTransporterChange();
                        form.setFieldsValue({
                          nameCompany: maxLengthValidator(e.target.value, 255),
                        });
                      }}
                      disabled={readOnly || editMode}
                      style={{
                        textTransform: 'uppercase',
                        fontWeight: '600',
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={6}>
                  <Form.Item
                    label="Țară"
                    name="companyCountry"
                    rules={[
                      {
                        required: requiredTransporter || isCargo,
                        message: 'Câmpul este obligatoriu',
                      },
                    ]}
                  >
                    <CountryDropDown
                      loading={loading}
                      content={content || []}
                      id="companyCountryId"
                      disabled={readOnly || editMode}
                      onChange={(value) => handleChangeCompanyCountry(value)}
                    />
                  </Form.Item>
                </Col>
                <Col xl={6} xxl={3}>
                  <Form.Item
                    label=" "
                    name="typeCompany"
                    valuePropName="checked"
                  >
                    <Checkbox
                      disabled={readOnly || editMode}
                      onChange={() => {
                        form.setFieldsValue({
                          companyCountry: form.getFieldValue('typeCompany')
                            ? defaultCountryId
                            : null,
                        });
                      }}
                    >
                      Rezident
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={15}>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={6}>
                  <Form.Item label="Pasageri" name="persons">
                    <Input
                      autoComplete="off"
                      onChange={(e) => {
                        form.setFieldsValue({
                          persons: numberValidator(e.target.value, 3),
                        });
                      }}
                      disabled={transaction?.isFromPF || readOnly || editMode}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col style={{ paddingLeft: '20px' }} span={12}>
              <Divider plain>Vehicul și Remorci</Divider>
              {image?.image !== null && image !== null && (
                <>
                  <Image
                    src={`data:${image?.imageType};base64,${image?.image}`}
                    width={240}
                    height={140}
                    style={{ borderRadius: '5px' }}
                  />
                  <Checkbox
                    key="plateNumberConfirm"
                    checked={transaction?.plateNumberConfirmed}
                    style={{ marginLeft: '10px' }}
                    disabled
                  >
                    <span>Numărul auto corespunde imaginii</span>
                  </Checkbox>
                </>
              )}
              <Row gutter={15}>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={6}>
                  <Form.Item
                    label="Nr. auto"
                    name="vehicleNumber"
                    rules={[
                      {
                        required: true,
                        message: 'Câmpul este obligatoriu',
                      },
                    ]}
                  >
                    <InputHelper
                      onChange={(e) =>
                        form.setFieldsValue({
                          vehicleNumber: e.target.value?.toUpperCase(),
                        })
                      }
                      minSearchValue={2}
                      onSearch={getPlatesRef}
                      getReference={getVehicle}
                      onSelect={handleSetVehicleFields}
                      disabled={
                        (transaction?.isFromPF && !editMode) ||
                        (readOnly && !editMode)
                      }
                      maxLength={10}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={6}>
                  <Form.Item
                    label="Nr. Caroserie/VIN"
                    name="vehicleVinCode"
                    rules={[
                      {
                        required: true,
                        message: 'Campul este obligatoriu',
                      },
                      {
                        min: 17,
                        message: 'Minim 17 caractere',
                      },
                    ]}
                  >
                    <InputHelper
                      onChange={(e) => {
                        form.setFieldsValue({
                          vehicleVinCode: vinNumberValidator(e.target.value),
                        });
                      }}
                      onSearch={getVehiclesReference}
                      getReference={getVehicle}
                      onSelect={handleSetVehicleFields}
                      disabled={
                        (transaction?.isFromPF && !editMode) ||
                        (readOnly && !editMode)
                      }
                      maxLength={17}
                      style={{
                        textTransform: 'uppercase',
                        fontWeight: '600',
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={6}>
                  <Form.Item
                    label="Țara"
                    name="vehicleCountry"
                    rules={[
                      {
                        required: true,
                        message: 'Câmpul este obligatoriu',
                      },
                    ]}
                  >
                    <CountryDropDown
                      loading={loading}
                      content={content || []}
                      disabled={
                        (transaction?.isFromPF && !editMode) ||
                        (readOnly && !editMode)
                      }
                      onChange={handleCheckDaysInRm}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={6}>
                  <Form.Item
                    label="Categorie"
                    name="vehicleType"
                    rules={[
                      {
                        required: true,
                        message: 'Câmpul este obligatoriu',
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      showSearch
                      onChange={(value) => onVehicleTypeChange(value)}
                      disabled={transaction?.isFromPF || readOnly || editMode}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) !== -1
                      }
                    >
                      {allowedVehicleCategories?.map(
                        (option) =>
                          !option.deleted && (
                            <Select.Option key={option.id} value={option.code}>
                              {`${option.code} - ${option.name}`}
                            </Select.Option>
                          ),
                      )}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={15}>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={isCargo ? 12 : 24}
                  xxl={isCargo ? 6 : 12}
                >
                  <Form.Item label="Marca" name="mark">
                    <Input
                      onChange={(e) => {
                        form.setFieldsValue({
                          mark: maxLengthValidator(e.target.value, 25),
                        });
                      }}
                      disabled={transaction?.isFromPF || readOnly || editMode}
                      style={{
                        textTransform: 'uppercase',
                        fontWeight: '600',
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={6}>
                  <Form.Item label="Model" name="model">
                    <Input
                      onChange={(e) => {
                        form.setFieldsValue({
                          model: maxLengthValidator(e.target.value, 80),
                        });
                      }}
                      disabled={transaction?.isFromPF || readOnly || editMode}
                      style={{
                        textTransform: 'uppercase',
                        fontWeight: '600',
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={6}>
                  <Form.Item label="Culoarea" name="color">
                    <Input
                      onChange={(e) => {
                        form.setFieldsValue({
                          color: maxLengthValidator(e.target.value, 50),
                        });
                      }}
                      disabled={transaction?.isFromPF || readOnly || editMode}
                      style={{
                        textTransform: 'uppercase',
                        fontWeight: '600',
                      }}
                    />
                  </Form.Item>
                </Col>
                {isCargo ? (
                  <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={6}>
                    <Form.Item
                      label="Tip vehicul"
                      name="typeOfVehicle"
                      rules={[
                        {
                          required: true,
                          message: 'Câmpul este obligatoriu',
                        },
                      ]}
                    >
                      <Select
                        disabled={readOnly || editMode}
                        allowClear
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) !== -1
                        }
                      >
                        {vehicleTypes?.content.map(
                          (option) =>
                            !option.deleted && (
                              <Select.Option key={option.id} value={option.id}>
                                {`${option.name}`}
                              </Select.Option>
                            ),
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                ) : null}
              </Row>
              <Row gutter={15}>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={6}>
                  <Form.Item label="Nr. remorcă 1" name="trail1_number">
                    <Input
                      autoComplete="off"
                      onChange={(e) => {
                        form.setFieldsValue({
                          trail1_number: maxLengthValidator(e.target.value, 10),
                        });
                      }}
                      disabled={
                        readOnly ||
                        editMode ||
                        (transaction?.isFromPF && !editMode)
                      }
                      style={{
                        textTransform: 'uppercase',
                        fontWeight: '600',
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={6}>
                  <Form.Item label="Nr. Caroserie/VIN" name="trail1_vin">
                    <Input
                      autoComplete="off"
                      onChange={(e) => {
                        form.setFieldsValue({
                          trail1_vin: vinNumberValidator(e.target.value),
                        });
                      }}
                      disabled={
                        readOnly ||
                        editMode ||
                        (transaction?.isFromPF && !editMode)
                      }
                      maxLength={17}
                      style={{
                        textTransform: 'uppercase',
                        fontWeight: '600',
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={6}>
                  <Form.Item label="Tip remorcă" name="trailerTypeId1">
                    <Select
                      disabled={readOnly || editMode}
                      allowClear
                      showSearch
                      style={{ fontWeight: '600' }}
                      onChange={handleChangeTypeTrailer}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) !== -1
                      }
                    >
                      {trailerTypes?.content.map(
                        (option) =>
                          !option.deleted && (
                            <Select.Option key={option.id} value={option.id}>
                              {`${option.name}`}
                            </Select.Option>
                          ),
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={6}>
                  <Form.Item label="Țara" name="trail1_country">
                    <CountryDropDown
                      content={content || []}
                      loading={loading}
                      disabled={readOnly || editMode}
                    />
                  </Form.Item>
                </Col>
                <Col span={6} />
              </Row>
              <Row gutter={15}>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={6}>
                  <Form.Item label="Nr. remorcă 2" name="trail2_number">
                    <Input
                      autoComplete="off"
                      onChange={(e) => {
                        form.setFieldsValue({
                          trail2_number: maxLengthValidator(e.target.value, 10),
                        });
                      }}
                      disabled={transaction?.isFromPF || readOnly || editMode}
                      style={{
                        textTransform: 'uppercase',
                        fontWeight: '600',
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={6}>
                  <Form.Item label="Nr. Caroserie/VIN" name="trail2_vin">
                    <Input
                      autoComplete="off"
                      onChange={(e) => {
                        form.setFieldsValue({
                          trail2_vin: vinNumberValidator(e.target.value),
                        });
                      }}
                      disabled={transaction?.isFromPF || readOnly || editMode}
                      maxLength={17}
                      style={{
                        textTransform: 'uppercase',
                        fontWeight: '600',
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={6}>
                  <Form.Item label="Tip remorcă" name="trailerTypeId2">
                    <Select
                      disabled={readOnly || editMode}
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) !== -1
                      }
                    >
                      {trailerTypes?.content.map(
                        (option) =>
                          !option.deleted && (
                            <Select.Option key={option.id} value={option.id}>
                              {`${option.name}`}
                            </Select.Option>
                          ),
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={6}>
                  <Form.Item label="Țara" name="trail2_country">
                    <CountryDropDown
                      content={content || []}
                      loading={loading}
                      disabled={readOnly || editMode}
                    />
                  </Form.Item>
                </Col>
                <Col span={6} />
              </Row>

              <Row gutter={15} hidden={hideContainers}>
                <Col xl={12} xxl={6}>
                  <Form.Item
                    label="Nr. Container 1"
                    name="container1PlateNumber"
                  >
                    <Input
                      onChange={(e) => {
                        form.setFieldsValue({
                          container1PlateNumber: maxLengthValidator(
                            e.target.value,
                            11,
                          ),
                        });
                      }}
                      disabled={readOnly}
                      style={{
                        textTransform: 'uppercase',
                        fontWeight: '600',
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xl={12} xxl={6}>
                  <Form.Item
                    label="Nr. Container 2"
                    name="container2PlateNumber"
                  >
                    <Input
                      onChange={(e) => {
                        form.setFieldsValue({
                          container2PlateNumber: maxLengthValidator(
                            e.target.value,
                            11,
                          ),
                        });
                      }}
                      disabled={readOnly}
                      style={{
                        textTransform: 'uppercase',
                        fontWeight: '600',
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xl={12} xxl={6}>
                  <Form.Item
                    label="Nr. Container 3"
                    name="container3PlateNumber"
                  >
                    <Input
                      onChange={(e) => {
                        form.setFieldsValue({
                          container3PlateNumber: maxLengthValidator(
                            e.target.value,
                            11,
                          ),
                        });
                      }}
                      disabled={readOnly}
                      style={{
                        textTransform: 'uppercase',
                        fontWeight: '600',
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {transaction?.id &&
                (transaction?.isSeedReceived ||
                  Object.is(transaction?.direction, 'OUT')) && (
                  <Row gutter={15}>
                    {user?.customsPost?.hasSeed && (
                      <Button
                        type="primary"
                        style={{ marginBottom: '.5rem' }}
                        onClick={() => setIsSaveAndSendModalVisible(true)}
                        disabled={
                          transaction?.isSeedSent ||
                          isSeedSent ||
                          hasGoods ||
                          readOnly
                        }
                      >
                        {Object.is(transaction?.direction, 'IN')
                          ? `Confirmare SEED`
                          : `Trimite SEED`}
                      </Button>
                    )}

                    {transaction?.isSeedReceived &&
                      !(isSeedSent || transaction?.isSeedSent) && (
                        <label
                          className="important__message"
                          style={{ marginLeft: '20px', height: '32px' }}
                        >
                          Datele SEED au fot transmise de catre partea
                          Romaneasca
                        </label>
                      )}
                    {(isSeedSent || transaction?.isSeedSent) && (
                      <Form.Item
                        className="important__message"
                        label={
                          Object.is(transaction?.direction, 'IN')
                            ? `Datele au fost confirmate`
                            : `Datele au fost trimise`
                        }
                        name="infoLabel"
                        style={{ marginLeft: '20px', height: '32px' }}
                      />
                    )}
                  </Row>
                )}
            </Col>
          </Row>
          {!editMode && (
            <>
              <Divider plain>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '10px',
                  }}
                >
                  <Col xl={12} xxl={6}>
                    <Form.Item name="hasGoods" valuePropName="checked">
                      <Switch
                        onChange={(e) => handleHasGoodsChange(e)}
                        checked={hasGoods}
                        disabled={
                          isSeedSent || transaction?.isSeedSent || readOnly
                        }
                      />
                    </Form.Item>
                  </Col>
                  <span style={{ transform: 'translateY(3px)' }}> Marfă</span>
                </div>
              </Divider>
              <Row style={{ gap: '20px' }}>{hasGoods ? <></> : null}</Row>

              {hasGoods ? (
                <>
                  <Row gutter={15}>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={6}>
                      <Form.Item
                        label="Țara de Încărcare"
                        name="loadCountryId"
                        rules={[
                          {
                            required: hasGoods,
                            message: 'Câmpul este obligatoriu',
                          },
                        ]}
                      >
                        <CountryDropDown
                          content={content || []}
                          loading={loading}
                          disabled={readOnly}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={6}>
                      <Form.Item
                        label="Țara de Descărcare"
                        name="unloadCountryId"
                        rules={[
                          {
                            required: hasGoods,
                            message: 'Câmpul este obligatoriu',
                          },
                        ]}
                      >
                        <CountryDropDown
                          loading={loading}
                          content={content || []}
                          disabled={readOnly}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={4}>
                      <Form.Item
                        name="hasDangerousGoods"
                        valuePropName="checked"
                        className="goods__checkbox"
                      >
                        <Checkbox defaultChecked="false" disabled={readOnly}>
                          Mărfuri periculoase
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={4}>
                      <Form.Item
                        name="hasPerishable"
                        valuePropName="checked"
                        className="goods__checkbox"
                      >
                        <Checkbox defaultChecked="false" disabled={readOnly}>
                          Mărfuri perisabile
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={4}>
                      <Form.Item
                        name="isGroupage"
                        valuePropName="checked"
                        className="goods__checkbox"
                      >
                        <Checkbox defaultChecked="false" disabled={readOnly}>
                          Grupaj
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={15}>
                    <Divider plain>Agent economic</Divider>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={6}>
                      <Form.Item
                        label="IDNO"
                        name="agentIdno"
                        rules={[
                          {
                            required: hasGoods,
                            message: 'Câmpul este obligatoriu',
                          },
                        ]}
                      >
                        <InputHelper
                          onSearch={getPersonsReference}
                          getReference={getPerson}
                          onSelect={handleSetAgentFields}
                          disabled={readOnly || editMode}
                          onChange={(e) =>
                            !editMode && e.target.value.length === 13
                              ? handleSearchAgentOrganizationByIDNO()
                              : () => {}
                          }
                          maxLength={13}
                          style={{
                            textTransform: 'uppercase',
                            fontWeight: '600',
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={8}>
                      <Form.Item
                        label="Denumire agent economic"
                        name="agentName"
                        rules={[
                          {
                            required: hasGoods,
                            message: 'Câmpul este obligatoriu',
                          },
                        ]}
                      >
                        <Input
                          onChange={(e) => {
                            form.setFieldsValue({
                              agentName: maxLengthValidator(
                                e.target.value,
                                255,
                              ),
                            });
                          }}
                          disabled={readOnly || editMode}
                          style={{
                            textTransform: 'uppercase',
                            fontWeight: '600',
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={6}>
                      <Form.Item
                        label="Țară"
                        name="agentCountry"
                        rules={[
                          {
                            required: hasGoods,
                            message: 'Câmpul este obligatoriu',
                          },
                        ]}
                      >
                        <CountryDropDown
                          loading={loading}
                          content={content || []}
                          id="agentCountryId"
                          disabled={readOnly || editMode}
                          onChange={(value) => handleChangeAgentCountry(value)}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={3}>
                      <Form.Item
                        label=" "
                        name="agentType"
                        valuePropName="checked"
                      >
                        <Checkbox
                          disabled={readOnly || editMode}
                          onChange={handleChangeAgentType}
                        >
                          Rezident
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={15}>
                    <Divider plain>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          gap: '3px',
                        }}
                      >
                        <Col xl={12} xxl={6}>
                          <Switch
                            onChange={(checked) => {
                              setHasScale(checked);
                            }}
                            checked={hasScale}
                            disabled={
                              isSeedSent ||
                              transaction?.isSeedSent ||
                              readOnly ||
                              !hasGoods
                            }
                          />
                        </Col>
                        <span style={{ transform: 'translateY(-2px)' }}>
                          {' '}
                          Date cântar
                        </span>
                      </div>
                    </Divider>

                    {hasScale ? (
                      <>
                        <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={4}>
                          <Form.Item
                            label={
                              <RequiredLabel title="Masa transportului (tone)" />
                            }
                            name="vehicleWeight"
                          >
                            <Input
                              onChange={(e) => {
                                form.setFieldsValue({
                                  vehicleWeight: floatValidator(
                                    dotValidator(e.target.value),
                                    3,
                                    2,
                                  ),
                                });
                                calculateMasaTotala();
                              }}
                              maxLength={9}
                              disabled={readOnly || editMode}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={4}>
                          <Form.Item
                            label={
                              <RequiredLabel title="Masa încărcăturii (tone)" />
                            }
                            name="goodsWeight"
                          >
                            <Input
                              onChange={(e) => {
                                form.setFieldsValue({
                                  goodsWeight: floatValidator(
                                    dotValidator(e.target.value),
                                    3,
                                    2,
                                  ),
                                });
                                calculateMasaTotala();
                              }}
                              maxLength={9}
                              disabled={readOnly || editMode}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={4}>
                          <Form.Item
                            label={<RequiredLabel title="Masa totală (tone)" />}
                            name="totalWeight"
                          >
                            <InputNumber disabled />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={4}>
                          <Form.Item
                            label={<RequiredLabel title="Număr de axe" />}
                            name="axisCount"
                          >
                            <Input
                              onChange={(e) => {
                                form.setFieldsValue({
                                  axisCount: maxNumberValidator(
                                    dotValidator(e.target.value),
                                  ),
                                });
                              }}
                              disabled={readOnly || editMode}
                            />
                          </Form.Item>
                        </Col>
                        <Col xxs={24} sm={12} md={12} lg={8} xl={8} xxl={4}>
                          <Form.Item
                            label={
                              <RequiredLabel title="Distanța dintre axe" />
                            }
                            name="axisDistance"
                          >
                            <Input
                              onChange={(e) => {
                                form.setFieldsValue({
                                  axisDistance: floatValidator(
                                    dotValidator(e.target.value),
                                  ),
                                });
                              }}
                              maxLength={9}
                              disabled={readOnly || editMode}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={4}>
                          <Form.Item
                            label={<RequiredLabel title="Distanța (km)" />}
                            name="distance"
                          >
                            <Input
                              onChange={(e) => {
                                form.setFieldsValue({
                                  distance: numberValidator(e.target.value, 10),
                                });
                              }}
                              disabled={readOnly || editMode}
                            />
                          </Form.Item>
                        </Col>
                      </>
                    ) : null}
                  </Row>
                  {/* <Row> 
                       <Col xl={12} xxl={6}>
                         <Form.Item
                           label=" " 
                           name="hasContainer" 
                           valuePropName="checked" 
                           initialValue={transaction?.scale?.hasContainer} 
                         > 
                           <Checkbox 
                             disabled={readOnly} 
                             style={{ whiteSpace: 'nowrap' }}
                           > 
                             Container (nr. axe este 5 sau 6) 
                           </Checkbox> 
                         </Form.Item> 
                       </Col> 
                      </Row>  */}
                </>
              ) : null}
            </>
          )}
          {(irregularities || consemneStr) && (
            <HistoricalDataAndAnalysis
              onConsemnResponse={setConsemnResponse}
              permissions={permissions}
              transactionId={transaction?.id}
              consemneStr={consemneStr}
              irregularities={irregularities}
              readOnly={readOnly}
              reload={reload}
              setLoadingLoader={setLoadingLoader}
              irregularitiesMessage={transaction?.message?.message || ''}
              imageIds={transaction?.consemne?.map((el) => el.docImageId)}
              form={form}
            />
          )}
          {hasGoods ? (
            <GoodsDocumentTable
              disabled={editMode || readOnly}
              initial={transaction?.scale?.goodsDocuments}
              onGoodsDocumentsUpdate={(res) => {
                setGoodsDocuments(res);
              }}
              form={form}
            />
          ) : null}
          {transaction?.id ? (
            <TransactionDocumentsTable
              rowKey="id"
              pagination={false}
              scroll={{ y: 500, drag: true }}
              transactionId={transaction.id}
              vinCode={transaction?.vehicleVinCode}
              idnp={transaction?.idnp}
              vehicleNumber={transaction?.vehicleNumber}
            />
          ) : null}
          {transaction?.id ? (
            <GeneralDataTimeLine
              vehicleVinCode={transaction?.vehicleVinCode}
              form={form}
              isFromMd={transaction?.vehicleCountry === defaultCountryId}
              reloadDependency={timelineDependency}
            />
          ) : null}
          {transaction?.status !== TransactionStatus.PENDING ? (
            <Col span={24}>
              <CorrectionTable
                corrections={transaction?.corrections}
                transactionId={transaction?.id}
                vinCode={transaction?.vehicleVinCode}
                countries={countries?.content}
                pagination={false}
                scroll={{ y: 400 }}
                rowKey="id"
              />
            </Col>
          ) : null}
          {editMode && (
            <>
              <Col span={12}>
                <div style={{ fontWeight: '600' }}>Scrisoare/ PV</div>
                <Row gutter={12}>
                  <Col span={12}>
                    <Form.Item
                      name="correctionLetterNumber"
                      label="Număr"
                      labelCol={{ span: 24 }}
                    >
                      <Input
                        onChange={(e) => {
                          form.setFieldsValue({
                            correctionLetterNumber: maxLengthValidator(
                              e.target.value,
                              '30',
                            ),
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="correctionLetterDate"
                      label="Data"
                      labelCol={{ span: 24 }}
                    >
                      <DatePicker format="DD.MM.YYYY" />
                    </Form.Item>
                  </Col>
                </Row>
                <Col span={24} style={{ marginBottom: '30px' }}>
                  <Form.Item
                    name="correctionReason"
                    label={
                      <RequiredLabel title="Motivul, care a stat la baza corecțiilor" />
                    }
                  >
                    <TextArea
                      showCount
                      maxLength={3000}
                      rows={4}
                      rules={[
                        {
                          required: true,
                          message: 'Câmpul este obligatoriu',
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Col>
            </>
          )}
          {transaction?.status !== TransactionStatus.PENDING ? (
            <CopyTransactionTable transactionId={transaction.id} />
          ) : null}
          <Row
            className="transaction__new-btn"
            style={{ marginTop: 'auto' }}
            gutter={15}
          >
            <Col>
              <CancelButton
                disabled={readOnly}
                onCancel={() =>
                  history.push(
                    transaction?.status !== TransactionStatus.PENDING
                      ? `/work-history`
                      : `/home`,
                  )
                }
              />
            </Col>
            {user?.function !== 'SCANN_OPERATOR' && (
              <Col xl={4} xxl={2} style={{ maxWidth: '100%' }}>
                <Button
                  id={
                    editMode || !transaction?.id
                      ? 'createTransaction'
                      : 'saveData'
                  }
                  disabled={(readOnly && !editMode) || loadingLoader}
                  type="primary"
                  icon={
                    editMode || !transaction?.id ? (
                      <SaveOutlined />
                    ) : (
                      <RightCircleOutlined />
                    )
                  }
                  style={{ width: '100%' }}
                  onClick={() => {
                    handleSaveTransaction()
                      .then(() => handleMoveNext())
                      .catch(() => {});
                  }}
                >
                  {editMode || !transaction?.id ? 'Salvare date' : 'Înainte'}
                </Button>
              </Col>
            )}
            {transaction.id ? (
              <Col xl={6} xxl={2} style={{ maxWidth: '100%' }}>
                <LiberModal
                  onInit={handleSaveTransaction}
                  hasScale={hasScale}
                  setLoadingLoader={setLoadingLoader}
                  loadingLoader={loadingLoader}
                  transactionId={transaction.id}
                  readOnly={readOnly}
                />
              </Col>
            ) : null}
          </Row>

          <Modal
            maskClosable={false}
            centered
            style={{ width: '400px' }}
            title="Confirmați acțiunea"
            open={isSaveAndSendModalVisible}
            onOk={handleSaveAndSendModalOk}
            onCancel={handleSaveAndSendModalCancel}
            cancelText="Înapoi"
          >
            <span>Doriți să continuați?</span>
          </Modal>
          <PreSearchModal
            transactionId={transaction?.id}
            transformTransaction={transformTransaction}
            setLoadingLoader={setLoadingLoader}
            setHasGoods={setHasGoods}
            setHasDrivingLicence={setHasDrivingLicence}
            onVehicleTypeChange={onVehicleTypeChange}
            handleChangeTypeTrailer={handleChangeTypeTrailer}
            form={form}
          />
          <CorrectionReasonModal />
        </Form>
      </FormContextProvider>
    </>
  );
};

export default GeneralData;
