import { get, post, put, remove } from '../../helpers/api';

export const BASE_URI = '/backend/api';
export const SERVICE_URI = '/v1/bon-plata-parameter';

export const findOne = (id) => get(`${BASE_URI}${SERVICE_URI}/${id}`);

export const findByTransactionId = (transactionId) =>
  get(`${BASE_URI}${SERVICE_URI}/transaction/${transactionId}`);

export const cancelBon = (transactionId) =>
  remove(`${BASE_URI}${SERVICE_URI}/cancel/${transactionId}`);

export const create = (body) => post(`${BASE_URI}${SERVICE_URI}`, body);

export const update = (id, body) =>
  put(`${BASE_URI}${SERVICE_URI}/${id}`, body);

export const updateByTransactionId = (transactionId, body) =>
  put(`${BASE_URI}${SERVICE_URI}/transaction/${transactionId}`, body);
