import React from 'react';

const ChatRightBackground = ({ rightSideChatBackground, rightSideChat }) => {
  return (
    <div
      className={`chat__rightside-background ${
        rightSideChatBackground ? 'active' : ''
      } ${rightSideChat ? 'rightside' : ''}`}
    />
  );
};

export default ChatRightBackground;
