import React from 'react';

import { useEditPage } from '../../../../hooks/useEditPage';

import {
  findOne,
  create,
  update,
} from '../../../../services/taxonomies/document-type-payment';
import EditDocumentTypePaymentForm from '../../../../components/admin/taxonomies/documentTypePayments/EditDocumentTypePaymentForm';
import AppLoader from '../../../../components/auth/AppLoader';

const initial = {};

const EditDocumentTypePayment = () => {
  const [post, handleSubmit, handleCancel, errors, loading] = useEditPage({
    initial,
    existent: findOne,
    goBackPath: '/admin/taxonomies/document-type-payment',
    onCreate: create,
    onUpdate: update,
  });

  return (
    <>
      <div className={`loaderOverlay ${loading ? 'active' : ''}`}>
        <AppLoader />
      </div>
      {post !== undefined && (
        <EditDocumentTypePaymentForm
          post={post}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          errors={errors}
        />
      )}
    </>
  );
};

export default EditDocumentTypePayment;
