import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Menu,
  notification,
  Row,
  Select,
  Table,
  Tooltip,
} from 'antd';
import Flag from 'react-world-flags';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  DownloadOutlined,
  EyeOutlined,
  FileTextOutlined,
  SearchOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import Column from '../../helpers/Columns';
import useDatasource from '../../hooks/useDatasource';
import {
  exportOperationsCSV,
  findAll as findAllOperations,
  getById,
  getByOldInstanceId,
  getByOldSysOpe,
} from '../../services/transactions/operations';
import { generatePdfInNewTab } from '../../services/transactions/transactions';
import { findAll as findAllCustomsPosts } from '../../services/admin/users/customs-posts';
import { findAll as findAllCustomsOffices } from '../../services/admin/users/customs-office';
import { findAll as findAllConstants } from '../../services/taxonomies/constants';
import { findAll as findAllCountries } from '../../services/admin/geo/countries';
import { findAll as findAllCustomsRegimes } from '../../services/taxonomies/customs-regime';
import { findAll as findAllVehicleTypes } from '../../services/taxonomies/vehicle-types';
import { uploadFiles } from '../../services/admin/files';
import { linkFileReport } from '../../services/admin/shift-report-parameters';
import {
  getSubordinates,
  getUsersByPostId,
} from '../../services/admin/users/users';
import useDictionaries from '../../hooks/useDictionaries';

import AuthContext, { hasPermission } from '../../components/auth';
import AppLoader from '../../components/auth/AppLoader';
import { useTableScroll } from '../../hooks/useTableScroll';
import CarColorChecker from '../CarColorChecker';
import DirectionCheckboxes from '../../components/DirectionCheckboxes';
import InputDebounce from '../../components/InputDebounce';
import TransactionStatus from '../../enums/TransactionStatus';
import Icon from '../../components/Icon';
import DocumentCodes from '../../enums/DocumentCodes';
import UserFunctions from '../../enums/UserFunctions';
import GenerateReportModal from '../../components/admin/modals/GenerateReportModal';

const dictionaries = {
  customsPosts: findAllCustomsPosts,
  customsOffices: findAllCustomsOffices,
  customsRegimes: findAllCustomsRegimes,
  constants: findAllConstants,
  countries: findAllCountries,
  vehicleTypes: findAllVehicleTypes,
};

const directions = {
  content: [
    { id: 'IN', code: 'IN', name: 'INTRARE' },
    { id: 'OUT', code: 'OUT', name: 'IEȘIRE' },
  ],
};

const ReportType = {
  CUSTOM_POST: 'CUSTOM_POST',
  DETAILED_REPORT: 'SHIFT_REPORT',
  INSPECTOR_REPORT: 'INSPECTOR_REPORT',
};

function subtractHours(numOfHours, date = new Date()) {
  date.setHours(date.getHours() - numOfHours);
  return date;
}

const WorkHistory = () => {
  const history = useHistory();
  useTableScroll();
  const [form] = Form.useForm();
  const { user } = useContext(AuthContext);
  const { permissions } = user;
  const [rute, setRute] = useState({});
  const [
    {
      customsPosts,
      constants,
      customsOffices,
      countries,
      customsRegimes,
      vehicleTypes,
    },
    ,
    loadingDictionaries,
  ] = useDictionaries(dictionaries);

  const [tableLoading, setTableLoading] = useState(false);

  const [reportButtonPresed, setReportButtonPresed] = useState(null);
  const { t } = useTranslation();
  const [inCheckbox, setInCheckbox] = useState(
    rute
      ? JSON.parse(localStorage.getItem('rute'))?.in
      : user?.direction === null || user?.direction === 'IN',
  );
  const [outCheckbox, setOutCheckbox] = useState(
    rute
      ? JSON.parse(localStorage.getItem('rute'))?.out
      : user?.direction === null || user?.direction === 'OUT',
  );

  const [isCargo, setIsCargo] = useState(
    rute ? JSON.parse(localStorage.getItem('rute'))?.cargo : false,
  );

  const [isAuto, setIsAuto] = useState(
    rute ? JSON.parse(localStorage.getItem('rute'))?.auto : false,
  );

  const [loadingLoader, setLoadingLoader] = useState(false);
  const [posts, setPosts] = useState([]);
  const [currentPostId, setCurrentPostId] = useState(null);
  const [currentUserId, setCurrentUserId] = useState(null);

  const [disableSelectPost, setDisableSelectPost] = useState(false);
  const [usersLoading, setUsersLoading] = useState(false);
  const [subordinates, setSubordinates] = useState([user]);
  const [currentCustomRegime, setCurrentCustomRegime] = useState(null);
  const [buttonFirstPress, setButtonFirstPress] = useState(false);
  const [isDataModalVisible, setDataModalVisible] = useState(false);

  const [postConstants, setPostConstants] = useState({
    hasAnsa: false,
    hasAnta: false,
    hasMdUa: false,
    hasScale: false,
    hasScan: false,
    hasWarehouse: false,
  });

  useEffect(() => {
    form.setFieldsValue({
      selectPost: currentPostId,
      selectUser: currentUserId,
    });
  }, [form, currentPostId, currentUserId]);

  useEffect(() => {
    if (
      user?.function === UserFunctions.HEAD &&
      user.postId === currentPostId
    ) {
      getSubordinates(user?.id)
        .then((res) => {
          const activeUsers = res
            .filter((el) => !el.deleted && el.id !== user.id)
            ?.sort((a, b) => a.fullname.localeCompare(b.fullname));

          setSubordinates([user, ...activeUsers]);
          // setCurrentUserId(user?.id);
        })
        .finally(() => setUsersLoading(false));
    } else if (currentPostId || user?.postId) {
      const postId = currentPostId || user?.postId;
      setUsersLoading(true);
      getUsersByPostId(postId)
        .then((users) => {
          const activeUsers = users
            .filter((el) => !el.deleted)
            ?.sort((a, b) => a.fullname.localeCompare(b.fullname));

          if (
            user.function === UserFunctions.POST_HEAD ||
            user.function === UserFunctions.OFFICE_HEAD ||
            user.function === UserFunctions.CENTRAL ||
            user.function === UserFunctions.MB_TEAM ||
            user.function === UserFunctions.HEAD
          ) {
            if (
              user.function === UserFunctions.POST_HEAD ||
              user.function === UserFunctions.HEAD
            ) {
              const usersPostsIds = user.posts?.map((el) => el.id) || [];
              if (postId === user.postId || usersPostsIds.includes(postId)) {
                setSubordinates(activeUsers);
              } else {
                setSubordinates([]);
              }
              // setCurrentUserId(user?.id);
            } else {
              setSubordinates(activeUsers);
            }
          } else if (
            user.function === UserFunctions.INSPECTOR &&
            postId !== user.postId
          ) {
            setSubordinates([]);
            setCurrentUserId(null);
          } else {
            setCurrentUserId(user.postId === currentPostId ? user?.id : null);
            setSubordinates([user]);
          }
        })
        .finally(() => setUsersLoading(false));
    }
  }, [currentPostId, user]);

  useEffect(() => {
    if (user?.customsPost) {
      setPostConstants({
        hasAnsa: user?.customsPost?.hasAnsa,
        hasAnta: user?.customsPost?.hasAnta,
        hasMdUa: user?.customsPost?.hasMdUa,
        hasScale: user?.customsPost?.hasScale,
        hasScan: user?.customsPost?.hasScan,
      });
    }
  }, [user]);

  const alowedCategories = useCallback(() => {
    const cargoCategories =
      constants?.content
        ?.find((el) => el.code === 'TERMINAL_MARFA_CATEGORII')
        ?.value?.split(',') || [];

    const autoCategories =
      constants?.content
        ?.find((el) => el.code === 'TERMINAL_PASAGERI_CATEGORII')
        ?.value?.split(',') || [];

    if (isCargo && isAuto) {
      return cargoCategories.concat(autoCategories);
    }
    if (isCargo && !isAuto) {
      return cargoCategories;
    }
    if (isAuto && !isCargo) {
      return autoCategories;
    }
    return ['NO_CATEGORY'];
  }, [constants?.content, isAuto, isCargo]);

  const handler = useCallback(
    (...params) => {
      form.setFieldsValue({ oldInstanceId: null, oldSysOpe: null, id: null });
      const hz = params[0];

      const initial = hz?.criterias;
      hz.criterias = {
        ...initial,
        postId: currentPostId || '',
        direction:
          // eslint-disable-next-line
          inCheckbox && outCheckbox
            ? ['IN', 'OUT']
            : // eslint-disable-next-line
            inCheckbox
            ? ['IN']
            : outCheckbox
            ? ['OUT']
            : ['INOUT'],
        statuses: ['ACCEPTED', 'REJECTED'],
        idnp: form.getFieldValue('passport') || '',
        plateNumber: form.getFieldValue('plateNumber') || '',
        driverFullName: form.getFieldValue('driverFullName') || '',
        vinCode: form.getFieldValue('vinCode') || '',
        category: alowedCategories(),
        updatedAtFrom: form.getFieldValue('historyFrom')
          ? new Date(form.getFieldValue('historyFrom')).toISOString()
          : subtractHours(24).toISOString(),
        updatedAtTo: form.getFieldValue('historyTo')
          ? new Date(form.getFieldValue('historyTo')).toISOString()
          : new Date().toISOString(),
        transporterPersonCode:
          form.getFieldValue('transporterPersonCode')?.length >= 5
            ? form.getFieldValue('transporterPersonCode')
            : '',
        transporterPersonName:
          hasPermission(permissions, 'SEARCH_OPERATIONS_BY_TRANSPORTER_NAME') &&
          form.getFieldValue('transporterPersonName')
            ? form.getFieldValue('transporterPersonName')
            : '',
      };
      localStorage.setItem('workHistory', JSON.stringify(form.getFieldValue()));
      // if (user?.function === 'HEAD' && !currentUserId) {
      //   hz.criterias.subordinates = subordinates.map((el) => el.id);
      // } else
      if (currentUserId) {
        hz.criterias.inspectorId = currentUserId;
      }
      if (currentCustomRegime) {
        hz.criterias.customsRegimeId = currentCustomRegime;
      }

      hz.sort = ['idx', 'desc'];

      if (user?.function === 'HEAD' || user?.function === 'POST_HEAD') {
        if (!currentPostId) {
          delete hz?.criterias?.postId;
        }
      }

      return findAllOperations(hz);
    },
    [
      user,
      form,
      inCheckbox,
      outCheckbox,
      currentPostId,
      currentUserId,
      alowedCategories,
      currentCustomRegime,
      permissions,
    ],
  );

  const { loading, pagination, content, handleChange, reload, setContent } =
    useDatasource(handler, {
      allowFetcher: !loadingDictionaries && !usersLoading && buttonFirstPress,
    });

  // const reloadWithCheck = useCallback(() => {
  //   if (!currentPostId) {
  //     const {
  //       plateNumber,
  //       vinCode,
  //       passport,
  //       transporterPersonName,
  //       transporterPersonCode,
  //     } = form.getFieldsValue();

  //     if (
  //       !(
  //         plateNumber ||
  //         vinCode ||
  //         passport ||
  //         transporterPersonName ||
  //         transporterPersonCode
  //       )
  //     ) {
  //       notification.error({
  //         message: 'Cel puțin un câmp obligatoriu nu este completat',
  //         description:
  //           'Nr. Auto, Nr. VIN, Pașaport, IDNO/IDNP transportator, Denumire transportator',
  //       });
  //       return;
  //     }
  //   }
  //   reload();
  // }, [currentPostId, form, reload]);

  useEffect(() => {
    const ruteCheckboxes = {
      out: outCheckbox,
      in: inCheckbox,
      cargo: isCargo,
      auto: isAuto,
    };
    localStorage.setItem('rute', JSON.stringify(ruteCheckboxes));
  }, [outCheckbox, inCheckbox, isCargo, isAuto]);

  // useEffect(() => {
  //   reload();
  // }, [customsOffices, reload]);

  useEffect(() => {
    const workHistory = JSON.parse(localStorage.getItem('workHistory'));
    if (workHistory) {
      form.setFieldsValue({
        ...workHistory,
        historyTo: workHistory.historyTo ? dayjs(workHistory.historyTo) : null,
        historyFrom: workHistory.historyFrom
          ? dayjs(workHistory.historyFrom)
          : null,
      });
    } else {
      form.setFieldsValue({
        historyTo: dayjs(new Date()),
        historyFrom: dayjs(subtractHours(24)),
      });
    }
    setRute(JSON.parse(localStorage.getItem('rute')));
  }, [form]);

  const handleExport = useCallback(() => {
    const spec = {};
    const historyFrom = form.getFieldValue('historyFrom')
      ? new Date(form.getFieldValue('historyFrom'))
      : subtractHours(24);
    const histoyTo = form.getFieldValue('historyTo')
      ? new Date(form.getFieldValue('historyTo'))
      : new Date();
    spec.criterias = {
      postId:
        currentPostId ||
        user?.customsPost?.id ||
        (user.officeId &&
          customsOffices?.content
            .find((el) => el?.id === user.officeId)
            ?.customsPosts?.map((el) => el?.id)) ||
        user.posts?.map((el) => el?.id) ||
        (user.postId && [user.PostId]),
      direction:
        // eslint-disable-next-line
        inCheckbox && outCheckbox
          ? ['IN', 'OUT']
          : // eslint-disable-next-line
          inCheckbox
          ? ['IN']
          : outCheckbox
          ? ['OUT']
          : ['INOUT'],
      idnp: form.getFieldValue('passport') || '',
      allPlateNumbers: form.getFieldValue('plateNumber') || '',
      vinCode: form.getFieldValue('vinCode') || '',
      category: alowedCategories(),
      updatedAtFrom: historyFrom.toISOString(),
      updatedAtTo: histoyTo.toISOString(),
    };
    if (user?.function === 'HEAD' && !currentUserId) {
      spec.criterias.subordinates = subordinates.map((el) => el.id);
    } else if (currentUserId) {
      spec.criterias.inspectorId = currentUserId;
    }
    if (currentCustomRegime) {
      spec.criterias.customsRegimeId = currentCustomRegime;
    }
    spec.sort = ['statusDate', 'desc'];
    const format = 'DD.MM.YY';
    const fileName = `Istoric-Operatiuni__${dayjs(historyFrom).format(
      format,
    )}-${dayjs(histoyTo).format(format)}`;
    exportOperationsCSV(spec, fileName).then().catch();
  }, [
    user,
    form,
    subordinates,
    inCheckbox,
    outCheckbox,
    currentPostId,
    currentUserId,
    customsOffices,
    alowedCategories,
    currentCustomRegime,
  ]);

  const generateRowTrafficLight = (currentRow, organisation) => {
    const {
      hasAnsa,
      hasAnta,
      hasMdUa,
      hasScale,
      hasScan,
      hasWarehouse,
      isAnsaConfirmed,
      isAntaConfirmed,
      isMdUaConfirmed,
      isScaleConfirmed,
      isScanConfirmed,
      isWarehouseConfirmed,
    } = currentRow;

    // eslint-disable-next-line consistent-return
    const generateIcons = (type) => {
      switch (type) {
        case 'ansa':
          if (!hasAnsa) {
            return <div className="icon-warning " />;
          }
          if (hasAnsa && !isAnsaConfirmed) {
            return <div className="icon-danger" />;
          }
          if (hasAnsa && isAnsaConfirmed) {
            return <div className="icon-success" />;
          }
          break;
        case 'anta':
          if (!hasAnta) {
            return <div className="icon-warning " />;
          }
          if (hasAnta && !isAntaConfirmed) {
            return <div className="icon-danger" />;
          }
          if (hasAnta && isAntaConfirmed) {
            return <div className="icon-success" />;
          }
          break;
        case 'mdua':
          if (!hasMdUa) {
            return <div className="icon-warning " />;
          }
          if (hasMdUa && !isMdUaConfirmed) {
            return <div className="icon-danger" />;
          }
          if (hasMdUa && isMdUaConfirmed) {
            return <div className="icon-success" />;
          }
          break;
        case 'scale':
          if (!hasScale) {
            return <div className="icon-warning " />;
          }
          if (hasScale && !isScaleConfirmed) {
            return <div className="icon-danger" />;
          }
          if (hasScale && isScaleConfirmed) {
            return <div className="icon-success" />;
          }
          break;
        case 'scan':
          if (!hasScan) {
            return <div className="icon-warning " />;
          }
          if (hasScan && !isScanConfirmed) {
            return <div className="icon-danger" />;
          }
          if (hasScan && isScanConfirmed) {
            return <div className="icon-success" />;
          }
          break;
        case 'warehouse':
          if (!hasWarehouse) {
            return <div className="icon-warning " />;
          }
          if (hasWarehouse && !isWarehouseConfirmed) {
            return <div className="icon-danger" />;
          }
          if (hasWarehouse && isWarehouseConfirmed) {
            return <div className="icon-success" />;
          }
          break;
        default:
          break;
      }
    };
    return <>{generateIcons(organisation)}</>;
  };

  const tableHeaderSettings = useCallback(() => {
    const CSV_BUTTON = 'csvButton';
    const downloadCsvObj = {
      key: CSV_BUTTON,
      label: 'Exportă CSV',
      icon: <DownloadOutlined />,
    };

    const items = [
      {
        key: 'settings',
        icon: <SettingOutlined />,
        children: [downloadCsvObj],
      },
    ];
    const onClick = (e) => {
      switch (e.key) {
        case CSV_BUTTON:
          handleExport();
          break;
        default:
          break;
      }
    };

    return (
      <>
        <Menu
          className="table__menu-settings"
          onClick={onClick}
          mode="vertical"
          items={items}
        />
        <div>{t('table.actions')}</div>
      </>
    );
  }, [t, handleExport]);

  const columns = useMemo(() => {
    const c = [
      Column.text('id', 'Nr. Rută', {
        sort: false,
        width: 100,
      }),
      Column.dictionary('customsRegimeId', 'Tip operațiune', customsRegimes, {
        width: 100,
        dictKey: 'id',
      }),
      {
        title: 'Inspector',
        key: 'inspectorName',
        width: 100,
        dataIndex: 'inspectorName',
        align: 'center',
        render: (code, row) => {
          return <div>{row.inspector} </div>;
        },
      },
      Column.dictionary('postId', 'Post vamal', customsPosts, {
        width: 100,
        dictLabel: 'newCode',
      }),
      Column.date('controlDate', 'Control inițiat la', {
        width: 100,
        format: 'DD.MM.YY HH:mm:ss',
        sort: (prev, next) => {
          return (
            new Date(prev?.controlDate).getTime() -
            new Date(next?.controlDate).getTime()
          );
        },
      }),
      {
        title: 'Eliberat/ Retur',
        key: 'status',
        width: 80,
        dataIndex: 'status',
        render: (code, row) => {
          if (row.status === TransactionStatus.ACCEPTED) {
            return <div className="icon-success" />;
          }
          if (row.status === TransactionStatus.REJECTED) {
            return <div className="icon-danger" />;
          }
          return <></>;
        },
      },
      Column.date('statusDate', 'La data', {
        width: 100,
        format: 'DD.MM.YY HH:mm:ss',
        sort: (prev, next) => {
          return (
            new Date(prev?.statusDate).getTime() -
            new Date(next?.statusDate).getTime()
          );
        },
      }),
      user?.customsPost?.hasSeed && {
        sort: false,
        title: 'Fără marfă (SEED)',
        key: 'isSeedReceived',
        width: 75,
        dataIndex: 'isSeedReceived',
        render: (code, row) => {
          return <>{row.isSeedReceived && <div className="icon-success" />}</>;
        },
      },
      Column.text('daysInRMFromLastEntry', 'Zile în  RM', {
        width: 60,
        sort: (prev, next) => {
          return prev?.daysInRMFromLastEntry - next?.daysInRMFromLastEntry;
        },
      }),
      Column.dictionary('direction', 'Direcție', directions, {
        width: 100,
        filter: false,
      }),
      {
        title: 'Tip Culoare',
        key: 'category',
        width: 85,
        align: 'center',
        render: (code, row) => {
          return (
            <CarColorChecker
              direction={row.direction}
              color={row.color}
              category={row.category}
            />
          );
        },
      },

      Column.other(
        'vehicleData',
        'Marcă, Model',
        (code, row) => {
          const carMark = row.vehicleMark?.toLowerCase();
          return (
            <div className="home__table-carIcon">
              <Icon
                width={
                  carMark === 'vanhool' ||
                  carMark === 'van hool' ||
                  carMark === 'iveco' ||
                  carMark === 'hummer' ||
                  carMark === 'daf'
                    ? 40
                    : 25
                }
                height={
                  carMark === 'vanhool' ||
                  carMark === 'van hool' ||
                  carMark === 'iveco' ||
                  carMark === 'hummer' ||
                  carMark === 'daf'
                    ? 40
                    : 25
                }
                name={carMark}
              />
              <div>
                <div>{row.vehicleMark || ''}</div>
                <div>{row.vehicleModel || ''}</div>
              </div>
            </div>
          );
        },
        {
          width: 120,
          align: 'center',
          sort: false,
        },
      ),
      Column.other(
        'allPlateNumbers',
        'Nr. auto',
        (code, row) => {
          const country = countries?.content?.find(
            (el) => el.id === row.vehicleCountryId,
          );

          return (
            <div className="home__table-flag">
              {country ? (
                <Tooltip title={country.name}>
                  <Flag code={country.alpha3} height="20" width="30" />
                </Tooltip>
              ) : null}
              {row.plateNumber}
            </div>
          );
        },
        {
          width: 90,
          sort: false,
        },
      ),
      Column.text('vinCode', 'Nr. Caroserie/VIN', {
        sort: false,
        // filter: true,
        width: 100,
      }),
      Column.text('idnp', 'Nr. Pașaport', {
        width: 150,
        sort: false,
      }),
      {
        sort: false,
        title: 'Nume',
        key: 'fullName',
        width: 200,
        dataIndex: 'fullName',
        render: (code, row) => {
          return <>{row.driverFullName?.toUpperCase()}</>;
        },
      },
      {
        title: 'Marfă',
        key: 'status',
        width: 120,
        dataIndex: 'status',
        render: (code, { hasGoods }) => {
          return (
            <>
              {hasGoods ? (
                <div>
                  <span style={{ color: 'green' }}>&#x25cf;</span> Da
                </div>
              ) : (
                <div>
                  <span style={{ color: 'red' }}>&#x25cf;</span> Nu
                  {/* <span style={{ color: 'green' }}>&#x25cf;</span> Da */}
                </div>
              )}
            </>
          );
        },
      },
      Column.date('createdAt', 'Data creare', {
        width: 100,
        format: 'DD.MM.YY HH:mm:ss',
        sort: (prev, next) => {
          return (
            new Date(prev?.createdAt).getTime() -
            new Date(next?.createdAt).getTime()
          );
        },
      }),
      Column.date('updatedAt', 'Data modificare', {
        width: 100,
        format: 'DD.MM.YY HH:mm:ss',
        sort: (prev, next) => {
          return (
            new Date(prev?.updatedAt).getTime() -
            new Date(next?.updatedAt).getTime()
          );
        },
      }),
      Column.text('crossingTimeMinutes', 'Durată control (minute)', {
        width: 100,
        filter: false,
        sort: false,
      }),
      {
        title: 'Însemnări',
        key: 'doc',
        width: 80,
        dataIndex: 'doc',
        render: (code, row) => {
          const records = [];
          if (row.haveActControl) records.push(DocumentCodes.ACT_CONTROL);
          if (row.sesizari) records.push(DocumentCodes.SESIZARI);
          if (row.irregularities) records.push(DocumentCodes.IRREGULARITIES);
          if (row.suspicionFraudReport)
            records.push(DocumentCodes.SUSPICION_FRAUD);

          return <div>{records.join(', ')}</div>;
        },
      },

      Column.text('transporterPersonCode', 'IDNO/IDNP transportator', {
        width: 150,
        sort: false,
      }),
      Column.text('transporterPersonName', 'Denumire transportator', {
        width: 150,
        sort: false,
      }),
      postConstants?.hasScale && isCargo
        ? {
            sort: false,
            title: 'Cântar',
            key: 'isScaleConfirmed',
            width: 75,
            dataIndex: 'isScaleConfirmed',
            render: (code, row) => generateRowTrafficLight(row, 'scale'),
          }
        : null,
      postConstants?.hasAnta && isCargo
        ? {
            sort: false,
            title: 'ANTA',
            key: 'isAntaConfirmed',
            width: 75,
            dataIndex: 'isAntaConfirmed',
            render: (code, row) => generateRowTrafficLight(row, 'anta'),
          }
        : null,
      postConstants?.hasAnsa && isCargo
        ? {
            sort: false,
            title: 'ANSA',
            key: 'isAnsaConfirmed',
            width: 75,
            dataIndex: 'isAnsaConfirmed',
            render: (code, row) => generateRowTrafficLight(row, 'ansa'),
          }
        : null,
      postConstants?.hasScan && isCargo
        ? {
            sort: false,
            title: 'SCAN',
            key: 'isScanConfirmed',
            width: 75,
            dataIndex: 'isScanConfirmed',
            render: (code, row) => generateRowTrafficLight(row, 'scan'),
          }
        : null,
      postConstants?.hasMdUa && isCargo
        ? {
            sort: false,
            title: 'MDUA',
            key: 'isMdUaConfirmed',
            width: 75,
            dataIndex: 'isMdUaConfirmed',
            render: (code, row) => generateRowTrafficLight(row, 'mdua'),
          }
        : null,
      {
        sort: false,
        title: tableHeaderSettings(),
        width: 85,
        fixed: 'right',
        align: 'center',
        render: (code, row) => {
          return (
            <>
              <button
                className="row-id-link history__view"
                type="button"
                onClick={() =>
                  history.push(`/physical-person-entry/${row?.id}`)
                }
              >
                <EyeOutlined style={{ color: '#fff', fontSize: '20px' }} />
              </button>
            </>
          );
        },
      },
    ];
    return c.filter((el) => el);
  }, [
    postConstants,
    countries?.content,
    history,
    user?.customsPost,
    isCargo,
    customsRegimes,
    tableHeaderSettings,
    customsPosts,
  ]);

  useEffect(() => {
    const workHistory = JSON.parse(localStorage.getItem('workHistory'));
    if (user?.function === UserFunctions.MB_TEAM) {
      setPosts(customsPosts?.content);
    } else if (
      user?.function === UserFunctions.POST_HEAD ||
      user?.function === UserFunctions.HEAD
      // || user?.function === UserFunctions.INSPECTOR
    ) {
      setPosts(customsPosts?.content);
      setCurrentPostId(
        workHistory?.selectPost === undefined
          ? user.postId
          : workHistory.selectPost,
      );
    } else if (user?.officeId) {
      setPosts(
        customsOffices?.content?.find((el) => el?.id === user.officeId)
          ?.customsPosts,
      );
    } else if (user?.posts?.length > 1) {
      const allowedPosts = user?.posts?.map((el) => el.id);
      setPosts(
        customsPosts?.content?.filter((el) => allowedPosts?.includes(el.id)),
      );
    } else {
      setPosts(customsPosts?.content);
      setCurrentPostId(
        workHistory?.selectPost === undefined
          ? user.postId
          : workHistory.selectPost,
      );
      setDisableSelectPost(false);
    }
  }, [customsOffices?.content, customsPosts?.content, user]);

  const handleSetReport = (buttonId) => {
    setReportButtonPresed(buttonId);
    setDataModalVisible(true);
  };

  const handleGenerateRaportPostVamal = (aditionalParams) => {
    const submitParameters = {};
    const office = customsOffices?.content.find(
      (el) => el?.id === user?.officeId,
    );

    submitParameters.fromDate = form.getFieldValue('historyFrom')
      ? new Date(form.getFieldValue('historyFrom')).toISOString()
      : subtractHours(24).toISOString();
    submitParameters.toDate = new Date(
      form.getFieldValue('historyTo'),
    ).toISOString();
    submitParameters.userId = currentUserId;
    const inspector = subordinates?.find((el) => el?.id === currentUserId);
    submitParameters.userName = inspector?.fullname;
    submitParameters.postId = (
      office ? office?.customsPosts?.map((el) => el?.id) : [currentPostId]
    ).join(',');
    const post = customsPosts.content?.find((el) => el?.id === currentPostId);
    submitParameters.postName = post?.name || office?.name;
    submitParameters.postCode = post?.code || office?.code;
    if (aditionalParams?.haveCargo) {
      submitParameters.haveCargoParam = aditionalParams.haveCargo.join();
    }

    if (aditionalParams?.vehicleType?.length) {
      submitParameters.vehicleTypeParam = aditionalParams.vehicleType.join();
    } else {
      submitParameters.vehicleTypeParam = '';
    }
    setLoadingLoader(true);

    generatePdfInNewTab('/report/post-vamal-operations', submitParameters)
      .then(() => {
        notification.success({
          message: t('messages.report.generated.success'),
          duration: 3,
        });
      })
      .catch(() => {
        notification.error({
          message: 'A apărut o excepție. Contactați administratorul.',
        });
      })
      .finally(() => setLoadingLoader(false));
  };

  const handleGenerateShiftReport = useCallback(
    (aditionalParams) => {
      const inspector = subordinates?.find((el) => el?.id === currentUserId);
      const post = customsPosts.content?.find((el) => el?.id === currentPostId);

      const shiftDirection = // eslint-disable-next-line
        inCheckbox && outCheckbox
          ? 'INOUT'
          : // eslint-disable-next-line
          inCheckbox
          ? 'IN'
          : outCheckbox
          ? 'OUT'
          : 'NONE';
      const vehicleTypeParam = aditionalParams.vehicleType
        ? aditionalParams.vehicleType
        : [];
      const data = {
        // transactionsDTO,
        postCode: post?.code,
        postId: post?.id,
        inspector: inspector?.fullname,
        shiftDirection,
        shiftFrom: form.getFieldValue('historyFrom')
          ? new Date(form.getFieldValue('historyFrom')).toISOString()
          : subtractHours(48).toISOString(),
        shiftTo: form.getFieldValue('historyTo')
          ? new Date(form.getFieldValue('historyTo')).toISOString()
          : new Date().toISOString(),
        haveCargoParam: aditionalParams.haveCargo,
        vehicleTypeParam,
      };

      const fromDate = form.getFieldValue('historyFrom')
        ? new Date(form.getFieldValue('historyFrom')).toLocaleDateString(
            'md-MD',
          )
        : subtractHours(24).toLocaleDateString('md-MD');

      setLoadingLoader(true);
      generatePdfInNewTab(`/shift-report-pdf`, data)
        .then((pdfFile) => {
          if (pdfFile) {
            uploadFiles([
              new File([pdfFile], `Raport-tură din ${fromDate}.pdf`, {
                type: 'application/pdf',
              }),
            ])
              .then((res) => {
                linkFileReport(res[0].id, data);
              })
              .catch(() => {
                notification.error({
                  message:
                    'A apărut o eroare la salvarea raportului in fișiere',
                });
              });
          }
          notification.success({
            message: t('messages.report.generated.success'),
            duration: 3,
          });
        })
        .catch((err) => {
          if (err) {
            notification.error({
              message:
                err?.response?.data?.message ||
                'A apărut o eroare la generarea raportului',
              description:
                'Verificați daca deja nu a fost creat raport in acest interval, altfel contactați administratorul',
            });
          }
        })
        .finally(() => {
          setLoadingLoader(false);
        });
    },
    [
      t,
      form,
      inCheckbox,
      outCheckbox,
      customsPosts.content,
      currentPostId,
      currentUserId,
      subordinates,
    ],
  );

  const handleGenerate = useCallback(
    (aditionalParams) => {
      const vehicleTypeParam = aditionalParams?.vehicleType
        ? aditionalParams.vehicleType.join()
        : '';
      const inspector = subordinates?.find((el) => el?.id === currentUserId);
      const post = customsPosts.content?.find((el) => el?.id === currentPostId);
      const parametersFromPage = {
        fromDate: form.getFieldValue('historyFrom')
          ? new Date(form.getFieldValue('historyFrom')).toISOString()
          : subtractHours(24).toISOString(),
        toDate: form.getFieldValue('historyTo')
          ? new Date(form.getFieldValue('historyTo')).toISOString()
          : new Date().toISOString(),
        userId: currentUserId,
        userName: inspector.fullname,
        postId: currentPostId,
        postName: post.name,
        postCode: post.code,
        haveCargoParam: aditionalParams?.haveCargo.join(),
        vehicleTypeParam,
      };

      setLoadingLoader(true);

      generatePdfInNewTab('/report/inspector-operations', parametersFromPage)
        .then(() => {
          notification.success({
            message: t('messages.report.generated.success'),
            duration: 3,
          });
        })
        .catch(() => {
          notification.error({
            message: 'A apărut o excepție. Contactați administratorul.',
          });
        })
        .finally(() => setLoadingLoader(false));
    },
    [form, currentPostId, currentUserId, customsPosts.content, subordinates, t],
  );

  const action = useCallback(() => {
    const byId = form.getFieldValue('id');
    const bySysOpe = form.getFieldValue('oldSysOpe');
    const byInsatceId = form.getFieldValue('oldInstanceId');

    if (byId) {
      return getById(byId);
    }
    if (bySysOpe) {
      return getByOldSysOpe(bySysOpe);
    }
    if (byInsatceId) {
      return getByOldInstanceId(byInsatceId);
    }

    return new Promise((resolve) => {
      resolve({ content: null });
    });
  }, [form]);

  const handleGetById = useCallback(() => {
    setTableLoading(true);
    action()
      .then(setContent)
      .catch(() => setContent({ content: null }))
      .finally(() => setTableLoading(false));
  }, [action, setContent]);

  const handleGenerateReport = (param) => {
    switch (reportButtonPresed) {
      case ReportType.CUSTOM_POST:
        handleGenerateRaportPostVamal(param);
        break;
      case ReportType.DETAILED_REPORT:
        handleGenerateShiftReport(param);
        break;
      case ReportType.INSPECTOR_REPORT:
        handleGenerate(param);
        break;
      default:
        break;
    }
  };

  const checkForMinimalDate = () => {
    const oneYearAgo = dayjs().subtract(1, 'year');
    const from = dayjs(form.getFieldValue('historyFrom'));
    const to = dayjs(form.getFieldValue('historyTo'));

    if (oneYearAgo > from) {
      form.setFieldsValue({ historyFrom: oneYearAgo });
    }
    if (oneYearAgo > to) {
      form.setFieldsValue({ historyTo: oneYearAgo.add(1, 'day') });
    }
  };

  return (
    <>
      <div className={`loaderOverlay ${loadingLoader ? 'active' : ''}`}>
        <AppLoader />
      </div>

      <PageHeader
        ghost={false}
        title={
          <div style={{ marginRight: '20px' }}>
            <span>Istoric operațiuni</span>
          </div>
        }
        subTitle={
          <DirectionCheckboxes
            setIsAuto={setIsAuto}
            isAuto={isAuto}
            setIsCargo={setIsCargo}
            isCargo={isCargo}
            user={user}
            setOutCheckbox={setOutCheckbox}
            outCheckbox={outCheckbox}
            setInCheckbox={setInCheckbox}
            inCheckbox={inCheckbox}
            // reload={reload}
            reload={() => {}}
          />
        }
      />
      <Form form={form} layout="vertical">
        <Row style={{ gap: '20px' }}>
          <Col span={6}>
            <Form.Item name="historyFrom" label="Data start operațiune">
              <DatePicker
                format="DD.MM.YYYY HH:mm"
                showTime
                // onChange={reload}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="historyTo" label="Data sfârșit operațiune">
              <DatePicker
                format="DD.MM.YYYY HH:mm"
                showTime
                // onChange={reload}
              />
            </Form.Item>
          </Col>
          {(user?.function === UserFunctions.OFFICE_HEAD ||
            user?.function === UserFunctions.HEAD ||
            user?.function === UserFunctions.POST_HEAD ||
            user?.function === UserFunctions.CENTRAL ||
            user?.function === UserFunctions.MB_TEAM) && (
            <Col span={4} style={{ maxWidth: '100%' }}>
              <Form.Item name="buttonGeneratePost" label=" ">
                <Button
                  type="primary"
                  style={{ marginBottom: '.5rem' }}
                  disabled={
                    (user?.function !== UserFunctions.OFFICE_HEAD &&
                      user?.function !== UserFunctions.CENTRAL &&
                      !currentPostId) ||
                    currentUserId
                  }
                  onClick={() => handleSetReport(ReportType.CUSTOM_POST)}
                  icon={<FileTextOutlined />}
                >
                  {user?.function === UserFunctions.OFFICE_HEAD ||
                  user?.function === UserFunctions.CENTRAL
                    ? `Generează raport tură birou vamal`
                    : `Generează raport tură post vamal`}
                </Button>
              </Form.Item>
            </Col>
          )}
          {hasPermission(permissions, 'SHIFT_REPORT_MANAGEMENT') && (
            <Col>
              <Form.Item name="buttonGenerateShift" label=" ">
                <Button
                  type="primary"
                  style={{ marginBottom: '.5rem' }}
                  disabled={!currentPostId || currentUserId}
                  onClick={() => handleSetReport(ReportType.DETAILED_REPORT)}
                  icon={<FileTextOutlined />}
                >
                  Generează raport tură detaliat
                </Button>
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row style={{ gap: '20px' }}>
          <Col span={6}>
            <Form.Item name="selectPost" label="Post vamal">
              <Select
                style={{ width: '100%' }}
                onSelect={(value) => {
                  setCurrentPostId(value);
                  form.resetFields(['selectUser']);
                  setCurrentUserId(null);
                  // reload();
                }}
                disabled={disableSelectPost}
                allowClear
                onClear={() => {
                  setCurrentPostId(null);
                  form.resetFields(['selectUser']);
                  setCurrentUserId(null);
                  checkForMinimalDate();
                  // reload();
                }}
                showSearch
                filterOption={(input, option) =>
                  option.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) !== -1
                }
              >
                {posts
                  ?.sort((a, b) => a.newCode?.localeCompare(b.newCode))
                  ?.map(
                    (option) =>
                      !option?.deleted && (
                        <Select.Option key={option?.id} value={option?.id}>
                          {`(${option.newCode}) ${option.name}`}
                        </Select.Option>
                      ),
                  )}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="selectUser" label="Inspector">
              <Select
                style={{ width: '100%' }}
                onSelect={(value) => {
                  setCurrentUserId(value);
                  // reload();
                }}
                allowClear
                onClear={() => {
                  setCurrentUserId(null);
                  // reload();
                }}
                showSearch
                filterOption={(input, option) =>
                  option.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) !== -1
                }
              >
                {subordinates.map((option) => (
                  <Select.Option key={option?.id} value={option?.id}>
                    {option.fullname}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col style={{ maxWidth: '100%' }}>
            <Form.Item name="buttonGenerateInspector" label=" ">
              <Button
                type="primary"
                style={{ marginBottom: '.5rem' }}
                disabled={!currentUserId || !currentPostId}
                onClick={() => handleSetReport(ReportType.INSPECTOR_REPORT)}
                icon={<FileTextOutlined />}
              >
                Generează raport tură inspector
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ gap: '20px' }}>
          <Col span={6}>
            <Form.Item name="plateNumber" label="Nr. Auto">
              <InputDebounce
                delay={500}

                // onDebounceChange={reload}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="vinCode" label="Nr. VIN">
              <InputDebounce
                delay={500}
                // onDebounceChange={reload}
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item name="passport" label="Pașaport">
              <InputDebounce
                delay={500}
                // onDebounceChange={reload}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="driverFullName" label="Nume prenume (Șofer)">
              <InputDebounce
                delay={500}
                // onDebounceChange={reload}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ gap: '20px' }}>
          <Col span={6}>
            <Form.Item
              name="transporterPersonCode"
              label="IDNO/IDNP transportator"
            >
              <InputDebounce
                delay={500}
                // onDebounceChange={reload}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="transporterPersonName"
              label="Denumire transportator"
            >
              <InputDebounce delay={500} />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item name="customsRegime" label="Tip operațiune">
              <Select
                style={{ width: '100%' }}
                onChange={(value) => {
                  setCurrentCustomRegime(value || null);
                  // reload();
                }}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) !== -1
                }
              >
                {customsRegimes?.content
                  ?.filter((el) => !el.deleted)
                  .map((option) => (
                    <Select.Option key={option?.id} value={option?.id}>
                      {option.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Button
          type="primary"
          onClick={() => {
            // reloadWithCheck();
            reload();
            setButtonFirstPress(true);
          }}
          icon={<SearchOutlined />}
        >
          Caută
        </Button>
        <Divider plain orientation="left">
          Caută după ID rută
        </Divider>
        <Row style={{ gap: '20px' }}>
          <Form.Item name="id" label="ID sistemul Frontieră">
            <Input
              onChange={() =>
                form.setFieldsValue({ oldInstanceId: null, oldSysOpe: null })
              }
            />
          </Form.Item>
          <Form.Item name="oldSysOpe" label="ID in sistemul vechi">
            <Input
              onChange={() =>
                form.setFieldsValue({ oldInstanceId: null, id: null })
              }
            />
          </Form.Item>
          <Form.Item
            name="oldInstanceId"
            label="ID sistemul Poliția de Frontieră"
          >
            <Input
              onChange={() =>
                form.setFieldsValue({ oldSysOpe: null, id: null })
              }
            />
          </Form.Item>
          <Form.Item label=" ">
            <Button
              type="primary"
              onClick={handleGetById}
              icon={<SearchOutlined />}
            >
              Caută după ID
            </Button>
          </Form.Item>
        </Row>
      </Form>
      <GenerateReportModal
        onOk={handleGenerateReport}
        visible={isDataModalVisible}
        onCancel={() => setDataModalVisible(false)}
        vehicleTypes={vehicleTypes}
      />
      <Table
        className="work-history-table"
        columns={columns}
        rowKey="id"
        loading={loading || tableLoading}
        rowClassName={(record) => {
          return record;
        }}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ x: 500 }}
        sticky={{ offsetHeader: 63 }}
      />
    </>
  );
};

export default WorkHistory;
