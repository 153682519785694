import { useTranslation } from 'react-i18next';
import React, { useCallback } from 'react';
import { Button, notification, Popconfirm, Tooltip } from 'antd';

const ActionItemIcon = ({
  tooltip,
  title,
  message,
  item,
  action,
  reload = () => {},
  disabled = false,
  icon,
  okText,
}) => {
  const { t } = useTranslation();

  const handleAction = useCallback(() => {
    action(item)
      .then(() => message && notification.success({ message, duration: 3 }))
      .finally(() => {
        reload();
      });
  }, [action, item, message, reload]);

  return (
    <Popconfirm
      placement="topLeft"
      title={title}
      disabled={disabled}
      onConfirm={handleAction}
      okText={okText || tooltip}
      cancelText={t('actions.cancel')}
    >
      <Tooltip title={tooltip}>
        <Button shape="circle" type="link" icon={icon} disabled={disabled} />
      </Tooltip>
    </Popconfirm>
  );
};

export default ActionItemIcon;
