import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import { useParams } from 'react-router-dom';
import CustomsPosts from './customsPosts/CustomsPosts';
import CustomsOffices from './customsOffices/CustomsOffices';
import AuthContext, { hasPermission } from '../../components/auth';

const Customs = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { permissions } = user;
  const { key } = useParams();

  return (
    <>
      <Tabs defaultActiveKey={key || 'customs'}>
        {hasPermission(permissions, 'CUSTOMS_POST_LIST') && (
          <Tabs.TabPane tab={t('entity.admin.customsPost._plural')} key="posts">
            <CustomsPosts />
          </Tabs.TabPane>
        )}
        {hasPermission(permissions, 'CUSTOMS_OFFICE_LIST') && (
          <Tabs.TabPane
            tab={t('entity.admin.customsOffice._plural')}
            key="offices"
          >
            <CustomsOffices />
          </Tabs.TabPane>
        )}
      </Tabs>
    </>
  );
};

export default Customs;
