import React, { useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

import useDatasource from '../../../../hooks/useDatasource';

import Column from '../../../../helpers/Columns';
import {
  findAll,
  remove,
} from '../../../../services/taxonomies/mapping-svm-post-to-weighing-post';

import EditItemIcon from '../../../buttons/EditItemIcon';
import DeleteItemIcon from '../../../buttons/DeleteItemIcon';
import AddEntityButton from '../../../buttons/AddEntityButton';
import ViewItemIcon from '../../../buttons/ViewItemIcon';
import AuthContext, { hasPermission } from '../../../auth';
import { useTableScroll } from '../../../../hooks/useTableScroll';

const MappingSvmPostToWeighingPostsList = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { permissions } = user;
  useTableScroll();
  const { loading, pagination, content, handleChange, reload } =
    useDatasource(findAll);

  const columns = useMemo(
    () => [
      Column.text('id', t('entity.admin.mappingSvmPostToWeighingPost.id'), {
        width: 50,
        filter: true,
      }),
      Column.text('code', t('entity.admin.mappingSvmPostToWeighingPost.code'), {
        width: 70,
        filter: true,
      }),
      Column.text('name', t('entity.admin.mappingSvmPostToWeighingPost.name'), {
        width: 150,
        filter: true,
      }),
      Column.text(
        'postSvmId',
        t('entity.admin.mappingSvmPostToWeighingPost.postSvmId'),
        {
          width: 90,
          filter: true,
        },
      ),
      Column.text(
        'postWeighingIds',
        t('entity.admin.mappingSvmPostToWeighingPost.postWeighingIds'),
        {
          width: 150,
          filter: false,
        },
      ),
      Column.date(
        'createdAt',
        t('entity.admin.mappingSvmPostToWeighingPost.createdAt'),
        {
          width: 100,
        },
      ),
      Column.text(
        'createdBy',
        t('entity.admin.mappingSvmPostToWeighingPost.createdBy'),
        {
          width: 100,
        },
      ),
      Column.date(
        'updatedAt',
        t('entity.admin.mappingSvmPostToWeighingPost.updatedAt'),
        {
          width: 100,
        },
      ),
      Column.text(
        'updatedBy',
        t('entity.admin.mappingSvmPostToWeighingPost.updatedBy'),
        {
          width: 100,
        },
      ),
      Column.bool(
        'deleted',
        t('entity.admin.mappingSvmPostToWeighingPost.deleted'),
        {
          width: 100,
          filter: true,
          inverted: true,
          labels: [
            t('table.filter.all'),
            t('entity._.deleted.true'),
            t('entity._.deleted.false'),
            t('entity._.deleted.unknown'),
          ],
        },
      ),
      Column.actions(t('table.actions'), (record) =>
        record.deleted ? (
          <span style={{ textAlign: 'right' }}>
            {hasPermission(permissions, 'TAXONOMIES_VIEW') && (
              <ViewItemIcon
                path={`/admin/taxonomies/mapping-svm-post-to-weighing-post/${record.id}`}
              />
            )}
          </span>
        ) : (
          <span style={{ textAlign: 'right' }}>
            {hasPermission(permissions, 'TAXONOMIES_EDIT') && (
              <EditItemIcon
                path={`/admin/taxonomies/mapping-svm-post-to-weighing-post/${record.id}`}
              />
            )}
            {hasPermission(permissions, 'TAXONOMIES_DELETE') && (
              <DeleteItemIcon
                title={t(
                  'entity.admin.mappingSvmPostToWeighingPost._delete',
                  record,
                )}
                message={t(
                  'entity.admin.mappingSvmPostToWeighingPost._deleted',
                  record,
                )}
                item={record}
                action={remove}
                reload={reload}
              />
            )}
          </span>
        ),
      ),
    ],
    [t, reload, permissions],
  );

  return (
    <>
      <PageHeader
        title={t('entity.admin.mappingSvmPostToWeighingPost._plural')}
        extra={[
          <AddEntityButton
            key="new"
            entity={t('entity.admin.mappingSvmPostToWeighingPost._singular')}
            path="/admin/taxonomies/mapping-svm-post-to-weighing-post/new"
          />,
        ]}
      />
      <Table
        scroll={{ x: 500 }}
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
      />
    </>
  );
};

export default MappingSvmPostToWeighingPostsList;
