import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import TrainTypesList from '../../../../components/admin/taxonomies/trainTypes/TrainTypesList';

const TrainTypes = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="trainTypes">
      <Tabs.TabPane tab={t('entity.admin.trainTypes._plural')} key="trainTypes">
        <TrainTypesList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default TrainTypes;
