import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@ant-design/pro-layout';
import AddEntityButton from '../../../../components/buttons/AddEntityButton';
import TransportUnitsRisksList from '../../../../components/lucrative/risks-actions/transport-units/TransportUnitsRisksList';
import AuthContext, { hasPermission } from '../../../../components/auth';
import AddSMSPersonButton from '../../../../components/lucrative/risks-actions/AddSMSPersonButton';

const TransportUnitsRisks = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { permissions } = user;

  return (
    <>
      <PageHeader
        title={t('entity.lucrative.transport-units._plural')}
        extra={[
          <AddEntityButton
            key="new"
            entity={t('entity.lucrative.transport-units._singular')}
            path="/lucrative/risks-analisys/transport-units/new"
          />,
          hasPermission(permissions, 'TAX_PERSONS_SMS_ADD') && (
            <AddSMSPersonButton />
          ),
        ]}
      />
      <TransportUnitsRisksList />
    </>
  );
};

export default TransportUnitsRisks;
