import React, { useCallback, useState } from 'react';
import { Select } from 'antd';
import InputDebounce from '../../../components/InputDebounce';

const SelectHelper = ({
  initialValue,
  onChange,
  getReference,
  onSelect,
  onSearch,
  ...rest
}) => {
  const [value, setValue] = useState(initialValue);
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClearSuggestion = () => {
    setSuggestions([]);
    setValue('');
  };
  const handleSelectSuggestion = (id) => {
    if (id) {
      const selected = suggestions.find((el) => el.id === id);
      onSelect(selected);
      handleClearSuggestion();
    }
  };

  const handleChange = (e) => {
    if (typeof onChange !== 'undefined' && typeof onChange === 'function') {
      onChange(e.target.value);
    }
    setValue(e.target.value);
  };

  const search = useCallback(() => {
    if (value && value.length > 2 && typeof onSearch === 'function') {
      setLoading(true);
      onSearch(value)
        .then((res) => {
          setSuggestions(res || []);
        })
        .catch(() => {})
        .finally(() => setLoading(false));
    }
  }, [value, onSearch]);

  return (
    <div className="input__helper">
      <>
        <InputDebounce
          initialValue={value}
          delay={500}
          onChange={handleChange}
          onDebounceChange={search}
          autoComplete="off"
          loading={loading}
          {...rest}
        />
        {suggestions && value?.length > 2 ? (
          <Select
            style={{ width: '100%' }}
            onSelect={handleSelectSuggestion}
            allowClear
            open={suggestions.length}
            onClear={handleClearSuggestion}
          >
            {suggestions.map((row) => (
              <Select.Option value={row.id} key={row.id}>
                {row.fullname}
              </Select.Option>
            ))}
          </Select>
        ) : null}
      </>
    </div>
  );
};

export default SelectHelper;
