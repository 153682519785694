import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import RiskTypesList from '../../../../components/admin/taxonomies/riskTypes/RiskTypesList';

const RiskTypes = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="riskTypes">
      <Tabs.TabPane tab={t('entity.admin.riskTypes._plural')} key="riskTypes">
        <RiskTypesList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default RiskTypes;
