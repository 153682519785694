import React, { useCallback, useMemo, useState, useContext } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Form,
  Row,
  Col,
  Space,
  Divider,
  Button,
  Input,
  Table,
  Select,
  notification,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Column from '../../helpers/Columns';
import { FormContextProvider } from '../../hooks/useFormContext';
import useDictionaries from '../../hooks/useDictionaries';
import { getAuthorizationTypes } from '../../services/admin/web-services/anta-authorization-type';
import { getTrafficCountries } from '../../services/admin/web-services/anta-traffic-countries';
import { getTransportAuthorization } from '../../services/admin/web-services/anta-transport-authorization';
import { findAll as findAllAuthorizationTypes } from '../../services/taxonomies/authorization-types';
import { findAll as findAllTrafficCountries } from '../../services/taxonomies/traffic-countries';
import AuthContext, { hasPermission } from '../auth';
import AppLoader from '../auth/AppLoader';
import { useTableScroll } from '../../hooks/useTableScroll';
import {
  numberValidator,
  vinNumberValidator,
} from '../../services/utils/validator/Validator';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const crossingDirections = {
  content: [
    { code: 0, name: 'INTRARE' },
    { code: 1, name: 'IEȘIRE' },
  ],
};

const openNotificationWithIcon = (type: NotificationType, msg, desc) => {
  notification[type]({
    message: msg,
    description: desc,
  });
};

const universale = [
  { id: 1, name: 'Țară terță' },
  { id: 2, name: 'Bilateral' },
  { id: 3, name: 'Tranzit' },
];

const dictionaries = {
  authorizationTypesTaxonomy: findAllAuthorizationTypes,
  trafficCountriesTaxonomy: findAllTrafficCountries,
};

const ANTAServicesForm = () => {
  const [form] = Form.useForm();
  const [authorizationTypes, setAuthorizationTypes] = useState(null);
  const [trafficCountries, setTrafficCountries] = useState(null);
  const [transportAuthorization, setTransportAuthorization] = useState(null);
  const [loadingLoader, setLoadingLoader] = useState(false);
  const { user } = useContext(AuthContext);
  const { permissions } = user;

  const [{ authorizationTypesTaxonomy, trafficCountriesTaxonomy }] =
    useDictionaries(dictionaries);
  useTableScroll();
  const columnsAuthorizationTypes = useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Cod',
        dataIndex: 'code',
        key: 'code',
      },
      {
        title: 'Nume',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Remarca',
        dataIndex: 'remark',
        key: 'remark',
      },
      {
        title: 'Conditii utilizare',
        dataIndex: 'usageConditions',
        key: 'usageConditions',
      },
    ],
    [],
  );

  const columnsTrafficCountries = useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Cod',
        dataIndex: 'code',
        key: 'code',
      },
      {
        title: 'Nume',
        dataIndex: 'name',
        key: 'name',
      },
    ],
    [],
  );

  const columnsTransportAuthorization = useMemo(
    () => [
      Column.text('authorizationMethodOfUse', 'Metoda utilizare', {
        width: 300,
        filter: true,
      }),
      Column.text('countryCode', 'Cod țară', {
        width: 300,
        filter: true,
      }),
      Column.text('mixedAssembly', 'Ansamblul mixt', {
        width: 300,
        filter: true,
      }),
      Column.text('number', 'Număr', {
        width: 50,
      }),
      Column.text('status', 'Statut', {
        width: 200,
      }),
      Column.text('trailerRegPlateNumber', 'Număr de înmatriculare', {
        width: 300,
        filter: true,
      }),
      Column.text('transportCompanyName', 'Denumirea companiei', {
        width: 300,
        filter: true,
      }),
      Column.text('truckRegPlateNumber', 'Număr de înmatriculare remorcă', {
        width: 300,
        filter: true,
      }),
      Column.text('type', 'Tip', {
        width: 50,
      }),
      Column.date('validFrom', 'Data eliberare', {
        width: 250,
      }),
      Column.date('validTo', 'Data expirare', {
        width: 250,
      }),
      Column.text('year', 'Anul autorizației', {
        width: 20,
      }),
    ],
    [],
  );

  const columnsEmissions = useMemo(
    () => [
      {
        title: 'Categorie EURO',
      },
    ],
    [],
  );
  let uniqueId = 0;

  const handleGetAuthorizationTypes = useCallback(() => {
    setLoadingLoader(true);
    setTrafficCountries(null);
    setTransportAuthorization(null);
    getAuthorizationTypes().then(
      (auth) => {
        setLoadingLoader(false);
        setAuthorizationTypes(auth);
      },
      () => {
        setLoadingLoader(false);
        openNotificationWithIcon(
          'error',
          'Eroare',
          'Eroare la descărcarea datelor...',
        );
      },
    );
  }, []);

  const handleGetTrafficCountries = useCallback(() => {
    setLoadingLoader(true);
    setAuthorizationTypes(null);
    setTransportAuthorization(null);
    getTrafficCountries().then(
      (cou) => {
        setLoadingLoader(false);
        setTrafficCountries(cou);
      },
      () => {
        setLoadingLoader(false);
        openNotificationWithIcon(
          'error',
          'Eroare',
          'Eroare la descărcarea datelor...',
        );
      },
    );
  }, []);

  const handleGetTransportAuthorization = useCallback(() => {
    setLoadingLoader(true);
    if (form.getFieldsValue()) {
      setTrafficCountries(null);
      setAuthorizationTypes(null);
      getTransportAuthorization(form.getFieldsValue()).then(
        (trns) => {
          setLoadingLoader(false);
          setTransportAuthorization([trns]);
        },
        () => {
          setLoadingLoader(false);
          openNotificationWithIcon(
            'error',
            'Eroare',
            'Eroare la căutarea datelor...',
          );
        },
      );
    }
  }, [form]);

  return (
    <>
      <div className={`loaderOverlay ${loadingLoader ? 'active' : ''}`}>
        <AppLoader />
      </div>

      <FormContextProvider form={form}>
        <Form form={form} layout="vertical">
          {hasPermission(permissions, 'GET_AUTHORIZATION_TYPES_ANTA_WS') && (
            <>
              <Divider plain>ANTA descărcare tip autorizații</Divider>
              <Form.Item>
                <Space>
                  <Button
                    type="primary"
                    onClick={() => handleGetAuthorizationTypes()}
                    icon={<SearchOutlined />}
                  >
                    Descărcare
                  </Button>
                </Space>
              </Form.Item>
            </>
          )}
          {hasPermission(permissions, 'GET_TRAFFIC_COUNTRIES_ANTA_WS') && (
            <>
              <Divider plain>ANTA descărcare țări</Divider>
              <Form.Item>
                <Space>
                  <Button
                    type="primary"
                    onClick={() => handleGetTrafficCountries()}
                    icon={<SearchOutlined />}
                  >
                    Descărcare
                  </Button>
                </Space>
              </Form.Item>
            </>
          )}
          {hasPermission(
            permissions,
            'GET_TRANSPORT_AUTHORIZATION_ANTA_WS',
          ) && (
            <>
              <Divider plain>ANTA căutare autorizație transport</Divider>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
                <Col xs={24} sm={24} md={10} lg={8} xl={8}>
                  <Form.Item
                    label="Număr autorizație"
                    name="authorizationNumber"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="Tip autorizație" name="authorizationType">
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) !== -1
                      }
                    >
                      {(authorizationTypesTaxonomy?.content || []).map(
                        (option) =>
                          !option?.deleted && (
                            <Select.Option key={option.id} value={option.code}>
                              {`${option.name}`}
                            </Select.Option>
                          ),
                      )}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Metodă autorizație"
                    name="authorizationMethodOfUse"
                  >
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) !== -1
                      }
                    >
                      {universale.map((option) => (
                        <Select.Option key={option.id} value={option.id}>
                          {`${option.name}`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Cod țară" name="countryCode">
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) !== -1
                      }
                    >
                      {(trafficCountriesTaxonomy?.content || []).map(
                        (option) =>
                          !option?.deleted && (
                            <Select.Option key={option.id} value={option.code}>
                              {`${option.name}`}
                            </Select.Option>
                          ),
                      )}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Anul" name="year">
                    <Input
                      onChange={(e) => {
                        form.setFieldsValue({
                          year: numberValidator(e.target.value, 4),
                        });
                      }}
                    />
                  </Form.Item>
                  <Form.Item label="Direcție" name="crossingDirection">
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) !== -1
                      }
                    >
                      {crossingDirections.content.map((option) => (
                        <Select.Option key={option.code} value={option.code}>
                          {`${option.name}`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Număr VIN"
                    name="vin"
                    rules={[
                      {
                        min: 17,
                        message: 'Minim 17 caractere',
                      },
                    ]}
                  >
                    <Input
                      maxLength={17}
                      onChange={(e) => {
                        form.setFieldsValue({
                          vin: vinNumberValidator(e.target.value),
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Space>
                  <Button
                    type="primary"
                    onClick={() => handleGetTransportAuthorization()}
                    icon={<SearchOutlined />}
                  >
                    Căutare
                  </Button>
                </Space>
              </Form.Item>
            </>
          )}
          {authorizationTypes &&
            Array.isArray(authorizationTypes) &&
            authorizationTypes.length > 0 && (
              <>
                <Divider plain>Rezultat căutare tip autorizații</Divider>
                <Table
                  columns={columnsAuthorizationTypes}
                  dataSource={authorizationTypes}
                  pagination={false}
                  rowKey="id"
                  size="small"
                />
              </>
            )}
          {Array.isArray(trafficCountries) && trafficCountries.length > 0 && (
            <>
              <Divider plain>Rezultat descărcare țări</Divider>
              <Table
                columns={columnsTrafficCountries}
                dataSource={trafficCountries}
                pagination={false}
                rowKey="id"
                size="small"
              />
            </>
          )}
          {Array.isArray(transportAuthorization) &&
            transportAuthorization.length > 0 && (
              <>
                <Divider plain>
                  Rezultat descărcare autorizație transport
                </Divider>
                <Table
                  columns={columnsTransportAuthorization}
                  dataSource={transportAuthorization}
                  pagination={false}
                  rowKey="number"
                  size="small"
                  expandable={{
                    expandedRowRender: (record) => (
                      <>
                        <PageHeader title="Categorii EURO" />
                        <Table
                          columns={columnsEmissions}
                          dataSource={record.emissionStandards.string}
                          pagination={false}
                          rowKey={() => {
                            uniqueId += 1;
                            return uniqueId;
                          }}
                          size="small"
                        />
                      </>
                    ),
                    rowExpandable: (record) =>
                      record &&
                      record.emissionStandards &&
                      record.emissionStandards.string &&
                      record.emissionStandards.string.length > 0,
                  }}
                />
              </>
            )}
        </Form>
      </FormContextProvider>
    </>
  );
};

export default ANTAServicesForm;
