import React from 'react';

import { useEditPage } from '../../../hooks/useEditPage';

import {
  findOne,
  create,
  update,
} from '../../../services/admin/days-correction';
import AppLoader from '../../../components/auth/AppLoader';
import EditDaysCorrectionForm from '../../../components/admin/daysCorections/EditDaysCorrectionForm';

const initial = {};

const EditDaysCorrections = () => {
  const [correction, handleSubmit, handleCancel, errors, loading] = useEditPage(
    {
      initial,
      existent: findOne,
      pushBackOnError: false,
      goBackPath: '/correct-days',
      onCreate: create,
      onUpdate: update,
    },
  );

  return (
    <>
      <div className={`loaderOverlay ${loading ? 'active' : ''}`}>
        <AppLoader />
      </div>
      {correction !== undefined && (
        <EditDaysCorrectionForm
          correction={correction}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          errors={errors}
        />
      )}
    </>
  );
};

export default EditDaysCorrections;
