import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

import useDatasource from '../../../hooks/useDatasource';

import { findAll, remove } from '../../../services/admin/users/customs-tracks';

import Column from '../../../helpers/Columns';

import EditItemIcon from '../../buttons/EditItemIcon';
import DeleteItemIcon from '../../buttons/DeleteItemIcon';
import AddEntityButton from '../../buttons/AddEntityButton';
import ViewItemIcon from '../../buttons/ViewItemIcon';
import { useTableScroll } from '../../../hooks/useTableScroll';

const directions = {
  content: [
    { id: 'IN', code: 'IN', name: 'INTRARE' },
    { id: 'OUT', code: 'OUT', name: 'IEȘIRE' },
  ],
};

const CustomsTracksList = () => {
  const { t } = useTranslation();
  useTableScroll();
  const { loading, pagination, content, handleChange, reload } =
    useDatasource(findAll);

  const columns = useMemo(
    () => [
      Column.text('code', t('entity.admin.customsTrack.code'), {
        width: 100,
        filter: true,
      }),
      Column.text('name', t('entity.admin.customsTrack.name'), {
        width: 100,
        filter: true,
      }),
      Column.dictionary(
        'direction',
        t('entity.admin.customsTrack.direction'),
        directions,
        {
          width: 100,
          filter: true,
        },
      ),
      Column.date('createdAt', t('entity.admin.customsTrack.createdAt'), {
        width: 100,
        filter: true,
        format: 'DD-MM-YYYY HH:mm:ss',
      }),
      Column.text('createdBy', t('entity.admin.customsTrack.createdBy'), {
        width: 100,
        filter: true,
      }),
      Column.date('updatedAt', t('entity.admin.customsTrack.updatedAt'), {
        width: 100,
        filter: true,
        format: 'DD-MM-YYYY HH:mm:ss',
      }),
      Column.text('updatedBy', t('entity.admin.customsTrack.updatedBy'), {
        width: 100,
        filter: true,
      }),
      Column.bool('deleted', t('entity.admin.customsTrack.deleted'), {
        width: 100,
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity._.deleted.true'),
          t('entity._.deleted.false'),
          t('entity._.deleted.unknown'),
        ],
      }),
      Column.actions(t('table.actions'), (record) =>
        record.deleted ? (
          <span style={{ textAlign: 'right' }}>
            <ViewItemIcon path={`/admin/customs-posts/tracks/${record.id}`} />
          </span>
        ) : (
          <span style={{ textAlign: 'right' }}>
            <EditItemIcon path={`/admin/customs-posts/tracks/${record.id}`} />
            <DeleteItemIcon
              title={t('entity.admin.customsTrack._delete', record)}
              message={t('entity.admin.customsTrack._deleted', record)}
              item={record}
              action={remove}
              reload={reload}
            />
          </span>
        ),
      ),
    ],
    [t, reload],
  );

  return (
    <>
      <PageHeader
        title={t('entity.admin.customsTrack._plural')}
        extra={[
          <AddEntityButton
            key="new"
            entity={t('entity.admin.customsTrack._singular')}
            path="/admin/customs-posts/tracks/new"
          />,
        ]}
      />
      <Table
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
      />
    </>
  );
};

export default CustomsTracksList;
