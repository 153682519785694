import React from 'react';

import { useEditPage } from '../../../hooks/useEditPage';

import { findOne } from '../../../services/admin/users/permissions';

import ViewPermissionForm from '../../../components/admin/users/ViewPermissionForm';
import AppLoader from '../../../components/auth/AppLoader';

const initial = {};

const ViewPermission = () => {
  const [permission, handleSubmit, handleCancel, , loading] = useEditPage({
    initial,
    existent: findOne,
    goBackPath: `/admin/users/${'permissions'}`,
  });

  return (
    <>
      <div className={`loaderOverlay ${loading ? 'active' : ''}`}>
        <AppLoader />
      </div>
      {permission !== undefined && (
        <ViewPermissionForm
          permission={permission}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </>
  );
};

export default ViewPermission;
