import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import CustomsRegimeList from '../../../../components/admin/taxonomies/customsRegime/CustomsRegimeList';

const CustomsRegime = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="customsRegime">
      <Tabs.TabPane
        tab={t('entity.admin.customsRegime._plural')}
        key="customsRegime"
      >
        <CustomsRegimeList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default CustomsRegime;
