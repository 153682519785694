import React, { useCallback, useEffect, useState } from 'react';
import { notification } from 'antd';
import { findOne as findUserById } from '../../services/admin/users/users';

const PhysicalPersonHeader = ({ inspectorId, post }) => {
  const [inspector, setInspector] = useState({});

  useEffect(() => {
    if (inspectorId) {
      findUserById(inspectorId)
        .then((res) => setInspector(res))
        .catch(() => {
          notification.error({
            message: 'A apărut o eroare la extragerea datelor inspectorului',
            description: 'Reporniți pagina.',
          });
        });
    }
  }, [inspectorId]);

  const getInspectorName = useCallback(() => {
    return inspector?.id
      ? `( Inspector: ${inspector?.fullname}${!post?.code ? ' )' : ''}`
      : '';
  }, [inspector?.id, inspector?.fullname, post?.code]);

  const getPostName = useCallback(() => {
    return post?.newCode
      ? `${!inspector?.id ? '( ' : ''}Post Vamal: (${post?.newCode}) ${
          post?.name
        } )`
      : '';
  }, [post?.newCode, inspector?.id, post?.name]);

  return (
    <div
      style={{
        display: 'flex',
        gap: '20px',
        alignItems: 'center',
        marginBottom: '5px',
        justifyContent: 'center',
      }}
    >
      <div style={{ fontSize: '1.1rem', fontWeight: '600' }}>
        Istoric Operațiuni:
      </div>
      <div style={{ fontSize: '1.1rem', fontWeight: '500' }}>
        {getInspectorName()}
      </div>
      <div style={{ fontSize: '1.1rem', fontWeight: '500' }}>
        {getPostName()}
      </div>
    </div>
  );
};
export default PhysicalPersonHeader;
