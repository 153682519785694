import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, notification, Popconfirm, Tooltip } from 'antd';
import { RollbackOutlined } from '@ant-design/icons';

const RecoveryItemIcon = ({
  title,
  message,
  item,
  action,
  reload,
  setLoadingLoader = null,
  disabled = false,
}) => {
  const { t } = useTranslation();

  const handleRecovery = useCallback(() => {
    if (setLoadingLoader !== null && typeof setLoadingLoader === 'function') {
      setLoadingLoader(true);
    }
    action(item)
      .then(() => message && notification.success({ message, duration: 3 }))
      .finally(() => {
        reload();
        if (
          setLoadingLoader !== null &&
          typeof setLoadingLoader === 'function'
        ) {
          setLoadingLoader(false);
        }
      });
  }, [action, item, message, reload, setLoadingLoader]);

  return (
    <Popconfirm
      placement="topLeft"
      title={title}
      disabled={disabled}
      onConfirm={handleRecovery}
      okText={t('actions.recovery')}
      cancelText={t('actions.cancel')}
    >
      <Tooltip title={t('actions.recovery')}>
        <Button
          shape="circle"
          type="link"
          icon={<RollbackOutlined />}
          disabled={disabled}
        />
      </Tooltip>
    </Popconfirm>
  );
};

export default RecoveryItemIcon;
