import { genericService, postFiles, get, post } from '../../helpers/api';

export const SERVICE_URI = '/backend/api';
export const BASE_URI = '/v1/trezoraria-document';
export const BASE_URI_CLIENT_BALANCE = '/v1/client-balance';

export const { create, update, findAll, remove, findOne } = genericService(
  SERVICE_URI,
  BASE_URI,
);

export const uploadFiles = async (files) => {
  if (files && files.length === 0) {
    return [];
  }
  const data = new FormData();
  files.forEach((file) => data.append('files', file, file.name));
  return postFiles(`${SERVICE_URI}${BASE_URI}/`, data).then((res) =>
    res.json(),
  );
};

export const getClientBalance = (idno) =>
  get(`${SERVICE_URI}${BASE_URI_CLIENT_BALANCE}/${idno}`);

export const updateClientBalance = (idno, id) =>
  post(`${SERVICE_URI}${BASE_URI_CLIENT_BALANCE}/${idno}/services/${id}`);
