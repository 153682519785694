import React from 'react';
import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';

const EditTransaction = ({ setEditMode, editMode, disabled }) => {
  return (
    <>
      {editMode ? (
        <Button
          className="edit__transaction-btn cancellation"
          type="button"
          disabled={disabled}
          icon={<EditOutlined />}
          onClick={() => {
            setEditMode(false);
          }}
        >
          Anulare corectarea datelor
        </Button>
      ) : (
        <Button
          className="edit__transaction-btn"
          type="button"
          disabled={disabled}
          icon={<EditOutlined />}
          onClick={() => {
            setEditMode(true);
          }}
        >
          Corectare date
        </Button>
      )}
    </>
  );
};

export default EditTransaction;
