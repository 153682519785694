import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Input, Row, Col, Space, Checkbox, Divider, Select } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

import SaveButton from '../../../buttons/SaveButton';
import CancelButton from '../../../buttons/BackButton';

import useFormErrors from '../../../../hooks/useFormErrors';
import { useTableScroll } from '../../../../hooks/useTableScroll';
import { findAll as findAllAuthorizationTypes } from '../../../../services/taxonomies/authorization-types';
import useDictionaries from '../../../../hooks/useDictionaries';
import {
  dotValidator,
  floatValidator,
  maxLengthValidator,
  numberValidator,
} from '../../../../services/utils/validator/Validator';

const usageMethods = [
  { id: 1, name: 'Țară terță' },
  { id: 2, name: 'Bilateral' },
  { id: 3, name: 'Tranzit' },
];

const dictionaries = {
  authorizationTypesList: findAllAuthorizationTypes,
};

const EditTrafficCountryForm = ({ post, onSubmit, onCancel, errors }) => {
  const { t } = useTranslation();

  const [{ authorizationTypesList }] = useDictionaries(dictionaries);

  const [
    isAuthorisationNotRequiredChecked,
    setIsAuthorisationNotRequiredChecked,
  ] = useState(post.authorisationNotRequired);

  const [form] = Form.useForm();
  useFormErrors(form, errors);
  useTableScroll();

  useEffect(() => {
    const additionalUsageMethod =
      post.additionalUsageMethod?.split(',')?.map((el) => Number(el)) || [];
    const authorizationTypes =
      post.authorizationTypes?.split(',')?.map((el) => String(el)) || [];
    form.setFieldsValue({
      ...post,
      additionalUsageMethod,
      authorizationTypes,
    });
  }, [post, form]);

  const handleSubmit = (data) => {
    const additionalUsageMethod = data.additionalUsageMethod?.join(',') || null;
    const authorizationTypes = data.authorizationTypes?.join(',') || null;
    onSubmit({
      ...data,
      additionalUsageMethod,
      authorizationTypes,
    });
  };

  const handleNoAuthorisationCheckbox = (e) => {
    setIsAuthorisationNotRequiredChecked(e.target.checked);
    form.setFieldsValue({
      additionalWithCargo: null,
      additionalMaxGoodsWeight: null,
      additionalMaxWeight: null,
      additionalUsageMethod: [],
    });
  };

  return (
    <>
      <PageHeader title={t('entity.admin.trafficCountry._singular')} />
      <Form
        form={form}
        layout="vertical"
        initialValues={post}
        onFinish={handleSubmit}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={t(
                'entity.admin.trafficCountry.thirdPartyTrafficCountryId',
              )}
              name="thirdPartyTrafficCountryId"
            >
              <Input
                disabled={post.deleted}
                onChange={(e) => {
                  form.setFieldsValue({
                    thirdPartyTrafficCountryId: numberValidator(
                      e.target.value,
                      19,
                    ),
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label={t('entity.admin.trafficCountry.code')}
              name="code"
            >
              <Input
                minLength={3}
                disabled={post.deleted}
                onChange={(e) => {
                  form.setFieldsValue({
                    code: maxLengthValidator(e.target.value, 20),
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label={t('entity.admin.trafficCountry.name')}
              name="name"
            >
              <Input
                disabled={post.deleted}
                onChange={(e) => {
                  form.setFieldsValue({
                    name: maxLengthValidator(e.target.value, 100),
                  });
                }}
              />
            </Form.Item>

            <Form.Item
              label={t('entity.admin.trafficCountry.authorizationTypes')}
              name="authorizationTypes"
            >
              <Select
                mode="multiple"
                disabled={post.deleted}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) !==
                  -1
                }
              >
                {authorizationTypesList?.content?.map(
                  (option) =>
                    !option.deleted && (
                      <Select.Option key={option.id} value={option.code}>
                        {option.name}
                      </Select.Option>
                    ),
                )}
              </Select>
            </Form.Item>

            <Form.Item name="authorisationNotRequired" valuePropName="checked">
              <Checkbox
                defaultChecked="false"
                disabled={post.deleted}
                onChange={handleNoAuthorisationCheckbox}
              >
                {t('entity.admin.trafficCountry.authorisationNotRequired')}
              </Checkbox>
            </Form.Item>
            <Divider plain>
              <div style={{ fontWeight: 600 }}>
                Condiții speciale (fără autorizație)
              </div>
            </Divider>

            <Form.Item
              label={t('entity.admin.trafficCountry.additionalUsageMethod')}
              name="additionalUsageMethod"
            >
              <Select
                mode="multiple"
                disabled={post.deleted || !isAuthorisationNotRequiredChecked}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) !==
                  -1
                }
              >
                {usageMethods.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label={t('entity.admin.trafficCountry.additionalMaxWeight')}
              name="additionalMaxWeight"
            >
              <Input
                disabled={post.deleted || !isAuthorisationNotRequiredChecked}
                onChange={(e) => {
                  form.setFieldsValue({
                    additionalMaxWeight: floatValidator(
                      dotValidator(e.target.value),
                      7,
                      2,
                    ),
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label={t('entity.admin.trafficCountry.additionalMaxGoodsWeight')}
              name="additionalMaxGoodsWeight"
            >
              <Input
                disabled={post.deleted || !isAuthorisationNotRequiredChecked}
                onChange={(e) => {
                  form.setFieldsValue({
                    additionalMaxGoodsWeight: floatValidator(
                      dotValidator(e.target.value),
                      7,
                      2,
                    ),
                  });
                }}
              />
            </Form.Item>

            <Form.Item name="additionalWithCargo" valuePropName="checked">
              <Checkbox
                defaultChecked="false"
                disabled={post.deleted || !isAuthorisationNotRequiredChecked}
              >
                {t('entity.admin.trafficCountry.additionalWithCargo')}
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Space>
            <SaveButton disabled={post.deleted} />
            <CancelButton onCancel={onCancel} />
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default EditTrafficCountryForm;
