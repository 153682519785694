import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Tooltip,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import lodash from 'lodash';
import Column from '../../helpers/Columns';
import DeleteItemIcon from '../buttons/DeleteItemIcon';
import EditItemIcon from '../buttons/EditItemIcon';
import { findAllPayments } from '../../services/taxonomies/customs-payments';
import useDictionaries from '../../hooks/useDictionaries';
import { numberValidator } from '../../services/utils/validator/Validator';

const dictionaries = {
  customsPayments: findAllPayments,
};

const EditModal = ({
  visible,
  onSave,
  onCancel,
  initialData,
  customsPayments,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [selectedPayment, setSelectedPayment] = useState(null);
  const [paymentSubType, setPaymentSubType] = useState(null);

  const handleCalcPrice = lodash.debounce(() => {
    let finalCost = 0;
    const quantity = Number(form.getFieldValue('volumeOfServices') || 0);
    if (paymentSubType !== null && quantity >= 1) {
      if (quantity > 0 && quantity <= 60) {
        finalCost = paymentSubType.firstHourPayment;
      } else {
        // Do not count first hour (-60); transform to minutes
        const halfs = Math.ceil(((quantity - 60) / 60) * 2);
        finalCost =
          paymentSubType.firstHourPayment +
          halfs * paymentSubType.afterHalfHourPayment;
      }
    } else {
      const valuePerUnit = Number(form.getFieldValue('unitCosts') || 0);
      finalCost = quantity * valuePerUnit;
    }

    form.setFieldsValue({ valueOfServices: finalCost });
  }, 400);

  const handleSelectPayment = useCallback(
    (id) => {
      form.resetFields();
      form.setFieldsValue({ customPaymentId: id });

      setPaymentSubType(null);
      const payment = customsPayments?.content?.find((el) => el.id === id);

      if (payment?.subTypes?.length > 0) {
        form.setFieldsValue({
          nameOfServicesProvided: payment.name,
        });
        setSelectedPayment(payment);
        return;
      }
      setSelectedPayment(null);
      form.setFieldsValue({
        nameOfServicesProvided: payment.name,
        feesAmount: `${payment.unitPrice} lei/${payment.unitDenomination}`,
        unitCosts: `${payment.unitPrice}`,
      });

      handleCalcPrice();
    },
    [handleCalcPrice, form, customsPayments?.content],
  );
  const handleSelectPaymentType = useCallback(
    (subTypeId) => {
      if (selectedPayment) {
        const subType = selectedPayment.subTypes?.find(
          (el) => el.id === subTypeId,
        );
        setPaymentSubType(subType);

        form.setFieldsValue({
          feesAmount: `${subType.firstHourPayment} lei/prima ora, ${subType.afterHalfHourPayment} per următoarele 30 minute suplimentare`,
          unitCosts: `${subType.firstHourPayment}`,
        });

        handleCalcPrice();
      }
    },
    [form, handleCalcPrice, selectedPayment],
  );

  useEffect(() => {
    if (initialData) {
      if (initialData?.customPaymentId) {
        handleSelectPayment(initialData?.customPaymentId);
        if (initialData?.customPaymentSubTypeId) {
          handleSelectPaymentType(initialData?.customPaymentSubTypeId);
        }
      }

      const formattedInitialData = {
        ...initialData,
        docDate: initialData.docDate ? dayjs(initialData.docDate) : null,
      };
      form.setFieldsValue(formattedInitialData);
    }
  }, [
    form,
    initialData,
    customsPayments?.content,
    handleSelectPayment,
    handleSelectPaymentType,
  ]);

  const handleSave = () => {
    const recordToSave = form.getFieldsValue();

    if (!recordToSave?.id && !recordToSave?.tempId) {
      recordToSave.tempId = 'temp'.concat(new Date().getTime().toString());
    }

    if (onSave && typeof onSave === 'function') {
      onSave(recordToSave);
      form.resetFields();
    }
  };
  return (
    <Modal
      visible={visible}
      title={
        initialData?.id || initialData?.tempId
          ? 'Editare serviciu special'
          : 'Adăugare serviciu special'
      }
      width={1000}
      okText="Ok"
      cancelText="Înapoi"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      centered
      onOk={handleSave}
    >
      <Form form={form} layout="vertical">
        <Row gutter={15}>
          <Col span={12}>
            <Form.Item
              name="customPaymentId"
              label={t('entity.specialServices.nameOfServicesProvided')}
            >
              <Select
                allowClear
                showSearch
                onClear={() => {
                  form.resetFields();
                  setSelectedPayment(null);
                }}
                onSelect={handleSelectPayment}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) !==
                  -1
                }
              >
                {customsPayments?.content
                  ?.filter((el) => !el.deleted)
                  ?.sort((a, b) => a.orderNumber - b.orderNumber)
                  ?.map((obj) => (
                    <Select.Option key={obj.id} value={obj.id}>
                      {obj.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          {selectedPayment !== null ? (
            <Col span={12}>
              <Form.Item
                name="customPaymentSubTypeId"
                label={t('entity.specialServices.paymentSybType')}
              >
                <Select
                  allowClear
                  showSearch
                  onClear={() => setPaymentSubType(null)}
                  onSelect={handleSelectPaymentType}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) !== -1
                  }
                >
                  {selectedPayment?.subTypes?.map((obj) => (
                    <Select.Option key={obj.id} value={obj.id}>
                      {obj.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          ) : null}
          <Col span={selectedPayment ? 24 : 12}>
            <Form.Item
              name="feesAmount"
              label={t('entity.specialServices.feesAmount')}
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={12}>
            <Form.Item
              name="volumeOfServices"
              label={
                paymentSubType
                  ? t('entity.specialServices.volumeOfServices').concat(
                      ' (minute)',
                    )
                  : t('entity.specialServices.volumeOfServices')
              }
            >
              <Input
                onChange={(e) => {
                  form.setFieldsValue({
                    volumeOfServices: numberValidator(e.target.value, 7),
                  });
                  handleCalcPrice(e);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="unitCosts"
              label={t('entity.specialServices.unitCosts')}
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={24}>
            <Form.Item
              name="valueOfServices"
              label={t('entity.specialServices.valueOfServices')}
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        {/*  DO NOT DELETE id noStyle it represent entity id currently on edit */}
        <Form.Item name="id" noStyle />
        <Form.Item name="tempId" noStyle />
      </Form>
    </Modal>
  );
};

const ServiceRecordTable = ({
  initial = [],
  onServiceRecordsUpdate,
  disabled,
}) => {
  const { t } = useTranslation();
  const [serviceRecords, setServiceRecords] = useState(initial);
  const sortedServiceRecords = useMemo(() => {
    return serviceRecords.slice().sort((a, b) => b.nr - a.nr);
  }, [serviceRecords]);

  const [editingServiceRecord, setEditingServiceRecord] = useState(null);

  const [{ customsPayments }] = useDictionaries(dictionaries);

  const tableFooter = (addServiceRecord) => {
    return (
      <Button
        onClick={addServiceRecord}
        type="primary"
        style={{ marginLeft: 'auto', display: 'block' }}
        icon={<PlusOutlined />}
        disabled={disabled}
      >
        Adaugare serviciu special
      </Button>
    );
  };

  useEffect(() => {
    if (
      onServiceRecordsUpdate &&
      typeof onServiceRecordsUpdate === 'function'
    ) {
      onServiceRecordsUpdate(serviceRecords);
    }
  }, [serviceRecords, onServiceRecordsUpdate]);

  const handleEdit = (record) => {
    setEditingServiceRecord(record);
  };

  const addServiceRecord = () => {
    handleEdit({});
  };

  const deleteServiceRecord = useCallback(
    (id) => {
      setServiceRecords((currentRecords) => {
        const updatedRecords = currentRecords.filter(
          (record) => record.id !== id && record.tempId !== id,
        );
        if (onServiceRecordsUpdate) {
          onServiceRecordsUpdate(updatedRecords);
        }
        return updatedRecords;
      });
    },
    [onServiceRecordsUpdate],
  );

  const saveServiceRecord = useCallback((entity) => {
    setServiceRecords((prev) => [
      ...prev.filter(
        (el) => el.id !== entity.id || el.tempId !== entity.tempId,
      ),
      entity,
    ]);
    setEditingServiceRecord(null);
  }, []);

  const columns = useMemo(() => {
    return [
      {
        title: 'Nr',
        key: 'nr',
        render: (_, __, index) => index + 1,
        width: 50,
      },
      Column.other(
        'nameOfServicesProvided',
        t('entity.specialServices.nameOfServicesProvided'),
        (code, row) => {
          const payment = customsPayments?.content?.find(
            (el) => el.id === row.customPaymentId,
          );

          const subType = payment?.subTypes?.find(
            (el) => el.id === row.customPaymentSubTypeId,
          );

          const text = payment?.name?.concat(`\n\n${subType?.name || ''}`);
          let title = text;
          if (title?.length > 200) {
            title = `${title.substring(0, 200)}...`;
          }
          return <Tooltip title={text}>{title}</Tooltip>;
        },
        {
          width: 400,
          sort: false,
        },
      ),
      Column.text('feesAmount', t('entity.specialServices.feesAmount'), {
        width: 30,
        sort: false,
      }),
      Column.text(
        'volumeOfServices',
        t('entity.specialServices.volumeOfServices'),
        {
          width: 30,
          sort: false,
        },
      ),
      Column.text('unitCosts', t('entity.specialServices.unitCosts'), {
        width: 30,
        sort: false,
      }),
      Column.text(
        'valueOfServices',
        t('entity.specialServices.valueOfServices'),
        {
          width: 30,
          sort: false,
        },
      ),
      Column.actions(t('table.actions'), (record) => (
        <span style={{ textAlign: 'right' }}>
          <Tooltip title="Editare">
            <EditItemIcon
              action={() =>
                handleEdit({
                  ...record,
                })
              }
              disabled={record.deleted || disabled}
            />
          </Tooltip>
          <DeleteItemIcon
            title={t('entity.specialServices._delete')}
            item={record}
            isPromise={false}
            action={() => deleteServiceRecord(record.id || record.tempId)}
            disabled={disabled}
          />
        </span>
      )),
    ];
  }, [t, deleteServiceRecord, customsPayments?.content, disabled]);

  return (
    <>
      <Table
        columns={columns}
        dataSource={sortedServiceRecords.map((record) => ({
          ...record,
          key: record.nr || record.tempId,
        }))}
        rowKey="nr"
        pagination={false}
        footer={() => tableFooter(addServiceRecord)}
      />
      {editingServiceRecord && (
        <EditModal
          visible={!!editingServiceRecord}
          initialData={editingServiceRecord}
          onSave={saveServiceRecord}
          onCancel={() => setEditingServiceRecord(null)}
          customsPayments={customsPayments}
        />
      )}
    </>
  );
};

export default ServiceRecordTable;
