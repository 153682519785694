import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Input, Modal, notification, Row, Space, Table } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import useFormErrors from '../../../../hooks/useFormErrors';
import { useTableScroll } from '../../../../hooks/useTableScroll';
import {
  maxLengthValidator,
  numberValidator,
} from '../../../../services/utils/validator/Validator';
import SaveButton from '../../../buttons/SaveButton';
import CancelButton from '../../../buttons/BackButton';
import Column from '../../../../helpers/Columns';
import DeleteItemIcon from '../../../buttons/DeleteItemIcon';
import AddEntityButton from '../../../buttons/AddEntityButton';
import EditItemIcon from '../../../buttons/EditItemIcon';
import RequiredLabel from '../../../../services/utils/RequiredLabel';

const EditRiskTypesForm = ({ risk, onSubmit, onCancel, errors }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [subTypeForm] = Form.useForm();
  const [riskSubTypes, setRiskSubTypes] = useState([]);
  const [subTypeModal, setSubTypeModal] = useState(false);

  useFormErrors(form, errors);
  useTableScroll();

  useEffect(() => {
    form.setFieldsValue(risk);
    setRiskSubTypes(risk.subTypes || []);
  }, [risk, form]);

  const handleAddSubType = useCallback(() => {
    const subType = subTypeForm.getFieldsValue();

    if (!subType.code) {
      notification.error({ message: 'Introduceți codul sub tipului' });
      return;
    }

    if (!subType.name) {
      notification.error({ message: 'Introduceți denumirea sub tipului' });
      return;
    }

    if (subType.id || subType.tempId) {
      setRiskSubTypes((prev) => [
        ...prev.filter(
          (el) => el.id !== subType.id || el.tempId !== subType.tempId,
        ),
        subType,
      ]);
    } else {
      // temp ID before save to manage Edit/ Delete without saving
      subType.tempId = new Date().getTime();
      setRiskSubTypes((prev) => [subType, ...prev]);
    }
    setSubTypeModal(false);
    subTypeForm.resetFields();
  }, [subTypeForm]);

  const renderActionRow = useCallback(
    (record) => {
      return (
        <>
          <DeleteItemIcon
            title={t(`entity.admin.riskTypes.deleteSubType`, record)}
            disabled={risk.deleted}
            key={`delete${record.id || record.tempId}`}
            isPromise={false}
            action={() => {
              setRiskSubTypes((prev) => [
                ...prev.filter(
                  (el) => el.id !== record.id || el.tempId !== record.tempId,
                ),
              ]);
            }}
          />
          <EditItemIcon
            key={`edit${record.id || record.tempId}`}
            disabled={risk.deleted}
            action={() => {
              subTypeForm.setFieldsValue(record);
              setSubTypeModal(true);
            }}
          />
        </>
      );
    },
    [t, subTypeForm, risk.deleted],
  );

  const handleSubmit = useCallback(
    (values) => {
      values.subTypes = riskSubTypes;
      onSubmit(values);
    },
    [onSubmit, riskSubTypes],
  );

  const subTypesColumns = useMemo(
    () => [
      Column.text('id', t('entity.admin.riskTypes.id'), {
        width: 50,
      }),
      Column.text('code', t('entity.admin.riskTypes.code'), {
        width: 75,
      }),
      Column.text('name', t('entity.admin.riskTypes.name'), {
        width: 150,
      }),
      Column.text(
        'reactionsDetermined',
        t('entity.admin.riskTypes.reactionsDetermined'),
        {
          width: 150,
        },
      ),
      Column.text(
        'reactionsPotential',
        t('entity.admin.riskTypes.reactionsPotential'),
        {
          width: 150,
        },
      ),
      Column.bool('deleted', t('entity._.deleted._'), {
        width: 100,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity._.deleted.true'),
          t('entity._.deleted.false'),
          t('entity._.deleted.unknown'),
        ],
      }),
      Column.date('createdAt', t('entity._.createdAt'), {
        width: 150,
      }),
      Column.text('createdBy', t('entity._.createdBy'), {
        width: 150,
      }),
      Column.date('updatedAt', t('entity._.updatedAt'), {
        width: 150,
      }),
      Column.text('updatedBy', t('entity._.updatedBy'), {
        width: 150,
      }),
      Column.actions(t('table.actions'), (record) => renderActionRow(record)),
    ],
    [t, renderActionRow],
  );

  return (
    <>
      <PageHeader title={t('entity.admin.riskTypes._singular')} />
      <Form
        form={form}
        layout="vertical"
        initialValues={risk}
        onFinish={handleSubmit}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={<RequiredLabel title={t('entity.admin.riskTypes.code')} />}
              name="code"
            >
              <Input
                disabled={risk.deleted}
                onChange={(e) => {
                  form.setFieldsValue({
                    code: maxLengthValidator(e.target.value, 5),
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label={<RequiredLabel title={t('entity.admin.riskTypes.name')} />}
              name="name"
            >
              <Input
                disabled={risk.deleted}
                onChange={(e) => {
                  form.setFieldsValue({
                    name: maxLengthValidator(e.target.value, 150),
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label={t('entity.admin.riskTypes.reactionsDetermined')}
              name="reactionsDetermined"
            >
              <Input
                disabled={risk.deleted}
                onChange={(e) => {
                  form.setFieldsValue({
                    reactionsDetermined: numberValidator(e.target.value, 10),
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label={t('entity.admin.riskTypes.reactionsPotential')}
              name="reactionsPotential"
            >
              <Input
                disabled={risk.deleted}
                onChange={(e) => {
                  form.setFieldsValue({
                    reactionsPotential: numberValidator(e.target.value, 10),
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Row justify="space-between">
              <PageHeader title={t('entity.admin.riskTypes.subTypes')} />
              <AddEntityButton
                onClick={() => setSubTypeModal(true)}
                disabled={risk.deleted}
              />
            </Row>
            <Table
              rowKey="id"
              columns={subTypesColumns}
              dataSource={riskSubTypes}
              scroll={{ y: 500 }}
              pagination={false}
            />
          </Col>
        </Row>
        <Form.Item>
          <Space>
            <SaveButton disabled={risk.deleted} />
            <CancelButton onCancel={onCancel} />
          </Space>
        </Form.Item>
      </Form>
      <Modal
        maskClosable={false}
        width={600}
        visible={subTypeModal}
        onCancel={() => setSubTypeModal(false)}
        onOk={handleAddSubType}
      >
        <PageHeader
          title={t('entity.admin.riskTypes.subType', {
            type: risk.name || '',
          })}
        />
        <Form form={subTypeForm}>
          <Row>
            <Col span={24}>
              <Form.Item
                label={
                  <RequiredLabel title={t('entity.admin.riskTypes.code')} />
                }
                name="code"
                labelCol={{ span: 24 }}
              >
                <Input
                  disabled={risk.deleted}
                  onChange={(e) => {
                    subTypeForm.setFieldsValue({
                      code: maxLengthValidator(e.target.value, 5),
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label={
                  <RequiredLabel title={t('entity.admin.riskTypes.name')} />
                }
                name="name"
                labelCol={{ span: 24 }}
              >
                <Input
                  disabled={risk.deleted}
                  onChange={(e) => {
                    subTypeForm.setFieldsValue({
                      name: maxLengthValidator(e.target.value, 150),
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label={t('entity.admin.riskTypes.reactionsDetermined')}
                name="reactionsDetermined"
                labelCol={{ span: 24 }}
              >
                <Input
                  disabled={risk.deleted}
                  onChange={(e) => {
                    subTypeForm.setFieldsValue({
                      reactionsDetermined: numberValidator(e.target.value, 10),
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label={t('entity.admin.riskTypes.reactionsPotential')}
                name="reactionsPotential"
                labelCol={{ span: 24 }}
              >
                <Input
                  disabled={risk.deleted}
                  onChange={(e) => {
                    subTypeForm.setFieldsValue({
                      reactionsPotential: numberValidator(e.target.value, 10),
                    });
                  }}
                />
              </Form.Item>
              {/*  DO NOT DELETE id noStyle it represent entity id currently on edit */}
              <Form.Item name="id" noStyle />
              <Form.Item name="tempId" noStyle />
              <Form.Item name="deleted" noStyle />
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default EditRiskTypesForm;
