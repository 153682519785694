import React, { useCallback, useContext, useState } from 'react';
import { Button, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import AuthContext, { hasPermission } from '../auth';
import { create } from './chat-service/group-service';
import ChatPopupUsersEdit from './components/ChatPopupUsersEdit';
import EntityTypes from './enums/EntityTypes';
import AvatarComponent from '../AvatarComponent';

const ChatGroupPopup = ({ reload }) => {
  const { user } = useContext(AuthContext);
  const [selectUsers, setSelectUsers] = useState(false);
  const [createGroupPopup, setCreateGroupPopup] = useState(false);
  const [selectedUsersIds, setSelectedUsersIds] = useState([]);
  const [groupName, setGroupName] = useState('');
  const [uploadedImage, setUploadedImage] = useState(null);
  const { permissions } = user;

  const handleUsersSelect = () => {
    setSelectUsers((prev) => !prev);
  };

  const handleAddUsers = (userIds) => {
    setSelectedUsersIds((prev) => {
      if (Array.isArray(userIds)) {
        return userIds;
      }
      if (prev.includes(userIds)) {
        return [...prev.filter((id) => id !== userIds)];
      }
      return [userIds, ...prev];
    });
  };

  // const handleAddUsers = (userId) => {
  //   setSelectedUsersIds((prev) => {
  //     if (prev.includes(userId)) {
  //       return [...prev.filter((id) => id !== userId)];
  //     }
  //     return [userId, ...prev];
  //   });
  // };

  const resetChatPopupOptions = () => {
    setCreateGroupPopup(false);
    setSelectedUsersIds([]);
    setUploadedImage([]);
    setGroupName('');
    setSelectUsers(false);
  };

  const handleSelectImage = (val) => {
    setUploadedImage(val);
  };

  const handleClearImage = () => {
    setUploadedImage(null);
  };

  const handleCreateGroup = useCallback(() => {
    const chat = {
      type: EntityTypes.GROUP,
      userIds: selectedUsersIds,
    };

    const group = {
      name: groupName,
      chat,
      base64Image: uploadedImage,
    };

    create(group)
      .then()
      .catch()
      .finally(() => {
        reload();
        resetChatPopupOptions();
        setCreateGroupPopup(false);
        setUploadedImage(null);
      });
  }, [reload, groupName, selectedUsersIds, uploadedImage]);

  return (
    <>
      {hasPermission(permissions, 'GROUP_MANAGEMENT') && (
        <Button
          className="chat__add-group"
          type="primary"
          onClick={() => setCreateGroupPopup((prev) => !prev)}
          icon={<PlusOutlined />}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Crează o grupă
        </Button>
      )}
      <div
        className={`chat__group-overlay ${createGroupPopup ? 'active' : ''}`}
      >
        <div className="chat__group-popup">
          <div className="chat__group-content">
            {selectUsers ? (
              <ChatPopupUsersEdit
                onSelect={handleAddUsers}
                selectedUsersIds={selectedUsersIds}
                onClear={() => setSelectedUsersIds([])}
                reload={reload}
              />
            ) : (
              <div className="chat__group-name">
                <AvatarComponent
                  base64={uploadedImage}
                  size={200}
                  removable
                  onUpload={handleSelectImage}
                  onClear={handleClearImage}
                />

                <div className="chat__group-input--name">
                  <label>Denumirea grupei</label>

                  <Input
                    className="chat__group-input"
                    onChange={(e) => setGroupName(e.target.value)}
                    value={groupName}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="chat__group-nav">
            <Button
              type="primary"
              onClick={resetChatPopupOptions}
              style={{ marginRight: 'auto' }}
              className="chat__group-cancel"
            >
              Anulează
            </Button>
            {selectUsers ? (
              <>
                <Button
                  type="primary"
                  onClick={() => {
                    setSelectedUsersIds([]);
                    handleUsersSelect();
                  }}
                >
                  Înapoi
                </Button>
                <Button type="primary" onClick={handleCreateGroup}>
                  Crează
                </Button>
              </>
            ) : (
              <Button
                type="primary"
                onClick={handleUsersSelect}
                disabled={!groupName?.length}
              >
                Continuă
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatGroupPopup;
