import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Avatar, Button, Collapse, Input, Spin } from 'antd';
import {
  ArrowRightOutlined,
  SearchOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { debounce } from 'lodash';
import EntityTypes from '../enums/EntityTypes';
import { ChatEntity } from './ChatEntity';
import { findByChatId } from '../chat-service/group-service';
import AuthContext from '../../auth';
import GroupSettingsPopup from './GroupSettingsPopup';
import { getBlobFile } from '../../../services/admin/files';
import { findAllByIds } from '../../../services/admin/users/users';
import { stringLengthValidator } from '../../../services/utils/validator/Validator';

const ChatInfo = ({
  searchRef = null,
  userInfoSide,
  onEntitySelect = () => {},
  handleUserInfoSide,
  selectedEntity = null,
  reloadGroups = () => {},
  onSearch = () => {},
  visible,
}) => {
  const { Panel } = Collapse;
  const { user } = useContext(AuthContext);
  const [group, setGroup] = useState(null);
  const [groupUsers, setGroupUsers] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [loadingUsers, setLoadingUsers] = useState(false);

  useEffect(() => {
    if (selectedEntity?.chatter?.imageId) {
      getBlobFile(selectedEntity?.chatter?.imageId)
        .then((res) => {
          setImageUrl(URL.createObjectURL(res));
        })
        .catch((error) => {
          console.error('Error fetching blob file:', error);
        });
    } else {
      setImageUrl(null);
    }
    return () => (imageUrl ? URL.revokeObjectURL(imageUrl) : () => {});
    // eslint-disable-next-line
  }, [selectedEntity?.chatter?.imageId]);

  const updateGroup = useCallback((id) => {
    if (id) {
      findByChatId(id).then((res) => {
        setGroup(res);
        if (res.chat?.userIds?.length > 0 && res.chat?.userIds?.length < 200) {
          setLoadingUsers(true);
          findAllByIds(res.chat.userIds)
            .then((users) => setGroupUsers(users))
            .finally(() => setLoadingUsers(false));
        } else {
          setGroupUsers(null);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (selectedEntity?.id && selectedEntity?.type === EntityTypes.GROUP) {
      updateGroup(selectedEntity?.id);
    } else {
      setGroup(null);
      setGroupUsers(null);
    }
  }, [selectedEntity?.id, selectedEntity?.type, updateGroup]);

  const handleSelectUser = (selectedUser) => {
    if (onEntitySelect && typeof onEntitySelect === 'function') {
      onEntitySelect(selectedUser);
    }
  };

  const renderParticipantsInfo = () => {
    if (group && !groupUsers && selectedEntity?.userIds?.length > 0) {
      return `${selectedEntity.userIds.length} Participanți`;
    }
    return null;
  };

  const lazySearch = debounce(onSearch, 450);

  return (
    <>
      <div
        className={`chat__user-side--info ${userInfoSide ? 'active' : ''} ${
          visible ? 'visible' : ''
        }`}
      >
        {userInfoSide ? (
          <Button
            icon={<ArrowRightOutlined />}
            type="primary"
            className="chat__info-close"
            onClick={handleUserInfoSide}
          />
        ) : null}

        <div className="chat__user-side--avatar-container">
          {imageUrl ? (
            <Avatar
              className="chat__user-side--avatar"
              src={imageUrl}
              alt="user-avatar"
              size={100}
            />
          ) : (
            <Avatar
              className="chat__user-side--avatar"
              size={100}
              icon={<UserOutlined />}
            />
          )}
          <div className="chat__user-side--name">
            {stringLengthValidator(selectedEntity?.chatter?.name, 20)}
            {loadingUsers ? (
              <Spin />
            ) : (
              <div style={{ width: '100%', marginTop: '10px' }}>
                {renderParticipantsInfo(selectedEntity)}
              </div>
            )}
          </div>

          <Input
            allowClear
            prefix={<SearchOutlined />}
            onChange={(e) => {
              if (searchRef !== null) {
                searchRef.current = e.target.value;
                lazySearch();
              }
            }}
            placeholder="Caută in mesaje"
          />
        </div>

        <Collapse
          className="chat__user-info--collapse"
          bordered={false}
          defaultActiveKey={['1']}
        >
          {/* TODO: implement functional for that
          <Panel className="chat__user-info--panel" header="Media" key="1">
            {selectedEntity?.info?.medias?.length ? (
              <>
                {selectedEntity?.info?.medias?.map((media) => {
                  return (
                    <Image
                      className="chat__user-info--collapse-media"
                      key={media.id}
                    />
                  );
                })}
              </>
            ) : (
              <div className="chat__user-info-nocontent">
                Nu sunt media fișiere
              </div>
            )}
          </Panel>
          <Panel className="chat__user-info--panel" header="Linkuri" key="2">
            {selectedEntity?.info?.links?.length ? (
              <>
                {selectedEntity?.info?.links?.map((link) => {
                  return (
                    <Link
                      className="chat__user-info--collapse-media"
                      key={link.id}
                      to={link.link}
                      target="_blank"
                    >
                      {link.link}
                    </Link>
                  );
                })}
              </>
            ) : (
              <div className="chat__user-info-nocontent">Nu sunt linkuri</div>
            )}
          </Panel>
          <Panel className="chat__user-info--panel" header="Fișiere" key="3">
            {selectedEntity?.info?.files?.length ? (
              <>
                {selectedEntity?.info?.files?.map((file) => {
                  return (
                    <div
                      className="chat__user-info--collapse-media"
                      key={file.id}
                    />
                  );
                })}
              </>
            ) : (
              <div className="chat__user-info-nocontent">Nu sunt fișiere</div>
            )}
          </Panel> */}
          {groupUsers ? (
            <Panel
              className="chat__user-info--panel-participants"
              header="Participanți"
              key="4"
            >
              {groupUsers.map((el) => (
                <ChatEntity
                  key={el.id}
                  entityId={el.id}
                  onClick={handleSelectUser}
                  entityName={el.fullname}
                  entityType={EntityTypes.USER}
                  base64Image={el.base64Image}
                  imageId={el.imageId}
                />
              ))}
            </Panel>
          ) : null}
        </Collapse>
        {group && group?.leaderId === user.id ? (
          <GroupSettingsPopup
            group={{ ...group, users: groupUsers }}
            onSave={() => {
              reloadGroups();
              updateGroup(selectedEntity?.id);
            }}
            onRemove={reloadGroups}
          />
        ) : null}
        {selectedEntity?.id && !group}
      </div>
    </>
  );
};

export default ChatInfo;
