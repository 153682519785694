import React, { useMemo, useCallback, useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Table,
  Col,
  Row,
  DatePicker,
  Input,
  Form,
  Select,
  Button,
  Modal,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import {
  FileTextOutlined,
  BarsOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import useDatasource from '../../../hooks/useDatasource';

import { findAll } from '../../../services/admin/audit/audit';

import Column from '../../../helpers/Columns';
import { useTableScroll } from '../../../hooks/useTableScroll';

const operations = {
  content: [
    { code: 'AUTHENTICATE', name: 'AUTENTIFICARE' },
    { code: 'EDIT', name: 'EDITARE' },
    { code: 'ADD', name: 'CREARE' },
    { code: 'DELETE', name: 'ȘTERGERE' },
    { code: 'VIEW', name: 'VIZUALIZARE' },
    { code: 'WS_CALL', name: 'SERVICII WEB' },
  ],
};

const AuditDataList = () => {
  useTableScroll();
  const dateFormat = 'YYYY-MM-DD HH:mm:ss';
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);

  const [isDataModalVisible, setDataModalVisible] = useState(false);
  const [isChangesModalVisible, setChangesModalVisible] = useState(false);
  const [currentChanges, setCurrentChanges] = useState([]);
  const [buttonFirstPress, setButtonFirstPress] = useState(false);

  function subtractHours(numOfHours, date = new Date()) {
    date.setHours(date.getHours() - numOfHours);
    return date;
  }

  function getUTCTime(date) {
    if (date) {
      const hours = date.getUTCHours().toString().padStart(2, '0');
      const minutes = date.getUTCMinutes().toString().padStart(2, '0');
      const seconds = date.getUTCSeconds().toString().padStart(2, '0');
      return `${hours}:${minutes}:${seconds}`;
    }
    return '';
  }

  const setDefaultTimeRange = (isMin) => {
    if (isMin || minDate === null) {
      const min = new Date(subtractHours(24)).getTime();
      setMinDate(min);
    }
    if (!isMin || maxDate === null) {
      const max = new Date().getTime();
      setMaxDate(max);
    }
  };

  const handleShowData = (record) => {
    setDataModalVisible(true);
    const obj = JSON.parse(record?.resource);
    form.setFieldsValue({ jsonRecord: JSON.stringify(obj, undefined, 4) });
  };

  const handleShowChanges = (record) => {
    setCurrentChanges(record.changes);
    setChangesModalVisible(true);
  };

  useEffect(() => {
    setDefaultTimeRange();
    // eslint-disable-next-line
  }, []);

  const handler = useCallback(
    (...params) => {
      const hz = params[0];
      hz.criterias = {
        ...hz.criterias,
        operationDateFrom: `${dayjs(minDate).format('YYYY-MM-DD')} ${getUTCTime(
          new Date(minDate),
        )}`,
        operationDateTo: `${dayjs(maxDate).format('YYYY-MM-DD')} ${getUTCTime(
          new Date(maxDate),
        )}`,
        author: form.getFieldValue('author'),
        resourceId: form.getFieldValue('resourceId'),
        resourceName: form.getFieldValue('resourceName'),
        operation: form.getFieldValue('operation')
          ? form.getFieldValue('operation')
          : '',
        vinCode: form.getFieldValue('vinCode')
          ? form.getFieldValue('vinCode')
          : '',
        plateNumber: form.getFieldValue('plateNumber')
          ? form.getFieldValue('plateNumber')
          : '',
        personIDNP: form.getFieldValue('personIDNP')
          ? form.getFieldValue('personIDNP')
          : '',
        transportatorPersonalCode: form.getFieldValue(
          'transportatorPersonalCode',
        )
          ? form.getFieldValue('transportatorPersonalCode')
          : '',
      };
      hz.sort = ['operationDate', 'desc'];
      return findAll(hz);
    },
    [maxDate, minDate, form],
  );

  const { loading, pagination, content, sort, handleChange, reload } =
    useDatasource(handler, {
      allowFetcher: buttonFirstPress,
    });

  const columns = useMemo(
    () => [
      {
        sort: false,
        title: 'Acțiune',
        key: 'id',
        width: 100,
        render: (record) => {
          return (
            <>
              {record?.resource && (
                <Button
                  style={{ marginRight: '10px' }}
                  shape="circle"
                  icon={<FileTextOutlined />}
                  onClick={() => handleShowData(record)}
                />
              )}
              {record.operation === 'EDIT' && (
                <Button
                  shape="circle"
                  icon={<BarsOutlined />}
                  onClick={() => handleShowChanges(record)}
                />
              )}
            </>
          );
        },
      },
      {
        sort: false,
        title: 'Operațiuni',
        key: 'operation',
        dataIndex: 'operation',
        width: 100,
        render: (row) => {
          return operations.content.find((el) => el.code === row)?.name;
        },
      },
      {
        sort: false,
        title: 'Nume Resursă',
        key: 'resourceName',
        dataIndex: 'resourceName',
        width: 100,
        render: (row) => {
          return row?.split('.').at(-1).toUpperCase();
        },
      },
      // Column.date('operationDate', t('entity.admin.audit.operationDate'), {
      //   width: 100,
      //   sort: false,
      //   format: 'DD.MM.YYYY HH:mm:ss',
      // }),
      {
        sort: false,
        title: t('entity.admin.audit.operationDate'),
        key: 'operationDate',
        dataIndex: 'operationDate',
        width: 100,
        render: (row) => {
          return dayjs(`${row} UTC`).format(dateFormat);
        },
      },
      Column.text('resourceId', t('entity.admin.audit.resourceId'), {
        sort: false,
        width: 100,
      }),
      Column.text('methodName', t('entity.admin.audit.methodName'), {
        sort: false,
        width: 100,
      }),
      Column.text('author', t('entity.admin.audit.author'), {
        sort: false,
        width: 100,
      }),
      Column.text('ipAddress', t('entity.admin.audit.ipAddress'), {
        sort: false,
        width: 100,
      }),
    ],
    // eslint-disable-next-line
    [t],
  );

  const columnsChanges = useMemo(
    () => [
      Column.text('changedField', 'Câmp modificat', {
        width: 100,
        sort: false,
      }),
      Column.text('beforeValue', 'Valoare inițială', {
        width: 100,
        sort: false,
      }),
      Column.text('afterValue', 'Valoare finală', {
        width: 100,
        sort: false,
      }),
    ],
    // eslint-disable-next-line
    [t],
  );

  const handleCloseChangesModal = () => {
    setChangesModalVisible(false);
    setCurrentChanges({});
  };

  return (
    <>
      <PageHeader title={t('entity.admin.audit.name')} />
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          author: '',
          resourceId: '',
          resourceName: '',
          changedField: '',
          beforeValue: '',
          afterValue: ' ',
        }}
      >
        <Row style={{ gap: '20px' }}>
          <Col span={6}>
            <label>Data start operațiune</label>
            <DatePicker
              format="DD.MM.YYYY HH:mm"
              showTime
              value={dayjs(minDate)}
              onChange={(date) => {
                if (date) {
                  setMinDate(new Date(date).getTime());
                } else {
                  setDefaultTimeRange(true);
                }
                reload();
              }}
            />
          </Col>
          <Col span={6}>
            <label>Data sfârșit operațiune</label>
            <DatePicker
              format="DD.MM.YYYY HH:mm"
              showTime
              value={dayjs(maxDate)}
              onChange={(date) => {
                if (date) {
                  setMaxDate(new Date(date).getTime());
                } else {
                  setDefaultTimeRange(false);
                }
                reload();
              }}
            />
          </Col>
          <Col span={6}>
            <label>Autor</label>
            <Form.Item name="author">
              <Input onChange={reload} />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ gap: '20px' }}>
          <Col span={6}>
            <label>Nume operațiune</label>
            <Form.Item name="operation">
              <Select
                allowClear
                showSearch
                onChange={reload}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) !==
                  -1
                }
              >
                {operations.content.map((option) => (
                  <Select.Option key={option.code} value={option.code}>
                    {`${option.name}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <label>ID resursă</label>
            <Form.Item name="resourceId">
              <Input onChange={reload} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <label>Nume resursă</label>
            <Form.Item name="resourceName">
              <Input onChange={reload} />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ gap: '20px' }}>
          <Col span={6}>
            <label>Nr. auto</label>
            <Form.Item name="plateNumber">
              <Input onChange={reload} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <label>Nr. Caroserie/VIN</label>
            <Form.Item name="vinCode">
              <Input onChange={reload} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <label>Nr. Pașaport</label>
            <Form.Item name="personIDNP">
              <Input onChange={reload} />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ gap: '20px' }}>
          <Col span={6}>
            <label>IDNO/IDNP transportator</label>
            <Form.Item name="transportatorPersonalCode">
              <Input onChange={reload} />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ gap: '20px' }}>
          <Col>
            <Button
              type="primary"
              onClick={() => {
                // reloadWithCheck();
                reload();
                setButtonFirstPress(true);
              }}
              icon={<SearchOutlined />}
            >
              Caută
            </Button>
          </Col>
        </Row>

        <Modal
          maskClosable={false}
          width={600}
          visible={isDataModalVisible}
          footer={null}
          onCancel={() => setDataModalVisible(false)}
        >
          <PageHeader title="Valoarea finală" />
          <Form.Item name="jsonRecord">
            <TextArea readOnly rows={25} style={{ width: '95%' }} />
          </Form.Item>
        </Modal>
        <Modal
          maskClosable={false}
          width={600}
          visible={isChangesModalVisible}
          footer={null}
          onCancel={handleCloseChangesModal}
        >
          <PageHeader title="Modificări" />
          <Table
            columns={columnsChanges}
            rowKey="changedField"
            dataSource={currentChanges}
            pagination={false}
            scroll={{ y: 580 }}
          />
        </Modal>
      </Form>

      <Table
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        sortDirections={sort}
      />
    </>
  );
};

export default AuditDataList;
