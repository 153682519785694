import React from 'react';

import SchedulerList from './SchedulerList';

const Scheduler = () => {
  return (
    <>
      <SchedulerList />
    </>
  );
};

export default Scheduler;
