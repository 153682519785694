import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import {
  DeleteOutlined,
  EditOutlined,
  FileTextOutlined,
  PlusOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import SaveButton from '../../buttons/SaveButton';
import CancelButton from '../../buttons/BackButton';
import useFormErrors from '../../../hooks/useFormErrors';
import Column from '../../../helpers/Columns';
import useDictionaries from '../../../hooks/useDictionaries';
import { findAll as findAllEndowments } from '../../../services/taxonomies/endowment';
import {
  findAll as findAllCameras,
  generate as generateUUID,
} from '../../../services/admin/users/customs-security-cameras';
import {
  editImage,
  generatePdfInNewTab,
} from '../../../services/admin/users/customs-posts';
import { useTableScroll } from '../../../hooks/useTableScroll';
import AppLoader from '../../auth/AppLoader';
import RequiredLabel from '../../../services/utils/RequiredLabel';
import {
  maxLengthValidator,
  numberValidator,
} from '../../../services/utils/validator/Validator';
import AvatarComponent from '../../AvatarComponent';
import { getBlobFile } from '../../../services/admin/files';
import CameraStreamingModal from './CameraStreamingModal';

const dictionaryData = {
  endowments: findAllEndowments,
  cameras: findAllCameras,
};

const dictionaries = {
  statutes: [
    { code: 'LOCAL', name: 'Local' },
    { code: 'INTERNAL', name: 'Intern' },
    { code: 'INTERSTATE', name: 'Interstatal' },
    { code: 'INTERNATIONAL', name: 'Internațional' },
    { code: 'INTERNAL_CONTROL', name: 'Intern de control' },
  ],
  types: [
    { code: 'AVIA', name: 'Avia' },
    { code: 'ROAD', name: 'Rutier' },
    { code: 'RAILWAY', name: 'Feroviar' },
    { code: 'NAVAL', name: 'Naval/Maritim' },
  ],
  geographics: [
    { code: 'UA', name: 'la frontiera cu Ucraina' },
    { code: 'RO', name: 'la frontiera cu România' },
  ],
  creationMethod: [
    {
      code: 'creationOrder',
      name: 'Ordin cu privire la creare a postului vamal',
    },
    {
      code: 'creationOrderAprob',
      name: 'Ordin cu privire la aprobarea Regulamentului postului vamal',
    },
    { code: 'zcvCreation', name: 'Ordinul cu privire la creare  ZCV' },
    {
      code: 'zcvCreationAprob',
      name: 'Ordin cu privire la aprobarea Regulamentului privind modul de activitatea în ZCV',
    },
  ],
  directions: [
    { code: 'IN', name: 'INTRARE' },
    { code: 'OUT', name: 'IEȘIRE' },
  ],
  orientations: [
    { code: 'REAR', name: 'Față' },
    { code: 'FRONT', name: 'Spate' },
  ],
};

const EditCustomsPostForm = ({ post, onSubmit, onCancel, errors, reload }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  useTableScroll();
  const [{ endowments }] = useDictionaries(dictionaryData);

  const [cameraContent, setCameraContent] = useState(post?.securityCameras);
  const [endowmentContent, setEndowmentContent] = useState([]);
  const [endowmentModalVisible, setEndowmentModalVisible] = useState(false);
  const [avatarLoading, setAvatarLoading] = useState(false);
  const [loadingLoader, setLoadingLoader] = useState(false);
  const [isCreationMethodModalVisible, setCreationMethodModalVisible] =
    useState(false);
  const [postImageSrc, setPostImageSrc] = useState('');
  const [isFieldTouched, setIsFieldTouched] = useState(false);
  const [streamCamera, setStreamCamera] = useState(null);

  useFormErrors(form, errors);

  const tempId = useRef(-1);

  useEffect(() => {
    const tempArray = [];
    post?.endowment?.forEach((el) =>
      tempArray.push({
        ...el,
        name: endowments?.content.find((val) => val.id === el.endowmentId)
          ?.name,
      }),
    );
    setEndowmentContent(tempArray);
  }, [post, endowments]);

  useEffect(() => {
    if (post?.imageId) {
      setAvatarLoading(true);
      getBlobFile(post?.imageId)
        .then((res) => {
          const imgUrl = URL.createObjectURL(res);
          setPostImageSrc(imgUrl);
        })
        .catch((error) => {
          console.error('Error fetching blob file:', error);
        })
        .finally(() => setAvatarLoading(false));
    }
    return () => {
      URL.revokeObjectURL(postImageSrc);
    };
    // eslint-disable-next-line
  }, [post?.imageId]);

  const [currentSecurityCamera, setCurrentSecurityCamera] = useState(null);
  const [isCameraModalVisible, setIsCameraModalVisible] = useState(false);

  const [isButtonEnabled, setIsButtonEnabled] = useState(
    post?.addTrackButtonEnabled,
  );

  const [uuid, setUUID] = useState(null);

  useEffect(() => {
    if (post?.id) {
      form.setFieldsValue(post);
    }
  }, [post, form]);

  const getNewCameraUUID = () => {
    generateUUID()
      .then((result) => result.uuid)
      .then((data) => {
        setUUID(data);
        setIsCameraModalVisible(true);
      });
  };

  const handleCancelCamera = () => {
    setCurrentSecurityCamera(null);
    setIsCameraModalVisible(false);
    form.setFieldsValue({ addCameraDescription: null });
    form.setFieldsValue({ addAddress: null });
    form.setFieldsValue({ addDirection: null });
    form.setFieldsValue({ addOrientation: null });
    form.setFieldsValue({ addBQS: null });
    form.setFieldsValue({ addParking: null });
    form.setFieldsValue({ addCheckAccess: null });
  };

  const handleAddCamera = useCallback(() => {
    if (!form.getFieldValue('addCameraDescription')) {
      notification.error({ message: 'Adăugați descriere' });
      return;
    }

    const cameraData = {
      uuid: currentSecurityCamera ? currentSecurityCamera.uuid : uuid,
      description: form.getFieldValue('addCameraDescription'),
      isParking: form.getFieldValue('addParking'),
      cameraAddress: form.getFieldValue('addAddress'),
      direction: form.getFieldValue('addDirection'),
      orientation: form.getFieldValue('addOrientation'),
      isBQS: form.getFieldValue('addBQS'),
      isCheckAccess: form.getFieldValue('addCheckAccess'),
    };

    if (currentSecurityCamera) {
      cameraContent.forEach((el) => {
        if (el.uuid === cameraData.uuid) {
          Object.assign(el, cameraData);
        }
      });
      setCurrentSecurityCamera(null);
    } else {
      setCameraContent((prev) =>
        Array.isArray(prev) ? [...prev, cameraData] : [cameraData],
      );
    }

    form.setFieldsValue({
      addCameraDescription: null,
      isParking: null,
      cameraAddress: null,
      direction: null,
      orientation: null,
      isBQS: null,
      isCheckAccess: null,
    });
    setIsCameraModalVisible(false);
  }, [currentSecurityCamera, uuid, form, cameraContent]);

  const handleDelete = useCallback(
    (entity) => {
      cameraContent.forEach((el) => {
        if (el.uuid === entity.uuid) {
          if (entity.id) {
            el.deleted = true;
          } else {
            setCameraContent((prev) =>
              prev.filter((camera) => camera.uuid !== entity.uuid),
            );
          }
        }
      });
    },
    [cameraContent],
  );

  const handleEndowmentOk = () => {
    if (!form.getFieldValue('editEndowmentType')) {
      notification.error({ message: 'Adăugați denumirea mijlocului tehnic' });
      return;
    }
    if (!form.getFieldValue('editEndowmentSerialNumber')) {
      notification.error({ message: 'Adăugați seria mijlocului tehnic' });
      return;
    }
    if (!form.getFieldValue('editEndowmentCondition')) {
      notification.error({
        message: 'Adăugați starea tehnică a mijlocului tehnic',
      });
      return;
    }

    const isNewOrId = form.getFieldValue('editEndowmentId');
    const newEndowment = {
      name: endowments?.content.find(
        (el) => el.id === form.getFieldValue('editEndowmentType'),
      )?.name,
      postId: post.id,
      endowmentId: form.getFieldValue('editEndowmentType'),
      serialNumber: form.getFieldValue('editEndowmentSerialNumber'),
      technicalCondition: form.getFieldValue('editEndowmentCondition'),
    };
    if (isNewOrId) {
      const oldList = endowmentContent.filter((el) => el.id !== isNewOrId);
      oldList.push({ ...newEndowment, id: isNewOrId });
      setEndowmentContent(oldList);
    } else {
      setEndowmentContent((old) => [
        ...old,
        { ...newEndowment, id: tempId.current },
      ]);
      tempId.current -= 1;
    }
    form.setFieldsValue({
      editEndowmentId: null,
      editEndowmentType: null,
      editEndowmentSerialNumber: null,
      editEndowmentCondition: null,
    });
    setEndowmentModalVisible(false);
  };

  const checkCreationMethod = (value) => {
    const methodTypes = post?.creationMethods?.map((el) => el.orderType);
    if (methodTypes && methodTypes.includes(value)) {
      notification.error({
        message: 'Acest ordin a fost deja adăugat',
      });
      form.setFieldsValue({ editCreationMethodType: null });
    }
  };

  const handleCreationMethodOk = useCallback(() => {
    // Validation checks for type and code
    if (!form.getFieldValue('editCreationMethodType')) {
      notification.error({ message: 'Adăugați ordinul' });
      return;
    }
    if (!form.getFieldValue('editCreationMethodCode')) {
      notification.error({ message: 'Adăugați numărul' });
      return;
    }
    if (!form.getFieldValue('editCreationMethodDate')) {
      notification.error({ message: 'Adăugați data creării' });
      return;
    }

    // Ensuring post.creationMethods is initialized
    if (!post.creationMethods) {
      post.creationMethods = [];
    }

    // Ensure tempId.current is initialized
    if (!tempId.current) {
      tempId.current = -1;
    }

    // Extract form values
    const isNewOrId = form.getFieldValue('editCreationMethodId');
    const newMethod = {
      postId: post.id,
      orderType: form.getFieldValue('editCreationMethodType'),
      code: form.getFieldValue('editCreationMethodCode'),
      creationDate: form.getFieldValue('editCreationMethodDate'),
    };

    // Add or update creation method
    if (isNewOrId) {
      // Update existing creation method
      post.creationMethods = post.creationMethods.map((el) =>
        el.id === isNewOrId ? { ...newMethod, id: isNewOrId } : el,
      );
    } else {
      // Add new creation method with a temporary ID
      newMethod.id = tempId.current;
      tempId.current -= 1;
      post.creationMethods.push(newMethod);
    }

    // Reset form and close modal
    form.setFieldsValue({
      editCreationMethodId: null,
      editCreationMethodType: null,
      editCreationMethodCode: null,
      editCreationMethodDate: null,
    });
    setCreationMethodModalVisible(false);
  }, [form, post]);

  const handleValuesChange = () => {
    setIsFieldTouched(true);
  };

  useEffect(() => {
    form.setFieldsValue({
      phoneNumber: post.postContactInfo?.phoneNumber,
      faxNumber: post.postContactInfo?.faxNumber,
      email: post.postContactInfo?.email,
    });
  }, [post, form]);

  const handleFinish = (value) => {
    const valueToSubmit = { ...value };
    if (!form.getFieldValue('code')) {
      notification.error({ message: 'Adăugați cod' });
      return;
    }
    if (!form.getFieldValue('address')) {
      notification.error({ message: 'Adăugați adresa' });
      return;
    }
    if (!form.getFieldValue('statute')) {
      notification.error({ message: 'Adăugați statut' });
      return;
    }
    if (!form.getFieldValue('name')) {
      notification.error({ message: 'Adăugați denumire' });
      return;
    }
    if (!form.getFieldValue('crossingType')) {
      notification.error({ message: 'Adăugați tip' });
      return;
    }
    valueToSubmit.securityCameras = cameraContent;
    valueToSubmit.addTrackButtonEnabled = isButtonEnabled;
    valueToSubmit.postContactInfo = {
      phoneNumber: form.getFieldValue('phoneNumber'),
      faxNumber: form.getFieldValue('faxNumber'),
      email: form.getFieldValue('email'),
      id: post?.postContactInfo?.id,
    };

    valueToSubmit.endowment = endowmentContent?.map((el) => {
      return el.id < 0 ? { ...el, id: null } : el;
    });
    valueToSubmit.creationMethods = post?.creationMethods?.map((el) => {
      return el.id < 0 ? { ...el, id: null } : el;
    });

    setLoadingLoader(true);
    onSubmit(valueToSubmit).finally(() => setLoadingLoader(false));
  };

  const handleGeneratePdf = useCallback(() => {
    if (isFieldTouched) {
      notification.warning({
        message: 'Salvați modificările înainte să generați pașaportul.',
      });
      return;
    }
    setLoadingLoader(true);
    generatePdfInNewTab(`passport/customs-post/${post?.id}`)
      .then(() => {
        notification.success({
          message: t('messages.report.generated.success'),
          duration: 3,
        });
      })
      .catch(() => {
        notification.error({ message: 'Erroare la generarea pașaportului' });
      })
      .finally(() => setLoadingLoader(false));
  }, [isFieldTouched, post?.id, t]);

  const columnsCameras = useMemo(
    () => [
      Column.text('uuid', t('entity.admin.customsSecurityCameras.uuid'), {
        width: 250,
      }),
      Column.text(
        'description',
        t('entity.admin.customsSecurityCameras.description'),
        {
          width: 150,
        },
      ),
      Column.bool('deleted', t('entity._.deleted._'), {
        width: 100,
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity._.deleted.true'),
          t('entity._.deleted.false'),
          t('entity._.deleted.unknown'),
        ],
      }),
      Column.actions(
        t('table.actions'),
        (record) =>
          !record.deleted && (
            <span style={{ textAlign: 'right' }}>
              {!post.deleted && (
                <>
                  <Tooltip title={t('actions.edit')}>
                    <Button
                      shape="circle"
                      type="link"
                      icon={<EditOutlined />}
                      onClick={() => {
                        setCurrentSecurityCamera(record);
                        setIsCameraModalVisible(true);
                        form.setFieldsValue({
                          addCameraDescription: record.description,
                          addAddress: record.cameraAddress,
                          addParking: record.isParking,
                          addDirection: record.direction,
                          addOrientation: record.orientation,
                          addBQS: record.isBQS,
                          addCheckAccess: record.isCheckAccess,
                        });
                      }}
                    />
                  </Tooltip>
                  {record?.uuid && record?.cameraIp ? (
                    <Tooltip title="Live">
                      <Button
                        shape="circle"
                        type="link"
                        icon={<VideoCameraOutlined />}
                        onClick={() => setStreamCamera(record)}
                      />
                    </Tooltip>
                  ) : null}
                  <Popconfirm
                    placement="topLeft"
                    title={t(
                      'entity.admin.customsSecurityCameras._delete',
                      record,
                    )}
                    onConfirm={() => handleDelete(record)}
                    okText={t('actions.delete')}
                    cancelText={t('actions.cancel')}
                  >
                    <Tooltip title={t('actions.delete')}>
                      <Button
                        shape="circle"
                        type="link"
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>
                </>
              )}
            </span>
          ),
      ),
    ],
    [t, form, handleDelete, post],
  );

  const columnsEndowment = useMemo(
    () => [
      Column.text('name', t('entity.admin.endowment.name'), {
        width: 100,
      }),
      Column.text('serialNumber', t('entity.admin.endowment.serialNumber'), {
        width: 150,
      }),
      Column.text('technicalCondition', 'Starea tehnică', {
        width: 150,
      }),
      Column.actions(
        t('table.actions'),
        (record) =>
          !record.deleted && (
            <span style={{ textAlign: 'right' }}>
              {!post.deleted && (
                <Tooltip title={t('actions.edit')}>
                  <Button
                    shape="circle"
                    type="link"
                    icon={<EditOutlined />}
                    onClick={() => {
                      form.setFieldsValue({
                        editEndowmentId: record.id,
                        editEndowmentType: record.endowmentId,
                        editEndowmentSerialNumber: record.serialNumber,
                        editEndowmentCondition: record.technicalCondition,
                      });
                      setEndowmentModalVisible(true);
                    }}
                  />
                </Tooltip>
              )}
              {!post.deleted && (
                <Popconfirm
                  placement="topLeft"
                  title={t('entity.admin.endowment._delete')}
                  onConfirm={() =>
                    setEndowmentContent((old) =>
                      old.filter((el) => el.id !== record.id),
                    )
                  }
                  okText={t('actions.delete')}
                  cancelText={t('actions.cancel')}
                >
                  <Tooltip title={t('actions.delete')}>
                    <Button
                      shape="circle"
                      type="link"
                      icon={<DeleteOutlined />}
                    />
                  </Tooltip>
                </Popconfirm>
              )}
            </span>
          ),
      ),
    ],
    [t, post, form],
  );

  const uploadPostImage = useCallback(
    (stringBase64) => {
      const base64Dto = {
        name: `${post.code}_${post.name}`,
        mimetype: 'image/*',
        content: stringBase64,
      };

      setAvatarLoading(true);
      editImage(post.id, base64Dto)
        .then(() => {
          reload();
        })
        .catch(() =>
          notification.error({
            message: 'A apărut o eroare la modificarea imaginii',
          }),
        )
        .finally(() => setAvatarLoading(false));
    },
    [post.id, post.code, post.name, reload],
  );

  const columnsCreationMethod = useMemo(
    () => [
      {
        sort: false,
        title: 'Ordin',
        key: 'orderType',
        width: 250,
        dataIndex: 'orderType',
        render: (row) => {
          return (
            <span>
              {dictionaries.creationMethod.find((el) => el.code === row)?.name}
            </span>
          );
        },
      },
      Column.text('code', 'Nr.', {
        width: 100,
      }),
      Column.date('creationDate', 'Data creării', {
        width: 100,
        filter: true,
        format: 'DD-MM-YYYY',
      }),
      Column.actions(
        t('table.actions'),
        (record) =>
          !record.deleted && (
            <span style={{ textAlign: 'right' }}>
              {!post.deleted && (
                <Tooltip title={t('actions.edit')}>
                  <Button
                    shape="circle"
                    type="link"
                    icon={<EditOutlined />}
                    onClick={() => {
                      form.setFieldsValue({
                        editCreationMethodId: record.id,
                        editCreationMethodType: record.orderType,
                        editCreationMethodCode: record.code,
                        editCreationMethodDate: record.creationDate
                          ? dayjs(record.creationDate)
                          : null,
                      });
                      setCreationMethodModalVisible(true);
                    }}
                  />
                </Tooltip>
              )}
              {!post.deleted && (
                <Popconfirm
                  placement="topLeft"
                  title="Sunteți sigur(ă) că doriți să ștergeți ordinul de creare?"
                  onConfirm={() => {
                    post.creationMethods = post?.creationMethods?.filter(
                      (el) => el.id !== record.id,
                    );
                  }}
                  okText={t('actions.delete')}
                  cancelText={t('actions.cancel')}
                >
                  <Tooltip title={t('actions.delete')}>
                    <Button
                      shape="circle"
                      type="link"
                      icon={<DeleteOutlined />}
                    />
                  </Tooltip>
                </Popconfirm>
              )}
            </span>
          ),
      ),
    ],
    [t, post, form],
  );

  const CameraTable = () => {
    return (
      <Table
        columns={columnsCameras}
        dataSource={cameraContent}
        pagination={false}
        rowKey="uuid"
        size="small"
      />
    );
  };

  const EndowmentTable = () => {
    return (
      <Table
        columns={columnsEndowment}
        dataSource={endowmentContent}
        pagination={false}
        rowKey="id"
        size="small"
      />
    );
  };

  const CreationMethodTable = () => {
    return (
      <Table
        columns={columnsCreationMethod}
        dataSource={post?.creationMethods}
        pagination={false}
        rowKey="id"
        size="small"
      />
    );
  };

  return (
    <>
      <div className={`loaderOverlay ${loadingLoader ? 'active' : ''}`}>
        <AppLoader />
      </div>

      <CameraStreamingModal
        camera={streamCamera}
        onClose={() => setStreamCamera(null)}
      />

      <PageHeader title={t('entity.admin.customsPost._singular')} />
      <Form
        form={form}
        layout="vertical"
        initialValues={post}
        onFinish={handleFinish}
        onValuesChange={handleValuesChange}
      >
        <Row gutter={15} className="custom__post-content">
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Row gutter={15}>
              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <Form.Item
                  label={
                    <RequiredLabel title={t('entity.admin.customsPost.code')} />
                  }
                  name="code"
                >
                  <Input
                    disabled={post.deleted}
                    onChange={(e) => {
                      form.setFieldsValue({
                        code: maxLengthValidator(e.target.value, 10),
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <Form.Item
                  label={
                    <RequiredLabel
                      title={t('entity.admin.customsPost.newCode')}
                    />
                  }
                  name="newCode"
                >
                  <Input
                    disabled={post.deleted}
                    onChange={(e) => {
                      form.setFieldsValue({
                        newCode: maxLengthValidator(e.target.value, 10),
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <Form.Item
                  label={t('entity.admin.customsPost.geographicalPosition')}
                  name="geographicalPosition"
                >
                  <Select
                    allowClear
                    disabled={post.deleted}
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) !== -1
                    }
                  >
                    {dictionaries.geographics.map((option) => (
                      <Select.Option key={option.code} value={option.code}>
                        {`${option.name}`}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label={
                    <RequiredLabel
                      title={t('entity.admin.customsPost.address')}
                    />
                  }
                  name="address"
                >
                  <Input
                    disabled={post.deleted}
                    onChange={(e) => {
                      form.setFieldsValue({
                        address: maxLengthValidator(e.target.value, 300),
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label={
                    <RequiredLabel
                      title={t('entity.admin.customsPost.statute')}
                    />
                  }
                  name="statute"
                >
                  <Select
                    allowClear
                    disabled={post.deleted}
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) !== -1
                    }
                  >
                    {dictionaries.statutes.map((option) => (
                      <Select.Option key={option.code} value={option.code}>
                        {`${option.name}`}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label={
                    <RequiredLabel title={t('entity.admin.customsPost.name')} />
                  }
                  name="name"
                >
                  <Input
                    disabled={post.deleted}
                    onChange={(e) => {
                      form.setFieldsValue({
                        name: maxLengthValidator(e.target.value, 400),
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label={
                    <RequiredLabel
                      title={t('entity.admin.customsPost.crossingType')}
                    />
                  }
                  name="crossingType"
                >
                  <Select
                    allowClear
                    disabled={post.deleted}
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) !== -1
                    }
                  >
                    {dictionaries.types.map((option) => (
                      <Select.Option key={option.code} value={option.code}>
                        {`${option.name}`}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row
              style={{ flexFlow: 'nowrap', justifyContent: 'space-between' }}
            >
              <Col>
                <Form.Item name="hasScale" valuePropName="checked">
                  <Checkbox defaultChecked="false" disabled={post.deleted}>
                    Cântar
                  </Checkbox>
                </Form.Item>
                <Form.Item name="hasWarehouse" valuePropName="checked">
                  <Checkbox defaultChecked="false" disabled={post.deleted}>
                    Transbordare
                  </Checkbox>
                </Form.Item>
                <Form.Item name="allowSARA" valuePropName="checked">
                  <Checkbox defaultChecked="false" disabled={post.deleted}>
                    Serviciul SARA
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="hasVicos" valuePropName="checked">
                  <Checkbox defaultChecked="false" disabled={post.deleted}>
                    VICOS
                  </Checkbox>
                </Form.Item>

                <Form.Item name="hasScan" valuePropName="checked">
                  <Checkbox defaultChecked="false" disabled={post.deleted}>
                    Scan
                  </Checkbox>
                </Form.Item>
                <Form.Item name="hasSeed" valuePropName="checked">
                  <Checkbox defaultChecked="false" disabled={post.deleted}>
                    Seed
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="hasAnta" valuePropName="checked">
                  <Checkbox defaultChecked="false" disabled={post.deleted}>
                    ANTA
                  </Checkbox>
                </Form.Item>
                <Form.Item name="hasAnsa" valuePropName="checked">
                  <Checkbox defaultChecked="false" disabled={post.deleted}>
                    ANSA
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="hasMdUa" valuePropName="checked">
                  <Checkbox defaultChecked="false" disabled={post.deleted}>
                    MD-UA
                  </Checkbox>
                </Form.Item>
                <Form.Item name="hasVignette" valuePropName="checked">
                  <Checkbox defaultChecked="false" disabled={post.deleted}>
                    VINIETA
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="addTrackButtonEnabled" valuePropName="checked">
                  <Checkbox
                    defaultChecked={isButtonEnabled}
                    disabled={post.deleted}
                    onChange={() => setIsButtonEnabled(!isButtonEnabled)}
                  >
                    Permite adăugarea manuală a rutelor
                  </Checkbox>
                </Form.Item>

                <Form.Item name="allowSMS" valuePropName="checked">
                  <Checkbox defaultChecked="false" disabled={post.deleted}>
                    Serviciul SMS Consemne
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <div>
              <Row gutter={15}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="phoneNumber"
                    label="Nr. Telefon"
                    initialValue={post.postContactInfo?.phoneNumber}
                  >
                    <Input
                      disabled={post.deleted}
                      onChange={(e) => {
                        form.setFieldsValue({
                          phoneNumber: maxLengthValidator(e.target.value, 15),
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="faxNumber"
                    label="FAX"
                    initialValue={post.postContactInfo?.faxNumber}
                  >
                    <Input
                      disabled={post.deleted}
                      onChange={(e) => {
                        form.setFieldsValue({
                          faxNumber: maxLengthValidator(e.target.value, 15),
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name="email"
                label="email"
                initialValue={post.postContactInfo?.email}
              >
                <Input
                  disabled={post.deleted}
                  onChange={(e) => {
                    form.setFieldsValue({
                      email: maxLengthValidator(e.target.value, 300),
                    });
                  }}
                />
              </Form.Item>
            </div>
            <div>
              <Divider plain>
                <span> Infrastructura </span>
              </Divider>

              <h3> Numărul de colaboratori </h3>
              <Row gutter={15}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item name="personal" label="Total">
                    <Input
                      disabled={post.deleted}
                      onChange={(e) => {
                        form.setFieldsValue({
                          personal: numberValidator(e.target.value, 10),
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item name="personalPerTur" label="Pe tură">
                    <Input
                      disabled={post.deleted}
                      onChange={(e) => {
                        form.setFieldsValue({
                          personalPerTur: numberValidator(e.target.value, 10),
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <h3>Separarea sensurilor de circulaţie pe tip de transport</h3>
              <Row gutter={15}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item name="tracksTrucks" label="Camioane">
                    <Input
                      disabled={post.deleted}
                      onChange={(e) => {
                        form.setFieldsValue({
                          tracksTrucks: numberValidator(e.target.value, 10),
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item name="tracksBusses" label="Autocare">
                    <Input
                      disabled={post.deleted}
                      onChange={(e) => {
                        form.setFieldsValue({
                          tracksBusses: numberValidator(e.target.value, 10),
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item name="tracksCars" label="Autoturisme">
                <Input
                  disabled={post.deleted}
                  onChange={(e) => {
                    form.setFieldsValue({
                      tracksCars: numberValidator(e.target.value, 10),
                    });
                  }}
                />
              </Form.Item>

              <div>
                <h3> Numărul de sensuri </h3>
                <Row gutter={15}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item name="tracksIn" label="Intrare">
                      <Input
                        disabled={post.deleted}
                        onChange={(e) => {
                          form.setFieldsValue({
                            tracksIn: numberValidator(e.target.value, 10),
                          });
                        }}
                      />
                    </Form.Item>
                    <Form.Item name="tracksOut" label="Ieșire">
                      <Input
                        disabled={post.deleted}
                        onChange={(e) => {
                          form.setFieldsValue({
                            tracksOut: numberValidator(e.target.value, 10),
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item name="tracksSpecialCD" label="Speciale CD">
                      <Input
                        disabled={post.deleted}
                        onChange={(e) => {
                          form.setFieldsValue({
                            tracksSpecialCD: numberValidator(
                              e.target.value,
                              10,
                            ),
                          });
                        }}
                      />
                    </Form.Item>
                    <Form.Item name="tracksAEO" label="AEO">
                      <Input
                        disabled={post.deleted}
                        onChange={(e) => {
                          form.setFieldsValue({
                            tracksAEO: numberValidator(e.target.value, 10),
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={15}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item name="tracksCargo" label="Marfă">
                      <Input
                        disabled={post.deleted}
                        onChange={(e) => {
                          form.setFieldsValue({
                            tracksCargo: numberValidator(e.target.value, 10),
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item name="tracksPassengers" label="Pasageri">
                      <Input
                        disabled={post.deleted}
                        onChange={(e) => {
                          form.setFieldsValue({
                            tracksPassengers: numberValidator(
                              e.target.value,
                              10,
                            ),
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item name="tracksOversize" label="Cu gabarit depăşit">
                  <Input
                    disabled={post.deleted}
                    onChange={(e) => {
                      form.setFieldsValue({
                        tracksOversize: numberValidator(e.target.value, 10),
                      });
                    }}
                  />
                </Form.Item>
              </div>
              <Row gutter={15}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <h3> Teritorii suplimentare </h3>
                  <Row style={{ gap: '20px' }}>
                    <Form.Item name="hasParking" valuePropName="checked">
                      <Checkbox defaultChecked="false" disabled={post.deleted}>
                        Parcări
                      </Checkbox>
                    </Form.Item>
                    <Form.Item name="hasTerminal" valuePropName="checked">
                      <Checkbox defaultChecked="false" disabled={post.deleted}>
                        Terminal
                      </Checkbox>
                    </Form.Item>
                  </Row>
                  <Form.Item name="other" label="Altele">
                    <Input
                      disabled={post.deleted}
                      onChange={(e) => {
                        form.setFieldsValue({
                          other: maxLengthValidator(e.target.value, 300),
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <h3> Condiţii</h3>
                  <Form.Item name="hasSanitaryGroup" valuePropName="checked">
                    <Checkbox defaultChecked="false" disabled={post.deleted}>
                      Grup sanit. pub.
                    </Checkbox>
                  </Form.Item>
                  <Form.Item name="hasMedicalCentre" valuePropName="checked">
                    <Checkbox defaultChecked="false" disabled={post.deleted}>
                      Punct medical
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="hasTelecommunicationStation"
                    valuePropName="checked"
                  >
                    <Checkbox defaultChecked="false" disabled={post.deleted}>
                      Mijloace pentru telecomunicaţii pentru şoferi şi pasageri
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={15}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <h3> Spaţiu de depozitare</h3>
                  <Form.Item name="hasDepositPV" valuePropName="checked">
                    <Checkbox defaultChecked="false" disabled={post.deleted}>
                      {
                        // eslint-disable-next-line
                        `Depozit PV (TV-20)`
                      }
                    </Checkbox>
                  </Form.Item>
                  <Form.Item name="hasTemporaryDeposit" valuePropName="checked">
                    <Checkbox defaultChecked="false" disabled={post.deleted}>
                      Depozit provizoriu
                    </Checkbox>
                  </Form.Item>
                  <Form.Item name="hasCustomsWarehouse" valuePropName="checked">
                    <Checkbox defaultChecked="false" disabled={post.deleted}>
                      Antrepozit vamal
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <h3>Informaţie suplimentară</h3>
                  <Form.Item name="hasDutyFree" valuePropName="checked">
                    <Checkbox defaultChecked="false" disabled={post.deleted}>
                      Duty Free
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="hasTranslationOffice"
                    valuePropName="checked"
                  >
                    <Checkbox defaultChecked="false" disabled={post.deleted}>
                      Birou de traduceri
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <h3> Condiţii</h3>
              <Row style={{ justifyContent: 'space-between' }}>
                <Col>
                  <Form.Item name="hasBroke" valuePropName="checked">
                    <Checkbox defaultChecked="false" disabled={post.deleted}>
                      Broke
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="hasFitoVeto" valuePropName="checked">
                    <Checkbox defaultChecked="false" disabled={post.deleted}>
                      Fito-Veto
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="hasBank" valuePropName="checked">
                    <Checkbox defaultChecked="false" disabled={post.deleted}>
                      Banc
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="hasConsulate" valuePropName="checked">
                    <Checkbox defaultChecked="false" disabled={post.deleted}>
                      Servicii consulare
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="hasInPostTerminal" valuePropName="checked">
                    <Checkbox defaultChecked="false" disabled={post.deleted}>
                      Terminal
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>

              <Divider plain>
                <span> Mijloace de control informațional </span>
              </Divider>
              <Row style={{ justifyContent: 'space-between' }}>
                <Col>
                  <Form.Item name="hasAsycuda" valuePropName="checked">
                    <Checkbox defaultChecked="false" disabled={post.deleted}>
                      Asycuda
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="hasStatisticalPortal"
                    valuePropName="checked"
                  >
                    <Checkbox defaultChecked="false" disabled={post.deleted}>
                      Portal statistic
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="hasAntifraud" valuePropName="checked">
                    <Checkbox defaultChecked="false" disabled={post.deleted}>
                      Antifraudă
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="hasMdUaPortal" valuePropName="checked">
                    <Checkbox defaultChecked="false" disabled={post.deleted}>
                      Portal MDUA
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Divider plain>
                <span> Energie alternativă </span>
              </Divider>
              <Form.Item name="hasGenerator" valuePropName="checked">
                <Checkbox defaultChecked="false" disabled={post.deleted}>
                  Generator
                </Checkbox>
              </Form.Item>
              <label>Informaţie suplimentară</label>
              <Form.Item name="additionalInformation">
                <TextArea showCount maxLength={3000} disabled={post.deleted} />
              </Form.Item>
              <Form.Item>
                <Space>
                  <SaveButton disabled={post.deleted} />
                  {post?.id ? (
                    <Button
                      key="generatePassport"
                      style={{ float: 'left' }}
                      onClick={handleGeneratePdf}
                      icon={<FileTextOutlined />}
                    >
                      Generează pașaport
                    </Button>
                  ) : null}
                  <CancelButton onCancel={onCancel} />
                </Space>
              </Form.Item>
            </div>
          </Col>

          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            {post?.id ? (
              <div className="custome__image">
                <div className="personal__data-info-image">
                  <div
                    className={`personal__data-info--loading ${
                      avatarLoading ? 'active' : ''
                    }`}
                  >
                    <Spin size="large" />
                  </div>
                  <AvatarComponent
                    src={postImageSrc}
                    size={200}
                    removable
                    onClear={() => uploadPostImage(null)}
                    onUpload={uploadPostImage}
                  />
                </div>
                <div className="custome__image-info">
                  <div
                    className="custome__image-info--name"
                    style={{ fontWeight: 600 }}
                  >
                    ({post.code}) {post.name}
                  </div>
                </div>
              </div>
            ) : null}
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <h3>Camere de securitate</h3>
                {!post.deleted && (
                  <Button
                    type="primary"
                    onClick={() => {
                      getNewCameraUUID();
                    }}
                    icon={<PlusOutlined />}
                  >
                    Adaugă cameră
                  </Button>
                )}
              </div>
              <Form.Item name="securityCameras" noStyle>
                <CameraTable />
              </Form.Item>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: '40px',
                }}
              >
                <h3>Mijloace tehnice de control vamal</h3>
                {!post.deleted && (
                  <Button
                    type="primary"
                    style={{ marginBottom: '.5rem' }}
                    onClick={() => setEndowmentModalVisible(true)}
                    icon={<PlusOutlined />}
                  >
                    Adaugă mijloc tehnic
                  </Button>
                )}
              </div>
              <Form.Item name="customsEndowment" noStyle>
                <EndowmentTable />
              </Form.Item>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: '40px',
                }}
              >
                <h3>Metodă creare post vamal</h3>
                {!post.deleted && (
                  <Button
                    type="primary"
                    style={{ marginBottom: '.5rem' }}
                    onClick={() => setCreationMethodModalVisible(true)}
                    icon={<PlusOutlined />}
                  >
                    Adaugă metodă
                  </Button>
                )}
              </div>
              <Form.Item name="customsCreationMethods" noStyle>
                <CreationMethodTable />
              </Form.Item>
            </div>
          </Col>
        </Row>

        <Modal
          maskClosable={false}
          width={600}
          centered
          title="Mijloc tehnic de control vamal"
          visible={endowmentModalVisible}
          onOk={handleEndowmentOk}
          onCancel={() => {
            setEndowmentModalVisible(false);
          }}
          cancelText="Înapoi"
        >
          <Col style={{ gap: '20px' }}>
            <Col>
              <Form.Item
                name="editEndowmentType"
                label={<RequiredLabel title="Mijloc tehnic" />}
                labelCol={{ span: 24 }}
                className="required__label"
              >
                <Select
                  allowClear
                  disabled={post.deleted}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) !== -1
                  }
                >
                  {endowments?.content?.map(
                    (option) =>
                      !option.deleted && (
                        <Select.Option key={option.id} value={option.id}>
                          {`${option.name}`}
                        </Select.Option>
                      ),
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="editEndowmentSerialNumber"
                labelCol={{ span: 24 }}
                label={<RequiredLabel title="Seria" />}
              >
                <Input disabled={post.deleted} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="editEndowmentCondition"
                labelCol={{ span: 24 }}
                label={<RequiredLabel title="Starea tehnică" />}
              >
                <Input
                  disabled={post.deleted}
                  style={{ textTransform: 'uppercase' }}
                />
              </Form.Item>
            </Col>
          </Col>
        </Modal>
        <Modal
          maskClosable={false}
          width={500}
          centered
          title="Metodă creare post vamal"
          visible={isCreationMethodModalVisible}
          onOk={handleCreationMethodOk}
          onCancel={() => {
            form.setFieldsValue({
              editCreationMethodId: null,
              editCreationMethodType: null,
              editCreationMethodCode: null,
              editCreationMethodDate: null,
            });
            setCreationMethodModalVisible(false);
          }}
          cancelText="Înapoi"
        >
          <Col style={{ gap: '20px' }}>
            <Form.Item
              name="editCreationMethodType"
              label={<RequiredLabel title="Ordin" />}
              labelCol={{ span: 24 }}
            >
              <Select
                allowClear
                disabled={post.deleted}
                showSearch
                onChange={checkCreationMethod}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) !==
                  -1
                }
              >
                {dictionaries.creationMethod.map((option) => (
                  <Select.Option key={option.code} value={option.code}>
                    {`${option.name}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="editCreationMethodCode"
              label={<RequiredLabel title="Nr." />}
              labelCol={{ span: 24 }}
            >
              <Input disabled={post.deleted} />
            </Form.Item>
            <Form.Item
              name="editCreationMethodDate"
              label={<RequiredLabel title="Data creării" />}
              labelCol={{ span: 24 }}
            >
              <DatePicker disabled={post.deleted} />
            </Form.Item>
          </Col>
        </Modal>

        <Modal
          maskClosable={false}
          width="50vw"
          centered
          title={currentSecurityCamera ? 'Editare cameră' : 'Adaugă cameră'}
          visible={isCameraModalVisible}
          onOk={handleAddCamera}
          onCancel={handleCancelCamera}
          cancelText="Înapoi"
        >
          <div style={{ marginBottom: '20px' }}>
            <Form.Item
              name="addCameraDescription"
              label={<RequiredLabel title="Descriere" />}
              labelCol={{ span: 8 }}
            >
              <Input disabled={post.deleted} />
            </Form.Item>

            <Form.Item
              name="addAddress"
              label={<RequiredLabel title="Adresa" />}
              labelCol={{ span: 8 }}
            >
              <Input disabled={post.deleted} />
            </Form.Item>
          </div>

          <div style={{ marginBottom: '20px' }}>
            <Form.Item
              name="addDirection"
              label={<RequiredLabel title="Direcția" />}
              labelCol={{ span: 8 }}
            >
              <Select disabled={post.deleted}>
                {dictionaries.directions.map((option) => (
                  <Select.Option key={option.code} value={option.code}>
                    {option.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="addOrientation"
              label={<RequiredLabel title="Orientare" />}
              labelCol={{ span: 8 }}
            >
              <Select disabled={post.deleted}>
                {dictionaries.orientations.map((option) => (
                  <Select.Option key={option.code} value={option.code}>
                    {option.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div style={{ marginBottom: '20px' }}>
            <Form.Item
              name="addBQS"
              label={<RequiredLabel title="Aparține sistemului BQS" />}
              labelCol={{ span: 8 }}
              valuePropName="checked"
            >
              <Checkbox defaultChecked="false" disabled={post.deleted} />
            </Form.Item>

            <Form.Item
              name="addParking"
              label={<RequiredLabel title="Orientată spre parcare" />}
              labelCol={{ span: 8 }}
              valuePropName="checked"
            >
              <Checkbox defaultChecked="false" disabled={post.deleted} />
            </Form.Item>

            <Form.Item
              name="addCheckAccess"
              label={<RequiredLabel title="Ridica bariera (check access)" />}
              labelCol={{ span: 8 }}
              valuePropName="checked"
            >
              <Checkbox defaultChecked="false" disabled={post.deleted} />
            </Form.Item>
          </div>
        </Modal>
      </Form>
    </>
  );
};

export default EditCustomsPostForm;
