import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { subDays } from 'date-fns';
import dayjs from 'dayjs';

import { Col, DatePicker, Divider, Empty, Form, Row, Spin, Switch } from 'antd';
import {
  getCrossing,
  getTimeline,
} from '../../../services/transactions/vehicles';
import EntranceExitTimeline from '../../lucrative/crossing-history/EntranceExitTimeline';
import CrossingsTimeline from '../../lucrative/crossing-history/CrossingsTimeline';

const dateFormat = 'DD.MM.YYYY';

const GeneralDataTimeLine = ({
  form,
  isFromMd,
  reloadDependency,
  showSwitch = true,
}) => {
  const [showTimelines, setShowTimelines] = useState(!showSwitch);
  const [timelines, setTimelines] = useState([]);
  const [crossings, setCrossings] = useState([]);
  const [timeLoader, setTimeLoader] = useState(false);
  const vehicleVinCode = form.getFieldValue('vehicleVinCode');
  const personalCode = form.getFieldValue('personalCode');

  const getDateMinusDays = (daysOffset) => {
    return subDays(new Date(), daysOffset);
  };

  const defaultTimelineDates = useMemo(
    () => ({
      fromDate: getDateMinusDays(30),
      toDate: new Date(),
    }),
    [],
  );

  useEffect(() => {
    if (vehicleVinCode && vehicleVinCode.length >= 17) {
      getTimeline({
        vinCode: vehicleVinCode,
        fromDate: defaultTimelineDates.fromDate.getTime(),
        toDate: defaultTimelineDates.toDate.getTime(),
      }).then((response) => {
        setTimelines(response);
      });
    } else {
      setTimelines([]);
    }

    if (vehicleVinCode && vehicleVinCode.length >= 17 && personalCode) {
      getCrossing({
        vinCode: vehicleVinCode,
        personalCode,
        fromDate: defaultTimelineDates.fromDate.getTime(),
        toDate: defaultTimelineDates.toDate.getTime(),
      }).then((response) => {
        setCrossings(response);
      });
    } else {
      setCrossings([]);
    }
    // eslint-disable-next-line
  }, [vehicleVinCode, personalCode, reloadDependency]);

  const handleTimelinePeriodChange = useCallback(
    (value) => {
      setTimeLoader(true);
      let fromDate = defaultTimelineDates.fromDate.getTime();
      let toDate = defaultTimelineDates.toDate.getTime();
      if (value !== null) {
        fromDate = new Date(value[0]).getTime();
        toDate = new Date(value[1]).getTime();
      } else {
        form.setFieldsValue({
          timelinePeriod: [
            dayjs(defaultTimelineDates.fromDate),
            dayjs(defaultTimelineDates.toDate),
          ],
        });
      }

      getTimeline({ vinCode: vehicleVinCode, fromDate, toDate }).then(
        (response) => {
          setTimelines(response);
        },
      );
      getCrossing({ vinCode: vehicleVinCode, personalCode, fromDate, toDate })
        .then((response) => {
          setCrossings(response);
        })
        .finally(() => setTimeLoader(false));
    },
    [
      form,
      vehicleVinCode,
      personalCode,
      defaultTimelineDates.fromDate,
      defaultTimelineDates.toDate,
    ],
  );

  return (
    <>
      {showSwitch ? (
        <Divider>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <Switch
              type="primary"
              style={{ marginBottom: '20px' }}
              onChange={() => setShowTimelines(!showTimelines)}
              className="timeline__switch"
              checked={showTimelines}
            />
            <span>Arată istoric traversari</span>
          </div>
        </Divider>
      ) : null}
      <Row>
        {showTimelines && (
          <Form.Item
            label="Alege perioada"
            name="timelinePeriod"
            style={{ marginLeft: '20px' }}
            initialValue={[
              dayjs(defaultTimelineDates.fromDate),
              dayjs(defaultTimelineDates.toDate),
            ]}
          >
            <DatePicker.RangePicker
              onChange={handleTimelinePeriodChange}
              format={dateFormat}
            />
          </Form.Item>
        )}
      </Row>

      {showTimelines ? (
        <div
          className={`time__loader ${timeLoader ? 'active' : ''}`}
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Spin />
        </div>
      ) : null}

      {timelines?.length === 0 && crossings?.length === 0 && showTimelines ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={false}
            style={{ marginBottom: '10px' }}
          />
          <h3 style={{ fontWeight: '600' }}>
            În această perioadă nu a fost găsită nicio traversare
          </h3>
        </div>
      ) : null}
      <>
        <Row>
          <Col span={12}>
            <EntranceExitTimeline
              timelines={timelines}
              show={showTimelines && timelines?.length > 0}
              isFromMd={isFromMd}
            />
          </Col>

          <Col span={12}>
            <CrossingsTimeline
              crossings={crossings}
              show={showTimelines && crossings?.length > 0}
            />
          </Col>
        </Row>
      </>
    </>
  );
};

export default GeneralDataTimeLine;
