import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Avatar, Tooltip } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import ActivityStatus from '../enums/ActivityStatus';
import { stringLengthValidator } from '../../../services/utils/validator/Validator';
import { getBlobFile } from '../../../services/admin/files';

export const ChatEntity = ({
  imageId = null,
  entityId,
  entityName,
  base64Image = null,
  status = null,
  onClick,
  isSelected = false,
  messageCount,
  entityType,
  selectedUsersForGroup,
  rightSideSmallChat,
  ...rest
}) => {
  const [imageUrl, setImgUrl] = useState('');

  useEffect(() => {
    if (base64Image && imageId === null) {
      const arrayBuffer = Uint8Array.from(atob(base64Image), (c) =>
        c.charCodeAt(0),
      ).buffer;
      const blob = new Blob([arrayBuffer]);
      setImgUrl(URL.createObjectURL(blob));
    } else if (imageId) {
      getBlobFile(imageId)
        .then((res) => {
          setImgUrl(URL.createObjectURL(res));
        })
        .catch((error) => {
          console.error('Error fetching blob file:', error);
        });
    }
    return () => (imageUrl ? URL.revokeObjectURL(imageUrl) : () => {});
    // eslint-disable-next-line
  }, [base64Image, imageId]);

  const handleClick = useCallback(() => {
    if (onClick && typeof onClick === 'function') {
      onClick({ id: entityId, name: entityName, imageUrl });
    }
  }, [onClick, entityId, entityName, imageUrl]);

  const statusClass = useMemo(() => {
    if (status === ActivityStatus.ONLINE) {
      return 'active';
    }
    if (status === ActivityStatus.OFFLINE) {
      return 'inactive';
    }
    if (status === ActivityStatus.DO_NOT_DISTURB) {
      return 'doNotDisturb';
    }
    return null;
  }, [status]);

  const renderMicroUser = () => {
    return (
      <>
        {imageUrl ? (
          <Avatar
            className="chat__user-avatar"
            src={imageUrl}
            alt="user-avatar"
          />
        ) : (
          <Avatar className="chat__user-avatar" icon={<UserOutlined />} />
        )}
        <div className="chat__user-data">
          <div className="chat__user-name">
            {stringLengthValidator(entityName, 15)}
          </div>
        </div>
        <div className={`chat__user-status ${statusClass}`} />
        {messageCount && messageCount > 0 ? (
          <div className="chat__user-unread">{messageCount}</div>
        ) : null}
      </>
    );
  };

  return (
    <button
      {...rest}
      className={`chat__user ${isSelected ? 'selected' : ''}`}
      type="button"
      onClick={handleClick}
    >
      {rightSideSmallChat ? (
        <Tooltip placement="left" title={stringLengthValidator(entityName, 35)}>
          {renderMicroUser()}
        </Tooltip>
      ) : (
        renderMicroUser()
      )}
    </button>
  );
};
