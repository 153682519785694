import React from 'react';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, Tooltip } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

const ViewItemIcon = ({ title, path, disabled = false, action = null }) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Tooltip title={t('actions.view')}>
      <Button
        shape="circle"
        type="link"
        icon={<EyeOutlined />}
        onClick={() => (action != null ? action() : history.push(path))}
        disabled={disabled}
      >
        {title || ''}
      </Button>
    </Tooltip>
  );
};

export default ViewItemIcon;
