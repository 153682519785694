import { Divider } from 'antd';
import React from 'react';

const AccizeCalculator = ({ form }) => {
  return (
    <div className="accize">
      <Divider plain />
      <div className="accize__calculator-container">
        <h3>
          Anul producerii
          <hr />
          {form.getFieldValue('fabricationYear')}
        </h3>
        <h3>
          Capacitatea cilindrica
          <hr />
          {form.getFieldValue('capacity')}
        </h3>
        <h3>
          Prețul (Lei)
          <hr />
          {form.getFieldValue('price')}
        </h3>
      </div>
      <div className="accize__calculator-sum">
        <h3>
          Suma accizelor (Lei)
          <hr />
          {form.getFieldValue('transportTaxation')}
        </h3>
        <h3>
          Cota de acciz lux (Lei)
          <hr />
          {form.getFieldValue('taxationLux')}
        </h3>
      </div>
      <div className="accize__calculator-total">
        <h3>
          Suma totală (Lei)
          <hr />
          {form.getFieldValue('transportTaxation') +
            form.getFieldValue('taxationLux') +
            form.getFieldValue('price')}
        </h3>
      </div>
    </div>
  );
};
export default AccizeCalculator;
