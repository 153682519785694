import { Checkbox } from 'antd';
import React from 'react';
import Icon from './Icon';

const DirectionCheckboxes = ({
  setIsAuto,
  isAuto,
  setIsCargo,
  isCargo,
  user,
  setOutCheckbox,
  outCheckbox,
  setInCheckbox,
  inCheckbox,
  reload,
}) => {
  return (
    <div
      className="home__header-navigation"
      style={{
        display: 'flex',
        height: '40px',
      }}
    >
      <Checkbox
        defaultChecked={inCheckbox}
        disabled={user.function === 'INSPECTOR' && user.direction !== null}
        onChange={() => {
          setInCheckbox((prev) => !prev);
          reload();
        }}
        className={`checkbox__background direction__checkbox
      ${inCheckbox ? 'active' : 'pasive'}`}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <Icon
            name="enterDirection"
            style={{
              width: '19px',
              height: '19px',
              fill: '#000',
              marginLeft: '10px',
            }}
          />
          <span style={{ fontSize: '1.1rem', fontWeight: '600' }}>Intrare</span>
        </div>
      </Checkbox>

      <Checkbox
        defaultChecked={outCheckbox}
        disabled={user.function === 'INSPECTOR' && user.direction !== null}
        onChange={() => {
          setOutCheckbox((prev) => !prev);
          reload();
        }}
        className={`checkbox__background direction__checkbox
      ${outCheckbox ? 'active' : 'pasive'}`}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <Icon
            name="exitDirection"
            style={{
              width: '18px',
              height: '18px',
              fill: '#000',
              marginLeft: '10px',
            }}
          />
          <span style={{ fontSize: '1.1rem', fontWeight: '600' }}>Ieșire</span>
        </div>
      </Checkbox>
      <Checkbox
        defaultChecked={isAuto}
        onChange={() => {
          setIsAuto((prev) => !prev);
          reload();
        }}
        className={`checkbox__background 
      ${isAuto ? 'active' : 'pasive'}`}
      >
        <span style={{ fontSize: '1.1rem', fontWeight: '600' }}>
          Autoturisme
        </span>
      </Checkbox>
      <Checkbox
        defaultChecked={isCargo}
        onChange={() => {
          setIsCargo((prev) => !prev);
          reload();
        }}
        className={`checkbox__background 
      ${isCargo ? 'active' : 'pasive'}`}
      >
        <span style={{ fontSize: '1.1rem', fontWeight: '600' }}>Camioane</span>
      </Checkbox>
    </div>
  );
};

export default DirectionCheckboxes;
