import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@ant-design/pro-layout';
import { Form, Input, Row, Col, Space } from 'antd';

import SaveButton from '../../../buttons/SaveButton';
import CancelButton from '../../../buttons/BackButton';

import useFormErrors from '../../../../hooks/useFormErrors';
import {
  maxLengthValidator,
  numberValidator,
} from '../../../../services/utils/validator/Validator';

const EditAuthorizationTypeForm = ({ post, onSubmit, onCancel, errors }) => {
  const { t } = useTranslation();
  const { TextArea } = Input;

  const [form] = Form.useForm();
  useFormErrors(form, errors);

  useEffect(() => {
    form.setFieldsValue(post);
  }, [post, form]);

  return (
    <>
      <PageHeader title={t('entity.admin.authorizationType._singular')} />
      <Form
        form={form}
        layout="vertical"
        initialValues={post}
        onFinish={onSubmit}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={t(
                'entity.admin.authorizationType.thirdPartyAuthorizationId',
              )}
              name="thirdPartyAuthorizationId"
            >
              <Input
                disabled={post.deleted}
                onChange={(e) => {
                  form.setFieldsValue({
                    thirdPartyAuthorizationId: numberValidator(
                      e.target.value,
                      19,
                    ),
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label={t('entity.admin.authorizationType.code')}
              name="code"
            >
              <Input
                minLength={3}
                disabled={post.deleted}
                onChange={(e) => {
                  form.setFieldsValue({
                    code: maxLengthValidator(e.target.value, 20),
                  });
                }}
              />
            </Form.Item>

            <Form.Item
              label={t('entity.admin.authorizationType.name')}
              name="name"
            >
              <Input
                disabled={post.deleted}
                onChange={(e) => {
                  form.setFieldsValue({
                    name: maxLengthValidator(e.target.value, 100),
                  });
                }}
              />
            </Form.Item>

            <Form.Item
              label={t('entity.admin.authorizationType.usageConditions')}
              name="usageConditions"
            >
              <TextArea disabled={post.deleted} showCount maxLength={2000} />
            </Form.Item>

            <Form.Item
              label={t('entity.admin.authorizationType.remark')}
              name="remark"
            >
              <TextArea disabled={post.deleted} showCount maxLength={2000} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Space>
            <SaveButton disabled={post.deleted} />
            <CancelButton onCancel={onCancel} />
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default EditAuthorizationTypeForm;
