import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import ControlReasonsList from '../../../../components/admin/taxonomies/controlReasons/ControlReasonsList';

const ControlReasons = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="controlReason">
      <Tabs.TabPane
        tab={t('entity.admin.controlReason._plural')}
        key="controlReason"
      >
        <ControlReasonsList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default ControlReasons;
