import React, { useEffect, useCallback, useState } from 'react';
import {
  Form,
  Row,
  Col,
  Input,
  Select,
  Space,
  Divider,
  Button,
  Typography,
  notification,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { RightCircleOutlined } from '@ant-design/icons';
import { FormContextProvider } from '../../hooks/useFormContext';
import { addAnsa } from '../../services/admin/web-services/ansa-transactions';
import { findAll as findAllServicesDescription } from '../../services/taxonomies/services-description-ansa';

import useFormErrors from '../../hooks/useFormErrors';
import useDictionaries from '../../hooks/useDictionaries';
import {
  confirmStep,
  enableSteps,
} from '../../services/transactions/transactions';
import AppLoader from '../auth/AppLoader';
import CancelButton from '../buttons/BackButton';
import {
  dotValidator,
  maxLengthValidator,
} from '../../services/utils/validator/Validator';
import NextButton from '../buttons/NextButton';
import TransactionStatus from '../../enums/TransactionStatus';

const { Text } = Typography;
const { TextArea } = Input;

const dictionaries = {
  descriptions: findAllServicesDescription,
};

const Ansa = ({
  transaction,
  errors,
  skipTab,
  setIsAnsaAmount,
  readOnly,
  reload,
  moveNext = () => {},
  moveBack = () => {},
}) => {
  const [{ descriptions }] = useDictionaries(dictionaries);
  const [loadingLoader, setLoadingLoader] = useState(false);
  const { loading, content } = descriptions;

  const [form] = Form.useForm();
  useFormErrors(form, errors);
  const { t } = useTranslation();

  useEffect(() => {
    form.setFieldsValue(transaction.ansa);
  }, [transaction, form]);

  // eslint-disable-next-line
  const confirmANSA = useCallback(() => {
    setLoadingLoader(true);
    confirmStep(transaction.id, 'HAS_ANSA')
      .then(() => {
        notification.success({
          message: t('actions.infirm'),
          duration: 3,
        });
        skipTab('#HAS_ANSA');
      })
      .catch(() => {
        notification.error({ message: t('actions.infirmErr') });
      })
      .finally(() => {
        setLoadingLoader(false);
        reload();
      });
  }, [t, transaction.id, skipTab, reload]);

  const handleSaveData = useCallback(() => {
    if (!form.getFieldValue('controlResult')) {
      notification.error({
        message: 'Rezultatul controlului este obligatoriu',
      });
      return;
    }
    setLoadingLoader(true);
    if (form.getFieldsValue()) {
      const ansa = {
        ...form.getFieldsValue(),
        transactionId: transaction.id,
      };
      addAnsa(ansa)
        .then(() => {
          if (ansa.amountToPay && ansa.amountToPay > 0) {
            setIsAnsaAmount(true);
          }
          moveNext();
        })
        .catch(() => {
          notification.error({ message: t('confirmErr') });
        })
        .finally(() => {
          setLoadingLoader(false);
          reload();
        });
    }
  }, [setIsAnsaAmount, transaction.id, reload, form, t, moveNext]);

  const handleEnableStep = useCallback(() => {
    setLoadingLoader(true);
    enableSteps(transaction.id, 'HAS_ANSA')
      .catch(() => {
        notification.error({
          message: 'A apărut o eroare. Contactați administratorul',
        });
      })
      .finally(() => {
        setLoadingLoader(false);
        reload();
      });
  }, [transaction.id, reload]);

  return (
    <>
      <div className={`loaderOverlay ${loadingLoader ? 'active' : ''}`}>
        <AppLoader />
      </div>
      {transaction?.status === TransactionStatus.PENDING ? (
        <Button
          disabled={readOnly}
          onClick={handleEnableStep}
          type="primary"
          danger={transaction.hasAnsa}
        >
          {!transaction.hasAnsa ? 'Inițiere control' : 'Anulare control'}
        </Button>
      ) : null}

      <Divider plain>
        <Text strong>{t('entity.admin.ansa.control')}</Text>
      </Divider>
      <FormContextProvider form={form} initialValues={transaction.ansa}>
        <Form form={form} layout="vertical">
          <Row gutter={15}>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              <Form.Item label="Serviciu" name="serviceDescription">
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) !== -1
                  }
                  loading={loading}
                  disabled={readOnly || !transaction.hasAnsa}
                >
                  {content.map(
                    (option) =>
                      !option?.deleted && (
                        <Select.Option key={option.id} value={option.name}>
                          {`${option.name}`}
                        </Select.Option>
                      ),
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              <Form.Item
                label={t('entity.admin.ansa.controlResult')}
                name="controlResult"
                rules={[
                  {
                    required: transaction.hasAnsa,
                    message: t(
                      `md.customs.frontiera.servicecore.validation.constraints.RequiredIfFieldEquals.message`,
                    ),
                  },
                ]}
              >
                <Input
                  disabled={readOnly || !transaction.hasAnsa}
                  maxLength={50}
                  style={{
                    textTransform: 'uppercase',
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              <Form.Item
                label={t('entity.admin.ansa.amountToPay')}
                name="amountToPay"
              >
                <Input
                  onChange={(e) => {
                    form.setFieldsValue({
                      amountToPay: maxLengthValidator(
                        dotValidator(e.target.value),
                        10,
                      ),
                    });
                  }}
                  min={0}
                  disabled={readOnly || !transaction.hasAnsa}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label={t('entity.admin.ansa.controlDetails')}
                name="controlDetails"
              >
                <TextArea
                  showCount
                  maxLength={2000}
                  rows={4}
                  disabled={readOnly || !transaction.hasAnsa}
                  style={{
                    textTransform: 'uppercase',
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Space>
              {!readOnly && (
                <>
                  <Col span={8} style={{ width: '100%' }}>
                    <CancelButton disabled={readOnly} onCancel={moveBack} />
                  </Col>
                  <Col style={{ maxWidth: '100%' }}>
                    {transaction.hasAnsa ? (
                      <Button
                        type="primary"
                        icon={<RightCircleOutlined />}
                        onClick={handleSaveData}
                      >
                        {t('actions.next')}
                      </Button>
                    ) : (
                      <NextButton
                        navigateFunc={moveNext}
                        transactionId={transaction.id}
                        canNavigate
                      />
                    )}
                  </Col>
                </>
              )}
            </Space>
          </Form.Item>
        </Form>
      </FormContextProvider>
    </>
  );
};

export default Ansa;
