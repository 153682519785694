import { genericService, get } from '../../helpers/api';

export const SERVICE_URI = '/backend';
export const VICOS_URI = '/vicos';
export const BASE_URI = '/vicos';

export const { findAll, findOne, create, update, remove } = genericService(
  SERVICE_URI,
  BASE_URI,
);

export const { findAll: searchVicoses } = genericService(VICOS_URI, BASE_URI);

export const getLastTransactionVicos = (id) =>
  get(`${SERVICE_URI}${BASE_URI}/${id}`);

export const getLastVicosCropped = (id) =>
  get(`${SERVICE_URI}${BASE_URI}/cropped/${id}`);

export const getLastVicosCroppedByPlaterNumbe = (id, plateNumber) =>
  get(`${SERVICE_URI}${BASE_URI}/cropped/${id}/${plateNumber}`);

export const findById = (id) => get(`${SERVICE_URI}${BASE_URI}/get/${id}`);

export const getVideoPath = (id) =>
  get(`${VICOS_URI}${BASE_URI}/media-path/${id}`);
