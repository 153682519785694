import React, { useContext, useMemo, useState } from 'react';
import {
  Modal,
  Form,
  Tooltip,
  Button,
  Input,
  Table,
  Select,
  notification,
  Row,
  Col,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { useTranslation } from 'react-i18next';
import { useTableScroll } from '../../../hooks/useTableScroll';
import Column from '../../../helpers/Columns';
import AuthContext, { hasPermission } from '../../auth';
import EditItemIcon from '../../buttons/EditItemIcon';
import DeleteItemIcon from '../../buttons/DeleteItemIcon';
import AddEntityButton from '../../buttons/AddEntityButton';
import CountryDropDown from '../../DropDownComponent';
import { findAll } from '../../../services/admin/geo/countries';
import useDictionaries from '../../../hooks/useDictionaries';
import {
  addWarehouseGood,
  deleteWarehouseGood,
  editWarehouseGood,
} from '../../../services/admin/web-services/warehouse-transactions';
import {
  dotValidator,
  floatValidator,
  maxLengthValidator,
} from '../../../services/utils/validator/Validator';
import RequiredLabel from '../../../services/utils/RequiredLabel';
import AppLoader from '../../auth/AppLoader';

const dictionaries = {
  countries: findAll,
};

const MEASURE_UNITS = [
  { name: 'C62' },
  { name: 'LTR' },
  { name: 'KG' },
  { name: 'MTR' },
  { name: 'MTK' },
  { name: 'MTQ' },
];

const GoodsListModal = ({ warehouseId, goods, reload = () => {} }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { permissions } = user;
  const [visible, setVisible] = useState(false);
  const [goodsModalVisible, setGoodsModalVisible] = useState(false);
  const [loadingLoader, setLoadingLoader] = useState(false);

  const [{ countries }] = useDictionaries(dictionaries);

  const action = (data) => {
    if (data.id) {
      return editWarehouseGood(data);
    }
    return addWarehouseGood({ ...data, warehouseId });
  };

  const handleAddGoods = () => {
    if (!form.getFieldValue('quantity')) {
      notification.error({
        message: 'Cantitatea este obligatorie',
      });
      return null;
    }
    if (!form.getFieldValue('measureUnit')) {
      notification.error({
        message: 'Unintatea de măsură este obligatorie',
      });
      return null;
    }
    const data = form.getFieldsValue();
    setLoadingLoader(true);
    action(data)
      .then(() => setGoodsModalVisible(false))
      .catch(() =>
        notification.error({
          message: 'A apărut o eroare la salvarea datelor',
        }),
      )
      .finally(() => {
        reload();
        setLoadingLoader(false);
      });
    return null;
  };

  const handleOpenModal = () => {
    setVisible(true);
  };

  const handleCloseModal = () => {
    form.resetFields();
    setVisible(false);
    setGoodsModalVisible(false);
  };

  useTableScroll();
  const columns = useMemo(
    () => [
      Column.text('quantity', 'Cantitatea', {
        width: 100,
      }),
      Column.text('measureUnit', 'Unitatea de măsură', {
        width: 150,
      }),
      Column.text('description', 'Descriere', {
        width: 150,
      }),
      Column.date('createdAt', t('entity._.createdAt'), {
        width: 150,
        format: 'DD-MM-YYYY HH:mm:ss',
      }),
      Column.text('createdBy', t('entity._.createdBy'), {
        width: 150,
      }),
      Column.date('updatedAt', t('entity._.updatedAt'), {
        width: 150,
        format: 'DD-MM-YYYY HH:mm:ss',
      }),
      Column.text('updatedBy', t('entity._.updatedBy'), {
        width: 150,
      }),
      Column.actions(t('table.actions'), (record) => (
        <span style={{ textAlign: 'right' }}>
          {hasPermission(permissions, 'WAREHOUSE_ADD') && (
            <EditItemIcon
              action={() => {
                form.setFieldsValue(record);
                setGoodsModalVisible(true);
              }}
            />
          )}
          {hasPermission(permissions, 'WAREHOUSE_ADD') && (
            <DeleteItemIcon
              title="Sunteți sigur(ă) că doriți sa ștergeți bunul?"
              item={record}
              action={deleteWarehouseGood}
              reload={reload}
            />
          )}
        </span>
      )),
    ],
    [permissions, reload, form, t],
  );

  return (
    <>
      <div className={`loaderOverlay ${loadingLoader ? 'active' : ''}`}>
        <AppLoader />
      </div>
      <Tooltip title="Permis Vamal de Transbordare">
        {goods?.length > 0 ? (
          <Button
            shape="circle"
            type="link"
            icon={<EyeOutlined />}
            onClick={handleOpenModal}
          >
            Vizualizare
          </Button>
        ) : (
          <Button
            shape="circle"
            type="link"
            icon={<PlusOutlined />}
            onClick={handleOpenModal}
          >
            Adaugă
          </Button>
        )}
      </Tooltip>
      <Modal
        width={1200}
        centered
        visible={visible}
        cancelText="Înapoi"
        onCancel={handleCloseModal}
        footer={null}
        maskClosable={false}
      >
        <PageHeader
          style={{ marginTop: '20px' }}
          title="Bunuri declarate"
          extra={[
            <AddEntityButton
              key="new"
              onClick={() => {
                form.resetFields();
                setGoodsModalVisible(true);
              }}
            />,
          ]}
        />
        <Table
          scroll={{ x: 500, y: 580, drag: true }}
          rowKey="id"
          pagination={false}
          dataSource={goods}
          columns={columns}
        />
      </Modal>

      <Form form={form}>
        <Modal
          maskClosable={false}
          width={900}
          centered
          title="Adaugă bun"
          visible={goodsModalVisible}
          okText="Salvează"
          cancelText="Înapoi"
          onCancel={() => setGoodsModalVisible(false)}
          onOk={handleAddGoods}
        >
          <Form.Item labelCol={{ span: 24 }} name="id" noStyle />
          <Row gutter={15}>
            <Col span={12}>
              <Form.Item
                labelCol={{ span: 24 }}
                label={<RequiredLabel title="Cantitate" />}
                name="quantity"
              >
                <Input
                  onChange={(e) => {
                    form.setFieldsValue({
                      quantity: floatValidator(dotValidator(e.target.value), 7),
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                labelCol={{ span: 24 }}
                label={<RequiredLabel title="Unitate de măsură" />}
                name="measureUnit"
              >
                <Select style={{ width: '100%' }} allowClear>
                  {MEASURE_UNITS.map((row) => (
                    <Select.Option value={row.name} key={row.name}>
                      {row.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            labelCol={{ span: 24 }}
            label="Descriere"
            name="description"
          >
            <TextArea maxLength={1500} showCount />
          </Form.Item>
          <Row gutter={15}>
            <Col span={12}>
              <Form.Item
                labelCol={{ span: 24 }}
                label="Țara de plecare"
                name="loadCountryId"
              >
                <CountryDropDown content={countries?.content} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                labelCol={{ span: 24 }}
                label="Țara de destinație"
                name="destinationCountryId"
              >
                <CountryDropDown content={countries?.content} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col span={12}>
              <Form.Item
                labelCol={{ span: 24 }}
                label="Cantitatea efectiv operativă"
                name="operationalQuantity"
              >
                <Input
                  onChange={(e) => {
                    form.setFieldsValue({
                      operationalQuantity: floatValidator(
                        dotValidator(e.target.value),
                        7,
                      ),
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item labelCol={{ span: 24 }} label="Note" name="note">
                <Input
                  onChange={(e) => {
                    form.setFieldsValue({
                      note: maxLengthValidator(e.target.value, 300),
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Modal>
      </Form>
    </>
  );
};

export default GoodsListModal;
