import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import LicensedActivitiesList from '../../../../components/admin/taxonomies/licensedActivities/LicensedActivitiesList';

const LicensedActivities = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="licensedActivities">
      <Tabs.TabPane
        tab={t('entity.admin.licensedActivity._plural')}
        key="licensedActivities"
      >
        <LicensedActivitiesList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default LicensedActivities;
