import React, { useState, useCallback } from 'react';
import { Button, Input, notification } from 'antd';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { DeleteOutlined } from '@ant-design/icons';
import { useDropzone } from 'react-dropzone';
import Icon from '../../Icon';
import useScrollToBottom from '../../../hooks/useScrollToBottom';
import { uploadAttachments } from '../chat-service/attachment-service';
import { stringLengthValidator } from '../../../services/utils/validator/Validator';

const ChatUploader = ({
  disabled = false,
  accept,
  uploadFilesFunc = uploadAttachments,
  onUpload = () => {},
}) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [attachmentComment, setCommentValue] = useState('');
  const [ref, handleContentChange] = useScrollToBottom();
  const limitFileSize = parseInt(window._env_.FILE_SIZE_LIMIT_BYTES, 10);

  const handleRemoveFile = (file) => {
    const newFileList = uploadedFiles.filter((f) => f?.path !== file?.path);
    setUploadedFiles(newFileList);
  };

  const closeUploader = () => {
    setUploadedFiles([]);
  };

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    accept,
    onDrop: (val) => {
      if (disabled) {
        return;
      }
      setUploadedFiles((prev) => [...prev, ...val]);
      handleContentChange();
    },

    validator: (file) => {
      if (file.size > limitFileSize) {
        notification.error({
          message: 'Marimea fișierului este prea mare',
        });
      }
    },

    maxSize: limitFileSize,
  });

  const handleSendFiles = useCallback(() => {
    uploadFilesFunc(uploadedFiles)
      .then((res) => {
        onUpload({ ...res, attachmentComment });
      })
      .catch((err) => {
        notification.error({ message: err.statusText });
      });

    closeUploader();
  }, [uploadFilesFunc, uploadedFiles, onUpload, attachmentComment]);

  return (
    <>
      <div
        {...getRootProps({
          onClick: (event) => event.stopPropagation(),
        })}
        className={isDragActive ? 'drop-zone' : ''}
        style={{ outline: 0 }}
      >
        <input {...getInputProps()} />
        <button
          type="button"
          className="chat__footer-attachment"
          onClick={open}
        >
          <Icon name="attachment" className="chat__footer-attachment-icon" />
        </button>
      </div>
      <div
        className={`chat__upload-overlay ${
          uploadedFiles?.length ? 'active' : ''
        }`}
      >
        <div className="chat__upload-content">
          <div className="chat__upload-title--content">
            <div>Trimite fișierul</div>
          </div>

          <div className="chat__upload-files" ref={ref}>
            {uploadedFiles?.map((file) => {
              const lastDotIndex = file.name.lastIndexOf('.');
              const ext = file.name.substring(lastDotIndex + 1);
              return (
                <div
                  className="chat__upload-files--content"
                  key={`${file.lastModified}${file.name}`}
                >
                  <FileIcon
                    className="chat__upload-type"
                    extension={ext}
                    {...defaultStyles[ext]}
                  />
                  <div className="chat__upload-text">
                    {stringLengthValidator(file?.name, 30)}
                  </div>
                  <Button
                    type="primary"
                    icon={<DeleteOutlined />}
                    className="chat__upload-trash"
                    onClick={() => handleRemoveFile(file)}
                  />
                </div>
              );
            })}
          </div>
          <Input
            className="chat__upload-comment"
            placeholder="Adaugă un comentariu"
            value={attachmentComment}
            onChange={(e) => setCommentValue(e.target.value)}
          />
          <div className="chat__upload-footer">
            <Button
              type="primary"
              className="chat__upload-cancel"
              onClick={closeUploader}
            >
              Anulează
            </Button>
            <div className="chat__upload-right">
              <div
                {...getRootProps({
                  onClick: (event) => event.stopPropagation(),
                })}
                className={isDragActive ? 'drop-zone' : ''}
                style={{ outline: 0 }}
              >
                <input {...getInputProps()} />
                <Button
                  type="primary"
                  className="chat__upload-add"
                  onClick={open}
                >
                  Adaugă
                </Button>
              </div>

              <Button
                className="chat__footer-send"
                type="primary"
                onClick={handleSendFiles}
              >
                <Icon name="chatSend" className="chat__footer-send--icon" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatUploader;
