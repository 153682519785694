import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@ant-design/pro-layout';
import { Col, Form, Input, notification, Row, Select, Space } from 'antd';

import SaveButton from '../../buttons/SaveButton';
import CancelButton from '../../buttons/BackButton';
import Column from '../../../helpers/Columns';

import { findAll as findAllRoles } from '../../../services/admin/users/roles';
import { getUsersByPostId } from '../../../services/admin/users/users';
import { findAll as findAllCustomsPosts } from '../../../services/admin/users/customs-posts';
import { findAll as findAllCustomsOffices } from '../../../services/admin/users/customs-office';
import useDictionaries from '../../../hooks/useDictionaries';
import useFormErrors from '../../../hooks/useFormErrors';
import TableFormItemWrapper from '../../TableFormItemWrapper';
import { useTableScroll } from '../../../hooks/useTableScroll';
import AppLoader from '../../auth/AppLoader';
import {
  emailValidator,
  maxLengthValidator,
  nameValidator,
} from '../../../services/utils/validator/Validator';
import UserFunctions from '../../../enums/UserFunctions';
import AvatarComponent from '../../AvatarComponent';

const dictionaries = {
  roles: findAllRoles,
  customsPosts: findAllCustomsPosts,
  customsOffices: findAllCustomsOffices,
};

const directions = {
  content: [
    { id: 'IN', code: 'IN', name: 'INTRARE' },
    { id: 'OUT', code: 'OUT', name: 'IEȘIRE' },
  ],
};

const functions = {
  content: [
    {
      id: '0',
      code: UserFunctions.SCANN_OPERATOR,
      name: 'Operator scanner',
    },
    { id: '1', code: UserFunctions.INSPECTOR, name: 'Inspector' },
    { id: '2', code: UserFunctions.HEAD, name: 'Șef Tură' },
    { id: '3', code: UserFunctions.POST_HEAD, name: 'Șef Post Vamal' },
    { id: '4', code: UserFunctions.OFFICE_HEAD, name: 'Șef Birou Vamal' },
    { id: '5', code: UserFunctions.CENTRAL, name: 'Aparat central' },
    { id: '6', code: UserFunctions.MB_TEAM, name: 'Echipa Mobilă' },
  ],
};

const EditUserForm = ({ user, onSubmit, onCancel, errors }) => {
  const { t } = useTranslation();

  const [loadingUsers, setLoadingUsers] = useState(false);
  const [selectedFunction, setSelectedFunction] = useState(user.function);
  const [userImage, setUserImage] = useState(null);

  const [{ roles, customsPosts, customsOffices }] =
    useDictionaries(dictionaries);

  const [form] = Form.useForm();
  useFormErrors(form, errors);
  useTableScroll();

  const choseBetweenOfficePost = useCallback(() => {
    const value = form.getFieldValue('function');
    if (value === UserFunctions.CENTRAL) {
      form.setFieldsValue({
        customsOffice: customsOffices?.content?.find((el) => el.code === '1')
          ?.id,
      });
    }

    return (
      value === UserFunctions.INSPECTOR ||
      value === UserFunctions.HEAD ||
      value === UserFunctions.POST_HEAD ||
      value === UserFunctions.SCANN_OPERATOR ||
      value === UserFunctions.MB_TEAM
    );
  }, [form, customsOffices?.content]);

  const customs = choseBetweenOfficePost() ? customsPosts : customsOffices;
  const [usersContent, setUserContent] = useState([]);
  const [subordinatesIds, setSubordinatesIds] = useState(user?.subordinates);
  const [subordinatesList, setSubordinatesList] = useState([]);
  const [loadingLoader, setLoadingLoader] = useState(false);

  const handlePostChange = useCallback(
    (postId) => {
      if (!postId || selectedFunction !== UserFunctions.HEAD) {
        return;
      }
      setLoadingUsers(true);
      getUsersByPostId(postId)
        .then((resp) => {
          setUserContent(
            resp
              .sort((a, b) => a.fullname.localeCompare(b.fullname))
              .filter((el) => !el.deleted && el.id !== user?.id),
          );
        })
        .catch(() =>
          notification.error({
            message:
              'Datele despre utilizatorii postului vamal nu au putut fi extrase.',
          }),
        )
        .finally(() => setLoadingUsers(false));
    },
    [user?.id, selectedFunction],
  );

  useEffect(() => {
    if (user.customsPost || user.postId || user.customsPostList?.length === 1) {
      handlePostChange(
        user.customsPost || user.postId || user.customsPostList[0]?.id,
      );
    }
  }, [
    user.customsPostList,
    user.postId,
    user.customsPost,
    form,
    handlePostChange,
  ]);

  const columnsUsers = useMemo(
    () => [
      Column.text('fullname', t('entity.admin.user.fullname'), {
        width: 250,
        sort: (prev, next) => {
          return prev?.fullname.localeCompare(next?.fullname);
        },
      }),
      Column.dictionary(
        'function',
        t('entity.admin.user.function'),
        functions,
        {
          width: 150,
          filter: false,
          sort: (prev, next) => {
            return prev?.function.localeCompare(next?.function);
          },
          dictKey: 'code',
        },
      ),
      {
        title: 'Direcție',
        key: 'direction',
        width: 200,
        render: (sub) => (
          <Select
            style={{ width: '100%' }}
            disabled={
              (subordinatesIds?.length === 0 &&
                !user?.subordinates?.includes(sub.id)) ||
              !subordinatesIds?.includes(sub.id)
            }
            allowClear
            defaultValue={sub.direction}
            // set subordinates current stats
            onChange={(direction) => {
              setSubordinatesList((arr) => {
                const newArr = arr.filter((el) => el.id !== sub.id);
                return [
                  ...newArr,
                  {
                    id: sub.id,
                    direction,
                  },
                ];
              });
            }}
          >
            {directions.content.map((row) => (
              <Select.Option value={row.id} key={row.id}>
                {`${row.name}`}
              </Select.Option>
            ))}
          </Select>
        ),
      },
    ],
    [t, subordinatesIds, user?.subordinates],
  );

  const handleSubmit = (value) => {
    const valueToSubmit = { ...value };

    if (user.function !== value.function && value.function !== 'HEAD') {
      valueToSubmit.subordinates = [];
    } else {
      const insertedSubs = subordinatesList
        .filter((el) => subordinatesIds.includes(el.id))
        .map((sub) => ({ id: sub.id, direction: sub.direction }));

      const subsNotIn = subordinatesIds?.filter(
        (id) => !insertedSubs.map((el) => el.id).includes(id),
      );

      const subsNotInList = usersContent
        .filter((usr) => subsNotIn?.includes(usr.id))
        .map((el) => ({ id: el.id, direction: el.direction }));

      valueToSubmit.subordinates = [...insertedSubs, ...subsNotInList];
    }

    valueToSubmit.customsPostIds = Array.isArray(valueToSubmit.customsPost)
      ? valueToSubmit.customsPost
      : [valueToSubmit.customsPost];
    valueToSubmit.base64Image = userImage;

    setLoadingLoader(true);
    onSubmit(valueToSubmit).finally(() => setLoadingLoader(false));
  };

  return (
    <>
      <div className={`loaderOverlay ${loadingLoader ? 'active' : ''}`}>
        <AppLoader />
      </div>
      <PageHeader title={t('entity.admin.user._singular')} />
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          ...user,
          customsPost: user.customsPostList?.map((el) => el.id),
        }}
        onFinish={(value) => handleSubmit(value)}
      >
        <Row gutter={15}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <div
              className="user__content"
              style={{ display: 'flex', gap: '40px' }}
            >
              <div style={{ width: '100%' }}>
                <Row gutter={15}>
                  <Col span={24}>
                    <Form.Item
                      label={t('entity.admin.user.username')}
                      name="username"
                      rules={[
                        {
                          required: true,
                          message: t(
                            `md.customs.frontiera.servicecore.validation.constraints.RequiredIfFieldEquals.message`,
                          ),
                        },
                      ]}
                    >
                      <Input
                        disabled={user.deleted || user?.id}
                        onChange={(e) => {
                          form.setFieldsValue({
                            username: maxLengthValidator(
                              nameValidator(e.target.value),
                              320,
                            ),
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label={t('entity.admin.user.fullname')}
                      name="fullname"
                      rules={[
                        {
                          required: true,
                          message: t(
                            `md.customs.frontiera.servicecore.validation.constraints.RequiredIfFieldEquals.message`,
                          ),
                        },
                      ]}
                    >
                      <Input
                        disabled={user.deleted}
                        maxLength={500}
                        onChange={(e) => {
                          form.setFieldsValue({
                            fullname: maxLengthValidator(
                              nameValidator(e.target.value),
                              500,
                            ),
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label={t('entity.admin.user.function')}
                      name="function"
                      rules={[
                        {
                          required: true,
                          message: t(
                            `md.customs.frontiera.servicecore.validation.constraints.RequiredIfFieldEquals.message`,
                          ),
                        },
                      ]}
                    >
                      <Select
                        onChange={(val) => {
                          setSelectedFunction(val);
                          form.setFieldsValue({ customsPost: undefined });
                        }}
                        style={{ minWidth: '120px' }}
                        allowClear
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) !== -1
                        }
                        disabled={user.deleted}
                      >
                        {functions.content.map((option) => (
                          <Select.Option key={option.id} value={option.code}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                {form.getFieldValue('function') && (
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label={
                          choseBetweenOfficePost()
                            ? t('entity.admin.user.customsPost')
                            : t('entity.admin.user.customsOffice')
                        }
                        name={
                          choseBetweenOfficePost()
                            ? 'customsPost'
                            : 'customsOffice'
                        }
                        rules={[
                          {
                            required: true,
                            message: t(
                              `md.customs.frontiera.servicecore.validation.constraints.RequiredIfFieldEquals.message`,
                            ),
                          },
                        ]}
                      >
                        <Select
                          style={{ minWidth: '120px' }}
                          mode={
                            selectedFunction === UserFunctions.POST_HEAD
                              ? 'multiple'
                              : null
                          }
                          allowClear
                          onChange={handlePostChange}
                          disabled={user.deleted}
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) !== -1
                          }
                        >
                          {customs.content
                            ?.sort((a, b) => (a.code < b.code ? -1 : 0))
                            .map(
                              (option) =>
                                !option?.deleted && (
                                  <Select.Option
                                    key={option.id}
                                    value={option.id}
                                  >
                                    {`(${option.code}) ${option.name}`}
                                  </Select.Option>
                                ),
                            )}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col span={24}>
                    <Form.Item label="Pașaport" name="idnp">
                      <Input
                        disabled={user.deleted}
                        onChange={(e) => {
                          form.setFieldsValue({
                            idnp: maxLengthValidator(e.target.value, 13),
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label={t('entity.admin.user.email')}
                      name="email"
                      rules={[
                        {
                          required: false,
                          message: t(
                            `md.customs.frontiera.servicecore.validation.constraints.RequiredIfFieldEquals.message`,
                          ),
                        },
                        { validator: emailValidator },
                      ]}
                    >
                      <Input
                        disabled={user.deleted}
                        onChange={(e) => {
                          form.setFieldsValue({
                            email: maxLengthValidator(e.target.value, 320),
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Form.Item
                      label={t(`entity.admin.user.role`)}
                      name="role"
                      rules={[
                        {
                          required: true,
                          message: t(
                            `md.customs.frontiera.servicecore.validation.constraints.RequiredIfFieldEquals.message`,
                          ),
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) !== -1
                        }
                        style={{ minWidth: '120px' }}
                        allowClear
                        disabled={user.deleted}
                      >
                        {roles.content.map(
                          (option) =>
                            !option?.deleted && (
                              <Select.Option key={option.id} value={option.id}>
                                {option.name}
                              </Select.Option>
                            ),
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                {!user?.deleted ? (
                  <>
                    <h3>Imagine</h3>
                    <AvatarComponent
                      imageId={user.imageId}
                      base64={userImage}
                      size={200}
                      onClear={() => setUserImage(null)}
                      onUpload={setUserImage}
                      removable
                    />
                  </>
                ) : null}
              </div>
            </div>
          </Col>
          {selectedFunction === 'HEAD' &&
            form.getFieldValue('customsPost') &&
            !user.deleted && (
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <PageHeader title={t('entity.admin.user.subordinates')} />
                <TableFormItemWrapper
                  values={subordinatesIds}
                  columns={columnsUsers}
                  rowKey="id"
                  loading={loadingUsers}
                  pagination={false}
                  dataSource={usersContent}
                  onChange={(val) => {
                    setSubordinatesIds(val);
                  }}
                  scroll={{ y: 580 }}
                  disabled={user.deleted || user?.id}
                />
              </Col>
            )}
        </Row>
        <Form.Item>
          <Space>
            <SaveButton disabled={user.deleted} />
            <CancelButton onCancel={onCancel} />
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default EditUserForm;
