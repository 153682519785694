import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import RegionsList from '../../../../components/admin/taxonomies/regions/RegionsList';

const Regions = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="regions">
      <Tabs.TabPane tab={t('entity.admin.region._plural')} key="regions">
        <RegionsList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default Regions;
