import React, { useEffect, useMemo, useState } from 'react';
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  notification,
  Divider,
  Table,
} from 'antd';
import CancelButton from '../buttons/BackButton';
import { FormContextProvider } from '../../hooks/useFormContext';
import useFormErrors from '../../hooks/useFormErrors';
import {
  // getVignetteByVehicleRegistrationNumber, // TODO: TEMPORAY changed with asd-old-vinieta
  getVignetteByVehicleVIN,
} from '../../services/admin/web-services/asd-vinieta';
import { getVignetteByVehicleRegistrationNumber } from '../../services/admin/web-services/asd-old-vinieta';
import Column from '../../helpers/Columns';
import { findAll as findAllTariffPositions } from '../../services/taxonomies/vignette-tariff-position';
import useDictionaries from '../../hooks/useDictionaries';
import NextButton from '../buttons/NextButton';
import { vinNumberValidator } from '../../services/utils/validator/Validator';

const dictionaries = {
  tariffPositions: findAllTariffPositions,
};

const Vignette = ({
  transactionId,
  vehicle,
  errors,
  readOnly,
  moveNext = () => {},
  moveBack = () => {},
}) => {
  const [form] = Form.useForm();

  const [vignettes, setVignettes] = useState();
  const [loading, setLoading] = useState();

  const [{ tariffPositions }] = useDictionaries(dictionaries);

  useFormErrors(form, errors);
  useEffect(
    () => {
      form.setFieldsValue(vehicle);
    }, // eslint-disable-next-line
    [],
  );

  const searchVignetteAction = (method) => {
    if (method === 'vinCode') {
      const vin = form.getFieldValue('vinCode');
      if (!vin) {
        notification.error({ message: 'Introduceți numarul VIN' });
        return null;
      }
      return getVignetteByVehicleVIN(vin, transactionId);
    }
    if (method === 'plateNumber') {
      const regNumber = form.getFieldValue('plateNumber');
      if (!regNumber) {
        notification.error({ message: 'Introduceți numărul de înmatriculare' });
        return null;
      }
      return getVignetteByVehicleRegistrationNumber(regNumber, transactionId);
    }
    return null;
  };

  const handleSearchVignette = (method = null) => {
    setLoading(true);
    searchVignetteAction(method)
      ?.then((res) => {
        const data = Array.isArray(res) ? res : [res];
        const hasNonNullField = data.some((obj) =>
          Object.values(obj).some((val) => val !== null),
        );
        if (hasNonNullField) {
          setVignettes([...data]);
        } else {
          setVignettes([]);
        }
      })
      ?.catch(() => {
        notification.error({
          message: 'A apărut o eroare la căutarea vinietei',
        });
        setVignettes([]);
      })
      ?.finally(() => setLoading(false));
  };

  const columnsVignette = useMemo(
    () => [
      Column.text('vehicleRegistrationNumber', 'Număr de înmatriculare', {
        width: 250,
        sort: false,
      }),
      Column.text('vehicleVIN', 'Număr VIN', {
        width: 250,
        sort: false,
      }),
      Column.text(
        'trailRegistrationNumber',
        'Număr de înmatriculare (trailer)',
        {
          width: 250,
          sort: false,
        },
      ),
      Column.text('country', 'Țara', {
        width: 250,
        sort: false,
      }),
      Column.text('vehicleCategory', 'Categorie', {
        width: 250,
        sort: false,
      }),
      Column.text('vignetteNumber', 'Număr vinieta', {
        width: 250,
        sort: false,
      }),
      Column.text('status', 'Status', {
        width: 250,
        sort: false,
      }),
      Column.date('paidAt', 'Plătit la', {
        width: 250,
        sort: false,
      }),
      Column.date('validFrom', 'Valid din', {
        width: 250,
        sort: false,
      }),
      Column.date('expiresAt', 'Expirat la', {
        width: 250,
        sort: false,
      }),
      {
        title: 'Poziția tarifară',
        key: 'tariffPosition',
        width: 250,
        dataIndex: 'tariffPosition',
        render: (value) => {
          const code = tariffPositions?.content?.find(
            (el) => el.code === value,
          );
          return <>{code ? `(${code.code}) ${code.value}` : value}</>;
        },
      },
      Column.text('payerName', 'Plătit de', {
        width: 250,
        sort: false,
      }),
      Column.text('payerId', 'ID Plătitor', {
        width: 250,
        sort: false,
      }),
      Column.text('identityDocumentSeriesAndNumber', 'Număr de identitate', {
        width: 250,
        sort: false,
      }),
      Column.text('email', 'Email', {
        width: 250,
        sort: false,
      }),
    ],
    [tariffPositions?.content],
  );

  return (
    <>
      <FormContextProvider form={form}>
        <Form form={form} layout="vertical">
          <Row style={{ gap: '20px' }}>
            <Col span={6}>
              <Form.Item name="vinCode" label="Vin cod">
                <Input
                  disabled
                  onChange={(e) => {
                    form.setFieldsValue({
                      vinCode: vinNumberValidator(e.target.value),
                    });
                  }}
                />
              </Form.Item>
            </Col>
            {/* TODO: NEW-VIGNETTE-SERVICE-BY-VIN
            <Button
              onClick={() => handleSearchVignette('vinCode')}
              type="primary"
              style={{ top: '30px', width: '200px' }}
            >
              Caută după nr. VIN
            </Button>
            */}
          </Row>
          <Row style={{ gap: '20px' }}>
            <Col span={6}>
              <Form.Item name="plateNumber" label="Nr. vehicul">
                <Input disabled style={{ textTransform: 'uppercase' }} />
              </Form.Item>
            </Col>
            <Button
              onClick={() => handleSearchVignette('plateNumber')}
              type="primary"
              style={{ top: '30px', width: '200px' }}
            >
              Caută după nr. vehicul
            </Button>
          </Row>
          {loading || vignettes ? (
            <>
              <Divider plain>Rezultat căutare vinieta</Divider>
              <Table
                columns={columnsVignette}
                dataSource={vignettes}
                loading={loading}
                size="small"
              />
            </>
          ) : null}
          <Row style={{ gap: '10px' }}>
            <CancelButton disabled={readOnly} onCancel={moveBack} />
            <NextButton
              navigateFunc={moveNext}
              transactionId={transactionId}
              canNavigate
            />
          </Row>
        </Form>
      </FormContextProvider>
    </>
  );
};

export default Vignette;
