import {
  genericService,
  put,
  get,
  postWithRawResponse,
} from '../../../helpers/api';

export const PDF_URI = '/backend';
export const BASE_PDF = '/api/v1/pdf';
export const SERVICE_URI = '/users';
export const BASE_URI = '/v1/customs-posts';

export const { findAll, findOne, create, update, remove } = genericService(
  SERVICE_URI,
  BASE_URI,
);

export const updateCustomsPostsButtons = (disabled) =>
  put(`${SERVICE_URI}${BASE_URI}/buttons/${disabled}`);

export const editImage = (postId, base64Dto) =>
  put(`${SERVICE_URI}${BASE_URI}/image/${postId}`, base64Dto);

export const getCustomPostById = (id) => get(`${SERVICE_URI}${BASE_URI}/${id}`);

export const generatePdfInNewTab = async (path) => {
  const response = await postWithRawResponse(`${PDF_URI}${BASE_PDF}/${path}`, {
    headers: {
      Accept: 'application/octet-stream',
    },
  });
  const responseBlob = await response.blob();
  const blob = new Blob([responseBlob], {
    type: 'application/pdf',
  });
  const objectUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = objectUrl;
  link.setAttribute('download', 'Pasaport-Post-Vamal.pdf');
  document.body.appendChild(link);
  window.open(window.URL.createObjectURL(blob), 'Pasaport-Post-Vamal.pdf');
  return responseBlob;
};
