import React, { useEffect, useState } from 'react';
import { getPartialContent } from '../services/videos/videos';

const StreamingPlayer = ({
  streamIp,
  streamName,
  width = '600px',
  height = 'auto',
  ...props
}) => {
  const [videoSrc, setVideoSrc] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const fetchNextSegment = () => {
      if (!isMounted) {
        return;
      }
      // This cals videos server to extract camera streaming body, but can be modified for any streaming api if needed
      getPartialContent(streamIp, streamName)
        .then((videoBlob) => {
          setVideoSrc((prev) => {
            if (prev) {
              URL.revokeObjectURL(prev);
            }
            return URL.createObjectURL(videoBlob);
          });
        })
        .finally(() => {
          if (isMounted) {
            setTimeout(fetchNextSegment, 500);
          }
        });
    };

    fetchNextSegment();

    return () => {
      isMounted = false;
    };
  }, [streamIp, streamName]);

  return (
    <div className="video video__live">
      <video
        id="streamPlayer"
        autoPlay
        playsInline
        muted
        controls
        src={videoSrc}
        style={{ width, height, borderRadius: '10px' }}
        controlsList="nodownload noplaybackrate"
        {...props}
      />
      <div className="video__live-banner">
        <span />
        Live
      </div>
    </div>
  );
};

export default StreamingPlayer;
