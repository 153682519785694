import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@ant-design/pro-layout';
import { Table } from 'antd';
import AuthContext, { hasPermission } from '../../../auth';
import { useTableScroll } from '../../../../hooks/useTableScroll';
import useDatasource from '../../../../hooks/useDatasource';
import {
  findAll,
  remove,
} from '../../../../services/taxonomies/vehicle-category-declaration';
import Column from '../../../../helpers/Columns';
import ViewItemIcon from '../../../buttons/ViewItemIcon';
import EditItemIcon from '../../../buttons/EditItemIcon';
import DeleteItemIcon from '../../../buttons/DeleteItemIcon';
import AddEntityButton from '../../../buttons/AddEntityButton';

const VehicleCategoryTravelersList = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { permissions } = user;

  useTableScroll();

  const { loading, pagination, content, handleChange, reload } =
    useDatasource(findAll);

  const columns = useMemo(
    () => [
      Column.text('id', t('entity.admin.vehicleCategoryTravelers.id'), {
        width: 50,
      }),
      Column.text('code', t('entity.admin.vehicleCategoryTravelers.code'), {
        width: 60,
        filter: true,
      }),
      Column.text('name', t('entity.admin.vehicleCategoryTravelers.name'), {
        width: 100,
        filter: true,
      }),
      Column.date(
        'createdAt',
        t('entity.admin.vehicleCategoryTravelers.createdAt'),
        {
          width: 100,
        },
      ),
      Column.text(
        'createdBy',
        t('entity.admin.vehicleCategoryTravelers.createdBy'),
        {
          width: 100,
        },
      ),
      Column.date(
        'updatedAt',
        t('entity.admin.vehicleCategoryTravelers.updatedAt'),
        {
          width: 100,
        },
      ),
      Column.text(
        'updatedBy',
        t('entity.admin.vehicleCategoryTravelers.updatedBy'),
        {
          width: 100,
        },
      ),
      Column.bool(
        'deleted',
        t('entity.admin.vehicleCategoryTravelers.deleted'),
        {
          width: 70,
          filter: true,
          inverted: true,
          labels: [
            t('table.filter.all'),
            t('entity._.deleted.true'),
            t('entity._.deleted.false'),
            t('entity._.deleted.unknown'),
          ],
        },
      ),
      Column.actions(t('table.actions'), (record) =>
        record.deleted ? (
          <span style={{ textAlign: 'right' }}>
            {hasPermission(permissions, 'TAXONOMIES_VIEW') && (
              <ViewItemIcon
                path={`/admin/taxonomies/vehicle-category-declarations/${record.id}`}
              />
            )}
          </span>
        ) : (
          <span style={{ textAlign: 'right' }}>
            {hasPermission(permissions, 'TAXONOMIES_EDIT') && (
              <EditItemIcon
                path={`/admin/taxonomies/vehicle-category-declarations/${record.id}`}
              />
            )}
            {hasPermission(permissions, 'TAXONOMIES_DELETE') && (
              <DeleteItemIcon
                title={t(
                  'entity.admin.vehicleCategoryTravelers._delete',
                  record,
                )}
                message={t(
                  'entity.admin.vehicleCategoryTravelers._deleted',
                  record,
                )}
                item={record}
                action={remove}
                reload={reload}
              />
            )}
          </span>
        ),
      ),
    ],
    [t, reload, permissions],
  );

  return (
    <>
      <PageHeader
        title={t('entity.admin.vehicleCategoryTravelers._plural')}
        extra={[
          <AddEntityButton
            key="new"
            entity={t('entity.admin.vehicleCategoryTravelers._singular')}
            path="/admin/taxonomies/vehicle-category-declarations/new"
          />,
        ]}
      />
      <Table
        scroll={{ x: 500 }}
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
      />
    </>
  );
};

export default VehicleCategoryTravelersList;
