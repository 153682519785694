import React, { useCallback, useEffect, useState } from 'react';
import { Col, Form, Input, Modal, notification, Row, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import useDatasource from '../../../../hooks/useDatasource';
import {
  remove as removeArticle,
  update as updateArticle,
  create as createArticle,
  findAll as findAllArticles,
} from '../../../../services/taxonomies/transaction-articles';
import RequiredLabel from '../../../../services/utils/RequiredLabel';
import {
  maxLengthValidator,
  vinNumberValidator,
} from '../../../../services/utils/validator/Validator';
import CountrySelect from '../../../CountrySelect';
import ArticleListTable from './ArticleListTable';

import useDictionaries from '../../../../hooks/useDictionaries';

import { findAll as findAllVehicleCategories } from '../../../../services/taxonomies/vehicle-categories';
import { findAll as findAllCountries } from '../../../../services/taxonomies/countries';

const dictionaries = {
  vehicleCategories: findAllVehicleCategories,
  countries: findAllCountries,
};
const ArticleList = ({ readOnly, transactionId }) => {
  const [formArticle] = Form.useForm();
  const [loadingArticles, setLoadingArticles] = useState(false);
  const [isArticleModalVisible, setArticleModalVisible] = useState(false);

  const handler = useCallback(
    (...params) => {
      const spec = params[0];
      spec.sort = ['id', 'desc'];
      spec.criterias = transactionId
        ? { transactionId }
        : { transactionId: -1 };
      return findAllArticles(spec);
    },
    [transactionId],
  );

  const { loading, reload, content, handleChange } = useDatasource(handler);

  useEffect(() => {
    setLoadingArticles(loading);
  }, [loading]);

  const [{ vehicleCategories, countries }] = useDictionaries(dictionaries);

  const handleModalOk = useCallback(async () => {
    try {
      await formArticle.validateFields();

      const data = {
        transactionId,
        vehicleNumber: formArticle.getFieldValue('articleVehicleNumber'),
        vinCode: formArticle.getFieldValue('articleVinCode'),
        category: formArticle.getFieldValue('articleCategory'),
        countryId: formArticle.getFieldValue('articleCountryId'),
        description: formArticle.getFieldValue('description'),
      };

      const currentId = formArticle.getFieldValue('articleId');

      setLoadingArticles(true);

      if (currentId === undefined || currentId === null) {
        await createArticle(data);
      } else {
        await updateArticle({
          id: currentId,
          ...data,
        });
      }

      setLoadingArticles(false);
      reload();
      setArticleModalVisible(false);
    } catch (error) {
      notification.error({ message: 'Eroare la salvarea datelor' });
    }
  }, [transactionId, formArticle, reload]);

  const handleEditModal = (record) => {
    if (record) {
      formArticle.setFieldsValue({
        articleVehicleNumber: record.vehicleNumber,
        articleCountryId: record.countryId,
        articleCategory: record.category,
        articleVinCode: record.vinCode,
        articleId: record.id,
        description: record.description,
      });
    } else {
      formArticle.resetFields();
    }
    setArticleModalVisible(true);
  };

  return (
    <>
      <Form form={formArticle}>
        <Modal
          maskClosable={false}
          centered
          width={600}
          title="Adăugare articol"
          open={isArticleModalVisible}
          onOk={handleModalOk}
          onCancel={() => setArticleModalVisible(false)}
          cancelText="Înapoi"
        >
          <Row gutter={15}>
            <Col span={12}>
              <Form.Item
                labelCol={{ span: 24 }}
                name="articleVehicleNumber"
                label={<RequiredLabel title="Număr" />}
              >
                <Input
                  style={{
                    textTransform: 'uppercase',
                  }}
                  maxLength={13}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="articleVinCode"
                label="VIN"
                labelCol={{ span: 24 }}
              >
                <Input
                  onChange={(e) => {
                    formArticle.setFieldsValue({
                      articleVinCode: maxLengthValidator(
                        vinNumberValidator(e.target.value),
                        17,
                      ),
                    });
                  }}
                  style={{
                    textTransform: 'uppercase',
                  }}
                  maxLength={17}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="articleCountryId"
                label="Țara"
                labelCol={{ span: 24 }}
              >
                <CountrySelect id="articleCountryId" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="articleCategory"
                label="Categorie"
                labelCol={{ span: 24 }}
              >
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) !== -1
                  }
                >
                  {vehicleCategories?.content.map(
                    (option) =>
                      !option.deleted && (
                        <Select.Option key={option.id} value={option.code}>
                          {`${option.code} - ${option.name}`}
                        </Select.Option>
                      ),
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="description"
                label="Descriere"
                labelCol={{ span: 24 }}
              >
                <TextArea showCount maxLength={2000} rows={3} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item name="articleId" noStyle />
        </Modal>
      </Form>

      <ArticleListTable
        readOnly={readOnly}
        transactionId={transactionId}
        setArticleModalVisible={setArticleModalVisible}
        content={content}
        countries={countries}
        setLoadingArticles={setLoadingArticles}
        reload={reload}
        handleEditModal={handleEditModal}
        handleChange={handleChange}
        removeArticle={removeArticle}
        loadingArticles={loadingArticles}
        vehicleCategories={vehicleCategories}
      />
    </>
  );
};

export default ArticleList;
