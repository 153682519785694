import React, { useMemo } from 'react';

import { Table } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

import useDatasource from '../../../hooks/useDatasource';

import Column from '../../../helpers/Columns';

import { findAll as findAllTransportAuthorizations } from '../../../services/admin/web-services/anta-transport-authorization';
import { useTableScroll } from '../../../hooks/useTableScroll';

const ANTATransportAuthorizationList = () => {
  const { loading, pagination, content, handleChange } = useDatasource(
    findAllTransportAuthorizations,
    { showNotification: true },
  );
  useTableScroll();
  const columns = useMemo(
    () => [
      Column.text('authorizationMethodOfUse', 'Metoda utilizare', {
        width: 300,
        filter: true,
      }),
      Column.text('countryCode', 'Cod țară', {
        width: 300,
        filter: true,
      }),
      Column.text('mixedAssembly', 'Ansamblu mixt', {
        width: 300,
        filter: true,
      }),
      Column.text('number', 'Număr', {
        width: 50,
      }),
      Column.text('status', 'Statut', {
        width: 200,
      }),
      Column.text('trailerRegPlateNumber', 'Număr de înmatriculare', {
        width: 300,
        filter: true,
      }),
      Column.text('transportCompanyName', 'Denumire companie', {
        width: 300,
        filter: true,
      }),
      Column.text('truckRegPlateNumber', 'Număr de înmatriculare remorca', {
        width: 300,
        filter: true,
      }),
      Column.text('type', 'Tip', {
        width: 50,
      }),
      Column.date('validFrom', 'Data eliberare', {
        width: 250,
        format: 'DD-MM-YYYY HH:mm:ss',
      }),
      Column.date('validTo', 'Data expirare', {
        width: 250,
        format: 'DD-MM-YYYY HH:mm:ss',
      }),
      Column.text('year', 'An autorizație', {
        width: 20,
      }),
    ],
    [],
  );

  const columnsEmissions = useMemo(
    () => [
      Column.text('emissionStandard', 'Categorie EURO', {
        width: 100,
      }),
    ],
    [],
  );

  return (
    <>
      <PageHeader title="Autorizații de transport" />
      <Table
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ x: 500 }}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <PageHeader title="Categorii EURO" />
              <Table
                columns={columnsEmissions}
                dataSource={record.emissionStandardsData}
                pagination={false}
                rowKey="id"
                size="small"
              />
            </>
          ),
          rowExpandable: (record) =>
            record &&
            record.emissionStandardsData &&
            record.emissionStandardsData.length > 0,
        }}
      />
    </>
  );
};

export default ANTATransportAuthorizationList;
