import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

const Timer = () => {
  const [time, setTime] = useState(dayjs().format('DD.MM.YYYY HH:mm:ss'));

  useEffect(() => {
    const interval = setInterval(
      () => setTime(dayjs().format('DD.MM.YYYY HH:mm:ss')),
      1000,
    );
    return () => {
      clearInterval(interval);
    };
  }, []);

  return <div>Data: {time}</div>;
};

export default Timer;
