import React, { useMemo, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Modal, Radio, Space, notification } from 'antd';
import useDatasource from '../../../hooks/useDatasource';

import {
  findAll,
  remove,
  updateCustomsPostsButtons,
} from '../../../services/admin/users/customs-posts';

import Column from '../../../helpers/Columns';
import EditItemIcon from '../../buttons/EditItemIcon';
import DeleteItemIcon from '../../buttons/DeleteItemIcon';
import ViewItemIcon from '../../buttons/ViewItemIcon';
import AuthContext, { hasPermission } from '../../auth';
import { useTableScroll } from '../../../hooks/useTableScroll';

const CustomsPostsList = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { permissions } = user;
  useTableScroll();
  const { loading, pagination, content, handleChange, reload } =
    useDatasource(findAll);

  const columns = useMemo(
    () => [
      Column.text('id', 'ID', {
        width: 50,
      }),
      Column.text('newCode', t('entity.admin.customsPost.code'), {
        width: 100,
        filter: true,
      }),
      Column.text('name', t('entity.admin.customsPost.name'), {
        width: 100,
        filter: true,
      }),
      Column.date('createdAt', t('entity.admin.customsPost.createdAt'), {
        width: 100,
        format: 'DD-MM-YYYY HH:mm:ss',
      }),
      Column.text('createdBy', t('entity.admin.customsPost.createdBy'), {
        width: 100,
      }),
      Column.date('updatedAt', t('entity.admin.customsPost.updatedAt'), {
        width: 100,
        format: 'DD-MM-YYYY HH:mm:ss',
      }),
      Column.text('updatedBy', t('entity.admin.customsPost.updatedBy'), {
        width: 100,
      }),
      Column.bool('deleted', t('entity._.deleted._'), {
        width: 100,
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity._.deleted.true'),
          t('entity._.deleted.false'),
          t('entity._.deleted.unknown'),
        ],
      }),
      Column.actions(t('table.actions'), (record) =>
        record.deleted ? (
          <span style={{ textAlign: 'right' }}>
            {hasPermission(permissions, 'CUSTOMS_POST_VIEW') && (
              <ViewItemIcon
                path={`/admin/customs-posts/customs/${record.id}`}
              />
            )}
          </span>
        ) : (
          <span style={{ textAlign: 'right' }}>
            {hasPermission(permissions, 'CUSTOMS_POST_EDIT') && (
              <EditItemIcon
                path={`/admin/customs-posts/customs/${record.id}`}
              />
            )}
            {hasPermission(permissions, 'CUSTOMS_POST_DELETE') && (
              <DeleteItemIcon
                title={t('entity.admin.customsPost._delete', record)}
                message={t('entity.admin.customsPost._deleted', record)}
                item={record}
                action={remove}
                reload={reload}
              />
            )}
          </span>
        ),
      ),
    ],
    [t, reload, permissions],
  );

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [value, setValue] = useState(1);

  const onRadioChange = (e) => {
    setValue(e.target.value);
  };

  const handleOk = () => {
    updateCustomsPostsButtons(value).then((res) =>
      res
        ? notification.success({
            message: 'Permisiunile au fost aplicate',
            duration: 3,
          })
        : notification.error({
            message: 'Nu au putut fi aplicate permisiunile',
          }),
    );
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Table
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        sticky={{ offsetHeader: 63 }}
      />

      <Modal
        maskClosable={false}
        style={{ width: '600px' }}
        title="Setare permisiuni pentru adaugarea manuală a rutelor"
        visible={isModalVisible}
        centered
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Înapoi"
        okText="Salvează"
      >
        <Radio.Group onChange={onRadioChange} value={value}>
          <Space direction="vertical">
            <Radio value={1} style={{ marginLeft: 10 }}>
              <span style={{ fontWeight: '600' }}>
                Permite adăugarea manuală a rutelor pentru toate posturile
                vamale
              </span>
            </Radio>
            <Radio value={0} style={{ marginLeft: 10 }}>
              <span style={{ fontWeight: '600' }}>
                Restricționează adăugarea manuală a rutelor pentru toate
                posturile vamale
              </span>
            </Radio>
          </Space>
        </Radio.Group>
      </Modal>
    </>
  );
};

export default CustomsPostsList;
