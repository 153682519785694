import React, { useEffect, useState } from 'react';
import { Checkbox, Col, DatePicker, Form, Input, Row, Space } from 'antd';
import dayjs from 'dayjs';
import TextArea from 'antd/lib/input/TextArea';
import MessagesList from './MessagesList';
import CancelButton from '../../../buttons/BackButton';
import { findAllByIds } from '../../../../services/admin/users/users';

const chatType = {
  content: [
    { id: 'GROUP', code: 'GROUP', name: 'GRUP' },
    { id: 'USER', code: 'USER', name: 'USER' },
  ],
};
const USERS_BATCH_SIZE = 100;
const ChatView = ({ chat, onCancel }) => {
  const [form] = Form.useForm();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (chat?.id) {
      form.setFieldsValue({
        ...chat,
        type:
          chatType.content.find((el) => el.code === chat.type)?.name || null,
        createdAt: chat?.createdAt ? dayjs(chat?.createdAt) : null,
        users: users
          .map((el) => `id: ${el.id} (${el.username}) ${el.fullname}`)
          .join(',\n'),
      });
    }
  }, [form, chat, users]);

  useEffect(() => {
    if (chat?.userIds?.length > 0) {
      const usersIds = chat?.userIds;
      const batchSize = USERS_BATCH_SIZE;
      for (let i = 0; i < usersIds.length; i += batchSize) {
        const batch = usersIds.slice(i, i + batchSize);
        findAllByIds(batch).then((res) =>
          setUsers((prev) => [
            ...prev,
            ...res.filter(
              (item) => !prev.some((prevItem) => prevItem.id === item.id),
            ),
          ]),
        );
      }
    }
  }, [chat?.userIds]);

  return (
    <>
      <Form form={form} layout="vertical">
        <Row gutter={15}>
          <Col span={12}>
            <Form.Item name="id" label="ID">
              <Input disabled />
            </Form.Item>
            <Form.Item name="type" label="Tip">
              <Input disabled />
            </Form.Item>
            <Form.Item name="createdAt" label="Creat la">
              <DatePicker disabled format="DD.MM.YYYY HH:mm" />
            </Form.Item>
            <Form.Item name="deleted" valuePropName="checked">
              <Checkbox disabled>Șters</Checkbox>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="users" label="Membri">
              <TextArea disabled rows={9} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Col span={24}>
        {chat?.id ? <MessagesList chatId={chat?.id} /> : null}
      </Col>

      <Space>
        <CancelButton onCancel={onCancel} />
      </Space>
    </>
  );
};

export default ChatView;
