import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeader } from '@ant-design/pro-layout';

import CustomsPostsList from '../../../components/admin/customsPosts/CustomsPostsList';
import AddEntityButton from '../../../components/buttons/AddEntityButton';

const CustomsPosts = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeader
        title={t('entity.admin.customsPost._plural')}
        extra={[
          // hasPermission(permissions, 'CUSTOMS_POST_EDIT') && (
          //   <Button
          //     key="id"
          //     type="primary"
          //     onClick={() => setIsModalVisible(true)}
          //     icon={<LockOutlined />}
          //   >
          //     {`Setare permisiuni pentru adăugarea manuală a rutelor la toate posturile
          //     vamale`}
          //   </Button>
          // ),
          <AddEntityButton
            key="new"
            entity={t('entity.admin.customsPost._singular')}
            path="/admin/customs-posts/customs/new"
          />,
        ]}
      />
      <CustomsPostsList />
    </>
  );
};

export default CustomsPosts;
