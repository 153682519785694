import React from 'react';

const EmptyRiskBox = ({ risk, onClick = () => {} }) => {
  const getTextualRow = (title, text) => {
    return (
      <>
        <div>{title}</div>
        <div>{text}</div>
      </>
    );
  };

  return (
    <div
      className="risk-card-button"
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        alignItems: 'center',
      }}
    >
      <button
        className="risk-card-button-btn"
        type="button"
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          borderRadius: '10px',
          alignItems: 'center',
          background: '#ccc',
          gap: '5px',
          padding: '5px',
        }}
        onClick={onClick}
      >
        {getTextualRow(`Ghișeul: ${risk.place}`)}
        {getTextualRow(`Ruta: ${risk.route}`)}
      </button>
      <div
        style={{ fontWeight: 'bold', textAlign: 'center' }}
      >{`${risk.firstName} ${risk.lastName}`}</div>
    </div>
  );
};

export default EmptyRiskBox;
