import { post, remove, genericService } from '../../../helpers/api';

export const SERVICE_URI = '/backend';
export const BASE_URI = '/warehouse';
export const BASE_URI_MESSAGE = '/api/v1/messages';

export const { findAll } = genericService(SERVICE_URI, BASE_URI);

export const addWarehouse = (path, warehouse) =>
  post(`${SERVICE_URI}${BASE_URI}/add/${path}`, warehouse);

export const deleteWarehouse = (id) =>
  remove(`${SERVICE_URI}${BASE_URI}/delete/${id}`);

export const addWarehouseReloadReason = (comment) =>
  post(
    `${SERVICE_URI}${BASE_URI_MESSAGE}/warehouse-reload-reason/${comment.transshipmentId}`,
    comment,
  );

export const linkReloadTransaction = (warehouseId, transactionId) =>
  post(
    `${SERVICE_URI}${BASE_URI}/warehouse-link-transaction/${warehouseId}/${transactionId}`,
  );

export const addWarehouseGood = (data) =>
  post(`${SERVICE_URI}${BASE_URI}/goods-add/${data.warehouseId}`, data);

export const editWarehouseGood = (data) =>
  post(`${SERVICE_URI}${BASE_URI}/goods-edit/${data?.id}`, data);

export const deleteWarehouseGood = (data) =>
  remove(`${SERVICE_URI}${BASE_URI}/goods-delete/${data?.id}`);

export const savePVT = (data) =>
  post(`${SERVICE_URI}${BASE_URI}/pvt-save/${data?.warehouseId}`, data);
