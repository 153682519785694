import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useMemo,
} from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import { Divider, notification, Table, Tooltip, Image } from 'antd';
import { FieldTimeOutlined, SnippetsOutlined } from '@ant-design/icons';

import Flag from 'react-world-flags';
import { useHistory } from 'react-router-dom';
import CarColorChecker from '../CarColorChecker';
import useDatasource from '../../hooks/useDatasource';
import { findAll as findAllTransactions } from '../../services/transactions/transaction-table';
import { findAll as findAllCustomsOffices } from '../../services/admin/users/customs-office';
import { findAll as findAllCustomsPosts } from '../../services/admin/users/customs-posts';
import { findAll as findAllConstants } from '../../services/taxonomies/constants';
import { findAll as findAllCountries } from '../../services/taxonomies/countries';
import useDictionaries from '../../hooks/useDictionaries';
import AddEntityButton from '../../components/buttons/AddEntityButton';
import AuthContext, { hasPermission } from '../../components/auth';
import { useTableScroll } from '../../hooks/useTableScroll';
import AppLoader from '../../components/auth/AppLoader';
import CustomsPostSelect from '../../components/CustomsPostSelect';
import DirectionCheckboxes from '../../components/DirectionCheckboxes';
import aeoLogo from '../../assets/aeo-logo.png';
import LiberModal from '../../components/admin/modals/LiberModal';

import Column from '../../helpers/Columns';
import { generateTrafficLightIcon } from '../../services/utils/TrafficLight';
import {
  cancelDuplicatedTransaction,
  retrieveControl,
  startControl,
} from '../../services/transactions/transactions';
import Icon from '../../components/Icon';
import UserFunctions from '../../enums/UserFunctions';
import ControlButton from '../../components/home/ControlButton';
import ViewButton from '../../components/home/ViewButton';
import CancelButton from '../../components/home/CancelButton';

const dictionaries = {
  constants: findAllConstants,
  customsOffices: findAllCustomsOffices,
  customsPosts: findAllCustomsPosts,
  countries: findAllCountries,
};

const HomeView = () => {
  useTableScroll();
  const MINUTE_MS = 10000;
  const { user, setUser } = useContext(AuthContext);
  const history = useHistory();
  const { permissions } = user;
  const [postId, setPostId] = useState(null);
  const [rute, setRute] = useState({});
  const [loadingLoader, setLoadingLoader] = useState(false);
  const [currentCustomPost, setCurrentCustomPost] = useState({});

  const [
    { constants, customsOffices, customsPosts, countries },
    ,
    loadingDictionaries,
  ] = useDictionaries(dictionaries);

  const [inCheckbox, setInCheckbox] = useState(
    rute
      ? JSON.parse(localStorage.getItem('rute'))?.in
      : user?.direction === null || user?.direction === 'IN',
  );

  const [outCheckbox, setOutCheckbox] = useState(
    rute
      ? JSON.parse(localStorage.getItem('rute'))?.out
      : user?.direction === null || user?.direction === 'OUT',
  );

  const [isCargo, setIsCargo] = useState(
    rute ? JSON.parse(localStorage.getItem('rute'))?.cargo : true,
  );

  const [isAuto, setIsAuto] = useState(
    rute ? JSON.parse(localStorage.getItem('rute'))?.auto : true,
  );

  const [postConstants, setPostConstants] = useState({
    hasAnsa: false,
    hasAnta: false,
    hasMdUa: false,
    hasScale: false,
    hasScan: false,
    hasWarehouse: false,
  });

  const isReadOnlyUser = useCallback(() => {
    return (
      user?.function === null ||
      user.function === UserFunctions.OFFICE_HEAD ||
      user.function === UserFunctions.CENTRAL ||
      user.function === UserFunctions.MB_TEAM
    );
  }, [user?.function]);

  const handleRetrieveControl = useCallback(
    (row) => {
      if (user.function === UserFunctions.SCANN_OPERATOR) {
        history.push(`/physical-person-entry/${row.id}`);
        return;
      }
      setLoadingLoader(true);
      retrieveControl(row.id)
        .then(() => {
          history.push(`/physical-person-entry/${row.id}`);
        })
        .catch((msg) => {
          const { inner } = msg || {};
          const { _: messageCode } = inner || {};
          notification.error({
            message: messageCode,
          });
        })
        .finally(() => {
          setLoadingLoader(false);
        });
    },
    [history, setLoadingLoader, user.function],
  );

  const getPostId = useCallback(() => {
    if (postId) {
      return [postId];
    }
    return (
      (user.postId && [user.postId]) ||
      (user.customsPost?.id && [user.customsPost.id]) ||
      (user.officeId &&
        customsOffices?.content
          .find((el) => el.id === user.officeId)
          ?.customsPosts?.map((el) => el.id))
    );
  }, [
    customsOffices?.content,
    user?.postId,
    user?.officeId,
    postId,
    user?.customsPost?.id,
  ]);

  const alowedCategories = useCallback(() => {
    if (!constants?.content?.length > 0) {
      return ['NO_CATEGORY'];
    }
    const cargoCategories =
      constants?.content
        ?.find((el) => el.code === 'TERMINAL_MARFA_CATEGORII')
        ?.value?.split(',') || [];

    const autoCategories =
      constants?.content
        ?.find((el) => el.code === 'TERMINAL_PASAGERI_CATEGORII')
        ?.value?.split(',') || [];

    if (isCargo && isAuto) {
      return cargoCategories.concat(autoCategories);
    }
    if (isCargo && !isAuto) {
      return cargoCategories;
    }
    if (isAuto && !isCargo) {
      return autoCategories;
    }
    return ['NO_CATEGORY'];
  }, [constants?.content, isAuto, isCargo]);

  const handler = useCallback(
    (...params) => {
      const hz = params[0];
      hz.sort = ['id', 'desc'];
      hz.criterias = {
        ...hz.criterias,
        postId: getPostId(),
        direction:
          // eslint-disable-next-line
          inCheckbox && outCheckbox
            ? ['IN', 'OUT']
            : // eslint-disable-next-line
            inCheckbox
            ? ['IN']
            : outCheckbox
            ? ['OUT']
            : ['INOUT'],
        category: alowedCategories(),
      };
      if (constants) {
        return findAllTransactions(hz);
      }
      return findAllTransactions();
    },
    [getPostId, constants, inCheckbox, outCheckbox, alowedCategories],
  );

  const { pagination, content, handleChange, reload } = useDatasource(handler, {
    allowFetcher: !loadingDictionaries,
  });

  useEffect(() => {
    if (user?.customsPost) {
      setPostConstants({
        hasAnsa: user?.customsPost?.hasAnsa,
        hasAnta: user?.customsPost?.hasAnta,
        hasMdUa: user?.customsPost?.hasMdUa,
        hasScale: user?.customsPost?.hasScale,
        hasScan: user?.customsPost?.hasScan,
      });
    }
  }, [user]);

  useEffect(() => {
    const interval = setInterval(() => {
      reload();
    }, MINUTE_MS);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reload();
  }, [constants, customsOffices, reload, postId]);

  const getExecutionTime = useCallback(
    (category) => {
      const cargoCategories =
        constants?.content
          ?.find((el) => el.code === 'TERMINAL_MARFA_CATEGORII')
          ?.value?.split(',') || [];

      const isCargoVehicle = cargoCategories.includes(category);
      if (isCargoVehicle) {
        const timeInSeconds =
          constants?.content?.find(
            (el) => el.code === 'TRANSACTION_PROCESSING_TIME_CARGO',
          )?.value || 3600;
        return Math.floor(timeInSeconds / 60);
      }
      const timeInSeconds =
        constants?.content?.find(
          (el) => el.code === 'TRANSACTION_PROCESSING_TIME_AUTO',
        )?.value || 900;
      return Math.floor(timeInSeconds / 60);
    },
    [constants?.content],
  );

  const renderTimeColor = useCallback(
    (minutes, category = null) => {
      const timeInMinutes = getExecutionTime(category);
      if (minutes || minutes === 0) {
        if (!category) {
          return (
            <div
              className="vehicle__time"
              style={{
                color: '#F07427',
                display: 'flex',
                gap: '5px',
              }}
            >
              <FieldTimeOutlined />
              {minutes}
            </div>
          );
        }
        if (minutes < timeInMinutes) {
          return (
            <div
              className="vehicle__time"
              style={{
                color: '#4FC878',
                display: 'flex',
                gap: '5px',
              }}
            >
              <FieldTimeOutlined />
              {minutes}
            </div>
          );
        }
        return (
          <div
            className="vehicle__time"
            style={{
              color: '#E6484B',
              display: 'flex',
              gap: '5px',
            }}
          >
            <FieldTimeOutlined />
            {minutes}
          </div>
        );
      }
      return null;
    },
    [getExecutionTime],
  );
  const handleCancelTransaction = useCallback((transaction) => {
    cancelDuplicatedTransaction(transaction.id)
      .then((res) => {
        if (res) {
          notification.success({
            message: 'Ruta a fost anulată cu succes',
          });
        }
      })
      .finally(() => reload());
    // eslint-disable-next-line
  }, []);

  const handleControl = useCallback(
    (row) => {
      if (
        isReadOnlyUser() ||
        row.inspectorId === user?.id ||
        user.function === UserFunctions.SCANN_OPERATOR
      ) {
        history.push(`/physical-person-entry/${row.id}`);
        return;
      }

      setLoadingLoader(true);
      startControl(row.id)
        .then(() => {
          history.push(`/physical-person-entry/${row.id}`);
        })
        .catch((msg) => {
          const { inner } = msg || {};
          const { _: messageCode } = inner || {};
          notification.error({
            message: messageCode,
          });
        })
        .finally(() => {
          setLoadingLoader(false);
        });
    },
    [history, isReadOnlyUser, setLoadingLoader, user.function, user?.id],
  );
  const columns = useMemo(() => {
    const c = [
      {
        title: ' ',
        key: ' ',
        sort: false,
        width: 40,
        align: 'center',
        render: (row) => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {row.isDuplicated && (
                <Tooltip title="Duplicare">
                  <SnippetsOutlined
                    className="snippetsOutlined duplicated-icon"
                    style={{
                      fontSize: '1.4rem',
                    }}
                  />
                </Tooltip>
              )}
              {row.isAeo && (
                <Tooltip title="AEO">
                  <Image
                    className="aeo-image"
                    preview={false}
                    src={aeoLogo}
                    width={30}
                    style={{ position: 'relative', zIndex: '99' }}
                  />
                </Tooltip>
              )}
              {row.isStopped && (
                <Tooltip title="Mașină reținută">
                  <Icon
                    name="stoppedCar"
                    className="car__stopped"
                    width="30"
                    height="30"
                  />
                </Tooltip>
              )}
            </div>
          );
        },
      },
      Column.date('createdAt', 'Data', {
        width: 95,
        sort: false,
        format: 'DD.MM.YY HH:mm:ss',
      }),
      {
        title: 'Zile în RM (intr-un an)',
        key: 'daysInRM',
        width: 75,
        dataIndex: 'daysInRM',
        align: 'center',
        render: (code, row) => {
          return (
            <div style={{ color: row.isOverstaying ? '#e6484b' : '#24a052' }}>
              {row.daysInRM}
            </div>
          );
        },
      },
      {
        title: 'Zile în RM (de la ultima intrare)',
        key: 'daysInRMFromLastEntry',
        width: 75,
        dataIndex: 'daysInRMFromLastEntry',
        align: 'center',
        render: (code, row) => {
          return (
            <div style={{ color: row.isOverstaying ? '#e6484b' : '#24a052' }}>
              {row.daysInRMFromLastEntry}
            </div>
          );
        },
      },
      user?.customsPost?.hasSeed && {
        sort: false,
        title: 'Fără marfă (SEED)',
        key: 'isSeedReceived',
        width: 80,
        dataIndex: 'isSeedReceived',
        render: (code, row) => {
          return <>{row.isSeedReceived && <div className="icon-success" />}</>;
        },
      },
      Column.other(
        'direction',
        'Direcția',
        (code, row) => {
          return (
            <>
              {row?.direction?.toUpperCase() === 'IN' ? (
                <div className="direction__enter">
                  <Icon name="enter" className="direction__enter-icon" />
                </div>
              ) : (
                <div className="direction__exit">
                  <Icon name="exit" className="direction__exit-icon" />
                </div>
              )}
            </>
          );
        },
        {
          width: 60,
          sort: false,
        },
      ),
      Column.other(
        'category',
        'Tip, Culoare',
        (code, row) => {
          return (
            <CarColorChecker
              direction={row.direction}
              color={row.color}
              category={row.category}
            />
          );
        },
        {
          width: 80,
          align: 'center',
          sort: false,
        },
      ),
      Column.other(
        'markAndModel',
        'Marcă, Model',
        (code, row) => {
          const carMark = row.mark?.toLowerCase();
          return (
            <div className="home__table-carIcon">
              <Icon
                width={
                  carMark === 'vanhool' ||
                  carMark === 'van hool' ||
                  carMark === 'iveco' ||
                  carMark === 'hummer' ||
                  carMark === 'daf'
                    ? 40
                    : 25
                }
                height={
                  carMark === 'vanhool' ||
                  carMark === 'van hool' ||
                  carMark === 'iveco' ||
                  carMark === 'hummer' ||
                  carMark === 'daf'
                    ? 40
                    : 25
                }
                name={carMark}
              />
              <div>
                <div>{row.mark}</div>
                <div>{row.model}</div>
              </div>
            </div>
          );
        },
        {
          width: 120,
          align: 'center',
          sort: false,
        },
      ),
      Column.other(
        'allPlateNumbers',
        'Nr. auto',
        (code, row) => {
          const country = countries?.content?.find(
            (el) => el.id === row.vehicleCountryId,
          );
          return (
            <div className="home__table-flag">
              {country ? (
                <Tooltip title={country.name}>
                  <Flag code={country.alpha3} height="20" width="30" />
                </Tooltip>
              ) : null}
              {row.allPlateNumbers}
            </div>
          );
        },
        {
          width: 90,
          sort: false,
          filter: true,
        },
      ),
      Column.text('idnp', 'Pașaport', {
        width: 135,
        filter: true,
        sort: false,
      }),
      Column.text('fullName', 'Nume', {
        width: 135,
        sort: false,
      }),
      Column.other(
        '',
        'Durată așteptare',
        (code, row) => {
          const waitingTime =
            row.crossingTimeTotalMinutes >= 0
              ? row.crossingTimeTotalMinutes
              : 0;
          return renderTimeColor(waitingTime, row.category);
        },
        {
          sort: false,
          width: 75,
        },
      ),
      Column.other(
        '',
        'Durată control',
        (code, row) => {
          return renderTimeColor(row.crossingTimeMinutes, row.category);
        },
        {
          sort: false,
          width: 75,
        },
      ),
      postConstants?.hasScale && isCargo
        ? {
            sort: false,
            title: 'Cântar',
            key: 'isScaleConfirmed',
            width: 75,
            dataIndex: 'isScaleConfirmed',
            align: 'center',
            render: (code, row) =>
              generateTrafficLightIcon(row.isScaleConfirmed),
          }
        : null,
      postConstants?.hasAnta && isCargo
        ? {
            sort: false,
            title: 'ANTA',
            key: 'isAntaConfirmed',
            width: 70,
            dataIndex: 'isAntaConfirmed',
            align: 'center',
            render: (code, row) =>
              generateTrafficLightIcon(row.isAntaConfirmed),
          }
        : null,
      postConstants?.hasAnsa && isCargo
        ? {
            sort: false,
            title: 'ANSA',
            key: 'isAnsaConfirmed',
            width: 70,
            dataIndex: 'isAnsaConfirmed',
            align: 'center',
            render: (code, row) =>
              generateTrafficLightIcon(row.isAnsaConfirmed),
          }
        : null,
      postConstants?.hasScan && isCargo
        ? {
            sort: false,
            title: 'SCAN',
            key: 'isScanConfirmed',
            width: 70,
            dataIndex: 'isScanConfirmed',
            align: 'center',
            render: (code, row) => {
              const { isScanConfirmed, isScanConfirmedByOperator } = row;
              if (
                isScanConfirmed === true &&
                isScanConfirmedByOperator === true
              ) {
                return generateTrafficLightIcon(true);
              }
              if (
                isScanConfirmed === false ||
                isScanConfirmedByOperator === false
              ) {
                return generateTrafficLightIcon(false);
              }
              if (
                isScanConfirmed === null &&
                isScanConfirmedByOperator === null
              ) {
                return generateTrafficLightIcon(null);
              }

              return generateTrafficLightIcon(null);
            },
          }
        : null,
      postConstants?.hasMdUa && isCargo
        ? {
            sort: false,
            title: 'MDUA',
            key: 'isMdUaConfirmed',
            width: 75,
            dataIndex: 'isMdUaConfirmed',
            align: 'center',
            render: (code, row) =>
              generateTrafficLightIcon(row.isMdUaConfirmed),
          }
        : null,
      {
        sort: false,
        title: 'Acțiune',
        width: 175,
        fixed: 'right',
        align: 'center',
        render: (code, row) => {
          return (
            <>
              <ControlButton
                isControl={
                  row.inspectorId === null || row.inspectorId === user.id
                }
                inspector={
                  user.function !== UserFunctions.SCANN_OPERATOR
                    ? row.inspector
                    : 'SCANARE'
                }
                onControl={() => handleControl(row)}
                onRetrieveControl={() => handleRetrieveControl(row)}
                show={
                  !isReadOnlyUser() &&
                  hasPermission(permissions, 'CREATE_TRANSACTION')
                }
              />
              <ViewButton
                inspector={row.inspector}
                onClick={() => history.push(`/physical-person-entry/${row.id}`)}
                show={
                  isReadOnlyUser() ||
                  (row.inspectorId !== user.id &&
                    (user.function === UserFunctions.POST_HEAD ||
                      user.function === UserFunctions.HEAD) &&
                    hasPermission(permissions, 'CREATE_TRANSACTION'))
                }
              />
              <CancelButton
                onClick={() => handleCancelTransaction(row)}
                disabled={isReadOnlyUser()}
                show={
                  row.isDuplicated &&
                  hasPermission(permissions, 'CREATE_TRANSACTION')
                }
              />
              {row.isGeneralDataConfirmed &&
                user.function !== UserFunctions.SCANN_OPERATOR &&
                hasPermission(permissions, 'ACCEPT_TRANSACTION') && (
                  <LiberModal
                    loadingLoader={loadingLoader}
                    setLoadingLoader={setLoadingLoader}
                    transactionId={row.id}
                    hasScale={row.hasScale}
                    reload={reload}
                    readOnly={
                      user.function === UserFunctions.POST_HEAD ||
                      isReadOnlyUser()
                    }
                  />
                )}
            </>
          );
        },
      },
    ];
    return c.filter((el) => el);
  }, [
    user,
    isCargo,
    reload,
    history,
    permissions,
    handleControl,
    postConstants,
    renderTimeColor,
    handleRetrieveControl,
    handleCancelTransaction,
    isReadOnlyUser,
    countries?.content,
    loadingLoader,
    setLoadingLoader,
  ]);

  useEffect(() => {
    setRute(JSON.parse(localStorage.getItem('rute')));
  }, []);

  useEffect(() => {
    if (user.postId) {
      const post = customsPosts?.content?.find((el) => el.id === user.postId);
      setCurrentCustomPost(post);
    }
  }, [user.postId, customsPosts?.content]);

  useEffect(() => {
    const ruteCheckboxes = {
      out: outCheckbox,
      in: inCheckbox,
      cargo: isCargo,
      auto: isAuto,
    };
    localStorage.setItem('rute', JSON.stringify(ruteCheckboxes));
  }, [outCheckbox, inCheckbox, isCargo, isAuto]);

  useEffect(() => {
    reload();
  }, [constants, customsOffices, reload, postId]);

  useEffect(() => {
    const interval = setInterval(() => {
      reload();
    }, MINUTE_MS);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  const handleChangePostId = (val) => {
    if (user?.posts?.length > 1) {
      setUser((prev) => ({
        ...prev,
        postId: val,
        customsPost: customsPosts?.content?.find((el) => el.id === val),
      }));
    }
    localStorage.setItem('postId', val);
    setPostId(val);
  };

  const rowClassName = () => {
    return 'animated-row';
  };

  const renderPostDetails = () => {
    return (
      <>
        {user.customsPost?.name && user.customsPost?.code ? (
          <div>
            {`Post Vamal: ${user.customsPost?.name}`}
            {`(${user.customsPost?.newCode})`}
          </div>
        ) : null}
        <div className="header__curs">
          <div>{`Curs valutar (EUR): ${user?.currency || ''} Lei`}</div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className={`loaderOverlay ${loadingLoader ? 'active' : ''}`}>
        <AppLoader />
      </div>

      <PageHeader ghost={false}>
        <div className="home__header-content">
          <div
            style={{
              display: 'flex',
              gap: '50px',
              justifyContent: 'center',
              alignItems: 'flex-start',
            }}
          >
            <div
              style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}
              className="home__header-title"
            >
              <h1>Rute</h1>

              <Icon
                name="carHeader"
                file="sprite"
                style={{
                  width: '30px',
                  height: '30px',
                }}
              />
            </div>
            <DirectionCheckboxes
              setIsAuto={setIsAuto}
              isAuto={isAuto}
              setIsCargo={setIsCargo}
              isCargo={isCargo}
              user={user}
              setOutCheckbox={setOutCheckbox}
              outCheckbox={outCheckbox}
              setInCheckbox={setInCheckbox}
              inCheckbox={inCheckbox}
              reload={reload}
            />
          </div>
          <div
            className="post__vamal"
            style={{ alignItems: 'center', textAlign: 'center' }}
          >
            {renderPostDetails()}
          </div>
          <div
            className="home__navigation-addbtn"
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '20px',
              marginLeft: '10px',
            }}
          >
            {user?.function === UserFunctions.OFFICE_HEAD ||
            user.function === UserFunctions.CENTRAL ||
            user.function === UserFunctions.MB_TEAM ||
            user.posts?.length > 1 ? (
              <div className="home__post">
                <CustomsPostSelect
                  value={postId || user?.customsPost?.id}
                  onChange={handleChangePostId}
                  officeId={user?.officeId}
                  allowedPostIds={
                    user.function === UserFunctions.MB_TEAM
                      ? customsPosts?.content?.map((el) => el.id)
                      : user?.posts?.map((el) => el.id)
                  }
                />
              </div>
            ) : null}
            {currentCustomPost?.addTrackButtonEnabled &&
              !(
                user.function === 'OFFICE_HEAD' || user.function === 'CENTRAL'
              ) && (
                <AddEntityButton
                  key="new"
                  disabled={!hasPermission(permissions, 'CREATE_TRANSACTION')}
                  path="/physical-person-entry/new"
                />
              )}
          </div>
        </div>
      </PageHeader>

      <div className="post__vamal post__vamal-mobile">
        {renderPostDetails()}
      </div>
      <Divider plain />

      <Table
        className="home-table"
        columns={columns}
        rowKey="id"
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ x: 500, drag: true }}
        sticky={{ offsetHeader: 63 }}
        rowClassName={rowClassName}
      />
    </>
  );
};

export default HomeView;
