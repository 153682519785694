import React from 'react';
import { Select, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

const values = ['SFSORGAN', 'SFSDEBTS', 'ANTA', 'VIGNETTE'];

const WebServiceSelect = ({ value, onSelect, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
      <Col xs={24} sm={24} md={10} lg={8} xl={8}>
        <Select
          {...rest}
          value={value}
          onSelect={onSelect}
          allowClear
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) !== -1
          }
        >
          {values.map((row) => (
            <Select.Option value={row} key={row}>
              {t(`entity.frontiera.webServices.${row.toLowerCase()}`)}
            </Select.Option>
          ))}
        </Select>
      </Col>
    </Row>
  );
};

export default WebServiceSelect;
