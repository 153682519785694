import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import StreamingPlayer from '../../StreamingPlayer';

const CameraStreamingModal = ({ camera, onClose }) => {
  const [cameraObj, setCameraObj] = useState(null);
  const [isStreamingVisible, setIsStreamingVisible] = useState(false);

  useEffect(() => {
    setCameraObj(camera);
    setIsStreamingVisible(!!camera?.id);
  }, [camera]);

  const handleModalClose = () => {
    setIsStreamingVisible(false);
    onClose();
  };

  return (
    <Modal
      title={camera?.description || 'Cameră de supraveghere LIVE'}
      width={800}
      centered
      maskClosable={false}
      open={cameraObj?.id}
      footer={null}
      onCancel={handleModalClose}
    >
      {isStreamingVisible && (
        <StreamingPlayer
          width="750px"
          streamIp={cameraObj?.cameraIp}
          streamName={cameraObj?.uuid}
        />
      )}
    </Modal>
  );
};

export default CameraStreamingModal;
