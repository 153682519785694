import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import UsersList from '../../components/admin/users/UsersList';
import RolesList from '../../components/admin/users/RolesList';
import PermissionsList from '../../components/admin/users/PermissionsList';
import AuthContext, { hasPermission } from '../../components/auth';

const Users = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { permissions } = user;
  const { key } = useParams();

  return (
    <Tabs defaultActiveKey={key || 'users'}>
      {hasPermission(permissions, 'USERS_USERS_LIST') && (
        <Tabs.TabPane tab={t('entity.admin.user._plural')} key="users">
          <UsersList />
        </Tabs.TabPane>
      )}
      {hasPermission(permissions, 'USERS_ROLES_LIST') && (
        <Tabs.TabPane tab={t('entity.admin.role._plural')} key="roles">
          <RolesList />
        </Tabs.TabPane>
      )}
      {hasPermission(permissions, 'USERS_PERMISSIONS_LIST') && (
        <Tabs.TabPane
          tab={t('entity.admin.permission._plural')}
          key="permissions"
        >
          <PermissionsList />
        </Tabs.TabPane>
      )}
    </Tabs>
  );
};

export default Users;
