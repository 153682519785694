import React, { useState } from 'react';
import TextArea from 'antd/lib/input/TextArea';
import { Modal, Form } from 'antd';

const CorrectionReasonModal = () => {
  const [correctionReasonModal, setCorrectionReasonModal] = useState(false);
  return (
    <>
      <Modal
        maskClosable={false}
        centered
        style={{ width: '400px' }}
        title="Motivul corectării datelor"
        visible={correctionReasonModal}
        onOk={() => setCorrectionReasonModal(false)}
        onCancel={() => setCorrectionReasonModal(false)}
        cancelText="Înapoi"
      >
        <Form.Item name="correctionReasonModalField">
          <TextArea maxLength={3000} disabled id="reason" rows={6} />
        </Form.Item>
      </Modal>
    </>
  );
};

export default CorrectionReasonModal;
