import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@ant-design/pro-layout';
import { Table } from 'antd';
import AuthContext, { hasPermission } from '../../../auth';
import { useTableScroll } from '../../../../hooks/useTableScroll';
import useDatasource from '../../../../hooks/useDatasource';
import {
  findAll,
  remove,
} from '../../../../services/taxonomies/risk-actions/risk-persons';
import Column from '../../../../helpers/Columns';
import ViewItemIcon from '../../../buttons/ViewItemIcon';
import EditItemIcon from '../../../buttons/EditItemIcon';
import DeleteItemIcon from '../../../buttons/DeleteItemIcon';
import AddEntityButton from '../../../buttons/AddEntityButton';

const RiskPersonsList = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { permissions } = user;

  useTableScroll();

  const { loading, pagination, content, handleChange, reload } =
    useDatasource(findAll);

  const columns = useMemo(
    () => [
      Column.text('id', t('entity.admin.riskPersons.id'), {
        width: 50,
      }),
      Column.text('name', t('entity.admin.riskPersons.name'), {
        width: 150,
        filter: true,
      }),
      Column.text('phone', t('entity.admin.riskPersons.phone'), {
        width: 150,
        filter: true,
      }),
      Column.text('description', t('entity.admin.riskPersons.description'), {
        width: 150,
      }),
      Column.date('createdAt', t('entity._.createdAt'), {
        width: 150,
      }),
      Column.text('createdBy', t('entity._.createdBy'), {
        width: 150,
      }),
      Column.date('updatedAt', t('entity._.updatedAt'), {
        width: 150,
      }),
      Column.text('updatedBy', t('entity._.updatedBy'), {
        width: 150,
      }),
      Column.bool('deleted', t('entity._.deleted._'), {
        width: 100,
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity._.deleted.true'),
          t('entity._.deleted.false'),
          t('entity._.deleted.unknown'),
        ],
      }),
      Column.actions(t('table.actions'), (record) =>
        record.deleted ? (
          <span>
            {hasPermission(permissions, 'TAXONOMIES_VIEW') && (
              <ViewItemIcon
                path={`/admin/taxonomies/risk-persons/${record.id}`}
              />
            )}
          </span>
        ) : (
          <span style={{ textAlign: 'right' }}>
            {hasPermission(permissions, 'TAXONOMIES_EDIT') && (
              <EditItemIcon
                path={`/admin/taxonomies/risk-persons/${record.id}`}
              />
            )}
            {hasPermission(permissions, 'TAXONOMIES_DELETE') && (
              <DeleteItemIcon
                title={t('entity.admin.riskPersons._delete', record)}
                message={t('entity.admin.riskPersons._deleted', record)}
                item={record}
                action={remove}
                reload={reload}
              />
            )}
          </span>
        ),
      ),
    ],
    [t, reload, permissions],
  );

  return (
    <>
      <PageHeader
        title={t('entity.admin.riskPersons._plural')}
        extra={[
          <AddEntityButton
            key="new"
            entity={t('entity.admin.riskPersons._singular')}
            path="/admin/taxonomies/risk-persons/new"
          />,
        ]}
      />
      <Table
        scroll={{ x: 500 }}
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
      />
    </>
  );
};

export default RiskPersonsList;
