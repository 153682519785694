import React, { useState } from 'react';
import { Modal, Input, Form, Button, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { create } from '../../../services/taxonomies/risk-actions/risk-persons';
import { phoneValidator } from '../../../services/utils/validator/Validator';
import AppLoader from '../../auth/AppLoader';
import RequiredLabel from '../../../services/utils/RequiredLabel';

const AddSMSPersonButton = () => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleModal = () => {
    setVisible((prev) => !prev);
    form.resetFields();
  };

  const handleAddSMSPerson = () => {
    setLoading(true);
    const entity = {
      phone: form.getFieldValue('phoneNr'),
      name: form.getFieldValue('nume'),
    };
    create(entity)
      .then(() => {
        notification.success({
          message: 'Numărul a fost salvat cu succes!',
        });
        setVisible(false);
      })
      .catch(() => {
        notification.error({
          message: 'Eroare la salvarea datelor',
        });
      })
      .finally(() => {
        form.resetFields();
        setLoading(false);
      });
  };

  return (
    <>
      <div className={`loaderOverlay ${loading ? 'active' : ''}`}>
        <AppLoader />
      </div>
      <Button
        key="addSMSBtn"
        type="primary"
        icon={<PlusOutlined />}
        onClick={handleModal}
      >
        Adaugă Persoană SMS
      </Button>
      <Form form={form}>
        <Modal
          maskClosable={false}
          centered
          key="addSMSPerson"
          width={500}
          title="Persoană SMS"
          open={visible}
          style={{ marginTop: '20px', marginBottom: '20px' }}
          onOk={handleAddSMSPerson}
          onCancel={handleModal}
          cancelText="Înapoi"
          okText="Salvează"
        >
          <Form.Item
            name="phoneNr"
            label={<RequiredLabel title="Telefon" />}
            labelCol={{ span: 24 }}
          >
            <Input
              onChange={(e) => {
                form.setFieldsValue({
                  phoneNr: phoneValidator(e.target.value, 15),
                });
              }}
            />
          </Form.Item>
          <Form.Item name="nume" label="Nume" labelCol={{ span: 24 }}>
            <Input />
          </Form.Item>
        </Modal>
      </Form>
    </>
  );
};

export default AddSMSPersonButton;
