import { notification } from 'antd';

const PASSWORD_LEN = 8;

export const checkPassword = (paswordDTO) => {
  const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{3,}$/;
  // let success = true;
  if (paswordDTO.currentPassword) {
    if (paswordDTO.currentPassword === paswordDTO.password) {
      notification.error({
        message: 'Parola nouă nu poate coincide cu parola veche',
      });
      return false;
    }
  }

  if (paswordDTO.password !== paswordDTO.repeatPassword) {
    notification.error({ message: 'Parola nu coincide' });
    return false;
  }
  if (paswordDTO.password.length < PASSWORD_LEN) {
    // success = false;
    notification.error({
      message: `Parola trebuie sa conțină minim ${PASSWORD_LEN} caractere`,
    });
    return false;
  }
  if (!pattern.test(paswordDTO.password)) {
    // success = false;
    notification.error({
      message:
        'Parola trebuie sa conțină minim un caracter majuscul, unul minuscul si o cifră',
    });
    return false;
  }
  return true;
  // return success;
};
