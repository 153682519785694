import React, { useCallback, useMemo, useState, useContext } from 'react';
//
import {
  Form,
  Row,
  Col,
  Space,
  Divider,
  Button,
  Input,
  Table,
  notification,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { FormContextProvider } from '../../hooks/useFormContext';
import Column from '../../helpers/Columns';
import {
  getOrganizationByIdno,
  getDebtsByIdno,
} from '../../services/admin/web-services/sfs-debts';
import { BUDGET_CODE } from '../admin/system/constants';
import AuthContext, { hasPermission } from '../auth';
import AppLoader from '../auth/AppLoader';
import { useTableScroll } from '../../hooks/useTableScroll';

const SFSDebtsForm = () => {
  const [form] = Form.useForm();
  const [organization, setOrganization] = useState(null);
  const [debts, setDebts] = useState(null);
  const [loadingLoader, setLoadingLoader] = useState(false);
  const { user } = useContext(AuthContext);
  const { permissions } = user;
  useTableScroll();
  const columns = useMemo(
    () => [
      {
        title: 'IDNO',
        dataIndex: 'idno',
        key: 'idno',
      },
      {
        title: 'Denumire',
        dataIndex: 'name',
        key: 'name',
      },
      Column.date('registrationDate', 'Data inregistrare', {
        width: 250,
      }),
      {
        title: 'Regiune',
        dataIndex: 'region',
        key: 'region',
      },
      {
        title: 'Localitate',
        dataIndex: 'locality',
        key: 'locality',
      },
      {
        title: 'Strada',
        dataIndex: 'street',
        key: 'street',
      },
      {
        title: 'Casa',
        dataIndex: 'house',
        key: 'house',
      },
      {
        title: 'Bloc',
        dataIndex: 'block',
        key: 'block',
      },
    ],
    [],
  );

  const columnsDebts = useMemo(
    () => [
      {
        title: 'Tip buget',
        key: 'budgetCode',
        width: 400,
        dataIndex: 'budgetCode',
        render: (value) => {
          return <>{BUDGET_CODE[value]?.text}</>;
        },
      },
      {
        title: 'Suma totală a restanței',
        dataIndex: 'totalDebt',
        key: 'totalDebt',
      },
    ],
    [],
  );

  const handleSearchOrganizationByIDNO = useCallback(() => {
    if (form.getFieldValue(['idno'])) {
      setLoadingLoader(true);
      setDebts(null);
      getOrganizationByIdno(form.getFieldValue(['idno']))
        .then((org) => {
          setLoadingLoader(false);
          if (!org || !org.idno) {
            setLoadingLoader(false);
            notification.warning({ message: 'Nu au fost găsite date' });
            return;
          }
          setOrganization([org]);
        })
        .catch(() => {
          setLoadingLoader(false);
          notification.error({ message: 'Eroare la căutarea datelor.' });
        });
    } else {
      notification.error({ message: 'Introduceți IDNO.' });
    }
  }, [form]);

  const handleSearchDebtsByIDNO = useCallback(() => {
    if (form.getFieldValue(['idnoDebt'])) {
      setLoadingLoader(true);
      setOrganization(null);
      getDebtsByIdno(form.getFieldValue(['idnoDebt']))
        .then((pers) => {
          setLoadingLoader(false);
          if (!pers || !pers.id) {
            setLoadingLoader(false);
            notification.warning({ message: 'Nu au fost găsite date' });
            return;
          }
          setDebts(pers);
        })
        .catch(() => {
          setLoadingLoader(false);
          notification.error({ message: 'Eroare la căutarea datelor.' });
        });
    } else {
      notification.error({ message: 'Introduceți IDNO.' });
    }
  }, [form]);

  return (
    <>
      <div className={`loaderOverlay ${loadingLoader ? 'active' : ''}`}>
        <AppLoader />
      </div>

      <FormContextProvider form={form}>
        <Form form={form} layout="vertical">
          {hasPermission(permissions, 'GET_ORGANIZATION_SFS_DEBTS_WS') && (
            <>
              <Divider plain>Căutare organizație in baza IDNO</Divider>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
                <Col xs={24} sm={24} md={10} lg={8} xl={8}>
                  <Form.Item
                    label="IDNO"
                    name="idno"
                    rules={[
                      {
                        pattern: new RegExp('^[0-9]*$'),
                        message: 'Format greșit',
                      },
                    ]}
                  >
                    <Input maxLength={13} />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Space>
                  <Button
                    type="primary"
                    onClick={() => handleSearchOrganizationByIDNO()}
                    icon={<SearchOutlined />}
                  >
                    Căutare
                  </Button>
                </Space>
              </Form.Item>
            </>
          )}
          {hasPermission(permissions, 'GET_DEBTS_SFS_WS') && (
            <>
              <Divider plain>
                Datoriile contribuabilului față de bugetul public național
              </Divider>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
                <Col xs={24} sm={24} md={10} lg={8} xl={8}>
                  <Form.Item
                    label="IDNO"
                    name="idnoDebt"
                    rules={[
                      {
                        pattern: new RegExp('^[0-9]*$'),
                        message: 'Format greșit',
                      },
                    ]}
                  >
                    <Input maxLength={13} />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item>
                <Space>
                  <Button
                    type="primary"
                    onClick={() => handleSearchDebtsByIDNO()}
                    icon={<SearchOutlined />}
                  >
                    Căutare
                  </Button>
                </Space>
              </Form.Item>
            </>
          )}
          {Array.isArray(organization) && organization.length > 0 && (
            <>
              <Divider plain>Rezultat căutare organizație</Divider>
              <Table
                columns={columns}
                dataSource={organization}
                pagination={false}
                rowKey="idno"
                size="small"
              />
            </>
          )}
          {debts &&
            debts.details &&
            Array.isArray(debts.details) &&
            debts.details.length > 0 && (
              <>
                <Divider plain>Datorii</Divider>
                <Table
                  columns={columnsDebts}
                  dataSource={debts.details}
                  pagination={false}
                  rowKey="budgetCode"
                  size="small"
                />
              </>
            )}
        </Form>
      </FormContextProvider>
    </>
  );
};

export default SFSDebtsForm;
