import React, { useEffect, useState } from 'react';
import { Select } from 'antd';

import useDictionaries from '../hooks/useDictionaries';
import { findAll as findAllCustomsPosts } from '../services/admin/users/customs-posts';
import { findAll as findAllOffices } from '../services/admin/users/customs-office';

const dictionaries = {
  posts: findAllCustomsPosts,
  offices: findAllOffices,
};

const CustomsPostSelect = ({
  value,
  onChange,
  allowedPostIds = [],
  postList = null,
  disabled = false,
  officeId = null,
  keys = 'id',
  width,
  ...rest
}) => {
  const [{ posts, offices }] = useDictionaries(dictionaries, {
    allowFetcher: !postList,
  });

  const [content, setContent] = useState([]);

  useEffect(() => {
    if (postList) {
      setContent(postList);
    } else if (officeId) {
      const office = offices?.content?.find((el) => el.id === officeId);
      setContent(office?.customsPosts);
    } else if (allowedPostIds !== null && allowedPostIds.length > 0) {
      setContent(
        posts?.content?.filter((el) => allowedPostIds.includes(el.id)),
      );
    } else {
      setContent(posts?.content);
    }
  }, [offices?.content, posts?.content, officeId, allowedPostIds, postList]);

  return (
    <Select
      style={{ width }}
      value={value}
      onChange={onChange}
      disabled={disabled}
      allowClear
      placeholder="Post vamal"
      showSearch
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) !== -1
      }
      {...rest}
    >
      {content
        ?.sort((a, b) => (a.code < b.code ? -1 : 0))
        .map(
          (row) =>
            !row?.deleted && (
              <Select.Option value={row[keys]} key={row.id}>
                {`(${row.newCode}) ${row.name}`}
              </Select.Option>
            ),
        )}
    </Select>
  );
};

export default CustomsPostSelect;
