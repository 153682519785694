import React, { useEffect, useState } from 'react';
import { getBlobFile } from '../../../services/admin/files';
import AvatarComponent from '../../AvatarComponent';
import EmptyRiskBox from './EmptyRiskBox';

const RiskPreview = ({ risk, onClick = () => {} }) => {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    if (risk.faceImageId) {
      getBlobFile(risk.faceImageId)
        .then((res) => {
          setImageUrl(URL.createObjectURL(res));
        })
        .catch((error) => {
          console.error('Error fetching blob file:', error);
        });
    } else {
      setImageUrl('');
    }

    return () => {
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }
    };
    // eslint-disable-next-line
  }, [risk]);

  return (
    <>
      {imageUrl ? (
        <div className="risk-card">
          <AvatarComponent
            src={imageUrl}
            radius={10}
            // size={140}
            onClick={onClick}
          />
          <div
            style={{ fontWeight: 'bold' }}
          >{`${risk.firstName} ${risk.lastName}`}</div>
        </div>
      ) : (
        <EmptyRiskBox risk={risk} onClick={onClick} />
      )}
    </>
  );
};

export default RiskPreview;
