import React, { useCallback } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Form,
  Input,
  notification,
  Popconfirm,
  Radio,
  Row,
  Space,
} from 'antd';
import dayjs from 'dayjs';
import {
  CheckOutlined,
  DollarOutlined,
  FileTextOutlined,
  FormatPainterOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import declarationImage from '../../../assets/declarationImage.png';
import SaveButton from '../../buttons/SaveButton';
import CancelButton from '../../buttons/CancelButton';
import BackButton from '../../buttons/BackButton';
import {
  changeStatus,
  generatePdfInNewTab,
} from '../../../services/lucrative/special-services-request';
import useFormErrors from '../../../hooks/useFormErrors';
import EditContainerRecordsForm from './EditContainerRecordsForm';
import EditVehicleRecordsForm from './EditVehicleRecordsForm';
import CustomsPostSelect from '../../CustomsPostSelect';
import {
  dotValidator,
  floatValidator,
} from '../../../services/utils/validator/Validator';
import SERVICE_REQUEST_STATUS from '../../../enums/SpecialServiceRequestStatus';
import RequiredLabel from '../../../services/utils/RequiredLabel';

const EditSpecialServiceRequestForm = ({
  service,
  onSubmit,
  onCancel,
  errors,
  disabled,
  reload = () => {},
}) => {
  const { Panel } = Collapse;
  const [form] = Form.useForm();
  const [t] = useTranslation();
  useFormErrors(form, errors);

  const history = useHistory();

  const handlePdf = useCallback(() => {
    generatePdfInNewTab(`/export-pdf/${service?.id}`, {})
      .then(() => {
        notification.success({
          message: t('messages.report.generated.success'),
          duration: 3,
        });
      })
      .catch((err) => {
        if (err)
          notification.error({
            message: 'A apărut o eroare la generarea cererii',
          });
      });
  }, [service?.id, t]);

  const handleStatusChange = useCallback(
    (newStatus, pushBack = false) => {
      changeStatus(service?.id, newStatus)
        .then(() => {
          form.setFieldsValue({ status: newStatus });
          if (pushBack) {
            reload();
          } else {
            history.push('/lucrative/special-services-requests');
          }
        })
        .catch(() => {
          notification.error({
            message: 'A apărut o eroare la schimbarea statutului',
          });
        });
    },
    [service?.id, form, reload, history],
  );

  const finalize = useCallback(() => {
    const data = form.getFieldsValue();
    if (service?.customsClearanceAfterWorkHours) {
      if (
        !data?.startControlDate ||
        !data?.endControlDate ||
        !data?.docNumber1
      ) {
        notification.warning({
          message: 'VĂMUIRE ÎN AFARA ORELOR DE PROGRAM',
          description:
            'Toate câmpurile despre vămuirea mărfurilor în afara orelor de program, sunt obligatorii pentru completare.',
        });
        return;
      }
    }
    if (service?.customsClearanceOutsideCustomsPosts) {
      if (
        !data?.officeDepartureDate ||
        !data?.officeArrivalDate ||
        !data?.distance ||
        !data?.transportUnit ||
        !data?.docNumber2
      ) {
        notification.warning({
          message: 'VĂMUIRE ÎN AFARA POSTURILOR VAMALE',
          description:
            'Toate câmpurile despre vămuirea mărfurilor în caz de vămuire în alte locuri decât posturile vamale, sunt obligatorii pentru completare.',
        });
        return;
      }
    }
    onSubmit(data).then(() =>
      handleStatusChange(SERVICE_REQUEST_STATUS.FINALIZED, true),
    );
  }, [
    form,
    service?.customsClearanceAfterWorkHours,
    service?.customsClearanceOutsideCustomsPosts,
    onSubmit,
    handleStatusChange,
  ]);

  const accept = useCallback(() => {
    onSubmit(form.getFieldsValue()).then(() =>
      handleStatusChange(SERVICE_REQUEST_STATUS.APPROVED),
    );
  }, [handleStatusChange, form, onSubmit]);

  const reject = useCallback(() => {
    const data = form.getFieldsValue();
    if (!data?.statusMessage) {
      notification.info({
        message: `La respingerea cererii, câmpul "Solicitarea este respină deoarece" este obligatoriu.`,
      });
      return;
    }
    onSubmit(data).then(() =>
      handleStatusChange(SERVICE_REQUEST_STATUS.REJECTED),
    );
  }, [handleStatusChange, form, onSubmit]);

  const draft = useCallback(() => {
    onSubmit(form.getFieldsValue()).then(() =>
      handleStatusChange(SERVICE_REQUEST_STATUS.DRAFT),
    );
  }, [handleStatusChange, form, onSubmit]);

  const formatDate = (value) => (value ? dayjs(value) : null);

  const handleCreatePayment = useCallback(() => {
    if (service?.id) {
      history.push(`/payments/payments-notice/new/${service?.id}`);
    }
  }, [service?.id, history]);

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          ...service,
          applicationDateTime: formatDate(service?.applicationDateTime),
          endDate: formatDate(service?.endDate),
          startDate: formatDate(service?.startDate),
          regDate: formatDate(service?.regDate),
          date: formatDate(service?.date),
          startControlDate: formatDate(service?.startControlDate),
          endControlDate: formatDate(service?.endControlDate),
          officeDepartureDate: formatDate(service?.officeDepartureDate),
          officeArrivalDate: formatDate(service?.officeArrivalDate),
        }}
        onFinish={onSubmit}
      >
        <div>
          <div style={{ textAlign: 'center' }}>
            <img
              className="declaration__header-image"
              src={declarationImage}
              alt="declarationImage"
            />
          </div>
          <Divider>{t('entity.admin.publicSpecialServices.divider1')}</Divider>
          <Row className="declaration__row" gutter={5}>
            <Col span={8}>
              <div
                className="declaration__field"
                style={{ marginLeft: '15px' }}
              >
                <div className="declaration__field-head">
                  <div className="declaration__field-title">
                    {t('entity.admin.publicSpecialServices.customPost')}
                  </div>
                </div>
                <Form.Item name="postCode">
                  <CustomsPostSelect keys="code" disabled />
                </Form.Item>
              </div>
            </Col>
            <Col span={8}>
              <Form.Item
                name="customsClearanceAfterWorkHours"
                disabled
                valuePropName="checked"
                label=" "
                style={{ marginLeft: '30px' }}
              >
                <Checkbox disabled>
                  {t('entity.admin.publicSpecialServices.outsideWorkingHours')}
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="customsClearanceOutsideCustomsPosts"
                disabled
                valuePropName="checked"
                label=" "
              >
                <Checkbox disabled>
                  {t('entity.admin.publicSpecialServices.inOtherPlaces')}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Collapse
            className="lucrative__collapse"
            defaultActiveKey={[
              service?.status !== SERVICE_REQUEST_STATUS.APPROVED
                ? 'divider2'
                : null,
              'divider3',
              service?.status === SERVICE_REQUEST_STATUS.APPROVED &&
              service?.customsClearanceAfterWorkHours
                ? 'divider4'
                : null,
              service?.status === SERVICE_REQUEST_STATUS.APPROVED &&
              service?.customsClearanceOutsideCustomsPosts
                ? 'divider5'
                : null,
            ]}
          >
            <Panel
              key="divider2"
              header={t('entity.admin.publicSpecialServices.divider2')}
            >
              <div className="specialService__fields">
                <Row className="declaration__row" gutter={5}>
                  <Col span={5}>
                    <div className="declaration__field">
                      <div className="declaration__field-head">
                        <div className="declaration__field-title">
                          {t('entity.admin.publicSpecialServices.date')}
                        </div>
                      </div>
                      <Form.Item name="applicationDateTime" disabled>
                        <DatePicker showTime={{ format: 'HH:mm' }} disabled />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={5}>
                    <div className="declaration__field">
                      <div className="declaration__field-head">
                        <div className="declaration__field-title">
                          {t('entity.admin.publicSpecialServices.name')}
                        </div>
                      </div>
                      <Form.Item name="declarantName" disabled>
                        <Input disabled />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={5}>
                    <div className="declaration__field">
                      <div className="declaration__field-head">
                        <div className="declaration__field-title">
                          {t('entity.admin.publicSpecialServices.surname')}
                        </div>
                      </div>
                      <Form.Item name="declarantSurname" disabled>
                        <Input disabled />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={5}>
                    <div className="declaration__field">
                      <div className="declaration__field-head">
                        <div className="declaration__field-title">
                          {t('entity.admin.publicSpecialServices.function')}
                        </div>
                      </div>
                      <Form.Item name="declarantFunction" disabled>
                        <Input disabled />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={4}>
                    <div className="declaration__field">
                      <div className="declaration__field-head">
                        <div className="declaration__field-title">
                          {t(
                            'entity.admin.publicSpecialServices.declarantIdnoEori',
                          )}
                        </div>
                      </div>
                      <Form.Item name="declarantIdnoEori" disabled>
                        <Input disabled maxLength={20} />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row className="declaration__row" gutter={5}>
                  <Col span={6}>
                    <div className="declaration__field">
                      <div className="declaration__field-head">
                        <div className="declaration__field-title">
                          {t('entity.admin.publicSpecialServices.address')}
                        </div>
                      </div>
                      <Form.Item name="declarantAddress" disabled>
                        <Input disabled />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className="declaration__field">
                      <div className="declaration__field-head">
                        <div className="declaration__field-title">
                          {t('entity.admin.publicSpecialServices.phoneNumber')}
                        </div>
                      </div>
                      <Form.Item name="declarantPhoneNumber" disabled>
                        <Input disabled />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className="declaration__field">
                      <div className="declaration__field-head">
                        <div className="declaration__field-title">
                          {t('entity.admin.publicSpecialServices.email')}
                        </div>
                      </div>
                      <Form.Item name="declarantEmail" disabled>
                        <Input disabled />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className="declaration__field">
                      <div className="declaration__field-head">
                        <div className="declaration__field-title">
                          {t('entity.admin.publicSpecialServices.fax')}
                        </div>
                      </div>
                      <Form.Item name="declarantFaxNumber" disabled>
                        <Input disabled />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row className="declaration__row" gutter={5}>
                  <Col span={24}>
                    <div className="declaration__field">
                      <div className="declaration__field-head">
                        <div className="declaration__field-title">
                          {t('entity.admin.publicSpecialServices.reason')}
                        </div>
                      </div>
                      <Form.Item name="reasonJustifyRequest" disabled>
                        <Input disabled />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row className="declaration__row" gutter={5}>
                  <Col span={6}>
                    <div className="declaration__field">
                      <div className="declaration__field-head">
                        <div className="declaration__field-title">
                          11 Opțiune de timp preferată
                        </div>
                      </div>

                      <Row className="declaration__row" gutter={5}>
                        <Form.Item name="preferredTimeControl">
                          <Row>
                            <div>
                              <Form.Item name="preferredTimeControl">
                                <Radio.Group disabled>
                                  <Radio value="1">
                                    {t(
                                      'entity.admin.publicSpecialServices.preferredTime1',
                                    )}
                                  </Radio>
                                  <Radio value="0">
                                    {t(
                                      'entity.admin.publicSpecialServices.preferredTime2',
                                    )}
                                  </Radio>
                                </Radio.Group>
                              </Form.Item>
                            </div>
                          </Row>
                        </Form.Item>
                      </Row>
                    </div>
                  </Col>
                  <Col span={18}>
                    <Row className="declaration__row" gutter={5}>
                      <Col span={24}>
                        <div className="declaration__field">
                          <div className="declaration__field-head">
                            <div className="declaration__field-title">
                              {t(
                                'entity.admin.publicSpecialServices.timeRange',
                              )}
                            </div>
                          </div>
                          <Row className="declaration__row" gutter={5}>
                            <Col span={12}>
                              <Form.Item name="startDate" disabled>
                                <DatePicker
                                  showTime={{ format: 'HH:mm' }}
                                  disabled
                                />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item name="endDate" disabled>
                                <DatePicker
                                  showTime={{ format: 'HH:mm' }}
                                  disabled
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="declaration__row" gutter={5}>
                  <Col span={24}>
                    <div className="declaration__field">
                      <div className="declaration__field-head">
                        <div className="declaration__field-title">
                          {t('entity.admin.publicSpecialServices.zipAddress')}
                        </div>
                      </div>
                      <Form.Item
                        name="zipAddress"
                        disabled
                        rules={[
                          {
                            required: true,
                            message: 'Cimpul este  obligatoriu',
                          },
                        ]}
                      >
                        <Input disabled />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={16}>
                    <Row className="declaration__row" gutter={5}>
                      <Col span={24}>
                        <div className="declaration__field">
                          <div className="declaration__field-head">
                            <div className="declaration__field-title">
                              {t(
                                'entity.admin.publicSpecialServices.vehiclesDetails',
                              )}
                            </div>
                            <Form.Item
                              name="havingVehicle"
                              valuePropName="checked"
                            >
                              <Checkbox
                                defaultChecked="false"
                                disabled
                                onChange={(value) =>
                                  value.target.checked
                                    ? null
                                    : form.setFieldValue('havingVehicle', null)
                                }
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Col span={24}>
                      <Form.Item
                        noStyle
                        disabled
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.havingVehicle !==
                          currentValues.havingVehicle
                        }
                      >
                        {({ getFieldValue }) =>
                          getFieldValue('havingVehicle') && (
                            <EditVehicleRecordsForm disabled />
                          )
                        }
                      </Form.Item>
                    </Col>
                  </Col>
                  <Col span={8}>
                    <Row className="declaration__row" gutter={5}>
                      <Col span={24}>
                        <div className="declaration__field">
                          <div className="declaration__field-head">
                            <div className="declaration__field-title">
                              {t(
                                'entity.admin.publicSpecialServices.containerDetails',
                              )}
                            </div>
                            <Form.Item
                              name="havingContainer"
                              disabled
                              valuePropName="checked"
                            >
                              <Checkbox
                                defaultChecked="false"
                                disabled
                                onChange={(value) =>
                                  value.target.checked
                                    ? null
                                    : form.setFieldValue(
                                        'havingContainer',
                                        null,
                                      )
                                }
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Col span={24}>
                      <Form.Item
                        noStyle
                        disabled
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.havingContainer !==
                          currentValues.havingContainer
                        }
                      >
                        {({ getFieldValue }) =>
                          getFieldValue('havingContainer') && (
                            <EditContainerRecordsForm disabled />
                          )
                        }
                      </Form.Item>
                    </Col>
                  </Col>
                </Row>
                <Row className="declaration__row" gutter={5}>
                  <Col span={24}>
                    <div className="declaration__field">
                      <div className="declaration__field-head">
                        <div className="declaration__field-title">
                          {t('entity.admin.publicSpecialServices.mrn')}
                        </div>
                      </div>
                      <Form.Item name="mrn" disabled>
                        <Input disabled />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row className="declaration__row" gutter={5}>
                  <Col span={24}>
                    <div className="declaration__field">
                      <div className="declaration__field-head">
                        <div className="declaration__field-title">
                          {t('entity.admin.publicSpecialServices.description')}
                        </div>
                      </div>
                      <Form.Item name="goodsQuantityDescription" disabled>
                        <Input.TextArea disabled maxLength={3000} />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row className="declaration__field" gutter={5}>
                  <Col span={8}>
                    <Form.Item
                      name="engagement_1"
                      disabled
                      valuePropName="checked"
                      label=" "
                    >
                      <Checkbox disabled>
                        {t('entity.admin.publicSpecialServices.engagement1')}
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="engagement_2"
                      disabled
                      valuePropName="checked"
                      label=" "
                    >
                      <Checkbox disabled>
                        {t('entity.admin.publicSpecialServices.engagement2')}
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="engagement_3"
                      disabled
                      valuePropName="checked"
                      label=" "
                    >
                      <Checkbox disabled>
                        {t('entity.admin.publicSpecialServices.engagement3')}
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Panel>
            <Panel
              key="divider3"
              header={t('entity.admin.publicSpecialServices.divider3')}
            >
              <Row className="declaration__row" gutter={5}>
                <Col span={12}>
                  <div className="declaration__field">
                    <div className="declaration__field-head">
                      <div className="declaration__field-title">
                        {t('entity.admin.publicSpecialServices.registrationNr')}
                      </div>
                    </div>
                    <Form.Item
                      name="regNumber"
                      initialValue={service?.seqNumber}
                    >
                      <Input disabled />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="declaration__field">
                    <div className="declaration__field-head">
                      <div className="declaration__field-title">
                        {t(
                          'entity.admin.publicSpecialServices.registrationDate',
                        )}
                      </div>
                    </div>
                    <Form.Item name="regDate">
                      <DatePicker
                        format="DD.MM.YYYY HH:mm"
                        showTime
                        disabled={
                          service?.status !== SERVICE_REQUEST_STATUS.DRAFT
                        }
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row className="declaration__row" gutter={5}>
                <Col span={12}>
                  <div className="declaration__field">
                    <div className="declaration__field-head">
                      <div className="declaration__field-title">
                        {t(
                          'entity.admin.publicSpecialServices.apruvedRejectedReasen',
                        )}
                      </div>
                    </div>
                    <Form.Item name="statusMessage">
                      <Input
                        disabled={
                          service?.status !== SERVICE_REQUEST_STATUS.DRAFT
                        }
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="declaration__field">
                    <div className="declaration__field-head">
                      <div className="declaration__field-title">
                        {t('entity.admin.publicSpecialServices.text21')}
                      </div>
                    </div>
                    <Form.Item name="designedOfficer">
                      <Input
                        disabled={
                          service?.status !== SERVICE_REQUEST_STATUS.DRAFT
                        }
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row className="declaration__row" gutter={5}>
                <Col span={12}>
                  <div className="declaration__field">
                    <div className="declaration__field-head">
                      <div className="declaration__field-title">
                        {t('entity.admin.publicSpecialServices.nameSurname')}
                      </div>
                    </div>
                    <Form.Item name="fullName">
                      <Input
                        disabled={
                          service?.status !== SERVICE_REQUEST_STATUS.DRAFT
                        }
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="declaration__field">
                    <div className="declaration__field-head">
                      <div className="declaration__field-title">
                        {t('entity.admin.publicSpecialServices.date2')}
                      </div>
                    </div>
                    <Form.Item name="date">
                      <DatePicker
                        format="DD.MM.YYYY HH:mm"
                        showTime
                        disabled={
                          service?.status !== SERVICE_REQUEST_STATUS.DRAFT
                        }
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </Panel>
            <Panel
              key="divider4"
              header={
                <RequiredLabel
                  required={service?.customsClearanceAfterWorkHours}
                  title={t('entity.admin.publicSpecialServices.divider4')}
                />
              }
            >
              <Row className="declaration__row" gutter={5}>
                <Col span={8}>
                  <div className="declaration__field">
                    <div className="declaration__field-head">
                      <div className="declaration__field-title">
                        {t('entity.admin.publicSpecialServices.startDate')}
                      </div>
                    </div>
                    <Form.Item name="startControlDate">
                      <DatePicker
                        format="DD.MM.YYYY HH:mm"
                        showTime
                        disabled={disabled}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="declaration__field">
                    <div className="declaration__field-head">
                      <div className="declaration__field-title">
                        {t('entity.admin.publicSpecialServices.endDate')}
                      </div>
                    </div>
                    <Form.Item name="endControlDate">
                      <DatePicker
                        format="DD.MM.YYYY HH:mm"
                        showTime
                        disabled={disabled}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="declaration__field">
                    <div className="declaration__field-head">
                      <div className="declaration__field-title">
                        {t('entity.admin.publicSpecialServices.nrRedAct')}
                      </div>
                    </div>
                    <Form.Item name="docNumber1">
                      <Input disabled={disabled} />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </Panel>
            <Panel
              key="divider5"
              header={
                <RequiredLabel
                  required={service?.customsClearanceOutsideCustomsPosts}
                  title={t('entity.admin.publicSpecialServices.divider5')}
                />
              }
            >
              <>
                <Row className="declaration__row" gutter={5}>
                  <Col span={8}>
                    <div className="declaration__field">
                      <div className="declaration__field-head">
                        <div className="declaration__field-title">
                          {t(
                            'entity.admin.publicSpecialServices.otherLocation1',
                          )}
                        </div>
                      </div>
                      <Form.Item name="officeDepartureDate">
                        <DatePicker
                          format="DD.MM.YYYY HH:mm"
                          showTime
                          disabled={disabled}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className="declaration__field">
                      <div className="declaration__field-head">
                        <div className="declaration__field-title">
                          {t(
                            'entity.admin.publicSpecialServices.otherLocation2',
                          )}
                        </div>
                      </div>
                      <Form.Item name="officeArrivalDate">
                        <DatePicker
                          format="DD.MM.YYYY HH:mm"
                          showTime
                          disabled={disabled}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className="declaration__field">
                      <div className="declaration__field-head">
                        <div className="declaration__field-title">
                          {t(
                            'entity.admin.publicSpecialServices.otherLocation3',
                          )}
                        </div>
                      </div>
                      <Form.Item name="distance">
                        <Input
                          disabled={disabled}
                          onChange={(e) => {
                            form.setFieldsValue({
                              distance: floatValidator(
                                dotValidator(e.target.value),
                                7,
                                2,
                              ),
                            });
                          }}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row className="declaration__row" gutter={5}>
                  <Col span={24}>
                    <div className="declaration__field">
                      <div className="declaration__field-head">
                        <div className="declaration__field-title">
                          {t(
                            'entity.admin.publicSpecialServices.otherLocation4',
                          )}
                        </div>
                      </div>
                      <Form.Item name="transportUnit">
                        <Input disabled={disabled} />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row className="declaration__row" gutter={5}>
                  <Col span={24}>
                    <div className="declaration__field">
                      <div className="declaration__field-head">
                        <div className="declaration__field-title">
                          {t(
                            'entity.admin.publicSpecialServices.otherLocation5',
                          )}
                        </div>
                      </div>
                      <Form.Item name="docNumber2">
                        <Input disabled={disabled} />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </>
            </Panel>
          </Collapse>

          <Form.Item>
            <Space>
              {service?.status === SERVICE_REQUEST_STATUS.DRAFT ? (
                <>
                  <SaveButton />
                  <Popconfirm
                    onConfirm={accept}
                    title="Sunteți sigur că doriți să acceptați cererea?"
                    okText="Da"
                    cancelText="Nu"
                  >
                    <Button icon={<CheckOutlined />} className="btn__liber">
                      Acceptă cererea
                    </Button>
                  </Popconfirm>
                  <Popconfirm>
                    <CancelButton
                      title="Respinge cererea"
                      requireConfirm
                      confirmProps={{
                        onConfirm: reject,
                        title: 'Sunteți sigur că doriți să respingeți cererea?',
                        okText: 'Da',
                        cancelText: 'Nu',
                      }}
                    />
                  </Popconfirm>
                </>
              ) : (
                <>
                  {service?.status === SERVICE_REQUEST_STATUS.APPROVED ? (
                    <Popconfirm
                      onConfirm={finalize}
                      title="Sunteți sigur că doriți să finalizați cererea"
                      okText="Da"
                      cancelText="Nu"
                    >
                      <Button icon={<CheckOutlined />} className="btn__liber">
                        Finalizează
                      </Button>
                    </Popconfirm>
                  ) : null}
                  {service?.status === SERVICE_REQUEST_STATUS.FINALIZED &&
                  !service?.paymentNotice ? (
                    <Button
                      icon={<DollarOutlined />}
                      type="primary"
                      onClick={handleCreatePayment}
                    >
                      Crează aviz de plată
                    </Button>
                  ) : null}
                  {service?.status !== SERVICE_REQUEST_STATUS.FINALIZED ? (
                    <Popconfirm
                      onConfirm={draft}
                      title="Sunteți sigur că doriți să marcați cererea ca DRAFT?"
                      okText="Da"
                      cancelText="Nu"
                    >
                      <Button icon={<FormatPainterOutlined />}>
                        Marchează draft
                      </Button>
                    </Popconfirm>
                  ) : null}
                </>
              )}
              <Button
                type="primary"
                icon={<FileTextOutlined />}
                onClick={() => handlePdf()}
              >
                Generare Cerere
              </Button>
              <BackButton onCancel={onCancel} />
            </Space>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default EditSpecialServiceRequestForm;
