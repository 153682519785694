import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Form, Modal, notification, Popconfirm, Row } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import TextArea from 'antd/lib/input/TextArea';
import { CheckOutlined } from '@ant-design/icons';
import Sound from 'react-sound';
import useDatasource from '../../../hooks/useDatasource';
import { findAll as findAllRisks, remove } from '../../../services/risks/risks';
import AuthContext, { hasPermission } from '../../../components/auth';
import UserFunctions from '../../../enums/UserFunctions';
import CustomsPostSelect from '../../../components/CustomsPostSelect';
import { findAll as findAllCustomsOffices } from '../../../services/admin/users/customs-office';
import { findAll as findAllCustomsPosts } from '../../../services/admin/users/customs-posts';
import useDictionaries from '../../../hooks/useDictionaries';
import AppLoader from '../../../components/auth/AppLoader';
import RiskPreview from '../../../components/lucrative/risks/RiskPreview';
import ExpandedRisk from '../../../components/lucrative/risks/ExpandedRisk';
import RequiredLabel from '../../../services/utils/RequiredLabel';
import CancelButton from '../../../components/buttons/CancelButton';
import messageSound from '../../../assets/sounds/messageNotification.mp3';

const dictionaries = {
  customsOffices: findAllCustomsOffices,
  customsPosts: findAllCustomsPosts,
};

const MINUTE_MS = 10000;
const RisksView = () => {
  window.soundManager.setup({ debugMode: false });
  const [playSound, setPlaySound] = useState(false);
  const { user } = useContext(AuthContext);
  const { permissions } = user;
  const [{ customsPosts, customsOffices }] = useDictionaries(dictionaries);

  const [postCode, setPostCode] = useState(null);
  const [allowedPosts, setAllowedPosts] = useState([]);
  const [loader, setLoading] = useState(false);
  const [expandedRisk, setExpandedRisk] = useState(false);
  const [cashedRisksIds, setCashedRiskIds] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    if (user?.officeId && customsOffices?.content?.length > 0) {
      setAllowedPosts(
        customsOffices?.content
          ?.find((el) => el?.id === user.officeId)
          ?.customsPosts?.filter((post) => post.allowSARA),
      );
    } else if (user?.posts?.length > 1) {
      const allowedPostsIds = user?.posts?.map((el) => el.id);
      setAllowedPosts(
        customsPosts?.content?.filter(
          (el) => allowedPostsIds?.includes(el.id) && el.allowSARA,
        ),
      );
    } else if (user?.posts?.length === 1) {
      const post = user?.posts?.[0];
      if (post?.allowSARA) {
        setAllowedPosts([post]);
        setPostCode(post.code);
      }
    } else if (user?.customsPost) {
      if (user?.customsPost) {
        setAllowedPosts([user?.customsPost]);
        setPostCode(user?.customsPost?.code);
      }
    } else if (customsPosts?.content?.length > 0) {
      const post = customsPosts?.content?.find(
        (el) => el.id === user?.postId && el.allowSARA,
      );
      setAllowedPosts([post]);
      setPostCode(post?.code);
    }
  }, [
    customsOffices?.content,
    customsPosts?.content,
    user?.customsPost,
    user?.postId,
    user?.officeId,
    user?.posts,
  ]);

  const renderPostDetails = useCallback(() => {
    let selectedPost = {};
    if (postCode) {
      selectedPost = customsPosts?.content?.find((el) => el.code === postCode);
    } else if (user?.posts?.length === 1) {
      selectedPost = user?.customsPost;
    }
    return (
      <>
        {selectedPost?.name && selectedPost?.newCode ? (
          <div>
            {`Post Vamal: ${selectedPost?.name}`}
            {`(${selectedPost?.newCode})`}
          </div>
        ) : null}
        <div className="header__curs">
          <div>{`Curs valutar (EUR): ${user?.currency || ''} Lei`}</div>
        </div>
      </>
    );
  }, [
    postCode,
    user?.posts,
    user?.currency,
    user?.customsPost,
    customsPosts?.content,
  ]);

  const handler = useCallback(
    (...params) => {
      const spec = params[0];
      if (!spec.sort) {
        spec.sort = ['date', 'desc'];
      }

      spec.criterias = {
        ...spec.criterias,
        postCode:
          postCode ||
          allowedPosts?.map((el) => el?.code) ||
          user?.customsPost?.code,
      };
      return findAllRisks(spec);
    },
    [allowedPosts, postCode, user?.customsPost?.code],
  );

  const { content, reload, loading } = useDatasource(handler, {
    allowFetcher: allowedPosts.length > 0 || !!postCode,
  });

  useEffect(() => {
    const contentIds = content?.map((el) => el.id);
    if (cashedRisksIds?.length > 0) {
      contentIds.forEach((id) => {
        if (!cashedRisksIds.includes(id)) {
          setPlaySound(true);
        }
      });
    }
    setCashedRiskIds(contentIds);
    // eslint-disable-next-line
  }, [content]);

  useEffect(() => {
    if (!loading) {
      setLoading(false);
    }
  }, [loading]);

  useEffect(() => {
    const interval = setInterval(() => {
      reload();
    }, MINUTE_MS);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  const handleChangePost = useCallback(
    (val) => {
      setPostCode(val);
      reload();
    },
    [reload],
  );

  const getVerifyBtn = () => {
    return (
      <Popconfirm
        key="verifyRisk"
        placement="topLeft"
        title="Sunteți sigur(ă) că doriți să marcați persoana `Verificată` ?"
        description={
          <>
            <Form form={form}>
              <Form.Item
                name="reactionArea"
                label={<RequiredLabel title="Acțiuni interprinse" />}
              >
                <TextArea />
              </Form.Item>
            </Form>
          </>
        }
        onConfirm={() => {
          const reaction = form.getFieldValue('reactionArea');
          if (!reaction) {
            notification.error({
              message: 'Câmpul `Acțiuni interprinse` este obligatoriu',
            });
            form.resetFields();
            return;
          }
          remove(expandedRisk.id, { message: reaction })
            .then(() => {
              notification.success({
                message: 'Persoana a fost marcată ca `Verificată`',
              });
              form.resetFields();
              setExpandedRisk(false);
              reload();
            })
            .catch(() => {
              notification.error({
                message: 'A apărut o eroare la salvarea datelor',
              });
            });
        }}
        okText="Da"
        cancelText="Înapoi"
        onCancel={() => {
          form.resetFields();
        }}
      >
        <Button key="verifyRiskBtn" type="primary" icon={<CheckOutlined />}>
          Verificat
        </Button>
      </Popconfirm>
    );
  };

  return (
    <>
      {playSound ? (
        <Sound
          url={messageSound}
          playStatus={Sound.status.PLAYING}
          volume={70}
          onFinishedPlaying={() => setPlaySound(false)}
          debug={false}
          debugMode={false}
        />
      ) : null}
      <div className={`loaderOverlay ${loader ? 'active' : ''}`}>
        <AppLoader />
      </div>
      <div className="post__vamal">{renderPostDetails()}</div>
      <Row justify="space-between">
        <PageHeader title="Persoane depistate" />
        {user?.function === UserFunctions.OFFICE_HEAD ||
        user.function === UserFunctions.CENTRAL ||
        user.posts?.length > 1 ? (
          <div className="home__post">
            <CustomsPostSelect
              onChange={handleChangePost}
              postList={allowedPosts}
              keys="code"
            />
          </div>
        ) : null}
      </Row>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '20px',
          marginTop: '20px',
        }}
      >
        {content?.map((risk) => (
          <RiskPreview
            key={risk.id}
            risk={risk}
            onClick={() => setExpandedRisk(risk)}
          />
        ))}
      </div>
      <Modal
        width={1400}
        visible={!!expandedRisk}
        onOk={() => setExpandedRisk(false)}
        onCancel={() => setExpandedRisk(false)}
        centered
        title="Persoana depistată"
        footer={[
          <>
            <CancelButton
              title="Înapoi"
              onCancel={() => setExpandedRisk(false)}
            />
            {hasPermission(permissions, 'RISK_MANAGEMENT')
              ? getVerifyBtn()
              : null}
          </>,
        ]}
      >
        <div style={{ marginTop: '20px' }}>
          <ExpandedRisk risk={expandedRisk} />
        </div>
      </Modal>
    </>
  );
};
export default RisksView;
