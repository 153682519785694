import React, { useMemo, useState, useContext } from 'react';

import {
  Form,
  Row,
  Col,
  Space,
  Divider,
  Button,
  Input,
  Table,
  notification,
  Modal,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { FormContextProvider } from '../../hooks/useFormContext';
import Column from '../../helpers/Columns';
import AuthContext, { hasPermission } from '../auth';
import AppLoader from '../auth/AppLoader';
import { useTableScroll } from '../../hooks/useTableScroll';
import { findAll as findAllErrorCodes } from '../../services/taxonomies/vignette-error-code';
import { findAll as findAllTariffPositions } from '../../services/taxonomies/vignette-tariff-position';
import {
  getVignetteByVehicleVIN,
  getVignettesByVehicleRegistrationNumber,
  getUncoveredPeriodByVehicleVIN,
  // getVignetteByVehicleRegistrationNumber, // TODO: TEMPORAY changed with asd-old-vinieta
  getVignettesByVehicleVIN,
  // getUncoveredPeriodByVehicleRegistrationNumber, // TODO: TEMPORAY changed with asd-old-vinieta
} from '../../services/admin/web-services/asd-vinieta';

import {
  getVignetteByVehicleRegistrationNumber,
  getUncoveredPeriodByVehicleRegistrationNumber,
} from '../../services/admin/web-services/asd-old-vinieta';

import ViewItemIcon from '../buttons/ViewItemIcon';
import useDictionaries from '../../hooks/useDictionaries';

const dictionaries = {
  errorCodes: findAllErrorCodes,
  tariffPositions: findAllTariffPositions,
};

const VignetteForm = () => {
  const [form] = Form.useForm();

  const [{ errorCodes, tariffPositions }] = useDictionaries(dictionaries);

  const [vignettes, setVignettes] = useState([]);
  const [uncoveredPeriods, setUncoveredPeriods] = useState([]);
  const [loadingLoader, setLoadingLoader] = useState(false);
  const { user } = useContext(AuthContext);
  const { permissions } = user;
  useTableScroll();

  const [vignettesViewModal, setVignettesViewModal] = useState(false);
  const [periodsViewModal, setPeriodsViewModal] = useState(false);

  const handleOpenVignettesView = () => {
    setVignettesViewModal(true);
  };
  const handleOpenPeriodsView = () => {
    setPeriodsViewModal(true);
  };

  const columnsVignette = useMemo(
    () => [
      Column.text('vehicleRegistrationNumber', 'Număr de înmatriculare', {
        width: 250,
        sort: false,
      }),
      Column.text('vehicleVIN', 'Număr VIN', {
        width: 250,
        sort: false,
      }),
      Column.text(
        'trailRegistrationNumber',
        'Număr de înmatriculare (trailer)',
        {
          width: 250,
          sort: false,
        },
      ),
      Column.text('country', 'Țara', {
        width: 250,
        sort: false,
      }),
      Column.text('vehicleCategory', 'Categorie', {
        width: 250,
        sort: false,
      }),
      Column.text('vignetteNumber', 'Număr vinieta', {
        width: 250,
        sort: false,
      }),
      Column.text('status', 'Status', {
        width: 250,
        sort: false,
      }),
      Column.date('paidAt', 'Plătit la', {
        width: 250,
        sort: false,
      }),
      Column.date('validFrom', 'Valid din', {
        width: 250,
        sort: false,
      }),
      Column.date('expiresAt', 'Expirat la', {
        width: 250,
        sort: false,
      }),
      {
        title: 'Poziția tarifară',
        key: 'tariffPosition',
        width: 250,
        dataIndex: 'tariffPosition',
        render: (value) => {
          const code = tariffPositions?.content?.find(
            (el) => el.code === value,
          );
          return <>{code ? `(${code.code}) ${code.value}` : value}</>;
        },
      },
      Column.text('payerName', 'Plătit de', {
        width: 250,
        sort: false,
      }),
      Column.text('payerId', 'ID Plătitor', {
        width: 250,
        sort: false,
      }),
      Column.text('identityDocumentSeriesAndNumber', 'Număr de identitate', {
        width: 250,
        sort: false,
      }),
      Column.text('email', 'Email', {
        width: 250,
        sort: false,
      }),
    ],
    [tariffPositions?.content],
  );

  const columnsUncoveredPeriods = useMemo(
    () => [
      {
        title: 'Cod',
        key: 'errorCode',
        width: 250,
        dataIndex: 'errorCode',
        render: (value) => {
          const code = errorCodes?.content?.find((el) => el.code === value);
          return <>{code ? `(${code.code}) ${code.value}` : value}</>;
        },
      },
      {
        title: 'Perioade',
        key: 'periods',
        width: 130,
        dataIndex: 'periods',
        render: () => {
          return (
            <ViewItemIcon title="Vizualizeaza" action={handleOpenPeriodsView} />
          );
        },
      },
      {
        title: 'Viniete',
        key: 'vignettes',
        width: 130,
        dataIndex: 'vignettes',
        render: () => {
          return (
            <ViewItemIcon
              title="Vizualizeaza"
              action={handleOpenVignettesView}
            />
          );
        },
      },
    ],
    [errorCodes?.content],
  );

  const columnsPeriods = useMemo(
    () => [
      Column.date('from', 'Din', {
        width: 250,
        sort: false,
      }),
      Column.date('to', 'Pana', {
        width: 250,
        sort: false,
      }),
    ],
    [],
  );

  const vinAction = () => {
    setUncoveredPeriods([]);
    const vin = form.getFieldValue('vin');
    const date = form.getFieldValue('dateVin');
    if (date?.length === 2) {
      return getVignettesByVehicleVIN(
        vin,
        new Date(date[0]).toISOString(),
        new Date(date[1]).toISOString(),
      );
    }
    return getVignetteByVehicleVIN(vin, -1);
  };

  // TODO: enable when change to new vignette service
  // eslint-disable-next-line
  const handleSearchVignetteByVin = () => {
    const vin = form.getFieldValue('vin');
    if (!vin) {
      notification.error({ message: 'Introduceți numarul VIN' });
      return;
    }
    setLoadingLoader(true);
    vinAction()
      .then((res) => {
        const data = Array.isArray(res) ? res : [res];
        setVignettes([...data]);
      })
      .catch(() =>
        notification.error({
          message: 'A apărut o eroare la căutarea vinietei',
        }),
      )
      .finally(() => setLoadingLoader(false));
  };

  const regNumberAction = () => {
    setUncoveredPeriods([]);
    const regNumber = form.getFieldValue('regNumber');
    const date = form.getFieldValue('dateReg');
    if (date?.length === 2) {
      return getVignettesByVehicleRegistrationNumber(
        regNumber,
        new Date(date[0]).toISOString(),
        new Date(date[1]).toISOString(),
      );
    }
    return getVignetteByVehicleRegistrationNumber(regNumber, -1);
  };

  const handleSearchVignetteByRegNumber = () => {
    const regNumber = form.getFieldValue('regNumber');
    if (!regNumber) {
      notification.error({ message: 'Introduceți numărul de înmatriculare' });
      return;
    }
    setLoadingLoader(true);
    regNumberAction()
      .then((res) => {
        const data = Array.isArray(res) ? res : [res];
        setVignettes([...data]);
      })
      .catch(() =>
        notification.error({
          message: 'A apărut o eroare la căutarea vinietei',
        }),
      )
      .finally(() => setLoadingLoader(false));
  };

  // TODO: enable when change to new vignette service
  // eslint-disable-next-line
  const handleSearchVignetteDataByVin = () => {
    setVignettes([]);
    const uncoveredVin = form.getFieldValue('uncoveredVin');
    if (!uncoveredVin) {
      notification.error({ message: 'Introduceți numărul VIN' });
      return;
    }
    setLoadingLoader(true);
    getUncoveredPeriodByVehicleVIN(uncoveredVin)
      .then((res) => {
        const data = Array.isArray(res) ? res : [res];
        setUncoveredPeriods([...data]);
      })
      .catch(() =>
        notification.error({
          message: 'A apărut o eroare la căutarea datelor',
        }),
      )
      .finally(() => setLoadingLoader(false));
  };

  // TODO: enable when change to new vignette service
  // eslint-disable-next-line
  const handleSearchVignetteDataByRegNumber = () => {
    setVignettes([]);
    const uncoveredRegNumber = form.getFieldValue('uncoveredRegNumber');
    if (!uncoveredRegNumber) {
      notification.error({ message: 'Introduceți numărul VIN' });
      return;
    }
    setLoadingLoader(true);
    getUncoveredPeriodByVehicleRegistrationNumber(uncoveredRegNumber)
      .then((res) => {
        const data = Array.isArray(res) ? res : [res];
        setUncoveredPeriods([...data]);
      })
      .catch(() =>
        notification.error({
          message: 'A apărut o eroare la căutarea datelor',
        }),
      )
      .finally(() => setLoadingLoader(false));
  };

  return (
    <>
      <div className={`loaderOverlay ${loadingLoader ? 'active' : ''}`}>
        <AppLoader />
      </div>

      <FormContextProvider form={form}>
        <Form form={form} layout="vertical">
          {hasPermission(permissions, 'ASD_WS') && (
            <>
              {/* TODO: NEW-VIGNETTE-SERVICE-BY-VIN
              <Divider plain>Căutare Viniete in baza VIN</Divider>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
                <Col xs={24} sm={24} md={10} lg={8} xl={8}>
                  <Form.Item
                    label="Număr VIN"
                    name="vin"
                    rules={[
                      {
                        min: 17,
                        message: 'Minim 17 caractere',
                      },
                    ]}
                  >
                    <Input
                      maxLength={17}
                      onChange={(e) => {
                        form.setFieldsValue({
                          vin: vinNumberValidator(e.target.value),
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Alege perioada pentru cautarea mai multor viniete"
                    name="dateVin"
                    style={{ marginLeft: '20px' }}
                  >
                    <DatePicker.RangePicker
                      placeholder={['Din data', 'Pâna la data']}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item>
                <Space>
                  <Button
                    type="primary"
                    onClick={handleSearchVignetteByVin}
                    icon={<SearchOutlined />}
                  >
                    Căutare
                  </Button>
                </Space>
              </Form.Item>  */}
            </>
          )}
          {hasPermission(permissions, 'ASD_WS') && (
            <>
              <Divider plain>
                Căutare Viniete in baza numărului de înmatriculare
              </Divider>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
                <Col xs={24} sm={24} md={10} lg={8} xl={8}>
                  <Form.Item label="Număr de înmatriculare" name="regNumber">
                    <Input />
                  </Form.Item>
                </Col>
                {/* TODO: NEW-VIGNETTE-SERVICE-BY-PERIOD
                <Col span={6}>
                  <Form.Item
                    label="Alege perioada pentru cautarea mai multor viniete"
                    name="dateReg"
                    style={{ marginLeft: '20px' }}
                  >
                    <DatePicker.RangePicker
                      placeholder={['Din data', 'Pâna la data']}
                    />
                  </Form.Item>
                </Col> */}
              </Row>
              <Form.Item>
                <Space>
                  <Button
                    type="primary"
                    onClick={handleSearchVignetteByRegNumber}
                    icon={<SearchOutlined />}
                  >
                    Căutare
                  </Button>
                </Space>
              </Form.Item>
            </>
          )}
          {hasPermission(permissions, 'ASD_WS') && (
            <>
              {/* TODO: NEW-VIGNETTE-SERVICE-BY-VIN
              <Divider plain>
                Căutarea perioadelor neacoperite de vinietă in baza VIN
              </Divider>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
                <Col xs={24} sm={24} md={10} lg={8} xl={8}>
                  <Form.Item
                    label="Număr VIN"
                    name="uncoveredVin"
                    rules={[
                      {
                        min: 17,
                        message: 'Minim 17 caractere',
                      },
                    ]}
                  >
                    <Input
                      maxLength={17}
                      onChange={(e) => {
                        form.setFieldsValue({
                          uncoveredVin: vinNumberValidator(e.target.value),
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item>
                <Space>
                  <Button
                    type="primary"
                    onClick={handleSearchVignetteDataByVin}
                    icon={<SearchOutlined />}
                  >
                    Căutare
                  </Button>
                </Space>
              </Form.Item> */}
            </>
          )}
          {hasPermission(permissions, 'ASD_WS') && (
            <>
              {/* TODO: NEW-VIGNETTE-SERVICE-BY-PERIOD
              <Divider plain>
                Căutarea perioadelor neacoperite de vinietă in baza numărului de
                înmatriculare
              </Divider>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
                <Col xs={24} sm={24} md={10} lg={8} xl={8}>
                  <Form.Item
                    label="Număr de înmatriculare"
                    name="uncoveredRegNumber"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Space>
                  <Button
                    type="primary"
                    onClick={handleSearchVignetteDataByRegNumber}
                    icon={<SearchOutlined />}
                  >
                    Căutare
                  </Button>
                </Space>
              </Form.Item> */}
            </>
          )}
          {vignettes && vignettes.length > 0 ? (
            <>
              <Divider plain>Rezultat căutare vinieta</Divider>
              <Table
                columns={columnsVignette}
                dataSource={vignettes}
                size="small"
              />
            </>
          ) : null}
          {uncoveredPeriods && uncoveredPeriods.length > 0 ? (
            <>
              <Divider plain>Rezultat căutare perioade neacoperite</Divider>
              <Table
                columns={columnsUncoveredPeriods}
                dataSource={uncoveredPeriods}
                pagination={false}
                size="small"
              />
            </>
          ) : null}
        </Form>
      </FormContextProvider>
      {uncoveredPeriods && uncoveredPeriods.length > 0 ? (
        <>
          <Modal
            maskClosable={false}
            width={1000}
            visible={vignettesViewModal}
            footer={null}
            onCancel={() => setVignettesViewModal(false)}
          >
            <PageHeader title="Viniete" />
            <Table
              columns={columnsVignette}
              dataSource={uncoveredPeriods[0]?.vignettes}
              pagination={false}
              scroll={{ y: 580 }}
            />
          </Modal>
          <Modal
            maskClosable={false}
            width={1000}
            visible={periodsViewModal}
            footer={null}
            onCancel={() => setPeriodsViewModal(false)}
          >
            <PageHeader title="Perioade" />
            <Table
              columns={columnsPeriods}
              dataSource={uncoveredPeriods[0]?.periods}
              pagination={false}
              scroll={{ y: 580 }}
            />
          </Modal>
        </>
      ) : null}
    </>
  );
};

export default VignetteForm;
