import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import MappingSvmPostToRoPostsList from '../../../../components/admin/taxonomies/mappingSvmPostToRoPost/MappingSvmPostToRoPostsList';

const MappingSvmPostToRoPosts = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="mappingSvmPostToRoPost">
      <Tabs.TabPane
        tab={t('entity.admin.mappingSvmPostToRoPost._plural')}
        key="mappingSvmPostToRoPost"
      >
        <MappingSvmPostToRoPostsList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default MappingSvmPostToRoPosts;
