import React from 'react';

import { Tabs } from 'antd';
import TransshipmentsList from '../../../components/admin/warehouse/transshipments/TransshipmentsList';

const Transshipments = () => {
  return (
    <Tabs defaultActiveKey="transshipments">
      <Tabs.TabPane tab="Transbordari" key="transshipmentsList">
        <TransshipmentsList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default Transshipments;
