import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { notification, Table, Tooltip, Button, Popconfirm, Col } from 'antd';
import { DownloadOutlined, DeleteOutlined } from '@ant-design/icons';
import { FileIcon, defaultStyles } from 'react-file-icon';
import dayjs from 'dayjs';
import FileUploader from './FileUploader';
import { getFiles, removeFile, downloadFiles } from '../services/admin/files';
import Column from '../helpers/Columns';
import AppLoader from './auth/AppLoader';

export const fileSize = (size) => {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return `${(size / 1024 ** i).toFixed(2)} ${['B', 'KB', 'MB', 'GB', 'TB'][i]}`;
};

const getExtension = (name) => name.substr(name.lastIndexOf('.') + 1);

const FilesList = ({
  identifiers = [],
  onFilesChange,
  disabled = false,
  disableDownload = false,
  transactionID,
}) => {
  const [files, setFiles] = useState([]);
  const { t } = useTranslation();
  const [loadingLoader, setLoadingLoader] = useState(false);

  useEffect(() => {
    if (Array.isArray(identifiers) && identifiers.length > 0) {
      const [cached, missing] = identifiers.reduce(
        (acc, ident) => {
          const file = Array.isArray(files)
            ? files.find((f) => f.id === ident)
            : null;
          acc[file ? 0 : 1].push(file || ident);
          return acc;
        },
        [[], []],
      );
      (missing.length > 0 ? getFiles(missing) : Promise.resolve([]))
        .then()
        .then((res) => {
          setFiles([...cached, ...(Array.isArray(res) ? res : [res])]);
          // returnedFiles([...cached, ...(Array.isArray(res) ? res : [res])]);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identifiers]);

  const handleDelete = (record) => {
    setLoadingLoader(true);
    removeFile(record.id)
      .then(() => {
        const filesFilt = files.filter((f) => f.id !== record.id);
        onFilesChange(filesFilt);
        setFiles(filesFilt);
      })
      .catch(() => {
        notification.error({ message: 'Fișierul nu a putut fi șters' });
      })
      .finally(() => setLoadingLoader(false));
  };

  const handleUploadComplete = (uploadedFiles) =>
    onFilesChange([...files, ...uploadedFiles]);

  const downloadFile = (row) => {
    downloadFiles(row.id).catch(() =>
      notification.error({ message: 'Fișierul nu a putut fi descărcat' }),
    );
  };

  const customDateSorter = (a, b) => {
    const dateA = new Date(a.uploadDate);
    const dateB = new Date(b.uploadDate);

    return dateA - dateB;
  };
  const columns = [
    {
      sort: false,
      title: 'Tip document',
      key: 'ext',
      width: 180,
      dataIndex: 'ext',
      render: (code, row) => {
        return (
          <div
            style={{
              width: '30px',
              height: '30px',
              margin: 'auto',
            }}
          >
            <FileIcon
              extension={getExtension(row.name)}
              {...defaultStyles[getExtension(row.name)]}
            />
          </div>
        );
      },
    },
    Column.text('name', 'Denumire', {
      sort: false,
      width: 250,
    }),
    {
      title: 'Data adăugare',
      key: 'uploadDate',
      width: 200,
      sorter: customDateSorter,
      render: (row) => {
        return (
          <>
            {row.uploadDate
              ? dayjs(row.uploadDate).format('DD.MM.YYYY HH:mm:ss')
              : ''}
          </>
        );
      },
    },
    {
      sort: false,
      title: 'Mărime document	',
      key: 'size',
      width: 190,
      dataIndex: 'size',
      render: (code, row) => {
        return <span>{fileSize(row.size)}</span>;
      },
    },
    Column.actions('Acțiune', (record) => (
      <>
        <Tooltip title="Descarcă document">
          <Button
            shape="circle"
            type="link"
            icon={<DownloadOutlined />}
            onClick={() => downloadFile(record)}
            disabled={disableDownload}
          />
        </Tooltip>
        <Tooltip title="Șterge document">
          <Popconfirm
            placement="topLeft"
            title="Sunteți sigur(ă) că doriți sa ștergeți documentul?"
            disabled={disabled}
            onConfirm={() => handleDelete(record)}
            okText={t('actions.delete')}
            cancelText={t('actions.cancel')}
          >
            <Button
              shape="circle"
              type="link"
              icon={<DeleteOutlined />}
              disabled={disabled}
            />
          </Popconfirm>
        </Tooltip>
      </>
    )),
  ];

  return (
    <div>
      <div className={`loaderOverlay ${loadingLoader ? 'active' : ''}`}>
        <AppLoader />
      </div>

      <Col
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
        span={24}
      >
        <span style={{ fontWeight: 600, fontSize: '20px', lineHeight: '32px' }}>
          Adaugă documente
        </span>
        <FileUploader
          disabled={disabled}
          onUploadComplete={handleUploadComplete}
          transactionID={transactionID}
        />
      </Col>
      <Col
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
        span={24}
      >
        <Table
          columns={columns}
          dataSource={files}
          pagination={false}
          rowKey="id"
        />
      </Col>
    </div>
  );
};

export default FilesList;
