import React, { useMemo } from 'react';
import { Table } from 'antd';
import { useTableScroll } from '../hooks/useTableScroll';

const TableFormItemWrapper = ({ value, onChange, ...props }) => {
  useTableScroll();
  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: value || props.values || [],
      onChange: (selectedRowKeys) => onChange(selectedRowKeys),
      getCheckboxProps: () => ({
        disabled: props.disabled && props.disabled === true,
      }),
    }),
    [value, onChange, props.disabled, props.values],
  );

  return (
    <Table
      {...props}
      rowSelection={{
        type: 'checkbox',
        ...rowSelection,
      }}
    />
  );
};

export default TableFormItemWrapper;
