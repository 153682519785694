import React from 'react';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Row,
} from 'antd';
import { manuallyPaymentConfirmation } from '../../../services/admin/manually-payment-confirmation';

const ManuallyPaymentConfirmationModal = ({
  visible,
  onFinish = () => {},
  onClose = () => {},
  transactionId,
  ...props
}) => {
  const [form] = Form.useForm();

  const handleManualConfirmationSubmit = (values) => {
    manuallyPaymentConfirmation(transactionId, values)
      .then(() => {
        onFinish();
        form.resetFields();
        notification.success({
          message: 'Confirmarea plății a fost efectuată cu succes.',
          duration: 3,
        });
      })
      .catch((err) => {
        if (err)
          notification.error({
            message: 'A apărut o eroare la confirmarea plății',
          });
      });
  };

  return (
    <Modal
      maskClosable={false}
      centered
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Înapoi
        </Button>,
        <Button key="submit" type="primary" onClick={() => form.submit()}>
          Confirmă
        </Button>,
      ]}
      {...props}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleManualConfirmationSubmit}
      >
        <Form.Item label="Confirmare manuală a bonului de plată">
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="paidDate"
                key="paidDate"
                label="Data plății"
                rules={[
                  { required: true, message: 'Data plății este obligatorie' },
                ]}
              >
                <DatePicker
                  showTime={{ format: 'HH:mm' }}
                  placeholder="Selectați data și ora"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="mpayReceiptNumber"
                key="mpayReceiptNumber"
                label="Număr notă de plată"
                rules={[
                  {
                    required: true,
                    message: 'Numărul notei de plată este obligatoriu',
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Introduceți numărul notei de plată"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ManuallyPaymentConfirmationModal;
