import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@ant-design/pro-layout';
import { Table, Tooltip, Button, notification } from 'antd';
import { FileDoneOutlined } from '@ant-design/icons';
import useDatasource from '../../../hooks/useDatasource';

import {
  changeReportActiveStatus,
  findAll,
  openPdfTemplateNewTab,
  remove,
} from '../../../services/admin/reports/jasper-reports';

import Column from '../../../helpers/Columns';

import DeleteItemIcon from '../../buttons/DeleteItemIcon';
import GeneratePdfReportIcon from '../../buttons/GeneratePdfReportIcon';
import UploadButton from '../../buttons/UploadButton';
import { useTableScroll } from '../../../hooks/useTableScroll';
import AppLoader from '../../auth/AppLoader';

const JasperReportsList = () => {
  const { t } = useTranslation();
  const [loadingLoader, setLoadingLoader] = useState(false);
  useTableScroll();
  const { loading, pagination, content, handleChange, reload } =
    useDatasource(findAll);

  const columns = useMemo(
    () => [
      Column.text('id', t('entity.admin.reports.id'), {
        width: 30,
      }),
      Column.text('code', t('entity.admin.reports.code'), {
        width: 80,
        filter: true,
      }),
      Column.text('name', t('entity.admin.reports.name'), {
        width: 100,
        filter: true,
      }),
      Column.date('uploadedAt', t('entity.admin.reports.uploadedAt'), {
        width: 80,
        format: 'DD-MM-YYYY HH:mm:ss',
      }),
      Column.text('uploadedBy', t('entity.admin.reports.uploadedBy'), {
        width: 80,
      }),
      Column.date('updatedAt', t('entity.admin.reports.updatedAt'), {
        width: 80,
        format: 'DD-MM-YYYY HH:mm:ss',
      }),
      Column.text('updatedBy', t('entity.admin.reports.updatedBy'), {
        width: 80,
      }),
      Column.text('version', t('entity.admin.reports.version'), {
        width: 80,
      }),
      Column.bool('isActive', t('entity.admin.reports.isActive'), {
        width: 100,
        filter: true,
        // inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity._.true'),
          t('entity._.false'),
          t('entity._.deleted.unknown'),
        ],
      }),
      Column.actions(t('table.actions'), (record) => (
        <span style={{ textAlign: 'right' }}>
          <GeneratePdfReportIcon
            fileName={`${record.name}.pdf`}
            path={`/pdf-export/template/${record.id}`}
            method={openPdfTemplateNewTab}
            hintText={t('actions.exportPdfReport')}
          />
          <Tooltip title="Modificati statusul activ/inactiv">
            <Button
              shape="circle"
              type="link"
              icon={<FileDoneOutlined />}
              onClick={() => {
                setLoadingLoader(true);
                changeReportActiveStatus(record.id, !record.isActive)
                  .then(() =>
                    notification.success({
                      message: 'Raport actualizat cu success',
                      duration: 3,
                    }),
                  )
                  .catch(() =>
                    notification.error({
                      message: 'Eroare la actualizarea datelor',
                    }),
                  )
                  .finally(() => {
                    reload();
                    setLoadingLoader(false);
                  });
              }}
            />
          </Tooltip>
          <DeleteItemIcon
            title={t('entity.admin.reports._delete', record)}
            message={t('entity.admin.reports._deleted', record)}
            item={record}
            action={remove}
            reload={reload}
          />
        </span>
      )),
    ],
    [t, reload],
  );

  return (
    <>
      <div className={`loaderOverlay ${loadingLoader ? 'active' : ''}`}>
        <AppLoader />
      </div>
      <PageHeader
        title={t('entity.admin.reports._plural')}
        extra={[
          <UploadButton
            key="addEntity"
            buttonCaption={t('actions.add')}
            buttonType="primary"
            path="/backend/api/v1/report-files/"
            methodType="post"
            reload={reload}
            setLoadingLoader={setLoadingLoader}
          />,
        ]}
      />
      <Table
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
      />
    </>
  );
};

export default JasperReportsList;
