import React, { useCallback, useMemo, useState, useContext } from 'react';

import {
  Button,
  Table,
  notification,
  DatePicker,
  Form,
  Modal,
  Input,
  Select,
  Col,
  Row,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { findAll as findAllCustomsDocumentTypes } from '../../../services/taxonomies/customs-document-type';
import { findAll as findAllCustomsPosts } from '../../../services/admin/users/customs-posts';
import CustomsPostSelect from '../../CustomsPostSelect';
import Column from '../../../helpers/Columns';
import DeleteItemIcon from '../../buttons/DeleteItemIcon';
import EditItemIcon from '../../buttons/EditItemIcon';
import useDictionaries from '../../../hooks/useDictionaries';
import {
  addDeclaration,
  editDeclaration,
  removeDeclaration,
} from '../../../services/transactions/mduaDeclarations';
import { FormContextProvider } from '../../../hooks/useFormContext';
import RequiredLabel from '../../../services/utils/RequiredLabel';
import AppLoader from '../../auth/AppLoader';
import AuthContext from '../../auth';

const dictionaries = {
  customsDocumentTypes: findAllCustomsDocumentTypes,
  customsPosts: findAllCustomsPosts,
};

const CustomsDeclarationsTable = ({
  transactionId,
  dataSource,
  disabled,
  reload,
  ...rest
}) => {
  const content = useMemo(
    () =>
      dataSource.sort((a, b) =>
        a?.declarationNumber?.localeCompare(b?.declarationNumber),
      ),
    [dataSource],
  );

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { user } = useContext(AuthContext);

  const [{ customsDocumentTypes, customsPosts }] =
    useDictionaries(dictionaries);

  const [isModalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCahngeDocumentType = (value) => {
    if (value === 'T1') {
      form.setFieldsValue({ customsPost: user?.postId });
    }
  };

  const handleOpenModal = useCallback(
    (record = null) => {
      if (record) {
        const data = {
          ...record,
          declarationDate: dayjs(record.declarationDate),
          customsPost: record.postId,
        };

        form.setFieldsValue(data);
      }
      setModalVisible(true);
    },
    [form, setModalVisible],
  );

  const handleCloseModal = useCallback(() => {
    setModalVisible(false);
    form.resetFields();
  }, [form, setModalVisible]);

  const action = (data) => {
    if (data.id) {
      return editDeclaration(data);
    }
    return addDeclaration(data);
  };

  const handleAddDeclaration = useCallback(() => {
    const documentType = form.getFieldValue('documentType');
    const declarationNumber = form.getFieldValue('declarationNumber');
    const declarationDate = form.getFieldValue('declarationDate');
    const postId = form.getFieldValue('customsPost');

    if (!documentType || !declarationNumber || !declarationDate) {
      notification.error({ message: 'Completați toate câmpurile' });
      return;
    }

    setLoading(true);
    const data = form.getFieldsValue();
    const dataToSubmit = {
      ...data,
      postId,
      transactionId,
      declarationDate: new Date(data.declarationDate).toISOString(),
    };

    action(dataToSubmit)
      .then(() => {
        notification.success({
          message: 'Declarația a fost adăugată cu succes',
        });
        handleCloseModal();
      })
      .catch(() => {
        notification.error({
          message: 'A apărut o eroare la adăugarea declarației',
        });
      })
      .finally(() => {
        setLoading(false);
        reload();
      });
  }, [form, reload, transactionId, handleCloseModal]);

  const customDateSorter = (a, b) => {
    const dateA = new Date(a.declarationDate);
    const dateB = new Date(b.declarationDate);

    return dateA - dateB;
  };

  const columns = useMemo(
    () => [
      {
        title: t('entity.admin.user.customsPost'),
        key: 'customsPost',
        sort: true,
        filter: true,
        width: 200,
        centered: false,
        render: (code, row) => {
          const customPost = customsPosts?.content?.find(
            (el) => el.id === row?.postId,
          );

          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <div>
                {customPost
                  ? `(${customPost?.newCode || ''}) ${customPost?.name || ''}`
                  : null}
              </div>
            </div>
          );
        },
      },
      Column.text('documentType', 'Document vamal', {
        width: 100,
        sort: false,
      }),
      {
        title: 'Nr. declarație',
        key: 'declarationNumber',
        width: 100,
        sorter: (a, b) =>
          a.declarationNumber?.localeCompare(b.declarationNumber),
        render: (row) => {
          return <>{row.declarationNumber}</>;
        },
      },
      {
        title: 'Data de emitere',
        key: 'declarationDate',
        width: 100,
        sorter: customDateSorter,
        render: (row) => {
          return (
            <>
              {row.declarationDate
                ? dayjs(row.declarationDate).format('DD.MM.YYYY')
                : ''}
            </>
          );
        },
      },
      Column.actions(t('table.actions'), (record) => (
        <span style={{ textAlign: 'right' }}>
          <EditItemIcon
            disabled={disabled}
            action={() => handleOpenModal(record)}
          />
          <DeleteItemIcon
            title="Șterge declarația ?"
            message="Declarația a fost ștearsă cu succes"
            item={{ transactionId, declarationId: record.id }}
            action={removeDeclaration}
            setLoadingLoader={setLoading}
            disabled={disabled}
            reload={reload}
          />
        </span>
      )),
    ],
    [
      t,
      reload,
      transactionId,
      handleOpenModal,
      disabled,
      customsPosts?.content,
    ],
  );

  return (
    <>
      <div className={`loaderOverlay ${loading ? 'active' : ''}`}>
        <AppLoader />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Button
          key="declarationButton"
          icon={<PlusOutlined />}
          type="primary"
          disabled={disabled}
          onClick={handleOpenModal}
        >
          Adăugă document vamal
        </Button>
      </div>
      <Table
        columns={columns}
        loading={loading}
        dataSource={content}
        pagination={false}
        scroll={{ y: 200 }}
        {...rest}
      />

      <FormContextProvider form={form}>
        <Form form={form}>
          <Form.Item name="id" noStyle />
          <Modal
            maskClosable={false}
            width={500}
            centered
            title="Declarație vamală"
            visible={isModalVisible}
            onOk={handleAddDeclaration}
            onCancel={handleCloseModal}
            okText="Salvează"
            cancelText="Înapoi"
          >
            <Row gutter={15}>
              <Col span={12}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  label={<RequiredLabel title="Document vamal" />}
                  name="documentType"
                >
                  <Select
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) !== -1
                    }
                    onChange={handleCahngeDocumentType}
                    disabled={disabled}
                  >
                    {customsDocumentTypes?.content?.map((option) => (
                      <Select.Option key={option.id} value={option.name}>
                        {`${option.name}`}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  label="Cod post vamal"
                  name="customsPost"
                  className="custom__document-code"
                >
                  <CustomsPostSelect />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col span={12}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  label={<RequiredLabel title="Nr. documentului" />}
                  name="declarationNumber"
                  className="custom__document-number"
                >
                  <Input
                    disabled={disabled}
                    style={{ textTransform: 'uppercase' }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  label={<RequiredLabel title="Data de emitere" />}
                  name="declarationDate"
                  className="custom__document-date"
                >
                  <DatePicker disabled={disabled} format="DD.MM.YYYY" />
                </Form.Item>{' '}
              </Col>
            </Row>
          </Modal>
        </Form>
      </FormContextProvider>
    </>
  );
};

export default CustomsDeclarationsTable;
