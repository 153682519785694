import React, { useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';

import useDatasource from '../../../hooks/useDatasource';
import { findAll, remove } from '../../../services/admin/days-correction';
import Column from '../../../helpers/Columns';
import EditItemIcon from '../../buttons/EditItemIcon';
import DeleteItemIcon from '../../buttons/DeleteItemIcon';
import ViewItemIcon from '../../buttons/ViewItemIcon';
import AuthContext, { hasPermission } from '../../auth';
import { useTableScroll } from '../../../hooks/useTableScroll';

const DaysCorrectionsList = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { permissions } = user;

  const { loading, pagination, content, handleChange, reload } =
    useDatasource(findAll);

  useTableScroll();
  const columns = useMemo(
    () => [
      Column.text('id', 'ID', {
        width: 50,
      }),
      Column.text('letterNumber', t('entity.daysCorrection.letterNumber'), {
        width: 100,
        filter: true,
      }),
      Column.text('regNumber', t('entity.daysCorrection.regNumber'), {
        width: 50,
        filter: true,
      }),
      Column.text('days', t('entity.daysCorrection.days'), {
        width: 50,
      }),
      Column.date('dateCalculated', t('entity.daysCorrection.dateCalculated'), {
        width: 100,
        format: 'DD-MM-YYYY',
      }),
      Column.text('createdBy', t('entity.daysCorrection.createdBy'), {
        width: 100,
        filter: true,
      }),
      Column.date('createdAt', t('entity.daysCorrection.createdAt'), {
        width: 100,
        format: 'DD-MM-YYYY',
      }),
      Column.bool('deleted', t('entity._.deleted._'), {
        width: 100,
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity._.deleted.true'),
          t('entity._.deleted.false'),
          t('entity._.deleted.unknown'),
        ],
      }),
      Column.actions(t('table.actions'), (record) =>
        record.deleted ? (
          <span style={{ textAlign: 'right' }}>
            {hasPermission(permissions, 'CORRECT_DAYS_VIEW') && (
              <ViewItemIcon path={`/correct-days/${record.id}`} />
            )}
          </span>
        ) : (
          <span style={{ textAlign: 'right' }}>
            {hasPermission(permissions, 'CORRECT_DAYS_EDIT') && (
              <EditItemIcon path={`/correct-days/${record.id}`} />
            )}
            {hasPermission(permissions, 'CORRECT_DAYS_EDIT') && (
              <DeleteItemIcon
                title={t('entity.daysCorrection._delete', record)}
                message={t('entity.daysCorrection._deleted', record)}
                item={record}
                action={remove}
                reload={reload}
              />
            )}
          </span>
        ),
      ),
    ],
    [t, reload, permissions],
  );

  return (
    <>
      <Table
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
      />
    </>
  );
};

export default DaysCorrectionsList;
