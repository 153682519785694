import { genericService, get } from '../../../helpers/api';

export const SERVICE_URI = '/mconnect-sfs-debts';
export const BASE_URI = '/api/v1';
export const BASE_URI_SEARCH_DEBTS = '/search/debts';

export const { findAll } = genericService(SERVICE_URI, BASE_URI_SEARCH_DEBTS);

export const getOrganizationByIdno = (idno) =>
  get(`${SERVICE_URI}${BASE_URI}/organization/${idno}`);

export const getDebtsByIdno = (idno) =>
  get(`${SERVICE_URI}${BASE_URI}/debts/${idno}`);
