import { useCallback, useEffect, useRef, useState } from 'react';

function useScrollPosition({
  ref,
  onScroll,
  onTopReach,
  onBottomReach,
  stay = true,
  options = {
    minOffset: 120,
  },
} = {}) {
  const [pageRef, setPageRef] = useState(
    ref && ref.current ? ref.current : window,
  );
  const [scrollHeight, setScrollHeight] = useState(0);
  const [atTop, setAtTop] = useState(false);
  const [atBottom, setAtBottom] = useState(false);
  const prevScrollHeight = useRef(0);

  useEffect(() => {
    setPageRef(ref && ref.current ? ref.current : window);
  }, [ref]);

  useEffect(() => {
    if (stay) {
      const offset = pageRef.scrollHeight - prevScrollHeight.current;
      if (offset > 0 && offset > options.minOffset) {
        pageRef.scrollTo({
          top: offset,
          behavior: 'auto',
        });
      } else {
        pageRef.scrollTo({
          top: pageRef.scrollHeight,
          behavior: 'auto',
        });
      }
      prevScrollHeight.current = pageRef.scrollHeight;
    }
    // eslint-disable-next-line
  }, [pageRef.scrollHeight, stay]);

  const handleScroll = useCallback(() => {
    const currentPosition =
      pageRef.scrollTop || document.documentElement.scrollTop;
    setScrollHeight(currentPosition);
    if (onScroll && typeof onScroll === 'function') {
      onScroll(currentPosition);
    }

    if (currentPosition === 0) {
      setAtTop(true);
      if (onTopReach && typeof onTopReach === 'function') {
        onTopReach();
      }
    } else {
      setAtTop(false);
    }

    const isBottom =
      currentPosition >= pageRef.scrollHeight - pageRef.clientHeight;
    if (isBottom) {
      setAtBottom(true);
      if (onBottomReach && typeof onBottomReach === 'function') {
        onBottomReach();
      }
    } else {
      setAtBottom(false);
    }
    // eslint-disable-next-line
  }, [pageRef]);

  useEffect(() => {
    pageRef.addEventListener('scroll', handleScroll);

    return () => {
      pageRef.removeEventListener('scroll', handleScroll);
    };
  }, [pageRef, handleScroll]);

  const scrollToTop = () => {
    pageRef.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  };

  const scrollToBottom = () => {
    pageRef.scrollTo({
      top: pageRef.scrollHeight,
      behavior: 'auto',
    });
  };

  return { scrollHeight, atTop, atBottom, scrollToTop, scrollToBottom };
}

export default useScrollPosition;
