import React, { useEffect, useCallback, useState, useContext } from 'react';
import {
  Form,
  Row,
  Col,
  Input,
  Space,
  Button,
  Divider,
  Typography,
  Select,
  Modal,
  notification,
  DatePicker,
  Radio,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import {
  SearchOutlined,
  RightCircleOutlined,
  EyeOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { subDays } from 'date-fns';
import { FormContextProvider } from '../../hooks/useFormContext';
import useFormErrors from '../../hooks/useFormErrors';
import {
  getDetailsByPlateNumber,
  getDetailsByDeclarationNumber,
  getDetailsByDeclarationIds,
  getDetailsByTIRNumber,
} from '../../services/admin/web-services/mdua';
import {
  addMdUaComment,
  confirmStep,
  enableSteps,
} from '../../services/transactions/transactions';
import { saveDeclarationNr } from '../../services/admin/web-services/backend';
import AuthContext, { hasPermission } from '../auth';
import AppLoader from '../auth/AppLoader';
import CancelButton from '../buttons/BackButton';
import { maxLengthValidator } from '../../services/utils/validator/Validator';
import CustomsDeclarationsTable from './physical-person-components/CustomsDeclarationsTable';
import { addMdUa } from '../../services/admin/web-services/mdua-transactions';
import Icon from '../Icon';
import NextButton from '../buttons/NextButton';
import RequiredLabel from '../../services/utils/RequiredLabel';
import TransactionStatus from '../../enums/TransactionStatus';

const { Text } = Typography;

const directions = {
  content: [
    { id: 'IN', code: 'IN', name: 'INTRARE' },
    { id: 'OUT', code: 'OUT', name: 'IEȘIRE' },
  ],
};

const PortalInfo = ({
  transaction = false,
  skipTab,
  readOnly,
  reload,
  moveNext = () => {},
  moveBack = () => {},
}) => {
  const [transactionGoods, setTransactionGoods] = useState([]);
  const [statusValue, setStatusValue] = useState(0);
  const [declarations, setDeclarations] = useState([]);
  const [loadingLoader, setLoadingLoader] = useState(false);
  const [disabledDeclaration, setDisabledDeclaration] = useState(true);
  const [commentModalVisible, setCommentModalVisible] = useState(false);
  const [disabledPlateNrSearchBtn, setDisabledPlateNrSearchBtn] = useState(
    !transaction?.vehicleNumber,
  );
  const [direction, setDirection] = useState(transaction?.direction);

  const [disabledTIRSearchBtn, setDisabledTIRSearchBtn] = useState(true);
  const [form] = Form.useForm();
  const { user } = useContext(AuthContext);
  const { permissions } = user;

  useFormErrors(form);

  const onChangeStatus = (e) => {
    setStatusValue(e.target.value);
  };

  useEffect(() => {
    if (transaction?.declarationNumberMdUa) {
      getDetailsByDeclarationIds(
        transaction.declarationNumberMdUa,
        direction,
      ).then((res) => {
        if (res && res.length > 0) {
          if (res[0].goods && res[0].goods.length > 0) {
            setDisabledPlateNrSearchBtn(true);
            form.setFieldsValue({
              ...res[0],
              ...res[0].goods[0],
              // plateNrSearchable: null,
            });
          } else {
            form.setFieldsValue({
              ...res[0],
              // plateNrSearchable: null,
            });
          }
          setTransactionGoods(res[0].goods);
          setDeclarations(
            res.map((el) => {
              return { id: el.docId, name: el.declaration, obj: el };
            }),
          );
        } else {
          // form.setFieldsValue({ plateNrSearchable: transaction.vehicleNumber });
        }
      });
    } else {
      // form.setFieldsValue({ plateNrSearchable: transaction.vehicleNumber });
    }
  }, [form, transaction?.declarationNumberMdUa, direction]);

  const handleSaveData = useCallback(() => {}, []);

  const resetForm = useCallback(() => {
    form.resetFields([
      'consignor',
      'consignee',
      'carrier',
      'officeOnBorder',
      'declaration',
      'packagesQuantity',
      'containerQuantity',
      'tirPageNo',
      'countryDispatch',
      'countryOrigin',
      'countryDestination',
      'totalAmountInvoiced',
      'exchangeRate',
      'goodCode',
      'goodNumber',
      'goodDescription',
      'grossMass',
      'netMass',
      'totalBrutto',
      'quantitySupUnit',
      'invoiceValue',
      'statValue',
    ]);
    form.setFieldsValue({
      transport: null,
    });
  }, [form]);

  const getDateMinusDays = (daysOffset) => {
    return subDays(new Date(), daysOffset).getTime();
  };

  const handleGetDetailsByPlateNumber = useCallback(() => {
    const searchFrom = form.getFieldValue('searchFrom')
      ? new Date(form.getFieldValue('searchFrom')).getTime()
      : getDateMinusDays(30);
    const searchTo = form.getFieldValue('searchTo')
      ? new Date(form.getFieldValue('searchTo')).getTime()
      : new Date().getTime();

    setLoadingLoader(true);
    resetForm();

    getDetailsByPlateNumber(
      form.getFieldValue('plateNrSearchable'),
      direction,
      searchFrom,
      searchTo,
    )
      .then((res) => {
        if (res && res.length > 0) {
          if (transaction?.id) {
            saveDeclarationNr(
              transaction.id,
              res?.map((r) => {
                return { id: r.docId, type: r.docType };
              }),
            );
          }
          if (res[0].goods && res[0].goods.length > 0) {
            form.setFieldsValue({
              ...res[0],
              ...res[0].goods[0],
            });
          } else {
            form.setFieldsValue({
              ...res[0],
            });
          }
          setTransactionGoods(res[0].goods);
          setDeclarations(
            res.map((el) => {
              return { id: el.docId, name: el.declaration, obj: el };
            }),
          );
        } else {
          notification.warning({
            message: 'Nu a fost gasită nici o declarație',
            duration: 3,
          });
        }
      })
      .catch(() => {
        notification.error({ message: 'Nu au putut fi extrase detaliile' });
      })
      .finally(() => {
        setLoadingLoader(false);
        reload();
      });
  }, [transaction?.id, direction, form, resetForm, reload]);

  const handleGetDetailsByTIRNumber = useCallback(() => {
    const searchFrom = form.getFieldValue('searchFrom')
      ? new Date(form.getFieldValue('searchFrom')).getTime()
      : getDateMinusDays(30);
    const searchTo = form.getFieldValue('searchTo')
      ? new Date(form.getFieldValue('searchTo')).getTime()
      : new Date().getTime();

    setLoadingLoader(true);
    resetForm();
    getDetailsByTIRNumber(
      form.getFieldValue('tirSearchable'),
      direction,
      searchFrom,
      searchTo,
    )
      .then((res) => {
        if (res && res.length > 0) {
          if (transaction?.id) {
            saveDeclarationNr(
              transaction.id,
              res?.map((r) => {
                return { id: r.docId, type: r.docType };
              }),
            );
          }
          if (res[0].goods && res[0].goods.length > 0) {
            form.setFieldsValue({
              ...res[0],
              ...res[0].goods[0],
            });
          } else {
            form.setFieldsValue({
              ...res[0],
            });
          }
          setTransactionGoods(res[0].goods);
          setDeclarations(
            res.map((el) => {
              return { id: el.docId, name: el.declaration, obj: el };
            }),
          );
        } else {
          notification.warning({
            message: 'Nu a fost gasită nici o declarație',
            duration: 3,
          });
        }
      })
      .catch(() => {
        notification.error({ message: 'Nu au putut fi extrase detaliile' });
      })
      .finally(() => {
        setLoadingLoader(false);
        reload();
      });
  }, [transaction?.id, direction, form, resetForm, reload]);

  const handleGetDetailsByDeclarationNumber = useCallback(() => {
    setLoadingLoader(true);
    resetForm();
    getDetailsByDeclarationNumber(
      form.getFieldValue('declarationNumber1'),
      form.getFieldValue('declarationNumber2'),
      form.getFieldValue('declarationNumber3'),
      direction,
    )
      .then((res) => {
        if (res.docId) {
          if (transaction?.id) {
            saveDeclarationNr(transaction.id, [
              { id: res.docId, type: res.docType },
            ]);
          }
          if (res.goods && res.goods.length > 0) {
            form.setFieldsValue({
              ...res,
              ...res.goods[0],
            });
          } else {
            form.setFieldsValue({
              ...res,
            });
          }
          setTransactionGoods(res.goods);
          setDeclarations([{ id: res.docId, name: res.declaration, obj: res }]);
        } else {
          notification.warning({
            message: 'Nu a fost gasită nici o declarație',
            duration: 3,
          });
        }
      })
      .catch(() => {
        notification.error({ message: 'Nu au putut fi extrase detaliile' });
      })
      .finally(() => {
        setLoadingLoader(false);
        reload();
      });
  }, [transaction?.id, direction, form, resetForm, reload]);

  const handleChangeGoodCode = useCallback(
    (value) => {
      const good = transactionGoods?.find((el) => el.goodNumber === value);
      form.setFieldsValue(good);
    },
    [transactionGoods, form],
  );

  const handleChangeDeclarations = useCallback(
    (value) => {
      const decl = declarations?.find((el) => el.id === value)?.obj;
      if (decl) {
        if (decl?.goods && decl?.goods.length > 0) {
          form.setFieldsValue({
            ...decl,
            ...decl.goods[0],
          });
        } else {
          form.setFieldsValue({
            ...decl,
          });
        }
        setTransactionGoods(decl.goods);
      }
    },
    [form, declarations],
  );
  const saveDeclaration = useCallback(() => {
    if (!transaction?.id) {
      return;
    }
    const dataToSave = {
      transactionId: transaction.id,
      verificationResult: form.getFieldValue('status'),
    };

    if (
      form?.getFieldValue('status') === null ||
      form?.getFieldValue('status') === undefined
    ) {
      notification.error({ message: 'Indicați rezultatul controlului' });
      return;
    }
    setLoadingLoader(true);

    addMdUa(dataToSave)
      .then(() => moveNext())
      .catch(() =>
        notification.error({ message: 'Datele nu au putut fi confirmate' }),
      )
      .finally(() => {
        reload();
        setLoadingLoader(false);
      });
  }, [form, reload, transaction?.id, moveNext]);

  // eslint-disable-next-line
  const infirmDeclaration = useCallback(() => {
    setLoadingLoader(true);
    confirmStep(transaction.id, 'HAS_MD_UA')
      .then(() => {
        notification.success({
          message: 'Datele au fost infirmate',
          duration: 3,
        });
        skipTab('#HAS_MD_UA');
      })
      .catch(() => {
        notification.error({ message: 'Datele nu au putut fi infirmate' });
      })
      .finally(() => {
        setLoadingLoader(false);
        reload();
      });
  }, [transaction?.id, skipTab, reload]);

  const handleOpenCommentModal = () => {
    setCommentModalVisible(true);
    form.setFieldsValue({
      comment: transaction?.mduaDeclarationComment,
    });
  };

  const handleCloseCommentModal = () => {
    setCommentModalVisible(false);
    form.setFieldsValue({
      comment: transaction?.mduaDeclarationComment,
    });
  };

  const handleSaveComment = useCallback(
    () => {
      const data = {
        transactionId: transaction?.id,
        message: form.getFieldValue('comment'),
      };

      if (!transaction?.mduaDeclarationComment && !data.message) {
        notification.error({ message: 'Adăugați textul comentariului.' });
        return;
      }

      setLoadingLoader(true);
      addMdUaComment(data)
        .then((res) => {
          if (res) {
            notification.success({
              message: 'Comentariul a fost adăugat cu succes',
              duration: 3,
            });
            handleCloseCommentModal();
          } else {
            notification.error({
              message: 'Comentariul nu a fost adăugat',
              duration: 3,
            });
          }
        })
        .catch((err) => {
          if (err) {
            notification.error({
              message: 'A apărut o eroare la adăugarea comentariului',
            });
          }
        })
        .finally(() => {
          setLoadingLoader(false);
          reload();
        });
    },

    // eslint-disable-next-line
    [transaction?.id, reload, form],
  );

  const handleEnableStep = useCallback(() => {
    setLoadingLoader(true);
    enableSteps(transaction.id, 'HAS_MD_UA')
      .catch(() => {
        notification.error({
          message: 'A apărut o eroare. Contactați administratorul',
        });
      })
      .finally(() => {
        setLoadingLoader(false);
        reload();
      });
  }, [transaction?.id, reload]);

  return (
    <>
      <div className={`loaderOverlay ${loadingLoader ? 'active' : ''}`}>
        <AppLoader />
      </div>
      {transaction?.status === TransactionStatus.PENDING ? (
        <Button
          disabled={readOnly}
          onClick={handleEnableStep}
          type="primary"
          danger={transaction.hasMdUa}
        >
          {!transaction.hasMdUa ? 'Inițiere control' : 'Anulare control'}
        </Button>
      ) : null}
      {transaction ? (
        <Button
          type="primary"
          style={{ marginLeft: '20px' }}
          onClick={handleOpenCommentModal}
          disabled={
            (readOnly || !transaction.hasMdUa) &&
            !transaction?.mduaDeclarationComment
          }
          icon={
            transaction?.mduaDeclarationComment ? (
              <EyeOutlined />
            ) : (
              <PlusOutlined />
            )
          }
        >
          {`${
            transaction?.mduaDeclarationComment
              ? 'Vizualizare comentariu'
              : 'Adaugă comentariu'
          } `}
        </Button>
      ) : null}
      <FormContextProvider form={form}>
        <Form form={form} layout="vertical" onFinish={handleSaveData}>
          <Divider plain>
            <Text strong>Căutare declarații</Text>
          </Divider>

          <Row gutter={15}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
              {transaction === false ||
              !transaction?.direction ||
              !direction ? (
                <Row>
                  <Col span={6}>
                    <Form.Item label={<RequiredLabel title="Direcție" />}>
                      <Select
                        style={{ width: '100%' }}
                        disabled={readOnly}
                        showSearch
                        allowClear
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) !== -1
                        }
                        onChange={(value) => {
                          setDirection(value);
                          if (
                            !form.getFieldValue('declarationNumber1') ||
                            !form.getFieldValue('declarationNumber2') ||
                            !form.getFieldValue('declarationNumber3') ||
                            !value
                          ) {
                            setDisabledDeclaration(true);
                          } else {
                            setDisabledDeclaration(false);
                          }
                        }}
                      >
                        {directions.content.map((option) => (
                          <Select.Option key={option.id} value={option.id}>
                            {`${option.name}`}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              ) : null}
              <Row gutter={15}>
                <Col span={6}>
                  <Form.Item label="Număr declarație" name="declarationNumber1">
                    <Input
                      onChange={() =>
                        !form.getFieldValue('declarationNumber1') ||
                        !form.getFieldValue('declarationNumber2') ||
                        !form.getFieldValue('declarationNumber3') ||
                        !direction
                          ? setDisabledDeclaration(true)
                          : setDisabledDeclaration(false)
                      }
                      disabled={
                        readOnly ||
                        (transaction !== false && !transaction.hasMdUa)
                      }
                      style={{
                        textTransform: 'uppercase',
                      }}
                    />
                  </Form.Item>
                </Col>
                <div style={{ marginTop: '2rem' }}>
                  <Icon name="devide" className="declaration__number-devider" />
                </div>
                <Col span={6}>
                  <Form.Item label=" " name="declarationNumber2">
                    <Input
                      onChange={() =>
                        !form.getFieldValue('declarationNumber1') ||
                        !form.getFieldValue('declarationNumber2') ||
                        !form.getFieldValue('declarationNumber3') ||
                        !direction
                          ? setDisabledDeclaration(true)
                          : setDisabledDeclaration(false)
                      }
                      disabled={
                        readOnly ||
                        (transaction !== false && !transaction.hasMdUa)
                      }
                      style={{
                        textTransform: 'uppercase',
                      }}
                    />
                  </Form.Item>
                </Col>
                <div style={{ marginTop: '2rem' }}>
                  <Icon name="devide" className="declaration__number-devider" />
                </div>

                <Col span={6}>
                  <Form.Item label=" " name="declarationNumber3">
                    <Input
                      onChange={() =>
                        !form.getFieldValue('declarationNumber1') ||
                        !form.getFieldValue('declarationNumber2') ||
                        !form.getFieldValue('declarationNumber3') ||
                        !direction
                          ? setDisabledDeclaration(true)
                          : setDisabledDeclaration(false)
                      }
                      disabled={
                        readOnly ||
                        (transaction !== false && !transaction.hasMdUa)
                      }
                      style={{
                        textTransform: 'uppercase',
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={2} style={{ paddingTop: 30 }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<SearchOutlined />}
                    onClick={() => handleGetDetailsByDeclarationNumber()}
                    disabled={
                      !hasPermission(permissions, 'SEARCH_MDUA') ||
                      disabledDeclaration ||
                      (transaction !== false && !transaction.hasMdUa)
                    }
                  >
                    Caută
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
              <Row gutter={15}>
                <Col span={8}>
                  <Form.Item label="Căutare din" name="searchFrom">
                    <DatePicker
                      showTime
                      disabled={
                        readOnly ||
                        (transaction !== false && !transaction.hasMdUa)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Nr. auto" name="plateNrSearchable">
                    <Input
                      maxLength={10}
                      disabled={
                        readOnly ||
                        (transaction !== false && !transaction.hasMdUa)
                      }
                      style={{
                        textTransform: 'uppercase',
                      }}
                      onChange={() =>
                        !form.getFieldValue('plateNrSearchable')
                          ? setDisabledPlateNrSearchBtn(true)
                          : setDisabledPlateNrSearchBtn(false)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label=" " name="btnSearch">
                    <Button
                      style={{ marginLeft: 15 }}
                      type="primary"
                      htmlType="submit"
                      icon={<SearchOutlined />}
                      disabled={
                        !hasPermission(permissions, 'SEARCH_MDUA') ||
                        disabledPlateNrSearchBtn ||
                        (transaction !== false && !transaction.hasMdUa)
                      }
                      onClick={() => handleGetDetailsByPlateNumber()}
                    >
                      Caută
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={15}>
                <Col span={8}>
                  <Form.Item label="Căutare până" name="searchTo">
                    <DatePicker
                      showTime
                      disabled={
                        readOnly ||
                        (transaction !== false && !transaction.hasMdUa)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Nr. TIR" name="tirSearchable">
                    <Input
                      disabled={
                        readOnly ||
                        (transaction !== false && !transaction.hasMdUa)
                      }
                      style={{
                        textTransform: 'uppercase',
                      }}
                      onChange={(e) => {
                        form.setFieldsValue({
                          tirSearchable: maxLengthValidator(e.target.value, 10),
                        });

                        if (!form.getFieldValue('tirSearchable')) {
                          setDisabledTIRSearchBtn(true);
                        } else {
                          setDisabledTIRSearchBtn(false);
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label=" " name="btnSearch3">
                    <Button
                      style={{ marginLeft: 15 }}
                      type="primary"
                      htmlType="submit"
                      icon={<SearchOutlined />}
                      disabled={
                        !hasPermission(permissions, 'SEARCH_MDUA') ||
                        disabledTIRSearchBtn ||
                        (transaction !== false && !transaction.hasMdUa)
                      }
                      onClick={() => handleGetDetailsByTIRNumber()}
                    >
                      Caută
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Divider plain>
            <Text strong>Detalii declarație vamală</Text>
          </Divider>
          <div className="mdua__declaration">
            <Row
              className="mdua__declaration-row"
              style={{
                gap: '10px',
                justifyContent: 'flex-start',
                flexWrap: 'nowrap',
              }}
            >
              <Row
                className="mdua__declaration-left"
                style={{
                  flexDirection: 'column',
                  gap: '10px',
                }}
              >
                <Form.Item
                  label="2. Exportator"
                  name="consignor"
                  className="mdua__declaration-item large uper"
                >
                  <TextArea
                    // disabled={ readOnly}
                    disabled
                    style={{
                      textTransform: 'uppercase',
                      height: '115px',
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="8. Importator"
                  name="consignee"
                  className="mdua__declaration-item large uper"
                >
                  <TextArea
                    disabled
                    // disabled={ readOnly}
                    style={{
                      textTransform: 'uppercase',
                      height: '115px',
                    }}
                  />
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 24 }}
                  style={{ flexDirection: 'column' }}
                  label="14. Declarant"
                  name="carrier"
                  className="mdua__declaration-item large uper"
                >
                  <TextArea
                    disabled
                    // disabled={ readOnly}
                    style={{
                      textTransform: 'uppercase',
                      height: '115px',
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="18.1 Transport la plecare"
                  name={['transport', 'departureIdentifier']}
                  className="mdua__declaration-item large"
                >
                  <Input
                    disabled
                    // disabled={ readOnly}
                    style={{ textTransform: 'uppercase' }}
                  />
                </Form.Item>
                <Form.Item
                  label="21.1 Transport la frontiera"
                  name={['transport', 'crossBorderIdentifier']}
                  className="mdua__declaration-item large"
                >
                  <Input
                    disabled
                    // disabled={ readOnly}
                    style={{ textTransform: 'uppercase' }}
                  />
                </Form.Item>
                <Form.Item
                  label="29. Vama"
                  name="officeOnBorder"
                  className="mdua__declaration-item large"
                >
                  <Input
                    disabled
                    // disabled={ readOnly}
                    style={{
                      textTransform: 'uppercase',
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="31. Mărci și numere. Colete."
                  name="brandsAndNumbers"
                  className="mdua__declaration-item large"
                >
                  <TextArea
                    disabled
                    // disabled={ readOnly}
                    style={{
                      textTransform: 'uppercase',
                      height: '115px',
                    }}
                  />
                </Form.Item>
              </Row>
              <Row
                style={{ flexDirection: 'column', gap: '10px', width: '100%' }}
              >
                <Row
                  style={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    gap: '10px',
                  }}
                >
                  <Form.Item
                    label="1. Tip decalarație"
                    name="declarationType"
                    className="mdua__declaration-item"
                    style={{ width: '100%' }}
                  >
                    <Input
                      // disabled={ readOnly}
                      disabled
                      style={{
                        textTransform: 'uppercase',
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    className="mdua__declaration-item"
                    label="A"
                    name="declaration"
                    style={{ width: '100%' }}
                  >
                    <Select
                      disabled={
                        transaction &&
                        (readOnly ||
                          !transaction.hasMdUa ||
                          transaction.isMdUaConfirmed)
                      }
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) !== -1
                      }
                      onChange={handleChangeDeclarations}
                    >
                      {declarations.map((option) => (
                        <Select.Option key={option.id} value={option.id}>
                          {`${option.name}`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Row>
                <Row
                  style={{
                    flexWrap: 'nowrap',
                    gap: '10px',
                    width: '100%',
                  }}
                >
                  <Row
                    style={{
                      flexWrap: 'nowrap',
                      width: '100%',
                      gap: '10px',
                    }}
                  >
                    <Form.Item
                      className="mdua__declaration-item"
                      label="5. Articole"
                      name="items"
                      style={{ width: '100%' }}
                    >
                      <Input
                        disabled
                        // disabled={ readOnly}
                        style={{
                          textTransform: 'uppercase',
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      className="mdua__declaration-item"
                      label="6. Colete"
                      name="totalPackages"
                      style={{ width: '100%' }}
                    >
                      <Input
                        disabled
                        // disabled={ readOnly}
                        style={{
                          textTransform: 'uppercase',
                        }}
                      />
                    </Form.Item>
                  </Row>
                  <Form.Item
                    className="mdua__declaration-item"
                    label="7. Nr. Declarație"
                    name="custCode"
                  >
                    <Input
                      disabled
                      // disabled={ readOnly}
                      style={{
                        textTransform: 'uppercase',
                      }}
                    />
                  </Form.Item>
                </Row>
                <Row
                  style={{ flexWrap: 'nowrap', gap: '10px' }}
                  className="mdua__declaration-row"
                >
                  <Form.Item
                    label="15. Țara livrarilor"
                    name="countryDispatchCode"
                    className="mdua__declaration-item"
                  >
                    <Input
                      disabled
                      // disabled={ readOnly}
                      style={{
                        textTransform: 'uppercase',
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    label="16. Țara destinație"
                    name="countryDestinationCode"
                    className="mdua__declaration-item"
                  >
                    <Input
                      disabled
                      // disabled={ readOnly}
                      style={{
                        textTransform: 'uppercase',
                      }}
                    />
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item
                    label="17. Țara de origine"
                    name="countryOriginCode"
                    className="mdua__declaration-item"
                  >
                    <Input
                      disabled
                      // disabled={ readOnly}
                      style={{
                        textTransform: 'uppercase',
                      }}
                    />
                  </Form.Item>
                </Row>
                <Row style={{ flexWrap: 'nowrap', gap: '10px', width: '100%' }}>
                  <Form.Item
                    label="Cod valuta"
                    name="currency"
                    className="mdua__declaration-item"
                  >
                    <Input
                      disabled
                      // disabled={ readOnly}
                      style={{
                        textTransform: 'uppercase',
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Valoarea totala (hrivne)"
                    name="totalAmountInvoiced"
                    className="mdua__declaration-item"
                  >
                    <Input
                      disabled
                      // disabled={ readOnly}
                      style={{
                        textTransform: 'uppercase',
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Valoarea totala (valuta)"
                    name="totalAmountInvoicedForeignCurrency"
                    className="mdua__declaration-item"
                  >
                    <Input
                      disabled
                      // disabled={ readOnly}
                      style={{
                        textTransform: 'uppercase',
                      }}
                    />
                  </Form.Item>
                </Row>
                <Row style={{ flexWrap: 'nowrap', gap: '10px', width: '100%' }}>
                  <Form.Item
                    label="32. Nr. bun"
                    name="goodNumber"
                    className="mdua__declaration-item"
                  >
                    <Input
                      disabled
                      // disabled={ readOnly}
                      style={{
                        textTransform: 'uppercase',
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="33. Codul MARFII"
                    name="goodCode"
                    className="mdua__declaration-item"
                  >
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) !== -1
                      }
                      onChange={handleChangeGoodCode}
                      disabled={
                        transaction && (readOnly || !transaction.hasMdUa)
                      }
                    >
                      {transactionGoods.map((option) => (
                        <Select.Option
                          key={option.goodNumber}
                          value={option.goodNumber}
                        >
                          {`${option.goodCode}`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className="mdua__declaration-item"
                    label="34. Tara de origine a bunurilor"
                    name="goodCountryOrigin"
                    style={{ width: '100%' }}
                  >
                    <Input
                      disabled
                      // disabled={ readOnly}
                      style={{
                        textTransform: 'uppercase',
                      }}
                    />
                  </Form.Item>
                </Row>

                <Row
                  style={{
                    gap: '10px',
                    width: '100%',
                    flexWrap: 'nowrap',
                  }}
                >
                  <Form.Item
                    className="mdua__declaration-item"
                    label="35. BRUTO"
                    name="grossMass"
                    style={{ width: '100%' }}
                  >
                    <Input
                      disabled
                      // disabled={ readOnly}
                      style={{
                        textTransform: 'uppercase',
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    className="mdua__declaration-item"
                    label="Total BRUTO"
                    name="totalBruto"
                    style={{ width: '100%' }}
                  >
                    <Input
                      disabled
                      // disabled={ readOnly}
                      style={{
                        textTransform: 'uppercase',
                      }}
                    />
                  </Form.Item>
                </Row>
                <Row
                  style={{
                    gap: '10px',
                    width: '100%',
                    flexWrap: 'nowrap',
                  }}
                >
                  <Form.Item
                    className="mdua__declaration-item"
                    label="38. NETTO"
                    name="netMass"
                    style={{ width: '100%' }}
                  >
                    <Input
                      disabled
                      // disabled={ readOnly}
                      style={{
                        textTransform: 'uppercase',
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    className="mdua__declaration-item"
                    label="Total NETTO"
                    name="totalNetto"
                    style={{ width: '100%' }}
                  >
                    <Input
                      disabled
                      // disabled={ readOnly}
                      style={{
                        textTransform: 'uppercase',
                      }}
                    />
                  </Form.Item>
                </Row>

                <Row
                  style={{
                    gap: '10px',
                    width: '100%',
                    flexWrap: 'nowrap',
                  }}
                >
                  <Form.Item
                    className="mdua__declaration-item"
                    label="41. UM suplim."
                    name="quantitySupUnit"
                    style={{ width: '100%' }}
                  >
                    <Input
                      disabled
                      // disabled={ readOnly}
                      style={{
                        textTransform: 'uppercase',
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    className="mdua__declaration-item"
                    label="42. Preț articol"
                    name="invoiceValue"
                    style={{ width: '100%' }}
                  >
                    <Input
                      disabled
                      // disabled={ readOnly}
                      style={{
                        textTransform: 'uppercase',
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    className="mdua__declaration-item"
                    label="46. Costul statistic"
                    name="statValue"
                    style={{ width: '100%' }}
                  >
                    <Input
                      disabled
                      // disabled={ readOnly}
                      style={{
                        textTransform: 'uppercase',
                      }}
                    />
                  </Form.Item>
                </Row>

                <Row>
                  <Form.Item
                    className="mdua__declaration-item"
                    label="44. Documente"
                    name="str44"
                    style={{ width: '100%' }}
                  >
                    <TextArea
                      disabled
                      // disabled={ readOnly}
                      style={{
                        textTransform: 'uppercase',
                        height: '115px',
                      }}
                    />
                  </Form.Item>
                </Row>
              </Row>
            </Row>
            {transaction ? (
              <Row className="mdua__bottom" gutter={20}>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                  <Divider plain>
                    <Text strong>Se închide cu</Text>
                  </Divider>
                  <CustomsDeclarationsTable
                    transactionId={transaction?.id}
                    dataSource={transaction?.mdUaDeclarations}
                    disabled={
                      readOnly ||
                      !transaction.hasMdUa ||
                      transaction?.isMdUaConfirmed
                    }
                    reload={reload}
                  />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                  <Divider plain>
                    <Text strong>
                      <RequiredLabel title="Rezultatul controlului" />
                    </Text>
                  </Divider>

                  <Row>
                    <Form.Item
                      name="status"
                      initialValue={transaction?.mduaVerificationResult}
                    >
                      <Radio.Group
                        onChange={onChangeStatus}
                        value={statusValue}
                        disabled={readOnly || !transaction.hasMdUa}
                      >
                        <Space
                          direction="vertical"
                          style={{ marginLeft: '20px' }}
                        >
                          <Radio value={0}>Conform</Radio>
                          <Radio value={1}>Neconform</Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </Row>
                </Col>
              </Row>
            ) : null}
          </div>

          {transaction ? (
            <Form.Item>
              <Space>
                {!readOnly && (
                  <>
                    <Col span={8} style={{ width: '100%' }}>
                      <CancelButton disabled={readOnly} onCancel={moveBack} />
                    </Col>

                    <Col style={{ maxWidth: '100%' }}>
                      {/* !hasPermission(permissions, 'ANTA_ADD') */}
                      {transaction?.hasMdUa ? (
                        <Button
                          type="primary"
                          icon={<RightCircleOutlined />}
                          onClick={saveDeclaration}
                        >
                          Înainte
                        </Button>
                      ) : (
                        <NextButton
                          navigateFunc={moveNext}
                          transactionId={transaction.id}
                          canNavigate
                        />
                      )}
                    </Col>
                  </>
                )}
              </Space>
            </Form.Item>
          ) : null}
          <Modal
            maskClosable={false}
            width={500}
            centered
            title="Comentariu"
            visible={commentModalVisible}
            onOk={handleSaveComment}
            onCancel={handleCloseCommentModal}
            okButtonProps={{
              disabled: readOnly || !transaction.hasMdUa,
            }}
            okText="Salvează"
            cancelText="Înapoi"
          >
            <Form.Item
              style={{ marginBottom: '25px' }}
              name="comment"
              initialValue={transaction?.mduaDeclarationComment}
            >
              <TextArea
                showCount
                maxLength={3000}
                disabled={readOnly}
                rows={5}
              />
            </Form.Item>
          </Modal>
        </Form>
      </FormContextProvider>
    </>
  );
};

export default PortalInfo;
