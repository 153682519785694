import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Table } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import Column from '../../../helpers/Columns';

const EditVehicleRecordsForm = ({ disabled }) => {
  const { t } = useTranslation();
  const tableFooter = (add) => {
    return (
      <Button
        style={{ marginLeft: 'auto', display: 'block' }}
        type="primary"
        onClick={() => add({})}
        icon={<PlusOutlined />}
      >
        Adaugă
      </Button>
    );
  };
  const columns = useMemo(
    () => [
      Column.other(
        'position',
        t('entity.admin.declaration.preciousProperties.position'),
        (code, row) => {
          return <>{row.fieldKey + 1}</>;
        },
        {
          width: 20,
        },
      ),
      {
        key: 'name',
        title: (
          <>
            <span style={{ color: 'red' }}>* </span>
            {t('entity.admin.publicSpecialServices.vehicle')}{' '}
          </>
        ),
        width: 50,
        render: (_value, _record, index) => (
          <Form.Item
            noStyle
            name={[index, 'name']}
            rules={[{ required: true, message: 'Câmpul este obligatoriu' }]}
          >
            <Input disabled={disabled} />
          </Form.Item>
        ),
      },

      {
        key: 'type',
        title: (
          <>
            <span style={{ color: 'red' }}>* </span>
            {t('entity.admin.publicSpecialServices.vehicleType')}{' '}
          </>
        ),
        width: 50,
        render: (_value, _record, index) => (
          <Form.Item
            noStyle
            name={[index, 'type']}
            rules={[{ required: true, message: 'Câmpul este obligatoriu' }]}
          >
            <Input disabled={disabled} />
          </Form.Item>
        ),
      },

      {
        key: 'model',
        title: (
          <>
            <span style={{ color: 'red' }}>* </span>
            {t('entity.admin.publicSpecialServices.vehicleModel')}{' '}
          </>
        ),
        width: 50,
        render: (_value, _record, index) => (
          <Form.Item
            noStyle
            name={[index, 'model']}
            rules={[{ required: true, message: 'Câmpul este obligatoriu' }]}
          >
            <Input disabled={disabled} />
          </Form.Item>
        ),
      },

      {
        key: 'number',
        title: (
          <>
            <span style={{ color: 'red' }}>* </span>
            {t('entity.admin.publicSpecialServices.vehicleNr')}{' '}
          </>
        ),
        width: 50,
        render: (_value, _record, index) => (
          <Form.Item
            noStyle
            name={[index, 'number']}
            rules={[{ required: true, message: 'Câmpul este obligatoriu' }]}
          >
            <Input disabled={disabled} />
          </Form.Item>
        ),
      },

      Column.actions(
        t('table.actions'),
        (record, index) =>
          !disabled && (
            <Button
              disabled
              shape="circle"
              type="link"
              icon={<DeleteOutlined />}
              onClick={() => record.$remove(index)}
            />
          ),
      ),
    ],
    [disabled, t],
  );

  return (
    <Form.List name="vehicles">
      {(fields, { add, remove }) => (
        <>
          <Table
            size="small"
            rowKey="position"
            columns={columns}
            dataSource={fields}
            pagination={false}
            disabled={disabled}
            footer={() => !disabled && tableFooter(add)}
            onRow={(record) => {
              // eslint-disable-next-line no-param-reassign
              record.$remove = remove;
            }}
          />
        </>
      )}
    </Form.List>
  );
};

export default EditVehicleRecordsForm;
