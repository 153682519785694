import React, { useState, useEffect } from 'react';

import { useEditPage } from '../../../hooks/useEditPage';

import {
  findOne,
  createForTransaction,
  update,
} from '../../../services/admin/declaration';
import EditDeclarationForm from '../../../components/admin/declaration/EditDeclarationForm';
import AppLoader from '../../../components/auth/AppLoader';

import { findOne as findTransaction } from '../../../services/transactions/transactions';
import { useRouteParams } from '../../../hooks/useRouteParams';
import useDictionaries from '../../../hooks/useDictionaries';
import { findAll as findAllVehicleBrands } from '../../../services/taxonomies/vehicle-brands';

function similarity(a, b) {
  if (a.length === 0) return b.length;
  if (b.length === 0) return a.length;

  const matrix = [];

  for (let i = 0; i <= b.length; i += 1) {
    matrix[i] = [i];
  }

  for (let j = 0; j <= a.length; j += 1) {
    matrix[0][j] = j;
  }
  for (let i = 1; i <= b.length; i += 1) {
    for (let j = 1; j <= a.length; j += 1) {
      if (b.charAt(i - 1) === a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1,
          Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1),
        );
      }
    }
  }

  return matrix[b.length][a.length];
}

const dictionaries = {
  vehicleBrands: findAllVehicleBrands,
};
const initial = {
  havingPreciousProperty: false,
  havingWeapon: false,
  havingDrugs: false,
  havingArt: false,
  havingInformationCarriers: false,
  havingLiving: false,
  havingRadioactiveSubstances: false,
  havingPoisons: false,
  havingProducts: false,
  havingPersonalProducts: false,
  havingComercialProducts: false,
  accompaniedBag: false,
  unaccompaniedBag: false,
};

const EditTransactionDeclaration = () => {
  const [{ vehicleBrands }] = useDictionaries(dictionaries);
  const { transactionId } = useRouteParams('transactionId');
  const [initialDeclaration, setInitialDeclaration] = useState({ ...initial });
  useEffect(() => {
    findTransaction(transactionId)
      .then((res) => {
        let brand;
        if (res.mark) {
          const brandsCopy = vehicleBrands.content;
          brandsCopy.sort(
            (a, b) =>
              similarity(res.mark, a.name) - similarity(res.mark, b.name),
          );
          [brand] = brandsCopy;
        }
        setInitialDeclaration({
          ...initial,
          idnp: res.idnp,
          direction: res.direction,
          fullname: res?.personList?.find((it) => it.isPhysicalPerson).fullName,
          postCode: res.postCode,
          bodyNumber: res.vinCode,
          transportModel: res.model,
          colour: res.colour,
          motorNumber: res.vehicles.find((it) => it.vinCode === res.vinCode)
            .engineNumber,
          transportBrand: brand.id,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }, [transactionId, vehicleBrands]);
  const [post, handleSubmit, handleCancel, errors, loading] = useEditPage({
    initial: initialDeclaration,
    existent: findOne,
    pushOnEntityPage: true,
    goBackPath: `/physical-person-entry/${transactionId}#DECLARATIONS`,
    onCreate: createForTransaction(transactionId),
    onUpdate: update,
  });

  return (
    <>
      <div className={`loaderOverlay ${loading ? 'active' : ''}`}>
        <AppLoader />
      </div>
      {post !== undefined && (
        <EditDeclarationForm
          post={post}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          errors={errors}
        />
      )}
    </>
  );
};

export default EditTransactionDeclaration;
