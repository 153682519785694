import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

import useDatasource from '../../../hooks/useDatasource';

import { findAll } from '../../../services/admin/users/permissions';

import Column from '../../../helpers/Columns';

import ViewItemIcon from '../../buttons/ViewItemIcon';
import { useTableScroll } from '../../../hooks/useTableScroll';

const PermissionsList = () => {
  const { t } = useTranslation();
  useTableScroll();
  const { loading, pagination, content, handleChange } = useDatasource(findAll);

  const columns = useMemo(
    () => [
      Column.text('id', t('entity.admin.permission.id'), {
        width: 50,
      }),
      Column.text('name', t('entity.admin.permission.name'), {
        width: 275,
        filter: true,
      }),
      Column.text('description', t('entity.admin.permission.description'), {
        width: 275,
        filter: true,
      }),

      Column.actions(t('table.actions'), (record) => (
        <span style={{ textAlign: 'right' }}>
          <ViewItemIcon path={`/admin/users/permissions/${record.id}`} />
        </span>
      )),
    ],
    [t],
  );

  return (
    <>
      <PageHeader title={t('entity.admin.permission._plural')} />
      <Table
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        sticky={{ offsetHeader: 63 }}
      />
    </>
  );
};

export default PermissionsList;
