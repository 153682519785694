import React from 'react';

import SFSOganizatiiDebtsList from './SFSOganizatiiDebtsList';

const SFSOrganizatiiDebts = () => {
  return (
    <>
      <SFSOganizatiiDebtsList />
    </>
  );
};

export default SFSOrganizatiiDebts;
