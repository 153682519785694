import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, InputNumber, Table } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import Column from '../../../helpers/Columns';

const EditPreciousPropertyRecordsForm = ({ disabled }) => {
  const { t } = useTranslation();
  const tableFooter = (add) => {
    return (
      <Button
        style={{ marginLeft: 'auto', display: 'block' }}
        type="primary"
        onClick={() => add({})}
        icon={<PlusOutlined />}
      >
        Adaugă
      </Button>
    );
  };

  const columns = useMemo(
    () => [
      Column.other(
        'position',
        t('entity.admin.declaration.preciousProperties.position'),
        (code, row) => {
          return <>{row.fieldKey + 1}</>;
        },
        {
          width: 50,
        },
      ),
      Column.other(
        'name',
        t('entity.admin.declaration.preciousProperties.name'),
        (code, row) => {
          return (
            <Form.Item noStyle name={[row.fieldKey, 'name']}>
              <Input
                disabled={disabled}
                style={{
                  textTransform: 'uppercase',
                  fontWeight: '600',
                }}
              />
            </Form.Item>
          );
        },
        {
          width: 50,
        },
      ),
      {
        key: 'quantity',
        title: t('entity.admin.declaration.preciousProperties.quantity'),
        width: 50,
        render: (_value, _record, index) => (
          <Form.Item noStyle name={[index, 'quantity']}>
            <InputNumber min={0.0} precision={2} disabled={disabled} />
          </Form.Item>
        ),
      },
      Column.actions(
        t('table.actions'),
        (record, index) =>
          !disabled && (
            <Button
              disabled
              shape="circle"
              type="link"
              icon={<DeleteOutlined />}
              onClick={() => record.$remove(index)}
            />
          ),
      ),
    ],
    [t, disabled],
  );

  return (
    <Form.List name="preciousProperties">
      {(fields, { add, remove }) => (
        <>
          <Table
            size="small"
            rowKey="position"
            columns={columns}
            dataSource={fields}
            pagination={false}
            footer={() => !disabled && tableFooter(add)}
            onRow={(record) => {
              // eslint-disable-next-line no-param-reassign
              record.$remove = remove;
            }}
          />
        </>
      )}
    </Form.List>
  );
};

export default EditPreciousPropertyRecordsForm;
