import React, { useCallback, useMemo, useState } from 'react';
import { Button, Col, DatePicker, Empty, Form, Input, Row, Spin } from 'antd';
import { subDays } from 'date-fns';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import { SearchOutlined } from '@ant-design/icons';
import { getCrossing, getTimeline } from '../../services/transactions/vehicles';
import CancelButton from '../../components/buttons/BackButton';
import EntranceExitTimeline from '../../components/lucrative/crossing-history/EntranceExitTimeline';
import CrossingsTimeline from '../../components/lucrative/crossing-history/CrossingsTimeline';

const dateFormat = 'YYYY-MM-DD HH:mm:ss';

const EmptyTimeline = ({ show }) => {
  return show ? (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={false}
        style={{ marginBottom: '10px' }}
      />
      <h3 style={{ fontWeight: '600' }}>
        În această perioadă nu a fost găsită nicio traversare
      </h3>
    </div>
  ) : null;
};

const Loading = ({ timeLoader }) => {
  return (
    <div
      className={`time__loader ${timeLoader ? 'active' : ''}`}
      style={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Spin size="large" style={{ marginTop: '50px' }} />
    </div>
  );
};

const CrossingHistory = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  const [timelines, setTimelines] = useState([]);
  const [crossings, setCrossings] = useState([]);
  const [timeLoader, setTimeLoader] = useState(false);

  const getDateMinusDays = (daysOffset) => {
    return subDays(new Date(), daysOffset);
  };

  const initial = useMemo(
    () => ({
      dateFrom: dayjs(getDateMinusDays(30)),
      dateTo: dayjs(),
    }),
    [],
  );

  const handleGetHistory = useCallback(() => {
    setTimeLoader(true);
    setTimelines([]);
    setCrossings([]);
    let params = form.getFieldsValue();
    params = {
      ...params,
      fromDate: params.dateFrom.toDate().getTime(),
      toDate: params.dateTo.toDate().getTime(),
    };

    const { dateFrom, dateTo, ...data } = params;

    getTimeline(data)
      .then((response) => {
        setTimelines(response);
      })
      .finally(() => setTimeLoader(false));

    getCrossing(data).then((response) => {
      setCrossings(response);
    });
  }, [form]);

  return (
    <>
      <PageHeader title="Istoric traversări" />
      <Form form={form} layout="vertical" initialValues={initial}>
        <Row gutter={15}>
          <Col span={8}>
            <Form.Item name="personalCode" label="Nr. Pasaport Șofer">
              <Input />
            </Form.Item>
            <Form.Item name="idno" label="IDNO Transportator">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="vinCode" label="VIN">
              <Input />
            </Form.Item>
            <Form.Item name="plateNumber" label="Număr de înmatriculare">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="dateFrom" label="Căutare din">
              <DatePicker showTime format={dateFormat} />
            </Form.Item>
            <Form.Item name="dateTo" label="Căutare până">
              <DatePicker showTime format={dateFormat} />
            </Form.Item>
          </Col>
          <Col>
            <CancelButton onCancel={() => history.goBack()} />
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={handleGetHistory}
              icon={<SearchOutlined />}
            >
              Caută
            </Button>
          </Col>
        </Row>
        <Loading timeLoader={timeLoader} />
        {timelines?.length === 0 && crossings?.length === 0 ? (
          <EmptyTimeline show={!timeLoader} />
        ) : (
          <>
            <Row gutter={15}>
              <Col span={crossings?.length === 0 ? 24 : 12}>
                <EntranceExitTimeline
                  timelines={timelines}
                  show={timelines?.length !== 0}
                />
              </Col>
              <Col span={timelines?.length === 0 ? 24 : 12}>
                <CrossingsTimeline
                  crossings={crossings}
                  show={crossings?.length !== 0}
                />
              </Col>
            </Row>
          </>
        )}
      </Form>
    </>
  );
};

export default CrossingHistory;
