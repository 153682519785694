import { downloadFile, genericService, get, put } from '../../helpers/api';

export const SERVICE_URI = '/backend';
export const BASE_URI = '/api/v1/payment-notice';

export const { findAll, findOne, create, update, remove } = genericService(
  SERVICE_URI,
  BASE_URI,
);

export const changeStatus = (id, status) =>
  put(`${SERVICE_URI}${BASE_URI}/${status}/${id}`);

export const generatePayment = (id) =>
  put(`${SERVICE_URI}${BASE_URI}/to-pay/${id}`);

export const checkPaymentStatus = (id) =>
  get(`${SERVICE_URI}${BASE_URI}/check-payment/${id}`);

export const checkConfirmStatus = (id) =>
  get(`${SERVICE_URI}${BASE_URI}/check-confirm/${id}`);

export const markAsPaid = (id, request) =>
  put(`${SERVICE_URI}${BASE_URI}/mark-paid/${id}`, request);

export async function exportFile(from, to, type) {
  const toDate = new Date(to);
  toDate.setDate(toDate.getDate() + 1);
  const fromDate = new Date(from);
  fromDate.setDate(fromDate.getDate() + 1);
  const toDateFormatted = toDate.toISOString().split('T')[0];
  const fromFormatted = fromDate.toISOString().split('T')[0];

  const filename =
    type === 'csv'
      ? `Avize_de_plata_[${fromFormatted}]_[${toDateFormatted}].csv`
      : `Avize_de_plata_[${fromFormatted}]_[${toDateFormatted}].xlsx`;

  const endpoint = `${SERVICE_URI}${BASE_URI}/data-export/${from}/${to}/${type}`;

  downloadFile(endpoint)
    .then((res) => res.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.download = filename;
      a.href = url;
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
}
export async function exportReportFile(from, to, post, office) {
  const toDate = new Date(to);
  toDate.setDate(toDate.getDate() + 1);
  const fromDate = new Date(from);
  fromDate.setDate(fromDate.getDate() + 1);
  const toDateFormatted = toDate.toISOString().split('T')[0];
  const fromFormatted = fromDate.toISOString().split('T')[0];

  const filename = `Raport_avize_[${fromFormatted}]_[${toDateFormatted}].xlsx`;

  let endpoint = `${SERVICE_URI}${BASE_URI}/data-export/${from}/${to}`;

  const queryParams = [];
  if (post) queryParams.push(`post=${post}`);
  if (office) queryParams.push(`office=${office}`);
  if (queryParams.length) endpoint += `?${queryParams.join('&')}`;

  downloadFile(endpoint)
    .then((res) => res.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.download = filename;
      a.href = url;
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
}
export const findPaymentNoticesByDateAndSpecialServices = (
  dateFrom,
  dateTo,
  specialServices,
) =>
  get(
    `${SERVICE_URI}/${BASE_URI}/find-by-date-customPaymentIds/${dateFrom}/${dateTo}/${specialServices.join(
      ',',
    )}`,
  );
