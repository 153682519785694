import React from 'react';
import './EmptyMpass.scss';

const EmptyMpass = (PageComponent) => (props) => {
  return (
    <>
      <header className="empty-header">
        <div className="empty-header__logo" />
      </header>

      <PageComponent {...props} />
    </>
  );
};

export default EmptyMpass;
