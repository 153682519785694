import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, notification, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const DeleteItemIcon = ({
  title,
  message,
  item,
  action,
  reload,
  setLoadingLoader = null,
  disabled = false,
  isPromise = true,
}) => {
  const { t } = useTranslation();

  const handleDelete = useCallback(() => {
    if (setLoadingLoader !== null && typeof setLoadingLoader === 'function') {
      setLoadingLoader(true);
    }
    if (!isPromise) {
      action();
      return;
    }
    action(item)
      .then(() => message && notification.success({ message, duration: 3 }))
      .finally(() => {
        reload();
        if (
          setLoadingLoader !== null &&
          typeof setLoadingLoader === 'function'
        ) {
          setLoadingLoader(false);
        }
      });
  }, [isPromise, action, item, message, reload, setLoadingLoader]);

  return (
    <Popconfirm
      placement="topLeft"
      title={title}
      disabled={disabled}
      onConfirm={handleDelete}
      okText={t('actions.delete')}
      cancelText={t('actions.cancel')}
    >
      <Tooltip title={t('actions.delete')}>
        <Button
          shape="circle"
          type="link"
          icon={<DeleteOutlined />}
          disabled={disabled}
        />
      </Tooltip>
    </Popconfirm>
  );
};

export default DeleteItemIcon;
