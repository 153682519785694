import React from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Tooltip, Upload, notification } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';

const UploadButton = ({
  isTooltip = false,
  tooltipTitle,
  buttonCaption,
  buttonType,
  path,
  methodType,
  reload,
  setLoadingLoader,
}) => {
  const { t } = useTranslation();

  function methodForUploadingFiles(httpMethodType, pathForUpload, file) {
    const formData = new FormData();
    formData.append('file', file);
    setLoadingLoader(true);
    const config = {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    if (httpMethodType.toLowerCase() === 'post') {
      setLoadingLoader(false);
      return axios.post(
        `${window._env_.API_BACKEND_URL}${pathForUpload}`,
        formData,
        config,
      );
    }
    if (httpMethodType.toLowerCase() === 'put') {
      setLoadingLoader(false);
      return axios.put(
        `${window._env_.API_BACKEND_URL}${pathForUpload}`,
        formData,
        config,
      );
    }
    return () => {};
  }

  const uploadReport = async (options) => {
    const { file } = options;
    setLoadingLoader(true);
    methodForUploadingFiles(methodType, path, file)
      .then(() => {
        notification.success({
          message: t('messages.file.uploaded'),
          duration: 3,
        });
      })
      .catch((err) => {
        const errorNotification =
          err?.response?.data?.message || t('message.file.not.uploaded');
        notification.error({ message: errorNotification });
      })
      .finally(() => {
        reload();
        setLoadingLoader(false);
      });
  };

  const props = {
    name: 'file',
    multiple: false,
    withCredentials: true,
    customRequest: uploadReport,
  };

  return isTooltip ? (
    <Tooltip title={tooltipTitle}>
      <Upload {...props} showUploadList={false}>
        <Button
          shape="circle"
          type={buttonType || 'link'}
          icon={<UploadOutlined />}
        >
          {buttonCaption}
        </Button>
      </Upload>
    </Tooltip>
  ) : (
    <Upload {...props} showUploadList={false}>
      <Button type={buttonType} icon={<UploadOutlined />}>
        {buttonCaption}
      </Button>
    </Upload>
  );
};

export default UploadButton;
