import React, { useMemo, useState } from 'react';
import { Divider, notification, Table } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { useTranslation } from 'react-i18next';
import FileUploader from '../../components/FileUploader';
import AppLoader from '../../components/auth/AppLoader';
import Column from '../../helpers/Columns';
import { useTableScroll } from '../../hooks/useTableScroll';
import {
  addFromFile,
  findAll,
} from '../../services/transactions/import-transactions';
import useDatasource from '../../hooks/useDatasource';
import { directionsContent } from '../../enums/Directions';

const ImportTransactions = () => {
  const [uploading, setUploading] = useState(false);

  const { t } = useTranslation();

  useTableScroll();

  const { loading, pagination, content, handleChange, reload } =
    useDatasource(findAll);

  const columns = useMemo(
    () => [
      Column.bool('isDuplicated', 'Duplicat', {
        width: 100,
        filter: false,
        labels: [
          t('table.filter.all'),
          t('entity._.true'),
          t('entity._.false'),
          t('entity._.deleted.unknown'),
        ],
      }),
      Column.text('fullName', 'Nume Prenume', { width: 100, filter: true }),
      Column.text('birthDate', 'Data naștere', { width: 100, filter: false }),
      Column.text('nationality', 'Cetățenia', { width: 100, filter: false }),
      Column.text('passportNumber', 'Număr pașaport', {
        width: 100,
        filter: false,
      }),
      Column.bool('isDriver', 'Șofer', {
        width: 100,
        filter: false,
        labels: [
          t('table.filter.all'),
          t('entity._.true'),
          t('entity._.false'),
          t('entity._.deleted.unknown'),
        ],
      }),
      Column.dictionary(
        'direction',
        t('entity.admin.customsTrack.direction'),
        directionsContent,
        {
          width: 100,
          filter: true,
        },
      ),
      Column.text('postName', 'Punct de trecere frontieră', {
        width: 100,
        filter: true,
      }),
      Column.date('crossingDate', 'Data', {
        width: 100,
        filter: true,
        format: 'DD/MM/YYYY HH:mm:ss',
      }),
      Column.text('plateNumber', 'Număr auto', { width: 100, filter: false }),
      Column.text('vehicleMake', 'Marcă', { width: 100, filter: false }),
      Column.text('vehicleModel', 'Model', { width: 100, filter: false }),
      Column.text('vehicleYear', 'An fabricație', {
        width: 100,
        filter: false,
      }),
      Column.text('vinCode', 'Număr VIN', { width: 100, filter: false }),
      Column.text('vehicleColor', 'Culoare auto', {
        width: 100,
        filter: false,
      }),
      Column.text('vehicleOrigin', 'Originea', { width: 100, filter: false }),
      Column.text('trailerNumber', 'Număr remorcă', {
        width: 100,
        filter: false,
      }),
      Column.text('trailerMake', 'Marcă remorcă', {
        width: 100,
        filter: false,
      }),
      Column.text('trailerYear', 'An fabricație remorcă', {
        width: 100,
        filter: false,
      }),
      Column.text('trailerVin', 'Număr VIN remorcă', {
        width: 100,
        filter: false,
      }),
    ],
    [t],
  );

  const handleUploadFile = (files) => {
    const [file] = files;
    if (file) {
      setUploading(true);
      addFromFile(file.id)
        .then(() => reload())
        .catch((err) => {
          const { inner } = err || {};
          const { _: messageCode } = inner || {};
          notification.error({
            message: messageCode || 'A apărut o eroare la importul rutelor',
          });
        })
        .finally(() => setUploading(false));
    }
  };

  return (
    <>
      <div className={`loaderOverlay ${uploading ? 'active' : ''}`}>
        <AppLoader />
      </div>
      <PageHeader
        title="Importă rute"
        extra={[
          <FileUploader
            key="file-uploader"
            multiple={false}
            accept=".xls, .xlsx, .csv"
            icon={<UploadOutlined />}
            onUploadComplete={handleUploadFile}
            title="Importă rute"
          />,
        ]}
      />

      {/* <div> */}
      {/*  TODO: verify ? */}
      {/*  Nu se recomanda csv din din cauza ca separatorul e `,` -&gt; daca suntem */}
      {/*  siguri ca nu este string care contine `,` csv nu e problema */}
      {/* </div> */}

      <Divider>Rute neconcordante</Divider>

      <PageHeader subTitle={`Marimea listei: ${pagination?.total || 0}`} />
      <Table
        columns={columns}
        dataSource={content}
        onChange={handleChange}
        pagination={pagination}
        loading={loading}
        rowKey="id"
      />
    </>
  );
};

export default ImportTransactions;
