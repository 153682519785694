import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Spin } from 'antd';
import ChatMessages from './ChatMessages';
import ChatFooter from './ChatFooter';
import Icon from '../../Icon';
import { stringLengthValidator } from '../../../services/utils/validator/Validator';
import ActivityStatus from '../enums/ActivityStatus';

const ChatWindow = ({
  loading,
  targetEntity,
  messages,
  isMenuVisible,
  rightSideSmallChat,
  onSend,
  handleUserInfoSide,
  onEntitySelect,
  onTopReach = () => {},
  onBottomReach = () => {},
  users,
  groups,
}) => {
  const [messageMore, setMessageMore] = useState(null);
  const [targetInfo, setTargetInfo] = useState(null);

  const carIcon = useMemo(
    () => (
      <Icon
        name={`chatCar${Math.floor(Math.random() * 14) + 1}`}
        className="chat__content-empty--car"
      />
    ),
    [],
  );

  useEffect(() => {
    setTargetInfo(targetEntity?.chatter);
  }, [targetEntity]);

  const handleSelectMessageMore = useCallback((selected) => {
    setMessageMore(selected);
  }, []);

  const handleCloseChat = () => {
    if (!!onEntitySelect && typeof onEntitySelect === 'function') {
      onEntitySelect(null);
    }
  };
  const getStatus = (status) => {
    if (status === ActivityStatus.ONLINE) {
      return 'active';
    }
    if (status === ActivityStatus.OFFLINE) {
      return 'inactive';
    }
    if (status === ActivityStatus.DO_NOT_DISTURB) {
      return 'doNotDisturb';
    }
    return null;
  };

  return (
    <>
      {!targetInfo ? (
        <div className={`chat__content-empty ${isMenuVisible ? 'active' : ''}`}>
          {carIcon}
          <div className="chat__content-empty--text">
            Selectează o mesagerie
          </div>
        </div>
      ) : (
        <>
          <div
            className={`chat__content-user--info ${
              isMenuVisible ? 'menu' : ''
            }`}
          >
            <div
              className={`chat__content-user--status ${getStatus(
                targetEntity?.status,
              )}`}
            />

            <div className="chat__content-user--name">
              {stringLengthValidator(targetInfo?.name, 28)}
            </div>

            <button
              className="chat__content-user--info-btn"
              type="button"
              onClick={handleUserInfoSide}
            >
              <Icon name="chatInfo" className="chat__content-user--info-icon" />
            </button>
            <div className="chat__message-content--message">
              {rightSideSmallChat ? (
                <div className="chat__small-head">
                  <div className="chat__small-name">
                    <div className="chat__content-user--status active" />

                    <div className="chat__small-user--name">
                      {stringLengthValidator(targetInfo?.name, 25)}
                    </div>
                  </div>
                  <button
                    type="button"
                    className="chat__small-close"
                    onClick={handleCloseChat}
                  >
                    <Icon
                      name="close"
                      style={{ width: '15px', height: '15px' }}
                    />
                  </button>
                </div>
              ) : null}
              {loading ? <Spin size="small" /> : null}
              <ChatMessages
                key="chatMessages"
                messages={messages}
                isMenuVisible={isMenuVisible}
                onSelect={handleSelectMessageMore}
                onTopReach={onTopReach}
                onBottomReach={onBottomReach}
              />
              <ChatFooter
                target={targetEntity}
                isMenuVisible={isMenuVisible}
                onSend={(messageDto) => {
                  onSend(messageDto);
                  setMessageMore(null);
                }}
                messageMore={messageMore}
                onRemoveMore={() => setMessageMore(null)}
                users={users}
                groups={groups}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default ChatWindow;
