import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import RegistrationTypesList from '../../../../components/admin/taxonomies/registrationTypes/RegistrationTypesList';

const RegistrationTypes = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="registrationTypes">
      <Tabs.TabPane
        tab={t('entity.admin.registrationType._plural')}
        key="registrationTypes"
      >
        <RegistrationTypesList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default RegistrationTypes;
