import React, { useCallback, useContext } from 'react';

import { ChatEntity } from './components/ChatEntity';
import EntityTypes from './enums/EntityTypes';
import AuthContext from '../auth';

const UsersList = ({
  onSelect,
  selectedEntityIds,
  content,
  setIsMenuVisible,
  rightSideChat,
}) => {
  const { user } = useContext(AuthContext);
  const handleSelectUser = useCallback(
    (val) => {
      if (onSelect && typeof onSelect === 'function') {
        onSelect({ ...val, type: EntityTypes.USER });
      }
      if (rightSideChat) {
        setIsMenuVisible(false);
      }
    },
    [onSelect, setIsMenuVisible, rightSideChat],
  );

  const renderUsers = useCallback(() => {
    return content.map((chat) => {
      const el = chat?.chatter || {};
      return (
        <ChatEntity
          key={chat.id}
          entityId={chat.id}
          onClick={() => handleSelectUser(chat)}
          messageCount={chat.unreadMessages || 0}
          entityName={el.id !== user.id ? el.name : 'Mesaje salvate'}
          isSelected={selectedEntityIds.includes(chat.id)}
          status={chat.status}
          entityType={EntityTypes.USER}
          base64Image={el.base64Image}
          imageId={el.imageId}
        />
      );
    });
  }, [content, selectedEntityIds, handleSelectUser, user.id]);

  return <>{content ? renderUsers() : null}</>;
};
export default UsersList;
