import React, { useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

import useDatasource from '../../../hooks/useDatasource';

import { findAll, remove } from '../../../services/admin/users/roles';

import EditItemIcon from '../../buttons/EditItemIcon';
import DeleteItemIcon from '../../buttons/DeleteItemIcon';
import AddEntityButton from '../../buttons/AddEntityButton';
import Column from '../../../helpers/Columns';
import AuthContext, { hasPermission } from '../../auth';
import { useTableScroll } from '../../../hooks/useTableScroll';

const UsersList = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { permissions } = user;

  const { loading, pagination, content, handleChange, reload } =
    useDatasource(findAll);

  useTableScroll();
  const columns = useMemo(
    () => [
      Column.text('name', t('entity.admin.role.name'), {
        width: 300,
        filter: true,
      }),
      Column.text('description', t('entity.admin.role.description'), {
        width: 300,
        filter: true,
      }),
      Column.date('createdAt', t('entity._.createdAt'), {
        width: 150,
      }),
      Column.text('createdBy', t('entity._.createdBy'), {
        width: 150,
      }),
      Column.date('updatedAt', t('entity._.updatedAt'), {
        width: 150,
      }),
      Column.text('updatedBy', t('entity._.updatedBy'), {
        width: 150,
      }),
      Column.bool('deleted', t('entity._.deleted._'), {
        width: 150,
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity._.deleted.true'),
          t('entity._.deleted.false'),
          t('entity._.deleted.unknown'),
        ],
      }),
      Column.actions(t('table.actions'), (record) => (
        <span style={{ textAlign: 'right' }}>
          {hasPermission(permissions, 'USERS_ROLES_EDIT') && (
            <EditItemIcon path={`/admin/users/roles/${record.id}`} />
          )}
          {hasPermission(permissions, 'USERS_ROLES_DELETE') && (
            <DeleteItemIcon
              title={t('entity.admin.role._delete', record)}
              message={t('entity.admin.role._deleted', record)}
              item={record}
              action={remove}
              reload={reload}
            />
          )}
        </span>
      )),
    ],
    [t, reload, permissions],
  );

  return (
    <>
      <PageHeader
        title={t('entity.admin.role._plural')}
        extra={[
          <AddEntityButton
            key="new"
            entity={t('entity.admin.role._singular')}
            path="/admin/users/roles/new"
          />,
        ]}
      />
      <Table
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
      />
    </>
  );
};

export default UsersList;
