import React from 'react';
import AppLoader from '../components/auth/AppLoader';

const Logout = () => {
  return (
    <div className="loaderOverlay">
      <AppLoader />
    </div>
  );
};

export default Logout;
