import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import VehicleCategoriesList from '../../../../components/admin/taxonomies/vehicleCategory/VehicleCategoriesList';

const VehicleCategories = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="vehicleCategory">
      <Tabs.TabPane
        tab={t('entity.admin.vehicleCategory._plural')}
        key="vehicleCategory"
      >
        <VehicleCategoriesList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default VehicleCategories;
