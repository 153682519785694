import React, { useEffect, useState } from 'react';
import { useEditPage } from '../../../../hooks/useEditPage';
import AppLoader from '../../../../components/auth/AppLoader';
import TransportUnitsRisksEditForm from '../../../../components/lucrative/risks-actions/transport-units/TransportUnitsRisksEditForm';
import {
  findOne,
  create,
  update,
} from '../../../../services/risks/risk-actions/transport-units-risks';
import { useRouteParams } from '../../../../hooks/useRouteParams';

const initial = {};
const TransportUnitsRisk = () => {
  const { copyId } = useRouteParams('copyId');
  const [copy, setCopy] = useState(undefined);

  const [risk, handleSubmit, handleCancel, errors, loading] = useEditPage({
    initial,
    existent: findOne,
    goBackPath: `/lucrative/risk-analysis/transport-units`,
    onCreate: create,
    onUpdate: update,
  });

  useEffect(() => {
    if (copyId && typeof copyId === 'number') {
      findOne(copyId).then(setCopy).catch().finally();
    }
  }, [copyId]);

  return (
    <>
      <div className={`loaderOverlay ${loading ? 'active' : ''}`}>
        <AppLoader />
      </div>
      {risk !== undefined && (
        <TransportUnitsRisksEditForm
          risk={risk}
          copy={copy}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          errors={errors}
        />
      )}
    </>
  );
};

export default TransportUnitsRisk;
