import React from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import SpecialServiceRequestsList from '../../../components/lucrative/special-services/SpecialServiceRequestsList';

const SpecialServiceRequests = () => {
  return (
    <>
      <PageHeader title="Cereri servicii speciale" />
      <SpecialServiceRequestsList />
    </>
  );
};

export default SpecialServiceRequests;
