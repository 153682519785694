import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import ClosingDocumentDeclarationList from '../../../../components/admin/taxonomies/closingDocumentDeclaration/ClosingDocumentDeclarationList';

const ClosingDocumentDeclaration = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="closingDocumentDeclaration">
      <Tabs.TabPane
        tab={t('entity.admin.closingDocumentDeclaration._plural')}
        key="closingDocumentDeclaration"
      >
        <ClosingDocumentDeclarationList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default ClosingDocumentDeclaration;
