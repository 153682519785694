import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import VignetteTariffPositionList from '../../../../components/admin/taxonomies/vignetteTariffPosition/VignetteTariffPositionList';

const VignetteTariffPosition = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="vignetteTariffPosition">
      <Tabs.TabPane
        tab={t('entity.admin.vignetteTariffPosition._plural')}
        key="vignetteTariffPosition"
      >
        <VignetteTariffPositionList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default VignetteTariffPosition;
