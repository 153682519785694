import React, { useState, useEffect } from 'react';
import { Button } from 'antd';

import { mpassLogin } from '../../services/auth';
// import { mpassLogin } from '../services/auth';

const MPassLoginForm = () => {
  const [samlReq, setSamlReq] = useState('');

  useEffect(() => {
    if (samlReq) {
      const form = document.getElementById('saml-form');
      form.submit();
    }
  }, [samlReq]);

  const mpassSubmit = (evt) => {
    evt.preventDefault();
    mpassLogin().then((resp) => {
      setSamlReq(resp);
    });
  };

  return (
    <form
      name="mpass-log-in"
      id="saml-form"
      method="POST"
      action={window._env_.MPASS_LOGIN_URL}
      onSubmit={mpassSubmit}
    >
      <input type="hidden" name="SAMLRequest" value={samlReq} />
      <input
        type="hidden"
        name="RelayState"
        value="Sample AuthnRequest Relay State"
      />

      <Button type="primary" onClick={mpassSubmit} style={{ width: '100%' }}>
        Autentificare MPass
      </Button>
    </form>
  );
};

export default MPassLoginForm;
