import React from 'react';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const AddEntityButton = ({ path, entity, onClick, disabled }) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Button
      type="primary"
      disabled={disabled}
      icon={<PlusOutlined />}
      onClick={() =>
        !path && typeof onClick === 'function' ? onClick() : history.push(path)
      }
    >
      {t('actions.add')} {entity}
    </Button>
  );
};

export default AddEntityButton;
