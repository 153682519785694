import { notification } from 'antd';

export const validatePhysicalPersonRisk = (form) => {
  if (!form.getFieldValue('firstName')) {
    notification.warning({
      message: 'Completați câmpul Prenume',
    });
    return false;
  }

  if (!form.getFieldValue('lastName')) {
    notification.warning({
      message: 'Completați câmpul Nume',
    });
    return false;
  }
  return true;
};
