import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import AuditDataList from '../../../components/admin/audit/AuditDataList';

const AuditData = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="audit">
      <Tabs.TabPane tab={t('entity.admin.audit.name')} key="audit">
        <AuditDataList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default AuditData;
