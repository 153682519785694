import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Popconfirm } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

const CancelButton = ({
  title,
  onCancel,
  requireConfirm = false,
  confirmProps = {},
}) => {
  const { t } = useTranslation();

  return requireConfirm ? (
    <Popconfirm {...confirmProps}>
      <Button icon={<CloseCircleOutlined />} type="primary" danger>
        {title || t('actions.cancel')}
      </Button>
    </Popconfirm>
  ) : (
    <Button
      icon={<CloseCircleOutlined />}
      type="primary"
      danger
      onClick={onCancel}
    >
      {title || t('actions.cancel')}
    </Button>
  );
};

export default CancelButton;
