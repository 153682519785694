import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Col,
  Divider,
  Form,
  Modal,
  notification,
  Popconfirm,
  Row,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useTranslation } from 'react-i18next';
import { DownOutlined } from '@ant-design/icons';
import { hasPermission } from '../../auth';
import {
  addMessage,
  removeMessage,
} from '../../../services/transactions/transactions';
import { getBlobFile } from '../../../services/admin/files';
import AvatarComponent from '../../AvatarComponent';

const HistoricalDataAndAnalysis = ({
  permissions,
  transactionId,
  consemneStr,
  irregularities,
  readOnly,
  isDataConfirmed,
  setLoadingLoader,
  irregularitiesMessage,
  imageIds,
  form,
  onConsemnResponse = () => {},
}) => {
  const { t } = useTranslation();
  const [isResponseModalVisible, setIsResponseModalVisible] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [showImages, setShowImages] = useState(false);

  useEffect(() => {
    if (imageIds && imageIds?.length > 0) {
      imageIds.forEach((imageId) => {
        if (imageId) {
          getBlobFile(imageId)
            .then((res) => {
              const imgUrl = URL.createObjectURL(res);
              setImageUrls((prev) => [...prev, imgUrl]);
            })
            .catch((error) => {
              console.error('Error fetching blob file:', error);
            });
        }
      });
    }
    return () => {
      if (imageUrls && imageUrls?.length > 0) {
        imageUrls.forEach((imageUrl) => URL.revokeObjectURL(imageUrl));
      }
    };
    // eslint-disable-next-line
  }, [imageIds?.length]);

  useEffect(() => {
    form.setFieldsValue({
      irregularitiesMessageResponse: irregularitiesMessage,
    });
  }, [irregularitiesMessage, form]);

  const handleOk = () => {
    setLoadingLoader(true);
    const value = form.getFieldValue('responseArea');
    addMessage({
      transactionId,
      message: value,
    })
      .then((res) => {
        form.setFieldsValue({ irregularitiesMessageResponse: res?.message });
        if (onConsemnResponse && typeof onConsemnResponse === 'function') {
          onConsemnResponse(res?.message);
        }
      })
      .catch(() => {
        setIsResponseModalVisible(false);
        notification.error({
          message: 'Răspunsul nu a putut fi adăugat',
        });
      })
      .finally(() => {
        // reload();
        form.setFieldsValue({ responseArea: '' });
        setLoadingLoader(false);
      });

    setIsResponseModalVisible(false);
  };

  const handleCancel = () => {
    setIsResponseModalVisible(false);
    form.setFieldsValue({ responseArea: '' });
  };

  const irregularitiesAndConsemnsValues = useMemo(() => {
    if (consemneStr?.length > 0 && irregularities?.length > 0) {
      return `${consemneStr}\n${irregularities}`;
    }
    if (!irregularities?.length && consemneStr?.length > 0) {
      return consemneStr;
    }
    if (irregularities?.length > 0 && !consemneStr?.length) {
      return irregularities;
    }
    return null;
  }, [consemneStr, irregularities]);

  useEffect(() => {
    if (consemneStr?.length > 0 && irregularities?.length > 0) {
      form.setFieldsValue({
        commentsForm: `${consemneStr}\n${irregularities}`,
      });
    }
    if (!irregularities?.length && consemneStr?.length > 0) {
      form.setFieldsValue({ commentsForm: consemneStr });
    }
    if (irregularities?.length > 0 && !consemneStr?.length) {
      form.setFieldsValue({ commentsForm: irregularities });
    }
  }, [consemneStr, irregularities, form]);

  const renderImages = useCallback(() => {
    if (imageUrls && imageUrls?.length > 0 && showImages) {
      return (
        <div className="image-galery">
          {imageUrls.map((url) => (
            <AvatarComponent
              key={url}
              src={url}
              radius={10}
              preview
              width={250}
              height={200}
            />
          ))}
        </div>
      );
    }
    return null;
  }, [showImages, imageUrls]);

  return (
    <>
      <Divider>Date istorice și analiză</Divider>
      <Row>
        <Col span={24} className="general-data-page">
          <Form.Item
            name="commentsForm"
            className="general-data-textarea-container"
            style={{ marginBottom: '20px' }}
          >
            <TextArea
              className="area_irregularity"
              readOnly
              value={irregularitiesAndConsemnsValues}
              rows={6}
              style={{
                backgroundColor: '#f1c40f',
                width: '100%',
              }}
            />
          </Form.Item>
          <Row>
            {form.getFieldValue('irregularitiesMessageResponse') ? (
              <Form.Item
                name="irregularitiesMessageResponse"
                style={{ marginBottom: '20px', width: '100%' }}
              >
                <TextArea rows={4} disabled value={irregularitiesMessage} />
              </Form.Item>
            ) : null}
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={21}>
          {imageUrls && imageUrls?.length > 0 ? (
            <Button
              className="show-image-btn"
              type="primary"
              onClick={() => setShowImages((prev) => !prev)}
              icon={<DownOutlined />}
              style={{
                marginBottom: '.5rem',
                marginTop: irregularitiesMessage ? '1rem' : undefined,
              }}
            >
              Afișează imaginile
            </Button>
          ) : null}
        </Col>
        <Col
          span={3}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          {form.getFieldValue('irregularitiesMessageResponse')
            ? null
            : hasPermission(permissions, 'MESSAGES_ADD') && (
                <Button
                  type="primary"
                  style={{ marginBottom: '.5rem' }}
                  onClick={() => setIsResponseModalVisible(true)}
                  disabled={!transactionId || readOnly || isDataConfirmed}
                >
                  Adaugă răspuns
                </Button>
              )}
          {form.getFieldValue('irregularitiesMessageResponse')
            ? hasPermission(permissions, 'MESSAGES_DEL') && (
                <Popconfirm
                  disabled={readOnly || isDataConfirmed}
                  placement="topLeft"
                  title="Sunteți sigur că doriți să ștergeți răspunsul?"
                  onConfirm={() => {
                    setLoadingLoader(true);
                    removeMessage(transactionId)
                      .then(() => {
                        form.setFieldsValue({
                          irregularitiesMessageResponse: '',
                        });
                        if (
                          onConsemnResponse &&
                          typeof onConsemnResponse === 'function'
                        ) {
                          onConsemnResponse(null);
                        }
                      })
                      .catch(() =>
                        notification.error({
                          message: 'Răspunsul nu a putut fi șters',
                        }),
                      )
                      .finally(() => {
                        setLoadingLoader(false);
                      });
                  }}
                  okText={t('actions.delete')}
                  cancelText={t('actions.cancel')}
                >
                  <Button
                    disabled={readOnly || isDataConfirmed}
                    type="primary"
                    style={{
                      marginBottom: '1rem',
                      marginTop: '1rem',
                      width: '10rem',
                    }}
                  >
                    Șterge răspuns
                  </Button>
                </Popconfirm>
              )
            : null}
        </Col>
      </Row>
      {renderImages()}

      <Modal
        maskClosable={false}
        centered
        style={{ width: '400px' }}
        title="Adaugă răspuns"
        visible={isResponseModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Înapoi"
      >
        <span>Răspuns</span>
        <Form.Item
          name="responseArea"
          initialValue={' '}
          style={{ marginBottom: '25px' }}
        >
          <TextArea
            showCount
            maxLength={2000}
            disabled={readOnly || isDataConfirmed}
            id="response"
            rows={6}
          />
        </Form.Item>
      </Modal>
    </>
  );
};

export default HistoricalDataAndAnalysis;
