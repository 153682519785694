import { get, post } from '../../helpers/api';

export const BASE_URI = '/backend/api';
export const SERVICE_URI = '/v1/qr';

export const generateQrValue = (transactionId) =>
  get(`${BASE_URI}${SERVICE_URI}/uuid-value/${transactionId}`);

export const getQrValue = (uuid) =>
  get(`${BASE_URI}${SERVICE_URI}/get/${uuid}`);

export const saveTransactionFiles = (data) =>
  post(`${BASE_URI}${SERVICE_URI}/save`, data);
