import { useEffect, useRef, useState } from 'react';
import Stomp from 'stompjs';
import SockJS from 'sockjs-client';
import { notification } from 'antd';

const useSockJS = (url, options = {}) => {
  const {
    onConnect,
    onDisconnect,
    onMessage,
    onError,
    enableDebug = false,
  } = options;

  const [lastMessage, setLastMessage] = useState(null);
  const [error, setError] = useState(null);
  const [subscriptions, setSubscriptions] = useState([]);

  const sockJSRef = useRef(null);
  const stompClientRef = useRef(null);

  const connect = (inConnect = () => {}, inError = () => {}) => {
    if (
      stompClientRef.current &&
      sockJSRef.current !== null &&
      stompClientRef.current.connected
    ) {
      return;
    }

    sockJSRef.current = new SockJS(`${window._env_.API_BACKEND_URL}${url}`);
    stompClientRef.current = Stomp.over(sockJSRef.current);

    if (!enableDebug) {
      stompClientRef.current.debug = () => {};
    }

    stompClientRef.current.connect(
      {},
      () => {
        if (onConnect && typeof onConnect === 'function') {
          onConnect();
        }
        inConnect();
      },
      (err) => {
        setError(err);
        if (onError && typeof onError === 'function') {
          onError(err);
        }
        inError();
      },
    );
  };

  const disconnect = () => {
    if (stompClientRef.current) {
      stompClientRef.current.disconnect(() => {
        if (onDisconnect && typeof onDisconnect === 'function') {
          onDisconnect();
        }
      });
      stompClientRef.current = null;
    }
  };

  useEffect(() => {
    if (!stompClientRef.current?.connected) {
      connect();
    }
    return () => disconnect();
    // eslint-disable-next-line
  }, []);

  const subscribe = (destination, headers = {}) => {
    if (!stompClientRef.current?.connected) {
      return;
    }
    if (!subscriptions.some((sub) => sub.destination === destination)) {
      const newSubscription = stompClientRef.current.subscribe(
        destination,
        (message) => {
          const parsedMessage = JSON.parse(message.body);
          setLastMessage(parsedMessage);

          if (onMessage && typeof onMessage === 'function') {
            onMessage(parsedMessage);
          }
        },
        headers,
      );
      setSubscriptions((prev) => [
        ...prev,
        { subscription: newSubscription, destination },
      ]);
    }
  };

  const unsubscribe = (unsubscribeDestination) => {
    if (!stompClientRef.current?.connected) {
      console.error('WebSocket connection is not open');
      return;
    }
    const subscription = subscriptions.find(
      (sub) => sub.destination === unsubscribeDestination,
    );
    if (subscription) {
      subscription.unsubscribe();
      setSubscriptions((prev) =>
        prev.filter((el) => el.destination === unsubscribeDestination),
      );
    }
  };

  const send = (destination, body, headers = {}) => {
    if (!stompClientRef.current?.connected) {
      connect(
        () =>
          stompClientRef.current.send(
            destination,
            headers,
            JSON.stringify(body),
          ),
        () =>
          notification.error({
            message: 'Ne pare rău, mesajul nu a putut fi expediat.',
          }),
      );
      return;
    }
    stompClientRef.current.send(destination, headers, JSON.stringify(body));
  };

  return {
    disconnect,
    connect,
    send,
    error,
    subscribe,
    isConnected: !!stompClientRef.current?.connected,
    lastMessage,
    unsubscribe,
  };
};

export default useSockJS;
