import React from 'react';
import { Select } from 'antd';

import useDictionaries from '../hooks/useDictionaries';
import { findAll } from '../services/admin/users/customs-office';

const dictionaries = {
  posts: findAll,
};

const CustomsOfficesSelect = ({ value, onChange, disabled }) => {
  const [{ posts }] = useDictionaries(dictionaries);
  const { content } = posts;

  return (
    <Select
      value={value}
      onChange={onChange}
      disabled={disabled}
      allowClear
      showSearch
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) !== -1
      }
    >
      {content.map(
        (row) =>
          !row?.deleted && (
            <Select.Option value={row.id} key={row.id}>
              {`(${row.newCode}) ${row.name}`}
            </Select.Option>
          ),
      )}
    </Select>
  );
};

export default CustomsOfficesSelect;
