import React, { useCallback, useEffect, useState } from 'react';
import { Button, notification } from 'antd';
import Icon from '../../Icon';
import EditGroupPopup from './EditGroupPopup';
import { remove, update } from '../chat-service/group-service';
import GroupAddUsersPopup from './GroupAddUsersPopup';

const GroupSettingsPopup = ({
  group,
  onSave = () => {},
  onRemove = () => {},
}) => {
  const [settingsPopupVisible, setSettingsPopupVisible] = useState(false);
  const [settingsEdit, setSettingsEdit] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [uploadedImage, setUploadedImage] = useState(null);

  useEffect(() => {
    setGroupName(group?.name);
  }, [group?.name]);

  const handleSettingsPopup = useCallback(() => {
    setSettingsPopupVisible((prev) => !prev);
  }, []);

  const handleClearImage = () => {
    setUploadedImage('delete');
  };

  const handleEditGroup = () => {
    setSettingsEdit((prev) => !prev);
  };

  const handleCloseEditGroup = useCallback(() => {
    setSettingsEdit(false);
    setUploadedImage(group?.base64Image);
    setGroupName(group?.name);
  }, [group?.name, group?.base64Image]);

  const handleDeleteGroup = () => {
    remove(group)
      .then(() => {
        notification.success({
          message: 'Grupa a fost ștearsă cu succes',
          duration: 3,
        });
      })
      .catch(() => {
        notification.error({
          message: 'A apărut o eroare la ștergerea grupei',
          duration: 3,
        });
      })
      .finally(() => {
        setSettingsPopupVisible(false);
        onRemove();
      });
  };

  const handleImageSelected = (val) => {
    setUploadedImage(val);
  };

  const handleSave = useCallback(() => {
    const data = {
      ...group,
      name: groupName,
      base64Image: uploadedImage,
    };

    update(data)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (onSave && typeof onSave === 'function') {
          onSave(json);
        }
      })
      .catch(() => {
        notification.error({
          message: 'A apărut o eroare la editarea grupei',
          duration: 3,
        });
      })
      .finally(() => {
        handleSettingsPopup();
        handleCloseEditGroup();
        setUploadedImage('');
        setGroupName('');
      });
  }, [
    group,
    onSave,
    uploadedImage,
    groupName,
    handleSettingsPopup,
    handleCloseEditGroup,
  ]);

  const renderSettingsInfo = () => {
    if (settingsEdit) {
      return (
        <>
          <EditGroupPopup
            initialImageId={group?.imageId}
            onImageSelected={handleImageSelected}
            groupName={groupName}
            setGroupName={setGroupName}
            onImageClear={handleClearImage}
          />
          <div className="chat__settings-confirmation">
            <Button
              className="chat__group-cancel"
              type="primary"
              onClick={handleCloseEditGroup}
            >
              Anulează
            </Button>
            <Button
              type="primary"
              disabled={groupName?.length < 1}
              onClick={handleSave}
            >
              Confirmă
            </Button>
          </div>
        </>
      );
    }

    return (
      <>
        <button
          type="button"
          className="chat__close chat__settings-close"
          onClick={handleSettingsPopup}
        >
          <Icon name="close" width="15" height="15" />
        </button>
        <h3 className="chat__settings-title">Setări</h3>
        <div className="chat__settings-list">
          <button
            type="button"
            className="chat__settings-list--item"
            onClick={handleEditGroup}
          >
            <div className="chat__settings-item--title">Editează grupa</div>
            <Icon
              name="messageEdit"
              style={{ width: '15px', height: '15px' }}
            />
          </button>
          <GroupAddUsersPopup
            group={group}
            onSave={(res) => {
              handleSettingsPopup();
              onSave(res);
            }}
            settingsButton="true"
          />
          <button
            type="button"
            className="chat__settings-list--item delete"
            onClick={handleDeleteGroup}
          >
            <div className="chat__settings-item--title">Șterge grupa</div>
            <Icon name="trash" style={{ width: '15px', height: '15px' }} />
          </button>
        </div>
      </>
    );
  };

  return (
    <div className="chat__settings-container">
      <button
        type="button"
        className="chat__settings"
        onClick={handleSettingsPopup}
      >
        Setări
        <Icon name="chatSettings" style={{ width: '30px', height: '30px' }} />
      </button>
      <div
        className={`chat__settings-overlay ${
          settingsPopupVisible ? 'active' : ''
        }`}
      >
        <div className="chat__settings-popup">{renderSettingsInfo()}</div>
      </div>
    </div>
  );
};

export default GroupSettingsPopup;
