import React, { useRef } from 'react';
import { ArrowDownOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import ChatMessage from './ChatMessage';
import useScrollPosition from '../helpers/useScrollPosition';

const ChatMessages = ({
  messages,
  isMenuVisible,
  onSelect,
  onTopReach = () => {},
  onBottomReach = () => {},
}) => {
  const myDivRef = useRef(null);

  const { scrollToBottom, atBottom } = useScrollPosition({
    ref: myDivRef,
    onTopReach,
    onBottomReach,
  });

  const handleSelectMore = (selected) => {
    onSelect(selected);
  };

  const renderMessages = () => {
    return messages.map((el) => (
      <ChatMessage key={el.id} message={el} onClick={handleSelectMore} />
    ));
  };

  return (
    <div
      className={`chat__messages ${isMenuVisible ? 'active' : ''}`}
      ref={myDivRef}
    >
      <Button
        type="primary"
        className={`chat__scroll-btn ${!atBottom ? 'active' : ''} ${
          isMenuVisible ? 'menu' : ''
        }`}
        onClick={scrollToBottom}
        icon={<ArrowDownOutlined />}
      />
      {renderMessages()}
    </div>
  );
};

export default ChatMessages;
