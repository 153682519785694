import React, { useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

import useDatasource from '../../../../hooks/useDatasource';

import Column from '../../../../helpers/Columns';
import { findAll, remove } from '../../../../services/taxonomies/endowment';

import EditItemIcon from '../../../buttons/EditItemIcon';
import DeleteItemIcon from '../../../buttons/DeleteItemIcon';
import AddEntityButton from '../../../buttons/AddEntityButton';
import ViewItemIcon from '../../../buttons/ViewItemIcon';
import AuthContext, { hasPermission } from '../../../auth';
import { useTableScroll } from '../../../../hooks/useTableScroll';

const EndowmentList = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { permissions } = user;
  useTableScroll();
  const { loading, pagination, content, handleChange, reload } =
    useDatasource(findAll);

  const columns = useMemo(
    () => [
      Column.text('id', t('entity.admin.endowment.id'), {
        width: 50,
      }),
      Column.text('code', t('entity.admin.endowment.code'), {
        width: 70,
        filter: true,
      }),
      Column.text('name', t('entity.admin.endowment.name'), {
        width: 150,
        filter: true,
      }),
      Column.date('createdAt', t('entity.admin.endowment.createdAt'), {
        width: 150,
        format: 'DD-MM-YYYY HH:mm:ss',
      }),
      Column.text('createdBy', t('entity.admin.endowment.createdBy'), {
        width: 100,
      }),
      Column.date('updatedAt', t('entity.admin.endowment.updatedAt'), {
        width: 150,
        format: 'DD-MM-YYYY HH:mm:ss',
      }),
      Column.text('updatedBy', t('entity.admin.endowment.updatedBy'), {
        width: 100,
      }),
      Column.bool('deleted', t('entity.admin.endowment.deleted'), {
        width: 100,
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity._.deleted.true'),
          t('entity._.deleted.false'),
          t('entity._.deleted.unknown'),
        ],
      }),
      Column.actions(t('table.actions'), (record) =>
        record.deleted ? (
          <span style={{ textAlign: 'right' }}>
            {hasPermission(permissions, 'TAXONOMIES_VIEW') && (
              <ViewItemIcon path={`/admin/taxonomies/endowment/${record.id}`} />
            )}
          </span>
        ) : (
          <span style={{ textAlign: 'right' }}>
            {hasPermission(permissions, 'TAXONOMIES_EDIT') && (
              <EditItemIcon path={`/admin/taxonomies/endowment/${record.id}`} />
            )}
            {hasPermission(permissions, 'TAXONOMIES_DELETE') && (
              <DeleteItemIcon
                title={t('entity.admin.endowment._delete', record)}
                message={t('entity.admin.endowment._deleted', record)}
                item={record}
                action={remove}
                reload={reload}
              />
            )}
          </span>
        ),
      ),
    ],
    [t, reload, permissions],
  );

  return (
    <>
      <PageHeader
        title={t('entity.admin.endowment._plural')}
        extra={[
          <AddEntityButton
            key="new"
            entity={t('entity.admin.endowment._singular')}
            path="/admin/taxonomies/endowment/new"
          />,
        ]}
      />
      <Table
        scroll={{ x: 500 }}
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
      />
    </>
  );
};

export default EndowmentList;
