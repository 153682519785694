import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Table,
  Tooltip,
  Button,
  notification,
  Modal,
  Row,
  Col,
  Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  PlayCircleOutlined,
  RedoOutlined,
  SettingOutlined,
  StopOutlined,
  PoweroffOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import Column from '../../helpers/Columns';
import {
  getAvailableJobs,
  runJob,
  interruptJob,
  activateJob,
  deactivateJob,
  rescheduleJob,
} from '../../services/admin/web-services/scheduler';
import {
  CRON_OPTIONS,
  JOB_STATUSES,
} from '../../components/admin/system/constants';
import { useTableScroll } from '../../hooks/useTableScroll';

const SFSIresponsabiliList = () => {
  const { t } = useTranslation();
  const [schedulerList, setSchedulerList] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [schedulerItem, setSchedulerItem] = useState();
  const [cronExpressionValue, setCronExpressionValue] = useState();
  useTableScroll();
  const showModal = (item) => {
    setSchedulerItem(item);
    setCronExpressionValue(item?.cronExpression);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    getAvailableJobs().then((res) => {
      setSchedulerList(
        res?.map((sc, index) => {
          return { ...sc, nr: index + 1, id: sc.name, name: sc.description };
        }),
      );
    });
  }, [setSchedulerList]);

  const handleCronExpressionChange = useCallback((e) => {
    setCronExpressionValue(e);
  }, []);

  const loadJobs = useCallback(() => {
    getAvailableJobs()
      .then((res) => {
        setSchedulerList(
          res?.map((sc, index) => {
            return { ...sc, nr: index + 1, id: sc.name, name: sc.description };
          }),
        );
      })
      .catch(() =>
        notification.error({
          message: t('scheduler.exceptions.getAvailableJobs'),
        }),
      );
  }, [t]);

  const handleOk = () => {
    if (schedulerItem.cronExpression !== cronExpressionValue) {
      rescheduleJob({
        ...schedulerItem,
        cronExpression: cronExpressionValue,
      })
        .then(() => {
          setIsModalVisible(false);
          loadJobs();
        })
        .catch(() =>
          notification.error({
            message: t('scheduler.exceptions.rescheduleJob'),
          }),
        );
    }
  };

  const columns = useMemo(
    () => [
      Column.text('nr', 'Nr.', {
        width: 10,
      }),
      Column.text('name', 'Denumire', {
        width: 500,
      }),
      {
        title: 'Statut',
        key: 'status',
        width: 120,
        dataIndex: 'status',
        render: (value) => {
          return <>{JOB_STATUSES[JOB_STATUSES[value]].text}</>;
        },
      },
      Column.date('nextFireTime', 'Următoarea dată a rulării', {
        width: 150,
        format: 'YYYY-MM-DD HH:mm:ss',
      }),
      {
        title: 'Interval',
        key: 'cronExpression',
        width: 120,
        dataIndex: 'cronExpression',
        render: (value) => {
          return <>{CRON_OPTIONS?.find((v) => v.value === value)?.label}</>;
        },
      },
      Column.actions(t('table.actions'), (record) => (
        <span style={{ textAlign: 'right' }}>
          <Tooltip title="Setări">
            <Button
              shape="circle"
              type="link"
              icon={<SettingOutlined />}
              onClick={() => showModal(record)}
            />
          </Tooltip>
          <Tooltip title="Pornește">
            <Button
              shape="circle"
              type="link"
              icon={<PlayCircleOutlined />}
              disabled={
                record.status !== JOB_STATUSES[JOB_STATUSES.WAITING].code
              }
              onClick={() =>
                runJob(record)
                  .then(() => {
                    loadJobs();
                    notification.success({
                      message: t('scheduler.successMesages.runJob'),
                      duration: 3,
                    });
                  })
                  .catch(() => {
                    notification.error({
                      message: t('scheduler.exceptions.runJob'),
                    });
                  })
              }
            />
          </Tooltip>
          <Tooltip title="Oprește">
            <Button
              shape="circle"
              type="link"
              icon={<StopOutlined />}
              disabled={
                record.status !== JOB_STATUSES[JOB_STATUSES.RUNNING].code
              }
              onClick={() =>
                interruptJob(record)
                  .then(() => {
                    loadJobs();
                    notification.success({
                      message: t('scheduler.successMesages.interruptJob'),
                      duration: 3,
                    });
                  })
                  .catch(() =>
                    notification.error({
                      message: t('scheduler.exceptions.interruptJob'),
                    }),
                  )
              }
            />
          </Tooltip>
          <Tooltip title="Actualizează">
            <Button
              shape="circle"
              type="link"
              icon={<RedoOutlined />}
              onClick={() => {
                loadJobs();
              }}
            />
          </Tooltip>
        </span>
      )),
    ],
    [t, loadJobs],
  );

  return (
    <>
      <PageHeader title="Planificare ServiciiWeb" />
      <Table
        columns={columns}
        dataSource={schedulerList}
        pagination={false}
        rowKey="id"
        size="small"
      />
      <Modal
        maskClosable={false}
        centered
        width={600}
        title="Setări"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Salvează"
        cancelText="Înapoi"
      >
        <Row gutter={12}>
          <Col span={12}>
            <Button
              block
              type="primary"
              icon={<CheckOutlined />}
              onClick={() =>
                activateJob(schedulerItem)
                  .then(() => {
                    setIsModalVisible(false);
                    loadJobs();
                  })
                  .catch(() => {
                    notification.error({
                      message: t('scheduler.exceptions.activateJob'),
                    });
                  })
              }
              disabled={
                !schedulerItem ||
                schedulerItem.status !== JOB_STATUSES[JOB_STATUSES.PAUSED].code
              }
            >
              Activează programarea
            </Button>
          </Col>
          <Col span={12}>
            <Button
              block
              type="primary"
              icon={<PoweroffOutlined />}
              onClick={() =>
                deactivateJob(schedulerItem)
                  .then(() => {
                    setIsModalVisible(false);
                    loadJobs();
                  })
                  .catch(() =>
                    notification.error({
                      message: t('scheduler.exceptions.deactivateJob'),
                    }),
                  )
              }
              disabled={
                !schedulerItem ||
                schedulerItem.status === JOB_STATUSES[JOB_STATUSES.PAUSED].code
              }
            >
              Dezactivează programarea
            </Button>
          </Col>
        </Row>
        <Row style={{ marginTop: '1rem' }}>
          <Col span={24}>
            <Select
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) !==
                -1
              }
              style={{ width: '100%' }}
              value={cronExpressionValue}
              onSelect={handleCronExpressionChange}
            >
              {CRON_OPTIONS.map((row) => (
                <Select.Option value={row.value} key={row.id}>
                  {row.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default SFSIresponsabiliList;
