import React, { useCallback, useState } from 'react';
import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { RiskSearchTypes } from './RiskSearchTypes';

const RadioCheckBoxes = ({
  onChange = () => {},
  initial = RiskSearchTypes.PHYSICAL_PERSONS,
}) => {
  const { t } = useTranslation();
  const [isPhysicalPersons, setIsPhysicalPersons] = useState(
    initial === RiskSearchTypes.PHYSICAL_PERSONS,
  );
  const [isLegalPersons, setIsLegalPersons] = useState(
    initial === RiskSearchTypes.LEGAL_PERSONS,
  );
  const [isTransportUnits, setIsTransportUnits] = useState(
    initial === RiskSearchTypes.TRANSPORT_UNITS,
  );

  const handleChange = useCallback(
    (checked, type) => {
      if (!checked) {
        onChange(RiskSearchTypes.NONE);
        setIsPhysicalPersons(false);
        setIsLegalPersons(false);
        setIsTransportUnits(false);
        return;
      }
      switch (type) {
        case RiskSearchTypes.PHYSICAL_PERSONS:
          setIsPhysicalPersons(checked);
          setIsLegalPersons(false);
          setIsTransportUnits(false);
          onChange(RiskSearchTypes.PHYSICAL_PERSONS);
          break;
        case RiskSearchTypes.LEGAL_PERSONS:
          setIsLegalPersons(checked);
          setIsPhysicalPersons(false);
          setIsTransportUnits(false);
          onChange(RiskSearchTypes.LEGAL_PERSONS);
          break;
        case RiskSearchTypes.TRANSPORT_UNITS:
          setIsTransportUnits(checked);
          setIsLegalPersons(false);
          setIsPhysicalPersons(false);
          onChange(RiskSearchTypes.TRANSPORT_UNITS);
          break;
        default:
          break;
      }
    },
    [onChange],
  );

  return (
    <div
      className="home__header-navigation"
      style={{
        display: 'flex',
        height: '40px',
      }}
    >
      <Checkbox
        checked={isPhysicalPersons}
        onChange={(e) =>
          handleChange(e.target.checked, RiskSearchTypes.PHYSICAL_PERSONS)
        }
        className={`checkbox__background 
      ${isPhysicalPersons ? 'active' : 'pasive'}`}
      >
        <span style={{ fontSize: '1.1rem', fontWeight: '600' }}>
          {t('entity.lucrative.physical-persons._plural')}
        </span>
      </Checkbox>
      <Checkbox
        checked={isLegalPersons}
        onChange={(e) =>
          handleChange(e.target.checked, RiskSearchTypes.LEGAL_PERSONS)
        }
        className={`checkbox__background 
      ${isLegalPersons ? 'active' : 'pasive'}`}
      >
        <span style={{ fontSize: '1.1rem', fontWeight: '600' }}>
          {t('entity.lucrative.legal-persons._plural')}
        </span>
      </Checkbox>
      <Checkbox
        checked={isTransportUnits}
        onChange={(e) =>
          handleChange(e.target.checked, RiskSearchTypes.TRANSPORT_UNITS)
        }
        className={`checkbox__background 
      ${isTransportUnits ? 'active' : 'pasive'}`}
      >
        <span style={{ fontSize: '1.1rem', fontWeight: '600' }}>
          {t('entity.lucrative.transport-units._plural')}
        </span>
      </Checkbox>
    </div>
  );
};

export default RadioCheckBoxes;
