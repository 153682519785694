import { genericService, get } from '../../helpers/api';

export const SERVICE_URI = '/taxonomies';
export const BASE_URI = '/v1/customs-payments';

export const { findAll, findOne, create, update, remove } = genericService(
  SERVICE_URI,
  BASE_URI,
);

export const findAllPayments = () => get(`${SERVICE_URI}${BASE_URI}/all`);
