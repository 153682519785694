import { genericService, get } from '../../helpers/api';

export const SERVICE_URI = '/taxonomies';
export const BASE_URI = '/v1/countries';

export const { findAll, findOne, create, update, remove } = genericService(
  SERVICE_URI,
  BASE_URI,
);

export const getCountryByAlpha3 = (alpha3) =>
  get(`${SERVICE_URI}/${BASE_URI}/by-alpha3/${alpha3}`);
