import React from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import { findOne } from '../../chat-service/chat-management';
import { useEditPage } from '../../../../hooks/useEditPage';
import ChatView from '../../components/admin/ChatView';

const initial = {};
const Chat = () => {
  const [chat, , handleCancel] = useEditPage({
    initial,
    existent: findOne,
    goBackPath: '/admin/chat-management',
    onCreate: () => {},
    onUpdate: () => {},
  });

  return (
    <>
      <PageHeader title="Conversație" />
      <ChatView chat={chat} onCancel={handleCancel} />
    </>
  );
};

export default Chat;
