import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Button, Modal, notification, Table, Tooltip } from 'antd';
import {
  CarOutlined,
  FileImageOutlined,
  RollbackOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import useDatasource from '../../../hooks/useDatasource';
import {
  findById,
  getVideoPath,
  searchVicoses,
} from '../../../services/admin/vicos';
import { useTableScroll } from '../../../hooks/useTableScroll';
import Column from '../../../helpers/Columns';
import VideoPlayer from '../../VideoPlayer';
import AuthContext, { hasPermission } from '../../auth';
import ImageSelect from '../../ImageSelect';

const VicosList = () => {
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const { permissions } = user;
  const [video, setVideo] = useState({});
  const [imageVicos, setImageVicos] = useState({});

  useTableScroll();
  const { loading, pagination, content, handleChange } =
    useDatasource(searchVicoses);

  const handleImagePress = useCallback((vicosId) => {
    if (vicosId) {
      findById(vicosId)
        .then(setImageVicos)
        .catch((err) => {
          if (err) {
            notification.error({
              message: 'A apărut o eroare la extragerea imaginii',
            });
          }
        });
    }
  }, []); // get Vicos from backend and use image []

  const handleVideoPress = useCallback((videoId) => {
    if (videoId) {
      getVideoPath(videoId)
        .then(setVideo)
        .catch((err) => {
          if (err) {
            notification.error({
              message: 'A apărut o eroare la extragerea videoclipului',
            });
          }
        });
    }
  }, []);

  const handleTransactionPress = useCallback(
    (transactionId) => {
      history.push(`/physical-person-entry/${transactionId}#HAS_VICOS`);
    },
    [history],
  );

  const columns = useMemo(
    () => [
      Column.text('id', 'ID', {
        width: 75,
        filter: true,
      }),
      Column.text('transactionId', 'ID Rută', {
        width: 75,
        filter: true,
      }),
      Column.text('plateNumber', 'Nr. înmatriculare', {
        width: 75,
        filter: true,
      }),
      Column.text('cameraId', 'Cameră', {
        width: 200,
        filter: true,
      }),
      Column.date('createdAt', 'Creat la', {
        width: 200,
        filter: true,
        format: 'DD.MM.YYYY HH:mm:ss',
      }),
      Column.actions('Acțiune', (record) => (
        <>
          <Tooltip title="Vizualizare Imagine">
            <Button
              shape="circle"
              type="link"
              icon={<FileImageOutlined />}
              onClick={() => handleImagePress(record.id)}
              hidden={!record.imageUrl}
            />
          </Tooltip>
          <Tooltip title="Vizualizare Video">
            <Button
              shape="circle"
              type="link"
              icon={<VideoCameraOutlined />}
              onClick={() => handleVideoPress(record.videoId)}
              hidden={
                !record.videoId || !hasPermission(permissions, 'WATCH_VIDEO')
              }
            />
          </Tooltip>
          <Tooltip title="Vizualizare Rută">
            <Button
              shape="circle"
              type="link"
              icon={<CarOutlined />}
              onClick={() => handleTransactionPress(record.transactionId)}
              hidden={
                !record.transactionId ||
                !hasPermission(permissions, 'SEARCH_TRANSACTIONS')
              }
            />
          </Tooltip>
        </>
      )),
    ],
    [handleTransactionPress, handleImagePress, handleVideoPress, permissions],
  );

  return (
    <>
      <Table
        loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        columns={columns}
      />
      {video?.path ? (
        <Modal
          title="Video"
          visible={video?.path}
          onCancel={() => setVideo({})}
          cancelText="Înapoi"
          cancelButtonProps={{ icon: <RollbackOutlined /> }}
          okButtonProps={{ hidden: true }}
          width={730}
          centered
        >
          <VideoPlayer path={video?.path} autoPlay width="650px" />
        </Modal>
      ) : null}
      {imageVicos?.id ? (
        <Modal
          title="Imagine"
          visible={imageVicos?.id}
          onCancel={() => setImageVicos({})}
          cancelText="Înapoi"
          cancelButtonProps={{ icon: <RollbackOutlined /> }}
          okButtonProps={{ hidden: true }}
          width={730}
        >
          <ImageSelect
            url={`data:${imageVicos.imageType};base64,${imageVicos.image}`}
            width={600}
          />
        </Modal>
      ) : null}
    </>
  );
};

export default VicosList;
