import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import {
  Form,
  Row,
  Col,
  Input,
  Space,
  Button,
  notification,
  Modal,
  Select,
  Table,
  Tooltip,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { PageHeader } from '@ant-design/pro-layout';
import { EyeOutlined, PlusOutlined } from '@ant-design/icons';
import EditItemIcon from '../buttons/EditItemIcon';
import DeleteItemIcon from '../buttons/DeleteItemIcon';
import ViewItemIcon from '../buttons/ViewItemIcon';
import CancelButton from '../buttons/BackButton';
import {
  addWarehouse,
  addWarehouseReloadReason,
  deleteWarehouse,
  linkReloadTransaction,
} from '../../services/admin/web-services/warehouse-transactions';
import { FormContextProvider } from '../../hooks/useFormContext';
import AuthContext, { hasPermission } from '../auth';
import { saveTransaction } from '../../services/admin/web-services/backend';
import useDictionaries from '../../hooks/useDictionaries';
import { findAll as findAllVehicleCategories } from '../../services/taxonomies/vehicle-categories';
import { findAll as findAllConstants } from '../../services/taxonomies/constants';
import AppLoader from '../auth/AppLoader';
import CountrySelect from '../CountrySelect';
import { findAll as findAllCountries } from '../../services/admin/geo/countries';
import {
  maxLengthValidator,
  dotValidator,
  floatValidator,
} from '../../services/utils/validator/Validator';
import {
  convertKilosToTons,
  convertTonesToKilos,
} from '../../services/utils/convertors/weigth-converter';
import NextButton from '../buttons/NextButton';
import GoodsListModal from '../admin/warehouse/GoodsListModal';
import RequiredLabel from '../../services/utils/RequiredLabel';
import WarehouseDocumentModal from '../admin/warehouse/WarehouseDocumentModal';

const directions = [
  { id: 'IN', name: 'INTRARE' },
  { id: 'OUT', name: 'IEȘIRE' },
];

const dictionaries = {
  vehicleCategories: findAllVehicleCategories,
  constants: findAllConstants,
  countries: findAllCountries,
};

const Deposit = ({
  editMode,
  transaction = null,
  transshipmentData = null,
  onCancel = () => {},
  readOnly,
  reload = () => {},
  moveNext = () => {},
  moveBack = () => {},
}) => {
  const [form] = Form.useForm();
  const { user } = useContext(AuthContext);
  const { permissions } = user;
  const [loadingLoader, setLoadingLoader] = useState(false);
  const [transbordareModal, setTransbordareModal] = useState(false);
  const [commentModalVisible, setCommentModalVisible] = useState(false);
  const [allowedVehicleCategories, setAllowedVehicleCategories] = useState([]);
  const [hasGoods] = useState(transaction?.hasGoods);
  const handleChange = (e) => {
    form.setFieldsValue({
      goodsWeight: floatValidator(dotValidator(e.target.value)),
    });
  };

  const [transshipment, setTransshipment] = useState({});

  useEffect(() => {
    if (transaction?.id) {
      setTransshipment(transaction?.transshipment);
    } else if (transshipmentData?.id) {
      setTransshipment(transshipmentData);
    }
  }, [
    transaction?.id,
    transshipmentData,
    transshipmentData?.id,
    transaction?.transshipment,
  ]);

  useEffect(() => {
    if (transshipment?.vehicleNumber) {
      form.setFieldsValue({ plateNumber: transshipment?.vehicleNumber });
    } else if (transaction?.plateNumber) {
      form.setFieldsValue({ plateNumber: transaction?.plateNumber });
    }
    if (transshipment?.quantity) {
      form.setFieldsValue({ goodsWeight: transshipment?.quantity });
    } else if (transaction?.scale?.goodsWeight) {
      form.setFieldsValue({
        goodsWeight: convertKilosToTons(transaction?.scale?.goodsWeight),
      });
    }
  }, [
    form,
    transaction?.plateNumber,
    transaction?.scale?.goodsWeight,
    transshipment?.vehicleNumber,
    transshipment?.quantity,
  ]);

  const [{ vehicleCategories, constants, countries }] =
    useDictionaries(dictionaries);

  useEffect(() => {
    const allowedCategories =
      constants?.content
        ?.find((el) => !el.deleted && el.code === 'TERMINAL_MARFA_CATEGORII')
        ?.value?.split(',') || [];
    setAllowedVehicleCategories(
      vehicleCategories?.content.filter((el) =>
        allowedCategories.includes(el.code),
      ),
    );
  }, [constants, user, vehicleCategories]);

  const vinNumberValidator = (field) => {
    const value = form.getFieldValue(field.field);
    const alowedChar = 'ABCDEFGHJKLMNPRSTUVWXYZ0123456789'.split('');
    let validated = value.toUpperCase();
    validated = validated
      .split('')
      .filter((letter) =>
        alowedChar.includes(letter)
          ? true
          : notification.error({
              message:
                'Codul VIN nu poate conține următoarele caractere: O, I, Q',
            }),
      )
      .join('');
    form.setFieldsValue({ [field.field]: validated });
  };

  const checkDataForSave = useCallback(() => {
    let success = true;
    if (!form.getFieldValue(['reloadPlateNumber'])) {
      notification.error({
        message: 'Introduceți număr vehicul transbordare',
      });
      success = false;
    }
    if (!form.getFieldValue(['reloadVinCode'])) {
      notification.error({
        message: 'Introduceți VIN',
      });
      success = false;
    }
    if (
      form.getFieldValue(['reloadVinCode']) &&
      form.getFieldValue(['reloadVinCode']).length < 17
    ) {
      notification.error({
        message: 'Introduceți 17 simboluri pentru VIN',
      });
      success = false;
    }
    if (!form.getFieldValue(['reloadCountryId'])) {
      notification.error({
        message: 'Selectați țara',
      });
      success = false;
    }
    if (!form.getFieldValue(['reloadVehicleCategory'])) {
      notification.error({
        message: 'Selectați categoria',
      });
      success = false;
    }
    if (!form.getFieldValue(['direction'])) {
      notification.error({
        message: 'Selectați direcția',
      });
      success = false;
    }
    if (!form.getFieldValue(['quantity'])) {
      notification.error({
        message: 'Adăugați cantitatea mărfii transbordate',
      });
      success = false;
    }
    return success;
  }, [form]);

  const createTransaction = useCallback(
    (warehouse) => {
      setLoadingLoader(true);
      const transactionDTO = {
        isPaid: false,
        persons: 0,
        vehicles: [],
        scale: {},
      };

      transactionDTO.postId = user.postId;
      transactionDTO.direction = warehouse?.direction;
      transactionDTO.category = warehouse?.reloadVehicleCategory;
      transactionDTO.vehicles.push({
        plateNumber: warehouse?.reloadPlateNumber,
        category: warehouse?.reloadVehicleCategory,
        countryId: warehouse?.reloadCountryId,
        vinCode: warehouse?.reloadVinCode,
        withCargo: true,
        isTrailer: false,
      });
      transactionDTO.scale.goodsWeight = convertTonesToKilos(
        warehouse?.quantity,
      );
      transactionDTO.scale.hasContainer = false;
      transactionDTO.hasGoods = true;

      saveTransaction(transactionDTO)
        .then((res) => {
          linkReloadTransaction(warehouse.id, res.id)
            .then((linkedRes) => {
              if (linkedRes) {
                notification.success({
                  message: 'Ruta a fost creată cu succes',
                });
              }
            })
            .catch((err) => {
              if (err) {
                notification.error({
                  message: 'A apărut o eroare la crearea rutei',
                });
              }
            });
        })
        .catch((err) => {
          if (err) {
            notification.error({
              message: 'A apărut o eroare la crearea rutei',
            });
          }
        })
        .finally(() => {
          setLoadingLoader(false);
          reload();
        });
    },
    [user?.postId, reload],
  );

  const handleOpenTransbordareModal = useCallback(() => {
    if (!transaction?.id && !form.getFieldValue('plateNumber')) {
      notification.error({
        message: 'Introdu numărul auto din care se transbordează',
      });
      return;
    }
    if (!transaction?.id && !form.getFieldValue('goodsWeight')) {
      notification.error({
        message: 'Masa încărcăturii (tone)',
      });
      return;
    }
    setTransbordareModal(true);
  }, [form, transaction?.id]);

  const handleCancelModal = useCallback(() => {
    form.setFieldsValue({
      id: null,
      reloadPlateNumber: null,
      reloadVinCode: null,
      reloadCountryId: null,
      reloadVehicleCategory: null,
      quantity: null,
      direction: null,
    });
    setTransbordareModal(false);
  }, [form]);

  const prepareEditWarehouseModal = useCallback(
    (entity) => {
      form.setFieldsValue({
        id: entity.id,
        reloadPlateNumber: entity.reloadPlateNumber,
        reloadVinCode: entity.reloadVinCode,
        reloadCountryId: entity.reloadCountryId,
        reloadVehicleCategory: entity.reloadVehicleCategory,
        quantity: entity.quantity,
        direction: entity.direction,
      });
      handleOpenTransbordareModal();
    },
    [form, handleOpenTransbordareModal],
  );

  const action = useCallback(
    (warehouseData) => {
      if (transaction?.id) {
        return addWarehouse(`${transaction?.id}`, {
          ...warehouseData,
          transshipmentId: transshipment?.id,
        });
      }

      return addWarehouse(`transshipment/${transshipment?.id || ''}`, {
        ...warehouseData,
        transshipmentId: transshipment?.id,
      });
    },
    [transshipment?.id, transaction?.id],
  );

  const handleAddWarehouse = useCallback(() => {
    if (!checkDataForSave() || loadingLoader) {
      return;
    }
    const warehouseData = form.getFieldsValue();
    warehouseData.transshipmentVehicleNbr = warehouseData.plateNumber;
    warehouseData.transshipmentQuantity = warehouseData.goodsWeight;
    warehouseData.reload = false;

    setLoadingLoader(true);
    action(warehouseData)
      .then((res) => {
        if (res.id) {
          notification.success({
            message: 'Datele au fost salvate.',
            duration: 3,
          });
          handleCancelModal();
        } else {
          notification.error({
            message: 'Datele nu au putut fi salvate.',
          });
        }
        if (!transaction?.id) {
          reload(res.id);
        } else {
          reload();
        }
      })
      .catch(() => {
        reload();
        notification.error({
          message: 'Datele nu au putut fi salvate.',
        });
      })
      .finally(() => {
        reload();
        setLoadingLoader(false);
      });
  }, [
    form,
    reload,
    action,
    loadingLoader,
    transaction?.id,
    checkDataForSave,
    handleCancelModal,
  ]);

  const renderGoods = useCallback(
    (id, goods) => {
      return <GoodsListModal warehouseId={id} goods={goods} reload={reload} />;
    },
    [reload],
  );

  const columns = useMemo(
    () => [
      {
        title: 'Nr. auto transbordare',
        key: 'reloadPlateNumber',
        width: 150,
        sorter: (a, b) =>
          a.reloadPlateNumber.localeCompare(b.reloadPlateNumber),
        render: (row) => {
          return <>{row.reloadPlateNumber?.toUpperCase()}</>;
        },
      },
      {
        title: 'Nr. Caroserie/VIN',
        key: 'reloadVinCode',
        width: 170,
        sorter: (a, b) => a.reloadVinCode.localeCompare(b.reloadVinCode),
        render: (row) => {
          return <>{row.reloadVinCode}</>;
        },
      },
      {
        title: 'Cantitate marfă (tone)',
        key: 'quantity',
        width: 100,
        sorter: (a, b) => a.quantity - b.quantity,
        render: (row) => {
          return <>{row.quantity}</>;
        },
      },
      {
        title: 'Bunuri',
        key: 'goods',
        width: 100,
        sort: false,
        render: (row) => {
          return renderGoods(row.id, row.goods);
        },
      },
      {
        sort: false,
        title: 'Țara',
        key: 'reloadCountryId',
        width: 150,
        dataIndex: 'reloadCountryId',
        render: (row) => {
          return `${countries?.content?.find((el) => el.id === row)?.name}`;
        },
      },
      {
        sort: false,
        title: 'Categorie',
        key: 'reloadVehicleCategory',
        width: 170,
        dataIndex: 'reloadVehicleCategory',
        render: (row) => {
          const category = allowedVehicleCategories?.find(
            (el) => el.code === row,
          );
          return `${category?.code} - ${category?.name}`;
        },
      },
      {
        title: 'Direcție',
        key: 'direction',
        width: 100,
        dataIndex: 'direction',
        sorter: (a, b) => a.direction.length - b.direction.length,
        render: (row) => {
          return `${directions?.find((el) => el.id === row)?.name}`;
        },
      },
      {
        title: 'ID Rută',
        key: 'reloadTransactionId',
        width: 150,
        sorter: (a, b) => a.reloadTransactionId - b.reloadTransactionId,
        render: (row) => {
          return <>{row.reloadTransactionId}</>;
        },
      },
      {
        sort: false,
        title: 'Acțiune',
        width: 80,
        fixed: 'right',
        align: 'center',
        render: (row) => {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              {hasPermission(permissions, 'CREATE_WAREHOUSE_DOCUMENT') && (
                <WarehouseDocumentModal
                  warehouseId={row.id}
                  toVehicle={row?.reloadPlateNumber}
                  fromVehicle={transshipment?.vehicleNumber}
                  document={row.document}
                  reload={reload}
                />
              )}
              {row.reloadTransactionId ? (
                <ViewItemIcon
                  action={() => {
                    window.location.href = `/physical-person-entry/${row.reloadTransactionId}#GENERAL_DATA`;
                  }}
                />
              ) : (
                <>
                  {hasPermission(permissions, 'WAREHOUSE_ADD') && (
                    <EditItemIcon
                      action={() => {
                        prepareEditWarehouseModal(row);
                      }}
                    />
                  )}
                  {hasPermission(permissions, 'WAREHOUSE_ADD') && (
                    <DeleteItemIcon
                      title="Sunteți sigur(ă) că doriți să ștergeți transbordarea?"
                      message="Transbordarea a fost ștersă"
                      item={row.id}
                      action={deleteWarehouse}
                      reload={reload}
                    />
                  )}
                  {hasPermission(permissions, 'CREATE_TRANSACTION') && (
                    <Tooltip title="Crează Rută">
                      <Button
                        shape="circle"
                        type="link"
                        icon={<PlusOutlined />}
                        onClick={() => {
                          createTransaction(row);
                        }}
                      />
                    </Tooltip>
                  )}
                </>
              )}
            </div>
          );
        },
      },
      // Column.actions(t('table.actions'), (record) => (

      // )),
    ],
    [
      // t,
      reload,
      permissions,
      createTransaction,
      countries?.content,
      allowedVehicleCategories,
      prepareEditWarehouseModal,
      transshipment?.vehicleNumber,
      renderGoods,
    ],
  );

  const handleOpenCommentModal = () => {
    setCommentModalVisible(true);
    form.setFieldsValue({
      warehouseReloadingReason: transshipment?.warehouseReloadingReason || '',
    });
  };

  const handleCloseCommentModal = () => {
    setCommentModalVisible(false);
    form.setFieldsValue({
      warehouseReloadingReason: transaction?.warehouseReloadingReason,
    });
  };

  const handleSaveComment = useCallback(
    () => {
      setLoadingLoader(true);
      const data = {
        transshipmentId: transshipment?.id,
        message: form.getFieldValue('warehouseReloadingReason'),
      };
      addWarehouseReloadReason(data)
        .then((res) => {
          if (res) {
            notification.success({
              message: 'Motivul a fost adăugat cu succes',
              duration: 3,
            });
            handleCloseCommentModal();
          } else {
            notification.error({
              message: 'Motivul nu a fost adăugat',
              duration: 3,
            });
          }
        })
        .catch((err) => {
          if (err) {
            notification.error({
              message: 'A apărut o eroare la adăugarea motivului',
            });
          }
        })
        .finally(() => {
          setLoadingLoader(false);
          reload();
        });
    },
    // eslint-disable-next-line
    [reload, form, transaction?.id],
  );

  return (
    <>
      <div className={`loaderOverlay ${loadingLoader ? 'active' : ''}`}>
        <AppLoader />
      </div>
      {!transaction?.id ? <PageHeader title="Transbordare" /> : null}
      <FormContextProvider form={form}>
        <Form form={form} layout="vertical">
          <Row gutter={15}>
            <Col span={6}>
              <Form.Item
                name="plateNumber"
                label={
                  <RequiredLabel title="Numărul auto din care se transbordează" />
                }
                rules={[
                  {
                    min: 2,
                    max: 10,
                    pattern: /^[A-Za-z0-9]*$/,
                    message:
                      'Numărul auto poate să conțină doar litere și cifre.',
                  },
                ]}
              >
                <Input
                  disabled={transaction?.id}
                  style={{ textTransform: 'uppercase' }}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const truncatedValue = inputValue.slice(0, 10);
                    form.setFieldsValue({ plateNumber: truncatedValue });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="goodsWeight"
                label={<RequiredLabel title="Masa încărcăturii (tone)" />}
                rules={[
                  {
                    required: hasGoods,
                    message: 'Câmpul este obligatoriu',
                  },
                ]}
              >
                <Input
                  onChange={handleChange}
                  maxLength={9}
                  disabled={readOnly || editMode || transaction?.id}
                />
              </Form.Item>
            </Col>

            <Button
              type="primary"
              style={{ marginTop: '30px' }}
              onClick={handleOpenCommentModal}
              disabled={readOnly || !transshipment?.id}
              icon={
                transshipment?.warehouseReloadingReason ? (
                  <EyeOutlined />
                ) : (
                  <PlusOutlined />
                )
              }
            >
              {`${
                transshipment?.warehouseReloadingReason
                  ? 'Vizualizare motiv transbordare'
                  : 'Adaugă motiv transbordare'
              } `}
            </Button>
          </Row>
          <Button
            type="primary"
            style={{ marginLeft: 'auto', display: 'block' }}
            onClick={handleOpenTransbordareModal}
            disabled={readOnly}
            icon={<PlusOutlined />}
          >
            Adaugă articol
          </Button>
          <Table
            columns={columns}
            rowKey="id"
            dataSource={transshipment?.warehouseList?.sort(
              (a, b) => b.id - a.id,
            )}
            scroll={{ x: 500 }}
          />
          <Modal
            maskClosable={false}
            centered
            width={800}
            title="Adăugare rută vehicul transbordare"
            visible={transbordareModal}
            onOk={handleAddWarehouse}
            onCancel={handleCancelModal}
            cancelText="Înapoi"
            okText="Salvează"
          >
            <Row justify="space-between" gutter={15}>
              <Col span={8}>
                <Form.Item name="id" style={{ display: 'none' }} />
                <Form.Item
                  label="Nr. auto transbordare"
                  name="reloadPlateNumber"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: 'Câmpul este obligatoriu',
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => {
                      form.setFieldsValue({
                        reloadPlateNumber: maxLengthValidator(
                          e.target.value,
                          10,
                        ),
                      });
                    }}
                    style={{ textTransform: 'uppercase', fontWeight: '600' }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Nr. Caroserie/VIN"
                  name="reloadVinCode"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: 'Campul este obligatoriu',
                      validator: (value) => vinNumberValidator(value),
                    },
                    // {
                    //   min: 17,
                    //   message: 'Minim 17 caractere',
                    // },
                  ]}
                >
                  <Input
                    style={{
                      textTransform: 'uppercase',
                      fontWeight: '600',
                    }}
                    maxLength={17}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Categorie"
                  name="reloadVehicleCategory"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: 'Câmpul este obligatoriu',
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) !== -1
                    }
                  >
                    {allowedVehicleCategories?.map((option) => (
                      <Select.Option key={option.id} value={option.code}>
                        {`${option.code} - ${option.name}`}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between" gutter={15}>
              <Col span={8}>
                <Form.Item
                  label="Cantitatea mărfii transbordate"
                  name="quantity"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: 'Câmpul este obligatoriu',
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => {
                      form.setFieldsValue({
                        quantity: floatValidator(dotValidator(e.target.value)),
                      });
                    }}
                    maxLength={9}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Țara"
                  name="reloadCountryId"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: 'Câmpul este obligatoriu',
                    },
                  ]}
                >
                  <CountrySelect />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Direcție"
                  name="direction"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: 'Câmpul este obligatoriu',
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) !== -1
                    }
                  >
                    {directions.map((track) => (
                      <Select.Option key={track.id} value={track.id}>
                        {track.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Modal>
          <Modal
            maskClosable={false}
            width={500}
            centered
            title="Motiv transbordare"
            visible={commentModalVisible}
            onOk={handleSaveComment}
            onCancel={handleCloseCommentModal}
            okButtonProps={{
              disabled: readOnly,
            }}
            okText="Salvează"
            cancelText="Înapoi"
          >
            <Form.Item
              name="warehouseReloadingReason"
              initialValue={transaction?.warehouseReloadingReason}
              style={{ marginBottom: '25px' }}
            >
              <TextArea
                showCount
                maxLength={1500}
                disabled={readOnly}
                rows={5}
              />
            </Form.Item>
          </Modal>
        </Form>
        <Space style={{ margin: '10px 0' }}>
          <Row style={{ gap: '10px' }}>
            <CancelButton
              disabled={readOnly}
              onCancel={transaction?.id ? moveBack : onCancel}
            />
            {transaction?.id ? (
              <NextButton
                navigateFunc={moveNext}
                transactionId={transaction.id}
                canNavigate
              />
            ) : null}
          </Row>
        </Space>
      </FormContextProvider>
    </>
  );
};

export default Deposit;
