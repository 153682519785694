export const JOB_STATUSES = Object.freeze({
  0: { code: 'WAITING', text: 'În așteptare', order: 0 },
  1: { code: 'PAUSED', text: 'Oprit', order: 1 },
  2: { code: 'RUNNING', text: 'Rulează', order: 2 },
  WAITING: 0,
  PAUSED: 1,
  RUNNING: 2,
});

export const LEGAL_STATE = Object.freeze({
  0: { code: 'ACTIVE', text: 'Activă', order: 0 },
  1: { code: 'SUSPENDED', text: 'Suspendată', order: 1 },
  2: { code: 'INSOLVIBLE', text: 'În proces de insolvabilitate', order: 2 },
  3: { code: 'TO_CLOSE', text: 'În proces de lichidare', order: 3 },
  4: { code: 'CLOSED', text: 'Lichidată', order: 4 },
  ACTIVE: 0,
  SUSPENDED: 1,
  INSOLVIBLE: 2,
  TO_CLOSE: 3,
  CLOSED: 4,
});

export const BUDGET_CODE = Object.freeze({
  1: { code: 'CONSOLIDAT', text: 'Consolidat', order: 1 },
  2: { code: 'STAT', text: 'Bugetul de stat', order: 2 },
  3: {
    code: 'ADMINISTRATIV',
    text: 'Bugetele unităților administrativ-teritoriale',
    order: 3,
  },
  5: {
    code: 'SOCIAL',
    text: 'Bugetul asigurărilor sociale de stat',
    order: 5,
  },
  6: {
    code: 'MEDICAL',
    text: 'Fondurile asigurărilor obligatorii de asistență medicală',
    order: 6,
  },
  8: { code: 'POPULATION', text: 'Fondul de susținere a populației', order: 8 },
  CONSOLIDAT: 1,
  STAT: 2,
  ADMINISTRATIV: 3,
  SOCIAL: 5,
  MEDICAL: 6,
  POPULATION: 8,
});

export const ACTIVITY_TYPE = Object.freeze({
  0: { code: 'NONLICENSED', text: 'Nelicențiată', order: 0 },
  1: { code: 'LICENSED', text: 'Licențiată', order: 1 },
  NONLICENSED: 0,
  LICENSED: 1,
});

export const CRON_OPTIONS = [
  {
    value: '0/10 * * * * ?',
    label: 'O dată la 10 secunde',
    id: 0,
  },
  {
    value: '0 0/1 * * * ?',
    label: 'O dată pe minut',
    id: 1,
  },
  {
    value: '0 0/15 * * * ?',
    label: 'O dată la 15 minute',
    id: 2,
  },
  {
    value: '0 0/30 * * * ?',
    label: 'O dată la 30 minute',
    id: 3,
  },
  {
    value: '0 0 * ? * * *',
    label: 'Fiecare ora',
    id: 4,
  },
  {
    value: '0 0 0/2 ? * *',
    label: 'O dată la 2 ore',
    id: 5,
  },
  {
    value: '0 0 */6 ? * *',
    label: 'O dată la 6 ore',
    id: 6,
  },
  {
    value: '0 0 */12 ? * *',
    label: 'O dată la 12 ore',
    id: 7,
  },
  {
    value: '0 0 22 */1 * ?',
    label: 'O dată pe zi',
    id: 8,
  },
  {
    value: '0 0 22 */2 * ?',
    label: 'O dată la 2 zile',
    id: 9,
  },
  {
    value: '0 0 22 */7 * ?',
    label: 'O dată la 7 zile',
    id: 10,
  },
  {
    value: '0 0 0 1 * ? *',
    label: 'Prima zi din fiecare luna',
    id: 11,
  },
  {
    value: '0 0 0 1 1 ? *',
    label: 'Prima zi din fiecare an',
    id: 12,
  },
  {
    value: '0 0 20 ? * *',
    label: 'Fiecare zi (ora 23:00)',
    id: 13,
  },
];

export const emailRegexPattern =
  "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])";
