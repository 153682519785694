import React, { useMemo, useContext, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@ant-design/pro-layout';
import { Table, Modal, Input, notification, Row, Col } from 'antd';
import { StopOutlined } from '@ant-design/icons';
import useDatasource from '../../../hooks/useDatasource';
import { useTableScroll } from '../../../hooks/useTableScroll';
import Column from '../../../helpers/Columns';
import {
  findAll as findAllUsers,
  remove,
  suspend,
  recoverUser,
  resetPassword,
} from '../../../services/admin/users/users';
import EditItemIcon from '../../buttons/EditItemIcon';
import EditPasswordIcon from '../../buttons/EditPasswordIcon';
import ActionItemIcon from '../../buttons/ActionItemIcon';
import RecoveryItemIcon from '../../buttons/RecoveryItemIcon';
import DeleteItemIcon from '../../buttons/DeleteItemIcon';
import AddEntityButton from '../../buttons/AddEntityButton';
import ViewItemIcon from '../../buttons/ViewItemIcon';
import AuthContext, { hasPermission } from '../../auth';
import { checkPassword } from './paswords';
import UserFunctions from '../../../enums/UserFunctions';

const functions = {
  content: [
    { id: '1', code: UserFunctions.INSPECTOR, name: 'Inspector' },
    { id: '2', code: UserFunctions.HEAD, name: 'Șef Tura' },
    { id: '3', code: UserFunctions.POST_HEAD, name: 'Șef Post Vamal' },
    { id: '4', code: UserFunctions.OFFICE_HEAD, name: 'Șef Birou Vamal' },
    { id: '5', code: UserFunctions.CENTRAL, name: 'Aparat central' },
  ],
};

const UsersList = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { permissions } = user;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newPassword, setNewPassword] = useState(null);
  const [newPasswordConfirm, setNewPasswordConfirm] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  useTableScroll();

  const { loading, pagination, content, handleChange, reload } =
    useDatasource(findAllUsers);

  const handleclick = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setNewPassword(null);
    setNewPasswordConfirm(null);
    setIsModalVisible(false);
    setSelectedUser(null);
  };

  const handlePasswordReset = useCallback(() => {
    const data = {
      password: newPassword,
      repeatPassword: newPasswordConfirm,
    };
    if (!checkPassword(data)) {
      return;
    }
    resetPassword(selectedUser.id, data)
      .then((res) => {
        if (res) {
          notification.success({
            message: 'Parola a fost setată cu succes.',
            duration: 3,
          });
        } else {
          notification.error('A apărut o eroare la setarea parolei');
        }
      })
      .catch((err) => {
        if (err) {
          notification.error('A apărut o eroare la setarea parolei');
        }
      })
      .finally(() => handleCancel());
  }, [selectedUser, newPassword, newPasswordConfirm]);

  const renderPostData = (row) => {
    return row.customsPostList
      ?.sort((a, b) => a.code.localeCompare(b.code))
      ?.map((el) => (
        <span
          key={el.code}
          style={{
            marginBottom: '5px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          ({el.code}) {el.name}
        </span>
      ));
  };

  const columns = useMemo(
    () => [
      Column.text('username', t('entity.admin.user.username'), {
        width: 170,
        filter: true,
        toUpperCase: false,
      }),
      Column.text('fullname', t('entity.admin.user.fullname'), {
        width: 270,
        filter: true,
      }),
      Column.dictionary(
        'function',
        t('entity.admin.user.function'),
        functions,
        {
          width: 120,
          filter: true,
          dictKey: 'code',
        },
      ),
      Column.text('role', t('entity.admin.user.role'), {
        width: 150,
        filter: true,
      }),
      {
        title: t('entity.admin.user.customsPost'),
        key: 'customsPostList',
        sort: true,
        filter: true,
        width: 200,
        centered: true,
        render: (code, row) => {
          return renderPostData(row);
        },
      },
      Column.date('createdAt', t('entity._.createdAt'), {
        width: 150,
      }),
      Column.text('createdBy', t('entity._.createdBy'), {
        width: 150,
      }),
      Column.date('updatedAt', t('entity._.updatedAt'), {
        width: 250,
      }),
      Column.text('updatedBy', t('entity._.updatedBy'), {
        width: 150,
      }),
      Column.bool('deleted', t('entity.admin.user.status'), {
        width: 150,
        inverted: true,
        labels: [
          'Toate',
          t(`entity._.deleted.true`),
          t(`entity._.deleted.false`),
          t('entity.admin.user.suspended'),
        ],
      }),
      Column.bool('hasPassword', 'Parolă', {
        width: 150,
        inverted: false,
        labels: ['?', 'Setată', 'Necesită setare', '?'],
      }),
      Column.actions(t('table.actions'), (record) =>
        record.deleted || record.suspended ? (
          <>
            <span style={{ textAlign: 'right' }}>
              {hasPermission(permissions, 'USERS_USERS_VIEW') && (
                <ViewItemIcon path={`/admin/users/users/${record.id}`} />
              )}
            </span>
            {hasPermission(permissions, 'USERS_USERS_DELETE') && (
              <RecoveryItemIcon
                title={t('entity.admin.user._recovery', record)}
                message={t('entity.admin.user._recovered', record)}
                item={record}
                action={recoverUser}
                reload={reload}
              />
            )}
          </>
        ) : (
          <span style={{ textAlign: 'right' }}>
            {hasPermission(permissions, 'USERS_USERS_EDIT') && (
              <EditItemIcon path={`/admin/users/users/${record.id}`} />
            )}
            {hasPermission(permissions, 'USERS_CHANGE_PASSWORD') && (
              <EditPasswordIcon
                handleClick={() => {
                  handleclick();
                  setSelectedUser(record);
                }}
              />
            )}
            {hasPermission(permissions, 'USERS_USERS_DELETE') && (
              <ActionItemIcon
                tooltip={t('entity.admin.user.suspend')}
                title={t('entity.admin.user._suspend', record)}
                message={t('entity.admin.user._suspended', record)}
                icon={<StopOutlined />}
                item={record}
                action={suspend}
                reload={reload}
              />
            )}
            {hasPermission(permissions, 'USERS_USERS_DELETE') && (
              <DeleteItemIcon
                title={t('entity.admin.user._delete', record)}
                message={t('entity.admin.user._deleted', record)}
                item={record}
                action={remove}
                reload={reload}
              />
            )}
          </span>
        ),
      ),
    ],
    [t, reload, permissions],
  );
  return (
    <>
      <PageHeader
        title={t('entity.admin.user._plural')}
        extra={[
          <AddEntityButton
            key="new"
            entity={t('entity.admin.user._singular')}
            path="/admin/users/users/new"
          />,
        ]}
      />

      <Table
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={pagination}
        dataSource={content?.map((el) => ({
          ...el,
          deleted: el.suspended ? undefined : el.deleted,
        }))}
        onChange={handleChange}
        scroll={{ x: 500 }}
        sticky={{ offsetHeader: 63 }}
      />
      <Modal
        maskClosable={false}
        centered
        style={{ width: '600px' }}
        title={`${t('actions.resetPassword')} pentu "${
          selectedUser?.fullname
        }"`}
        visible={isModalVisible}
        onOk={handlePasswordReset}
        onCancel={handleCancel}
        cancelText="Înapoi"
      >
        <Row style={{ flexDirection: 'column', gap: '10px' }}>
          <Col>
            <span>{t('entity.admin.user.password')}</span>
            <Input.Password
              placeholder="Introdu parola"
              value={newPassword}
              onChange={(evt) => setNewPassword(evt.target.value)}
            />
          </Col>
          <Col>
            <span>{t('entity.admin.user.repeatPassword')}</span>
            <Input.Password
              placeholder="Introdu parola"
              value={newPasswordConfirm}
              onChange={(evt) => setNewPasswordConfirm(evt.target.value)}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default UsersList;
