import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { getPersonByIdnp } from '../../../services/admin/web-services/sfs-organizatii';
import AvatarComponent from '../../AvatarComponent';

const DriverImage = ({ idnp }) => {
  const [loading, setLoading] = useState(false);
  const [base64Image, setBase64Image] = useState(false);

  useEffect(() => {
    if (idnp && idnp.trim().length === 13) {
      setLoading(true);
      getPersonByIdnp(idnp)
        .then((pers) => {
          setBase64Image(pers.photo);
        })
        .finally(() => setLoading(false));
    } else {
      setBase64Image(null);
    }
  }, [idnp]);

  return loading ? (
    <Spin
      style={{
        fontSize: 18,
        margintBottom: '10px',
      }}
      tip=""
    />
  ) : (
    <AvatarComponent base64={base64Image} size={100} radius="10px" preview />
  );
};

export default DriverImage;
