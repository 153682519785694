import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import LocalitiesList from '../../../../components/admin/taxonomies/localities/LocalitiesList';

const Localities = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="localities">
      <Tabs.TabPane tab={t('entity.admin.locality._plural')} key="localities">
        <LocalitiesList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default Localities;
