import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import CancelButton from '../../buttons/BackButton';
import FilesList from '../../FilesList';
import {
  saveTransactioFiles,
  getTransactioFiles,
  removeTransactioFile,
} from '../../../services/admin/transaction-files';
import NextButton from '../../buttons/NextButton';
import ArticleList from './tables/ArticleList';
import MortuaryList from './tables/MortuaryList';

const AditionalData = ({
  transactionId,
  readOnly = false,
  moveNext = () => {},
  moveBack = () => {},
}) => {
  const [fileIdentifierList, setFileIdentifierList] = useState([]);

  useEffect(() => {
    getTransactioFiles(transactionId).then((res) => {
      if (res) {
        const arr = res.map((f) => {
          return f.fileId;
        });
        setFileIdentifierList(arr);
      }
    });
  }, [transactionId]);

  const handleChangeFiles = (files) => {
    const filesNew = [];
    files
      .filter((f) => !fileIdentifierList.find((el) => el === f.id))
      .forEach((file) => {
        filesNew.push(file.id);
        saveTransactioFiles({ fileId: file.id, transactionId });
      });
    fileIdentifierList
      ?.filter((f) => !files.find((el) => el.id === f))
      .forEach((fileId) => {
        const index = fileIdentifierList.indexOf(fileId);
        fileIdentifierList.splice(index, 1);
        removeTransactioFile(fileId);
      });
    setFileIdentifierList([...fileIdentifierList, ...filesNew]);
  };

  return (
    <>
      <Row gutter={15}>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          <ArticleList transactionId={transactionId} readOnly={readOnly} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          {transactionId && (
            <FilesList
              transactionID={transactionId}
              disabled={readOnly}
              onFilesChange={handleChangeFiles}
              identifiers={fileIdentifierList}
            />
          )}
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          <MortuaryList transactionId={transactionId} />
        </Col>
      </Row>
      <Row style={{ gap: '10px' }}>
        <CancelButton disabled={readOnly} onCancel={moveBack} />

        <NextButton
          navigateFunc={moveNext}
          transactionId={transactionId}
          canNavigate
        />
      </Row>
    </>
  );
};

export default AditionalData;
