import {
  genericService,
  get,
  post,
  postWithRawResponse,
  put,
  remove,
} from '../../helpers/api';

export const SERVICE_URI = '/backend';
export const BASE_URI = '/api/v1/transactions';
export const BASE_URI_MESSAGE = '/api/v1/messages';
export const BASE_URI_SEARCH_TRANSACTION = '/search/transaction';
export const BASE_URI_PDF = '/api/v1/pdf';

export const { findAll, findOne, create, update } = genericService(
  SERVICE_URI,
  BASE_URI,
);

export const startControl = (transactionId) =>
  put(`${SERVICE_URI}${BASE_URI}/control/${transactionId}`);

export const cancelDuplicatedTransaction = (transactionId) =>
  put(`${SERVICE_URI}${BASE_URI}/cancel-transaction/${transactionId}`);

export const stopTransactionTime = (message) =>
  put(
    `${SERVICE_URI}${BASE_URI}/stop-transaction/${message.transactionId}`,
    message,
  );

export const retrieveControl = (transactionId) =>
  put(`${SERVICE_URI}${BASE_URI}/retrieve-control/${transactionId}`);

export const addMessage = (message) =>
  post(`${SERVICE_URI}${BASE_URI_MESSAGE}/${message.transactionId}`, message);

export const getMessageDetails = (transactionId) =>
  get(`${SERVICE_URI}${BASE_URI_MESSAGE}/${transactionId}`);

export const addIrregularities = (irr) =>
  post(`${SERVICE_URI}${BASE_URI_MESSAGE}/irr/${irr.transactionId}`, irr);

export const addMdUaComment = (comment) =>
  post(
    `${SERVICE_URI}${BASE_URI_MESSAGE}/mdua-declaration-comment/${comment.transactionId}`,
    comment,
  );

export const addSesizari = (s) =>
  post(`${SERVICE_URI}${BASE_URI_MESSAGE}/sesiz/${s.transactionId}`, s);

export const enableSteps = (id, step) =>
  post(`${SERVICE_URI}${BASE_URI}/enable-steps/${id}/${step}`);

export const confirmStep = (id, step) =>
  post(`${SERVICE_URI}${BASE_URI}/confirm-step/${id}/${step}`);

export const sendWithholdingReason = (id, reason) =>
  post(`${SERVICE_URI}${BASE_URI}/withholding-reason/${id}`, reason);

export const checkDaysInRM = (params) =>
  get(
    `${SERVICE_URI}${BASE_URI}/days-in-rm/${params.id}/${params.licenceCountry}/${params.vehicleCountry}`,
  );

export const removeMessage = (transactionId) =>
  remove(`${SERVICE_URI}${BASE_URI_MESSAGE}/${transactionId}`);

export const generatePdfInNewTab = async (path, params) => {
  const response = await postWithRawResponse(
    `${SERVICE_URI}${BASE_URI_PDF}${path}`,
    params,
    {
      headers: {
        Accept: 'application/octet-stream',
      },
    },
  );
  const responseBlob = await response.blob();
  const blob = new Blob([responseBlob], {
    type: 'application/pdf',
  });
  const objectUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = objectUrl;
  link.setAttribute('download', 'Document.pdf');
  document.body.appendChild(link);
  window.open(window.URL.createObjectURL(blob), 'Document.pdf');
  return responseBlob;
};

export const generatePdfInNewTabMessages = async (path, params) => {
  const response = await postWithRawResponse(
    `${SERVICE_URI}${BASE_URI_MESSAGE}${path}`,
    params,
    {
      headers: {
        Accept: 'application/octet-stream',
      },
    },
  );
  const responseBlob = await response.blob();
  const blob = new Blob([responseBlob], {
    type: 'application/pdf',
  });
  const objectUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = objectUrl;
  link.setAttribute('download', 'Document.pdf');
  document.body.appendChild(link);
  window.open(window.URL.createObjectURL(blob), 'Document.pdf');
  return responseBlob;
};

export const saveBonPlata = (body) =>
  post(`${SERVICE_URI}${BASE_URI}/bon-plata`, body);

export const saveActControl = (body) =>
  post(`${SERVICE_URI}${BASE_URI}/act-control`, body);

export const saveSuspicionFraudReport = (body) =>
  post(
    `${SERVICE_URI}${BASE_URI}/suspicion-fraud-report/${body.transactionId}`,
    body,
  );
