import { getMpass, postMpass } from '../helpers/api';

export const SERVICE_URI = '/auth';
export const BASE_URI = '/v1';
const AUTH_BASE_URI = '/auth/api/v1/auth';

export const getCurrentUserDetails = async () =>
  fetch(`${window._env_.API_BACKEND_URL}${SERVICE_URI}${BASE_URI}/current`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  }).then((resp) => (resp.ok ? resp.json() : Promise.reject(resp)));

export const getCurrentUserDetails2 = async (token) =>
  fetch(`${window._env_.API_BACKEND_URL}${SERVICE_URI}${BASE_URI}/current`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`,
    },
  }).then((resp) => (resp.ok ? resp.json() : Promise.reject(resp)));

export const mpassLogin = async () =>
  getMpass(`${SERVICE_URI}/api${BASE_URI}/auth/mpass`).then((resp) =>
    resp.text(),
  );

export const mpassUserBySession = async (sessionIndex) =>
  getMpass(`${AUTH_BASE_URI}/mpass/auth-details/${sessionIndex}`).then((resp) =>
    resp.text(),
  );

export const mpassLogout = async () =>
  postMpass(`${AUTH_BASE_URI}/mpass/mpass-logout`).then((resp) => resp.text());
