import React, { useContext, useCallback, useState, useEffect } from 'react';
import {
  Button,
  Form,
  Modal,
  Col,
  Input,
  Divider,
  Checkbox,
  DatePicker,
  Select,
  notification,
  Popconfirm,
  Row,
} from 'antd';
import {
  FileAddOutlined,
  RollbackOutlined,
  SaveOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/lib/input/TextArea';
import dayjs from 'dayjs';
import CountrySelect from '../../CountrySelect';
import AuthContext, { hasPermission } from '../../auth';
import AppLoader from '../../auth/AppLoader';
import useDictionaries from '../../../hooks/useDictionaries';
import {
  generatePdfInNewTabMessages,
  addSesizari,
} from '../../../services/transactions/transactions';
import { findAll as findAllCustomsOffices } from '../../../services/admin/users/customs-office';
import {
  phoneValidator,
  numberValidator,
} from '../../../services/utils/validator/Validator';

const dictionariesLocal = {
  sex: [
    { code: 'M', name: 'MASCULIN' },
    { code: 'F', name: 'FEMININ' },
  ],
  docType: [
    { code: 'PASSPORT', name: 'PAȘAPORT' },
    { code: 'IDENTITY_CARD', name: 'BULETIN' },
  ],
};

const dictionaries = {
  customsOffices: findAllCustomsOffices,
};

const SesizariModal = ({
  initialVisible = false,
  transactionId,
  readOnly,
  allowGenerate = false, // only on readOnly mode
  sesizariData,
  person,
  onClose = () => {},
  reload = () => {
    window.location.reload();
  },
}) => {
  const [form] = Form.useForm();
  const [loadingLoader, setLoadingLoader] = useState(false);
  const [sesizariModal, setSesizariModal] = useState(initialVisible);
  const [isPhysicalPerson, setIsPhysicalPerson] = useState(true);
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { permissions } = user;

  const [{ customsOffices }] = useDictionaries(dictionaries);

  useEffect(() => {
    customsOffices?.content?.forEach((element) => {
      const customPost = element?.customsPosts?.find(
        (el) => el.code === user?.customsPost?.code,
      );
      if (customPost) {
        form.setFieldsValue({ nrBirou: element?.code });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customsOffices, user?.customsPost?.code]);

  const handleGeneratePdf = () => {
    setLoadingLoader(true);
    const data = form.getFieldsValue();
    generatePdfInNewTabMessages(
      `/generate/sesizari/${transactionId || sesizariData?.transactionId}`,
      {
        ...data,
        transactionId: transactionId || sesizariData?.transactionId,
        sesizNumber: sesizariData?.sesizNumber,
        postCode: user?.customsPost?.code,
        inspector: user?.fullname,
        isPhysicalPerson,
      },
    )
      .then(() => {
        notification.success({
          message: t('messages.report.generated.success'),
          duration: 3,
        });
        reload();
      })
      .catch((err) => {
        notification.error({ message: err });
      })
      .finally(() => {
        setLoadingLoader(false);
        reload();
      });
  };
  const handleSave = useCallback(() => {
    const data = {
      ...form.getFieldsValue(),
      transactionId: transactionId || sesizariData?.transactionId,
      sesizNumber: sesizariData?.sesizNumber,
      postCode: user?.customsPost?.code,
      inspector: user?.fullname,
      isPhysicalPerson,
    };

    setLoadingLoader(true);
    addSesizari(data)
      .then(() => {
        notification.success({
          message: 'Sesizarea a fost adăugată',
          duration: 3,
        });
        reload();
      })
      .catch(() => {
        form.resetFields();
        notification.error({
          message: 'Sesizarea nu a putut fi adăugată',
        });
      })
      .finally(() => {
        setLoadingLoader(false);
        reload();
      });
  }, [
    form,
    transactionId,
    sesizariData?.sesizNumber,
    sesizariData?.transactionId,
    user?.customsPost?.code,
    user?.fullname,
    isPhysicalPerson,
    reload,
  ]);

  return (
    <>
      <div className={`loaderOverlay ${loadingLoader ? 'active' : ''}`}>
        <AppLoader />
      </div>

      <Form
        form={form}
        layout="vertical"
        initialValues={{
          ...sesizariData,
          // firstName: sesizariData?.firstName
          //   ? sesizariData.firstName
          //   : person?.fullName?.split(' ')[0] || '',
          // lastName: sesizariData?.lastName
          //   ? sesizariData.lastName
          //   : person?.fullName?.split(' ')[1] || '',
          // patronymic: sesizariData?.patronymic
          //   ? sesizariData.patronymic
          //   : person?.fullName?.split(' ')[2] || '',
          idnp: sesizariData?.idnp ? sesizariData.idnp : person?.personalCode,
          sesizareDate: sesizariData?.sesizareDate
            ? dayjs(sesizariData?.sesizareDate)
            : null,
          birthDate: sesizariData?.birthDate
            ? dayjs(sesizariData.birthDate)
            : null,
        }}
      >
        {hasPermission(permissions, 'ADD_IRREGULARITIES') && transactionId && (
          <Col xl={6} xxl={2} style={{ maxWidth: '100%' }}>
            <Button
              icon={<WarningOutlined />}
              type="primary"
              onClick={() => setSesizariModal(true)}
              style={{
                width: '100%',
                marginBottom: '.5rem',
                color: 'white',
              }}
            >
              Sesizări (SZ)
            </Button>
          </Col>
        )}
        <Modal
          maskClosable={false}
          title="SESIZARE"
          width={1300}
          centered
          visible={sesizariModal}
          onCancel={() => {
            setSesizariModal(false);
            onClose();
          }}
          style={{ marginTop: '20px', marginBottom: '20px' }}
          footer={[
            <Button
              key="cancelModal"
              onClick={() => setSesizariModal(false)}
              style={{ float: 'left' }}
              icon={<RollbackOutlined />}
            >
              Înapoi
            </Button>,
            <Popconfirm
              key="generateReportPop"
              disabled={readOnly && !sesizariData?.id && !allowGenerate}
              placement="top"
              title="Sunteți sigur(ă) că doriți sa generați raportul sesizare?"
              onConfirm={handleGeneratePdf}
              okText="Ok"
              cancelText={t('actions.cancel')}
            >
              <Button
                key="generate"
                disabled={readOnly && !sesizariData?.id && !allowGenerate}
                style={{ margin: '0 20px' }}
                icon={<FileAddOutlined />}
              >
                Generează Act
              </Button>
            </Popconfirm>,
            transactionId ? (
              <Popconfirm
                key="confirmSesizari"
                disabled={readOnly}
                className="actions-column"
                placement="top"
                title="Doriți sa salvați o sesizare?"
                onConfirm={handleSave}
                okText="Da"
                cancelText="Nu"
              >
                <Button
                  key="okModal"
                  disabled={readOnly}
                  type="primary"
                  icon={<SaveOutlined />}
                >
                  Salvează
                </Button>
              </Popconfirm>
            ) : null,
          ]}
        >
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                name="nrBirou"
                label="Nr.Birou"
                labelCol={{ span: 24 }}
              >
                <Input disabled maxLength={150} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="inspector"
                label="Agent constatator"
                initialValue={user?.fullname}
                labelCol={{ span: 24 }}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="agentFunction"
                label="Funcția Agent"
                labelCol={{ span: 24 }}
              >
                <Input disabled={readOnly} maxLength={255} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="authorityName"
                label="Denumirea autorităţii agentului constatator"
                labelCol={{ span: 24 }}
              >
                <Input disabled={readOnly} maxLength={255} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="authorityAddress"
                label="Adresa juridică autorităţii agentului constatator"
                labelCol={{ span: 24 }}
              >
                <Input disabled={readOnly} maxLength={255} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="founded"
                label="A constatat că"
                labelCol={{ span: 24 }}
              >
                <Input disabled={readOnly} maxLength={255} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8} style={{ paddingRight: '10px' }}>
              <Form.Item valuePropName="checked">
                <Checkbox
                  onChange={(e) => {
                    setIsPhysicalPerson(e.target.checked);
                  }}
                  defaultChecked="false"
                >
                  Persoana fizică
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          {isPhysicalPerson && (
            <>
              <Divider>Persoana fizică</Divider>
              <Row gutter={20}>
                <Col span={6}>
                  <Form.Item
                    name="firstName"
                    label="Numele"
                    labelCol={{ span: 24 }}
                  >
                    <Input disabled={readOnly} maxLength={50} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="lastName"
                    label="Prenumele"
                    labelCol={{ span: 24 }}
                  >
                    <Input disabled={readOnly} maxLength={50} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="patronymic"
                    label="Patronimic"
                    labelCol={{ span: 24 }}
                  >
                    <Input disabled={readOnly} maxLength={50} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="birthDate"
                    label="Data naşterii"
                    labelCol={{ span: 24 }}
                  >
                    <DatePicker disabled={readOnly} format="DD.MM.YYYY" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col span={6}>
                  <Form.Item
                    name="birthLocation"
                    label="Locul naşterii"
                    labelCol={{ span: 24 }}
                  >
                    <Input disabled={readOnly} maxLength={50} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="citizenship"
                    label="Cetăţenia"
                    labelCol={{ span: 24 }}
                  >
                    <Input disabled={readOnly} maxLength={50} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="sex" label="Sexul" labelCol={{ span: 24 }}>
                    <Select
                      allowClear
                      disabled={readOnly}
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) !== -1
                      }
                    >
                      {dictionariesLocal.sex.map((option) => (
                        <Select.Option key={option.code} value={option.code}>
                          {`(${option.code}) ${option.name}`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="docType"
                    label="Tipul documentului"
                    labelCol={{ span: 24 }}
                  >
                    <Select
                      allowClear
                      disabled={readOnly}
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) !== -1
                      }
                    >
                      {dictionariesLocal.docType.map((option) => (
                        <Select.Option key={option.code} value={option.code}>
                          {`${option.name}`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col span={6}>
                  <Form.Item
                    name="docSeries"
                    label="Seria"
                    labelCol={{ span: 24 }}
                  >
                    <Input disabled={readOnly} maxLength={10} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="docNumber"
                    label="Număr"
                    labelCol={{ span: 24 }}
                  >
                    <Input disabled={readOnly} maxLength={20} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="idnp" label="IDNP" labelCol={{ span: 24 }}>
                    <Input disabled style={{ textTransform: 'uppercase' }} />
                  </Form.Item>
                </Col>
              </Row>
              <Divider>Viza de reşedinţă</Divider>
              <Row gutter={20}>
                <Col span={8}>
                  <Form.Item
                    name="residentCountryId"
                    label="Țara"
                    labelCol={{ span: 24 }}
                  >
                    <CountrySelect disabled={readOnly} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="residentLocality"
                    label="Localitatea"
                    labelCol={{ span: 24 }}
                  >
                    <Input disabled={readOnly} maxLength={50} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="residentStreet"
                    label="Strada"
                    labelCol={{ span: 24 }}
                  >
                    <Input disabled={readOnly} maxLength={50} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="residentBuilding"
                    label="Număr bloc/casă"
                    labelCol={{ span: 24 }}
                  >
                    <Input disabled={readOnly} maxLength={50} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="residentApartment"
                    label="Apartament"
                    labelCol={{ span: 24 }}
                  >
                    <Input disabled={readOnly} maxLength={10} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="residentPhone"
                    label="Telefon"
                    labelCol={{ span: 24 }}
                  >
                    <Input
                      disabled={readOnly}
                      onChange={(e) => {
                        form.setFieldsValue({
                          residentPhone: phoneValidator(e.target.value, 20),
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Divider>Domiciliul</Divider>
              <Row gutter={20}>
                <Col span={8}>
                  <Form.Item
                    name="homeCountryId"
                    label="Țara"
                    labelCol={{ span: 24 }}
                  >
                    <CountrySelect disabled={readOnly} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="homeLocality"
                    label="Localitatea"
                    labelCol={{ span: 24 }}
                  >
                    <Input disabled={readOnly} maxLength={50} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="homeStreet"
                    label="Strada"
                    labelCol={{ span: 24 }}
                  >
                    <Input disabled={readOnly} maxLength={50} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="homeBuilding"
                    label="Număr bloc/casă"
                    labelCol={{ span: 24 }}
                  >
                    <Input disabled={readOnly} maxLength={50} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="homeApartment"
                    label="Apartament"
                    labelCol={{ span: 24 }}
                  >
                    <Input disabled={readOnly} maxLength={10} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="homePhone"
                    label="Telefon"
                    labelCol={{ span: 24 }}
                  >
                    <Input
                      disabled={readOnly}
                      onChange={(e) => {
                        form.setFieldsValue({
                          homePhone: phoneValidator(e.target.value, 20),
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Divider>Locul de muncă</Divider>
              <Row gutter={20}>
                <Col span={8}>
                  <Form.Item
                    name="workPlace"
                    label="Locul de muncă"
                    labelCol={{ span: 24 }}
                  >
                    <Input disabled={readOnly} maxLength={50} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="workFunction"
                    label="Funcţia"
                    labelCol={{ span: 24 }}
                  >
                    <Input disabled={readOnly} maxLength={50} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="workPhone"
                    label="Telefon"
                    labelCol={{ span: 24 }}
                  >
                    <Input
                      disabled={readOnly}
                      onChange={(e) => {
                        form.setFieldsValue({
                          workPhone: phoneValidator(e.target.value, 20),
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          <Divider>Persoana juridică/ persoana fizică </Divider>
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item
                name="companyName"
                label="Denumirea persoanei"
                labelCol={{ span: 24 }}
              >
                <Input disabled={readOnly} maxLength={100} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="companyAddress"
                label="Adresa"
                labelCol={{ span: 24 }}
              >
                <Input disabled={readOnly} maxLength={150} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="companyCode"
                label="Codul fiscal"
                labelCol={{ span: 24 }}
                maxLength={13}
                rules={[
                  {
                    min: 13,
                    message: 'Minim 13 caractere',
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    form.setFieldsValue({
                      companyCode: numberValidator(e.target.value, 13),
                    });
                  }}
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                name="sesizareDate"
                label="Timpul săvârșirii faptei contravenționale"
                labelCol={{ span: 24 }}
              >
                <DatePicker
                  disabled={readOnly}
                  showTime
                  format="DD.MM.YYYY HH:mm"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="sesizarePlace"
                label="Locul săvârșirii faptei contravenționale"
                labelCol={{ span: 24 }}
              >
                <Input disabled={readOnly} maxLength={100} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="sesizareText"
                label="A săvârșit fapta"
                labelCol={{ span: 24 }}
              >
                <TextArea
                  showCount
                  maxLength={3000}
                  rows={3}
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
          </Row>
        </Modal>
      </Form>
    </>
  );
};

export default SesizariModal;
