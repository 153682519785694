import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Input, Row, Col, Space, notification } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { resetPassword } from '../../../services/admin/users/users';
import SaveButton from '../../buttons/SaveButton';
import CancelButton from '../../buttons/BackButton';
import useFormErrors from '../../../hooks/useFormErrors';
import { checkPassword } from './paswords';
import AppLoader from '../../auth/AppLoader';

const EditUserPasswordForm = ({ user, errors }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [loadingLoader, setLoadingLoader] = useState(false);

  const [form] = Form.useForm();
  useFormErrors(form, errors);

  const handleSubmit = useCallback(() => {
    const currentPassword = form.getFieldValue('currentPassword');
    const password = form.getFieldValue('password');
    const repeatPassword = form.getFieldValue('repeatPassword');

    const data = {
      currentPassword,
      password,
      repeatPassword,
    };

    if (!checkPassword(data)) {
      return;
    }
    setLoadingLoader(true);
    resetPassword(`by-user/${user.id}`, data)
      .then((res) => {
        if (res) {
          notification.success({
            message: 'Parola a fost setată cu succes.',
            duration: 3,
          });
          history.push('/');
        } else {
          notification.error({
            message: 'Ați introdus greșit parola curentă',
          });
        }
      })
      .catch((err) => {
        if (err) {
          notification.error({
            message: 'A apărut o eroare la setarea parolei',
          });
        }
      })
      .finally(() => setLoadingLoader(false));
  }, [form, user, history]);

  // const handleSubmit2 = () => {
  //   history.push(`/home`);
  // };

  const handleCancel = () => {
    history.push(`/home`);
  };

  return (
    <>
      <div className={`loaderOverlay ${loadingLoader ? 'active' : ''}`}>
        <AppLoader />
      </div>
      <PageHeader title="Modificarea parolei" />

      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={8}>
            <Form.Item
              label={t('entity.admin.user.currentPassword')}
              name="currentPassword"
              rules={[
                {
                  required: true,
                  message: t(
                    `md.customs.frontiera.servicecore.validation.constraints.RequiredIfFieldEquals.message`,
                  ),
                },
              ]}
            >
              <Input.Password disabled={user.deleted && user.id} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={8}>
            <Form.Item
              label={t('entity.admin.user.password')}
              name="password"
              rules={[
                {
                  required: true,
                  message: t(
                    `md.customs.frontiera.servicecore.validation.constraints.RequiredIfFieldEquals.message`,
                  ),
                },
              ]}
            >
              <Input.Password disabled={user.deleted && user.id} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={8}>
            <Form.Item
              label={t('entity.admin.user.repeatPassword')}
              name="repeatPassword"
              rules={[
                {
                  required: true,
                  message: t(
                    `md.customs.frontiera.servicecore.validation.constraints.RequiredIfFieldEquals.message`,
                  ),
                },
              ]}
            >
              <Input.Password disabled={user.deleted && user.id} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Space>
            <SaveButton disabled={user.deleted} buttonText="Salvare parolă" />
            <CancelButton onCancel={handleCancel} />
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default EditUserPasswordForm;
