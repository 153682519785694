import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Row, Col, Space, Input } from 'antd';

import { PageHeader } from '@ant-design/pro-layout';
import TextArea from 'antd/lib/input/TextArea';

import SaveButton from '../../../buttons/SaveButton';
import CancelButton from '../../../buttons/BackButton';

import useFormErrors from '../../../../hooks/useFormErrors';
import { useTableScroll } from '../../../../hooks/useTableScroll';
import { maxLengthValidator } from '../../../../services/utils/validator/Validator';

const EditReturnReasonForm = ({ post, onSubmit, onCancel, errors }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  useFormErrors(form, errors);
  useTableScroll();
  useEffect(() => {
    form.setFieldsValue(post);
  }, [post, form]);

  return (
    <>
      <PageHeader title={t('entity.admin.returnReasons._singular')} />
      <Form
        form={form}
        layout="vertical"
        initialValues={post}
        onFinish={onSubmit}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label={t('entity.admin.returnReasons.code')} name="code">
              <Input
                disabled={post.deleted}
                onChange={(e) => {
                  form.setFieldsValue({
                    code: maxLengthValidator(e.target.value, 3),
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label={t('entity.admin.returnReasons.reason')}
              name="reason"
            >
              <TextArea showCount maxLength={2000} disabled={post.deleted} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Space>
            <SaveButton disabled={post.deleted} />
            <CancelButton onCancel={onCancel} />
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default EditReturnReasonForm;
