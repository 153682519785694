import React, { useState, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Form,
  Select,
  DatePicker,
  notification,
  Button,
  Checkbox,
  Input,
  Row,
  Col,
  Divider,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { CopyOutlined } from '@ant-design/icons';
import CustomsPostSelect from '../../CustomsPostSelect';
import { copyTransaction } from '../../../services/admin/web-services/backend';
import AuthContext, { hasPermission } from '../../auth';
import RequiredLabel from '../../../services/utils/RequiredLabel';
import { maxLengthValidator } from '../../../services/utils/validator/Validator';

const CopyTransactionModal = ({
  setLoadingLoader,
  reloadTimelines,
  getTransactionData,
  directions,
  isGeneralData,
}) => {
  const [copyTransactionModal, setCopyTransactionModal] = useState(false);
  const [foreignPostType, setForeignPostType] = useState(false);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { user } = useContext(AuthContext);
  const { permissions } = user || { permissions: [] };

  const handleCopyTransactionModal = () => {
    setCopyTransactionModal((v) => !v);
    form.resetFields();
  };

  const handleCopyTransaction = useCallback(() => {
    let success = true;
    if (!form.getFieldValue('copyTransactionDirection')) {
      notification.error({ message: 'Indicați direția' });
      success = false;
    }

    if (
      !form.getFieldValue('copyTransactionCustomPostId') &&
      !form.getFieldValue('copyTransactionForeignPost')
    ) {
      notification.error({ message: 'Indicați postul vamal' });
      success = false;
    }

    if (!form.getFieldValue('copyTransactionCrossingDate')) {
      notification.error({ message: 'Indicați data traversării' });
      success = false;
    }
    if (!success) {
      return;
    }

    // skip validation === true
    const additionalCopyData = getTransactionData(true);
    if (!additionalCopyData?.vehicles) {
      notification.error({ message: 'Ruta nu a putut fi validată' });
      return;
    }
    // clear containers
    additionalCopyData.vehicles = additionalCopyData.vehicles.map((vehicle) => {
      if (
        vehicle.container1PlateNumber ||
        vehicle.container2PlateNumber ||
        vehicle.container3PlateNumber
      ) {
        const {
          container1PlateNumber,
          container2PlateNumber,
          container3PlateNumber,
          ...rest
        } = vehicle;
        return rest;
      }
      return vehicle;
    });
    const date = new Date(form.getFieldValue('copyTransactionCrossingDate'));

    additionalCopyData.direction = form.getFieldValue(
      'copyTransactionDirection',
    );
    additionalCopyData.statusDate = new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
      ),
    ).toISOString();
    additionalCopyData.controlDate = additionalCopyData.statusDate;
    additionalCopyData.postId = form.getFieldValue(
      'copyTransactionCustomPostId',
    );
    additionalCopyData.foreignPost = form.getFieldValue(
      'copyTransactionForeignPost',
    );

    additionalCopyData.copyLetter = {
      number: form.getFieldValue('letterNumber'),
      date: form.getFieldValue('date'),
      reason: form.getFieldValue('reason'),
    };

    setLoadingLoader(true);
    copyTransaction(additionalCopyData)
      .then((res) => {
        if (res.id) {
          notification.success({ message: 'Datele au fost salvate cu succes' });
          setCopyTransactionModal(false);
        } else {
          notification.error({ message: 'Datele nu au fost salvate' });
        }
      })
      .catch(() =>
        notification.error({
          message: 'A apărut o eroare la salvarea datelor',
        }),
      )
      .finally(() => {
        setLoadingLoader(false);
        reloadTimelines();
      });
    // eslint-disable-next-line
  }, [form, getTransactionData, reloadTimelines]);

  const handleChangePostType = (e) => {
    setForeignPostType(e.target.checked);
    form.setFieldsValue({ copyTransactionForeignPost: '' });
    form.setFieldsValue({ copyTransactionCustomPostId: '' });
  };

  return (
    <>
      {!isGeneralData && hasPermission(permissions, 'COPY_TRANSACTION') ? (
        <Button
          onClick={() => setCopyTransactionModal(true)}
          icon={<CopyOutlined />}
          className="btn__retur"
          style={{
            marginLeft: '20px',
            background: '#e74c3c',
            marginTop: '20px',
            border: '1px solid #e74c3c',
          }}
        >
          Închiderea declarării prin acțiune
        </Button>
      ) : null}
      <Form form={form}>
        <Modal
          maskClosable={false}
          centered
          width={600}
          title={<Divider>Traversare</Divider>}
          visible={copyTransactionModal}
          onOk={handleCopyTransaction}
          onCancel={handleCopyTransactionModal}
          cancelText="Înapoi"
        >
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                label="Direcție"
                name="copyTransactionDirection"
                initialValue="OUT"
                labelCol={{ span: 24 }}
              >
                <Select disabled allowClear style={{ width: '100%' }}>
                  {directions?.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {`${option.name}`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<RequiredLabel title="Data traversării" />}
                name="copyTransactionCrossingDate"
                labelCol={{ span: 24 }}
              >
                <DatePicker
                  showTime
                  format="DD.MM.YYYY HH:mm"
                  placeholder="Selectați data"
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="foreignPost" valuePropName="checked">
            <Checkbox defaultChecked="false" onChange={handleChangePostType}>
              Post vamal străin
            </Checkbox>
          </Form.Item>
          <Form.Item
            name="copyTransactionCustomPostId"
            labelCol={{ span: 24 }}
            hidden={foreignPostType}
            label={<RequiredLabel title="Post Vamal" />}
          >
            <CustomsPostSelect />
          </Form.Item>
          <Form.Item
            name="copyTransactionForeignPost"
            labelCol={{ span: 24 }}
            hidden={!foreignPostType}
            label={<RequiredLabel title="Post Vamal" />}
          >
            <Input
              placeholder="Denumire post vamal"
              onChange={(e) => {
                form.setFieldsValue({
                  copyTransactionForeignPost: maxLengthValidator(
                    e.target.value,
                    250,
                  ),
                });
              }}
            />
          </Form.Item>

          <Divider plain>
            <div>Scrisoare/ PV</div>
          </Divider>

          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                name="letterNumber"
                labelCol={{ span: 24 }}
                label={
                  <RequiredLabel
                    title={t('entity.daysCorrection.letterNumber')}
                  />
                }
              >
                <Input
                  onChange={(e) => {
                    form.setFieldsValue({
                      letterNumber: maxLengthValidator(e.target.value, '30'),
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="date"
                labelCol={{ span: 24 }}
                style={{ width: '100%' }}
                label={
                  <RequiredLabel title={t('entity.daysCorrection.date')} />
                }
              >
                <DatePicker format="DD.MM.YYYY" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="reason"
                label={
                  <RequiredLabel title={t('entity.daysCorrection.reason')} />
                }
                labelCol={{ span: 24 }}
              >
                <TextArea showCount maxLength={2000} />
              </Form.Item>
            </Col>
          </Row>
        </Modal>
      </Form>
    </>
  );
};

export default CopyTransactionModal;
