import React, { useState, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { notification, Button, Modal } from 'antd';
import { CameraOutlined, PlusOutlined } from '@ant-design/icons';
import QRCode from 'react-qr-code';
import AuthContext, { hasPermission } from './auth';
import { uploadFiles } from '../services/admin/files';
import './FileUploader.scss';
import AppLoader from './auth/AppLoader';

const FileUploader = ({
  disabled = false,
  onUploadComplete = null,
  getQrAPI = null,
  children,
  accept,
  uploadFilesFunc = uploadFiles,
  multiple = true,
  enableQR = false,
  icon = null,
  title = null,
}) => {
  const limitFileSize = parseInt(window._env_.FILE_SIZE_LIMIT_BYTES, 10);
  const { t } = useTranslation();
  const [loadingLoader, setLoadingLoader] = useState(false);
  const [qrValue, setQrValue] = useState(null);
  const [openQRcodePopup, setOpenQRcodePopup] = useState(false);

  const { user } = useContext(AuthContext);
  const { permissions } = user;

  const getQrValue = () => {
    if (getQrAPI) {
      getQrAPI()
        .then((res) => {
          setQrValue(res);
          setOpenQRcodePopup(true);
        })
        .catch(() =>
          notification.error({
            message: 'A apărut o eroare la generarea QR-codului',
          }),
        );
    }
  };

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    accept,
    multiple,
    onDrop: (val) => {
      setLoadingLoader(true);
      if (disabled) {
        return;
      }

      uploadFilesFunc(val)
        .then((res) => {
          if (typeof onUploadComplete === 'function') {
            onUploadComplete(res);
          }
        })
        .catch((err) => {
          notification.error({
            message: err?.inner?._ ? err.inner._ : err.statusText,
          });
        })
        .finally(() => {
          setLoadingLoader(false);
        });
    },

    validator: (file) => {
      if (file.size > limitFileSize) {
        notification.error(
          t('file.size', {
            fileName: file.name,
            limitSize: Math.ceil(limitFileSize / 1024 ** 2),
          }),
        );
      }
    },

    maxSize: limitFileSize,
  });

  return (
    <>
      <div className={`loaderOverlay ${loadingLoader ? 'active' : ''}`}>
        <AppLoader />
      </div>

      <div style={{ display: 'flex', gap: '20px' }}>
        {enableQR ? (
          <>
            {hasPermission(permissions, 'FILES_MANAGEMENT') && (
              <Button
                disabled={disabled}
                key="imageButton"
                icon={<CameraOutlined />}
                type="primary"
                onClick={() => getQrValue()}
              >
                Adaugă fișiere prin intermediul unui dispozitiv mobil
              </Button>
            )}
            <Modal
              maskClosable={false}
              centered
              visible={openQRcodePopup}
              onOk={() => setOpenQRcodePopup(!openQRcodePopup)}
              onCancel={() => setOpenQRcodePopup(!openQRcodePopup)}
              className="popup__qrcode"
              footer="Codul este valabil 5 minute"
            >
              <h2 style={{ marginBottom: '20px' }}>
                Scaneaza QR codul pentru a adauga imagini
              </h2>
              <QRCode
                className="qrcode"
                bgColor="transparent"
                fgColor={
                  localStorage.getItem('them') === 'dark' ? '#CCCCCC' : '#000'
                }
                value={`${window.location.origin}/QRCodeServices/uuid=${qrValue?.uuid}`}
              />
            </Modal>
          </>
        ) : null}

        <div
          {...getRootProps({
            onClick: (event) => event.stopPropagation(),
          })}
          className={isDragActive ? 'drop-zone' : ''}
          style={{ outline: 0 }}
        >
          <div className="drop-zone__message">Drop you files here</div>
          <input {...getInputProps()} />
          {hasPermission(permissions, 'FILES_MANAGEMENT') && (
            <Button
              disabled={disabled}
              key="fileButton"
              icon={icon || <PlusOutlined />}
              type="primary"
              onClick={open}
              style={{ float: 'right', marginLeft: 'auto' }}
            >
              {title || 'Adaugă documente'}
            </Button>
          )}

          {children}
        </div>
      </div>
    </>
  );
};

export default FileUploader;
