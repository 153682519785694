import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeader } from '@ant-design/pro-layout';
import AddEntityButton from '../../../components/buttons/AddEntityButton';
import DaysCorrectionsList from '../../../components/admin/daysCorections/DaysCorrectionsList';
import AuthContext, { hasPermission } from '../../../components/auth';

const DaysCorrections = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { permissions } = user;
  return (
    <>
      <PageHeader
        title={t('entity.daysCorrection._plural')}
        extra={[
          hasPermission(permissions, 'CORRECT_DAYS_EDIT') && (
            <AddEntityButton
              key="new"
              entity={t('entity.daysCorrection._singular')}
              path="/correct-days/new"
            />
          ),
        ]}
      />
      <DaysCorrectionsList />
    </>
  );
};

export default DaysCorrections;
