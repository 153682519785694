import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Row, Table } from 'antd';
import useDatasource from '../../../hooks/useDatasource';
import { findAll } from '../../../services/lucrative/special-services-request';
import { useTableScroll } from '../../../hooks/useTableScroll';
import Column from '../../../helpers/Columns';
import ViewItemIcon from '../../buttons/ViewItemIcon';
import CustomsPostSelect from '../../CustomsPostSelect';
import UserFunctions from '../../../enums/UserFunctions';
import AuthContext from '../../auth';
import { findAll as findAllCustomsOffices } from '../../../services/admin/users/customs-office';
import useDictionaries from '../../../hooks/useDictionaries';
import SERVICE_REQUEST_STATUS from '../../../enums/SpecialServiceRequestStatus';

const dictionaries = {
  customsOffices: findAllCustomsOffices,
};

const SpecialServiceRequestsList = () => {
  const { user } = useContext(AuthContext);
  const [{ customsOffices }, , loadingDictionary] =
    useDictionaries(dictionaries);
  const [postCode, setPostCode] = useState(null);

  const getPostCode = useCallback(() => {
    if (postCode) {
      return [postCode];
    }
    if (user?.officeId) {
      return customsOffices?.content
        ?.find((el) => el.id === user?.officeId)
        ?.customsPosts?.map((el) => el.code);
    }
    if (user?.posts?.length > 0) {
      return user.posts?.map((el) => el.code);
    }
    return [user?.customsPost?.code];
  }, [customsOffices?.content, postCode, user]);

  const handler = useCallback(
    (...params) => {
      const spec = params[0];
      if (!spec.sort) {
        spec.sort = ['id', 'desc'];
      }
      spec.criterias = {
        ...spec.criterias,
        postCode: getPostCode(),
      };

      return findAll(spec);
    },
    [getPostCode],
  );

  const { loading, pagination, content, handleChange, reload } = useDatasource(
    handler,
    { allowFetcher: !loadingDictionary },
  );

  useTableScroll();

  const columns = useMemo(
    () => [
      Column.other(
        'status',
        'Statut',
        (code, row) => {
          const requestStatusName = () => {
            switch (row.status) {
              case SERVICE_REQUEST_STATUS.APPROVED:
                return 'Aprobat';
              case SERVICE_REQUEST_STATUS.REJECTED:
                return 'Respins';
              case SERVICE_REQUEST_STATUS.FINALIZED:
                return 'Finalizat';
              default:
                return SERVICE_REQUEST_STATUS.DRAFT;
            }
          };
          const requestStatus = () => {
            return (
              <div className="declaration__status">
                <span
                  className={`declaration__status-indicator ${row?.status?.toLowerCase()}`}
                />
                <div>{requestStatusName()}</div>
              </div>
            );
          };
          return <>{requestStatus()}</>;
        },
        {
          width: 130,
          align: 'center',
        },
      ),
      Column.text('id', 'ID', {
        filter: true,
        width: 100,
      }),
      Column.text('seqNumber', 'Numar document', {
        filter: true,
        width: 150,
      }),
      Column.date('applicationDateTime', 'Data de aplicare', {
        width: 150,
        format: 'DD.MM.YY HH:mm:ss',
      }),
      Column.text('declarantName', 'Numele declarantului', {
        width: 250,
        filter: true,
      }),
      Column.text('declarantSurname', 'Prenumele declarantului', {
        width: 250,
        filter: true,
      }),
      // IDNO/ EORI
      Column.text('declarantIdnoEori', 'IDNP/EORI', {
        width: 250,
        filter: true,
      }),
      Column.text('mrn', 'MRN', {
        width: 250,
        filter: true,
      }),
      Column.actions('Acțiune', (record) => (
        <>
          <ViewItemIcon
            path={`/lucrative/special-services-requests/${record.id}`}
          />
        </>
      )),
    ],
    [],
  );

  return (
    <>
      <Row justify="end">
        {user?.function === UserFunctions.OFFICE_HEAD ||
        user.function === UserFunctions.CENTRAL ||
        user.posts?.length > 1 ? (
          <CustomsPostSelect
            width="400px"
            keys="code"
            value={postCode || user?.customsPost?.code}
            onChange={(code) => {
              setPostCode(code);
              reload();
            }}
            officeId={user?.officeId}
            allowedPostIds={user?.posts?.map((el) => el.id)}
          />
        ) : null}
      </Row>
      <Table
        loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        columns={columns}
      />
    </>
  );
};

export default SpecialServiceRequestsList;
