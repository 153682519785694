import React from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import ChatList from '../../components/admin/ChatList';

const Chats = () => {
  return (
    <>
      <PageHeader title="Conversații" />
      <ChatList />
    </>
  );
};

export default Chats;
