import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import ReturnReasonsList from '../../../../components/admin/taxonomies/returnReasons/ReturnReasonsList';

const ReturnReasons = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="returnReasons">
      <Tabs.TabPane
        tab={t('entity.admin.returnReasons._plural')}
        key="returnReasons"
      >
        <ReturnReasonsList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default ReturnReasons;
