import { notification } from 'antd';

export const validateLegalPersonRisk = (form) => {
  if (!form.getFieldValue('idno')) {
    notification.warning({
      message: 'Completați câmpul IDNO',
    });
    return false;
  }

  if (!form.getFieldValue('name')) {
    notification.warning({
      message: 'Completați câmpul Denumire',
    });
    return false;
  }
  return true;
};
