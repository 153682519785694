import React from 'react';

import { useEditPage } from '../../../hooks/useEditPage';

import { findOne, create, update } from '../../../services/admin/users/users';

import EditUserForm from '../../../components/admin/users/EditUserForm';
import AppLoader from '../../../components/auth/AppLoader';

const initial = {};

const EditUser = () => {
  const [user, handleSubmit, handleCancel, errors, loading] = useEditPage({
    initial,
    existent: findOne,
    goBackPath: '/admin/users',
    pushBackOnError: false,
    onCreate: create,
    onUpdate: update,
  });

  return (
    <>
      <div className={`loaderOverlay ${loading ? 'active' : ''}`}>
        <AppLoader />
      </div>
      {user !== undefined && (
        <EditUserForm
          user={user}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          errors={errors}
        />
      )}
    </>
  );
};

export default EditUser;
