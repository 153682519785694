import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Select,
  Switch,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { RightCircleOutlined } from '@ant-design/icons';
import { FormContextProvider } from '../../hooks/useFormContext';
import { addScale } from '../../services/admin/web-services/scale-transactions';
import { getWeighingDetails } from '../../services/admin/web-services/weighing';
import AuthContext, { hasPermission } from '../auth/index';
import useFormErrors from '../../hooks/useFormErrors';
import {
  confirmStep,
  enableSteps,
} from '../../services/transactions/transactions';
import AppLoader from '../auth/AppLoader';
import useDictionaries from '../../hooks/useDictionaries';
import { findAll as findAllConstants } from '../../services/taxonomies/constants';
import CancelButton from '../buttons/BackButton';
import {
  dotValidator,
  floatValidator,
  numberValidator,
} from '../../services/utils/validator/Validator';
import {
  convertKilosToTons,
  convertTonesToKilos,
} from '../../services/utils/convertors/weigth-converter';
import TransactionStatus from '../../enums/TransactionStatus';

const { Text } = Typography;

const dictionaries = {
  constants: findAllConstants,
};

const scaleTypes = {
  content: [
    { code: 'STATIC', name: 'STATIC' },
    { code: 'DYNAMIC', name: 'DINAMIC' },
  ],
};

const Weighing = ({
  transaction,
  errors,
  skipTab,
  readOnly,
  reload,
  setIsWeighingAmount,
  moveNext = () => {},
  moveBack = () => {},
}) => {
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { permissions } = user;
  const [loadingLoader, setLoadingLoader] = useState(false);
  const [numberAxis, setNumberAxis] = useState();
  const [scaleType, setScaleType] = useState(transaction?.scale?.scaleType);
  const [scaleDTO, setScaleDTO] = useState({});
  const [isOverWeight, setOverWeight] = useState();

  const [{ constants }] = useDictionaries(dictionaries);

  const normalizeScaleWheights = useCallback((scale) => {
    const scaleConverted = { ...scale };
    scaleConverted.vehicleWeight = convertKilosToTons(scale.vehicleWeight);
    scaleConverted.goodsWeight = convertKilosToTons(scale.goodsWeight);
    scaleConverted.totalWeight = convertKilosToTons(scale.totalWeight);
    scaleConverted.scaleWeight = convertKilosToTons(scale.scaleWeight);
    scaleConverted.axis0 = convertKilosToTons(scale.axis0);
    scaleConverted.axis1 = convertKilosToTons(scale.axis1);
    scaleConverted.axis2 = convertKilosToTons(scale.axis2);
    scaleConverted.axis3 = convertKilosToTons(scale.axis3);
    scaleConverted.axis4 = convertKilosToTons(scale.axis4);
    scaleConverted.axis5 = convertKilosToTons(scale.axis5);
    scaleConverted.europeanDocDate = scale.europeanDocDate
      ? dayjs(scale.europeanDocDate)
      : undefined;

    setScaleDTO(scaleConverted);
  }, []);

  const antaNavigateWithoutSave = () => {
    moveNext();
  };

  const extractNumber = (string) => {
    const numberRegex = /-?\d+/g;
    const numbers = string?.match(numberRegex);
    return numbers ? numbers.map(Number) : [];
  };

  useEffect(() => {
    if (transaction?.scale) {
      normalizeScaleWheights(transaction?.scale);
    }
  }, [normalizeScaleWheights, transaction?.scale]);

  useEffect(() => {
    if (transaction?.scale) {
      const {
        allowedLength,
        allowedWidth,
        allowedHeight,
        length,
        width,
        height,
      } = transaction.scale;

      const overWeight = [
        allowedLength,
        allowedWidth,
        allowedHeight,
        length,
        width,
        height,
      ].some((value) => value !== null && value !== undefined);
      setOverWeight(overWeight);
    } else {
      setOverWeight(false);
    }
  }, [transaction.scale]);

  useFormErrors(form, errors);

  const calculateAbsoluteAndPercentageDifference = useCallback(() => {
    const totalWeight = form.getFieldValue('totalWeight');
    const scaleWeight = form.getFieldValue('scaleWeight');
    form.setFieldsValue({
      percentageDifference:
        totalWeight && scaleWeight
          ? `${(((scaleWeight - totalWeight) / totalWeight) * 100).toFixed(3)}%`
          : null,
    });
    const absoluteDifferenceNumber =
      totalWeight && scaleWeight
        ? (scaleWeight - totalWeight).toFixed(3)
        : null;
    form.setFieldsValue({
      absoluteDifference:
        // eslint-disable-next-line
        absoluteDifferenceNumber < 0
          ? `Lipsă de ${absoluteDifferenceNumber * -1} (tone)`
          : absoluteDifferenceNumber > 0
          ? `Surplus de ${absoluteDifferenceNumber * 1} (tone)`
          : `0 (tone)`,
    });
  }, [form]);

  useEffect(() => {
    setNumberAxis(transaction?.scale?.axisCount);
    form.setFieldsValue(scaleDTO);
    calculateAbsoluteAndPercentageDifference();
    setIsSwitchOn(
      transaction?.scale?.europeanDocDate ||
        transaction?.scale?.europeanDocNumber,
    );
    // eslint-disable-next-line
  }, [transaction, form, scaleDTO]);

  // eslint-disable-next-line
  const confirmWeighing = useCallback(() => {
    setLoadingLoader(true);
    confirmStep(transaction.id, 'HAS_SCALE')
      .then(() => {
        notification.success({
          message: t('actions.infirm'),
          duration: 3,
        });
        skipTab('#HAS_SCALE');
      })
      .catch(() => {
        notification.error({
          message: t('actions.infirmErr'),
          duration: 3,
        });
      })
      .finally(() => {
        setLoadingLoader(false);
        reload();
      });
  }, [t, transaction.id, skipTab, reload]);

  const handleSaveData = useCallback(() => {
    if (!form?.getFieldValue('scaleType')) {
      notification.warning({
        message: 'Selectați tip cântar',
      });
      return;
    }
    if (!form?.getFieldValue('scaleCount')) {
      notification.warning({
        message: 'Selectați numarul de cântăriri',
      });
      return;
    }

    const scale = {
      ...transaction?.scale,
      ...form.getFieldsValue(),
      transactionId: transaction.id,
      hasContainer: form.getFieldValue(['hasContainer']) ? 1 : 0,
    };

    scale.vehicleWeight = convertTonesToKilos(scale.vehicleWeight);
    scale.goodsWeight = convertTonesToKilos(scale.goodsWeight);
    scale.totalWeight = convertTonesToKilos(scale.totalWeight);
    scale.scaleWeight = convertTonesToKilos(scale.scaleWeight);

    scale.axis0 = convertTonesToKilos(scale.axis0);
    scale.axis1 = convertTonesToKilos(scale.axis1);
    scale.axis2 = convertTonesToKilos(scale.axis2);
    scale.axis3 = convertTonesToKilos(scale.axis3);
    scale.axis4 = convertTonesToKilos(scale.axis4);
    scale.axis5 = convertTonesToKilos(scale.axis5);

    addScale(scale)
      .then(() => {
        if (scale.toBePaid && scale.toBePaid > 0) {
          setIsWeighingAmount(true);
        }
        moveNext();
      })
      .catch(() => {
        notification.error({
          message: 'A apărut o eroare la salvarea datelor',
        });
      })
      .finally(() => {
        setLoadingLoader(false);
        reload();
      });
  }, [
    form,
    reload,
    transaction.id,
    transaction?.scale,
    setIsWeighingAmount,
    moveNext,
  ]);

  const handleChangeScaleType = (value) => {
    setScaleType(value || null);
  };

  // TODO: delete this function after using new post codes on weightings
  const handleCallWeighingServiceOld = useCallback(() => {
    setLoadingLoader(true);

    const postCode =
      scaleType === 'STATIC'
        ? user?.customsPost.code
        : transaction?.direction[0] + user?.customsPost.code;
    getWeighingDetails(transaction?.plateNumber, postCode)
      .then((res) => {
        if (!res || res?.length === 0) {
          notification.warning({ message: 'Nu au fost găsite date' });
          return;
        }
        notification.success({ message: 'Datele au fost găsite cu succes' });

        const scaleWeight = res?.reduce((acc, val) => acc + val?.weight, 0);
        const axlesWeight = res?.reduce((acc, val) => {
          return {
            ...acc,
            [`axis${val?.axleNo}`]: convertKilosToTons(val?.weight),
          };
        }, {});
        setNumberAxis(res[0].axles);
        form.setFieldsValue({
          scaleWeight: convertKilosToTons(scaleWeight),
          axisDistance: res?.typeName
            ? res?.typeName
            : form.getFieldValue(['axisDistance']),
          axisCount: res[0].axles,
          ...axlesWeight,
        });
      })
      .catch(() => {
        notification.error({ message: 'Eroare la căutarea datelor.' });
      })
      .finally(() => {
        setLoadingLoader(false);
        calculateAbsoluteAndPercentageDifference();
      });
  }, [
    transaction?.plateNumber,
    user,
    form,
    transaction?.direction,
    scaleType,
    calculateAbsoluteAndPercentageDifference,
  ]);

  const handleCallWeighingService = useCallback(() => {
    setLoadingLoader(true);

    const postCode =
      scaleType === 'STATIC'
        ? user?.customsPost.newCode
        : transaction?.direction[0] + user?.customsPost.newCode;
    getWeighingDetails(transaction?.plateNumber, postCode)
      .then((res) => {
        if (!res || res?.length === 0) {
          handleCallWeighingServiceOld();
          return;
        }
        notification.success({ message: 'Datele au fost găsite cu succes' });

        const scaleWeight = res?.reduce((acc, val) => acc + val?.weight, 0);
        const axlesWeight = res?.reduce((acc, val) => {
          return {
            ...acc,
            [`axis${val?.axleNo}`]: convertKilosToTons(val?.weight),
          };
        }, {});
        setNumberAxis(res[0].axles);
        form.setFieldsValue({
          scaleWeight: convertKilosToTons(scaleWeight),
          axisDistance: res?.typeName
            ? res?.typeName
            : form.getFieldValue(['axisDistance']),
          axisCount: res[0].axles,
          ...axlesWeight,
        });
      })
      .catch(() => {
        handleCallWeighingServiceOld();
      })
      .finally(() => {
        setLoadingLoader(false);
        calculateAbsoluteAndPercentageDifference();
      });
  }, [
    transaction?.plateNumber,
    user,
    form,
    transaction?.direction,
    scaleType,
    handleCallWeighingServiceOld,
    calculateAbsoluteAndPercentageDifference,
  ]);

  const setAmountToPay = useCallback(() => {
    const e = form.getFieldValue(['scaleCount']);

    if (e && e > 0) {
      const weighingCost = constants.content?.find(
        (el) => el.code === 'WEIGHING_COST',
      );
      if (weighingCost) {
        form.setFieldsValue({
          toBePaid: +weighingCost.value * e,
        });
      }
    } else {
      form.setFieldsValue({
        toBePaid: 0,
      });
    }
  }, [form, constants.content]);

  const handleEnableStep = useCallback(() => {
    setLoadingLoader(true);
    enableSteps(transaction.id, 'HAS_SCALE')
      .catch(() => {
        notification.error({
          message: 'A apărut o eroare. Contactați administratorul',
        });
      })
      .finally(() => {
        setLoadingLoader(false);
        reload();
      });
  }, [transaction.id, reload]);

  const handleIsOverWeight = (checked) => {
    if (transaction?.scale) {
      setOverWeight(checked);
      if (!checked) {
        form.setFieldsValue({
          allowedLength: null,
          allowedWidth: null,
          allowedHeight: null,
          length: null,
          width: null,
          height: null,
        });
      } else {
        form.setFieldsValue({
          allowedLength: transaction.allowedLength,
          allowedWidth: transaction.allowedWidth,
          allowedHeight: transaction.allowedHeight,
          length: transaction.length,
          width: transaction.width,
          height: transaction.height,
        });
      }
    }
  };

  return (
    <>
      <div className={`loaderOverlay ${loadingLoader ? 'active' : ''}`}>
        <AppLoader />
      </div>
      {transaction?.status === TransactionStatus.PENDING ? (
        <Button
          disabled={readOnly}
          onClick={handleEnableStep}
          type="primary"
          danger={transaction.hasScale}
        >
          {!transaction.hasScale ? 'Inițiere control' : 'Anulare control'}
        </Button>
      ) : null}

      <Divider plain>
        <Text strong>{t('entity.admin.ansa.control')}</Text>
      </Divider>
      <FormContextProvider form={form} initialValues={scaleDTO}>
        <Form form={form} layout="vertical" autoComplete="off">
          <Row gutter={15}>
            <Col s={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item label="Masa transportului (tone)" name="vehicleWeight">
                <InputNumber disabled />
              </Form.Item>
            </Col>
            <Col s={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item label="Masa încărcăturii (tone)" name="goodsWeight">
                <InputNumber disabled />
              </Form.Item>
            </Col>
            <Col s={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item label="Masa totală (tone)" name="totalWeight">
                <InputNumber disabled />
              </Form.Item>
            </Col>
          </Row>
          {scaleType !== 'STATIC' && (
            <Row gutter={15}>
              <Col s={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item label="Număr de axe" name="axisCount">
                  <InputNumber
                    onChange={(value) => setNumberAxis(value)}
                    max={6}
                    min={1}
                    step={1}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col s={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item label="Distanța dintre axe" name="axisDistance">
                  <InputNumber disabled />
                </Form.Item>
              </Col>
              <Col s={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item label="Distanța (km)" name="distance">
                  <InputNumber disabled />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row gutter={15}>
            <Divider plain>
              <Text strong>Parametri</Text>
            </Divider>
            <Col xl={24} xxl={24}>
              <Row gutter={15}>
                <Col xs={12} xl={6} xxl={6}>
                  <Form.Item
                    label="Tip cântar"
                    name="scaleType"
                    rules={[
                      { required: true, message: 'Câmpul este obligatoriu' },
                    ]}
                  >
                    <Select
                      allowClear
                      onChange={handleChangeScaleType}
                      disabled={readOnly || !transaction.hasScale}
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) !== -1
                      }
                    >
                      {scaleTypes?.content?.map((option) => (
                        <Select.Option key={option.code} value={option.code}>
                          {`${option.name}`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={12} xl={6} xxl={6}>
                  <Form.Item
                    label="Masa conform cântar (tone)"
                    name="scaleWeight"
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} xl={6} xxl={6} style={{ marginTop: '30px' }}>
                  <Button
                    type="primary"
                    style={{ marginBottom: '.5rem' }}
                    onClick={() => handleCallWeighingService()}
                    disabled={
                      readOnly ||
                      !scaleType ||
                      !transaction.hasScale ||
                      (!hasPermission(permissions, 'SEARCH_WEIGHING') &&
                        !transaction.plateNumber)
                    }
                  >
                    Încarcă detalii Cântăriri
                  </Button>
                </Col>
              </Row>
              <Row gutter={15}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={6}>
                  <Form.Item
                    label="Cântăriri"
                    name="scaleCount"
                    rules={[
                      { required: true, message: 'Câmpul este obligatoriu' },
                    ]}
                  >
                    <Input
                      disabled={readOnly || !transaction.hasScale}
                      onChange={(e) => {
                        form.setFieldsValue({
                          scaleCount: numberValidator(e.target.value, 9),
                        });
                        setAmountToPay();
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={6}>
                  <Form.Item label="Spre achitare" name="toBePaid">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={6}>
                  <Form.Item
                    label="Diferența absolută"
                    name="absoluteDifference"
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={6}>
                  <Form.Item
                    label="Diferența în procente"
                    name="percentageDifference"
                  >
                    <Input
                      disabled
                      className={`weight__difference-percentage ${
                        extractNumber(
                          form.getFieldValue('percentageDifference'),
                        ) < 0
                          ? 'negative'
                          : ''
                      }`}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col className="splitter" span={8} style={{ marginTop: '20px' }}>
              <Divider plain>
                <div
                  style={{
                    display: 'flex',
                    // justifyContent: 'space-between',
                    gap: '10px',
                  }}
                >
                  <Col xl={12} xxl={6}>
                    <Switch
                      onChange={handleIsOverWeight}
                      checked={isOverWeight}
                      disabled={!transaction.hasScale || readOnly}
                    />
                  </Col>
                  <span>Depășiri la cântărire</span>
                </div>
              </Divider>
              {isOverWeight ? (
                <>
                  <Row gutter={15}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item label="Certificat de cântărire european">
                        <Switch
                          checked={isSwitchOn}
                          onChange={(checked) => setIsSwitchOn(checked)}
                          disabled={!transaction.hasScale || readOnly}
                        />
                      </Form.Item>
                    </Col>
                    {isSwitchOn && (
                      <>
                        <Col s={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                          <Form.Item
                            label="Numar certificat"
                            name="europeanDocNumber"
                          >
                            <Input
                              maxLength={20}
                              disabled={!transaction.hasScale || readOnly}
                            />
                          </Form.Item>
                        </Col>
                        <Col s={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                          <Form.Item
                            label="Data emitere"
                            name="europeanDocDate"
                          >
                            <DatePicker
                              format="DD-MM-YYYY"
                              disabled={!transaction.hasScale || readOnly}
                            />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                  </Row>
                  <Row gutter={15}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Form.Item
                        label="Lungime admisă (metri)"
                        name="allowedLength"
                      >
                        <Input
                          onChange={(e) => {
                            form.setFieldsValue({
                              allowedLength: floatValidator(
                                dotValidator(e.target.value),
                              ),
                            });
                          }}
                          disabled={!transaction.hasScale || readOnly}
                          maxLength={9}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Form.Item
                        label="Lățime admisă (metri)"
                        name="allowedWidth"
                      >
                        <Input
                          onChange={(e) => {
                            form.setFieldsValue({
                              allowedWidth: floatValidator(
                                dotValidator(e.target.value),
                              ),
                            });
                          }}
                          disabled={!transaction.hasScale || readOnly}
                          maxLength={9}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Form.Item
                        label="Înălțime admisă (metri)"
                        name="allowedHeight"
                      >
                        <Input
                          onChange={(e) => {
                            form.setFieldsValue({
                              allowedHeight: floatValidator(
                                dotValidator(e.target.value),
                              ),
                            });
                          }}
                          disabled={!transaction.hasScale || readOnly}
                          maxLength={9}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={15}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Form.Item label="Lungime (metri)" name="length">
                        <Input
                          onChange={(e) => {
                            form.setFieldsValue({
                              length: floatValidator(
                                dotValidator(e.target.value),
                              ),
                            });
                          }}
                          disabled={!transaction.hasScale || readOnly}
                          maxLength={9}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Form.Item label="Lățime (metri)" name="width">
                        <Input
                          onChange={(e) => {
                            form.setFieldsValue({
                              width: floatValidator(
                                dotValidator(e.target.value),
                              ),
                            });
                          }}
                          disabled={!transaction.hasScale || readOnly}
                          maxLength={9}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Form.Item label="Înălțime (metri)" name="height">
                        <Input
                          onChange={(e) => {
                            form.setFieldsValue({
                              height: floatValidator(
                                dotValidator(e.target.value),
                              ),
                            });
                          }}
                          disabled={!transaction.hasScale || readOnly}
                          maxLength={9}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              ) : null}
            </Col>

            <Col style={{ paddingLeft: '20px', marginTop: '20px' }} span={12}>
              <Divider plain>
                <Text strong>Încărcătura pe axe (tone)</Text>
              </Divider>
              {scaleType === 'DYNAMIC' && (
                <>
                  <Row gutter={15} className="axis__row-container">
                    {numberAxis &&
                      [...Array(parseInt(numberAxis, 10))?.keys()].map((el) => (
                        <Col key={el} span={3}>
                          <div className="axis1__wrapper">
                            <div key={el} className="axis1">
                              <Form.Item
                                name={`axis${el}`}
                                className="axis1__field"
                              >
                                <Input disabled />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>
                      ))}
                  </Row>
                </>
              )}
            </Col>
          </Row>
          <Row gutter={15}>
            {!readOnly && (
              <>
                <Col>
                  <CancelButton disabled={readOnly} onCancel={moveBack} />
                </Col>

                <Col style={{ maxWidth: '100%' }}>
                  {/* !hasPermission(permissions, 'SCALE_ADD') */}
                  {transaction.hasScale ? (
                    <Button
                      type="primary"
                      icon={<RightCircleOutlined />}
                      onClick={handleSaveData}
                    >
                      {t('actions.next')}
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      icon={<RightCircleOutlined />}
                      onClick={antaNavigateWithoutSave}
                    >
                      {t('actions.next')}
                    </Button>
                  )}
                </Col>
              </>
            )}
          </Row>
        </Form>
      </FormContextProvider>
    </>
  );
};

export default Weighing;
