import { genericService, get } from '../../../helpers/api';

export const SERVICE_URI = '/mconnect-anta';
export const BASE_URI = '/api/anta';
export const BASE_URI_SEARCH_AUTHORIZATION_TYPES = '/search/authorization-type';

export const { findAll } = genericService(
  SERVICE_URI,
  BASE_URI_SEARCH_AUTHORIZATION_TYPES,
);

export const getAuthorizationTypes = () =>
  get(`${SERVICE_URI}${BASE_URI}/authorization-types`);
