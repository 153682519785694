import React from 'react';

import SFSDebtsList from './SFSDebtsList';

const SFSDebts = () => {
  return (
    <>
      <SFSDebtsList />
    </>
  );
};

export default SFSDebts;
