import React, { useMemo } from 'react';
import { Button, Col, Row, Table, Tooltip } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Column from '../../../../helpers/Columns';
import DeleteItemIcon from '../../../buttons/DeleteItemIcon';

const ArticleListTable = ({
  transactionId,
  vehicleCategories,
  countries,
  readOnly,
  setArticleModalVisible = () => {},
  handleEditModal = () => {},
  removeArticle = () => {},
  reload,
  setLoadingArticles = () => {},
  loadingArticles,
  content,
  handleChange = () => {},
}) => {
  const { t } = useTranslation();

  const columns = useMemo(() => {
    const c = [
      {
        title: 'Număr',
        key: 'vehicleNumber',
        width: 50,
        sorter: (a, b) => a.vehicleNumber - b.vehicleNumber,
        render: (row) => {
          return <>{row.vehicleNumber?.toUpperCase()}</>;
        },
      },
      Column.text('vinCode', 'VIN', {
        width: 100,
      }),
      {
        sort: false,
        title: 'Categorie',
        key: 'category',
        width: 100,
        dataIndex: 'category',
        render: (row) => {
          const cat = vehicleCategories?.content?.find((el) => el.code === row);
          return row ? `${cat?.code} - ${cat?.name}` : null;
        },
      },
      {
        sort: false,
        title: 'Țara',
        key: 'countryId',
        width: 100,
        dataIndex: 'countryId',
        render: (row) => {
          return countries?.content
            .find((el) => el.id === row)
            ?.name?.toUpperCase();
        },
      },
      Column.actions(t('table.actions'), (record) => (
        <span style={{ textAlign: 'right' }}>
          <Tooltip title={t('actions.edit')}>
            <Button
              shape="circle"
              type="link"
              disabled={readOnly}
              icon={<EditOutlined />}
              onClick={() => {
                handleEditModal(record);
                setArticleModalVisible(true);
              }}
            />
          </Tooltip>
          <DeleteItemIcon
            title="Sunteți sigur(ă) că doriți să ștergeți Articolul?"
            item={record}
            action={removeArticle}
            reload={reload}
            disabled={readOnly}
            setLoadingLoader={setLoadingArticles}
          />
        </span>
      )),
    ];
    return c.filter((el) => el);
  }, [
    t,
    vehicleCategories?.content,
    countries?.content,
    readOnly,
    removeArticle,
    reload,
    setLoadingArticles,
    handleEditModal,
    setArticleModalVisible,
  ]);

  return (
    <>
      <Row>
        <Col span={24}>
          {transactionId && (
            <>
              <Row justify="space-between">
                <span
                  style={{
                    fontWeight: '600',
                    fontSize: '20px',
                    lineHeight: '32px',
                  }}
                >
                  Articole
                </span>
                <Button
                  disabled={readOnly}
                  key="articleButton"
                  icon={<PlusOutlined />}
                  type="primary"
                  onClick={() => handleEditModal(null)}
                >
                  Adaugă articol
                </Button>
              </Row>
              <Table
                columns={columns}
                rowKey="id"
                loading={loadingArticles}
                pagination={false}
                dataSource={content}
                onChange={handleChange}
              />
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default ArticleListTable;
