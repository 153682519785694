import { genericService } from '../../../helpers/api';

export const SERVICE_URI = '/chat';
export const BASE_URI = '/api/v1/chat-message';
export const SEND_PRIVATE_URI = '/app/private-message';

export const WS_URL = `${SERVICE_URI}/connect`;

export const subscribePrivateURI = (userId) => {
  return `/user/${userId}/private`;
};

export const { findAll } = genericService(SERVICE_URI, BASE_URI);
