import {
  Button,
  Col,
  Form,
  InputNumber,
  Modal,
  notification,
  Popconfirm,
  Radio,
  Row,
  Space,
  Spin,
  Tooltip,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useCallback, useState } from 'react';
import {
  CloseCircleOutlined,
  CheckOutlined,
  FileTextOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { getScaleByTransactionId } from '../../../services/admin/web-services/scale-transactions';
import { getAntaByTransactionId } from '../../../services/admin/web-services/anta-transactions';
import { getAnsaByTransactionId } from '../../../services/admin/web-services/ansa-transactions';
import {
  cancelBon,
  findByTransactionId as getBonPlataByTransactionId,
} from '../../../services/admin/bon-plata-parameters';
import { generatePdfInNewTab } from '../../../services/transactions/transactions';
import AppLoader from '../../auth/AppLoader';
import {
  getPaymentInCurrentSystem,
  getPaymentInOldSystem,
} from '../../../services/admin/web-services/payments';
import ManuallyPaymentConfirmationModal from './ManuallyPaymentConfirmationModal';

const BonDePlataParametersModal = ({
  readOnly,
  parameters,
  modalTitle,
  buttonCaption,
  submitMethod,
  inputsBasicDetails,
  successMessage,
  reload = () => {},
}) => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  const [scaleAmountWaiveModalForm] = Form.useForm();
  const [antaAmountWaiveModalForm] = Form.useForm();
  const [ansaAmountWaiveModalForm] = Form.useForm();

  const { t } = useTranslation();
  const [additionalValues, setAdditionalValues] = useState({});

  const [scaleAmount, setScaleAmount] = useState();
  const [antaAmount, setAntaAmount] = useState();
  const [ansaAmount, setAnsaAmount] = useState();

  const [scaleWaiveDisabled, setScaleWaiveDisabled] = useState(true);
  const [antaWaiveDisabled, setAntaWaiveDisabled] = useState(true);
  const [ansaWaiveDisabled, setAnsaWaiveDisabled] = useState(true);

  const [isPaidLoading, setIsPaidLoading] = useState(false);

  const [scaleAmountWaiveInitialAmount, setScaleAmountWaiveInitialAmount] =
    useState(0.0);
  const [antaAmountWaiveInitialAmount, setAntaAmountWaiveInitialAmount] =
    useState(0.0);
  const [ansaAmountWaiveInitialAmount, setAnsaAmountWaiveInitialAmount] =
    useState(0.0);

  const [scaleAmountWaiveResultAmount, setScaleAmountWaiveResultAmount] =
    useState(0.0);
  const [antaAmountWaiveResultAmount, setAntaAmountWaiveResultAmount] =
    useState(0.0);
  const [ansaAmountWaiveResultAmount, setAnsaAmountWaiveResultAmount] =
    useState(0.0);

  // eslint-disable-next-line no-unused-vars
  const [scaleAmountWithWaive, setScaleAmountWithWaive] = useState(0.0);
  // eslint-disable-next-line no-unused-vars
  const [antaAmountWithWaive, setAntaAmountWithWaive] = useState(0.0);
  // eslint-disable-next-line no-unused-vars
  const [ansaAmountWithWaive, setAnsaAmountWithWaive] = useState(0.0);

  const [isScaleAmountWaiveApplied, setIsScaleAmountWaiveApplied] =
    useState(false);
  const [isAntaAmountWaiveApplied, setIsAntaAmountWaiveApplied] =
    useState(false);
  const [isAnsaAmountWaiveApplied, setIsAnsaAmountWaiveApplied] =
    useState(false);

  const [scaleAmountWaiveModalVisible, setScaleAmountWaiveModalVisible] =
    useState(false);
  const [antaAmountWaiveModalVisible, setAntaAmountWaiveModalVisible] =
    useState(false);
  const [ansaAmountWaiveModalVisible, setAnsaAmountWaiveModalVisible] =
    useState(false);
  const [loadingLoader, setLoadingLoader] = useState(false);
  const [scaleAmountWaive, setScaleAmountWaive] = useState(0.0);
  const [antaAmountWaive, setAntaAmountWaive] = useState(0.0);
  const [ansaAmountWaive, setAnsaAmountWaive] = useState(0.0);

  const [scaleAmountWaiveType, setScaleAmountWaiveType] = useState('TOTAL');
  const [antaAmountWaiveType, setAntaAmountWaiveType] = useState('TOTAL');
  const [ansaAmountWaiveType, setAnsaAmountWaiveType] = useState('TOTAL');

  const [isScaleWaiveApplyClicked, setIsScaleWaiveApplyClicked] =
    useState(false);
  const [isAntaWaiveApplyClicked, setIsAntaWaiveApplyClicked] = useState(false);
  const [isAnsaWaiveApplyClicked, setIsAnsaWaiveApplyClicked] = useState(false);
  const [existingBonPlata, setExistingBonPlata] = useState(null);
  const [isReceiptPaid, setIsReceiptPaid] = useState(false);

  const getPaymentData = useCallback(() => {
    setIsPaidLoading(true);
    getPaymentInCurrentSystem(parameters?.transactionId)
      .then((res) => {
        if (res?.isPaid === true) {
          setIsReceiptPaid(true);
        } else {
          getPaymentInOldSystem(parameters?.transactionId)
            .then((oldRes) => {
              if (oldRes?.ste === 'PAID') {
                setIsReceiptPaid(true);
              } else {
                setIsReceiptPaid(false);
              }
            })
            .catch(() => setIsReceiptPaid(undefined));
        }
      })
      .catch(() => setIsReceiptPaid(undefined))
      .finally(() => setIsPaidLoading(false));
  }, [parameters?.transactionId]);

  const showModal = async () => {
    const bonPlata = await getBonPlataByTransactionId(parameters.transactionId);

    getPaymentData();

    if (bonPlata.id) {
      setExistingBonPlata(bonPlata);
      setAdditionalValues({});
      form.resetFields();
      form.setFieldsValue({
        amountForScale: bonPlata.scaleAmount,
        amountForAnsa: bonPlata.ansaAmount,
        amountForAnta: bonPlata.antaAmount,
      });
      setScaleAmount(bonPlata.scaleAmount);
      setAnsaAmount(bonPlata.ansaAmount);
      setAntaAmount(bonPlata.antaAmount);
      // SCALE
      if (bonPlata.scaleWaiveType && bonPlata.scaleWaiveType !== 'NONE') {
        setIsScaleAmountWaiveApplied(true);
        setScaleAmountWaiveType(bonPlata.scaleAmountWaiveType);
      }
      // ANTA
      if (bonPlata.antaWaiveType && bonPlata.antaWaiveType !== 'NONE') {
        setIsAntaAmountWaiveApplied(true);
        setAntaAmountWaiveType(bonPlata.antaAmountWaiveType);
      }
      // ANSA
      if (bonPlata.ansaWaiveType && bonPlata.ansaWaiveType !== 'NONE') {
        setIsAnsaAmountWaiveApplied(true);
        setAnsaAmountWaiveType(bonPlata.antaAmountWaiveType);
      }
    } else {
      setAdditionalValues({});
      form.resetFields();
      // SCALE
      if (parameters.hasScale) {
        getScaleByTransactionId(parameters.transactionId)
          .then((response) => {
            form.setFieldsValue({ amountForScale: response.toBePaid });
            setScaleAmount(response.toBePaid);
          })
          .catch(() =>
            notification.error({ message: 'Eroare la extragerea datelor' }),
          );
      }
      // ANTA
      if (parameters.hasAnta) {
        getAntaByTransactionId(parameters.transactionId)
          .then((response) => {
            form.setFieldsValue({ amountForAnta: response.amountToPay });
            setAntaAmount(response.amountToPay);
          })
          .catch(() =>
            notification.error({ message: 'Eroare la extragerea datelor' }),
          );
      }

      // ANSA
      if (parameters.hasAnsa) {
        getAnsaByTransactionId(parameters.transactionId)
          .then((response) => {
            form.setFieldsValue({ amountForAnsa: response.amountToPay });
            setAnsaAmount(response.amountToPay);
          })
          .catch(() =>
            notification.error({ message: 'Eroare la extragerea datelor' }),
          );
      }
    }
    setVisible(true);
  };

  const showScaleAmountWaiveModal = useCallback(() => {
    if (existingBonPlata && Object.keys(existingBonPlata).length > 0) {
      if (!scaleAmountWaiveInitialAmount) {
        setScaleAmountWaiveInitialAmount(existingBonPlata.scaleBaseAmount);
      }
      setScaleAmountWaive(existingBonPlata.scaleBaseAmount);
    } else {
      if (!scaleAmountWaiveInitialAmount) {
        setScaleAmountWaiveInitialAmount(scaleAmount);
      }
      setScaleAmountWaive(scaleAmount);
    }
    setIsScaleWaiveApplyClicked(false);
    setScaleAmountWaiveModalVisible(true);
  }, [existingBonPlata, scaleAmount, scaleAmountWaiveInitialAmount]);

  const showAntaAmountWaiveModal = useCallback(() => {
    if (existingBonPlata && Object.keys(existingBonPlata).length > 0) {
      setAntaAmountWaiveInitialAmount(existingBonPlata.antaBaseAmount);
      setAntaAmountWaive(existingBonPlata.antaBaseAmount);
    } else {
      setAntaAmountWaiveInitialAmount(antaAmount);
      setAntaAmountWaive(antaAmount);
    }
    setIsAntaWaiveApplyClicked(false);
    setAntaAmountWaiveModalVisible(true);
  }, [antaAmount, existingBonPlata]);

  const showAnsaAmountWaiveModal = useCallback(() => {
    if (existingBonPlata && Object.keys(existingBonPlata).length > 0) {
      setAnsaAmountWaiveInitialAmount(existingBonPlata.ansaBaseAmount);
      setAnsaAmountWaive(existingBonPlata.ansaBaseAmount);
    } else {
      setAnsaAmountWaiveInitialAmount(ansaAmount);
      setAnsaAmountWaive(ansaAmount);
    }
    setIsAnsaWaiveApplyClicked(false);
    setAnsaAmountWaiveModalVisible(true);
  }, [ansaAmount, existingBonPlata]);

  const onSubmit = () => {
    setLoadingLoader(true);
    const submitParameters = { ...parameters };

    inputsBasicDetails.forEach((element) => {
      if (form.getFieldValue(element.name)) {
        submitParameters[`${element.name}`] = form.getFieldValue(element.name);
      }
    });

    if (additionalValues) {
      Object.keys(additionalValues).forEach((k) => {
        if (!submitParameters[k]) {
          submitParameters[k] = additionalValues[k];
        }
      });
    }
    if (
      !submitParameters.amountForScale &&
      parameters.hasScale &&
      form.getFieldValue('amountForScale')
    ) {
      submitParameters.amountForScale = form.getFieldValue('amountForScale');
    }

    if (
      parameters.hasAnsa &&
      !submitParameters.amountForAnsa &&
      form.getFieldValue('amountForAnsa')
    ) {
      if (form.getFieldValue('amountForAnsa'))
        submitParameters.amountForAnsa = form.getFieldValue('amountForAnsa');
    }

    if (
      parameters.hasAnta &&
      !submitParameters.amountForAnta &&
      form.getFieldValue('amountForAnta')
    ) {
      submitParameters.amountForAnta = form.getFieldValue('amountForAnta');
    }
    if (!scaleWaiveDisabled)
      submitParameters.scaleWaiveReason =
        scaleAmountWaiveModalForm.getFieldValue('waveReasonScale');
    if (!antaWaiveDisabled)
      submitParameters.antaWaiveReason =
        antaAmountWaiveModalForm.getFieldValue('waveReasonAnta');
    if (!ansaWaiveDisabled)
      submitParameters.ansaWaiveReason =
        ansaAmountWaiveModalForm.getFieldValue('waveReasonAnsa');

    submitParameters.postCode = parameters.postCode;
    submitMethod(submitParameters)
      .then(() => {
        setAdditionalValues({});
        notification.success({
          message: successMessage || t('messages.success'),
          duration: 3,
        });
      })
      .catch(() => {
        notification.error({
          message: `A apărut o eroare la salvarea bonului`,
        });
      })
      .finally(() => {
        setLoadingLoader(false);
        form.resetFields();
        setVisible(false);
      });
  };

  const handleCancelBon = () => {
    if (existingBonPlata?.id && parameters.transactionId) {
      cancelBon(parameters.transactionId)
        .then(() => {
          setExistingBonPlata(null);
          notification.success({ message: 'Bonul a fost anulat cu succes.' });
        })
        .catch(() =>
          notification.error({ message: `Erroare la anularea bonului` }),
        )
        .finally(() => {
          setVisible(false);
          reload();
        });
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setScaleAmount(null);
    form.setFieldsValue({ amountForScale: null });
    setAdditionalValues({});
    setVisible(false);
  };

  const handleGeneratePdf = () => {
    setLoadingLoader(true);
    const submitParameters = { ...parameters };
    inputsBasicDetails.forEach((element) => {
      if (form.getFieldValue(element.name)) {
        submitParameters[`${element.name}`] = form.getFieldValue(element.name);
      }
    });

    if (additionalValues) {
      Object.keys(additionalValues).forEach((k) => {
        if (!submitParameters[k]) {
          submitParameters[k] = additionalValues[k];
        }
      });
    }
    if (
      !submitParameters.amountForScale &&
      parameters.hasScale &&
      form.getFieldValue('amountForScale')
    ) {
      submitParameters.amountForScale = form.getFieldValue('amountForScale');
    }

    if (
      parameters.hasAnsa &&
      !submitParameters.amountForAnsa &&
      form.getFieldValue('amountForAnsa')
    ) {
      submitParameters.amountForAnsa = form.getFieldValue('amountForAnsa');
    }

    if (
      parameters.hasAnta &&
      !submitParameters.amountForAnta &&
      form.getFieldValue('amountForAnta')
    ) {
      submitParameters.amountForAnta = form.getFieldValue('amountForAnta');
    }
    submitParameters.postCode = parameters.postCode;
    generatePdfInNewTab('/report/bon-plata', submitParameters)
      .then(() => {
        setVisible(false);
        notification.success({
          message: t('messages.report.generated.success'),
          duration: 3,
        });
      })
      .catch(() => {
        notification.error({
          message: `A apărut o eroare la generarea bonului`,
        });
      })
      .finally(() => {
        reload();
        setLoadingLoader(false);
      });
  };

  const handleScaleAmountWaiveCancel = () => {
    scaleAmountWaiveModalForm.resetFields();
    setScaleAmountWaiveResultAmount(0.0);
    setScaleAmountWaiveType('TOTAL');
    setScaleAmountWaive(0.0);
    setScaleAmountWaiveModalVisible(false);
  };

  const handleAntaAmountWaiveCancel = () => {
    antaAmountWaiveModalForm.resetFields();
    setAntaAmountWaiveResultAmount(0.0);
    setAntaAmountWaiveType('TOTAL');
    setAntaAmountWaive(0.0);
    setAntaAmountWaiveModalVisible(false);
  };

  const handleAnsaAmountWaiveCancel = () => {
    ansaAmountWaiveModalForm.resetFields();
    setAnsaAmountWaiveResultAmount(0.0);
    setAnsaAmountWaiveType('TOTAL');
    setAnsaAmountWaive(0.0);
    setAnsaAmountWaiveModalVisible(false);
  };

  const onScaleAmountWaiveTypeChange = (e) => {
    const newWaiveType = e.target.value;
    if (newWaiveType === 'TOTAL') {
      setScaleAmountWaive(scaleAmountWaiveInitialAmount);
      setScaleAmountWaiveResultAmount(0.0);
    } else {
      setScaleAmountWaive(0.0);
      setScaleAmountWaiveResultAmount(0.0);
    }
    setIsScaleWaiveApplyClicked(false);
    setScaleAmountWaiveType(newWaiveType);
  };

  const onAntaAmountWaiveTypeChange = (e) => {
    const newWaiveType = e.target.value;
    if (newWaiveType === 'TOTAL') {
      setAntaAmountWaive(antaAmountWaiveInitialAmount);
      setAntaAmountWaiveResultAmount(0.0);
    } else {
      setAntaAmountWaive(0.0);
      setAntaAmountWaiveResultAmount(0.0);
    }
    setIsAntaWaiveApplyClicked(false);
    setAntaAmountWaiveType(newWaiveType);
  };

  const onAnsaAmountWaiveTypeChange = (e) => {
    const newWaiveType = e.target.value;
    if (newWaiveType === 'TOTAL') {
      setAnsaAmountWaive(ansaAmountWaiveInitialAmount);
      setAnsaAmountWaiveResultAmount(0.0);
    } else {
      setAnsaAmountWaive(0.0);
      setAnsaAmountWaiveResultAmount(0.0);
    }
    setIsAnsaWaiveApplyClicked(false);
    setAnsaAmountWaiveType(newWaiveType);
  };

  const waiveScaleAmountSubmitMethod = (waiveType, waivedValue, newAmount) => {
    form.setFieldsValue({
      amountForScale: newAmount,
    });

    if (typeof additionalValues === 'undefined') {
      setAdditionalValues({
        amountForScale: newAmount,
        amountForScale_waive_type: waiveType,
        amountForScale_waived_value: waivedValue,
      });
    } else {
      setAdditionalValues({
        ...additionalValues,
        amountForScale: newAmount,
        amountForScale_waive_type: waiveType,
        amountForScale_waived_value: waivedValue,
      });
    }
  };

  const waiveAntaAmountSubmitMethod = (waiveType, waivedValue, newAmount) => {
    form.setFieldsValue({
      amountForAnta: newAmount,
    });

    if (typeof additionalValues === 'undefined') {
      setAdditionalValues({
        amountForAnta: newAmount,
        amountForAnta_waive_type: waiveType,
        amountForAnta_waived_value: waivedValue,
      });
    } else {
      setAdditionalValues({
        ...additionalValues,
        amountForAnta: newAmount,
        amountForAnta_waive_type: waiveType,
        amountForAnta_waived_value: waivedValue,
      });
    }
  };

  const waiveAnsaAmountSubmitMethod = (waiveType, waivedValue, newAmount) => {
    form.setFieldsValue({
      amountForAnsa: newAmount,
    });

    if (typeof additionalValues === 'undefined') {
      setAdditionalValues({
        amountForAnsa: newAmount,
        amountForAnsa_waive_type: waiveType,
        amountForAnsa_waived_value: waivedValue,
      });
    } else {
      setAdditionalValues({
        ...additionalValues,
        amountForAnsa: newAmount,
        amountForAnsa_waive_type: waiveType,
        amountForAnsa_waived_value: waivedValue,
      });
    }
  };

  const onScaleAmountWaiveModalSubmit = () => {
    setIsScaleAmountWaiveApplied(true);
    setScaleAmountWithWaive(scaleAmountWaiveResultAmount);
    waiveScaleAmountSubmitMethod(
      scaleAmountWaiveType,
      scaleAmountWaive,
      scaleAmountWaiveResultAmount,
    );
    setScaleAmountWaiveResultAmount(0.0);
    setScaleAmountWaiveType('TOTAL');
    setScaleAmountWaive(0.0);
    setScaleAmountWaiveModalVisible(false);
  };

  const onAntaAmountWaiveModalSubmit = () => {
    setIsAntaAmountWaiveApplied(true);
    setAntaAmountWithWaive(antaAmountWaiveResultAmount);
    waiveAntaAmountSubmitMethod(
      antaAmountWaiveType,
      antaAmountWaive,
      antaAmountWaiveResultAmount,
    );
    setAntaAmountWaiveResultAmount(0.0);
    setAntaAmountWaiveType('TOTAL');
    setAntaAmountWaive(0.0);
    setAntaAmountWaiveModalVisible(false);
  };

  const onAnsaAmountWaiveModalSubmit = () => {
    setIsAnsaAmountWaiveApplied(true);
    setAnsaAmountWithWaive(ansaAmountWaiveResultAmount);
    waiveAnsaAmountSubmitMethod(
      ansaAmountWaiveType,
      ansaAmountWaive,
      ansaAmountWaiveResultAmount,
    );
    setAnsaAmountWaiveResultAmount(0.0);
    setAnsaAmountWaiveType('TOTAL');
    setAnsaAmountWaive(0.0);
    setAnsaAmountWaiveModalVisible(false);
  };

  const scaleAmountApplyWaive = () => {
    if (scaleAmountWaiveType === 'TOTAL') {
      setScaleAmountWaiveResultAmount(0.0);
    } else if (scaleAmountWaiveType === 'PERCENT') {
      const waivedAmount =
        scaleAmountWaiveInitialAmount * (scaleAmountWaive / 100);
      setScaleAmountWaiveResultAmount(
        scaleAmountWaiveInitialAmount - waivedAmount,
      );
    } else if (scaleAmountWaiveType === 'AMOUNT') {
      setScaleAmountWaiveResultAmount(
        scaleAmountWaiveInitialAmount - scaleAmountWaive,
      );
    }
    setIsScaleWaiveApplyClicked(true);
  };

  const antaAmountApplyWaive = () => {
    if (antaAmountWaiveType === 'TOTAL') {
      setAntaAmountWaiveResultAmount(0.0);
    } else if (antaAmountWaiveType === 'PERCENT') {
      const waivedAmount =
        antaAmountWaiveInitialAmount * (antaAmountWaive / 100);
      setAntaAmountWaiveResultAmount(
        antaAmountWaiveInitialAmount - waivedAmount,
      );
    } else if (antaAmountWaiveType === 'AMOUNT') {
      setAntaAmountWaiveResultAmount(
        antaAmountWaiveInitialAmount - antaAmountWaive,
      );
    }
    setIsAntaWaiveApplyClicked(true);
  };

  const ansaAmountApplyWaive = () => {
    if (ansaAmountWaiveType === 'TOTAL') {
      setAnsaAmountWaiveResultAmount(0.0);
    } else if (ansaAmountWaiveType === 'PERCENT') {
      const waivedAmount =
        ansaAmountWaiveInitialAmount * (ansaAmountWaive / 100);
      setAnsaAmountWaiveResultAmount(
        ansaAmountWaiveInitialAmount - waivedAmount,
      );
    } else if (ansaAmountWaiveType === 'AMOUNT') {
      setAnsaAmountWaiveResultAmount(
        ansaAmountWaiveInitialAmount - ansaAmountWaive,
      );
    }
    setIsAnsaWaiveApplyClicked(true);
  };

  const cancelScaleAmountWaive = () => {
    if (existingBonPlata && Object.keys(existingBonPlata).length > 0) {
      waiveScaleAmountSubmitMethod(
        'NONE',
        0.0,
        existingBonPlata.scaleBaseAmount,
      );
      setScaleAmountWithWaive(existingBonPlata.scaleBaseAmount);
    } else {
      waiveScaleAmountSubmitMethod('NONE', 0.0, scaleAmount);
      setScaleAmountWithWaive(scaleAmount);
    }

    setScaleAmountWaiveResultAmount(0.0);
    setScaleAmountWaiveType('TOTAL');
    setScaleAmountWaive(0.0);
    setIsScaleAmountWaiveApplied(false);
    scaleAmountWaiveModalForm.setFieldsValue({ waveReasonScale: null });
  };

  const cancelAntaAmountWaive = () => {
    if (existingBonPlata && existingBonPlata.antaBaseAmount) {
      waiveAntaAmountSubmitMethod('NONE', 0.0, existingBonPlata.antaBaseAmount);
      setAntaAmountWithWaive(existingBonPlata.antaBaseAmount);
    } else {
      waiveAntaAmountSubmitMethod('NONE', 0.0, antaAmount);
      setAntaAmountWithWaive(antaAmount);
    }
    setAntaAmountWaiveResultAmount(0.0);
    setAntaAmountWaiveType('TOTAL');
    setAntaAmountWaive(0.0);
    setIsAntaAmountWaiveApplied(false);
    antaAmountWaiveModalForm.setFieldsValue({ waveReasonAnta: null });
  };

  const cancelAnsaAmountWaive = () => {
    if (existingBonPlata && existingBonPlata.ansaBaseAmount) {
      waiveAnsaAmountSubmitMethod('NONE', 0.0, existingBonPlata.ansaBaseAmount);
      setAnsaAmountWithWaive(existingBonPlata.ansaBaseAmount);
    } else {
      waiveAnsaAmountSubmitMethod('NONE', 0.0, ansaAmount);
      setAnsaAmountWithWaive(ansaAmount);
    }
    setAnsaAmountWaiveResultAmount(0.0);
    setAnsaAmountWaiveType('TOTAL');
    setAnsaAmountWaive(0.0);
    setIsAnsaAmountWaiveApplied(false);
    ansaAmountWaiveModalForm.setFieldsValue({ waveReasonAnsa: null });
  };

  const getPaymentInfoText = useCallback(() => {
    if (isPaidLoading) {
      return (
        <Spin
          style={{
            fontSize: 18,
          }}
          tip=""
        />
      );
    }
    if (isReceiptPaid === true) {
      return <div className="receipt__paid">Bonul a fost achitat</div>;
    }
    return <div className="receipt__notPaid">Bonul nu a fost achitat</div>;
  }, [isPaidLoading, isReceiptPaid]);

  const [manualConfirmationVisible, setManualConfirmationVisible] =
    useState(false);

  const handleManualConfirmation = () => {
    setManualConfirmationVisible(true);
    setVisible(false);
  };

  const handleCloseManualConfirmationModal = () => {
    setManualConfirmationVisible(false);
    setVisible(true);
  };

  const handleFinishManualConfirmationModal = () => {
    setManualConfirmationVisible(false);
    setVisible(false);
  };

  return (
    <>
      <div className={`loaderOverlay ${loadingLoader ? 'active' : ''}`}>
        <AppLoader />
      </div>

      <Button
        type="primary"
        onClick={showModal}
        style={{ width: '100%', marginBottom: '.5rem' }}
        icon={<FileTextOutlined />}
        disabled={
          !parameters.hasAnsa && !parameters.hasScale && !parameters.hasAnta
        }
      >
        {buttonCaption}
      </Button>

      <Modal
        maskClosable={false}
        centered
        width={600}
        visible={visible}
        title={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {modalTitle}
            {existingBonPlata && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  marginRight: '20px',
                }}
              >
                {getPaymentInfoText()}
                <Tooltip title="Împrospăteză starea bonului">
                  <Button
                    onClick={getPaymentData}
                    type="primary"
                    icon={<UndoOutlined />}
                  />
                </Tooltip>
                {!isReceiptPaid && (
                  <Tooltip title="Confirmare manuală">
                    <Button
                      onClick={handleManualConfirmation}
                      type="primary"
                      icon={<CheckOutlined />}
                    />
                  </Tooltip>
                )}
              </div>
            )}
          </div>
        }
        // onOk={form.submit}
        onCancel={handleCancel}
        cancelText="Anulați"
        footer={[
          existingBonPlata ? (
            <Popconfirm
              key="bonCancel"
              placement="top"
              title="Atenție! Sunteți sigur ca doriți sa anulați bonul?"
              onConfirm={handleCancelBon}
              okText="OK"
              cancelText="Înapoi"
              disabled={readOnly || isReceiptPaid === true}
            >
              <Button
                key="returButton"
                className="btn__retur"
                icon={<CloseCircleOutlined />}
                disabled={readOnly || isReceiptPaid === true}
                style={{
                  float: 'left',
                  background: '#e74c3c',
                  marginRight: '5px',
                }}
              >
                Anulare bon
              </Button>
            </Popconfirm>
          ) : null,
          <Popconfirm
            key="raportConfirm"
            placement="top"
            title="Atenție! Dupa generare, datele nu vor putea fi editate"
            onConfirm={handleGeneratePdf}
            okText="OK"
            cancelText={t('actions.cancel')}
            disabled={readOnly && !existingBonPlata}
          >
            <Button
              key="generateReport"
              style={{ float: 'left' }}
              disabled={readOnly && !existingBonPlata}
            >
              Generează raport
            </Button>
          </Popconfirm>,

          <Button
            key="back"
            onClick={handleCancel}
            style={{ marginRight: '5px' }}
          >
            Înapoi
          </Button>,
          <Popconfirm
            key="saveConfirm"
            placement="top"
            title="Sunteți sigur(ă) ca doriți sa salvați datele?"
            onConfirm={form.submit}
            okText="OK"
            cancelText={t('actions.cancel')}
            disabled={readOnly || isReceiptPaid === true}
          >
            <Button
              key="submit"
              type="primary"
              // loading={loading}
              // onClick={form.submit}
              disabled={readOnly || isReceiptPaid === true}
            >
              Salvare date
            </Button>
          </Popconfirm>,
        ]}
      >
        <Form form={form} layout="vertical" onFinish={onSubmit}>
          <Form.Item
            label="Suma pentru Cântar"
            hidden={
              !parameters.hasScale || (!scaleAmount && scaleAmount !== 0.0)
            }
          >
            <Row style={{ gap: '20px' }}>
              <Col>
                <Form.Item
                  key="amountForScale"
                  name="amountForScale"
                  initialValue={scaleAmount}
                  value={
                    additionalValues?.amountForScale ||
                    (scaleAmount && scaleAmount >= 0)
                  }
                >
                  <InputNumber step={0.01} precision={2} readOnly />
                </Form.Item>
              </Col>
              <Col>
                <Button
                  key="waiveModal"
                  type="primary"
                  onClick={showScaleAmountWaiveModal}
                  disabled={isScaleAmountWaiveApplied || existingBonPlata?.id}
                >
                  {!readOnly ? `Aplicați reducere` : `Vizualizați reducere`}
                </Button>
              </Col>
              <Col>
                <Button
                  disabled={
                    !isScaleAmountWaiveApplied ||
                    readOnly ||
                    existingBonPlata?.id
                  }
                  onClick={cancelScaleAmountWaive}
                >
                  Anulați reducerea
                </Button>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            label="Suma pentru ANTA"
            hidden={!parameters.hasAnta || (!antaAmount && antaAmount !== 0.0)}
          >
            <Row style={{ gap: '20px' }}>
              <Col>
                <Form.Item
                  key="amountForAnta"
                  name="amountForAnta"
                  initialValue={antaAmount}
                  value={additionalValues?.amountForAnta || antaAmount}
                >
                  <InputNumber step={0.01} precision={2} readOnly />
                </Form.Item>
              </Col>
              <Col>
                <Button
                  key="waiveModal"
                  type="primary"
                  onClick={showAntaAmountWaiveModal}
                  disabled={isAntaAmountWaiveApplied || existingBonPlata?.id}
                >
                  {!readOnly ? `Aplicați reducere` : `Vizualizați reducere`}
                </Button>
              </Col>
              <Col>
                <Button
                  disabled={
                    !isAntaAmountWaiveApplied ||
                    readOnly ||
                    existingBonPlata?.id
                  }
                  onClick={cancelAntaAmountWaive}
                >
                  Anulați reducerea
                </Button>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            label="Suma pentru ANSA"
            hidden={!parameters.hasAnsa || (!ansaAmount && ansaAmount !== 0.0)}
          >
            <Row style={{ gap: '20px' }}>
              <Col>
                <Form.Item
                  key="amountForAnsa"
                  name="amountForAnsa"
                  initialValue={ansaAmount} // {control1.value}
                  value={
                    additionalValues?.amountForAnsa || ansaAmount // control1.value
                  }
                >
                  <InputNumber step={0.01} precision={2} readOnly />
                </Form.Item>
              </Col>
              <Col>
                <Button
                  key="waiveModal"
                  type="primary"
                  onClick={showAnsaAmountWaiveModal}
                  disabled={isAnsaAmountWaiveApplied || existingBonPlata?.id}
                >
                  {!readOnly ? `Aplicați reducere` : `Vizualizați reducere`}
                </Button>
              </Col>
              <Col>
                <Button
                  disabled={
                    !isAnsaAmountWaiveApplied ||
                    readOnly ||
                    existingBonPlata?.id
                  }
                  onClick={cancelAnsaAmountWaive}
                >
                  Anulați reducerea
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        maskClosable={false}
        centered
        id="scaleAmountWaiveModal"
        visible={scaleAmountWaiveModalVisible}
        title="Aplicarea reducerii la suma pentru Cântar"
        okButtonProps={{ disabled: !isScaleWaiveApplyClicked || readOnly }}
        onOk={scaleAmountWaiveModalForm.submit}
        onCancel={handleScaleAmountWaiveCancel}
        cancelText="Înapoi"
        width={600}
      >
        <Form
          form={scaleAmountWaiveModalForm}
          layout="vertical"
          onFinish={onScaleAmountWaiveModalSubmit}
        >
          <Row style={{ gap: '20px' }}>
            <Col>
              <Form.Item key="scaleAmountForWaive" label="Suma inițială">
                <InputNumber
                  disabled
                  value={scaleAmountWaiveInitialAmount || readOnly}
                />
              </Form.Item>
              <Form.Item
                key="waveReasonScale"
                name="waveReasonScale"
                label="Motivul aplicarii reducerii"
                rules={[
                  {
                    required: true,
                    message: 'Câmpul este obligatoriu',
                  },
                ]}
              >
                <TextArea
                  showCount
                  maxLength={2000}
                  disabled={readOnly}
                  rows={6}
                  style={{
                    textTransform: 'uppercase',
                  }}
                  onChange={(el) => {
                    if (el.target.value.length > 0) {
                      setScaleWaiveDisabled(false);
                    } else setScaleWaiveDisabled(true);
                  }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item key="scaleAmountWaiveType" label="Tipul de reducere">
                <Radio.Group
                  disabled={readOnly}
                  onChange={onScaleAmountWaiveTypeChange}
                  value={scaleAmountWaiveType}
                >
                  <Space direction="vertical">
                    <Radio value="TOTAL">Total</Radio>
                    <Radio value="PERCENT">Procent</Radio>
                    <Radio value="AMOUNT">Sumă</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              <Form.Item key="scaleAmountApplyWaive">
                <Button
                  onClick={scaleAmountApplyWaive}
                  disabled={
                    (scaleAmountWaiveType !== 'TOTAL' &&
                      scaleAmountWaive === 0.0) ||
                    scaleWaiveDisabled ||
                    readOnly
                  }
                >
                  Aplică
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item key="scaleAmountModalWaive" label="Reducerea">
                <InputNumber
                  step={0.01}
                  min={0.0}
                  max={
                    scaleAmountWaiveType === 'PERCENT'
                      ? 100.0
                      : scaleAmountWaiveInitialAmount
                  }
                  value={scaleAmountWaive}
                  onChange={setScaleAmountWaive}
                  disabled={scaleAmountWaiveType === 'TOTAL' || readOnly}
                />
              </Form.Item>

              <Form.Item
                key="scaleAmountModalWaiveResultAmount"
                label="Suma finală"
              >
                <InputNumber
                  precision={2}
                  step={0.01}
                  value={scaleAmountWaiveResultAmount}
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        maskClosable={false}
        centered
        id="antaAmountWaiveModal"
        visible={antaAmountWaiveModalVisible}
        title="Aplicarea reducerii la suma pentru ANTA"
        okButtonProps={{ disabled: !isAntaWaiveApplyClicked || readOnly }}
        onOk={antaAmountWaiveModalForm.submit}
        onCancel={handleAntaAmountWaiveCancel}
        cancelText="Înapoi"
        width={600}
      >
        <Form
          form={antaAmountWaiveModalForm}
          layout="vertical"
          onFinish={onAntaAmountWaiveModalSubmit}
        >
          <Row style={{ gap: '20px' }}>
            <Col>
              <Form.Item key="antaAmountForWaive" label="Suma inițială">
                <InputNumber disabled value={antaAmountWaiveInitialAmount} />
              </Form.Item>
              <Form.Item
                key="waveReasonAnta"
                name="waveReasonAnta"
                label="Motivul aplicarii reducerii"
                rules={[
                  {
                    required: true,
                    message: 'Câmpul este obligatoriu',
                  },
                ]}
              >
                <TextArea
                  showCount
                  maxLength={2000}
                  rows={6}
                  disabled={readOnly}
                  style={{
                    textTransform: 'uppercase',
                  }}
                  onChange={(el) => {
                    if (el.target.value.length > 0) {
                      setAntaWaiveDisabled(false);
                    } else setAntaWaiveDisabled(true);
                  }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item key="antaAmountWaiveType" label="Tipul de reducere">
                <Radio.Group
                  disabled={readOnly}
                  onChange={onAntaAmountWaiveTypeChange}
                  value={antaAmountWaiveType}
                >
                  <Space direction="vertical">
                    <Radio value="TOTAL">Total</Radio>
                    <Radio value="PERCENT">Procent</Radio>
                    <Radio value="AMOUNT">Sumă</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              <Form.Item key="antaAmountApplyWaive">
                <Button
                  onClick={antaAmountApplyWaive}
                  disabled={
                    (antaAmountWaiveType !== 'TOTAL' &&
                      antaAmountWaive === 0.0) ||
                    antaWaiveDisabled ||
                    readOnly
                  }
                >
                  Aplică
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item key="antaAmountModalWaive" label="Reducerea">
                <InputNumber
                  step={0.01}
                  min={0.0}
                  max={
                    antaAmountWaiveType === 'PERCENT'
                      ? 100.0
                      : antaAmountWaiveInitialAmount
                  }
                  value={antaAmountWaive}
                  onChange={setAntaAmountWaive}
                  disabled={antaAmountWaiveType === 'TOTAL' || readOnly}
                />
              </Form.Item>

              <Form.Item
                key="antaAmountModalWaiveResultAmount"
                label="Suma finală"
              >
                <InputNumber
                  precision={2}
                  step={0.01}
                  value={antaAmountWaiveResultAmount}
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        maskClosable={false}
        centered
        id="ansaAmountWaiveModal"
        visible={ansaAmountWaiveModalVisible}
        title="Aplicarea reducerii la suma pentru ANSA"
        okButtonProps={{ disabled: !isAnsaWaiveApplyClicked || readOnly }}
        onOk={ansaAmountWaiveModalForm.submit}
        onCancel={handleAnsaAmountWaiveCancel}
        cancelText="Înapoi"
        width={600}
      >
        <Form
          form={ansaAmountWaiveModalForm}
          layout="vertical"
          onFinish={onAnsaAmountWaiveModalSubmit}
        >
          <Row style={{ gap: '20px' }}>
            <Col>
              <Form.Item key="ansaAmountForWaive" label="Suma inițială">
                <InputNumber disabled value={ansaAmountWaiveInitialAmount} />
              </Form.Item>
              <Form.Item
                key="waveReasonAnsa"
                name="waveReasonAnsa"
                label="Motivul aplicarii reducerii"
                rules={[
                  {
                    required: true,
                    message: 'Câmpul este obligatoriu',
                  },
                ]}
              >
                <TextArea
                  showCount
                  maxLength={2000}
                  disabled={readOnly}
                  rows={6}
                  style={{
                    textTransform: 'uppercase',
                  }}
                  onChange={(el) => {
                    if (el.target.value.length > 0) {
                      setAnsaWaiveDisabled(false);
                    } else setAnsaWaiveDisabled(true);
                  }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item key="ansaAmountWaiveType" label="Tipul de reducere">
                <Radio.Group
                  onChange={onAnsaAmountWaiveTypeChange}
                  disabled={readOnly}
                  value={ansaAmountWaiveType}
                >
                  <Space direction="vertical">
                    <Radio value="TOTAL">Total</Radio>
                    <Radio value="PERCENT">Procent</Radio>
                    <Radio value="AMOUNT">Sumă</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              <Form.Item key="ansaAmountApplyWaive">
                <Button
                  onClick={ansaAmountApplyWaive}
                  disabled={
                    (ansaAmountWaiveType !== 'TOTAL' &&
                      ansaAmountWaive === 0.0) ||
                    ansaWaiveDisabled ||
                    readOnly
                  }
                >
                  Aplică
                </Button>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item key="ansaAmountModalWaive" label="Reducerea">
                <InputNumber
                  step={0.01}
                  min={0.0}
                  max={
                    ansaAmountWaiveType === 'PERCENT'
                      ? 100.0
                      : ansaAmountWaiveInitialAmount
                  }
                  value={ansaAmountWaive}
                  onChange={setAnsaAmountWaive}
                  disabled={ansaAmountWaiveType === 'TOTAL' || readOnly}
                />
              </Form.Item>
              <Form.Item
                key="ansaAmountModalWaiveResultAmount"
                label="Suma finală"
              >
                <InputNumber
                  precision={2}
                  step={0.01}
                  value={ansaAmountWaiveResultAmount}
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <ManuallyPaymentConfirmationModal
        visible={manualConfirmationVisible}
        transactionId={parameters.transactionId}
        onFinish={handleFinishManualConfirmationModal}
        onClose={handleCloseManualConfirmationModal}
      />
    </>
  );
};

export default BonDePlataParametersModal;
