import React, { useCallback, useContext } from 'react';
import { Button, Popconfirm } from 'antd';
import { stringLengthValidator } from '../../services/utils/validator/Validator';
import AuthContext from '../auth';
import UserFunctions from '../../enums/UserFunctions';

const ControlButton = ({
  isControl = true,
  onControl = () => {},
  onRetrieveControl = () => {},
  inspector,
  show = true,
  disabled = false,
}) => {
  const { user } = useContext(AuthContext);
  const renderButtons = useCallback(() => {
    if (!show) {
      return null;
    }

    if (isControl) {
      return (
        <Button
          onClick={onControl}
          className={`row-id-link ${
            inspector !== null ? 'btn__liber' : 'watch'
          } `}
          type={inspector !== null ? 'default' : 'primary'}
          block
          disabled={disabled}
        >
          {inspector !== null
            ? stringLengthValidator(inspector, 17)
            : 'Control'}
        </Button>
      );
    }

    return user.function === UserFunctions.SCANN_OPERATOR ? (
      <Button
        onClick={onControl}
        className={`row-id-link ${
          inspector !== null ? 'btn__liber' : 'watch'
        } `}
        type={inspector !== null ? 'default' : 'primary'}
        block
        disabled={disabled}
      >
        {inspector !== null ? stringLengthValidator(inspector, 17) : 'Control'}
      </Button>
    ) : (
      <Popconfirm
        placement="topLeft"
        title={`Ruta este deja luată la control de către ${inspector}. Sunteți sigur(ă) ca doriți să preluați controlul?`}
        onConfirm={onRetrieveControl}
        okText="Ok"
        cancelText="Înapoi"
        disabled={disabled}
      >
        <Button
          className="row-id-link take__btn"
          type="primary"
          block
          disabled={disabled}
        >
          {stringLengthValidator(inspector, 17)}
        </Button>
      </Popconfirm>
    );
  }, [
    disabled,
    inspector,
    isControl,
    onControl,
    onRetrieveControl,
    show,
    user.function,
  ]);

  return renderButtons();
};

export default ControlButton;
