import React, { useContext } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import { useTranslation } from 'react-i18next';
import AddEntityButton from '../../../../components/buttons/AddEntityButton';
import LegalPersonsRisksList from '../../../../components/lucrative/risks-actions/legal-persons/LegalPersonsRisksList';
import AuthContext, { hasPermission } from '../../../../components/auth';
import AddSMSPersonButton from '../../../../components/lucrative/risks-actions/AddSMSPersonButton';

const LegalPersonsRisks = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { permissions } = user;

  return (
    <>
      <PageHeader
        title={t('entity.lucrative.legal-persons._plural')}
        extra={[
          <AddEntityButton
            key="new"
            entity={t('entity.lucrative.legal-persons._singular')}
            path="/lucrative/risks-analisys/legal-persons/new"
          />,
          hasPermission(permissions, 'TAX_PERSONS_SMS_ADD') && (
            <AddSMSPersonButton />
          ),
        ]}
      />
      <LegalPersonsRisksList />
    </>
  );
};

export default LegalPersonsRisks;
