import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Input, Row, Col, Space } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import TextArea from 'antd/lib/input/TextArea';
import SaveButton from '../../../buttons/SaveButton';
import CancelButton from '../../../buttons/BackButton';

import useFormErrors from '../../../../hooks/useFormErrors';
import { useTableScroll } from '../../../../hooks/useTableScroll';
import { maxLengthValidator } from '../../../../services/utils/validator/Validator';

const EditVignetteErrorCodeForm = ({
  vignetteErrorCode,
  onSubmit,
  onCancel,
  errors,
}) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  useFormErrors(form, errors);
  useTableScroll();
  useEffect(() => {
    form.setFieldsValue(vignetteErrorCode);
  }, [vignetteErrorCode, form]);

  return (
    <>
      <PageHeader title={t('entity.admin.vignetteErrorCode._singular')} />
      <Form
        form={form}
        layout="vertical"
        initialValues={vignetteErrorCode}
        onFinish={onSubmit}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={t('entity.admin.vignetteErrorCode.code')}
              name="code"
            >
              <Input
                disabled={vignetteErrorCode?.deleted}
                onChange={(e) => {
                  form.setFieldsValue({
                    code: maxLengthValidator(e.target.value, 5),
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label={t('entity.admin.vignetteErrorCode.value')}
              name="value"
            >
              <TextArea
                showCount
                maxLength={1500}
                disabled={vignetteErrorCode?.deleted}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Space>
            <SaveButton disabled={vignetteErrorCode?.deleted} />
            <CancelButton onCancel={onCancel} />
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default EditVignetteErrorCodeForm;
