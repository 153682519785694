import React from 'react';
import { Button } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { stringLengthValidator } from '../../services/utils/validator/Validator';

const ViewButton = ({ onClick = () => {}, inspector, show = true }) => {
  return show ? (
    <Button
      onClick={onClick}
      className="row-id-link watch"
      type="primary"
      block
      style={{ marginTop: '10px' }}
    >
      <EyeOutlined />
      {inspector ? stringLengthValidator(inspector, 17) : ' Vizualizare'}
    </Button>
  ) : null;
};

export default ViewButton;
