import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import { useHistory } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Table,
  Button,
  Checkbox,
  Divider,
  notification,
  Modal,
  Tooltip,
  Popconfirm,
  Image,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import {
  CarTwoTone,
  SnippetsOutlined,
  FieldTimeOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import Column from '../../helpers/Columns';

import useDatasource from '../../hooks/useDatasource';
import {
  findAll as findAllTransactions,
  addMessage,
  startControl,
  retrieveControl,
  cancelDuplicatedTransaction,
} from '../../services/transactions/transactions';
import { findAll as findAllCustomsOffices } from '../../services/admin/users/customs-office';
import { findAll as findAllConstants } from '../../services/taxonomies/constants';
import useDictionaries from '../../hooks/useDictionaries';

import AddEntityButton from '../../components/buttons/AddEntityButton';
import AuthContext, { hasPermission } from '../../components/auth';
import { useTableScroll } from '../../hooks/useTableScroll';

import AppLoader from '../../components/auth/AppLoader';
import LiberModal from '../../components/admin/modals/LiberModal';
import aeoLogo from '../../assets/aeo-logo.png';
import CarColorChecker from '../CarColorChecker';
import CustomsPostSelect from '../../components/CustomsPostSelect';
import Icon from '../../components/Icon';
import { stringLengthValidator } from '../../services/utils/validator/Validator';

const dictionaries = {
  constants: findAllConstants,
  customsOffices: findAllCustomsOffices,
};

const Home = () => {
  const history = useHistory();
  useTableScroll();
  const MINUTE_MS = 10000;
  const [{ constants, customsOffices }] = useDictionaries(dictionaries);
  const { user } = useContext(AuthContext);
  const { permissions } = user;
  // eslint-disable-next-line
  const [rute, setRute] = useState({});
  const [loadingLoader, setLoadingLoader] = useState(false);
  const [postConstants, setPostConstants] = useState({
    hasAnsa: false,
    hasAnta: false,
    hasMdUa: false,
    hasScale: false,
    hasScan: false,
    hasWarehouse: false,
  });

  const isReadOnlyUser = useCallback(() => {
    return (
      user?.function === null ||
      user.function === 'POST_HEAD' ||
      user.function === 'OFFICE_HEAD' ||
      user.function === 'CENTRAL'
    );
  }, [user?.function]);

  useEffect(() => {
    if (user?.customsPost) {
      setPostConstants({
        hasAnsa: user?.customsPost?.hasAnsa,
        hasAnta: user?.customsPost?.hasAnta,
        hasMdUa: user?.customsPost?.hasMdUa,
        hasScale: user?.customsPost?.hasScale,
        hasScan: user?.customsPost?.hasScan,
      });
    }
  }, [user]);
  const [currentTransactionId, setCurrentTransactionId] = useState();
  const [messagesList, setMessagesList] = useState([]);
  const [isResponseModalVisible, setIsResponseModalVisible] = useState(false);
  const [currentTransactionMessage, setCurrentTransactionMessage] =
    useState('');

  const [inCheckbox, setInCheckbox] = useState(
    rute
      ? JSON.parse(localStorage.getItem('rute'))?.in
      : user?.direction === null || user?.direction === 'IN',
  );
  const [outCheckbox, setOutCheckbox] = useState(
    rute
      ? JSON.parse(localStorage.getItem('rute'))?.out
      : user?.direction === null || user?.direction === 'OUT',
  );
  const [isCargo, setIsCargo] = useState(
    rute ? JSON.parse(localStorage.getItem('rute'))?.cargo : false,
  );
  const [isAuto, setIsAuto] = useState(
    rute ? JSON.parse(localStorage.getItem('rute'))?.auto : false,
  );

  useEffect(() => {
    const ruteCheckboxes = {
      out: outCheckbox,
      in: inCheckbox,
      cargo: isCargo,
      auto: isAuto,
    };
    localStorage.setItem('rute', JSON.stringify(ruteCheckboxes));
  }, [outCheckbox, inCheckbox, isCargo, isAuto]);

  useEffect(() => {
    setRute(JSON.parse(localStorage.getItem('rute')));
  }, []);

  const getExecutionTime = useCallback(
    (category) => {
      const cargoCategories =
        constants?.content
          ?.find((el) => el.code === 'TERMINAL_MARFA_CATEGORII')
          ?.value?.split(',') || [];

      const isCargoVehicle = cargoCategories.includes(category);
      if (isCargoVehicle) {
        const timeInSeconds =
          constants?.content?.find(
            (el) => el.code === 'TRANSACTION_PROCESSING_TIME_CARGO',
          )?.value || 3600;
        return Math.floor(timeInSeconds / 60);
      }
      const timeInSeconds =
        constants?.content?.find(
          (el) => el.code === 'TRANSACTION_PROCESSING_TIME_AUTO',
        )?.value || 900;
      return Math.floor(timeInSeconds / 60);
    },
    [constants?.content],
  );

  const alowedCategories = useCallback(() => {
    const cargoCategories =
      constants?.content
        ?.find((el) => el.code === 'TERMINAL_MARFA_CATEGORII')
        ?.value?.split(',') || [];

    const autoCategories =
      constants?.content
        ?.find((el) => el.code === 'TERMINAL_PASAGERI_CATEGORII')
        ?.value?.split(',') || [];

    if (isCargo && isAuto) {
      return cargoCategories.concat(autoCategories);
    }
    if (isCargo && !isAuto) {
      return cargoCategories;
    }
    if (isAuto && !isCargo) {
      return autoCategories;
    }
    return ['NO_CATEGORY'];
  }, [constants?.content, isAuto, isCargo]);

  const handler = useCallback(
    (...params) => {
      const hz = params[0];
      hz.sort = ['id', 'desc'];
      hz.criterias = {
        ...hz.criterias,
        postId:
          (user.postId && [user.postId]) ||
          (user.officeId &&
            customsOffices?.content
              .find((el) => el.id === user.officeId)
              ?.customsPosts?.map((el) => el.id)),
        direction:
          // eslint-disable-next-line
          inCheckbox && outCheckbox
            ? ['IN', 'OUT']
            : // eslint-disable-next-line
            inCheckbox
            ? ['IN']
            : outCheckbox
            ? ['OUT']
            : ['INOUT'],
        category: alowedCategories(),
      };
      if (constants) {
        return findAllTransactions(hz);
      }
      return [];
    },
    [
      user,
      constants,
      inCheckbox,
      outCheckbox,
      customsOffices,
      alowedCategories,
    ],
  );

  const { pagination, content, handleChange, reload } = useDatasource(handler);

  useEffect(() => {
    reload();
  }, [customsOffices, reload]);

  useEffect(() => {
    const interval = setInterval(() => {
      reload();
    }, MINUTE_MS);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  const handleCancelTransaction = useCallback((transaction) => {
    cancelDuplicatedTransaction(transaction.id).then((res) => {
      if (res) {
        reload();
        notification.success({
          message: 'Tranzacția a fost anulată cu succes',
        });
      }
    });
    // eslint-disable-next-line
  }, []);

  const handleOk = () => {
    const isFound = !!messagesList.find(
      (message) => message.transactionId === currentTransactionId,
    );

    if (!isFound) {
      addMessage({
        transactionId: currentTransactionId,
        message: currentTransactionMessage,
      })
        .then((res) =>
          setMessagesList([
            ...messagesList,
            {
              transactionId: currentTransactionId,
              message: currentTransactionMessage,
              id: res.id,
            },
          ]),
        )
        .catch(() => {
          setIsResponseModalVisible(false);
          notification.error({
            message: 'Răspunsul nu a putut fi adăugat',
          });
        });
    }
    setCurrentTransactionId(null);
    setCurrentTransactionMessage('');
    setIsResponseModalVisible(false);
  };
  const handleCancel = () => {
    setCurrentTransactionId(null);
    setCurrentTransactionMessage('');
    setIsResponseModalVisible(false);
  };

  const generateRowTrafficLight = (currentRow, organisation) => {
    const {
      hasAnsa,
      hasAnta,
      hasMdUa,
      hasScale,
      hasScan,
      hasWarehouse,
      isAnsaConfirmed,
      isAntaConfirmed,
      isMdUaConfirmed,
      isScaleConfirmed,
      isScanConfirmed,
      isScanConfirmedByOperator,
      isWarehouseConfirmed,
    } = currentRow;

    // eslint-disable-next-line consistent-return
    const generateIcons = (type) => {
      switch (type) {
        case 'ansa':
          if (!hasAnsa) {
            return <div className="icon-warning " />;
          }
          if (hasAnsa && !isAnsaConfirmed) {
            return <div className="icon-danger" />;
          }
          if (hasAnsa && isAnsaConfirmed) {
            return <div className="icon-success" />;
          }
          break;
        case 'anta':
          if (!hasAnta) {
            return <div className="icon-warning " />;
          }
          if (hasAnta && !isAntaConfirmed) {
            return <div className="icon-danger" />;
          }
          if (hasAnta && isAntaConfirmed) {
            return <div className="icon-success" />;
          }
          break;
        case 'mdua':
          if (!hasMdUa) {
            return <div className="icon-warning " />;
          }
          if (hasMdUa && !isMdUaConfirmed) {
            return <div className="icon-danger" />;
          }
          if (hasMdUa && isMdUaConfirmed) {
            return <div className="icon-success" />;
          }
          break;
        case 'scale':
          if (!hasScale) {
            return <div className="icon-warning " />;
          }
          if (hasScale && !isScaleConfirmed) {
            return <div className="icon-danger" />;
          }
          if (hasScale && isScaleConfirmed) {
            return <div className="icon-success" />;
          }
          break;
        case 'scan':
          if (!hasScan) {
            return <div className="icon-warning " />;
          }
          if (hasScan && (!isScanConfirmed || !isScanConfirmedByOperator)) {
            return <div className="icon-danger" />;
          }
          if (hasScan && isScanConfirmed && isScanConfirmedByOperator) {
            return <div className="icon-success" />;
          }
          break;
        case 'warehouse':
          if (!hasWarehouse) {
            return <div className="icon-warning " />;
          }
          if (hasWarehouse && !isWarehouseConfirmed) {
            return <div className="icon-danger" />;
          }
          if (hasWarehouse && isWarehouseConfirmed) {
            return <div className="icon-success" />;
          }
          break;
        default:
          break;
      }
    };
    return <>{generateIcons(organisation)}</>;
  };

  // const showCurrentImage = useCallback(() => {
  //   return image != null && image.id != null ? (
  //     <div className="popover__icon">
  //       <Image
  //         src={`data:${image?.imageType};base64,${image?.image}`}
  //         width={240}
  //         height={140}
  //       />
  //     </div>
  //   ) : null;
  // }, [image]);

  const renderTimeColor = useCallback(
    (row, minutes) => {
      const mainVehicle = row.vehicles?.find(
        (auto) => !auto.isTrailer && auto.isTrailer !== null,
      );
      const timeInMinutes = getExecutionTime(mainVehicle?.category);
      if (minutes) {
        if (minutes < timeInMinutes) {
          return (
            <div
              className="vehicle__time"
              style={{
                color: '#4FC878',
                display: 'flex',
                gap: '5px',
              }}
            >
              <FieldTimeOutlined />
              {minutes}
            </div>
          );
        }
        return (
          <div
            className="vehicle__time"
            style={{
              color: '#E6484B',
              display: 'flex',
              gap: '5px',
            }}
          >
            <FieldTimeOutlined />
            {minutes}
          </div>
        );
      }
      return null;
    },
    [getExecutionTime],
  );

  const handleControl = useCallback(
    (row) => {
      if (!isReadOnlyUser()) {
        setLoadingLoader(true);
        startControl(row.id)
          .then(() => {
            history.push(`/physical-person-entry/${row.id}`);
          })
          .catch((msg) => {
            const { inner } = msg || {};
            const { _: messageCode } = inner || {};
            notification.error({
              message: messageCode,
            });
          })
          .finally(() => {
            setLoadingLoader(false);
          });
      } else {
        history.push(`/physical-person-entry/${row.id}`);
      }
    },
    [history, isReadOnlyUser],
  );

  const handleRetrieveControl = useCallback(
    (row) => {
      setLoadingLoader(true);
      retrieveControl(row.id)
        .then(() => {
          history.push(`/physical-person-entry/${row.id}`);
        })
        .catch((msg) => {
          const { inner } = msg || {};
          const { _: messageCode } = inner || {};
          notification.error({
            message: messageCode,
          });
        })
        .finally(() => {
          setLoadingLoader(false);
        });
    },
    [history],
  );

  const columns = useMemo(() => {
    const c = [
      {
        sort: false,
        width: 40,
        render: (row) => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {row.isDuplicated && (
                <Tooltip title="Duplicare">
                  <SnippetsOutlined
                    className="snippetsOutlined"
                    style={{
                      fontSize: '1.4rem',
                    }}
                  />
                </Tooltip>
              )}
              {row.personList?.find((el) => !el.isPhysicalPerson)?.isAeo && (
                <Image src={aeoLogo} width={40} />
              )}
            </div>
          );
        },
      },
      Column.date('createdAt', 'Data', {
        width: 90,
        sort: false,
        format: 'DD.MM.YY HH:mm:ss',
      }),
      Column.text('daysInRMFromLastEntry', 'Zile în RM', {
        sort: false,
        width: 60,
      }),
      user?.customsPost?.hasSeed && {
        sort: false,
        title: 'Fără marfă (SEED)',
        key: 'isSeedReceived',
        width: 80,
        dataIndex: 'isSeedReceived',
        render: (code, row) => {
          return <>{row.isSeedReceived && <div className="icon-success" />}</>;
        },
      },
      // Column.dictionary('direction', 'Direcția', directions, {
      //   sort: false,
      //   width: 90,
      //   filter: false,
      // }),
      {
        title: 'Direcția',
        key: 'direction',
        sort: false,
        width: 100,
        filter: false,
        align: 'center',
        render: (code, row) => {
          return (
            <>
              {row?.direction?.toUpperCase() === 'IN' ? (
                <div className="direction__enter">
                  <Icon name="enter" className="direction__enter-icon" />
                </div>
              ) : (
                <div className="direction__exit">
                  <Icon name="exit" className="direction__exit-icon" />
                </div>
              )}
            </>
          );
        },
      },
      {
        title: 'Tip, Culoare',
        key: 'category',
        width: 85,
        dataIndex: 'category',
        align: 'center',
        render: (code, row) => {
          const vehicle = row.vehicles?.find((el) => !el.isTrailer);
          return (
            <CarColorChecker
              color={vehicle?.color}
              category={vehicle?.category}
            />
          );
        },
      },
      {
        sort: false,
        title: 'Marcă, Model',
        key: 'vehicleData',
        width: 120,
        render: (code, row) => {
          return (
            <>{`${row?.mark != null ? row?.mark.toUpperCase() : ''} ${
              row?.model != null ? row?.model.toUpperCase() : ''
            }`}</>
          );
        },
      },
      Column.text('allPlateNumbers', 'Nr. auto', {
        sort: false,
        filter: true,
        width: 100,
      }),
      Column.text('idnp', 'Pașaport', {
        width: 135,
        filter: true,
        sort: false,
      }),
      {
        sort: false,
        title: 'Nume',
        key: 'fullName',
        width: 180,
        dataIndex: 'fullName',
        render: (code, row) => {
          return (
            <>
              {row.personList &&
                row.personList
                  .find((r) => r.isPhysicalPerson && r.isCrossing)
                  ?.fullName?.toUpperCase()}
            </>
          );
        },
      },
      {
        title: 'Durată așteptare',
        sort: false,
        width: 75,
        render: (row) => {
          return renderTimeColor(row, row.crossingTimeTotalMinutes);
        },
      },
      {
        title: 'Durată control',
        sort: false,
        width: 80,
        render: (row) => {
          return renderTimeColor(row, row.crossingTimeMinutes);
        },
      },
      postConstants?.hasScale && isCargo
        ? {
            sort: false,
            title: 'Cântar',
            key: 'isScaleConfirmed',
            width: 75,
            dataIndex: 'isScaleConfirmed',
            align: 'center',
            render: (code, row) => generateRowTrafficLight(row, 'scale'),
          }
        : null,
      postConstants?.hasAnta && isCargo
        ? {
            sort: false,
            title: 'ANTA',
            key: 'isAntaConfirmed',
            width: 70,
            dataIndex: 'isAntaConfirmed',
            align: 'center',
            render: (code, row) => generateRowTrafficLight(row, 'anta'),
          }
        : null,
      postConstants?.hasAnsa && isCargo
        ? {
            sort: false,
            title: 'ANSA',
            key: 'isAnsaConfirmed',
            width: 70,
            dataIndex: 'isAnsaConfirmed',
            align: 'center',
            render: (code, row) => generateRowTrafficLight(row, 'ansa'),
          }
        : null,
      postConstants?.hasScan && isCargo
        ? {
            sort: false,
            title: 'SCAN',
            key: 'isScanConfirmed',
            width: 70,
            dataIndex: 'isScanConfirmed',
            align: 'center',
            render: (code, row) => generateRowTrafficLight(row, 'scan'),
          }
        : null,
      postConstants?.hasMdUa && isCargo
        ? {
            sort: false,
            title: 'MDUA',
            key: 'isMdUaConfirmed',
            width: 75,
            dataIndex: 'isMdUaConfirmed',
            align: 'center',
            render: (code, row) => generateRowTrafficLight(row, 'mdua'),
          }
        : null,
      {
        sort: false,
        title: 'Acțiune',
        width: 175,
        fixed: 'right',
        align: 'center',
        render: (code, row) => {
          return (
            <>
              {(!row.inspectorId || row.inspectorId === user.id) && (
                <>
                  {row.inspectorId && row.inspectorId === user.id && (
                    <Button
                      onClick={() => handleControl(row)}
                      className="row-id-link"
                      type="primary"
                      block
                    >
                      {
                        // eslint-disable-next-line
                        isReadOnlyUser() ? (
                          <>
                            <EyeOutlined /> Vizualizare
                          </>
                        ) : (
                          stringLengthValidator(row.inspector, 17)
                        )
                      }
                    </Button>
                  )}
                  {!row.inspectorId && (
                    <Button
                      onClick={() => handleControl(row)}
                      className="row-id-link"
                      type="primary"
                      block
                    >
                      {isReadOnlyUser() ? (
                        <>
                          <EyeOutlined /> Vizualizare
                        </>
                      ) : (
                        'Control'
                      )}
                    </Button>
                  )}
                  {row.isGeneralDataConfirmed &&
                    hasPermission(permissions, 'ACCEPT_TRANSACTION') && (
                      <LiberModal
                        transactionId={row.id}
                        hasScale={row.hasScale}
                        readOnly={
                          user?.function === null ||
                          user.function === 'POST_HEAD' ||
                          user.function === 'OFFICE_HEAD' ||
                          user.function === 'CENTRAL'
                        }
                      />
                    )}
                  {row.isDuplicated && (
                    <Popconfirm
                      placement="topLeft"
                      title="Sunteți sigur?"
                      onConfirm={() => handleCancelTransaction(row)}
                      okText="Ok"
                      cancelText="Înapoi"
                      disabled={
                        user?.function === null ||
                        user.function === 'POST_HEAD' ||
                        user.function === 'OFFICE_HEAD' ||
                        user.function === 'CENTRAL'
                      }
                    >
                      <Button
                        className="cancellation__btn"
                        style={{ marginTop: '10px' }}
                        type="primary"
                        block
                        disabled={
                          user?.function === null ||
                          user.function === 'POST_HEAD' ||
                          user.function === 'OFFICE_HEAD' ||
                          user.function === 'CENTRAL'
                        }
                      >
                        Anulare rută
                      </Button>
                    </Popconfirm>
                  )}
                </>
              )}

              {row.inspectorId &&
                row.inspectorId !== user.id &&
                (!isReadOnlyUser() ? (
                  <Popconfirm
                    placement="topLeft"
                    title={`Ruta este deja luată la control de către ${row.inspector}. Sunteți sigur(ă) ca doriți să preluați controlul?`}
                    onConfirm={() => handleRetrieveControl(row)}
                    okText="Ok"
                    cancelText="Înapoi"
                  >
                    <Button
                      className="row-id-link btn__taking"
                      type="primary"
                      block
                    >
                      {stringLengthValidator(row.inspector, 17)}
                    </Button>
                  </Popconfirm>
                ) : (
                  <Button
                    onClick={() =>
                      history.push(`/physical-person-entry/${row.id}`)
                    }
                    className="row-id-link btn__taking"
                    type="primary"
                    block
                  >
                    <EyeOutlined /> Vizualizare
                  </Button>
                ))}
            </>
          );
        },
      },
    ];
    return c.filter((el) => el);
  }, [
    user,
    history,
    isCargo,
    permissions,
    postConstants,
    handleControl,
    isReadOnlyUser,
    renderTimeColor,
    handleRetrieveControl,
    handleCancelTransaction,
  ]);

  return (
    <>
      <div className={`loaderOverlay ${loadingLoader ? 'active' : ''}`}>
        <AppLoader />
      </div>

      <PageHeader
        ghost={false}
        title={
          <div>
            <span>Rute</span>
            <CarTwoTone style={{ fontSize: '1.5rem', marginLeft: '.5rem' }} />
          </div>
        }
        subTitle={
          <div
            style={{
              marginLeft: '2rem',
              display: 'flex',
            }}
          >
            <Checkbox
              defaultChecked={inCheckbox}
              disabled={
                user.function === 'INSPECTOR' && user.direction !== null
              }
              onChange={() => {
                setInCheckbox(!inCheckbox);
                reload();
              }}
              className={`checkbox__background direction__checkbox
              ${inCheckbox ? 'active' : 'pasive'}`}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '5px',
                }}
              >
                <Icon
                  name="enter"
                  style={{
                    width: '18px',
                    height: '18px',
                    fill: '#000',
                    marginLeft: '10px',
                  }}
                />

                <span style={{ fontSize: '1.1rem', fontWeight: '600' }}>
                  Intrare
                </span>
              </div>
            </Checkbox>

            <Checkbox
              defaultChecked={outCheckbox}
              disabled={
                user.function === 'INSPECTOR' && user.direction !== null
              }
              onChange={() => {
                setOutCheckbox(!outCheckbox);
                reload();
              }}
              className={`checkbox__background direction__checkbox
              ${outCheckbox ? 'active' : 'pasive'}`}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '5px',
                  // transform: 'translateY(3px)',
                }}
              >
                <Icon
                  name="exit"
                  className="direction__enter-icon"
                  style={{
                    width: '17px',
                    height: '17px',
                    fill: '#000',
                    marginLeft: '10px',
                  }}
                />

                <span style={{ fontSize: '1.1rem', fontWeight: '600' }}>
                  Ieșire
                </span>
              </div>
            </Checkbox>
            <Checkbox
              defaultChecked={isAuto}
              onChange={() => {
                setIsAuto(!isAuto);
                reload();
              }}
              className={`checkbox__background 
              ${isAuto ? 'active' : 'pasive'}`}
            >
              <span style={{ fontSize: '1.1rem', fontWeight: '600' }}>
                Autoturisme
              </span>
            </Checkbox>
            <Checkbox
              defaultChecked={isCargo}
              onChange={() => {
                setIsCargo(!isCargo);
                reload();
              }}
              className={`checkbox__background 
              ${isCargo ? 'active' : 'pasive'}`}
            >
              <span style={{ fontSize: '1.1rem', fontWeight: '600' }}>
                Camioane
              </span>
            </Checkbox>
            <div className="post__vamal">
              <div>
                {user.customsPost !== null
                  ? `Post Vamal: ${user.customsPost?.name || ''} (${
                      user.customsPost?.newCode || ''
                    })`
                  : null}
              </div>
              <div className="header__curs">
                <div>{`Curs valutar (EUR): ${user?.currency || ''}`}</div>
              </div>
            </div>
          </div>
        }
        extra={
          <div style={{ display: 'flex', gap: '20px', width: '100%' }}>
            {user?.function === 'POST_HEAD' ||
              (user?.function === 'OFFICE_HEAD' && (
                <div className="home__post">
                  <CustomsPostSelect />
                </div>
              ))}
            {user?.customsPost?.addTrackButtonEnabled && (
              <AddEntityButton
                key="new"
                disabled={!hasPermission(permissions, 'CREATE_TRANSACTION')}
                path="/physical-person-entry/new"
              />
            )}
          </div>
        }
      >
        <Divider plain />
        <Table
          className="home-table"
          columns={columns}
          rowKey="id"
          // pagination={{
          //   pageSizeOptions: [8, 20, 50, 100],
          //   defaultPageSize: 8,
          //   showSizeChanger: true,
          // }}
          // onRow={(r) => ({
          //   onMouseEnter: () => getImage(r?.id),
          // })}
          rowClassName={(record) => {
            if (
              record.instructed === 'DANGER' ||
              record?.irregularities?.length > 0
            ) {
              return 'instructed-danger';
            }
            if (
              record.instructed === 'WARNING' ||
              record?.daysInRMFromLastEntry > 180
            ) {
              return 'instructed-warning';
            }
            if (record.instructed === 'SUCCESS') {
              return 'instructed-success';
            }
            return record;
          }}
          pagination={pagination}
          dataSource={content}
          onChange={handleChange}
          scroll={{ x: 500 }}
        />
      </PageHeader>
      <Modal
        maskClosable={false}
        centered
        style={{ width: '400px' }}
        title="Adaugă răspuns"
        visible={isResponseModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Înapoi"
      >
        <span>Răspuns</span>
        <TextArea
          showCount
          maxLength={3000}
          value={currentTransactionMessage}
          onChange={(evt) => setCurrentTransactionMessage(evt.target.value)}
          id="response"
          rows={6}
          style={{
            textTransform: 'uppercase',
          }}
        />
      </Modal>
    </>
  );
};

export default Home;
