import React, { useContext } from 'react';
import { Divider } from 'antd';
import { useEditPage } from '../../../hooks/useEditPage';
import EditUserPasswordForm from '../../../components/admin/users/EditUserPasswordForm';
import AuthContext from '../../../components/auth';
import PersonalData from './PersonalData';

const initial = {};

const EditUserPassword = () => {
  const [errors] = useEditPage({ initial });
  const { user } = useContext(AuthContext);

  return (
    <>
      {user !== undefined && (
        <>
          <PersonalData />
          <Divider />
          <EditUserPasswordForm user={user} errors={errors} />
        </>
      )}
    </>
  );
};

export default EditUserPassword;
