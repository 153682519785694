import { genericService, put } from '../../../helpers/api';

export const SERVICE_URI = '/backend';
export const BASE_URI = '/api/v1/legal-persons-risk';

export const { findAll, findOne, create, update, remove } = genericService(
  SERVICE_URI,
  BASE_URI,
);

export const changeStatus = (entity) =>
  put(`${SERVICE_URI}${BASE_URI}/change-status/${entity.id}`);
