import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  notification,
  Row,
  Space,
} from 'antd';
import dayjs from 'dayjs';
import SaveButton from '../../../buttons/SaveButton';
import CancelButton from '../../../buttons/BackButton';
import useFormErrors from '../../../../hooks/useFormErrors';
import GenericRiskEditForm from '../GenericRiskEditForm';
import PERSON_TYPES from '../../../../enums/PersonTypes';
import { maxLengthValidator } from '../../../../services/utils/validator/Validator';
import { getPersonByIdnp } from '../../../../services/admin/web-services/sfs-organizatii';
import AppLoader from '../../../auth/AppLoader';
import { validatePhysicalPersonRisk } from './PhysicalPersonsRiskValidation';
import { validateGenericRisk } from '../GenericRiskValidation';

const PhysicalPersonsRisksEditForm = ({
  risk,
  copy,
  onSubmit,
  onCancel,
  errors,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loadingLoader, setLoadingLoader] = useState(false);
  const isRiskDeleted = useMemo(() => risk?.deleted, [risk?.deleted]);

  useFormErrors(form, errors);

  const handleFinish = useCallback(
    (values) => {
      if (!validatePhysicalPersonRisk(form)) {
        return;
      }
      if (!validateGenericRisk(form)) {
        return;
      }

      if (values.isSms && !values.smsText) {
        notification.error({ message: 'Adăugați textul mesajului SMS' });
        return;
      }

      const persons = values.persons?.map((id) => ({ smsPersonId: id }));
      const posts = values.posts?.map((code) => ({ postCode: code }));

      onSubmit({
        ...values,
        persons,
        posts,
        rezident: values.rezident
          ? PERSON_TYPES.REZIDENT
          : PERSON_TYPES.NEREZIDENT,
      });
    },
    [onSubmit, form],
  );

  const handleSearchPersonByIDNP = useCallback(
    (idnp) => {
      const title = 'Căutare persoană';
      if (idnp && idnp.trim().length === 13) {
        setLoadingLoader(true);
        getPersonByIdnp(idnp.trim())
          .then((pers) => {
            setLoadingLoader(false);
            if (pers && pers.idnp) {
              form.setFieldsValue({
                firstName: pers.firstName,
                lastName: pers.lastName,
              });
            } else {
              setLoadingLoader(false);
              notification.warning({
                message: title,
                description: 'Nu a fost gasită persoană cu IDNP-ul introdus.',
              });
            }
          })
          .catch(() => {
            setLoadingLoader(false);
            notification.error({
              message: title,
              description: 'Eroare la căutarea persoanei.',
            });
          });
      }
    }, // eslint-disable-next-line
    [form],
  );

  useEffect(() => {
    if (!risk?.id && copy?.id) {
      form.setFieldsValue({
        ...copy,
        posts: copy.posts?.map((el) => el.postCode),
        persons: copy.persons?.map((el) => el.smsPersonId),
        rezident: copy?.rezident === PERSON_TYPES.REZIDENT,
        validFrom: copy?.validFrom ? dayjs(copy.validFrom) : undefined,
        validTo: copy?.validTo ? dayjs(copy.validTo) : undefined,
        createdAt: copy?.createdAt ? dayjs(copy.createdAt) : undefined,
        updatedAt: copy?.updatedAt ? dayjs(copy.updatedAt) : undefined,
        birthDate: copy?.birthDate ? dayjs(copy.birthDate) : undefined,
      });
    }
  }, [form, risk?.id, copy]);

  return (
    <>
      <div className={`loaderOverlay ${loadingLoader ? 'active' : ''}`}>
        <AppLoader />
      </div>

      <PageHeader title={t('entity.lucrative.physical-persons._singular')} />
      <Form
        form={form}
        onFinish={handleFinish}
        layout="vertical"
        initialValues={{
          ...risk,
          posts: risk.posts?.map((el) => el.postCode),
          persons: risk.persons?.map((el) => el.smsPersonId),
          rezident: risk?.rezident === PERSON_TYPES.REZIDENT,
          validFrom: risk?.validFrom ? dayjs(risk.validFrom) : undefined,
          validTo: risk?.validTo ? dayjs(risk.validTo) : undefined,
          createdAt: risk?.createdAt ? dayjs(risk.createdAt) : undefined,
          updatedAt: risk?.updatedAt ? dayjs(risk.updatedAt) : undefined,
          birthDate: risk?.birthDate ? dayjs(risk.birthDate) : undefined,
        }}
      >
        <Row gutter={15}>
          <Col span={6}>
            <Form.Item
              label={t('entity.lucrative.physical-persons.idnp')}
              name="idnp"
            >
              <Input
                className="pasaport__number"
                onChange={(e) => {
                  form.setFieldsValue({
                    idnp: maxLengthValidator(e.target.value, 13),
                  });
                  handleSearchPersonByIDNP(e.target.value);
                }}
                disabled={risk.deleted}
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              name="firstName"
              labelCol={{ span: 24 }}
              label={t('entity.lucrative.physical-persons.firstName')}
              rules={[
                {
                  required: true,
                  message: 'Câmpul este obligatoriu',
                },
              ]}
            >
              <Input
                disabled={risk.deleted}
                onChange={(e) => {
                  form.setFieldsValue({
                    firstName: maxLengthValidator(e.target.value, 150),
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="lastName"
              labelCol={{ span: 24 }}
              label={t('entity.lucrative.physical-persons.lastName')}
              rules={[
                {
                  required: true,
                  message: 'Câmpul este obligatoriu',
                },
              ]}
            >
              <Input
                disabled={risk.deleted}
                onChange={(e) => {
                  form.setFieldsValue({
                    lastName: maxLengthValidator(e.target.value, 150),
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="birthDate"
              label={t('entity.lucrative.physical-persons.birthDate')}
              labelCol={{ span: 24 }}
            >
              <DatePicker
                disabled={risk.deleted}
                format="DD.MM.YYYY"
                placeholder="Selectați data"
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name="rezident" valuePropName="checked">
          <Checkbox defaultChecked="false" disabled={risk.deleted}>
            {t('entity.lucrative.physical-persons.rezident')}
          </Checkbox>
        </Form.Item>

        <GenericRiskEditForm
          existent={risk}
          disabled={risk?.deleted}
          form={form}
        />

        <Form.Item>
          <Space>
            <CancelButton onCancel={onCancel} />
            <SaveButton disabled={isRiskDeleted} />
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default PhysicalPersonsRisksEditForm;
