import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import ServicesDescriptionANTAList from '../../../../components/admin/taxonomies/servicesDescriptionANTA/ServicesDescriptionANTAList';

const ServicesDescriptionANTA = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="servicesDescriptionANTA">
      <Tabs.TabPane
        tab={t('entity.admin.serviceDescription._plural')}
        key="servicesDescriptionANTA"
      >
        <ServicesDescriptionANTAList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default ServicesDescriptionANTA;
