import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@ant-design/pro-layout';
import { Form, Input, Row, Col, Space } from 'antd';

import SaveButton from '../../../buttons/SaveButton';
import CancelButton from '../../../buttons/BackButton';

import useFormErrors from '../../../../hooks/useFormErrors';
import { maxLengthValidator } from '../../../../services/utils/validator/Validator';

const EditCountryForm = ({ post, onSubmit, onCancel, errors }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  useFormErrors(form, errors);

  useEffect(() => {
    form.setFieldsValue(post);
  }, [post, form]);

  return (
    <>
      <PageHeader title={t('entity.admin.country._singular')} />
      <Form
        form={form}
        layout="vertical"
        initialValues={post}
        onFinish={onSubmit}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label={t('entity.admin.country.alpha2')} name="alpha2">
              <Input maxLength={2} minLength={2} disabled={post.deleted} />
            </Form.Item>
            <Form.Item label={t('entity.admin.country.alpha3')} name="alpha3">
              <Input maxLength={3} minLength={3} disabled={post.deleted} />
            </Form.Item>

            <Form.Item label={t('entity.admin.country.name')} name="name">
              <Input
                disabled={post.deleted}
                onChange={(e) => {
                  form.setFieldsValue({
                    name: maxLengthValidator(e.target.value, 120),
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Space>
            <SaveButton disabled={post.deleted} />
            <CancelButton onCancel={onCancel} />
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default EditCountryForm;
