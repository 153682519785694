import { post, put, remove } from '../../helpers/api';

export const SERVICE_URI = '/backend';
export const BASE_URI = '/api/v1/mdua-declaration';

export const addDeclaration = (payload) =>
  post(`${SERVICE_URI}${BASE_URI}/add/${payload.transactionId}`, payload);

export const editDeclaration = (payload) =>
  put(`${SERVICE_URI}${BASE_URI}/edit/${payload.transactionId}`, payload);

export const removeDeclaration = (payload) =>
  remove(
    `${SERVICE_URI}${BASE_URI}/delete/${payload.transactionId}/${payload.declarationId}`,
  );
