import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import VehicleTypeList from '../../../../components/admin/taxonomies/vehicleTypes/VehicleTypeList';

const VehicleTypes = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="vehicleTypes">
      <Tabs.TabPane
        tab={t('entity.admin.vehicleType._plural')}
        key="vehicleType"
      >
        <VehicleTypeList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default VehicleTypes;
