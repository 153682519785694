import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, notification, Table, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@ant-design/icons';
import { useTableScroll } from '../../../../hooks/useTableScroll';
import useDatasource from '../../../../hooks/useDatasource';
import Column from '../../../../helpers/Columns';
import { findAllMessages } from '../../chat-service/chat-management';
import { findAllByIds } from '../../../../services/admin/users/users';
import { downloadAttachments } from '../../chat-service/attachment-service';

const USERS_BATCH_SIZE = 100;
const MessagesList = ({ chatId }) => {
  const { t } = useTranslation();
  useTableScroll();

  const [readByUsers, setReadByUsers] = useState([]);
  const [readByIds, setReadByIds] = useState([]);

  const handler = useCallback(
    (...params) => {
      const hz = params[0];
      hz.sort = ['messageDate', 'desc'];
      hz.criterias = {
        ...hz.criterias,
        chatId,
      };
      return findAllMessages(hz);
    },
    [chatId],
  );

  const { loading, pagination, content, handleChange } = useDatasource(handler);

  useEffect(() => {
    const usersIds = new Set(
      content?.map((el) => el.readBy).flatMap((item) => item),
    );
    if (usersIds?.size > 0) {
      setReadByIds(Array.from(usersIds));
    }
  }, [content]);

  useEffect(() => {
    if (readByIds?.length > 0) {
      const batchSize = USERS_BATCH_SIZE;
      for (let i = 0; i < readByIds.length; i += batchSize) {
        const batch = readByIds.slice(i, i + batchSize);
        findAllByIds(batch).then((res) =>
          setReadByUsers((prev) => [
            ...prev,
            ...res.filter(
              (item) => !prev.some((prevItem) => prevItem.id === item.id),
            ),
          ]),
        );
      }
    }
  }, [readByIds]);

  const renderUsers = useCallback(
    (ids = []) => {
      if (readByUsers?.length > 0) {
        const present = readByUsers.filter((el) => ids.includes(el.id));
        const stringResult = present?.map((el) => el.fullname).join(', ');
        if (stringResult?.length > 50) {
          return (
            <Tooltip title={stringResult}>
              {`${stringResult.substring(0, 30)}...`}
            </Tooltip>
          );
        }
        return stringResult;
      }
      return ' ';
    },
    [readByUsers],
  );

  const handleDownload = (messageId) => {
    downloadAttachments(messageId).catch(() => {
      notification.error({ message: 'Fișierele nu pot fi descărcate' });
    });
  };

  const columns = useMemo(() => {
    const columnsArray = [
      Column.text('senderFullName', 'Trimis de', {
        filter: true,
        width: 100,
      }),
      Column.text('messageBody', 'Text', {
        filter: true,
        width: 100,
      }),
      Column.date('messageDate', 'Trimis la', {
        filter: true,
        format: 'DD.MM.YY HH:mm:ss',
        width: 150,
      }),
      Column.text('messageType', 'Tip mesaj', {
        sort: false,
        width: 150,
      }),
      ...(content.some((entity) => entity.editDate !== null)
        ? [
            Column.date('editDate', 'Editat la', {
              format: 'DD.MM.YY HH:mm:ss',
              width: 150,
            }),
          ]
        : []),
      ...(content.some((entity) => entity.attachments?.length > 0)
        ? [
            Column.other(
              'attachments',
              'Atașamente',
              (code, row) => {
                return (
                  <>
                    <Tooltip title="Descarcă">
                      <Button
                        icon={<DownloadOutlined />}
                        type="link"
                        onClick={() => handleDownload(row?.id)}
                        hidden={!row.attachments?.length > 0}
                      >
                        Descarcă
                      </Button>
                    </Tooltip>
                  </>
                );
              },
              {
                sort: false,
                width: 50,
              },
            ),
          ]
        : []),
      ...(content.some((entity) => entity.reply?.messageBody !== null)
        ? [
            Column.other(
              'reply',
              'Răspuns la',
              (code, row) => {
                return row.reply?.attachments
                  ? 'Atașamente'
                  : row.reply?.messageBody;
              },
              {
                sort: false,
                width: 50,
              },
            ),
          ]
        : []),
      Column.other(
        'readBy',
        'Citit de',
        (code, row) => {
          return renderUsers(row.readBy);
        },
        {
          sort: false,
          width: 250,
        },
      ),
      Column.bool('isDeleted', t('entity._.deleted._'), {
        width: 100,
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity._.deleted.true'),
          t('entity._.deleted.false'),
          t('entity._.deleted.false'),
        ],
      }),
    ];
    return columnsArray;
  }, [renderUsers, content, t]);

  return (
    <>
      <Table
        scroll={{ x: 500 }}
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
      />
    </>
  );
};

export default MessagesList;
