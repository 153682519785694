import React, { useCallback, useRef, useState } from 'react';

import InputDebounce from '../../../components/InputDebounce';
import { useClickOutside } from '../../../hooks/useClickOutside';

const InputHelper = ({
  initialValue,
  onChange,
  onDebounceChange,
  getReference,
  onSelect,
  onSearch,
  fieldName = 'code',
  clearAfterSelect = false,
  debouceDelay = 500,
  minSearchValue = 3,
  ...rest
}) => {
  const [value, setValue] = useState(initialValue);
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const selectRef = useRef(null);

  const clearValue = () => {
    setValue('');
  };

  const handleKeyDown = useCallback(
    (event) => {
      if (!suggestions || suggestions.length === 0) return;
      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault();
          setSelectedIndex(
            selectedIndex === suggestions.length - 1 ? 0 : selectedIndex + 1,
          );
          break;
        case 'ArrowUp':
          event.preventDefault();
          setSelectedIndex(
            selectedIndex === 0 ? suggestions.length - 1 : selectedIndex - 1,
          );
          break;
        case 'Enter':
          setSuggestions([]);

          if (clearAfterSelect) {
            clearValue();
          } else {
            setValue(suggestions[selectedIndex][fieldName]);
          }

          if (getReference && typeof getReference === 'function') {
            setLoading(true);
            getReference(suggestions[selectedIndex].referenceId)
              .then((res) => {
                if (res && onSelect && typeof onSelect === 'function') {
                  onSelect(res);
                }
              })
              .catch()
              .finally(() => setLoading(false));
          }
          break;
        default:
      }
    },
    [
      clearAfterSelect,
      fieldName,
      getReference,
      onSelect,
      setSelectedIndex,
      selectedIndex,
      setSuggestions,
      suggestions,
    ],
  );

  const closePopup = () => {
    setPopup(false);
  };

  const outside = useClickOutside(closePopup, popup);

  const handleChange = (e) => {
    if (typeof onChange !== 'undefined' && typeof onChange === 'function') {
      onChange(e);
    }
    setValue(e.target.value);
  };

  const handleChoose = useCallback(
    (suggestion) => {
      setSuggestions([]);
      if (clearAfterSelect) {
        clearValue();
      } else {
        setValue(suggestion[fieldName]);
      }

      if (getReference && typeof getReference === 'function') {
        setLoading(true);
        getReference(suggestion.referenceId)
          .then((res) => {
            if (res && onSelect && typeof onSelect === 'function') {
              onSelect(res);
            }
          })
          .catch()
          .finally(() => setLoading(false));
      } else if (suggestion && onSelect && typeof onSelect === 'function') {
        onSelect(suggestion);
      }
    },
    [onSelect, getReference, fieldName, clearAfterSelect],
  );

  const search = useCallback(() => {
    if (
      value &&
      !loading &&
      value.length >= minSearchValue &&
      onSearch &&
      typeof onSearch === 'function'
    ) {
      setLoading(true);
      onSearch(value)
        .then((res) => {
          setSuggestions(res || []);
          if (res) {
            setPopup(true);
          }
        })
        .catch(() => {})
        .finally(() => setLoading(false));
    }
  }, [value, onSearch, loading, minSearchValue]);

  const handleDebounceChange = () => {
    search();
    if (onDebounceChange && typeof onDebounceChange === 'function') {
      onDebounceChange(value);
    }
  };

  return (
    // eslint-disable-next-line
    <div className="input__helper" onKeyDown={handleKeyDown}>
      <InputDebounce
        initialValue={value}
        delay={debouceDelay}
        onChange={handleChange}
        onDebounceChange={handleDebounceChange}
        autoComplete="off"
        loading={loading}
        {...rest}
      />
      {
        // eslint-disable-next-line
        <div
          ref={(selectRef, outside)}
          className={`input__helper-suggestions ${
            value?.length >= minSearchValue && suggestions.length > 0 && popup
              ? 'active'
              : ''
          }`}
        >
          {suggestions.map((suggestion, index) => (
            // eslint-disable-next-line
            <div
              style={{
                backgroundColor: index === selectedIndex ? 'lightgray' : '',
              }}
              className="input__helper-suggestion"
              key={suggestion?.id}
              onClick={() => handleChoose(suggestion)}
            >
              {suggestion?.[fieldName]}
            </div>
          ))}
        </div>
      }
    </div>
  );
};

export default InputHelper;
