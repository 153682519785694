import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Table, notification, Tooltip, Button } from 'antd';
import {
  FileTextOutlined,
  FormatPainterOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';

import useDatasource from '../../../hooks/useDatasource';

import { findAll, changeStatus } from '../../../services/admin/declaration';

import Column from '../../../helpers/Columns';

import EditItemIcon from '../../../components/buttons/EditItemIcon';
import AddEntityButton from '../../../components/buttons/AddEntityButton';
import { useTableScroll } from '../../../hooks/useTableScroll';

import { generatePdfInNewTab } from '../../../services/transactions/transactions';
import AppLoader from '../../../components/auth/AppLoader';

const directions = {
  content: [{ id: 'IN' }, { id: 'OUT' }, { id: 'ALL' }],
};

const transportTypes = {
  content: [
    { id: 'AIR' },
    { id: 'ROAD' },
    { id: 'MARINE' },
    { id: 'RAILWAY' },
    { id: 'ANOTHER' },
  ],
};
const DeclarationsList = () => {
  const { t } = useTranslation();
  const [load, setLoad] = useState(false);

  const { loading, pagination, content, handleChange, reload } =
    useDatasource(findAll);
  const history = useHistory();

  const handlePdf = useCallback(
    (id) => {
      setLoad(true);
      generatePdfInNewTab(`/declaration-pdf/${id}`, {})
        .then(() => {
          notification.success({
            message: t('messages.report.generated.success'),
            duration: 3,
          });
        })
        .catch((err) => {
          if (err)
            notification.error({
              message: 'A apărut o eroare la generarea declarației',
            });
        })
        .finally(() => {
          setLoad(false);
          reload();
        });
    },
    [reload, t],
  );

  const draft = useCallback(
    (id) => {
      changeStatus(id, 'DRAFT')
        .then(() => {
          notification.success({
            message: 'Statul a fost schimbat',
            duration: 3,
          });
        })
        .catch((err) => {
          if (err)
            notification.error({
              message: 'A apărut o eroare la schimbarea statutului',
            });
        })
        .finally(() => {
          reload();
        });
    },
    [reload],
  );

  const handleEmptyPdf = useCallback(() => {
    generatePdfInNewTab(`/declaration-pdf`, {})
      .then(() => {
        notification.success({
          message: t('messages.report.generated.success'),
          duration: 3,
        });
      })
      .catch((err) => {
        if (err)
          notification.error({
            message: 'A apărut o eroare la generarea declarației',
          });
      })
      .finally(() => {
        reload();
      });
  }, [reload, t]);

  useTableScroll();
  const columns = useMemo(
    () => [
      Column.other(
        'status',
        'Statut',
        (code, row) => {
          const declarationStatusName = () => {
            switch (row.status) {
              case 'APPROVED':
                return 'Aprobat';
              case 'REJECTED':
                return 'Respins';
              default:
                return 'Draft';
            }
          };
          const declarationStatus = () => {
            return (
              <div className="declaration__status">
                <span
                  className={`declaration__status-indicator ${row.status.toLowerCase()}`}
                />
                <div>{declarationStatusName()}</div>
              </div>
            );
          };
          return <>{declarationStatus()}</>;
        },
        {
          sort: true,
          width: 110,
          align: 'center',
        },
      ),
      Column.date('statusDate', t('entity.admin.declaration.statusDate'), {
        width: 95,
        sort: false,
        format: 'DD.MM.YY HH:mm:ss',
      }),
      Column.text(
        'declarationNumber',
        t('entity.admin.declaration.declarationNumber', {
          width: 75,
          filter: true,
        }),
      ),
      Column.text('fullname', t('entity.admin.declaration.fullname'), {
        width: 250,
        filter: true,
      }),
      Column.text('idnp', t('entity.admin.declaration.idnp'), {
        width: 250,
        filter: true,
      }),
      Column.dictionary(
        'direction',
        t('entity.admin.declaration.direction._'),
        directions,
        {
          dictLabel: (key) => t(`entity.admin.declaration.direction.${key.id}`),
          width: 100,
          filter: true,
        },
      ),
      Column.dictionary(
        'transportType',
        t('entity.admin.declaration.transportType._'),
        transportTypes,
        {
          dictLabel: (key) =>
            t(`entity.admin.declaration.transportType.${key.id}`),
          width: 100,
          filter: true,
        },
      ),
      Column.actions(t('table.actions'), (record) => (
        <span style={{ textAlign: 'right' }}>
          <Tooltip title="Generare declarație PDF">
            <Button
              shape="circle"
              type="link"
              icon={<FileTextOutlined />}
              onClick={() => handlePdf(record.id)}
            />
          </Tooltip>
          {record.status === 'DRAFT' ? (
            <EditItemIcon path={`/declaration/${record.id}`} />
          ) : (
            <Tooltip title="Vizualizare">
              <Button
                shape="circle"
                type="link"
                icon={<EyeOutlined />}
                onClick={() => history.push(`/declaration/${record.id}`)}
              />
            </Tooltip>
          )}
          {record.status !== 'DRAFT' && (
            <Tooltip title="Draft">
              <Button
                shape="circle"
                type="link"
                icon={<FormatPainterOutlined />}
                onClick={() => draft(record.id)}
              />
            </Tooltip>
          )}
        </span>
      )),
    ],
    [t, handlePdf, draft, history],
  );

  return (
    <>
      <div className={`loaderOverlay ${load ? 'active' : ''}`}>
        <AppLoader />
      </div>
      <PageHeader
        title={t('entity.admin.declaration._plural')}
        extra={[
          <Button
            key="raport"
            type="primary"
            icon={<FileTextOutlined />}
            onClick={() => handleEmptyPdf()}
          >
            Formular necompletat PDF
          </Button>,
          <AddEntityButton
            key="new"
            entity={t('entity.admin.declaration._singular')}
            path="/declaration/new"
          />,
        ]}
      />
      <Table
        columns={columns}
        rowKey="id"
        scroll={{ x: 1000 }}
        loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
      />
    </>
  );
};

export default DeclarationsList;
