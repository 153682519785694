import React, { useCallback, useEffect, useMemo } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import { useTranslation } from 'react-i18next';
import { Checkbox, Col, Form, Input, notification, Row, Space } from 'antd';
import dayjs from 'dayjs';
import SaveButton from '../../../buttons/SaveButton';
import CancelButton from '../../../buttons/BackButton';
import useFormErrors from '../../../../hooks/useFormErrors';
import GenericRiskEditForm from '../GenericRiskEditForm';
import RequiredLabel from '../../../../services/utils/RequiredLabel';
import PERSON_TYPES from '../../../../enums/PersonTypes';
import { maxLengthValidator } from '../../../../services/utils/validator/Validator';
import { validateLegalPersonRisk } from './LegalPersonsRiskValidation';
import { validateGenericRisk } from '../GenericRiskValidation';

const LegalPersonsRisksEditForm = ({
  risk,
  copy,
  onSubmit,
  onCancel,
  errors,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isRiskDeleted = useMemo(() => risk?.deleted, [risk?.deleted]);

  useEffect(() => {
    if (!risk?.id && copy?.id) {
      form.setFieldsValue({
        ...copy,
        posts: copy.posts?.map((el) => el.postCode),
        persons: copy.persons?.map((el) => el.smsPersonId),
        rezident: copy?.rezident === PERSON_TYPES.REZIDENT,
        validFrom: copy?.validFrom ? dayjs(copy.validFrom) : undefined,
        validTo: copy?.validTo ? dayjs(copy.validTo) : undefined,
        createdAt: copy?.createdAt ? dayjs(copy.createdAt) : undefined,
        updatedAt: copy?.updatedAt ? dayjs(copy.updatedAt) : undefined,
      });
    }
  }, [form, risk?.id, copy]);

  useFormErrors(form, errors);

  const handleFinish = useCallback(
    (values) => {
      if (!validateLegalPersonRisk(form)) {
        return;
      }
      if (!validateGenericRisk(form)) {
        return;
      }

      if (values.isSms && !values.smsText) {
        notification.error({ message: 'Adăugați textul mesajului SMS' });
        return;
      }
      const persons = values.persons?.map((id) => ({ smsPersonId: id }));
      const posts = values.posts?.map((code) => ({ postCode: code }));
      onSubmit({
        ...values,
        persons,
        posts,
        rezident: values.rezident
          ? PERSON_TYPES.REZIDENT
          : PERSON_TYPES.NEREZIDENT,
      });
    },
    [onSubmit, form],
  );

  return (
    <>
      <PageHeader title={t('entity.lucrative.legal-persons._singular')} />
      <Form
        form={form}
        onFinish={handleFinish}
        layout="vertical"
        initialValues={{
          ...risk,
          posts: risk.posts?.map((el) => el.postCode),
          persons: risk.persons?.map((el) => el.smsPersonId),
          rezident: risk?.rezident === PERSON_TYPES.REZIDENT,
          validFrom: risk?.validFrom ? dayjs(risk.validFrom) : undefined,
          validTo: risk?.validTo ? dayjs(risk.validTo) : undefined,
          createdAt: risk?.createdAt ? dayjs(risk.createdAt) : undefined,
          updatedAt: risk?.updatedAt ? dayjs(risk.updatedAt) : undefined,
        }}
      >
        <Row gutter={15}>
          <Col span={12}>
            <Form.Item
              name="idno"
              labelCol={{ span: 24 }}
              label={
                <RequiredLabel
                  title={t('entity.lucrative.legal-persons.idno')}
                />
              }
            >
              <Input
                disabled={risk.deleted}
                onChange={(e) => {
                  form.setFieldsValue({
                    idno: maxLengthValidator(e.target.value, 13),
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="name"
              labelCol={{ span: 24 }}
              label={
                <RequiredLabel
                  title={t('entity.lucrative.legal-persons.name')}
                />
              }
            >
              <Input
                disabled={risk.deleted}
                onChange={(e) => {
                  form.setFieldsValue({
                    name: maxLengthValidator(e.target.value, 150),
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="rezident" valuePropName="checked">
          <Checkbox defaultChecked="false" disabled={risk.deleted}>
            {t('entity.lucrative.legal-persons.rezident')}
          </Checkbox>
        </Form.Item>

        <GenericRiskEditForm
          existent={risk}
          disabled={risk?.deleted}
          form={form}
        />

        <Form.Item>
          <Space>
            <CancelButton onCancel={onCancel} />
            <SaveButton disabled={isRiskDeleted} />
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default LegalPersonsRisksEditForm;
