const MessageTypes = {
  TEXT: 'TEXT',
  IMAGE: 'IMAGE',
  ATTACHMENT: 'ATTACHMENT',
  REPLY: 'REPLY',
  LINK: 'LINK',

  // used only for function transfer
  DELETE: 'DELETE',
  DOWNLOAD: 'DOWNLOAD',
  FORWARD: 'FORWARD',
  EDIT: 'EDIT',
};

export default MessageTypes;
