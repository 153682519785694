import React, { useCallback, useMemo, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Table,
  Tooltip,
  notification,
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import {
  MinusCircleFilled,
  PlusCircleFilled,
  PlusOutlined,
} from '@ant-design/icons';
import useDatasource from '../../hooks/useDatasource';
import Column from '../../helpers/Columns';
import { useTableScroll } from '../../hooks/useTableScroll';
import {
  editBalance,
  create,
  findAll,
} from '../../services/admin/client-balance';

const BalanceInformationList = () => {
  useTableScroll();

  const [form] = Form.useForm();

  const { loading, pagination, content, handleChange, reload } =
    useDatasource(findAll);
  const [selectedBalanceId, setSelectedBalanceId] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [modalUserVisible, setModalUserVisible] = useState(false);
  const [suma, setSuma] = useState('');

  const handleAddPaymentModal = (id) => {
    setSelectedBalanceId(id);
    setSelectedAction('reincarcare');
    setSuma('');
  };

  const handleSubtractPaymentModal = (id) => {
    setSelectedBalanceId(id);
    setSelectedAction('extragere');
    setSuma('');
  };

  const handleCancel = () => {
    setSelectedBalanceId(null);
    setSelectedAction(null);
    setSuma('');
  };
  const handleEditPayment = (func, id) => {
    editBalance(func, id, suma)
      .then(() => {
        notification.success({ message: 'Reîncărcare cu succes' });
      })
      .catch(() => {
        notification.error({ message: 'Eroare la reîncărcare' });
      })
      .finally(() => {
        setSelectedBalanceId(null);
        reload();
      });
  };
  // const handleExtractPayment = (id) => {
  //   editBalance('SUBTRACT', id, suma)
  //     .then(() => {
  //       notification.success({ message: 'Extragere  cu succes' });
  //     })
  //     .catch(() => notification.error({ message: 'Eroare la extragere' }))
  //     .finally(() => {
  //       reload();
  //     });
  // };

  const handleModalUser = () => {
    setModalUserVisible(true);
  };

  const handleModalUserCancel = () => {
    setModalUserVisible(false);
  };

  const handleSave = useCallback(() => {
    const formData = form.getFieldsValue();
    create(formData)
      .then(() => {
        notification.success({ message: 'Salvare cu succes' });
        form.resetFields();
        handleModalUserCancel();
      })
      .catch(() => {
        notification.error({
          message: 'Eroare la salvare. IDNO-ul specificat este deja utilizat. ',
        });
      })
      .finally(() => {
        reload();
        setModalUserVisible(false);
      });
  }, [form, reload]);

  const columns = useMemo(
    () => [
      Column.text('idno', 'IDNO', {
        width: 50,
        filter: true,
      }),
      Column.text('nume', 'Nume', {
        width: 50,
        filter: true,
      }),
      Column.text('suma', 'Suma', {
        width: 50,
        filter: false,
        sort: false,
      }),
      Column.text('dt', 'DT', {
        width: 50,
        filter: false,
        sort: false,
      }),
      Column.text('sold', 'Sold', {
        width: 50,
        filter: false,
        sort: false,
      }),
      Column.actions('Acțiune', (record) => (
        <span style={{ textAlign: 'center' }}>
          <Tooltip title="Reîncărcare">
            <Button
              icon={<PlusCircleFilled style={{ fontSize: '18px' }} />}
              onClick={() => handleAddPaymentModal(record?.id)}
              type="link"
            />
          </Tooltip>
          <Tooltip title="Extragere">
            <Button
              icon={<MinusCircleFilled style={{ fontSize: '18px' }} />}
              onClick={() => handleSubtractPaymentModal(record?.id)}
              type="link"
            />
          </Tooltip>
        </span>
      )),
    ],
    [],
  );

  return (
    <>
      <PageHeader title="Solduri Persoane Juridice" />
      <Button
        onClick={handleModalUser}
        type="primary"
        style={{ marginBottom: 10, marginLeft: 'auto', display: 'block' }}
        icon={<PlusOutlined />}
        disabled={false}
      >
        Adaugă Persoana juridică
      </Button>
      <Modal
        title="Persoana Juridica"
        visible={modalUserVisible}
        onCancel={handleModalUserCancel}
        centered
        footer={[
          <Button key="save" type="primary" onClick={handleSave}>
            Salvare date
          </Button>,
          <Button key="back" onClick={handleModalUserCancel}>
            Înapoi
          </Button>,
        ]}
      >
        <Form form={form}>
          <Col span={24}>
            <Form.Item
              label="IDNO"
              name="idno"
              rules={[
                {
                  required: true,
                  message: 'Câmpul necesită completare.',
                },
              ]}
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 16 }}
            >
              <Input maxLength={100} name="idno" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Nume"
              name="nume"
              rules={[
                {
                  required: true,
                  message: 'Câmpul necesită completare.',
                },
              ]}
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 16 }}
            >
              <Input maxLength={500} name="nume" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Suma"
              name="suma"
              rules={[
                {
                  required: true,
                  message: 'Câmpul necesită completare.',
                },
              ]}
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 16 }}
            >
              <Input type="number" maxLength={500} name="suma" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="DT"
              name="dt"
              rules={[
                {
                  required: true,
                  message: 'Câmpul necesită completare.',
                },
              ]}
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 16 }}
            >
              <Input type="number" maxLength={500} name="dt" />
            </Form.Item>
          </Col>
        </Form>
      </Modal>
      <Table
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
      />
      <Modal
        title={
          selectedAction === 'reincarcare'
            ? 'Reincarcarea Contului'
            : 'Extragerea din Cont'
        }
        visible={selectedBalanceId}
        onCancel={handleCancel}
        centered
        footer={[
          <Button
            key={selectedAction === 'reincarcare' ? 'reincarcare' : 'extragere'}
            type="primary"
            onClick={() => {
              if (selectedAction === 'reincarcare') {
                handleEditPayment('ADD', selectedBalanceId);
              } else {
                handleEditPayment('SUBSTRACT', selectedBalanceId);
              }
            }}
          >
            {selectedAction === 'reincarcare' ? 'Reîncarcă' : 'Extragere'}
          </Button>,
        ]}
      >
        <Input
          type="number"
          placeholder="Introduceți suma"
          value={suma}
          onChange={(e) => {
            const inputVal = e.target.value;
            if (/^\d*$/gm.test(inputVal)) {
              setSuma(inputVal);
            }
          }}
        />
      </Modal>
    </>
  );
};

export default BalanceInformationList;
