import React, { useCallback, useContext, useMemo, useState } from 'react';
import {
  notification,
  Table,
  Form,
  Modal,
  Col,
  Input,
  Row,
  DatePicker,
  Button,
  Menu,
  Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { subDays } from 'date-fns';
import {
  DownloadOutlined,
  ReloadOutlined,
  SearchOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import useDatasource from '../../hooks/useDatasource';
import {
  changeStatus,
  checkPaymentStatus,
  checkConfirmStatus,
  exportFile,
  findAll,
  generatePayment,
  markAsPaid,
  remove,
  exportReportFile,
} from '../../services/payments/payment-notice';
import Column from '../../helpers/Columns';
import { useTableScroll } from '../../hooks/useTableScroll';
import AuthContext from '../auth';
import { getActionColumn } from './GenericPaymentNoticeColumns';
import useDictionaries from '../../hooks/useDictionaries';
import { findAll as findAllCustomsPosts } from '../../services/admin/users/customs-posts';
import { findAll as findAllCustomsOffices } from '../../services/admin/users/customs-office';
import AppLoader from '../auth/AppLoader';
import { findAllPayments } from '../../services/taxonomies/customs-payments';
import CustomsOfficesSelect from '../CustomsOfficesSelect';
import CustomsPostSelect from '../CustomsPostSelect';

const { RangePicker } = DatePicker;

const dictionaries = {
  posts: findAllCustomsPosts,
  offices: findAllCustomsOffices,
  customsPayments: findAllPayments,
};

const PAYMENT_STE = {
  TO_PAY: 'TO_PAY',
  PAID: 'PAID',
  CONFIRMED: 'CONFIRMED',
  CANCELED: 'CANCELED',
};

const PaymentNoticeList = () => {
  const [{ posts }] = useDictionaries(dictionaries);
  const [{ customsPayments = [] }] = useDictionaries(dictionaries);

  useTableScroll();
  const { user } = useContext(AuthContext);
  const { permissions } = user || { permissions: [] };
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [rangeForm] = Form.useForm();
  const [currentId, setCurrentId] = useState();
  const [officeId, setOfficeId] = useState(null);
  const [showCSVModal, setShowCSVModal] = useState(false);
  const [showXLSXModal, setShowXLSXModal] = useState(false);
  const [showXLSXReportModal, setShowXLSXReportModal] = useState(false);
  const [showAdvancedSearchModal, setShowAdvancedSearchModal] = useState(false);

  const [loadingLoader, setLoadingLoader] = useState(false);

  const handler = useCallback(
    (...params) => {
      const selectedDate = rangeForm.getFieldValue('timelinePeriod');
      const selectedOptions = rangeForm.getFieldValue('selectedOptions');
      const hz = params[0];
      hz.criterias = {
        ...hz.criterias,
      };

      if (selectedDate) {
        hz.criterias.startDate = selectedDate[0].startOf('day').toISOString();
        hz.criterias.endDate = selectedDate[1].startOf('day').toISOString();
      }
      if (selectedOptions) {
        hz.criterias.selectedOptions = selectedOptions.join(',');
      }
      return findAll(hz);
    },
    [rangeForm],
  );

  const {
    loading,
    pagination,
    content = [],
    handleChange,
    reload,
  } = useDatasource(handler);

  const draft = useCallback(
    (id) => {
      changeStatus(id, 'DRAFT')
        .then(() => {
          notification.success({
            message: 'Statutul a fost modificat cu succes',
            duration: 3,
          });
        })
        .catch((err) => {
          if (err)
            notification.error({
              message: 'A apărut o eroare',
            });
        })
        .finally(() => {
          reload();
        });
    },
    [reload],
  );

  const handleGeneratePayment = useCallback(
    (id) => {
      generatePayment(id)
        .then(() => {
          notification.success({
            message: 'Chitanța a fost generată cu succes.',
          });
        })
        .catch(() =>
          notification.error({ message: 'Erroare la generarea chitanței.' }),
        )
        .finally(() => reload());
    },
    [reload],
  );

  const handleReloadPaymentStatus = useCallback(
    (id) => {
      checkPaymentStatus(id)
        .then((res) => {
          if (res.paymentStatus === PAYMENT_STE.PAID) {
            notification.success({
              message: 'Avizul a fost achitat.',
            });
          }
          if (res.paymentStatus === PAYMENT_STE.TO_PAY) {
            notification.info({
              message: 'Avizul este in aștepatrea plații.',
            });
          }
          if (res.paymentStatus === PAYMENT_STE.CANCELED) {
            notification.warning({
              message: 'Bonul a fost anulată.',
            });
          }
        })
        .catch(() =>
          notification.error({ message: 'Erroare la verificarea statutului.' }),
        )
        .finally(() => reload());
    },
    [reload],
  );

  const handleReloadConfirmStatus = useCallback(
    (id) => {
      checkConfirmStatus(id)
        .then((res) => {
          if (res.paymentStatus === PAYMENT_STE.CONFIRMED) {
            notification.success({
              message: 'Avizul este confirmat.',
            });
          }
          if (res.paymentStatus === PAYMENT_STE.PAID) {
            notification.info({
              message: 'Avizul nu este încă confirmat.',
            });
          }
        })
        .catch(() =>
          notification.error({ message: 'Erroare la verificarea statutului.' }),
        )
        .finally(() => reload());
    },
    [reload],
  );

  const handleMarkAsPaid = useCallback(() => {
    markAsPaid(currentId, form.getFieldsValue())
      .then(() => {
        setCurrentId(null);
        form.resetFields();
        reload();
      })
      .catch(() =>
        notification.error({ message: 'Erroare la marcarea statutului.' }),
      );
  }, [reload, setCurrentId, form, currentId]);

  const chooseMarkAsPaid = useCallback(
    (id) => {
      setCurrentId(id);
    },
    [setCurrentId],
  );

  const csvModalHandleOk = useCallback(() => {
    const selectedDate = rangeForm.getFieldValue('timelinePeriod');
    if (selectedDate?.length) {
      exportFile(
        selectedDate[0].startOf('day'),
        selectedDate[1].startOf('day'),
        'csv',
      )
        .then(() => {
          setLoadingLoader(false);
        })
        .catch((error) => {
          console.error('Error exporting CSV:', error);
          notification.error({ message: 'Eroare la exportul CSV.' });
        })
        .finally(() => {
          setShowCSVModal(false);
        });
    } else {
      setShowCSVModal(false);
    }
  }, [rangeForm, setLoadingLoader, setShowCSVModal]);

  const xlsxModalHandleOk = useCallback(() => {
    const selectedDate = rangeForm.getFieldValue('timelinePeriod');
    if (selectedDate?.length) {
      exportFile(
        selectedDate[0].startOf('day'),
        selectedDate[1].startOf('day'),
        'xlsx',
      )
        .then(() => {
          setLoadingLoader(false);
        })
        .catch((error) => {
          console.error('Error exporting XLSX:', error);
          notification.error({ message: 'Eroare la exportul XLSX.' });
        })
        .finally(() => {
          setShowXLSXModal(false);
        });
    } else {
      setShowXLSXModal(false);
    }
  }, [rangeForm, setLoadingLoader, setShowXLSXModal]);

  const xlsxReportModalHandleOk = useCallback(() => {
    const selectedDate = rangeForm.getFieldValue('timelinePeriod');
    if (selectedDate?.length) {
      exportReportFile(
        selectedDate[0].startOf('day'),
        selectedDate[1].startOf('day'),
        rangeForm.getFieldValue('post'),
        rangeForm.getFieldValue('office'),
      )
        .then(() => {
          setLoadingLoader(false);
        })
        .catch((error) => {
          console.error('Error exporting XLSX:', error);
          notification.error({ message: 'Eroare la exportul XLSX.' });
        })
        .finally(() => {
          setShowXLSXReportModal(false);
        });
    } else {
      setShowXLSXReportModal(false);
    }
  }, [rangeForm]);

  const getDateMinusDays = (daysOffset) => {
    return subDays(new Date(), daysOffset);
  };

  const defaultTimelineDates = useMemo(
    () => ({
      fromDate: getDateMinusDays(30),
      toDate: new Date(),
    }),
    [],
  );

  const tableHeaderSettings = useCallback(() => {
    const MODAL_BUTTON = 'csvGenerate';
    const MODAL_BUTTON_XLSX = 'modalButtonXlsx';
    const MODAL_BUTTON_XLSX_REPORT = 'modalButtonXlsxReport';
    const advancedSearchButton = 'advancedSearch';
    const refreshDataButton = 'refreshData';
    const downloadCsvObj = {
      key: MODAL_BUTTON,
      label: 'Exportă listă avize (CSV)',
      icon: <DownloadOutlined />,
    };
    const advancedSearchObj = {
      key: advancedSearchButton,
      label: 'Căutare avansată',
      icon: <SearchOutlined />,
    };
    const refreshDataObj = {
      key: refreshDataButton,
      label: 'Reîncarcă datele',
      icon: <ReloadOutlined />,
    };
    const downloadXlsxObj = {
      key: MODAL_BUTTON_XLSX,
      label: 'Exportă listă avize (XLSX)',
      icon: <DownloadOutlined />,
    };
    const downloadXlsxReportObj = {
      key: MODAL_BUTTON_XLSX_REPORT,
      label: 'Exportă raport (XLSX)',
      icon: <DownloadOutlined />,
    };

    const items = [
      {
        key: 'settings',
        icon: <SettingOutlined />,
        children: [
          downloadCsvObj,
          downloadXlsxObj,
          downloadXlsxReportObj,
          advancedSearchObj,
          refreshDataObj,
        ],
      },
    ];
    const onClick = (e) => {
      switch (e.key) {
        case MODAL_BUTTON:
          setShowCSVModal(true);
          setShowAdvancedSearchModal(false); // Only show CSV Modal
          break;
        case MODAL_BUTTON_XLSX:
          setShowXLSXModal(true);
          setShowAdvancedSearchModal(false); // Only show XLSX Modal
          break;
        case MODAL_BUTTON_XLSX_REPORT:
          setShowXLSXReportModal(true);
          setShowAdvancedSearchModal(false); // Only show XLSX Modal
          break;
        case advancedSearchButton:
          setShowCSVModal(false);
          setShowAdvancedSearchModal(true); // Only show Advanced Search Modal
          break;
        case refreshDataButton:
          rangeForm.resetFields();
          reload();
          break;
        default:
          break;
      }
    };

    return (
      <>
        <Menu
          className="table__menu-settings"
          onClick={onClick}
          mode="vertical"
          items={items}
        />
        <div>{t('table.actions')}</div>
      </>
    );
  }, [rangeForm, reload, t]);

  const columns = useMemo(() => {
    const postsDictionary = { content: [] };
    posts?.content?.forEach((el) => {
      postsDictionary.content.push({
        id: el.id,
        name: `(${el.newCode}) ${el.name}`,
      });
    });
    content.filter((record) => record.customsPostId !== null);
    return [
      Column.text('id', t('entity.payments.id'), {
        width: 50,
        filter: true,
      }),
      Column.other(
        'status',
        'Statut',
        (code, row) => {
          const declarationStatusName = () => {
            if (row?.status === 'APPROVED' && row?.paymentStatus === null) {
              return 'Aprobat';
            }
            if (
              row?.status === 'APPROVED' &&
              row?.paymentStatus === PAYMENT_STE.TO_PAY
            ) {
              return 'Aprobat (asteptare plată)';
            }
            if (
              row?.status === 'APPROVED' &&
              row?.paymentStatus === PAYMENT_STE.PAID &&
              row?.bankId !== null
            ) {
              return 'Plătit MPAY';
            }
            if (
              row?.status === 'APPROVED' &&
              row?.paymentStatus === PAYMENT_STE.PAID &&
              row?.receiptNumber !== null
            ) {
              return 'Plătit (confirmat manual)';
            }
            if (
              row?.status === 'APPROVED' &&
              row?.paymentStatus === PAYMENT_STE.CONFIRMED &&
              (row?.receiptNumber !== null || row?.bankId !== null)
            ) {
              return 'Confirmat (Trezorerie)';
            }
            if (
              row?.status === 'APPROVED' &&
              row?.paymentStatus === PAYMENT_STE.CONFIRMED &&
              row?.receiptNumber == null &&
              row?.bankId == null
            ) {
              return 'Plătit (extras din sold - Trezorerie)';
            }
            if (
              row?.status === 'APPROVED' &&
              row?.paymentStatus === PAYMENT_STE.PAID &&
              row?.receiptNumber == null &&
              row?.bankId == null
            ) {
              return 'Plătit (extras din sold)';
            }
            if (row?.status === 'REJECTED') {
              return 'Respins';
            }

            return 'Draft';
          };

          const declarationStatus = () => {
            return (
              <div className="declaration__status">
                <span
                  className={`aviz__status-indicator ${
                    row?.paymentStatus === PAYMENT_STE.PAID ||
                    row?.paymentStatus === PAYMENT_STE.CONFIRMED
                      ? 'paid'
                      : row?.status?.toLowerCase()
                  }`}
                />
                <div>{declarationStatusName()}</div>
              </div>
            );
          };
          return <>{declarationStatus()}</>;
        },
        {
          sort: true,
          width: 140,
          align: 'center',
        },
      ),
      Column.text('docNumber', t('entity.payments.docNumber'), {
        width: 100,
        filter: true,
      }),
      Column.date('docDate', t('entity.payments.docDate'), {
        width: 80,
        format: 'DD.MM.YY HH:mm:ss',
      }),
      Column.dictionary(
        'customsPostId',
        t('entity.payments.customsPost'),
        postsDictionary,
        {
          sort: false,
          dataIndex: 'customsPostId',
          width: 100,
          filter: true,
        },
      ),
      Column.text('deliveredServices', 'Autor', {
        width: 120,
        filter: false,
      }),
      Column.text('codPersonal2', 'IDNO/IDNP beneficiar', {
        width: 120,
        filter: true,
      }),
      Column.text('beneficiary', t('entity.payments.beneficiaryName'), {
        width: 120,
        filter: false,
      }),
      Column.text('payerIdentificationNumber', 'IDNO/IDNP plătitor', {
        width: 120,
        filter: true,
      }),
      Column.text('payerName', t('entity.payments.payerName'), {
        width: 100,
        filter: false,
      }),
      Column.text('totalAmount', t('entity.payments.totalAmount'), {
        width: 100,
        filter: false,
      }),
      Column.actions(tableHeaderSettings(), (record) =>
        getActionColumn({
          t,
          path: `/payments/payments-notice/${record.id}`,
          record,
          reload,
          remove,
          permissions,
          changeStatus,
          generatePayment: handleGeneratePayment,
          reloadPaymentStatus: handleReloadPaymentStatus,
          reloadConfirmStatus: handleReloadConfirmStatus,
          markAsPaid: chooseMarkAsPaid,
          draft,
        }),
      ),
    ];
  }, [
    content,
    t,
    tableHeaderSettings,
    posts?.content,
    reload,
    permissions,
    handleGeneratePayment,
    handleReloadPaymentStatus,
    handleReloadConfirmStatus,
    chooseMarkAsPaid,
    draft,
  ]);

  return (
    <>
      <div className={`loaderOverlay ${loadingLoader ? 'active' : ''}`}>
        <AppLoader />
      </div>
      <Table
        scroll={{ x: 500 }}
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
      />

      <Modal
        title="Selectați intervalul de date"
        visible={showCSVModal}
        onCancel={() => setShowCSVModal(false)}
        footer={[
          <Button key="cancel" onClick={() => setShowCSVModal(false)}>
            Anulează
          </Button>,
          <Button
            key="ok"
            type="primary"
            onClick={() => {
              setLoadingLoader(true);
              csvModalHandleOk();
            }}
            loading={loadingLoader}
          >
            OK
          </Button>,
        ]}
      >
        <Form form={rangeForm}>
          <Form.Item
            labelCol={{ span: 24 }}
            label="Perioada"
            name="timelinePeriod"
            style={{ marginLeft: '20px' }}
            initialValue={[
              dayjs(defaultTimelineDates.fromDate),
              dayjs(defaultTimelineDates.toDate),
            ]}
          >
            <RangePicker />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Selectați intervalul de date pentru XLSX"
        visible={showXLSXModal}
        onCancel={() => setShowXLSXModal(false)}
        footer={[
          <Button key="cancel" onClick={() => setShowXLSXModal(false)}>
            Anulează
          </Button>,
          <Button
            key="ok"
            type="primary"
            onClick={() => {
              setLoadingLoader(true);
              xlsxModalHandleOk();
            }}
            loading={loadingLoader}
          >
            OK
          </Button>,
        ]}
      >
        <Form form={rangeForm}>
          <Form.Item
            labelCol={{ span: 24 }}
            label="Perioada"
            name="timelinePeriod"
            style={{ marginLeft: '20px' }}
            initialValue={[
              dayjs(defaultTimelineDates.fromDate),
              dayjs(defaultTimelineDates.toDate),
            ]}
          >
            <RangePicker />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        maskClosable={false}
        width={600}
        centered
        title="Sunteți sigur că doriți să marcați ca PLĂTIT?"
        visible={currentId}
        onOk={form.submit}
        onCancel={() => {
          form.resetFields();
          setCurrentId(null);
        }}
        cancelText="Înapoi"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleMarkAsPaid}
          initialValues={{
            paymentDate: dayjs(new Date()),
          }}
        >
          <Row gutter={15}>
            <Col span={12}>
              <Form.Item
                label="Număr bon de plată"
                name="receiptNumber"
                rules={[
                  {
                    required: true,
                    message: 'Câmpul este obligatoriu',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Data achitare"
                name="paymentDate"
                rules={[
                  {
                    required: true,
                    message: 'Câmpul este obligatoriu',
                  },
                ]}
              >
                <DatePicker format="DD.MM.YYYY HH:mm" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        title="Căutare avansată"
        visible={showAdvancedSearchModal}
        onCancel={() => setShowAdvancedSearchModal(false)}
        footer={[
          <Button
            key="cancel"
            onClick={() => setShowAdvancedSearchModal(false)}
          >
            Anulează
          </Button>,
          <Button
            key="ok"
            type="primary"
            onClick={() => {
              setShowAdvancedSearchModal(false);
              reload();
              if (!rangeForm.getFieldValue('timelinePeriod')) {
                notification.error({ message: 'Selectați intervalul de date' });
              }
              // if (!rangeForm.getFieldValue('selectedOptions')) {
              //   notification.error({ message: 'Selectați opțiunile' });
              // }
            }}
          >
            OK
          </Button>,
        ]}
      >
        <Form form={rangeForm}>
          <Form.Item
            labelCol={{ span: 24 }}
            label="Perioada"
            name="timelinePeriod"
            style={{ marginLeft: '20px' }}
            initialValue={[dayjs().subtract(30, 'day'), dayjs()]}
            rules={[
              {
                required: true,
                message: 'Câmpul este obligatoriu',
              },
            ]}
          >
            <RangePicker />
          </Form.Item>
          <Form.Item
            labelCol={{ span: 24 }}
            label="Selectează opțiunile"
            name="selectedOptions"
            style={{ marginLeft: '20px' }}
            // rules={[
            //   {
            //     required: true,
            //     message: 'Câmpul este obligatoriu',
            //   },
            // ]}
          >
            <Select
              mode="multiple"
              placeholder="Denumirea serviciilor speciale"
              style={{ width: '100%' }}
            >
              {Array.isArray(customsPayments.content) &&
                customsPayments.content.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Selectați intervalul de date pentru XLSX"
        visible={showXLSXReportModal}
        onCancel={() => setShowXLSXReportModal(false)}
        footer={[
          <Button key="cancel" onClick={() => setShowXLSXReportModal(false)}>
            Anulează
          </Button>,
          <Button
            key="ok"
            type="primary"
            onClick={() => {
              setLoadingLoader(true);
              xlsxReportModalHandleOk();
            }}
            loading={loadingLoader}
          >
            OK
          </Button>,
        ]}
      >
        <Form form={rangeForm}>
          <Form.Item
            labelCol={{ span: 24 }}
            label="Perioada"
            name="timelinePeriod"
            style={{ marginLeft: '20px' }}
            initialValue={[
              dayjs(defaultTimelineDates.fromDate),
              dayjs(defaultTimelineDates.toDate),
            ]}
            rules={[
              {
                required: true,
                message: 'Câmpul este obligatoriu',
              },
            ]}
          >
            <RangePicker />
          </Form.Item>
          <Form.Item
            labelCol={{ span: 24 }}
            label="Birou vamal"
            name="office"
            style={{ marginLeft: '20px' }}
          >
            <CustomsOfficesSelect onChange={setOfficeId} />
          </Form.Item>
          <Form.Item
            labelCol={{ span: 24 }}
            label="Postul vamal"
            name="post"
            style={{ marginLeft: '20px' }}
          >
            <CustomsPostSelect
              mode="multiple"
              officeId={officeId}
              placeholder=""
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default PaymentNoticeList;
