import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { findAll } from '../services/admin/geo/countries';
import useDictionaries from '../hooks/useDictionaries';

const dictionaries = {
  countries: findAll,
};

const CountryDropDown = ({
  content,
  loading,
  value,
  onChange,
  loadContent = false,
  ...rest
}) => {
  const [{ countries }] = useDictionaries(dictionaries, {
    allowFetcher: loadContent,
  });

  const [data, setData] = useState([]);

  useEffect(() => {
    if (loadContent) {
      setData(countries?.content || []);
    } else if (!loadContent) {
      setData(content);
    }
    // eslint-disable-next-line
  }, [content, countries?.content]);

  return (
    <Select
      {...rest}
      style={{ width: '100%' }}
      loading={loading}
      value={value}
      onChange={onChange}
      autoComplete="none"
      allowClear
      showSearch
      filterOption={(input, option) => {
        return option.children.toLowerCase().startsWith(input.toLowerCase());
      }}
    >
      {data
        ?.sort((a, b) => a.alpha2.localeCompare(b.alpha2))
        ?.map(
          (row) =>
            !row?.deleted && (
              <Select.Option value={row.id} key={row.id}>
                {`${row.alpha2} - ${row.name}`}
              </Select.Option>
            ),
        )}
    </Select>
  );
};

export default CountryDropDown;
