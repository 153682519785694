import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import MappingSvmPostToSgPostsList from '../../../../components/admin/taxonomies/mappingSvmPostToSgPost/MappingSvmPostToSgPostsList';

const MappingSvmPostToSgPosts = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="mappingSvmPostToSgPost">
      <Tabs.TabPane
        tab={t('entity.admin.mappingSvmPostToSgPost._plural')}
        key="mappingSvmPostToSgPost"
      >
        <MappingSvmPostToSgPostsList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default MappingSvmPostToSgPosts;
