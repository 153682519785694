import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import FuelTypesList from '../../../../components/admin/taxonomies/fuelTypes/FuelTypesList';

const FuelTypes = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="fuelTypes">
      <Tabs.TabPane tab={t('entity.admin.fuelTypes._plural')} key="fuelTypes">
        <FuelTypesList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default FuelTypes;
