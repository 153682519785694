import React from 'react';

import SFSIresponsabiliList from './SFSIresponsabiliList';

const SFSIresponsabili = () => {
  return (
    <>
      <SFSIresponsabiliList />
    </>
  );
};

export default SFSIresponsabili;
