import { notification } from 'antd';

export const validateGenericRisk = (form) => {
  if (!form.getFieldValue('applicantId')) {
    notification.warning({
      message: 'Selectați  solicitant',
    });
    return false;
  }

  if (!form.getFieldValue('risk')) {
    notification.warning({
      message: 'Completați câmpul Risc',
    });
    return false;
  }
  if (!form.getFieldValue('direction')) {
    notification.warning({
      message: 'Completați câmpul Direcție',
    });
    return false;
  }
  if (!form.getFieldValue('riskLevelId')) {
    notification.warning({
      message: 'Completați câmpul Nivel de risc',
    });
    return false;
  }
  if (!form.getFieldValue('action')) {
    notification.warning({
      message: 'Completați câmpul Acțiune',
    });
    return false;
  }
  if (!form.getFieldValue('validFrom')) {
    notification.warning({
      message: 'Completați câmpul Valid din',
    });
    return false;
  }

  return true;
};
