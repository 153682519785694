import React, { useState, useEffect, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { notification, Button, Layout, Switch } from 'antd';
import { CameraOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import {
  getQrValue,
  saveTransactionFiles,
} from '../../services/admin/qr-files';
import logoImage from '../../assets/logo.png';
import { uploadFilesFromQR } from '../../services/admin/files';
import '../FileUploader.scss';
import AppLoader from '../auth/AppLoader';

const QRCodeServices = ({
  disabled = false,
  onUploadComplete = null,
  children,
  accept,
}) => {
  const limitFileSize = parseInt(window._env_.FILE_SIZE_LIMIT_BYTES, 10);
  const { t } = useTranslation();
  const location = useLocation();
  //  const history = useHistory();
  const [loadingLoader, setLoadingLoader] = useState(false);
  const [theme, setTheme] = useState(
    localStorage.getItem('them') ? localStorage.getItem('them') : true,
  );

  const [qrValue, setQrValue] = useState({});
  const body = document.querySelector('body');
  const localTheme = localStorage.getItem('them');
  const loadTheme = useMemo(() => {
    if (localTheme && localTheme === 'true') {
      body.classList.add('light');
      body.classList.remove('dark');
      setTheme(true);
    } else if (localTheme && localTheme === 'false') {
      body.classList.add('dark');
      body.classList.remove('light');
      setTheme(false);
    } else {
      localStorage.setItem('them', true);
      body.classList.add('light');
      body.classList.remove('dark');
      setTheme(true);
    }
    document.body.classList.add('notranslate');
  }, [body.classList, localTheme]);
  useEffect(() => {
    // eslint-disable-next-line
    loadTheme;

    // eslint-disable-next-line
  }, []);

  const handleChangeTheme = (check) => {
    if (check) {
      localStorage.setItem('them', true);
      body.classList.add('light');
      body.classList.remove('dark');
      setTheme(true);
    } else if (!check) {
      localStorage.setItem('them', false);
      body.classList.add('dark');
      body.classList.remove('light');
      setTheme(false);
    }
  };

  useEffect(() => {
    const uuid = location.pathname
      .split('/')
      .find((el) => el.includes('uuid='))
      ?.replace('uuid=', '');

    getQrValue(uuid).then((res) => setQrValue(res));
  }, [location.pathname]);

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    accept,
    onDrop: (val) => {
      setLoadingLoader(true);
      if (disabled) {
        return;
      }

      uploadFilesFromQR(qrValue?.uuid, val)
        .then((res) => {
          res.forEach((el) => {
            const data = {
              fileId: el.id,
              transactionId: qrValue?.transactionId,
              userId: qrValue?.userId,
              qrId: qrValue?.id,
            };
            saveTransactionFiles(data)
              .then(() => {
                notification.success({
                  message: 'Fișierele au fost adăugate cu succes',
                });
                setQrValue((old) => {
                  return { ...old, userId: null, transactionId: null };
                });
              })
              .catch(() =>
                notification.error({
                  message: 'Codul dumneavoastră nu este valid ',
                }),
              );
          });
          // history.push(`/physical-person-entry/${transactionId}#HAS_ADITIONAL`);
          if (typeof onUploadComplete === 'function') {
            onUploadComplete(res);
          }
        })
        .catch(() => {
          notification.error({
            message: 'A apărut o eroare la salvarea datelor',
          });
        })
        .finally(() => setLoadingLoader(false));
    },

    validator: (file) => {
      if (file.size > limitFileSize) {
        notification.error(
          t('file.size', {
            fileName: file.name,
            limitSize: Math.ceil(limitFileSize / 1024 ** 2),
          }),
        );
      }
    },

    maxSize: limitFileSize,
  });
  return (
    <div className="qrcode__page">
      <div className={`loaderOverlay ${loadingLoader ? 'active' : ''}`}>
        <AppLoader />
      </div>

      <div>
        <Layout.Header>
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignContent: 'space-between',
              justifyContent: 'space-between',
              position: 'relative',
            }}
          >
            <div
              className="header__leftside"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '40px',
              }}
            >
              <div className="header__logo">
                <div>
                  <img
                    src={logoImage}
                    alt="Frontiera"
                    width="48"
                    style={{
                      marginRight: '.8rem',
                      boxSizing: 'content-box',
                    }}
                  />
                  SIA Frontiera
                </div>
              </div>
            </div>
            <div className="header__rightside" style={{ display: 'flex' }}>
              <div className="theme__switcher">
                <Switch checked={theme} onChange={handleChangeTheme} />
              </div>
            </div>
          </div>
        </Layout.Header>
        <Layout
          style={{
            height: '100vh',
            minHeight: '400px',
            borderRight: 0,
            padding: '84px 20px 20px 20px',
          }}
        >
          <Layout.Content
            style={{ background: 'white', minHeight: '100%', padding: 24 }}
          >
            <div
              {...getRootProps({
                onClick: (event) => event.stopPropagation(),
              })}
              className={isDragActive ? 'drop-zone' : ''}
              style={{
                outline: 0,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div className="drop-zone__message">Drop you files here</div>
              <input {...getInputProps()} />

              <Button
                className="qrcode__btn"
                disabled={
                  disabled ||
                  (qrValue?.userId === null && qrValue?.transactionId === null)
                }
                key="fileButton"
                icon={<CameraOutlined />}
                type="primary"
                onClick={open}
                style={{
                  float: 'right',
                  marginLeft: 'auto',
                  width: '100%',
                }}
              >
                Adaugă fișierele
              </Button>
              {qrValue?.userId === null && qrValue?.transactionId === null && (
                <div
                  style={{
                    transform: 'translateY(5px)',
                    color: '#ff4d4f',
                    fontSize: '14px',
                  }}
                >
                  Codul dumneavoastră nu mai este valid
                </div>
              )}

              {children}
            </div>
          </Layout.Content>
        </Layout>
      </div>
    </div>
  );
};

export default QRCodeServices;
