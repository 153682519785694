import React, { useEffect, useMemo, useState, useCallback } from 'react';

import dayjs from 'dayjs';
import {
  Button,
  Col,
  DatePicker,
  Input,
  Modal,
  Row,
  Table,
  Tooltip,
} from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/lib/input/TextArea';
import { findAllByIds as findUsers } from '../../../services/admin/users/users';
import Column from '../../../helpers/Columns';
import { getOldTransactionCorrections } from '../../../services/admin/web-services/backend';

const directions = [
  { id: 'IN', name: 'INTRARE' },
  { id: 'OUT', name: 'IEȘIRE' },
];

const CorrectionTable = ({
  transactionId,
  vinCode,
  corrections,
  countries,
  ...rest
}) => {
  const { t } = useTranslation();
  const [correctionHistory, setCorrectionHistory] = useState([]);
  const [correctionReasonModal, setCorrectionReasonModal] = useState(false);
  const [correctionLetter, setCorrectionLetter] = useState({});
  const [users, setUsers] = useState([]);
  const [dataCorrections, setDataCorrections] = useState([]);

  useEffect(() => {
    if (corrections) {
      setDataCorrections(corrections);
    }
  }, [corrections]);

  useEffect(() => {
    if (corrections?.length === 0 && transactionId && vinCode) {
      getOldTransactionCorrections(transactionId, vinCode)
        .then((res) => {
          setDataCorrections(res);
        })
        .catch()
        .finally();
    }
  }, [corrections, transactionId, vinCode]);

  const showCorrectionReasonModal = useCallback((record) => {
    setCorrectionLetter({
      number: record.correctionLetterNumber,
      reason: record.correctionReason,
      date: record.correctionLetterDate
        ? dayjs(record.correctionLetterDate)
        : null,
    });
    setCorrectionReasonModal(true);
  }, []);

  useEffect(() => {
    if (dataCorrections?.length > 0) {
      const userIds = dataCorrections?.map((el) => el.userId);
      if (userIds.length > 0) {
        findUsers(userIds)
          .then((res) => setUsers(res))
          .catch();
      }
    }
  }, [dataCorrections]);

  useEffect(() => {
    const correctionTable = [];
    if (dataCorrections.length > 0 && users.length > 0) {
      dataCorrections?.forEach((el) => {
        if (el.directionFrom || el.directionTo) {
          correctionTable.push({
            ...el,
            id: `0${el.directionTo}${el.correctionDate}`,
            field: 'Direcția',
            from: directions.find((d) => d.id === el.directionFrom)?.name,
            to: directions.find((d) => d.id === el.directionTo)?.name,
          });
        }
        if (el.personCodeFrom || el.personCodeTo) {
          correctionTable.push({
            ...el,
            id: `1${el.personCodeTo}${el.correctionDate}`,
            field: 'IDNP (Șofer)',
            from: el.personCodeFrom,
            to: el.personCodeTo,
          });
        }
        if (el.nameFrom || el.nameTo) {
          correctionTable.push({
            ...el,
            id: `2${el.nameTo}${el.correctionDate}`,
            field: 'Nume prenume (Șofer)',
            from: el.nameFrom,
            to: el.nameTo,
          });
        }
        if (el.personCountryIdFrom || el.personCountryIdTo) {
          correctionTable.push({
            ...el,
            id: `3${el.personCountryIdTo}${el.correctionDate}`,
            field: 'Țară (persoană fizică)',
            from: countries?.find((c) => c.id === el.personCountryIdFrom)?.name,
            to: countries?.find((c) => c.id === el.personCountryIdTo)?.name,
          });
        }
        if (el.idnoFrom || el.idnoTo) {
          correctionTable.push({
            ...el,
            id: `4${el.idnoTo}${el.correctionDate}`,
            field: 'IDNO',
            from: el.idnoFrom,
            to: el.idnoTo,
          });
        }
        if (el.companyCountryIdFrom || el.companyCountryIdTo) {
          correctionTable.push({
            ...el,
            id: `5${el.companyCountryIdTo}${el.correctionDate}`,
            field: 'Țară (persoană juridică)',
            from: countries?.find((c) => c.id === el.companyCountryIdFrom)
              ?.name,
            to: countries?.find((c) => c.id === el.companyCountryIdTo)?.name,
          });
        }
        if (el.vehicleNumberFrom || el.vehicleNumberTo) {
          correctionTable.push({
            ...el,
            id: `6${el.vehicleNumberTo}${el.correctionDate}`,
            field: 'Nr. auto',
            from: el.vehicleNumberFrom,
            to: el.vehicleNumberTo,
          });
        }
        if (el.vehicleVinFrom || el.vehicleVinTo) {
          correctionTable.push({
            ...el,
            id: `7${el.vehicleVinTo}${el.correctionDate}`,
            field: 'Nr. Caroserie/VIN',
            from: el.vehicleVinFrom,
            to: el.vehicleVinTo,
          });
        }
        if (el.vehicleCountryIdFrom || el.vehicleCountryIdTo) {
          correctionTable.push({
            ...el,
            id: `8${el.vehicleCountryIdTo}${el.correctionDate}`,
            field: 'Țara (vehicul)',
            from: countries?.find((c) => c.id === el.vehicleCountryIdFrom)
              ?.name,
            to: countries?.find((c) => c.id === el.vehicleCountryIdTo)?.name,
          });
        }
        if (el.trailerNumberFrom || el.trailerNumberTo) {
          correctionTable.push({
            ...el,
            id: `9${el.trailerNumberTo}${el.correctionDate}`,
            field: 'Nr. remorcă 1',
            from: el.trailerNumberFrom,
            to: el.trailerNumberTo,
          });
        }
        if (el.trailerVinFrom || el.trailerVinTo) {
          correctionTable.push({
            ...el,
            id: `10${el.trailerVinTo}${el.correctionDate}`,
            field: 'Nr. Caroserie/VIN (remorcă 1)',
            from: el.trailerVinFrom,
            to: el.trailerVinTo,
          });
        }
      });
      setCorrectionHistory(correctionTable);
    }
  }, [users.length, countries, dataCorrections]);
  const customDateSorter = (a, b) => {
    const dateA = new Date(a.correctionDate);
    const dateB = new Date(b.correctionDate);

    return dateA - dateB;
  };

  const columns = useMemo(
    () => [
      {
        sort: true,
        title: 'Modificat de',
        key: 'modifiedBy',
        dataIndex: 'userId',
        width: 100,
        render: (row) => {
          return users.find((el) => el.id === row)?.fullname;
        },
      },
      {
        title: 'Post vamal al inspectorului',
        key: 'customsPost',
        sort: true,
        filter: true,
        width: 150,
        centered: true,
        render: (row) => {
          const user = users?.find((el) => el.id === row.userId);
          const userPosts = user?.customsPostList
            ?.map((post) => `(${post.code}) ${post.name}`)
            ?.toString();

          return userPosts;
        },
      },
      {
        title: 'Modificat la',
        key: 'correctionDate',
        width: 100,
        sorter: customDateSorter,
        render: (row) => {
          return (
            <>
              {row.correctionDate
                ? dayjs(row.correctionDate).format('DD.MM.YYYY HH:mm:ss')
                : ''}
            </>
          );
        },
      },
      Column.text('field', 'Câmpul modificat', {
        width: 100,
        sort: false,
        filter: true,
      }),
      Column.text('to', 'Modificat în', {
        width: 100,
        sort: false,
      }),
      Column.text('from', 'Modificat din', {
        width: 100,
        sort: false,
      }),
      Column.actions(t('table.actions'), (record) => (
        <span style={{ textAlign: 'right' }}>
          <Tooltip title="Vizualizează motivul">
            <Button
              shape="circle"
              type="link"
              icon={<EyeOutlined />}
              onClick={() => showCorrectionReasonModal(record)}
            />
          </Tooltip>
        </span>
      )),
    ],
    [t, users, showCorrectionReasonModal],
  );

  return (
    <>
      {correctionHistory.length > 0 ? (
        <>
          <h1
            style={{
              fontWeight: 600,
              fontSize: '18px',
              lineHeight: '32px',
            }}
          >
            Modificări
          </h1>
          <Table
            columns={columns}
            dataSource={correctionHistory}
            pagination={false}
            {...rest}
          />
          <Modal
            maskClosable={false}
            centered
            style={{ width: '400px' }}
            title="Motivul corectării datelor"
            visible={correctionReasonModal}
            onOk={() => setCorrectionReasonModal(false)}
            onCancel={() => setCorrectionReasonModal(false)}
            cancelText="Înapoi"
          >
            <div style={{ fontWeight: '600', marginBottom: '10px' }}>
              Scrisoare/ PV
            </div>
            <Row gutter={12} style={{ marginBottom: '10px' }}>
              <Col span={12}>
                <label> Număr </label>
                <Input disabled value={correctionLetter.number} />
              </Col>
              <Col span={12}>
                <label> Data </label>
                <DatePicker
                  format="DD.MM.YYYY"
                  disabled
                  value={correctionLetter.date}
                />
              </Col>
            </Row>
            <label> Motiv </label>
            <TextArea
              value={correctionLetter.reason}
              maxLength={3000}
              disabled
              id="reason"
              rows={6}
            />
          </Modal>
        </>
      ) : null}
    </>
  );
};

export default CorrectionTable;
