import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Tooltip from 'antd/lib/tooltip';
import { Badge } from 'antd';
import { MessageOutlined, WarningOutlined } from '@ant-design/icons';
import {
  checkStatus,
  findAllChats,
  registerConnection,
} from './chat-service/chat-users';
import AuthContext from '../auth';
import Chat from './Chat';
import useSockJS from './helpers/useSockJS';
import {
  SEND_PRIVATE_URI,
  subscribePrivateURI,
  WS_URL,
} from './chat-service/chat-service';
import EntityTypes from './enums/EntityTypes';
import useDatasource from '../../hooks/useDatasource';

const STATUS_CHECK_MS = 15000;

const ChatWrapper = ({
  collapsed,
  setLayoutRightPadding,
  setLayoutrightSideSmallPadding,
}) => {
  const { user } = useContext(AuthContext);
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [chats, setChats] = useState([]);
  const [userStatus, setUserStatus] = useState([]);

  const { isConnected, lastMessage, send, subscribe, connect } = useSockJS(
    WS_URL,
    {
      enableDebug: false,
    },
  );

  const { content, reload } = useDatasource(findAllChats);

  useEffect(() => {
    setChats(content);
  }, [content]);

  useEffect(() => {
    if (isConnected && user?.id) {
      subscribe(subscribePrivateURI(user.id));
    }
    // eslint-disable-next-line
  }, [isConnected, user?.id]);

  const appendUser = useCallback(
    (chatUser, after = () => {}) => {
      if (chatUser?.id) {
        registerConnection(chatUser.id).then(async (e) => {
          reload();
          const data = await e.json();
          after({ ...data, chatter: chatUser });
        });
      } else {
        reload();
      }
    },
    [reload],
  );

  const setUnreadMessageCount = useCallback((openedChat, unreadMessages) => {
    if (openedChat?.id) {
      setChats((prev) => {
        const otherChats = prev.filter((el) => el?.id !== openedChat?.id);
        return [{ ...openedChat, unreadMessages }, ...otherChats];
      });
    }
  }, []);

  const handleChatVisible = (vis) => {
    setIsChatVisible(vis || ((prev) => !prev));
  };

  const handleSendMessage = useCallback(
    (message) => send(SEND_PRIVATE_URI, message),
    [send],
  );

  const totalMessages = useMemo(() => {
    return (
      chats?.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue.unreadMessages,
        0,
      ) || 0
    );
  }, [chats]);

  useEffect(() => {
    const fetchStatus = () => {
      const users = chats?.filter((el) => el.type === EntityTypes.USER);
      if (users?.length > 0) {
        checkStatus(users?.map((el) => el.chatter?.username))
          .then(setUserStatus)
          .catch();
      }
    };
    fetchStatus();

    const intervalId = setInterval(fetchStatus, STATUS_CHECK_MS);
    return () => clearInterval(intervalId);
  }, [chats]);

  const getUsersModified = () => {
    let users = chats?.filter((el) => el.type === EntityTypes.USER);
    users = users?.map((el) => {
      const status = userStatus?.find(
        (st) => st.username === el.chatter?.username,
      );
      return { ...el, status: status?.status || null };
    });
    return users;
  };

  return (
    <>
      <Tooltip title={`${collapsed ? 'Mesagerie' : ''}`} placement="right">
        <button
          className="header__chat-btn"
          type="submit"
          onClick={handleChatVisible}
        >
          <div className="calculator__focused-indicator" />
          <Badge
            count={
              isConnected ? (
                totalMessages
              ) : (
                <WarningOutlined style={{ color: 'red' }} />
              )
            }
            overflowCount={99}
          >
            <MessageOutlined />
          </Badge>
        </button>
      </Tooltip>

      <Chat
        isConnected={isConnected}
        chatReconnect={connect}
        users={getUsersModified()}
        groups={chats?.filter((el) => el.type === EntityTypes.GROUP)}
        reloadChats={reload}
        onSend={handleSendMessage}
        receivedMessage={lastMessage}
        appendUser={appendUser}
        setUnreadMessageCount={setUnreadMessageCount}
        visible={isChatVisible}
        handleChat={handleChatVisible}
        setLayoutRightPadding={setLayoutRightPadding}
        setLayoutrightSideSmallPadding={setLayoutrightSideSmallPadding}
      />
    </>
  );
};
export default ChatWrapper;
