import React, { useCallback, useMemo } from 'react';
import { Badge, Button, Collapse, Empty } from 'antd';
import { ArrowLeftOutlined, MenuOutlined } from '@ant-design/icons';
import SelectHelper from '../../../services/utils/helper/SelectHelper';
import { getPersonsLikeName } from '../../../services/admin/users/users';
import UserSettings from './UserSettings';
import UsersList from '../UsersList';
import GroupsList from '../GroupsList';

const ChatMenu = ({
  handleMenu,
  isMenuVisible,
  users,
  visible,
  appendUser,
  onEntitySelect,
  groups,
  handleMenusVisible,
  reloadChats,
  rightSideChat,
  selectedEntityId,
}) => {
  const { Panel } = Collapse;
  const handleSelectFromSearch = useCallback(
    (selected) => {
      if (!users?.some((el) => el.id === selected.id)) {
        appendUser(selected);
      }
      onEntitySelect(selected);
    },
    [appendUser, users, onEntitySelect],
  );

  const userMessages = useMemo(() => {
    return (
      users?.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue.unreadMessages,
        0,
      ) || 0
    );
  }, [users]);

  const groupsMessages = useMemo(() => {
    return (
      groups?.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue.unreadMessages,
        0,
      ) || 0
    );
  }, [groups]);
  const getUsersPanel = () => {
    return (
      <Panel
        header={
          <Badge count={userMessages} overflowCount={99}>
            <div style={{ width: '35px' }}>Toți</div>
          </Badge>
        }
        key="1"
      >
        {users.length === 0 ? (
          <Empty description="Lista de utilizatori este goală" />
        ) : (
          <UsersList
            onSelect={onEntitySelect}
            selectedEntityIds={Array.of(selectedEntityId)}
            content={users}
            setIsMenuVisible={handleMenusVisible}
            rightSideChat={rightSideChat}
          />
        )}
      </Panel>
    );
  };
  const getGroupPanel = () => {
    return (
      <Panel
        header={
          <Badge
            count={groupsMessages}
            overflowCount={99}
            style={{ marginLeft: '100px' }}
          >
            <div style={{ width: '60px' }}>Grupuri</div>
          </Badge>
        }
        key="2"
      >
        {groups.length === 0 ? (
          <Empty description="Lista de grupuri este goală" />
        ) : null}
        <GroupsList
          onSelect={onEntitySelect}
          selectedEntityIds={Array.of(selectedEntityId)}
          content={groups}
          reload={reloadChats}
          setIsMenuVisible={handleMenusVisible}
          rightSideChat={rightSideChat}
        />
      </Panel>
    );
  };
  return (
    <div className={`chat__menu-container ${isMenuVisible ? 'active' : ''} `}>
      <div
        className={`chat__menu ${isMenuVisible ? 'active' : ''} ${
          visible ? 'visible' : ''
        }`}
      >
        <div className="chat__menu-head--items">
          <Button
            icon={isMenuVisible ? <ArrowLeftOutlined /> : <MenuOutlined />}
            type="primary"
            className="chat__menu-btn chat__close-menu--btn"
            onClick={handleMenu}
          />
          <SelectHelper
            className="chat__search-users"
            placeholder="Caută pe cineva"
            onSearch={getPersonsLikeName}
            onSelect={handleSelectFromSearch}
            clearAfterSelect="true"
            fieldName="fullname"
          />
        </div>
        <div className="chat__users">
          <Collapse bordered={false} defaultActiveKey={['1']}>
            {getUsersPanel()}
            {getGroupPanel()}
          </Collapse>
          <UserSettings />
        </div>
      </div>
    </div>
  );
};

export default ChatMenu;
