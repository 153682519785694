import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col,
  Form,
  Input,
  Row,
  Space,
  notification,
  Table,
  Modal,
  Select,
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import DeleteItemIcon from '../../../buttons/DeleteItemIcon';
import useFormErrors from '../../../../hooks/useFormErrors';
import Column from '../../../../helpers/Columns';
import { maxLengthValidator } from '../../../../services/utils/validator/Validator';
import SaveButton from '../../../buttons/SaveButton';
import CancelButton from '../../../buttons/BackButton';
import EditItemIcon from '../../../buttons/EditItemIcon';
import AddEntityButton from '../../../buttons/AddEntityButton';
import RequiredLabel from '../../../../services/utils/RequiredLabel';

const EditFinalDestionationForm = ({ post, onSubmit, onCancel, errors }) => {
  const { t } = useTranslation();
  const [subTypeModal, setSubTypeModal] = useState(false);

  const [form] = Form.useForm();
  const [subTypeForm] = Form.useForm();
  useFormErrors(form, errors);
  const handleAddSubType = useCallback(() => {
    const subType = subTypeForm.getFieldsValue();

    if (!subType.code) {
      notification.error({ message: 'Introduceți codul sub tipului' });
      return;
    }

    if (!subType.name) {
      notification.error({ message: 'Introduceți denumirea sub tipului' });
      return;
    }

    if (subType.id || subType.tempId) {
      form.setFieldsValue({
        finalDestinationSubs: [
          ...form
            .getFieldValue('finalDestinationSubs')
            .filter(
              (el) => el.id !== subType.id || el.tempId !== subType.tempId,
            ),
          subType,
        ],
      });
    } else {
      // temp ID before save to manage Edit/ Delete without saving
      subType.tempId = new Date().getTime();
      form.setFieldsValue({
        finalDestinationSubs: [
          subType,
          ...form.getFieldValue('finalDestinationSubs'),
        ],
      });
    }
    setSubTypeModal(false);
    subTypeForm.resetFields();
  }, [subTypeForm, form]);
  const renderActionRow = useCallback(
    (record) => {
      return (
        <>
          <DeleteItemIcon
            title={t(`entity.admin.riskTypes.deleteSubType`, record)}
            disabled={post.deleted}
            key={`delete${record.id || record.tempId}`}
            isPromise={false}
            action={() => {
              form.setFieldsValue({
                finalDestinationSubs: [
                  ...form
                    .getFieldValue('finalDestinationSubs')
                    .filter(
                      (el) =>
                        el.id !== record.id || el.tempId !== record.tempId,
                    ),
                ],
              });
            }}
          />
          <EditItemIcon
            key={`edit${record.id || record.tempId}`}
            disabled={post.deleted}
            action={() => {
              subTypeForm.setFieldsValue(record);
              setSubTypeModal(true);
            }}
          />
        </>
      );
    },
    [t, subTypeForm, post.deleted, form],
  );
  const subTypesColumns = useMemo(
    () => [
      Column.text('id', t('entity.admin.riskTypes.id'), {
        width: 50,
      }),
      Column.text('code', t('entity.admin.riskTypes.code'), {
        width: 75,
      }),
      Column.text('name', t('entity.admin.riskTypes.name'), {
        width: 150,
      }),
      Column.text('type', t('entity.admin.riskTypes.type'), {
        width: 150,
      }),
      Column.bool('deleted', t('entity._.deleted._'), {
        width: 100,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity._.deleted.true'),
          t('entity._.deleted.false'),
          t('entity._.deleted.unknown'),
        ],
      }),
      Column.actions(t('table.actions'), (record) => renderActionRow(record)),
    ],
    [t, renderActionRow],
  );

  useEffect(() => {
    form.setFieldsValue({
      ...post,
      finalDestinationSubs: post.finalDestinationSubs,
    });
  }, [post, form]);

  return (
    <>
      <PageHeader title={t('entity.admin.finalDestinations._singular')} />
      <Form
        form={form}
        layout="vertical"
        initialValues={post}
        onFinish={onSubmit}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={t('entity.admin.finalDestinations.code')}
              name="code"
            >
              <Input
                disabled={post.deleted}
                onChange={(e) => {
                  form.setFieldsValue({
                    code: maxLengthValidator(e.target.value, 50),
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label={t('entity.admin.finalDestinations.name')}
              name="name"
            >
              <Input
                disabled={post.deleted}
                onChange={(e) => {
                  form.setFieldsValue({
                    name: maxLengthValidator(e.target.value, 256),
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.List name="finalDestinationSubs">
            {() => (
              <>
                <Row justify="space-between">
                  <PageHeader title={t('entity.admin.riskTypes.subTypes')} />
                  <AddEntityButton
                    onClick={() => setSubTypeModal(true)}
                    disabled={post.deleted}
                  />
                </Row>
                <Table
                  rowKey="id"
                  columns={subTypesColumns}
                  dataSource={form.getFieldValue('finalDestinationSubs')}
                  scroll={{ y: 500 }}
                  pagination={false}
                />
              </>
            )}
          </Form.List>
        </Col>
        <Form.Item>
          <Space>
            <SaveButton disabled={post.deleted} />
            <CancelButton onCancel={onCancel} />
          </Space>
        </Form.Item>
      </Form>
      <Modal
        maskClosable={false}
        width={600}
        visible={subTypeModal}
        onCancel={() => setSubTypeModal(false)}
        onOk={handleAddSubType}
      >
        <PageHeader
          title={t('entity.admin.riskTypes.subType', {
            type: post.name || '',
          })}
        />
        <Form form={subTypeForm}>
          <Row>
            <Col span={24}>
              <Form.Item
                label={
                  <RequiredLabel title={t('entity.admin.riskTypes.code')} />
                }
                name="code"
                labelCol={{ span: 24 }}
              >
                <Input
                  disabled={post.deleted}
                  onChange={(e) => {
                    subTypeForm.setFieldsValue({
                      code: maxLengthValidator(e.target.value, 5),
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label={
                  <RequiredLabel title={t('entity.admin.riskTypes.name')} />
                }
                name="name"
                labelCol={{ span: 24 }}
              >
                <Input
                  disabled={post.deleted}
                  onChange={(e) => {
                    subTypeForm.setFieldsValue({
                      name: maxLengthValidator(e.target.value, 150),
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label={
                  <RequiredLabel title={t('entity.admin.riskTypes.type')} />
                }
                name="type"
                labelCol={{ span: 24 }}
              >
                <Select allowClear style={{ width: '100%' }}>
                  {['IM', 'EX'].map((option) => (
                    <Select.Option key={option} value={option}>
                      {option}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {/*  DO NOT DELETE id noStyle it represent entity id currently on edit */}
              <Form.Item name="id" noStyle />
              <Form.Item name="tempId" noStyle />
              <Form.Item name="deleted" noStyle />
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default EditFinalDestionationForm;
