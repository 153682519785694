import { notification } from 'antd';

export const validateTransportUnitsRisk = (form) => {
  if (!form.getFieldValue('plateNumber')) {
    notification.warning({
      message: 'Completați câmpul Număr de înmatriculare',
    });
    return false;
  }

  if (!form.getFieldValue('countryId')) {
    notification.warning({
      message: 'Completați câmpul Țară',
    });
    return false;
  }
  return true;
};
