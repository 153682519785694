import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
  useContext,
} from 'react';
import {
  Form,
  Row,
  Col,
  Input,
  Select,
  Button,
  notification,
  Divider,
  Typography,
  DatePicker,
  Checkbox,
  Tooltip,
  Collapse,
} from 'antd';
import { SearchOutlined, RightCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { FormContextProvider } from '../../hooks/useFormContext';
import { getTransportAuthorization } from '../../services/admin/web-services/anta-transport-authorization';
import { addAnta } from '../../services/admin/web-services/anta-transactions';
import useFormErrors from '../../hooks/useFormErrors';
import useDictionaries from '../../hooks/useDictionaries';
import { findAll as findAllServicesDescription } from '../../services/taxonomies/services-description-anta';
import { findAll as findAllAuthorizationTypes } from '../../services/taxonomies/authorization-types';
import { findAll as findAllTrafficCountries } from '../../services/taxonomies/traffic-countries';
import { findAll as findAllCountries } from '../../services/admin/geo/countries';
import {
  confirmStep,
  enableSteps,
} from '../../services/transactions/transactions';
import AppLoader from '../auth/AppLoader';
import CancelButton from '../buttons/BackButton';
import {
  dotValidator,
  maxLengthValidator,
  numberValidator,
  vinNumberValidator,
} from '../../services/utils/validator/Validator';
import Icon from '../Icon';
import { useClickOutside } from '../../hooks/useClickOutside';
import NextButton from '../buttons/NextButton';
import TransactionStatus from '../../enums/TransactionStatus';
import AuthContext from '../auth';
import RequiredLabel from '../../services/utils/RequiredLabel';

const WITHOUT_AUTHORISATION = 'FĂRĂ AUTORIZAȚIE';

const dictionaries = {
  descriptions: findAllServicesDescription,
  authorizationTypes: findAllAuthorizationTypes,
  trafficCountries: findAllTrafficCountries,
  countries: findAllCountries,
};

const crossingDirections = {
  content: [
    { code: 0, name: 'INTRARE' },
    { code: 1, name: 'IEȘIRE' },
  ],
};

const { Text } = Typography;
const { TextArea } = Input;

const universale = [
  { id: 1, name: 'Țară terță' },
  { id: 2, name: 'Bilateral' },
  { id: 3, name: 'Tranzit' },
];

const statuses = [
  { id: 0, name: 'Nu există așa autorizație' },
  { id: 1, name: 'Autorizația este valabilă' },
  {
    id: 2,
    name: 'Autorizația este stinsă',
  },
];

const Anta = ({
  transaction,
  errors,
  skipTab,
  setIsAntaAmount,
  readOnly,
  reload,
  moveNext = () => {},
  moveBack = () => {},
}) => {
  const { user } = useContext(AuthContext);
  const dateFormat = 'YYYY-MM-DD';
  const { Panel } = Collapse;
  const { t } = useTranslation();

  const [{ countries, descriptions, authorizationTypes, trafficCountries }] =
    useDictionaries(dictionaries);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [loadingLoader, setLoadingLoader] = useState(false);
  const [autorizationInfoBtn, setAutorizationInfoBtn] = useState(false);
  const [autorizationInfo, setAutorizationInfo] = useState(null);
  const [mixedAssemblyBox, setMixedAssemblyBox] = useState(false);
  const [requireAuthorisation, setRequireAuthorisation] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [methodOfUse, setMethodOfUse] = useState();
  const [currentAuthorisation, setCurrentAuthorisation] = useState({
    country: null,
    usageMethod: null,
  });
  const [allowedAuthorisations, setAllowedAuthorisations] = useState([]);

  const [selectedDepartureCountryAlpha3, setSelectedDepartureCountryAlpha3] =
    useState();
  const [
    selectedDestinationCountryAlpha3,
    setSelectedDestinationCountryAlpha3,
  ] = useState();
  const [selectedDCountryCode, setSelectedDCountryCode] = useState();
  const { loading, content } = descriptions;
  const [form] = Form.useForm();
  const popupButtonRef = useRef(null);

  useFormErrors(form, errors);

  useEffect(() => {
    let allowed = [];
    if (authorizationTypes?.content) {
      allowed = authorizationTypes?.content?.filter((el) => !el.deleted);
    }
    if (
      currentAuthorisation?.country?.authorizationTypes &&
      currentAuthorisation?.country?.authorizationTypes?.length > 0
    ) {
      const countryAuthorisations =
        currentAuthorisation?.country?.authorizationTypes?.split(',');
      allowed = allowed.filter((el) =>
        countryAuthorisations?.includes(el?.code),
      );
    }
    setAllowedAuthorisations(allowed);
  }, [authorizationTypes?.content, currentAuthorisation]);

  const closePopup = (e) => {
    if (popupButtonRef.current && popupButtonRef.current.contains(e.target)) {
      return;
    }
    setAutorizationInfoBtn(false);
  };
  const handleAuthorizationBtn = () => {
    setAutorizationInfoBtn((prev) => !prev);
  };
  const convertKilosToTons = (kilos) => {
    return kilos && kilos !== null ? (kilos * 0.001).toFixed(3) * 1 : null;
  };

  const authorizationPopup = useClickOutside(closePopup, autorizationInfoBtn);

  const checkAuthorisation = useCallback(() => {
    const { country, usageMethod } = currentAuthorisation;
    if (country && country.authorisationNotRequired) {
      const {
        additionalUsageMethod,
        additionalMaxWeight,
        additionalMaxGoodsWeight,
        additionalWithCargo,
      } = country;

      if (additionalUsageMethod) {
        const allowedMethods = additionalUsageMethod
          .split(',')
          .map((method) => Number(method));
        if (!allowedMethods.includes(usageMethod)) {
          return false;
        }
      }

      // Check if the weight limit is exceeded
      const vehicleWeight = transaction?.scale?.vehicleWeight;
      const goodsWeight = transaction?.scale?.goodsWeight;
      if (additionalMaxWeight && !vehicleWeight) {
        return false;
      }
      if (
        additionalMaxWeight &&
        additionalMaxWeight < convertKilosToTons(vehicleWeight)
      ) {
        return false;
      }
      if (additionalMaxGoodsWeight && !goodsWeight) {
        return false;
      }
      if (
        additionalMaxGoodsWeight &&
        additionalMaxGoodsWeight < convertKilosToTons(goodsWeight)
      ) {
        return false;
      }

      // Check if cargo is required but not present
      return !(additionalWithCargo && !transaction.hasGoods);
    }
    return false;
  }, [currentAuthorisation, transaction.hasGoods, transaction.scale]);

  const autorizationSelect = (e) => {
    setAutorizationInfo(
      authorizationTypes.content.find(
        (option) => option.thirdPartyAuthorizationId === e,
      ),
    );
  };

  const [defaultMethodValue] = useState(undefined);

  useEffect(() => {
    if (checkAuthorisation()) {
      setRequireAuthorisation(false);
      form.setFieldsValue({ authorizationType: WITHOUT_AUTHORISATION });
    } else {
      setRequireAuthorisation(true);
      form.setFieldsValue({ authorizationType: null });
    }
  }, [
    form,
    currentAuthorisation,
    transaction.hasGoods,
    transaction.scale,
    checkAuthorisation,
  ]);

  useEffect(() => {
    const values = {
      ...transaction?.anta,
      validFrom: transaction?.anta?.validFrom
        ? dayjs(transaction?.anta.validFrom, dateFormat)
        : null,
      validTo: transaction?.anta?.validTo
        ? dayjs(transaction?.anta.validTo, dateFormat)
        : null,

      authorizationMethodOfUse: transaction?.anta?.authorizationMethodOfUse
        ? parseInt(transaction?.anta?.authorizationMethodOfUse, 10)
        : null,

      // eslint-disable-next-line no-nested-ternary
      authorizationType: transaction?.anta?.authorizationType
        ? transaction?.anta?.authorizationType === '-1'
          ? WITHOUT_AUTHORISATION
          : parseInt(transaction?.anta?.authorizationType, 10)
        : null,
    };
    if (transaction?.anta?.authorizationType === '-1') {
      setRequireAuthorisation(false);
    }
    if (!values.inspector) {
      values.inspector = user?.username;
    }

    // set traffic country
    if (!transaction?.anta?.countryCode) {
      const mainVehicle = transaction?.vehicles?.find((el) => !el.isTrailer);
      if (mainVehicle?.countryId) {
        const countryMain = countries?.content.find(
          (el) => el.id === mainVehicle?.countryId,
        );
        const trafficCountryMain = trafficCountries?.content?.find(
          (el) => el.code === countryMain.alpha3,
        );
        values.countryCode = trafficCountryMain?.code;
      }
    }

    // check if tara is terta
    if (
      !transaction?.anta?.authorizationMethodOfUse &&
      transaction?.loadCountryId &&
      transaction?.unloadCountryId
    ) {
      const destinationCountry = countries?.content.find(
        (el) => el.id === transaction?.unloadCountryId,
      );
      const departureCountry = countries?.content.find(
        (el) => el.id === transaction?.loadCountryId,
      );
      if (
        destinationCountry?.alpha3 !== values.countryCode &&
        departureCountry?.alpha3 !== values.countryCode
      ) {
        values.authorizationMethodOfUse = universale.find(
          (el) => el.id === 1,
        )?.id;
      }
    }
    form.setFieldsValue(values);
  }, [
    transaction,
    form,
    authorizationTypes,
    trafficCountries?.content,
    user?.username,
    countries,
  ]);

  useEffect(() => {
    let vinCode = transaction?.anta?.vin;
    if (!vinCode) {
      vinCode = transaction?.vehicles?.find((el) => !el.isTrailer)?.vinCode;
    }
    const direction = transaction?.direction;
    if (vinCode) {
      form.setFieldsValue({ vin: vinCode });
    }
    if (transaction?.anta?.crossingDirection) {
      form.setFieldsValue({
        crossingDirection: transaction?.anta?.crossingDirection,
      });
    } else if (direction) {
      form.setFieldsValue({ crossingDirection: direction === 'IN' ? 0 : 1 });
    }
  }, [form, transaction?.vehicles, transaction?.direction, transaction?.anta]);

  const handleGetAuthorization = useCallback(() => {
    setLoadingLoader(true);
    if (
      form.getFieldsValue() &&
      form.getFieldValue('authorizationNumber') &&
      form.getFieldValue('authorizationType') &&
      form.getFieldValue('vin') &&
      form.getFieldValue('crossingDirection') !== undefined
    ) {
      setLoadingLoader(true);
      const data = form.getFieldsValue();
      getTransportAuthorization(data).then(
        (trns) => {
          if (trns) {
            if (trns.number === null && trns.validFrom === null) {
              setLoadingLoader(false);
              notification.warning({
                message: 'Nu a fost gasită nici o autorizație',
              });
              return;
            }
            setLoadingLoader(false);
            form.setFieldsValue({
              transportCompanyName: trns.transportCompanyName,
              truckRegPlateNumber: trns.truckRegPlateNumber,
              trailerRegPlateNumber: trns.trailerRegPlateNumber,
              validFrom: trns.validFrom ? dayjs(trns.validFrom) : null,
              validTo: trns.validTo ? dayjs(trns.validTo) : null,
              status: trns.status,
              emissionStandards: trns?.emissionStandards,
              mixedAssembly: trns.mixedAssembly,
            });
            setMixedAssemblyBox(trns.mixedAssembly);
          } else {
            setLoadingLoader(false);
            notification.warning({
              message: 'Nu a fost gasită nici o autorizație',
            });
          }
        },
        () => {
          setLoadingLoader(false);
          notification.error({
            message: 'Eroare la căutarea datelor...',
          });
        },
      );
    } else {
      setLoadingLoader(false);
      if (!form.getFieldValue('authorizationType')) {
        notification.error({
          message: 'Lipsește tipul autorizației',
        });
      } else if (!form.getFieldValue('authorizationNumber')) {
        notification.error({
          message: 'Lipsește numărul autorizației',
        });
      } else if (form.getFieldValue('crossingDirection') === undefined) {
        notification.error({
          message: 'Lipsește direcția',
        });
      } else if (!form.getFieldValue('vin')) {
        notification.error({
          message: 'Lipsește VIN',
        });
      }
    }
  }, [form]);

  // eslint-disable-next-line
  const confirmANTA = useCallback(() => {
    setLoadingLoader(true);
    confirmStep(transaction.id, 'HAS_ANTA')
      .then(() => {
        notification.success({
          message: t('actions.infirm'),
          duration: 3,
        });
        skipTab('#HAS_ANTA');
      })
      .catch(() => {
        notification.error({
          message: t('actions.infirmErr'),
          duration: 3,
        });
      })
      .finally(() => {
        setLoadingLoader(false);
        reload();
      });
  }, [t, transaction.id, skipTab, reload]);

  const handleSaveData = useCallback(() => {
    if (!form?.getFieldValue('countryCode')) {
      notification.warning({
        message: 'Selectați țara',
      });
      return;
    }
    if (
      requireAuthorisation &&
      !form?.getFieldValue('authorizationMethodOfUse')
    ) {
      notification.warning({
        message: 'Selectați metoda de utilizare',
      });
      return;
    }
    if (requireAuthorisation && !form?.getFieldValue('authorizationType')) {
      notification.warning({
        message: 'Selectați tipul autorizației',
      });
      return;
    }
    if (requireAuthorisation && !form?.getFieldValue('authorizationNumber')) {
      notification.warning({
        message: 'Introduceți numărul autorizației',
      });
      return;
    }

    if (form?.getFieldValue('crossingDirection') === undefined) {
      notification.warning({
        message: 'Selectați direcția',
      });
      return;
    }
    if (!form?.getFieldValue('vin')) {
      notification.warning({
        message: 'Câmpul VIN este obligatoriu',
      });
      return;
    }
    if (form?.getFieldValue('vin')?.length < 17) {
      notification.warning({
        message: 'Introduceți minim 17 simboluri pentru VIN',
      });
      return;
    }
    setLoadingLoader(true);
    if (form.getFieldsValue()) {
      const anta = {
        ...form.getFieldsValue(),
        transactionId: transaction.id,
        authorizationType:
          form.getFieldValue('authorizationType') === WITHOUT_AUTHORISATION
            ? -1
            : form.getFieldValue('authorizationType'),
      };
      addAnta(anta)
        .then(() => {
          if (anta.amountToPay && anta.amountToPay > 0) {
            setIsAntaAmount(true);
          }
          moveNext();
        })
        .catch(() => {
          notification.error({
            message: t('actions.confirmErr'),
            duration: 3,
          });
        })
        .finally(() => {
          reload();
          setLoadingLoader(false);
        });
    }
  }, [
    t,
    form,
    transaction.id,
    setIsAntaAmount,
    reload,
    requireAuthorisation,
    moveNext,
  ]);

  const handleEnableStep = useCallback(() => {
    setLoadingLoader(true);
    enableSteps(transaction.id, 'HAS_ANTA')
      .catch(() => {
        notification.error({
          message: 'A apărut o eroare. Contactați administratorul',
        });
      })
      .finally(() => {
        setLoadingLoader(false);
        reload();
      });
  }, [reload, transaction.id]);

  const handleSelectTrafficCounty = (countryCode) => {
    const selected = countryCode
      ? trafficCountries?.content?.find((el) => el.code === countryCode)
      : null;

    setCurrentAuthorisation((prev) => ({ ...prev, country: selected }));
    setSelectedDCountryCode(countryCode);
    if (
      selectedDepartureCountryAlpha3 !== selectedDCountryCode &&
      selectedDestinationCountryAlpha3 !== selectedDCountryCode
    ) {
      form.setFieldsValue({
        authorizationMethodOfUse: universale.find((el) => el.id === 1)?.id,
      });
      setMethodOfUse(1);
    }
  };

  const handleMethodOfUseChange = (method) => {
    setCurrentAuthorisation((prev) => ({
      ...prev,
      usageMethod: method || null,
    }));
  };

  return (
    <>
      <div className={`loaderOverlay ${loadingLoader ? 'active' : ''}`}>
        <AppLoader />
      </div>
      {transaction?.status === TransactionStatus.PENDING ? (
        <Button
          disabled={readOnly}
          onClick={handleEnableStep}
          type="primary"
          danger={transaction?.hasAnta}
        >
          {!transaction?.hasAnta ? 'Inițiere control' : 'Anulare control'}
        </Button>
      ) : null}

      <Divider plain>
        <Text strong>{t('entity.admin.ansa.control')}</Text>
      </Divider>
      <FormContextProvider form={form} initialValues={transaction?.anta}>
        <Form form={form} layout="vertical">
          <Row gutter={15}>
            <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                label="Țară"
                name="countryCode"
                rules={[
                  {
                    required: transaction?.hasAnta,
                    message: 'Câmpul este obligatoriu',
                  },
                ]}
              >
                <Select
                  onChange={handleSelectTrafficCounty}
                  allowClear
                  disabled={readOnly || !transaction?.hasAnta}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) !== -1
                  }
                >
                  {trafficCountries?.content
                    ?.sort((a, b) => a.code?.localeCompare(b.code))
                    .filter((option) => !option.deleted)
                    .map((option) => (
                      <Select.Option key={option.id} value={option.code}>
                        {`${option.code} - ${option.name}`}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                label="Metodă de utilizare"
                name="authorizationMethodOfUse"
                rules={[
                  {
                    required: requireAuthorisation && transaction?.hasAnta,
                    message: 'Câmpul este obligatoriu',
                  },
                ]}
                initialValue={defaultMethodValue}
              >
                <Select
                  onChange={handleMethodOfUseChange}
                  allowClear
                  disabled={readOnly || !transaction?.hasAnta}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) !== -1
                  }
                >
                  {universale.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {`${option.name}`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={6}
              xxl={6}
              style={{ position: 'relative' }}
            >
              <Form.Item
                name="authorizationType"
                class={
                  autorizationInfo?.remark || autorizationInfo?.usageConditions
                    ? 'authorizaztion__type-item'
                    : ''
                }
                label={
                  <RequiredLabel
                    title="Tip autorizație"
                    required={transaction?.hasAnta && requireAuthorisation}
                  />
                }
              >
                <Select
                  allowClear
                  disabled={readOnly || !transaction?.hasAnta}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) !== -1
                  }
                  onSelect={autorizationSelect}
                  onClear={() => {
                    setAutorizationInfo(null);
                  }}
                >
                  {allowedAuthorisations.map((option) => (
                    <Select.Option
                      key={option.id}
                      value={option.thirdPartyAuthorizationId}
                    >
                      {`${option.name}`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {autorizationInfo?.remark || autorizationInfo?.usageConditions ? (
                <>
                  <Tooltip
                    className="authorization__popup-info"
                    title={
                      <div
                        className="authorization__popup-content"
                        ref={authorizationPopup}
                      >
                        <Collapse ghost>
                          <Panel header="Remarcă:">
                            {autorizationInfo?.remark}
                          </Panel>
                          <Panel header="Condiții de utilizare:">
                            {autorizationInfo?.usageConditions}
                          </Panel>
                        </Collapse>
                      </div>
                    }
                    visible={autorizationInfoBtn}
                  >
                    <button
                      type="button"
                      onClick={handleAuthorizationBtn}
                      className="authorization__btn"
                      ref={popupButtonRef}
                    >
                      <Icon
                        name="info"
                        className="authorization__icon"
                        style={{ width: '15px', height: '15px' }}
                      />
                    </button>
                  </Tooltip>
                </>
              ) : null}
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="authorizationNumber"
                label={
                  <RequiredLabel
                    title="Nr. Autorizației"
                    required={transaction?.hasAnta && requireAuthorisation}
                  />
                }
              >
                <Input
                  disabled={readOnly || !transaction?.hasAnta}
                  maxLength={50}
                  style={{
                    textTransform: 'uppercase',
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                label="An"
                name="year"
                initialValue={new Date().getFullYear()}
              >
                <Input
                  onChange={(e) => {
                    form.setFieldsValue({
                      year: numberValidator(e.target.value, 4),
                    });
                  }}
                  disabled={readOnly || !transaction?.hasAnta}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="crossingDirection"
                label={
                  <RequiredLabel
                    title="Direcție"
                    required={transaction?.hasAnta}
                  />
                }
              >
                <Select
                  disabled={!transaction?.hasAnta || readOnly}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) !== -1
                  }
                >
                  {crossingDirections.content.map((option) => (
                    <Select.Option key={option.code} value={option.code}>
                      {`${option.name}`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="vin"
                label={
                  <RequiredLabel title="VIN" required={transaction?.hasAnta} />
                }
                initialValue={
                  transaction?.vehicles?.find((el) => !el.isTrailer)?.vinCode
                }
              >
                <Input
                  onChange={(e) => {
                    form.setFieldsValue({
                      vin: vinNumberValidator(e.target.value),
                    });
                  }}
                  maxLength={17}
                  disabled={readOnly || !transaction?.hasAnta}
                  style={{
                    textTransform: 'uppercase',
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                label="Nr. locuri pentru pasageri"
                name="passengersSeats"
                initialValue={
                  transaction?.anta?.passengersSeats || transaction?.persons
                }
              >
                <Input
                  disabled={readOnly || !transaction?.hasAnta}
                  style={{
                    textTransform: 'uppercase',
                  }}
                  onChange={(e) => {
                    form.setFieldsValue({
                      passengersSeats: numberValidator(e.target.value, 3),
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                label="Nr. Rută"
                name="routeNumber"
                initialValue={transaction?.anta?.routeNumber}
              >
                <Input
                  disabled={readOnly || !transaction?.hasAnta}
                  style={{
                    textTransform: 'uppercase',
                  }}
                  onChange={(e) => {
                    form.setFieldsValue({
                      routeNumber: maxLengthValidator(e.target.value, 20),
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                label="Țara de plecare"
                name="departureCountryId"
                initialValue={
                  transaction?.anta?.departureCountryId ||
                  transaction?.loadCountryId
                }
              >
                <Select
                  allowClear
                  disabled={readOnly || !transaction?.hasAnta}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) !== -1
                  }
                  onChange={
                    ((value, option) =>
                      setSelectedDepartureCountryAlpha3(option?.alpha3 || ''),
                    handleSelectTrafficCounty)
                  }
                >
                  {countries?.content
                    ?.sort((a, b) => a.alpha2.localeCompare(b.alpha2))
                    .filter((option) => !option.deleted)
                    .map((option) => (
                      <Select.Option
                        key={option.id}
                        value={option.id}
                        alpha3={option.alpha3}
                      >
                        {`${option.alpha2} - ${option.name}`}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                label="Țara de destinație"
                name="destinationCountryId"
                initialValue={
                  transaction?.anta?.destinationCountryId ||
                  transaction?.unloadCountryId
                }
              >
                <Select
                  allowClear
                  disabled={readOnly || !transaction?.hasAnta}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) !== -1
                  }
                  onChange={
                    ((value, option) =>
                      setSelectedDestinationCountryAlpha3(option?.alpha3 || ''),
                    handleSelectTrafficCounty)
                  }
                >
                  {countries?.content
                    ?.sort((a, b) => a.alpha2.localeCompare(b.alpha2))
                    .filter((option) => !option.deleted)
                    .map((option) => (
                      <Select.Option
                        key={option.id}
                        value={option.id}
                        alpha3={option.alpha3}
                      >
                        {`${option.alpha2} - ${option.name}`}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item>
                <Button
                  className="anta__autorisation-search"
                  style={{ transform: 'translateY(30px)', width: '100%' }}
                  type="primary"
                  onClick={() => handleGetAuthorization()}
                  icon={<SearchOutlined />}
                  disabled={
                    readOnly || !transaction?.hasAnta || !requireAuthorisation
                  }
                >
                  Caută autorizația
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <Divider plain>
            <Text strong>Detaliile Autorizației</Text>
          </Divider>
          <Row gutter={15}>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              <Form.Item
                label="Denumire operator de transport"
                name="transportCompanyName"
              >
                <Input
                  disabled
                  style={{
                    textTransform: 'uppercase',
                  }}
                  maxLength={50}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              <Form.Item
                label="Număr de înmatriculare"
                name="truckRegPlateNumber"
              >
                <Input
                  onChange={(e) => {
                    form.setFieldsValue({
                      truckRegPlateNumber: maxLengthValidator(
                        e.target.value,
                        19,
                      ),
                    });
                  }}
                  disabled
                  style={{
                    textTransform: 'uppercase',
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              <Form.Item
                label="Număr de înmatriculare remorcă"
                name="trailerRegPlateNumber"
              >
                <Input
                  onChange={(e) => {
                    form.setFieldsValue({
                      trailerRegPlateNumber: maxLengthValidator(
                        e.target.value,
                        19,
                      ),
                    });
                  }}
                  disabled
                  style={{
                    textTransform: 'uppercase',
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              <Form.Item label="Status" name="status">
                <Select disabled>
                  {statuses.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {`${option.name}`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              <Form.Item name="validFrom" label="Dată eliberare">
                <DatePicker
                  disabled
                  onChange={(date) => setMinDate(date)}
                  disabledDate={(d) => !d || (maxDate && d.isAfter(maxDate))}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              <Form.Item name="validTo" label="Data expirării">
                <DatePicker
                  disabled
                  onChange={(date) => setMaxDate(date)}
                  disabledDate={(d) => !d || (minDate && d.isBefore(minDate))}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              <Form.Item label="Categorii EURO" name="emissionStandards">
                <Input
                  disabled
                  style={{
                    textTransform: 'uppercase',
                  }}
                  maxLength={200}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              <Form.Item label=" " name="mixedAssembly">
                <Checkbox checked={mixedAssemblyBox} disabled>
                  Ansamblu mixt
                </Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              <Form.Item label="Inspector" name="inspector">
                <Input
                  disabled={readOnly || !transaction?.hasAnta}
                  maxLength={100}
                  style={{
                    textTransform: 'uppercase',
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider plain>
            <Text strong>Control</Text>
          </Divider>
          <Row gutter={15}>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              <Form.Item label="Serviciu" name="serviceDescription">
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) !== -1
                  }
                  loading={loading}
                  disabled={readOnly || !transaction?.hasAnta}
                >
                  {content
                    .filter((option) => !option.deleted)
                    .map((option) => (
                      <Select.Option key={option.code} value={option.name}>
                        {`${option.name}`}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              <Form.Item label="Rezultatul controlului" name="controlResult">
                <Input
                  disabled={readOnly || !transaction?.hasAnta}
                  maxLength={50}
                  style={{
                    textTransform: 'uppercase',
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              <Form.Item label="Suma spre achitare" name="amountToPay">
                <Input
                  onChange={(e) => {
                    form.setFieldsValue({
                      amountToPay: maxLengthValidator(
                        dotValidator(e.target.value),
                        10,
                      ),
                    });
                  }}
                  min={0}
                  disabled={readOnly || !transaction?.hasAnta}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ width: '100%', maxWidth: '100%' }}>
            <Col
              style={{ width: '100%', maxWidth: '100%' }}
              className="anta__detail-textarea"
            >
              <Form.Item
                label="Detaliile controlului"
                name="controlDetails"
                style={{ width: '100%', maxWidth: '100%' }}
              >
                <TextArea
                  showCount
                  maxLength={2000}
                  rows={4}
                  disabled={readOnly || !transaction?.hasAnta}
                  style={{
                    textTransform: 'uppercase',
                    width: '100%',
                    maxWidth: '100%',
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ gap: '10px' }}>
            {!readOnly && (
              <>
                <Col>
                  <CancelButton disabled={readOnly} onCancel={moveBack} />
                </Col>
                <Col style={{ maxWidth: '100%' }}>
                  {transaction?.hasAnta ? (
                    <Button
                      type="primary"
                      icon={<RightCircleOutlined />}
                      onClick={handleSaveData}
                    >
                      {t('actions.next')}
                    </Button>
                  ) : (
                    <NextButton navigateFunc={moveNext} />
                  )}
                </Col>
              </>
            )}
          </Row>
        </Form>
      </FormContextProvider>
    </>
  );
};

export default Anta;
