import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import JasperReportsList from '../../../components/admin/reports/JasperReportsList';

const JasperReports = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="reports">
      <Tabs.TabPane tab={t('entity.admin.reports._plural')} key="reports">
        <JasperReportsList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default JasperReports;
