import React from 'react';

import ANTATransportAuthorizationList from './ANTATransportAuthorizationList';

const ANTATransportAuthorization = () => {
  return (
    <>
      <ANTATransportAuthorizationList />
    </>
  );
};

export default ANTATransportAuthorization;
