import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Popconfirm,
  Row,
  Select,
} from 'antd';
import dayjs from 'dayjs';

import {
  CloseCircleOutlined,
  FileAddOutlined,
  RollbackOutlined,
  FormOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/lib/input/TextArea';
import CountrySelect from '../../CountrySelect';
import { findAll as findAllVehicleCategories } from '../../../services/taxonomies/vehicle-categories';
import { rejectTransaction } from '../../../services/admin/web-services/backend';
import AuthContext, { hasPermission } from '../../auth';
import useDictionaries from '../../../hooks/useDictionaries';
import { findAll as findAllReasons } from '../../../services/taxonomies/return-reasons';
import { findAll as findAllConstants } from '../../../services/taxonomies/constants';
import { findAll as findAllCustomsPosts } from '../../../services/admin/users/customs-posts';
import AppLoader from '../../auth/AppLoader';
import {
  findOne,
  generatePdfInNewTab,
} from '../../../services/transactions/transactions';
import WithholdingReasonModal from './WithholdingReasonModal';

const directions = {
  content: [
    { id: 'IN', code: 'IN', name: 'INTRARE' },
    { id: 'OUT', code: 'OUT', name: 'IEȘIRE' },
  ],
};

const functions = {
  content: [
    { code: 'POST_HEAD', name: 'Șef Post Vamal' },
    { code: 'DEPUTY_HEAD', name: 'Șef Adjunct' },
    { code: 'HEAD', name: 'Șef Tura' },
    { code: 'INSPECTOR_SUPERIOR', name: 'Inspector Superior' },
    { code: 'INSPECTOR_MAIN', name: 'Inspector Principal' },
    { code: 'INSPECTOR', name: 'Inspector' },
  ],
};

const dictionaries = {
  vehicleCategories: findAllVehicleCategories,
  returnReasons: findAllReasons,
  constants: findAllConstants,
  customsPosts: findAllCustomsPosts,
};

const ReturModal = ({
  transactionId,
  readOnly,
  returnData,
  allowGenerate = false, // only on readOnly mode
  onClose = () => {},
  initialVisible = false,
}) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [loadingLoader, setLoadingLoader] = useState(false);
  const { user } = useContext(AuthContext);
  const { permissions } = user;

  const [visible, setVisible] = useState(false);
  const [vehicle, setVehicle] = useState({});
  const [getDictionaries, setGetDictionaries] = useState(false);
  const [withholdingReasonsModal, setWithholdingReasonsModal] = useState(false);
  const [
    { customsPosts, vehicleCategories, returnReasons, constants },
    ,
    isDictionariesLoading,
  ] = useDictionaries(dictionaries, { allowFetcher: getDictionaries });

  const handleWithholdTransaction = () => {
    setWithholdingReasonsModal(true);
  };

  const checkTransactionTime = (transaction) => {
    if (transaction.withholdingReason) return false;
    const cargo =
      constants?.content
        ?.find((el) => el.code === 'TERMINAL_MARFA_CATEGORII')
        ?.value?.split(',') || [];

    const isCargo = cargo.includes(
      transaction.vehicles.find((el) => !el.isTrailer && el.isTrailer !== null)
        ?.category,
    );

    if (isCargo) {
      const timeForCargo =
        constants?.content?.find(
          (el) => el.code === 'TRANSACTION_PROCESSING_TIME_CARGO',
        )?.value || null;

      return timeForCargo !== null
        ? transaction.crossingTimeSeconds > Number(timeForCargo)
        : false;
    }

    const timeForVehicles =
      constants?.content?.find(
        (el) => el.code === 'TRANSACTION_PROCESSING_TIME_AUTO',
      )?.value || null;

    return timeForVehicles !== null
      ? transaction.crossingTimeSeconds > Number(timeForVehicles)
      : false;
  };

  const showModal = async () => {
    if (
      !isDictionariesLoading &&
      (transactionId || returnData?.transactionId)
    ) {
      setLoadingLoader(true);

      findOne(transactionId || returnData?.transactionId)
        .then((res) => {
          if (!readOnly && checkTransactionTime(res)) {
            handleWithholdTransaction();
            return;
          }

          const vehicleNotTrailer = res.vehicles?.find(
            (el) => !el.isTrailer && el.isTrailer !== null,
          );
          const person = res.personList?.find(
            (el) => el.isPhysicalPerson && el.isCrossing,
          );
          const company = res.personList?.find(
            (el) => !el.isPhysicalPerson && el.isPhysicalPerson !== null,
          );

          const post = customsPosts?.content?.find(
            (el) => el.id === res?.postId,
          );

          form.setFieldsValue({
            date: returnData?.documentFormattedDate
              ? dayjs(returnData?.documentFormattedDate, 'DD.MM.YYYY')
              : dayjs(),
            country: vehicleNotTrailer?.countryId,
            operator: returnData?.inspectorName
              ? returnData.inspectorName
              : user?.fullname,
            naiming1: person?.fullName,
            naiming2: company?.fullName,
            resident1: person?.personType === 'REZIDENT',
            resident2: company?.personType === 'REZIDENT',
            direction: res.direction,
            companyIDNO: company?.personalCode,
            vehicleType: vehicleNotTrailer?.category,
            personsNumber: res.persons,
            vehicleVinCode: vehicleNotTrailer?.vinCode,
            vehicleNumber1: vehicleNotTrailer?.plateNumber,
            driverPersonalNumber: person?.personalCode,
            customsPost: returnData?.borderCrossingPoint
              ? returnData?.borderCrossingPoint
              : `(${post?.newCode}) ${post?.name}`,
          });
          setVehicle(vehicleNotTrailer);

          setVisible(true);
        })
        .catch(() => {
          notification.error({
            message: 'A apărut o eroare la încărcarea datelor',
          });
        })
        .finally(() => setLoadingLoader(false));
    }
  };

  const getReturnParameters = useCallback(() => {
    if (!form.getFieldValue('returnReason')) {
      notification.error({ message: 'Introduceți motivul returului' });
      return false;
    }
    const data = {
      transactionId,
      returnNumber: returnData?.returnNumber,
      returnReason: form.getFieldValue('returnReason'),
      returnReasonDetailed: form.getFieldValue('returnReasonDetailed'),
      pfShiftChief: form.getFieldValue('pfShiftChief'),
      svTourResponsible: form.getFieldValue('svTourResponsible'),
      documentFormattedDate: form.getFieldValue('date'),
      controlBody: form.getFieldValue('controlBody'),
      goods: form.getFieldValue('goods'),
      returnPassengerGoods: form.getFieldValue('returnPassengerGoods'),
      transporterName: form.getFieldValue('naiming2'),
      transporterAddress: form.getFieldValue('transporterAddress'),
      driverName: form.getFieldValue('naiming1'),
      returnDeadline: form.getFieldValue('returnDeadline'),
      cooperatorJob: form.getFieldValue('cooperatorJob'),
      cooperatorName: form.getFieldValue('cooperatorName'),
      borderCrossingPoint: form.getFieldValue('customsPost'),
      postCode: user?.customsPost?.code,
      vehiclePlateNumber: form.getFieldValue('vehicleNumber1'),
      inspectorName: form.getFieldValue('operator'),
      vehicleType: `${vehicle?.mark ? vehicle?.mark : ''} ${
        vehicle?.model ? vehicle?.model : ''
      } ${form.getFieldValue('vehicleType')}`,
    };
    data.documentFormattedDate =
      data.documentFormattedDate?.format('DD.MM.YYYY');
    return data;
  }, [
    form,
    transactionId,
    user?.customsPost?.code,
    returnData?.returnNumber,
    vehicle?.model,
    vehicle?.mark,
  ]);

  const handleGeneratePdf = () => {
    const submitParameters = getReturnParameters();
    if (!submitParameters) {
      return;
    }
    setLoadingLoader(true);
    generatePdfInNewTab(
      `/return/${transactionId || returnData?.transactionId}`,
      submitParameters,
    )
      .then(() => {
        notification.success({
          message: t('messages.report.generated.success'),
          duration: 3,
        });
      })
      .catch((err) => {
        notification.error({ message: err });
      })
      .finally(() => setLoadingLoader(false));
  };

  const handleRejectTransaction = useCallback(() => {
    setLoadingLoader(true);
    if (!form.getFieldValue('returnReason')) {
      notification.warning({ message: 'Introduceți motivul returului' });
      setLoadingLoader(false);
      return false;
    }
    const data = getReturnParameters();
    rejectTransaction(data)
      .then(() => {
        notification.success({ message: 'Retur succes.', duration: 3 });
        history.push('/home');
        return true;
      })
      .catch(() => {
        notification.error({ message: 'Eroare la acțiunea Retur' });
      })
      .finally(() => setLoadingLoader(false));
    return false;
  }, [history, form, getReturnParameters]);

  const handleRetur = () => {
    setVisible(!handleRejectTransaction());
  };

  const handleRender = () => {
    setGetDictionaries((prev) => !prev);
  };

  useEffect(() => {
    if (initialVisible) {
      handleRender();
    }
  }, [initialVisible]);

  useEffect(
    () => {
      if (
        (!isDictionariesLoading && getDictionaries) ||
        (constants?.content?.length > 0 && !isDictionariesLoading)
      ) {
        showModal();
      }
    }, // eslint-disable-next-line
    [isDictionariesLoading, getDictionaries],
  );

  return (
    <>
      <div className={`loaderOverlay ${loadingLoader ? 'active' : ''}`}>
        <AppLoader />
      </div>

      <Form
        form={form}
        layout="vertical"
        initialValues={{
          ...returnData,
        }}
      >
        {transactionId ? (
          <Button
            key="returButton"
            className="btn__retur"
            onClick={handleRender}
            icon={<CloseCircleOutlined />}
            style={{
              width: '100%',
              marginBottom: '.5rem',
              background: '#e74c3c',
              border: '1px solid #e74c3c',
            }}
          >
            Retur
          </Button>
        ) : null}

        <Modal
          maskClosable={false}
          centered
          className="retur__modal"
          key="returModal"
          width={1300}
          title="Retur"
          visible={visible}
          onCancel={() => {
            setVisible(false);
            onClose();
          }}
          style={{ marginTop: '20px', marginBottom: '20px' }}
          footer={[
            <Button
              key="back"
              onClick={() => setVisible(false)}
              style={{ float: 'left' }}
              icon={<RollbackOutlined />}
            >
              Înapoi
            </Button>,
            <Popconfirm
              key="generateReportPop"
              disabled={readOnly && !returnData?.returnNumber && !allowGenerate}
              placement="topLeft"
              title="Sunteți sigur(ă) că doriți sa generați actul de retur?"
              onConfirm={handleGeneratePdf}
              okText="Ok"
              cancelText={t('actions.cancel')}
            >
              <Button
                key="generateReturn"
                disabled={
                  readOnly && !returnData?.returnNumber && !allowGenerate
                }
                icon={<FileAddOutlined />}
              >
                Generează act retur
              </Button>
            </Popconfirm>,
          ]}
        >
          <Row
            justify="space-between"
            style={{ alignItems: 'center', flexFlow: 'nowrap', gap: '20px' }}
          >
            <Col style={{ width: '100%' }}>
              <Form.Item name="date" label="Data" labelCol={{ span: 24 }}>
                <DatePicker format="DD.MM.YYYY" disabled />
              </Form.Item>
            </Col>
            <Col style={{ width: '100%' }}>
              <Form.Item
                name="operator"
                label="Inspector"
                labelCol={{ span: 24 }}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col style={{ width: '100%' }}>
              <Form.Item
                name="customsPost"
                label="Post Vamal"
                labelCol={{ span: 24 }}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            {hasPermission(permissions, 'REJECT_TRANSACTION') ? (
              <Col>
                <Popconfirm
                  disabled={readOnly}
                  key="returConfirm"
                  placement="bottomRight"
                  title="Confirmați operațiunea Retur?"
                  onConfirm={handleRetur}
                  okText="OK"
                  cancelText={t('actions.cancel')}
                >
                  <Button
                    key="returModalButton"
                    disabled={readOnly}
                    icon={<CloseCircleOutlined />}
                    className="btn__retur"
                    style={{
                      width: '100%',
                      background: '#e74c3c',
                      marginTop: '20px',
                      border: '1px solid #e74c3c',
                    }}
                  >
                    Retur
                  </Button>
                </Popconfirm>
              </Col>
            ) : null}
          </Row>

          <Divider plain>
            <span> Date Generale </span>
          </Divider>

          <Row
            justify="space-between"
            style={{ alignItems: 'center', flexFlow: 'nowrap', gap: '20px' }}
          >
            <Col style={{ width: '100%' }}>
              <Form.Item
                name="vehicleNumber1"
                label="Nr. unitații de transport"
                labelCol={{ span: 24 }}
              >
                <Input disabled style={{ textTransform: 'uppercase' }} />
              </Form.Item>

              <Form.Item
                name="vehicleVinCode"
                label="Nr. Caroserie/ VIN cod"
                labelCol={{ span: 24 }}
              >
                <Input disabled />
              </Form.Item>
            </Col>

            <Col style={{ width: '100%' }}>
              <Col>
                <Form.Item
                  name="driverPersonalNumber"
                  label="Șofer (Pașaport)"
                  labelCol={{ span: 24 }}
                >
                  <Input disabled style={{ textTransform: 'uppercase' }} />
                </Form.Item>
                <Col style={{ width: '100%' }}>
                  <Form.Item
                    name="companyIDNO"
                    label="Declarantul (IDNO sau Cod fiscal)"
                    labelCol={{ span: 24 }}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Col>
            </Col>
            <Col style={{ width: '100%' }}>
              <Col>
                <Form.Item
                  name="naiming1"
                  label="Nume șofer"
                  labelCol={{ span: 24 }}
                >
                  <Input disabled style={{ textTransform: 'uppercase' }} />
                </Form.Item>
                <Col style={{ width: '100%' }}>
                  <Form.Item
                    name="naiming2"
                    label="Denumire"
                    labelCol={{ span: 24 }}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Col>
            </Col>
            <Col>
              <Col>
                <Col>
                  <div
                    style={{
                      flexFlow: 'nowrap',
                      display: 'flex',
                      gap: '10px',
                    }}
                  >
                    <Form.Item name="resident1" valuePropName="checked">
                      <Checkbox disabled />
                    </Form.Item>
                    <label style={{ transform: 'translateY(5px)' }}>
                      Rezident
                    </label>
                  </div>
                </Col>
                <Col>
                  <div
                    style={{
                      flexFlow: 'nowrap',
                      display: 'flex',
                      gap: '10px',
                      transform: 'translateY(30px)',
                    }}
                  >
                    <Form.Item name="resident2" valuePropName="checked">
                      <Checkbox disabled />
                    </Form.Item>
                    <label style={{ transform: 'translateY(5px)' }}>
                      Rezident
                    </label>
                  </div>
                </Col>
              </Col>
            </Col>
          </Row>

          <Row
            justify="space-between"
            style={{ alignItems: 'center', flexFlow: 'nowrap', gap: '20px' }}
          >
            <Col style={{ width: '100%' }}>
              <Form.Item
                name="personsNumber"
                label="Numarul de persoane"
                labelCol={{ span: 24 }}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col style={{ width: '100%' }}>
              <Form.Item
                name="country"
                label="Înmatriculare"
                labelCol={{ span: 24 }}
              >
                <CountrySelect
                  id={form.getFieldValue('country')}
                  disabled
                  // onChange={handleChangeCountry}
                />
              </Form.Item>
            </Col>
            <Col style={{ width: '100%' }}>
              <Form.Item
                name="direction"
                label="Direcția"
                labelCol={{ span: 24 }}
              >
                <Select
                  // onSelect={onSelect}
                  disabled
                  allowClear
                >
                  {directions.content.map((row) => (
                    <Select.Option value={row.id} key={row.id}>
                      {`${row.name}`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row
            justify="space-between"
            style={{ alignItems: 'center', flexFlow: 'nowrap', gap: '20px' }}
          >
            <Col style={{ width: '100%' }}>
              <Form.Item
                name="vehicleType"
                label="Tip transport"
                labelCol={{ span: 24 }}
              >
                <Select
                  disabled
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) !== -1
                  }
                >
                  {vehicleCategories?.content.map(
                    (option) =>
                      !option.deleted && (
                        <Select.Option key={option.id} value={option.code}>
                          {`${option.code} - ${option.name}`}
                        </Select.Option>
                      ),
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col style={{ width: '100%' }}>
              <Form.Item
                name="transporterAddress"
                label="Adresa juridică a transportatorului"
                labelCol={{ span: 24 }}
              >
                <Input disabled={readOnly} maxLength={255} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col style={{ width: '100%' }}>
              <Form.Item
                name="goods"
                label="Denumirea și cantitatea mărfii"
                labelCol={{ span: 24 }}
              >
                <Input disabled={readOnly} maxLength={255} />
              </Form.Item>
            </Col>
          </Row>

          <Divider plain>
            <span> Informație retur </span>
          </Divider>
          <Row
            justify="space-between"
            style={{ alignItems: 'center', flexFlow: 'nowrap', gap: '20px' }}
          >
            <Col style={{ width: '100%' }}>
              <Form.Item
                name="svTourResponsible"
                label="Responsabil de tură SV"
                labelCol={{ span: 24 }}
              >
                <Input disabled={readOnly} maxLength={50} />
              </Form.Item>
            </Col>
            <Col style={{ width: '100%' }}>
              <Form.Item
                name="pfShiftChief"
                label="Coordonat: Șef schimb PF"
                labelCol={{ span: 24 }}
              >
                <Input disabled={readOnly} maxLength={50} />
              </Form.Item>
            </Col>
            <Col style={{ width: '100%' }}>
              <Form.Item
                name="controlBody"
                label="Denumirea organului de control"
                labelCol={{ span: 24 }}
              >
                <Input disabled={readOnly} maxLength={50} />
              </Form.Item>
            </Col>
          </Row>
          <Row
            justify="space-between"
            style={{ alignItems: 'center', flexFlow: 'nowrap', gap: '20px' }}
          >
            <Col style={{ width: '100%' }}>
              <Form.Item
                name="returnDeadline"
                label="Termenul de efectuare a returnarii"
                labelCol={{ span: 24 }}
              >
                <Input disabled={readOnly} maxLength={50} />
              </Form.Item>
            </Col>
            <Col style={{ width: '100%' }}>
              <Form.Item
                name="cooperatorJob"
                label="Funcția colaboratorului organului ce a dispus returnarea"
                labelCol={{ span: 24 }}
              >
                <Select
                  disabled={readOnly}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) !== -1
                  }
                >
                  {functions.content.map((option) => (
                    <Select.Option key={option.code} value={option.name}>
                      {`${option.name}`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col style={{ width: '100%' }}>
              <Form.Item
                name="cooperatorName"
                label="Numele colaboratorului organului ce a dispus returnarea"
                labelCol={{ span: 24 }}
              >
                <Input disabled={readOnly} maxLength={50} />
              </Form.Item>
            </Col>
          </Row>
          <Row
            justify="space-between"
            style={{ alignItems: 'center', flexFlow: 'nowrap', gap: '20px' }}
          >
            <Col style={{ width: '100%' }}>
              <Form.Item
                label="Motiv Retur"
                labelCol={{ span: 24 }}
                name="returnReason"
                rules={[
                  {
                    required: true,
                    message: t(
                      `md.customs.frontiera.servicecore.validation.constraints.RequiredIfFieldEquals.message`,
                    ),
                  },
                ]}
              >
                <Select
                  allowClear
                  disabled={readOnly}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) !== -1
                  }
                >
                  {returnReasons?.content.map(
                    (option) =>
                      !option.deleted && (
                        <Select.Option key={option.id} value={option.reason}>
                          {`${option.reason}`}
                        </Select.Option>
                      ),
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Col style={{ width: '100%' }}>
            <Form.Item
              name="returnReasonDetailed"
              label="Motiv retur detaliat"
              labelCol={{ span: 24 }}
              style={{ marginBottom: '25px' }}
            >
              <TextArea
                showCount
                maxLength={3000}
                disabled={readOnly}
                rows={3}
              />
            </Form.Item>
          </Col>
          <Col style={{ width: '100%' }}>
            <Form.Item
              name="returnPassengerGoods"
              label={
                <span>
                  <FormOutlined style={{ marginRight: '8px' }} />
                  Retur bunuri pasager
                </span>
              }
              labelCol={{ span: 24 }}
              style={{ marginBottom: '25px' }}
            >
              <TextArea
                showCount
                maxLength={3000}
                disabled={readOnly}
                rows={3}
              />
            </Form.Item>
          </Col>
        </Modal>
      </Form>
      {withholdingReasonsModal && (
        <WithholdingReasonModal
          visible={withholdingReasonsModal}
          setVisible={setWithholdingReasonsModal}
          transactionId={transactionId || returnData?.transactionId}
          setLoadingLoader={setLoadingLoader}
          showModal={showModal}
        />
      )}
    </>
  );
};

export default ReturModal;
