import React, { useMemo } from 'react';

import { Table } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

import useDatasource from '../../hooks/useDatasource';

import Column from '../../helpers/Columns';
import useDictionaries from '../../hooks/useDictionaries';
import { findAll as findAllOrganizations } from '../../services/admin/web-services/sfs-iresponsabili';
import { findAll as findAllLegalForms } from '../../services/taxonomies/legal-forms';
import { findAll as findAllActivities } from '../../services/taxonomies/activities';
import {
  LEGAL_STATE,
  ACTIVITY_TYPE,
} from '../../components/admin/system/constants';
import { useTableScroll } from '../../hooks/useTableScroll';

const dictionaries = {
  legalForms: findAllLegalForms,
  activities: findAllActivities,
};

const SFSIresponsabiliList = () => {
  const { loading, pagination, content, handleChange } = useDatasource(
    findAllOrganizations,
    { showNotification: true },
  );

  const [{ legalForms, activities }] = useDictionaries(dictionaries);
  useTableScroll();
  const columns = useMemo(
    () => [
      Column.text('idno', 'IDNO', {
        width: 300,
        filter: true,
      }),
      Column.text('name', 'Denumire', {
        width: 300,
        filter: true,
      }),
      Column.text('shortName', 'Denumire prescurtată', {
        width: 300,
      }),
      Column.date('registrationDate', 'Data inregistrare', {
        width: 250,
      }),
      Column.dictionary('legalFormCode', 'Forma organizare', legalForms, {
        width: 200,
        dictKey: 'code',
      }),
      {
        title: 'Stare',
        key: 'state',
        width: 100,
        dataIndex: 'state',
        render: (value) => {
          return <>{LEGAL_STATE[value]?.text}</>;
        },
      },
      Column.text('region', 'Raion', {
        width: 300,
        filter: true,
      }),
      Column.text('locality', 'Localitate', {
        width: 300,
        filter: true,
      }),
      Column.text('administrativeCode', 'Cod CUATM', {
        width: 50,
      }),
      Column.text('street', 'Strada', {
        width: 300,
      }),
      Column.text('house', 'Casa', {
        width: 20,
      }),
      Column.text('block', 'Bloc', {
        width: 20,
      }),
      Column.text('flat', 'Apartament', {
        width: 20,
      }),
      Column.text('phone', 'Telefon', {
        width: 20,
      }),
      Column.text('fax', 'Fax', {
        width: 20,
      }),
      Column.text('post', 'Cod poștal', {
        width: 20,
      }),
    ],
    [legalForms],
  );

  const columnsActivities = useMemo(
    () => [
      Column.text('orderNumber', 'Număr ordine', {
        width: 20,
      }),
      Column.dictionary('code', 'Gen de ativitate', activities, {
        width: 200,
        dictKey: 'code',
      }),
      {
        title: 'Tip gen de activitate',
        key: 'type',
        width: 100,
        dataIndex: 'type',
        render: (value) => {
          return <>{ACTIVITY_TYPE[value]?.text}</>;
        },
      },
    ],
    [activities],
  );

  return (
    <>
      <PageHeader title="Contribuabili iresponsabili" />
      <Table
        columns={columns}
        rowKey="idno"
        loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ x: 500 }}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <PageHeader title="Activități" />
              <Table
                columns={columnsActivities}
                dataSource={record.activities.sort(
                  (a, b) => a.orderNumber - b.orderNumber,
                )}
                pagination={false}
                rowKey="orderNumber"
                size="small"
              />
            </>
          ),
          rowExpandable: (record) =>
            record && record.activities && record.activities.length > 0,
        }}
      />
    </>
  );
};

export default SFSIresponsabiliList;
