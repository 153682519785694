import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import CustomsDocumentTypesList from '../../../../components/admin/taxonomies/customsDocumentTypes/CustomsDocumentTypesList';

const CustomsDocumentTypes = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="customsDocumentType">
      <Tabs.TabPane
        tab={t('entity.admin.customsDocumentType._plural')}
        key="customsDocumentType"
      >
        <CustomsDocumentTypesList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default CustomsDocumentTypes;
