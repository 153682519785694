import React from 'react';
import { Typography } from 'antd';

import logoImage from '../assets/logo.png';

const TitleLogo = () => (
  <div style={{ textAlign: 'center', padding: '2rem 0' }}>
    <img
      src={logoImage}
      alt="eAST"
      height="100"
      style={{
        marginRight: '.5rem',
        boxSizing: 'content-box',
      }}
    />
    <Typography.Title level={3} style={{ paddingTop: '1rem', margin: '0' }}>
      Serviciul Vamal al Republicii Moldova
    </Typography.Title>
    <Typography.Title level={3} style={{ paddingTop: '1rem', margin: '0' }}>
      SIA FRONTIERA
    </Typography.Title>
  </div>
);

export default TitleLogo;
