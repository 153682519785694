import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, InputNumber, Select, Table } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import Column from '../../../helpers/Columns';
import useDictionaries from '../../../hooks/useDictionaries';
import { findAll as findAllUnits } from '../../../services/taxonomies/measuring-units';

const dictionaries = {
  measuringUnits: findAllUnits,
};
const EditBagProductsForm = ({ disabled }) => {
  const [{ measuringUnits }] = useDictionaries(dictionaries);
  const { t } = useTranslation();
  const tableFooter = (add) => {
    return (
      <Button
        style={{ marginLeft: 'auto', display: 'block' }}
        type="primary"
        onClick={() => add({})}
        icon={<PlusOutlined />}
      >
        Adaugă
      </Button>
    );
  };
  const columns = useMemo(
    () => [
      Column.other(
        'position',
        t('entity.admin.declaration.preciousProperties.position'),
        (code, row) => {
          return <>{row.fieldKey + 1}</>;
        },
        {
          width: 50,
        },
      ),
      {
        key: 'name',
        title: t('entity.admin.declaration.bagProducts.name'),
        width: 50,
        render: (_value, _record, index) => (
          <Form.Item noStyle name={[index, 'name']}>
            <Input
              disabled={disabled}
              style={{
                textTransform: 'uppercase',
                fontWeight: '600',
              }}
            />
          </Form.Item>
        ),
      },
      {
        key: 'quantity',
        title: t('entity.admin.declaration.products.quantity'),
        width: 50,
        render: (_value, _record, index) => (
          <Form.Item noStyle name={[index, 'quantity']}>
            <InputNumber min={0.0} precision={2} disabled={disabled} />
          </Form.Item>
        ),
      },
      {
        key: 'unit',
        title: t('entity.admin.declaration.bagProducts.unit'),
        width: 50,
        render: (_value, _record, index) => (
          <Form.Item noStyle name={[index, 'unit']}>
            <Select
              allowClear
              showSearch
              style={{ width: '100%' }}
              disabled={disabled}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) !==
                -1
              }
            >
              {measuringUnits?.content.map((option) => (
                <Select.Option key={option.name} value={option.name}>
                  {`${option.name}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ),
      },
      {
        key: 'value',
        title: t('entity.admin.declaration.bagProducts.value'),
        width: 50,
        render: (_value, _record, index) => (
          <Form.Item noStyle name={[index, 'value']}>
            <InputNumber min={0.0} precision={2} disabled={disabled} />
          </Form.Item>
        ),
      },
      {
        key: 'valute',
        title: t('entity.admin.declaration.valute._'),
        width: 50,
        render: (_value, _record, index) => (
          <Form.Item
            style={{ transform: 'translateY(5px)' }}
            name={[index, 'valute']}
          >
            <Select disabled={disabled} allowClear>
              {['LEU', 'EURO'].map((row) => (
                <Select.Option value={row} key={row}>
                  {t(`entity.admin.declaration.valute.${row}`)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ),
      },

      Column.actions(
        t('table.actions'),
        (record, index) =>
          !disabled && (
            <Button
              disabled
              shape="circle"
              type="link"
              icon={<DeleteOutlined />}
              onClick={() => record.$remove(index)}
            />
          ),
      ),
    ],
    [t, disabled, measuringUnits],
  );

  return (
    <Form.List name="bagProducts">
      {(fields, { add, remove }) => (
        <>
          <Table
            size="small"
            rowKey="position"
            columns={columns}
            dataSource={fields}
            pagination={false}
            disabled={disabled}
            footer={() => !disabled && tableFooter(add)}
            onRow={(record) => {
              // eslint-disable-next-line no-param-reassign
              record.$remove = remove;
            }}
          />
        </>
      )}
    </Form.List>
  );
};

export default EditBagProductsForm;
