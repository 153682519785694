import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@ant-design/pro-layout';
import AddEntityButton from '../../../components/buttons/AddEntityButton';
import PaymentNoticeList from '../../../components/payment-notice/PaymentNoticeList';

const PaymentsNotices = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeader
        title={t('entity.payments._plural')}
        extra={[
          <AddEntityButton
            key="new"
            entity={t('entity.payments._singular')}
            path="/payments/payments-notice/new"
          />,
        ]}
      />
      <PaymentNoticeList />
    </>
  );
};

export default PaymentsNotices;
