import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@ant-design/pro-layout';
import AddEntityButton from '../../../../components/buttons/AddEntityButton';
import PhysicalPersonsRisksList from '../../../../components/lucrative/risks-actions/physical-persons/PhysicalPersonsRisksList';
import AuthContext, { hasPermission } from '../../../../components/auth';
import AddSMSPersonButton from '../../../../components/lucrative/risks-actions/AddSMSPersonButton';

const PhysicalPersonsRisks = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { permissions } = user;

  return (
    <>
      <PageHeader
        title={t('entity.lucrative.physical-persons._plural')}
        extra={[
          <AddEntityButton
            key="new"
            entity={t('entity.lucrative.physical-persons._singular')}
            path="/lucrative/risks-analisys/physical-persons/new"
          />,
          hasPermission(permissions, 'TAX_PERSONS_SMS_ADD') && (
            <AddSMSPersonButton />
          ),
        ]}
      />

      <PhysicalPersonsRisksList />
    </>
  );
};

export default PhysicalPersonsRisks;
