import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import FinalDestinationsList from '../../../../components/admin/taxonomies/finalDestinations/FinalDestinationsList';

const FinalDestinations = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="finalDestinations">
      <Tabs.TabPane
        tab={t('entity.admin.finalDestinations._plural')}
        key="finalDestionations"
      >
        <FinalDestinationsList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default FinalDestinations;
