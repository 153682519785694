import React, { useEffect, useState } from 'react';

import { useEditPage } from '../../../hooks/useEditPage';

import {
  findOne,
  create,
  update,
} from '../../../services/admin/users/customs-posts';
import EditCustomsPostForm from '../../../components/admin/customsPosts/EditCustomsPostForm';
import AppLoader from '../../../components/auth/AppLoader';

const initial = {};

const EditCustomsPost = () => {
  const [reloadPage, setReload] = useState(0);

  const doUpdate = () => {
    setReload((prev) => prev + 1);
  };

  const [post, handleSubmit, handleCancel, errors, loading] = useEditPage({
    initial,
    existent: findOne,
    pushBackOnError: false,
    goBackPath: '/admin/customs-posts/customs',
    onCreate: create,
    onUpdate: update,
    dependency: reloadPage,
  });

  useEffect(() => {
    if (post && !post.id) {
      post.securityCameras = [];
      post.creationMethods = [];
    }
  }, [post]);

  return (
    <>
      <div className={`loaderOverlay ${loading ? 'active' : ''}`}>
        <AppLoader />
      </div>
      {post !== undefined && (
        <EditCustomsPostForm
          post={post}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          errors={errors}
          reload={doUpdate}
        />
      )}
    </>
  );
};

export default EditCustomsPost;
