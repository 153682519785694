import React, { useMemo, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Table,
  Button,
  Tooltip,
  notification,
  DatePicker,
  Col,
  Row,
} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import AuthContext from '../../auth';
import { findAll } from '../../../services/admin/shift-report-parameters';
import { findAll as findAllCustomsOffices } from '../../../services/admin/users/customs-office';
import useDatasource from '../../../hooks/useDatasource';
import useDictionaries from '../../../hooks/useDictionaries';

import Column from '../../../helpers/Columns';
import { downloadFiles } from '../../../services/admin/files';
import { useTableScroll } from '../../../hooks/useTableScroll';

const directions = {
  content: [
    { id: 'IN', code: 'IN', name: 'INTRARE' },
    { id: 'OUT', code: 'OUT', name: 'IEȘIRE' },
    { id: 'INOUT', code: 'INOUT', name: 'INTRARE/ IEȘIRE' },
  ],
};

const dictionaries = {
  customsOffices: findAllCustomsOffices,
};

const ShiftReportsList = () => {
  const { t } = useTranslation();
  useTableScroll();
  const { user } = useContext(AuthContext);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);

  const [{ customsOffices }] = useDictionaries(dictionaries);

  function subtractHours(numOfHours, date = new Date()) {
    date.setHours(date.getHours() - numOfHours);
    return date;
  }

  const handler = useCallback(
    (...params) => {
      const hz = params[0];
      const initial = hz?.criterias;
      hz.criterias = {
        ...initial,
        postId:
          user?.customsPost?.id ||
          (user.officeId &&
            customsOffices?.content
              .find((el) => el?.id === user.officeId)
              ?.customsPosts?.map((el) => el?.id)) ||
          (user.postId && [user.PostId]),
        from:
          (minDate && new Date(minDate).toISOString()) ||
          subtractHours(72).toISOString(),
        to:
          (maxDate && new Date(maxDate).toISOString()) ||
          new Date().toISOString(),
      };
      return findAll(hz);
    },
    [
      minDate,
      maxDate,
      user.postId,
      user.PostId,
      user.officeId,
      user?.customsPost?.id,
      customsOffices,
    ],
  );

  const { loading, pagination, content, handleChange, reload } =
    useDatasource(handler);

  const downloadFile = useCallback(
    (entity) => {
      downloadFiles(entity.fileId).catch(() =>
        notification.error({ message: t('entity.admin.guides.error') }),
      );
    },
    [t],
  );

  const columns = useMemo(
    () => [
      Column.text('shiftNumber', t('entity.admin.shiftReports.shiftNumber'), {
        width: 30,
        sort: false,
        filter: true,
      }),
      Column.text('postCode', t('entity.admin.shiftReports.postCode'), {
        width: 30,
        sort: false,
        filter: true,
      }),
      Column.dictionary(
        'shiftDirection',
        t('entity.admin.shiftReports.shiftDirection'),
        directions,
        {
          sort: false,
          width: 90,
          filter: true,
        },
      ),
      Column.text(
        'inspectorName',
        t('entity.admin.shiftReports.inspectorName'),
        {
          width: 30,
          filter: true,
        },
      ),
      Column.date('shiftFrom', t('entity.admin.shiftReports.shiftFrom'), {
        width: 30,
        format: 'DD.MM.YYYY HH:mm',
      }),
      Column.date('shiftTo', t('entity.admin.shiftReports.shiftTo'), {
        width: 30,
        format: 'DD.MM.YYYY HH:mm',
      }),
      Column.actions(t('table.actions'), (record) => (
        <span style={{ textAlign: 'right' }}>
          <Tooltip title="Descarcă fișier">
            <Button
              shape="circle"
              type="link"
              icon={<DownloadOutlined />}
              disabled={!record.fileId}
              onClick={() => {
                downloadFile(record);
              }}
            />
          </Tooltip>
        </span>
      )),
    ],
    [t, downloadFile],
  );

  return (
    <>
      <PageHeader title={t('entity.admin.shiftReports._singular')} />
      <Row>
        <Col span={6}>
          <label>Data start tură</label>
          <DatePicker
            style={{ marginTop: '10px' }}
            format="DD.MM.YYYY HH:mm"
            showTime
            defaultValue={dayjs(subtractHours(72))}
            de
            onChange={(date) => {
              setMinDate(date);

              reload();
            }}
          />
        </Col>
        <Col span={6} style={{ marginLeft: '20px' }}>
          <label>Data sfârșit tură</label>
          <DatePicker
            style={{ marginTop: '10px' }}
            format="DD.MM.YYYY HH:mm"
            showTime
            defaultValue={dayjs(new Date())}
            onChange={(date) => {
              setMaxDate(date);
              reload();
            }}
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
      />
    </>
  );
};

export default ShiftReportsList;
