import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UploadOutlined } from '@ant-design/icons';

import { Table } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

import useDatasource from '../../hooks/useDatasource';

import { findAll, uploadFiles } from '../../services/admin/trezoraria-document';

import Column from '../../helpers/Columns';
import { useTableScroll } from '../../hooks/useTableScroll';
import FileUploader from '../../components/FileUploader';

const TrezorarieDocumentList = () => {
  const { t } = useTranslation();

  const { loading, pagination, content, handleChange, reload } =
    useDatasource(findAll);

  useTableScroll();
  const columns = useMemo(
    () => [
      Column.date(
        'extrasDate',
        t('entity.admin.trezorarieDocument.extrasDate'),
        {
          width: 300,
          format: 'DD.MM.YY',
          sort: false,
          filter: true,
        },
      ),
      Column.text('debit', t('entity.admin.trezorarieDocument.debit'), {
        width: 50,
        filter: true,
      }),
      Column.text('credit', t('entity.admin.trezorarieDocument.credit'), {
        width: 50,
        filter: true,
      }),
      Column.text('tipDoc', t('entity.admin.trezorarieDocument.tipDoc'), {
        width: 50,
        filter: true,
      }),
      Column.text('nrDoc', t('entity.admin.trezorarieDocument.nrDoc'), {
        width: 50,
        filter: true,
      }),
      Column.text('iban', t('entity.admin.trezorarieDocument.iban'), {
        width: 250,
        filter: true,
      }),
      Column.text('codFiscal', t('entity.admin.trezorarieDocument.codFiscal'), {
        width: 250,
        filter: true,
      }),
      Column.text('name', t('entity.admin.trezorarieDocument.name'), {
        width: 250,
        filter: true,
      }),
      Column.text('sumDt', t('entity.admin.trezorarieDocument.sumDt'), {
        width: 50,
      }),
      Column.text('sumCt', t('entity.admin.trezorarieDocument.sumCt'), {
        width: 50,
      }),
      Column.text('contTrez', t('entity.admin.trezorarieDocument.contTrez'), {
        width: 250,
      }),
      Column.text('destinat', t('entity.admin.trezorarieDocument.destinat'), {
        width: 250,
      }),
    ],
    [t],
  );

  return (
    <>
      <PageHeader
        title={t('entity.admin.trezorarieDocument._plural')}
        extra={[
          <FileUploader
            key="upload"
            multiple={false}
            accept=".xml"
            icon={<UploadOutlined />}
            uploadFilesFunc={uploadFiles}
            onUploadComplete={() => reload()}
            title="Importă extrase"
          />,
        ]}
      />
      <Table
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
      />
    </>
  );
};

export default TrezorarieDocumentList;
