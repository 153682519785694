import React from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Tooltip } from 'antd';
import { LockOutlined } from '@ant-design/icons';

const EditPasswordIcon = ({ handleClick, disabled = false }) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t('actions.resetPassword')}>
      <Button
        shape="circle"
        type="link"
        icon={<LockOutlined />}
        onClick={handleClick}
        disabled={disabled}
      />
    </Tooltip>
  );
};

export default EditPasswordIcon;
