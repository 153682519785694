import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Table,
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import useFormErrors from '../../../../hooks/useFormErrors';
import { useTableScroll } from '../../../../hooks/useTableScroll';
import {
  floatValidator,
  maxLengthValidator,
  numberValidator,
} from '../../../../services/utils/validator/Validator';
import SaveButton from '../../../buttons/SaveButton';
import CancelButton from '../../../buttons/BackButton';
import Column from '../../../../helpers/Columns';
import DeleteItemIcon from '../../../buttons/DeleteItemIcon';
import AddEntityButton from '../../../buttons/AddEntityButton';
import EditItemIcon from '../../../buttons/EditItemIcon';
import RequiredLabel from '../../../../services/utils/RequiredLabel';
import { findAll as findAllUnits } from '../../../../services/taxonomies/measuring-units';
import useDictionaries from '../../../../hooks/useDictionaries';

const dictionaries = {
  measuringUnits: findAllUnits,
};

const EditCustomsPaymentsForm = ({ entity, onSubmit, onCancel, errors }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [subTypeForm] = Form.useForm();
  const [entitySubTypes, setentitySubTypes] = useState([]);
  const [subTypeModal, setSubTypeModal] = useState(false);

  useFormErrors(form, errors);
  useTableScroll();

  const [{ measuringUnits }] = useDictionaries(dictionaries);

  useEffect(() => {
    form.setFieldsValue(entity);
    setentitySubTypes(entity.subTypes || []);
  }, [entity, form]);

  const handleAddSubType = useCallback(() => {
    const subType = subTypeForm.getFieldsValue();

    if (!subType.name) {
      notification.error({ message: 'Introduceți denumirea sub tipului' });
      return;
    }

    if (subType.id || subType.tempId) {
      setentitySubTypes((prev) => [
        ...prev.filter(
          (el) => el.id !== subType.id || el.tempId !== subType.tempId,
        ),
        subType,
      ]);
    } else {
      // temp ID before save to manage Edit/ Delete without saving
      subType.tempId = new Date().getTime();
      setentitySubTypes((prev) => [subType, ...prev]);
    }
    setSubTypeModal(false);
    subTypeForm.resetFields();
  }, [subTypeForm]);

  const renderActionRow = useCallback(
    (record) => {
      return (
        <>
          <DeleteItemIcon
            title={t(`entity.admin.customsPayments.deleteSubType`, record)}
            disabled={entity.deleted}
            key={`delete${record.id || record.tempId}`}
            isPromise={false}
            action={() => {
              setentitySubTypes((prev) => [
                ...prev.filter(
                  (el) => el.id !== record.id || el.tempId !== record.tempId,
                ),
              ]);
            }}
          />
          <EditItemIcon
            key={`edit${record.id || record.tempId}`}
            disabled={entity.deleted}
            action={() => {
              subTypeForm.setFieldsValue(record);
              setSubTypeModal(true);
            }}
          />
        </>
      );
    },
    [t, subTypeForm, entity.deleted],
  );

  const handleSubmit = useCallback(
    (values) => {
      values.subTypes = entitySubTypes;
      onSubmit(values);
    },
    [onSubmit, entitySubTypes],
  );

  const subTypesColumns = useMemo(
    () => [
      Column.text('id', t('entity.admin.customsPayments.id'), {
        width: 50,
      }),
      Column.text('name', t('entity.admin.customsPayments.name'), {
        width: 150,
      }),
      Column.text(
        'firstHourPayment',
        t('entity.admin.customsPayments.firstHourPayment'),
        {
          width: 150,
        },
      ),
      Column.text(
        'afterHalfHourPayment',
        t('entity.admin.customsPayments.afterHalfHourPayment'),
        {
          width: 150,
        },
      ),
      Column.actions(t('table.actions'), (record) => renderActionRow(record)),
    ],
    [t, renderActionRow],
  );

  return (
    <>
      <PageHeader title={t('entity.admin.customsPayments._singular')} />
      <Form
        form={form}
        layout="vertical"
        initialValues={entity}
        onFinish={handleSubmit}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={
                <RequiredLabel title={t('entity.admin.customsPayments.name')} />
              }
              name="name"
            >
              <Input
                disabled={entity.deleted}
                onChange={(e) => {
                  form.setFieldsValue({
                    name: maxLengthValidator(e.target.value, 150),
                  });
                }}
              />
            </Form.Item>
            <Row gutter={{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}>
              <Col span={8}>
                <Form.Item
                  label={t('entity.admin.customsPayments.orderNumber')}
                  name="orderNumber"
                >
                  <Input
                    disabled={entity.deleted}
                    onChange={(e) => {
                      form.setFieldsValue({
                        reactionsDetermined: numberValidator(
                          e.target.value,
                          10,
                        ),
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={t('entity.admin.customsPayments.unitPrice')}
                  name="unitPrice"
                >
                  <Input
                    disabled={entity.deleted}
                    onChange={(e) => {
                      form.setFieldsValue({
                        reactionsDetermined: numberValidator(
                          e.target.value,
                          10,
                        ),
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={t('entity.admin.customsPayments.unitDenomination')}
                  name="unitDenomination"
                >
                  <Select
                    allowClear
                    showSearch
                    disabled={entity.deleted}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) !== -1
                    }
                  >
                    {measuringUnits?.content.map((option) => (
                      <Select.Option key={option.id} value={option.name}>
                        {`${option.name}`}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Row justify="space-between">
              <PageHeader title={t('entity.admin.customsPayments.subTypes')} />
              <AddEntityButton
                onClick={() => setSubTypeModal(true)}
                disabled={entity.deleted}
              />
            </Row>
            <Table
              rowKey="id"
              columns={subTypesColumns}
              dataSource={entitySubTypes}
              scroll={{ y: 500 }}
              pagination={false}
            />
          </Col>
        </Row>
        <Form.Item>
          <Space>
            <SaveButton disabled={entity.deleted} />
            <CancelButton onCancel={onCancel} />
          </Space>
        </Form.Item>
      </Form>
      <Modal
        maskClosable={false}
        width={600}
        visible={subTypeModal}
        onCancel={() => setSubTypeModal(false)}
        onOk={handleAddSubType}
      >
        <PageHeader
          title={t('entity.admin.customsPayments.subType', {
            type: entity.name || '',
          })}
        />
        <Form form={subTypeForm}>
          <Row>
            <Col span={24}>
              <Form.Item
                label={
                  <RequiredLabel
                    title={t('entity.admin.customsPayments.name')}
                  />
                }
                name="name"
                labelCol={{ span: 24 }}
              >
                <Input
                  disabled={entity.deleted}
                  onChange={(e) => {
                    subTypeForm.setFieldsValue({
                      name: maxLengthValidator(e.target.value, 255),
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label={t('entity.admin.customsPayments.firstHourPayment')}
                name="firstHourPayment"
                labelCol={{ span: 24 }}
              >
                <Input
                  disabled={entity.deleted}
                  onChange={(e) => {
                    subTypeForm.setFieldsValue({
                      firstHourPayment: floatValidator(e.target.value, 8, 2),
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label={t('entity.admin.customsPayments.afterHalfHourPayment')}
                name="afterHalfHourPayment"
                labelCol={{ span: 24 }}
              >
                <Input
                  disabled={entity.deleted}
                  onChange={(e) => {
                    subTypeForm.setFieldsValue({
                      afterHalfHourPayment: floatValidator(
                        e.target.value,
                        8,
                        2,
                      ),
                    });
                  }}
                />
              </Form.Item>
              {/*  DO NOT DELETE id noStyle it represent entity id currently on edit */}
              <Form.Item name="id" noStyle />
              <Form.Item name="tempId" noStyle />
              <Form.Item name="deleted" noStyle />
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default EditCustomsPaymentsForm;
