import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Draggable from 'react-draggable';
import Sound from 'react-sound';
import { debounce } from 'lodash';
import AuthContext from '../auth';
import ChatHeader from './components/ChatHeader';
import ChatWindow from './components/ChatWindow';
import { findAll as findAllMessages } from './chat-service/chat-service';
import { findOne } from '../../services/admin/users/users';
import useDatasource from '../../hooks/useDatasource';
import ChatInfo from './components/ChatInfo';
import EntityTypes from './enums/EntityTypes';
import MicroUserList from './MicroUserList';
import MessageTypes from './enums/MessageTypes';
import MessageNotification from './helpers/messageNotification';
import messageSound from '../../assets/sounds/messageNotification.mp3';
import ChatMenu from './components/ChatMenu';
import ChatRightBackground from './components/ChatRightBackground';
import ChatTopBackground from './components/ChatTopBackground';

const DELETED_TEXT = 'Mesajul a fost șters';
const MESSAGES_PER_PAGE = 20;

const Chat = ({
  isConnected = true,
  chatReconnect = () => {},
  groups = [],
  reloadChats = () => {},
  users = [],
  onSend,
  receivedMessage,
  appendUser,
  setUnreadMessageCount,
  handleChat,
  visible,
  setLayoutRightPadding,
  setLayoutrightSideSmallPadding,
}) => {
  window.soundManager.setup({ debugMode: false });
  const { user } = useContext(AuthContext);
  const [messages, setMessages] = useState([]);
  const [showElement, setShowElement] = useState(true);
  const [userInfoSide, setUserInfoSide] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [rightSideSmallChat, setRightSideSmallChat] = useState(false);
  const [rightSideChatBackground, setRightSideChatBackground] = useState(false);
  const [messageNotificationSound, setMessageNotificationSound] =
    useState(false);
  const [topSideChatBackground, setTopSideChatBackground] = useState(false);
  const [topSideChat, setTopSideChat] = useState(false);
  const [rightSideChat, setRightSideChat] = useState(
    localStorage.getItem('rightchat')
      ? JSON.parse(localStorage.getItem('rightchat'))
      : false,
  );
  const [screenDimensions, setScreenDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const searchInputRef = useRef(null);
  const messageSizeLen = useRef(MESSAGES_PER_PAGE);

  useEffect(() => {
    const handleResize = () => {
      setScreenDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setSelectedEntity((prev) => {
      if (prev?.id) {
        const updatedEntity =
          users?.find((el) => el.id === prev.id) ||
          groups?.find((el) => el.id === prev.id) ||
          null;
        return updatedEntity;
      }
      return null;
    });
  }, [users, groups]);

  const handleUserInfoSide = () => {
    setUserInfoSide((prev) => !prev);
  };

  const handleMinify = () => {
    setTopSideChat(false);
    setTopSideChatBackground(false);
  };

  const isCurrentChatSelected = useMemo(() => {
    return selectedEntity?.id === receivedMessage?.chatId;
  }, [selectedEntity?.id, receivedMessage?.chatId]);

  const handler = useCallback(
    (...params) => {
      const hz = params[0];
      hz.criterias = {
        ...hz.criterias,
        chatId: selectedEntity?.id,
      };
      if (searchInputRef?.current !== null) {
        hz.criterias.messageBody = searchInputRef.current;
      }
      if (selectedEntity?.unreadMessages > messageSizeLen.current) {
        hz.pageSize = 5 + (selectedEntity?.unreadMessages || 0);
      } else {
        hz.pageSize =
          messageSizeLen.current + (selectedEntity?.unreadMessages || 0);
      }
      hz.sort = ['messageDate', 'desc'];
      return findAllMessages(hz);
    },
    [selectedEntity?.id, selectedEntity?.unreadMessages],
  );

  const { content, reload, loading } = useDatasource(handler, {
    allowFetcher: selectedEntity && selectedEntity.id,
  });

  const lazyReload = debounce(reload, 250);

  const appendUnreadMessageCount = useCallback(
    (chatId) => {
      if (!chatId) return;
      const chat =
        users?.find((el) => el.id === chatId) ||
        groups?.find((el) => el.id === chatId);
      if (chat) {
        const unreadMessagesCount = (chat.unreadMessages || 0) + 1;
        setUnreadMessageCount(chat, unreadMessagesCount);
      }
    },
    [users, groups, setUnreadMessageCount],
  );

  useEffect(() => {
    const fromUser =
      users?.find((el) => el.id === selectedEntity?.id) ||
      groups?.find((el) => el.id === selectedEntity?.id);
    setUnreadMessageCount(fromUser, 0);

    const messagesFormatted = content
      .map((el) => {
        return !el.isDeleted ? el : { ...el, messageBody: DELETED_TEXT };
      })
      .reverse();
    setMessages(messagesFormatted);
    // eslint-disable-next-line
  }, [content]);

  useEffect(() => {
    if (selectedEntity?.id) {
      reload();
    }
  }, [selectedEntity?.id, reload]);

  const onEntitySelect = useCallback((entity) => {
    setSelectedEntity(entity);
    searchInputRef.current = null;
    messageSizeLen.current = MESSAGES_PER_PAGE;
  }, []);

  const handleMenu = () => {
    setIsMenuVisible((prev) => !prev);
  };

  const handleOpenNotification = useCallback(
    (message, fromUser = null) => {
      const group =
        message.targetType === EntityTypes.GROUP
          ? groups?.find((el) => el.id === message.chatId)?.chatter
          : null;

      const userName = fromUser?.chatter?.name || 'UTILIZATOR';
      const userImageId = group ? group.imageId : fromUser?.chatter?.imageId;

      const messageSoundLocaleStorage = localStorage.getItem('sound');
      const messageNotificationLocaleStorage =
        localStorage.getItem('notification');

      if (messageSoundLocaleStorage === 'true') {
        setMessageNotificationSound(true);
      }
      if (messageNotificationLocaleStorage === 'true') {
        MessageNotification({
          key: message.id,
          message,
          userName,
          groupName: group ? group?.name || 'GRUPA' : null,
          userImageId,
          userId: user?.id,
          groupId: group?.id,
        });
      }
    },

    [groups, user?.id],
  );

  const handleCheckUsersList = useCallback(
    (message) => {
      const inChatList =
        users?.find((el) => el.id === message.chatId) ||
        groups?.find((el) => el.id === message.chatId);

      if (!inChatList) {
        if (message?.targetType === EntityTypes.USER) {
          findOne(message.sourceId)
            .then((res) => {
              appendUser(res);
              handleOpenNotification(message, res);
              appendUnreadMessageCount(message?.chatId);
            })
            .catch();
        } else {
          reloadChats();
        }
      } else {
        const fromUser = users?.find(
          (el) => el.chatter?.id === message.sourceId,
        );
        appendUnreadMessageCount(message?.chatId);
        handleOpenNotification(message, fromUser);
      }
    },
    [
      users,
      groups,
      appendUser,
      handleOpenNotification,
      appendUnreadMessageCount,
      reloadChats,
    ],
  );

  const handleReceive = useCallback(
    (message) => {
      if (isCurrentChatSelected) {
        setMessages((prev) => [...prev, message]);
      } else if (message.sourceId !== user?.id) {
        handleCheckUsersList(message);
      }
    },
    [handleCheckUsersList, isCurrentChatSelected, user?.id],
  );

  const editMessage = useCallback((message) => {
    setMessages((prev) => {
      let updatedList = prev.filter((el) => el.id !== message.id);
      updatedList = [...updatedList, message].sort(
        (m1, m2) => new Date(m1.messageDate) - new Date(m2.messageDate),
      );
      return updatedList;
    });
  }, []);

  useEffect(() => {
    if (receivedMessage && receivedMessage.sourceId) {
      if (receivedMessage.isDeleted) {
        editMessage({ ...receivedMessage, messageBody: DELETED_TEXT });
      } else if (receivedMessage.messageType === MessageTypes.EDIT) {
        editMessage(receivedMessage);
      } else {
        handleReceive(receivedMessage);
      }
      lazyReload();
    }
    // eslint-disable-next-line
  }, [receivedMessage?.id]);

  useEffect(() => {
    if (rightSideSmallChat && visible) {
      setLayoutrightSideSmallPadding(true);
    } else {
      setLayoutrightSideSmallPadding(false);
    }
  }, [visible, rightSideSmallChat, setLayoutrightSideSmallPadding]);

  const handleDrag = (e, ui) => {
    const { x, y } = ui;
    if (y < -50 && !rightSideChatBackground) {
      setTopSideChatBackground(true);
    } else {
      setTopSideChatBackground(false);
    }
    if (x > 20) {
      setRightSideChatBackground(true);
    } else {
      setRightSideChatBackground(false);
    }
  };

  const handleRightSideSmallChat = () => {
    setRightSideSmallChat((prev) => !prev);
  };

  useEffect(() => {
    if (visible && rightSideChat) {
      setLayoutRightPadding(true);
    } else {
      setLayoutRightPadding(false);
    }
    // eslint-disable-next-line
  }, [visible, rightSideChat]);

  const handleRightChatBtn = () => {
    setShowElement(false);
    setRightSideChat(true);
    localStorage.setItem('rightchat', true);
    setTimeout(() => {
      setShowElement(true);
    }, [10]);
  };

  const handleRightSideChat = () => {
    if (rightSideChatBackground) {
      setShowElement(false);
      setRightSideChat(true);
      localStorage.setItem('rightchat', true);
      localStorage.setItem('topchat', false);

      setTimeout(() => {
        setShowElement(true);
      }, [10]);
    } else {
      setRightSideChat(false);
      localStorage.setItem('rightchat', false);
    }
    if (topSideChatBackground) {
      setTopSideChat(true);
      localStorage.setItem('rightchat', false);
      localStorage.setItem('topchat', true);
    } else {
      setTopSideChat(false);
      localStorage.setItem('topchat', false);
    }
  };

  useEffect(() => {
    if (topSideChat) {
      setIsMenuVisible(true);
    }
  }, [topSideChat]);

  const handleTopReach = useCallback(() => {
    messageSizeLen.current += MESSAGES_PER_PAGE;
    lazyReload();
  }, [lazyReload]);

  const handleBottomReach = useCallback(() => {
    messageSizeLen.current = MESSAGES_PER_PAGE;
    lazyReload();
  }, [lazyReload]);

  return (
    <>
      {messageNotificationSound ? (
        <Sound
          url={messageSound}
          playStatus={Sound.status.PLAYING}
          volume={50}
          onFinishedPlaying={() => setMessageNotificationSound(false)}
          debug={false}
          debugMode={false}
        />
      ) : null}
      {showElement ? (
        <>
          <ChatRightBackground
            rightSideChatBackground={rightSideChatBackground}
            rightSideChat={rightSideChat}
          />
          <ChatTopBackground
            topSideChatBackground={topSideChatBackground}
            topSideChat={topSideChat}
          />
          <Draggable
            handle="ul"
            onDrag={handleDrag}
            onStop={handleRightSideChat}
            bounds={{
              top: -60,
              right: 30,
              bottom: screenDimensions.height - 250,
              left: -screenDimensions.width + 850,
            }}
          >
            <div
              className={`chat ${visible ? 'active' : ''} ${
                userInfoSide ? 'user-info' : ''
              } ${rightSideChat ? 'rightside' : ''} ${
                rightSideSmallChat ? 'small' : ''
              }  ${topSideChat ? 'chat-top' : ''}`}
            >
              <ChatHeader
                isConnected={isConnected}
                chatReconnect={chatReconnect}
                reloadChats={reloadChats}
                userInfoSide={userInfoSide}
                onClose={() => {
                  handleChat();
                  setSelectedEntity(null);
                }}
                isMenuVisible={isMenuVisible}
                handleMenu={handleMenu}
                handleRightChatBtn={handleRightChatBtn}
                rightSideChat={rightSideChat}
                handleRightSideSmallChat={handleRightSideSmallChat}
                rightSideSmallChat={rightSideSmallChat}
                handleMinify={handleMinify}
              />
              <div className="chat__content">
                {rightSideSmallChat && isConnected ? (
                  <>
                    <MicroUserList
                      onSelect={onEntitySelect}
                      selectedEntityId={selectedEntity?.id}
                      content={users}
                      rightSideSmallChat={rightSideSmallChat}
                    />

                    <MicroUserList
                      onSelect={onEntitySelect}
                      selectedEntityId={selectedEntity?.id}
                      content={groups}
                      rightSideSmallChat={rightSideSmallChat}
                    />
                  </>
                ) : null}
                {isConnected ? (
                  <>
                    <ChatWindow
                      loading={loading}
                      targetEntity={selectedEntity}
                      messages={messages}
                      isMenuVisible={isMenuVisible}
                      rightSideSmallChat={rightSideSmallChat}
                      onSend={(e) => {
                        onSend(e);
                        lazyReload();
                      }}
                      handleUserInfoSide={handleUserInfoSide}
                      onEntitySelect={onEntitySelect}
                      onTopReach={handleTopReach}
                      onBottomReach={handleBottomReach}
                      users={users}
                      groups={groups}
                    />

                    <ChatInfo
                      searchRef={searchInputRef}
                      selectedEntity={selectedEntity}
                      onEntitySelect={(e) => appendUser(e, onEntitySelect)}
                      userInfoSide={userInfoSide}
                      handleUserInfoSide={handleUserInfoSide}
                      reloadGroups={reloadChats}
                      visible={visible}
                      onSearch={reload}
                    />

                    <ChatMenu
                      handleMenu={handleMenu}
                      isMenuVisible={isMenuVisible}
                      users={users}
                      visible={visible}
                      appendUser={appendUser}
                      onEntitySelect={onEntitySelect}
                      groups={groups}
                      handleMenusVisible={setIsMenuVisible}
                      reloadChats={reloadChats}
                      rightSideChat={rightSideChat}
                      selectedEntityId={selectedEntity?.id}
                    />
                  </>
                ) : null}
              </div>
            </div>
          </Draggable>
        </>
      ) : null}
    </>
  );
};

export default Chat;
