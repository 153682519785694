import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import LegalFormsList from '../../../../components/admin/taxonomies/legalForms/LegalFormsList';

const LegalForms = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="legalForms">
      <Tabs.TabPane tab={t('entity.admin.legalForm._plural')} key="legalForms">
        <LegalFormsList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default LegalForms;
