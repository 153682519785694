import React from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import Column from '../../../helpers/Columns';
import ViewItemIcon from '../../buttons/ViewItemIcon';
import { hasPermission } from '../../auth';
import EditItemIcon from '../../buttons/EditItemIcon';
import DeleteItemIcon from '../../buttons/DeleteItemIcon';
import ActionItemIcon from '../../buttons/ActionItemIcon';
import { Directions } from '../../../enums/Directions';
import CopyItemButton from '../../buttons/CopyItemButton';

const STATUSES = {
  VALID: 'VALID',
  INVALID: 'INVALID',
};

const statuses = {
  content: [
    { id: STATUSES.VALID, name: STATUSES.VALID },
    { id: STATUSES.INVALID, name: STATUSES.INVALID },
  ],
};

export const riskColumns = (t) => [
  Column.other(
    'action',
    t('entity.lucrative.risk_generic.action'),
    (code, row) => {
      let action = row.action || ' ';
      if (action?.length > 50) {
        action = `${action.substring(0, 30)}...`;
      }
      return <Tooltip title={row.action}>{action}</Tooltip>;
    },
    {
      width: 150,
      filter: true,
    },
  ),
  Column.other(
    'reactions',
    t('entity.lucrative.risk_generic.reactions'),
    (code, row) => {
      return (
        <>{row.reactions ? `${row.reactions} (${row.reactionsFire})` : ''}</>
      );
    },
    {
      width: 100,
      sort: false,
    },
    123,
  ),
  Column.text('document', t('entity.lucrative.risk_generic.document'), {
    width: 150,
  }),
  Column.bool('isFrontiera', t('entity.lucrative.risk_generic.isFrontiera'), {
    width: 130,
    filter: true,
    inverted: false,
    labels: [t('table.filter.all'), 'DA', 'NU', t('entity._.deleted.unknown')],
  }),
  Column.bool('isSms', t('entity.lucrative.risk_generic.isSms'), {
    width: 90,
    filter: true,
    inverted: false,
    labels: [t('table.filter.all'), 'DA', 'NU', t('entity._.deleted.unknown')],
  }),
  Column.other(
    'direction',
    t('entity.lucrative.risk_generic.direction'),
    (code, row) => {
      return <>{Directions[row.direction] || row.direction}</>;
    },
    {
      width: 100,
    },
  ),
  Column.dictionary(
    'status',
    t('entity.lucrative.risk_generic.status'),
    statuses,
    {
      width: 100,
      filter: true,
    },
  ),
  Column.date('validFrom', t('entity.lucrative.risk_generic.validFrom'), {
    width: 120,
    format: 'DD-MM-YYYY HH:mm:ss',
  }),
  Column.date('validTo', t('entity.lucrative.risk_generic.validTo'), {
    width: 120,
    format: 'DD-MM-YYYY HH:mm:ss',
  }),
  Column.date('createdAt', t('entity.lucrative.risk_generic.createdAt'), {
    width: 120,
    format: 'DD-MM-YYYY HH:mm:ss',
  }),
  Column.text('createdBy', t('entity.lucrative.risk_generic.createdBy'), {
    width: 120,
  }),
  Column.date('updatedAt', t('entity.lucrative.risk_generic.updatedAt'), {
    width: 130,
    format: 'DD-MM-YYYY HH:mm:ss',
  }),
  Column.text('updatedBy', t('entity.lucrative.risk_generic.updatedBy'), {
    width: 130,
  }),
  Column.bool('deleted', t('entity._.deleted._'), {
    width: 100,
    filter: true,
    inverted: true,
    labels: [
      t('table.filter.all'),
      t('entity._.deleted.true'),
      t('entity._.deleted.false'),
      t('entity._.deleted.unknown'),
    ],
  }),
];

export const getActionColumn = ({
  path = '',
  trail = '',
  record = {},
  permissions = [],
  reload = () => {},
  remove = () => {},
  changeStatus = () => {},
  t = () => {},
}) => {
  if (record.deleted) {
    return (
      <span style={{ textAlign: 'right' }}>
        <ViewItemIcon path={path} />
      </span>
    );
  }
  if (hasPermission(permissions, 'RISK_ANALYSIS')) {
    const statusInverse = {
      status:
        record.status === STATUSES.VALID ? STATUSES.INVALID : STATUSES.VALID,
    };
    return (
      <span style={{ textAlign: 'right' }}>
        <CopyItemButton path={trail} />
        <EditItemIcon path={path} />
        <DeleteItemIcon
          title={t('entity.lucrative.risk_generic._delete', record)}
          message={t('entity.lucrative.risk_generic._deleted', record)}
          item={record}
          action={remove}
          reload={reload}
        />
        <ActionItemIcon
          tooltip={
            record?.status === STATUSES.VALID
              ? t('entity.lucrative.risk_generic.invalidate')
              : t('entity.lucrative.risk_generic.validate')
          }
          title={t('entity.lucrative.risk_generic._change', statusInverse)}
          message={
            record?.status === STATUSES.VALID
              ? t('entity.lucrative.risk_generic._invalidated')
              : t('entity.lucrative.risk_generic._validated')
          }
          icon={
            record?.status === STATUSES.VALID ? (
              <CloseCircleOutlined />
            ) : (
              <CheckCircleOutlined />
            )
          }
          item={record}
          action={changeStatus}
          reload={reload}
        />
      </span>
    );
  }
  return <></>;
};
