import { get } from '../../../helpers/api';

export const SERVICE_URI = '/backend';
export const BASE_URI = '/api/v1/payments';

export const getPaymentInCurrentSystem = (transactionId) =>
  get(`${SERVICE_URI}${BASE_URI}/by-transaction-id/${transactionId}`);

export const getPaymentInOldSystem = (transactionId) =>
  get(
    `${SERVICE_URI}${BASE_URI}/by-transaction-id/old-system/${transactionId}`,
  );
