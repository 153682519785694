import React, { useCallback, useContext, useEffect, useState } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import { notification, Spin } from 'antd';
import {
  deleteAvatar,
  findOne as findUser,
  setAvatar,
} from '../../../services/admin/users/users';
import AuthContext from '../../../components/auth';
import AvatarComponent from '../../../components/AvatarComponent';

const PersonalData = () => {
  const { user, setUser } = useContext(AuthContext);
  const [userInfo, setUserInfo] = useState(null);
  const [avatarLoading, setAvatarLoading] = useState(false);

  const clearAvatar = useCallback(() => {
    setUser((prev) => ({ ...prev, imageId: null }));
    setAvatarLoading(true);
    deleteAvatar()
      .then((res) => res.json())
      .then((res) => {
        setUserInfo(res);
      })
      .catch()
      .finally(() => setAvatarLoading(false));
  }, [setUser]);

  const uploadAvatar = useCallback(
    (image) => {
      setAvatarLoading(true);
      setAvatar(image)
        .then((res) => {
          setUserInfo(res);
          setUser((prev) => ({ ...prev, imageId: res.imageId }));
        })
        .catch()
        .finally(() => setAvatarLoading(false));
    },
    [setUser],
  );

  useEffect(() => {
    findUser(user.id)
      .then((res) => {
        setUserInfo(res);
      })
      .catch(() =>
        notification.error({
          message: 'Nu au putut fi extrase datele despre utilizator',
        }),
      );
  }, [user.id]);

  const renderPostData = (usr) => {
    return usr.customsPostList
      ?.sort((a, b) => a.newCode.localeCompare(b.newCode))
      ?.map((el) => (
        <span
          key={el.newCode}
          style={{
            marginBottom: '5px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          ({el.newCode}) {el.name}
        </span>
      ));
  };

  return (
    <>
      <PageHeader title="Date personale" />
      {userInfo ? (
        <div className="personal__data-info">
          <div className="personal__data-info--left">
            <div className="personal__data-info-image">
              <div
                className={`personal__data-info--loading ${
                  avatarLoading ? 'active' : ''
                }`}
              >
                <Spin size="large" />
              </div>

              <AvatarComponent
                imageId={userInfo?.imageId}
                base64={userInfo?.base64Image}
                size={200}
                onClear={clearAvatar}
                onUpload={uploadAvatar}
                removable
              />
            </div>
            <div className="personal__data-user--info">
              <div className="personal__data-name">{user.fullname}</div>
              <div style={{ fontWeight: '600' }}>Post Vamal: </div>
              <div>{renderPostData(userInfo)}</div>
            </div>
          </div>
        </div>
      ) : (
        <Spin />
      )}
    </>
  );
};

export default PersonalData;
