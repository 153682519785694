import React, { useEffect } from 'react';

import {
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Col,
  notification,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import CountryDropDown from '../../DropDownComponent';
import SaveButton from '../../buttons/SaveButton';
import CancelButton from '../../buttons/CancelButton';
import useFormErrors from '../../../hooks/useFormErrors';
import RequiredLabel from '../../../services/utils/RequiredLabel';
import {
  maxLengthValidator,
  numberValidator,
} from '../../../services/utils/validator/Validator';

const EditDaysCorrectionForm = ({ correction, onSubmit, onCancel, errors }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  useFormErrors(form, errors);

  useEffect(() => {
    const { dateCalculated } = correction;
    const { number, date, reason } = correction?.letter ?? {};
    form.setFieldsValue({
      ...correction,
      reason,
      letterNumber: number,
      date: date ? dayjs(date) : null,
      dateCalculated: dateCalculated ? dayjs(dateCalculated) : null,
    });
  }, [correction, form]);

  const handleSubmit = (data) => {
    if (!form.getFieldValue('days')) {
      notification.warning({ message: 'Zile calculate este obligatoriu' });
      return;
    }
    if (!form.getFieldValue('dateCalculated')) {
      notification.warning({ message: 'Calculat la este obligatoriu' });
      return;
    }
    if (!form.getFieldValue('regNumber')) {
      notification.warning({
        message: 'Numărul de înmatriculare este obligatoriu',
      });
      return;
    }
    if (!form.getFieldValue('countryId')) {
      notification.warning({ message: 'Țara este obligatorie' });
      return;
    }
    data.letter = {
      id: correction?.letter?.id,
      number: data.letterNumber,
      date: data.date,
      reason: data.reason,
    };
    onSubmit(data);
  };

  return (
    <>
      <Divider> Date Generale </Divider>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Row gutter={15}>
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={6}>
            <Form.Item
              name="days"
              label={<RequiredLabel title={t('entity.daysCorrection.days')} />}
            >
              <Input
                disabled={correction.deleted}
                onChange={(e) => {
                  form.setFieldsValue({
                    days: numberValidator(e.target.value, 19),
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={6}>
            <Form.Item
              name="dateCalculated"
              label={
                <RequiredLabel
                  title={t('entity.daysCorrection.dateCalculated')}
                />
              }
            >
              <DatePicker format="DD.MM.YYYY" disabled={correction.deleted} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={6}>
            <Form.Item
              name="regNumber"
              label={
                <RequiredLabel title={t('entity.daysCorrection.regNumber')} />
              }
            >
              <Input
                style={{ textTransform: 'uppercase' }}
                disabled={correction.deleted}
                onChange={(e) => {
                  form.setFieldsValue({
                    regNumber: maxLengthValidator(e.target.value, 10),
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={6}>
            <Form.Item
              name="countryId"
              label={
                <RequiredLabel title={t('entity.daysCorrection.country')} />
              }
            >
              <CountryDropDown
                loadContent="true"
                disabled={correction.deleted}
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider> Cauzele modificării/ Anulării </Divider>
        <div style={{ fontWeight: '600', marginBottom: '10px' }}>
          Scrisoare/ PV
        </div>
        <Row gutter={15}>
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
            <Form.Item
              name="letterNumber"
              label={t('entity.daysCorrection.letterNumber')}
            >
              <Input
                style={{ textTransform: 'uppercase' }}
                disabled={correction.deleted}
                onChange={(e) => {
                  form.setFieldsValue({
                    letterNumber: maxLengthValidator(e.target.value, 50),
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
            <Form.Item
              name="date"
              label={t('entity.daysCorrection.date')}
              style={{ width: '100%' }}
            >
              <DatePicker format="DD.MM.YYYY" disabled={correction.deleted} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="reason" label={t('entity.daysCorrection.reason')}>
          <TextArea
            showCount
            maxLength={2000}
            disabled={correction.deleted}
            rows={4}
          />
        </Form.Item>
        <Form.Item>
          <Space>
            <SaveButton disabled={correction.deleted} />
            <CancelButton onCancel={onCancel} />
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default EditDaysCorrectionForm;
