import React, { useState, useContext } from 'react';
import {
  Input,
  Button,
  Modal,
  Form,
  Popconfirm,
  notification,
  Row,
  Col,
  Checkbox,
  DatePicker,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import {
  FileAddOutlined,
  RollbackOutlined,
  SaveOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import AuthContext from '../../auth';
import CustomsPostSelect from '../../CustomsPostSelect';
import CustomsOfficesSelect from '../../CustomsOfficesSelect';
import {
  saveSuspicionFraudReport,
  generatePdfInNewTab,
} from '../../../services/transactions/transactions';
import AppLoader from '../../auth/AppLoader';
import {
  dotValidator,
  maxLengthValidator,
} from '../../../services/utils/validator/Validator';

const FraudReportModal = ({
  initialVisible = false,
  vehicleNumber,
  parameters,
  transactionId,
  readOnly,
  allowGenerate = false, // only on readOnly mode
  onClose = () => {},
  reload = () => {},
}) => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(initialVisible);
  const [loadingLoader, setLoadingLoader] = useState(false);

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };
  const handleRefreshPage = () => {
    window.location.reload();
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    onClose();
    handleRefreshPage();
  };

  const handleOkModal = () => {
    const data = {
      id: parameters?.id,
      transactionId,
      reportNumber: parameters?.reportNumber,
      postCode: user?.customsPost?.code,
      ...form.getFieldsValue(),
    };

    setLoadingLoader(true);
    saveSuspicionFraudReport(data)
      .then((res) => {
        if (res)
          notification.success({ message: 'Datele au fost salvate cu succes' });
        else
          notification.error({
            message: 'A apărut o eroare la salvarea datelor',
          });
      })
      .catch(() => {
        notification.error({
          message: 'A apărut o eroare la salvarea datelor',
        });
      })
      .finally(() => {
        reload();
        setLoadingLoader(false);
        setIsModalVisible(false);
      });
  };

  const handleGeneratePdf = () => {
    setLoadingLoader(true);

    const data = {
      id: parameters?.id,
      transactionId: transactionId || parameters?.transactionId,
      reportNumber: parameters?.reportNumber,
      postCode: user?.customsPost?.code,
      ...form.getFieldsValue(),
    };

    generatePdfInNewTab(
      `/suspicion-fraud-report-pdf/${
        transactionId || parameters?.transactionId
      }`,
      data,
    )
      .then(() => {
        notification.success({
          message: t('messages.report.generated.success'),
          duration: 3,
        });
      })
      .catch((err) => {
        if (err)
          notification.error({
            message: 'A apărut o eroare la generarea raportului',
          });
      })
      .finally(() => {
        reload();
        setLoadingLoader(false);
      });
  };

  return (
    <>
      <div className={`loaderOverlay ${loadingLoader ? 'active' : ''}`}>
        <AppLoader />
      </div>

      <Button
        onClick={handleOpenModal}
        type="primary"
        icon={<WarningOutlined />}
        style={{
          width: '100%',
          marginBottom: '.5rem',
          background: '#e74c3c',
          border: '1px solid #e74c3c',
        }}
        block
      >
        Raport suspiciuni fraudă (RSF)
      </Button>

      <Form
        form={form}
        layout="vertical"
        initialValues={{
          ...parameters,
          dispatchDate: dayjs(parameters?.dispatchDate),
          validationDate: dayjs(parameters?.validationDate),
        }}
      >
        <Modal
          maskClosable={false}
          centered
          key="rsfModal"
          width={1200}
          title="Detalii Raport Suspiciuni Fraudă"
          visible={isModalVisible}
          onCancel={() => {
            setIsModalVisible(false);
            onClose();
          }}
          cancelText="Înapoi"
          okText="Salvează"
          style={{ marginTop: '20px', marginBottom: '20px' }}
          footer={[
            <Popconfirm
              key="cancelPop"
              placement="top"
              title="Sunteți sigur(ă) că doriți să închideți raportul suspiciuni fraudă? Datele nu vor fi salvate!"
              onConfirm={handleCloseModal}
              okText="Ok"
              cancelText={t('actions.cancel')}
            >
              <Button
                key="back"
                style={{ float: 'left' }}
                icon={<RollbackOutlined />}
              >
                Înapoi
              </Button>
            </Popconfirm>,
            <Popconfirm
              key="generateReportPop"
              disabled={readOnly && !parameters?.id && !allowGenerate}
              placement="top"
              title="Sunteți sigur(ă) că doriți sa generați raportul suspiciuni fraudă?"
              onConfirm={handleGeneratePdf}
              okText="Ok"
              cancelText={t('actions.cancel')}
            >
              <Button
                key="generateRSF"
                disabled={readOnly && !parameters?.id && !allowGenerate}
                style={{ marginRight: '20px' }}
                icon={<FileAddOutlined />}
              >
                Generează RSF
              </Button>
            </Popconfirm>,
            transactionId ? (
              <Popconfirm
                key="saveReportPop"
                disabled={readOnly}
                placement="topRight"
                title="Sunteți sigur(ă) că doriți sa salvați raportul suspiciuni fraudă?"
                onConfirm={handleOkModal}
                okText="Ok"
                cancelText={t('actions.cancel')}
              >
                <Button
                  key="okModal"
                  disabled={readOnly}
                  type="primary"
                  icon={<SaveOutlined />}
                >
                  Salvează
                </Button>
              </Popconfirm>
            ) : null,
          ]}
        >
          <Row gutter={20}>
            <Col span={6}>
              <Form.Item
                label="Data expedierii"
                name="dispatchDate"
                labelCol={{ span: 24 }}
              >
                <DatePicker
                  disabled={readOnly}
                  showTime
                  format="DD.MM.YYYY HH:mm:ss"
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Data validării"
                name="validationDate"
                labelCol={{ span: 24 }}
              >
                <DatePicker
                  disabled={readOnly}
                  showTime
                  format="DD.MM.YYYY HH:mm:ss"
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="BV Expediere"
                name="officeDispatchId"
                labelCol={{ span: 24 }}
              >
                <CustomsOfficesSelect disabled={readOnly} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="BV Destinație"
                name="officeDestinationId"
                labelCol={{ span: 24 }}
              >
                <CustomsOfficesSelect disabled={readOnly} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={6}>
              <Form.Item
                label="PV Expediere"
                name="postDispatchId"
                labelCol={{ span: 24 }}
              >
                <CustomsPostSelect disabled={readOnly} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="PV Destinație"
                name="postDestinationId"
                labelCol={{ span: 24 }}
              >
                <CustomsPostSelect disabled={readOnly} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Responsabil PV Expediere"
                name="responsiblePostDispatch"
                labelCol={{ span: 24 }}
              >
                <Input
                  style={{ textTransform: 'uppercase' }}
                  disabled={readOnly}
                  maxLength={50}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Autor"
                name="author"
                initialValue={user?.fullname}
                labelCol={{ span: 24 }}
              >
                <Input style={{ textTransform: 'uppercase' }} disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item
                label="Nr. înmatriculare mijloc transport"
                name="vehicleNumber"
                initialValue={vehicleNumber}
                labelCol={{ span: 24 }}
              >
                <Input
                  onChange={(e) => {
                    form.setFieldsValue({
                      vehicleNumber: maxLengthValidator(e.target.value, 20),
                    });
                  }}
                  style={{ textTransform: 'uppercase' }}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Nr. container"
                name="containerNumber"
                labelCol={{ span: 24 }}
              >
                <Input
                  onChange={(e) => {
                    form.setFieldsValue({
                      containerNumber: maxLengthValidator(e.target.value, 20),
                    });
                  }}
                  style={{ textTransform: 'uppercase' }}
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Nr. document"
                name="documentNumber"
                labelCol={{ span: 24 }}
              >
                <Input
                  onChange={(e) => {
                    form.setFieldsValue({
                      documentNumber: maxLengthValidator(e.target.value, 20),
                    });
                  }}
                  style={{ textTransform: 'uppercase' }}
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={6}>
              <Form.Item
                label="Transportator"
                name="carrier"
                labelCol={{ span: 24 }}
              >
                <Input
                  style={{ textTransform: 'uppercase' }}
                  disabled={readOnly}
                  maxLength={100}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="IDNO Transportator"
                name="carrierIDNO"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    pattern: new RegExp('^[0-9]*$'),
                    message: 'Format greșit',
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    form.setFieldsValue({
                      carrierIDNO: maxLengthValidator(e.target.value, 13),
                    });
                  }}
                  style={{ textTransform: 'uppercase' }}
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Procedura"
                name="procedure"
                labelCol={{ span: 24 }}
              >
                <Input
                  onChange={(e) => {
                    form.setFieldsValue({
                      procedure: maxLengthValidator(e.target.value, 20),
                    });
                  }}
                  style={{ textTransform: 'uppercase' }}
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Figura RSF"
                name="rsfFigure"
                labelCol={{ span: 24 }}
              >
                <Input
                  style={{ textTransform: 'uppercase' }}
                  disabled={readOnly}
                  maxLength={50}
                />
              </Form.Item>
            </Col>
          </Row>{' '}
          <Row gutter={20}>
            <Col span={6}>
              <Form.Item
                label="IDNO"
                name="idno"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    pattern: new RegExp('^[0-9]*$'),
                    message: 'Format greșit',
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    form.setFieldsValue({
                      idno: maxLengthValidator(e.target.value, 13),
                    });
                  }}
                  style={{ textTransform: 'uppercase' }}
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Document de însoţire"
                name="accompanyingDocument"
                labelCol={{ span: 24 }}
              >
                <Input
                  style={{ textTransform: 'uppercase' }}
                  disabled={readOnly}
                  maxLength={50}
                />
              </Form.Item>
            </Col>{' '}
            <Col span={6}>
              <Form.Item
                label="Denumire marfă"
                name="goodsName"
                labelCol={{ span: 24 }}
              >
                <Input
                  style={{ textTransform: 'uppercase' }}
                  disabled={readOnly}
                  maxLength={100}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Cod marfă"
                name="goodsCode"
                labelCol={{ span: 24 }}
              >
                <Input
                  onChange={(e) => {
                    form.setFieldsValue({
                      goodsCode: maxLengthValidator(e.target.value, 10),
                    });
                  }}
                  style={{ textTransform: 'uppercase' }}
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={6}>
              <Form.Item
                label="Valută Facturată"
                name="currencyName"
                labelCol={{ span: 24 }}
              >
                <Input
                  style={{ textTransform: 'uppercase' }}
                  disabled={readOnly}
                  maxLength={30}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Valoare în valută facturată"
                name="currencyAmount"
                labelCol={{ span: 24 }}
              >
                <Input
                  onChange={(e) => {
                    form.setFieldsValue({
                      currencyAmount: maxLengthValidator(
                        dotValidator(e.target.value),
                        9,
                      ),
                    });
                  }}
                  style={{ textTransform: 'uppercase' }}
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Greutate marfă (conform acte însoțitoare)"
                name="goodsWeight"
                labelCol={{ span: 24 }}
              >
                <Input
                  onChange={(e) => {
                    form.setFieldsValue({
                      goodsWeight: maxLengthValidator(
                        dotValidator(e.target.value),
                        9,
                      ),
                    });
                  }}
                  style={{ textTransform: 'uppercase' }}
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Greutate totală (conform cântar)"
                name="totalWeighed"
                labelCol={{ span: 24 }}
              >
                <Input
                  onChange={(e) => {
                    form.setFieldsValue({
                      totalWeighed: maxLengthValidator(
                        dotValidator(e.target.value),
                        9,
                      ),
                    });
                  }}
                  style={{ textTransform: 'uppercase' }}
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item
                label="Masa proprie a mijlocului de transport"
                name="vehicleWeight"
                labelCol={{ span: 24 }}
                onChange={(e) => {
                  form.setFieldsValue({
                    vehicleWeight: maxLengthValidator(
                      dotValidator(e.target.value),
                      9,
                    ),
                  });
                }}
              >
                <Input
                  style={{ textTransform: 'uppercase' }}
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Diferența de greutate depistată"
                name="weightDifference"
                labelCol={{ span: 24 }}
              >
                <Input
                  onChange={(e) => {
                    form.setFieldsValue({
                      weightDifference: maxLengthValidator(
                        dotValidator(e.target.value),
                        9,
                      ),
                    });
                  }}
                  style={{ textTransform: 'uppercase' }}
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Tip suspiciune"
                name="fraudType"
                labelCol={{ span: 24 }}
              >
                <Input
                  onChange={(e) => {
                    form.setFieldsValue({
                      fraudType: maxLengthValidator(e.target.value, 20),
                    });
                  }}
                  style={{ textTransform: 'uppercase' }}
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={10}>
              <div style={{ textAlign: 'center' }}>
                Nr. Document/ data eliberării
              </div>
              <div style={{ marginBottom: '10px' }}>CMR:</div>
              <Row style={{ gap: '20px', flexWrap: 'nowrap' }}>
                <Form.Item name="cmrField1">
                  <Input
                    onChange={(e) => {
                      form.setFieldsValue({
                        cmrField1: maxLengthValidator(e.target.value, 20),
                      });
                    }}
                    style={{ textTransform: 'uppercase' }}
                    disabled={readOnly}
                  />
                </Form.Item>
                <Form.Item name="cmrField2">
                  <Input
                    onChange={(e) => {
                      form.setFieldsValue({
                        cmrField2: maxLengthValidator(e.target.value, 20),
                      });
                    }}
                    style={{ textTransform: 'uppercase' }}
                    disabled={readOnly}
                  />
                </Form.Item>
              </Row>
              <div style={{ marginBottom: '10px' }}>Invoice:</div>
              <Row style={{ gap: '20px', flexWrap: 'nowrap' }}>
                <Form.Item name="invoiceField1">
                  <Input
                    onChange={(e) => {
                      form.setFieldsValue({
                        invoiceField1: maxLengthValidator(e.target.value, 20),
                      });
                    }}
                    style={{ textTransform: 'uppercase' }}
                    disabled={readOnly}
                  />
                </Form.Item>
                <Form.Item name="invoiceField2">
                  <Input
                    onChange={(e) => {
                      form.setFieldsValue({
                        invoiceField2: maxLengthValidator(e.target.value, 20),
                      });
                    }}
                    style={{ textTransform: 'uppercase' }}
                    disabled={readOnly}
                  />
                </Form.Item>
              </Row>
              <div style={{ marginBottom: '10px' }}>DV țării de export:</div>
              <Row style={{ gap: '20px', flexWrap: 'nowrap' }}>
                <Form.Item name="countryDVField1">
                  <Input
                    onChange={(e) => {
                      form.setFieldsValue({
                        countryDVField1: maxLengthValidator(e.target.value, 20),
                      });
                    }}
                    style={{ textTransform: 'uppercase' }}
                    disabled={readOnly}
                  />
                </Form.Item>
                <Form.Item name="countryDVField2">
                  <Input
                    onChange={(e) => {
                      form.setFieldsValue({
                        countryDVField2: maxLengthValidator(e.target.value, 20),
                      });
                    }}
                    style={{ textTransform: 'uppercase' }}
                    disabled={readOnly}
                  />
                </Form.Item>
              </Row>
              <div style={{ marginBottom: '10px' }}>Altele:</div>
              <Row style={{ gap: '20px', flexWrap: 'nowrap' }}>
                <Form.Item name="othersField1">
                  <Input
                    onChange={(e) => {
                      form.setFieldsValue({
                        othersField1: maxLengthValidator(e.target.value, 20),
                      });
                    }}
                    style={{ textTransform: 'uppercase' }}
                    disabled={readOnly}
                  />
                </Form.Item>
                <Form.Item name="othersField2">
                  <Input
                    onChange={(e) => {
                      form.setFieldsValue({
                        othersField2: maxLengthValidator(e.target.value, 20),
                      });
                    }}
                    style={{ textTransform: 'uppercase' }}
                    disabled={readOnly}
                  />
                </Form.Item>
              </Row>
            </Col>
            <Col span={14}>
              <Form.Item
                label="Acțiuni de recomandare"
                name="recommendedActions"
                labelCol={{ span: 24 }}
              >
                <Input
                  style={{ textTransform: 'uppercase', height: '55px' }}
                  disabled={readOnly}
                  maxLength={50}
                />
              </Form.Item>
              <Form.Item
                label="Suplimentar la acțiuni de recomandare"
                name="addToRecommendedActions"
                labelCol={{ span: 24 }}
              >
                <Input
                  style={{ textTransform: 'uppercase', height: '55px' }}
                  disabled={readOnly}
                  maxLength={50}
                />
              </Form.Item>
              <Form.Item
                label="Descriere suspiciune"
                name="fraudDescription"
                labelCol={{ span: 24 }}
              >
                <Input
                  style={{ textTransform: 'uppercase', height: '55px' }}
                  disabled={readOnly}
                  maxLength={50}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                label="Responsabil PV Destinație"
                name="responsiblePostDestination"
                labelCol={{ span: 24 }}
              >
                <Input
                  style={{ textTransform: 'uppercase' }}
                  disabled={readOnly}
                  maxLength={50}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Executorul acțiunilor de verificare solicitate"
                name="actionExecutor"
                labelCol={{ span: 24 }}
              >
                <Input
                  style={{ textTransform: 'uppercase' }}
                  disabled={readOnly}
                  maxLength={50}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                label="Acțiuni efectuate"
                name="performedActions"
                labelCol={{ span: 24 }}
              >
                <TextArea
                  showCount
                  maxLength={3000}
                  rows={3}
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Descriere rezultate verificare"
                name="verificationResultsDescription"
                labelCol={{ span: 24 }}
              >
                <TextArea
                  showCount
                  maxLength={3000}
                  rows={3}
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={6}>
              <Form.Item
                label="Tip document finalizare tranzacție"
                name="finalTransactionDocumentType"
                labelCol={{ span: 24 }}
              >
                <Input
                  onChange={(e) => {
                    form.setFieldsValue({
                      finalTransactionDocumentType: maxLengthValidator(
                        e.target.value,
                        10,
                      ),
                    });
                  }}
                  style={{ textTransform: 'uppercase' }}
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Nr. Document finalizare tranzacție"
                name="finalTransactionDocumentNumber"
                labelCol={{ span: 24 }}
                onChange={(e) => {
                  form.setFieldsValue({
                    finalTransactionDocumentNumber: maxLengthValidator(
                      e.target.value,
                      10,
                    ),
                  });
                }}
              >
                <Input
                  style={{ textTransform: 'uppercase' }}
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Act de control fizic Nr."
                name="verificationReportNumber"
                labelCol={{ span: 24 }}
              >
                <Input
                  onChange={(e) => {
                    form.setFieldsValue({
                      verificationReportNumber: maxLengthValidator(
                        e.target.value,
                        10,
                      ),
                    });
                  }}
                  style={{ textTransform: 'uppercase' }}
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Rezultat RSF"
                name="rsfResult"
                labelCol={{ span: 24 }}
              >
                <Input
                  style={{ textTransform: 'uppercase' }}
                  disabled={readOnly}
                  maxLength={50}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="isConformable"
            valuePropName="checked"
            label="Conform"
          >
            <Checkbox style={{ paddingRight: '10px' }} disabled={readOnly} />
          </Form.Item>
        </Modal>
      </Form>
    </>
  );
};

export default FraudReportModal;
