import React from 'react';

const ChatTopBackground = ({ topSideChatBackground, topSideChat }) => {
  return (
    <div
      className={`chat__topside-background ${
        topSideChatBackground && !topSideChat ? 'active' : ''
      }`}
    />
  );
};

export default ChatTopBackground;
