import { genericService, get } from '../../helpers/api';

const SERVICE_URI = '/backend';
const BASE_URI = '/api/v1/import-transactions';

export const { findAll, findOne, create, update, remove } = genericService(
  SERVICE_URI,
  BASE_URI,
);

export const addFromFile = (fileId) =>
  get(`${SERVICE_URI}${BASE_URI}/add/${fileId}`);
