import React, { useCallback, useState } from 'react';
import { Col, Row, Form, Modal, Radio, Select } from 'antd';

const GenerateReportModal = ({
  onOk = () => {},
  visible = false,
  onCancel = () => {},
  vehicleTypes,
}) => {
  const [form] = Form.useForm();
  const [checkboxState, setCheckboxState] = useState('2');

  const handleOk = useCallback(() => {
    const params = form.getFieldsValue();

    switch (checkboxState) {
      case '0':
        params.haveCargo = [0];
        break;
      case '1':
        params.haveCargo = [1];
        break;
      case '2':
        params.haveCargo = [0, 1];
        break;
      default:
        params.ha = [0, 1];
    }

    onOk(params);
  }, [form, onOk, checkboxState]);

  return (
    <>
      <Form form={form}>
        <Modal
          maskClosable={false}
          width={700}
          title="Selectează parametrii raportului"
          open={visible}
          onOk={handleOk}
          onCancel={onCancel}
          cancelText="Înapoi"
          okText="Generează raport"
          centered
        >
          <Row gutter={15}>
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Tip vehicul"
                name="vehicleType"
                labelCol={{ span: 24 }}
              >
                <Select
                  mode="multiple"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) !== -1
                  }
                >
                  {vehicleTypes?.content?.map(
                    (option) =>
                      !option.deleted && (
                        <Select.Option key={option.id} value={option.id}>
                          {`${option.name}`}
                        </Select.Option>
                      ),
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8} xl={16}>
              {/* <Form.Item label="" name="haveCargo" initialValue={[0, 1]}> */}
              <Radio.Group
                style={{ transform: 'translateY(35px)' }}
                value={checkboxState}
                onChange={(e) => setCheckboxState(e.target.value)}
              >
                <Radio value="2" checked={checkboxState === '2'}>
                  Cu și fără marfă
                </Radio>
                <Radio value="1" checked={checkboxState === '1'}>
                  Cu marfă
                </Radio>
                <Radio value="0" checked={checkboxState === '0'}>
                  Fără marfă
                </Radio>
              </Radio.Group>
              {/* </Form.Item> */}
            </Col>
          </Row>
        </Modal>
      </Form>
    </>
  );
};

export default GenerateReportModal;
