import React from 'react';
import { Avatar, notification } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import AvatarComponent from '../../AvatarComponent';

export default function messageNotification({
  key = 'message',
  placement = 'right-bottom',
  message,
  groupName,
  userName,
  duration = 5,
  userImageId,
  handleSelectedMessageNotification,
  userId,
  groupId,
}) {
  const openNotification = () => {
    notification.destroy(key);
    notification.open({
      key,
      message: groupName,
      description: (
        <>
          <button
            className="chat__message-notification--button"
            type="button"
            onClick={() => handleSelectedMessageNotification(userId || groupId)}
          />
          <div className="chat__message-notification">
            <div className="chat__message-notification--user">
              <div className="chat__message-notification--avatar-container">
                <div className="chat__message-notification--status active" />
                {userImageId ? (
                  <AvatarComponent imageId={userImageId} size={50} />
                ) : (
                  <Avatar
                    className="chat__message-notification--avatar"
                    size="large"
                    icon={<UserOutlined />}
                  />
                )}
              </div>
              <div className="chat__message-notification--name">
                {userName || 'UTILIZATOR'}
              </div>
            </div>
            <div className="chat__message-notification--text">
              {message.messageBody}
            </div>
          </div>
        </>
      ),
      placement,
      duration,
    });
  };

  return openNotification();
}
