import React from 'react';
import { Select } from 'antd';

import useDictionaries from '../hooks/useDictionaries';
import { findAll } from '../services/admin/geo/countries';

const dictionaries = {
  countries: findAll,
};

const CountrySelect = ({ value, onChange, ...rest }) => {
  const [{ countries }] = useDictionaries(dictionaries);
  const { loading, content } = countries;

  return (
    <Select
      autoComplete="off"
      {...rest}
      style={{ width: '100%' }}
      loading={loading}
      value={value}
      onChange={onChange}
      allowClear
      showSearch
      filterOption={(input, option) => {
        return (
          option.children.toLowerCase().slice(0, 2) === input.toLowerCase()
        );
      }}
    >
      {content
        ?.sort((a, b) => a.alpha2.localeCompare(b.alpha2))
        .map(
          (row) =>
            !row?.deleted && (
              <Select.Option value={row.id} key={row.id}>
                {`${row.alpha2} - ${row.name}`}
              </Select.Option>
            ),
        )}
    </Select>
  );
};

export default CountrySelect;
