import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import CustomsTrackList from '../../../components/admin/customsTracks/CustomsTracksList';

const CustomsTracks = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="customsTracks">
      <Tabs.TabPane
        tab={t('entity.admin.customsTrack._plural')}
        key="customsTracks"
      >
        <CustomsTrackList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default CustomsTracks;
