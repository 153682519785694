import React, { useContext } from 'react';
import Icon from '../../Icon';
import MessageTypes from '../enums/MessageTypes';
import AuthContext from '../../auth';

const ChatMessageMore = ({
  onSelect,
  sourceId,
  isAttachment = false,
  messageBody,
}) => {
  const { user } = useContext(AuthContext);
  const handleDelete = () => {
    if (onSelect && typeof onSelect === 'function') {
      onSelect(MessageTypes.DELETE);
    }
  };

  const handleReply = () => {
    if (onSelect && typeof onSelect === 'function') {
      onSelect(MessageTypes.REPLY);
    }
  };

  const handleEdit = () => {
    if (onSelect && typeof onSelect === 'function') {
      onSelect(MessageTypes.EDIT);
    }
  };

  const handleSave = () => {
    if (onSelect && typeof onSelect === 'function') {
      onSelect(MessageTypes.DOWNLOAD);
    }
  };

  const handleForward = () => {
    if (onSelect && typeof onSelect === 'function') {
      onSelect(MessageTypes.FORWARD);
    }
  };

  const copyMessage = () => {
    const tempInput = document.createElement('textarea');
    tempInput.value = messageBody;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    if (onSelect && typeof onSelect === 'function') {
      onSelect(null);
    }
  };

  return (
    <div className="chat__message-more--list">
      <button
        className="chat__message-more--item"
        type="button"
        onClick={handleForward}
      >
        <div className="chat__message-more--title">Redirect</div>
        <div className="chat__message-more-reply">
          <Icon
            name="messageReply"
            className="chat__messages-more--list-icon"
          />
        </div>
      </button>

      {sourceId === user.id && !isAttachment ? (
        <button
          className="chat__message-more--item"
          type="button"
          onClick={handleEdit}
        >
          <div className="chat__message-more--title">Editează</div>
          <div className="chat__message-more-reply">
            <Icon
              name="messageEdit"
              className="chat__messages-more--list-icon"
            />
          </div>
        </button>
      ) : null}
      {!isAttachment ? (
        <button
          type="button"
          className="chat__message-more--item"
          onClick={copyMessage}
        >
          <div className="chat__message-more--title">Copie</div>
          <div className="chat__message-more-reply">
            <Icon
              name="messageCopy"
              className="chat__messages-more--list-icon"
            />
          </div>
        </button>
      ) : null}
      <button
        className="chat__message-more--item"
        type="button"
        onClick={handleReply}
      >
        <div className="chat__message-more--title">Răspunde</div>
        <div className="chat__message-more-reply">
          <Icon name="chatReply" className="chat__messages-more--list-icon" />
        </div>
      </button>

      {isAttachment ? (
        <button
          className="chat__message-more--item"
          type="button"
          onClick={handleSave}
        >
          <div className="chat__message-more--title">Salvează</div>
          <div className="chat__message-more-reply">
            <Icon
              name="messageDownload"
              className="chat__messages-more--list-icon"
            />
          </div>
        </button>
      ) : null}
      {sourceId === user.id ? (
        <button
          className="chat__message-more--item delete-btn"
          type="button"
          onClick={handleDelete}
        >
          <div className="chat__message-more--title">Șterge</div>
          <div className="chat__message-more-reply">
            <Icon name="trash" className="chat__messages-more--list-icon" />
          </div>
        </button>
      ) : null}
    </div>
  );
};

export default ChatMessageMore;
