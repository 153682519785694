import { genericService, post } from '../../../helpers/api';

export const SERVICE_URI = '/mconnect-anta';
export const BASE_URI = '/api/anta';
export const BASE_URI_SEARCH_TRANSPORT_AUTHORIZATION =
  '/search/transport-authorization';

export const { findAll } = genericService(
  SERVICE_URI,
  BASE_URI_SEARCH_TRANSPORT_AUTHORIZATION,
);

export const getTransportAuthorization = (transportAuthorization) =>
  post(
    `${SERVICE_URI}${BASE_URI}/transport-authorization`,
    transportAuthorization,
  );
