import { genericService, get, searchWithRawResponse } from '../../helpers/api';

export const SERVICE_URI = '/backend';
export const BASE_URI = '/api/v1/transactions/operations';

export const { findAll, findOne, create, update, remove } = genericService(
  SERVICE_URI,
  BASE_URI,
);

export const exportOperationsCSV = async (data, fileName = 'Document') => {
  const response = await searchWithRawResponse(
    `${SERVICE_URI}${BASE_URI}/export-search`,
    data,
    {
      headers: {
        Accept: 'application/octet-stream',
      },
    },
  );
  const responseBlob = await response.blob();
  const file = new File([responseBlob], `${fileName}.csv`, {
    type: 'text/csv',
  });
  const objectUrl = URL.createObjectURL(file);

  const link = document.createElement('a');
  link.href = objectUrl;
  link.setAttribute('download', file.name);
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(objectUrl);
  document.body.removeChild(link);
};

export const getById = (id) => get(`${SERVICE_URI}${BASE_URI}/by-id/${id}`);
export const getByOldSysOpe = (oldSysOpe) =>
  get(`${SERVICE_URI}${BASE_URI}/by-sys-ope/${oldSysOpe}`);

export const getByOldInstanceId = (instanceId) =>
  get(`${SERVICE_URI}${BASE_URI}/by-instance-id/${instanceId}`);
