import { genericService, postWithRawResponse, put } from '../../helpers/api';

export const SERVICE_URI = '/backend';
export const BASE_URI = '/api/v1/special-services';

export const {
  update: setDecision,
  findAll,
  findOne,
} = genericService(SERVICE_URI, BASE_URI);

export const changeStatus = (id, status) =>
  put(`${SERVICE_URI}${BASE_URI}/${status}/${id}`);

export const generatePdfInNewTab = async (path, params) => {
  const response = await postWithRawResponse(
    `${SERVICE_URI}${BASE_URI}${path}`,
    params,
    {
      headers: {
        Accept: 'application/octet-stream',
      },
    },
  );

  const responseBlob = await response.blob();
  const blob = new Blob([responseBlob], {
    type: 'application/pdf',
  });
  const objectUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = objectUrl;
  link.setAttribute('download', 'Document.pdf');
  document.body.appendChild(link);
  window.open(window.URL.createObjectURL(blob), 'Document.pdf');
  return responseBlob;
};
