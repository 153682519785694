import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@ant-design/pro-layout';
import TransactionRisksList from '../../../components/lucrative/transaction-risks/TransactionRisksList';
import RadioCheckBoxes from '../../../components/lucrative/transaction-risks/RadioCheckBoxes';
import { RiskSearchTypes } from '../../../components/lucrative/transaction-risks/RiskSearchTypes';

const TransactionRisks = () => {
  const { t } = useTranslation();

  const [searchType, setSearchType] = useState(
    RiskSearchTypes.PHYSICAL_PERSONS,
  );

  const handleChangeCheckBox = useCallback((type) => {
    setSearchType(type);
  }, []);

  return (
    <>
      <PageHeader
        title={t('entity.lucrative.transaction-risks._plural')}
        extra={[
          <RadioCheckBoxes
            key="checkBox"
            onChange={handleChangeCheckBox}
            initial={searchType}
          />,
        ]}
      />
      <TransactionRisksList searchType={searchType} />
    </>
  );
};

export default TransactionRisks;
