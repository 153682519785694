import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useEditPage } from '../../../hooks/useEditPage';

import {
  findOne,
  create,
  update,
} from '../../../services/admin/web-services/warehouse-transshipments';

import AppLoader from '../../../components/auth/AppLoader';
import { Deposit } from '../../../components/phisical-person-entry';

const initial = {};

const EditTransshipment = () => {
  const [updatePage, setUpdate] = useState(0);
  const history = useHistory();

  const doUpdate = (newId) => {
    setUpdate((val) => val + 1);
    if (newId) {
      history.push(`/warehouse/${newId}`);
    }
  };

  const [tr, , handleCancel, , loading] = useEditPage({
    initial,
    dependency: updatePage,
    existent: findOne,
    goBackPath: '/warehouse',
    onCreate: create,
    onUpdate: update,
  });

  return (
    <>
      <div className={`loaderOverlay ${loading ? 'active' : ''}`}>
        <AppLoader />
      </div>
      {tr !== undefined && (
        <Deposit
          transshipmentData={tr}
          reload={doUpdate}
          onCancel={handleCancel}
        />
      )}
    </>
  );
};

export default EditTransshipment;
