import React from 'react';
import { useEditPage } from '../../../../hooks/useEditPage';
import {
  create,
  findOne,
  update,
} from '../../../../services/taxonomies/risk-actions/risk-level';
import AppLoader from '../../../../components/auth/AppLoader';
import EditRiskLevelForm from '../../../../components/admin/taxonomies/riskLevels/EditRiskLevelForm';

const initial = {};
const EditRiskLevels = () => {
  const [risk, handleSubmit, handleCancel, errors, loading] = useEditPage({
    initial,
    existent: findOne,
    goBackPath: '/admin/taxonomies/risk-levels',
    onCreate: create,
    onUpdate: update,
  });

  return (
    <>
      <div className={`loaderOverlay ${loading ? 'active' : ''}`}>
        <AppLoader />
      </div>
      {risk !== undefined && (
        <EditRiskLevelForm
          risk={risk}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          errors={errors}
        />
      )}
    </>
  );
};

export default EditRiskLevels;
