import React from 'react';

import { useEditPage } from '../../../../hooks/useEditPage';

import {
  findOne,
  create,
  update,
} from '../../../../services/taxonomies/vignette-tariff-position';
import EditVignetteTariffPositionForm from '../../../../components/admin/taxonomies/vignetteTariffPosition/EditVignetteTariffPositionForm';
import AppLoader from '../../../../components/auth/AppLoader';

const initial = {};

const EditVignetteTariffPosition = () => {
  const [code, handleSubmit, handleCancel, errors, loading] = useEditPage({
    initial,
    existent: findOne,
    goBackPath: '/admin/taxonomies/vignette-tariff-position',
    onCreate: create,
    onUpdate: update,
  });

  return (
    <>
      <div className={`loaderOverlay ${loading ? 'active' : ''}`}>
        <AppLoader />
      </div>
      {code !== undefined && (
        <EditVignetteTariffPositionForm
          vignetteTariffPosition={code}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          errors={errors}
        />
      )}
    </>
  );
};

export default EditVignetteTariffPosition;
