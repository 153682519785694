import React from 'react';
import { useEditPage } from '../../../../hooks/useEditPage';
import AppLoader from '../../../../components/auth/AppLoader';
import EditRiskApplicantsForm from '../../../../components/admin/taxonomies/riskApplicants/EditRiskApplicantsForm';
import {
  findOne,
  create,
  update,
} from '../../../../services/taxonomies/risk-actions/risk-applicants';

const initial = {};
const EditRiskApplicants = () => {
  const [risk, handleSubmit, handleCancel, errors, loading] = useEditPage({
    initial,
    existent: findOne,
    goBackPath: '/admin/taxonomies/risk-applicants',
    onCreate: create,
    onUpdate: update,
  });

  return (
    <>
      <div className={`loaderOverlay ${loading ? 'active' : ''}`}>
        <AppLoader />
      </div>
      {risk !== undefined && (
        <EditRiskApplicantsForm
          risk={risk}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          errors={errors}
        />
      )}
    </>
  );
};

export default EditRiskApplicants;
