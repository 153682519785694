import React from 'react';

import { useEditPage } from '../../../hooks/useEditPage';

import {
  findOne,
  create,
  update,
} from '../../../services/admin/users/customs-tracks';
import EditCustomsTracksForm from '../../../components/admin/customsTracks/EditCustomsTrackForm';
import AppLoader from '../../../components/auth/AppLoader';

const initial = {};

const EditCustomsTrack = () => {
  const [track, handleSubmit, handleCancel, errors, loading] = useEditPage({
    initial,
    existent: findOne,
    goBackPath: '/admin/customs-posts',
    onCreate: create,
    onUpdate: update,
  });

  return (
    <>
      <div className={`loaderOverlay ${loading ? 'active' : ''}`}>
        <AppLoader />
      </div>
      {track && (
        <EditCustomsTracksForm
          track={track}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          errors={errors}
        />
      )}
    </>
  );
};

export default EditCustomsTrack;
