import React from 'react';
import { useEditPage } from '../../../../hooks/useEditPage';
import {
  create,
  findOne,
  update,
} from '../../../../services/taxonomies/customs-payments';
import AppLoader from '../../../../components/auth/AppLoader';
import EditCustomsPaymentsForm from '../../../../components/admin/taxonomies/customsPayments/EditCustomsPaymentsForm';

const initial = {};
const EditCustomsPayments = () => {
  const [entity, handleSubmit, handleCancel, errors, loading] = useEditPage({
    initial,
    existent: findOne,
    goBackPath: '/admin/taxonomies/customs-payments',
    onCreate: create,
    onUpdate: update,
  });

  return (
    <>
      <div className={`loaderOverlay ${loading ? 'active' : ''}`}>
        <AppLoader />
      </div>
      {entity !== undefined && (
        <EditCustomsPaymentsForm
          entity={entity}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          errors={errors}
        />
      )}
    </>
  );
};

export default EditCustomsPayments;
