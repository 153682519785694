import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Row, Col, Space } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import useDatasource from '../../../hooks/useDatasource';
import { getAll } from '../../../services/admin/users/permissions';
import Column from '../../../helpers/Columns';
import SaveButton from '../../buttons/SaveButton';
import CancelButton from '../../buttons/BackButton';
import TableFormItemWrapper from '../../TableFormItemWrapper';
import { useTableScroll } from '../../../hooks/useTableScroll';
import { maxLengthValidator } from '../../../services/utils/validator/Validator';

const EditRoleForm = ({ role, onSubmit, onCancel }) => {
  const { t } = useTranslation();
  useTableScroll();
  const [form] = Form.useForm();

  const columns = useMemo(
    () => [
      Column.text('name', t('entity.admin.permission.name'), {
        width: 150,
        sort: false,
        // filter: true,
      }),
      Column.text('description', t('entity.admin.permission.description'), {
        // filter: true,
        width: 150,
        sort: false,
      }),
    ],
    [t],
  );

  const { loading, content, handleChange } = useDatasource(getAll);
  return (
    <>
      <PageHeader title={t('entity.admin.role._singular')} />
      <Form
        form={form}
        layout="vertical"
        initialValues={role}
        onFinish={onSubmit}
      >
        <Form.Item>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
            <Col xs={24} sm={24} md={10} lg={8} xl={8}>
              <Form.Item label="Role name" name="name">
                <Input
                  onChange={(e) => {
                    form.setFieldsValue({
                      name: maxLengthValidator(e.target.value, 120),
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Role description"
                name="description"
                onChange={(e) => {
                  form.setFieldsValue({
                    description: maxLengthValidator(e.target.value, 1000),
                  });
                }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={14} lg={16} xl={16}>
              <Form.Item name="permissions" noStyle>
                <TableFormItemWrapper
                  columns={columns}
                  rowKey="name"
                  loading={loading}
                  pagination={false}
                  dataSource={content.sort((a, b) =>
                    a.name.localeCompare(b.name),
                  )}
                  onTableChange={handleChange}
                  scroll={{ y: 580 }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Space>
            <SaveButton />
            <CancelButton onCancel={onCancel} />
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default EditRoleForm;
