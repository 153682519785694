import React, { useEffect, useState, useCallback } from 'react';
import Draggable from 'react-draggable';
import { Tooltip } from 'antd';
import { CalculatorOutlined } from '@ant-design/icons';
import Icon from '../../../components/Icon';
import { useClickOutside } from '../../../hooks/useClickOutside';

const Calculator = ({ collapsed }) => {
  const [display, setDisplay] = useState('0');
  const [calculator, setCalculator] = useState(false);
  const [focus, setFocus] = useState(false);
  const handleFocus = useCallback(
    (element) => () => {
      setFocus(element);
    },
    [],
  );
  const handleCalculator = () => {
    setCalculator((prev) => !prev);
    setFocus(!calculator);
  };
  const handleStopFocus = () => {
    setFocus(false);
  };
  const outside = useClickOutside(handleStopFocus, focus);

  useEffect(() => {
    if (calculator) {
      document.getElementById('calculator-trigger').focus();
    }
  }, [calculator]);
  useEffect(() => {
    if (display?.length < 1) {
      setDisplay(0);
    }
    if (display === '*' || display === '/' || display === '=') {
      setDisplay((prev) => prev.slice(0, -1));
    }
  }, [display]);
  // eslint-disable-next-line
  const handleClick = (value) => {
    const symbols = ['+', '-', '*', '/', '.', ','];
    if (
      symbols.includes(value) &&
      symbols.includes(display[display.length - 1])
    ) {
      setDisplay((prev) => prev.slice(0, -1) + value);
      return;
    }
    if (value === '=') {
      document.getElementById('calculator-trigger').focus();
      try {
        if (symbols.includes(display[display.length - 1])) {
          // eslint-disable-next-line
          setDisplay(eval(display.slice(0, -1)).toString());
        } else {
          // eslint-disable-next-line
          setDisplay(eval(display).toString());
        }
        return;
      } catch (error) {
        setDisplay('Error');
      }
    } else if (value === 'C') {
      setDisplay('0');
    } else if (value === '.') {
      setDisplay(display + value);
    } else if (value === '<') {
      if (display?.length < 2) {
        setDisplay('0');
      } else {
        setDisplay(display?.slice(0, -1));
      }
    } else if (value === 'change') {
      if (display === '0') {
        setDisplay('0');
      } else if (display?.length && display[0] !== '-') {
        // eslint-disable-next-line
        setDisplay('-' + display);
      } else {
        setDisplay(display?.slice(1));
      }
    } else {
      setDisplay(display === '0' ? value : display + value);
    }
  };

  // eslint-disable-next-line
  useEffect(() => {
    if (focus) {
      const handleKeyPress = (event) => {
        // eslint-disable-next-line
        const key = event.key;
        if (key >= '0' && key <= '9') {
          handleClick(key);
        } else if (key === '+' || key === '-' || key === '*' || key === '/') {
          handleClick(key);
        } else if (key === 'Enter') {
          handleClick('=');
        } else if (key === 'Delete' || key === 'Escape') {
          handleClick('C');
        } else if (key === 'Backspace') {
          handleClick('<');
        } else if (event.key === '.' || event.key === ',') {
          handleClick('.');
        } else if (event.key === 'm') {
          handleClick('change');
        }
      };
      window.addEventListener('keydown', handleKeyPress);

      return () => {
        window.removeEventListener('keydown', handleKeyPress);
      };
    }
  }, [handleClick, focus]);

  const calculatorDigit = (digit, handle, btnClass) => {
    return (
      <button
        className={`calculator__digit ${btnClass}`}
        type="button"
        onClick={() => handleClick(`${handle}`)}
      >
        {digit}
      </button>
    );
  };

  return (
    <>
      <Tooltip title={`${collapsed ? 'Calculator' : ''}`} placement="right">
        <button
          className="calculator__btn"
          type="submit"
          onClick={handleCalculator}
        >
          <div
            className={`calculator__focused-indicator ${focus ? 'active' : ''}`}
          />
          <CalculatorOutlined />
          <div className="calculator__btn-text">Calculator</div>
        </button>
      </Tooltip>
      <Draggable handle="span" onMouseDown={handleFocus(true)}>
        <div
          className={`calculator ${calculator ? 'active' : ''} ${
            focus ? 'focus' : ''
          }`}
          ref={outside}
        >
          <button
            type="submit"
            className="calculator__close"
            onClick={handleCalculator}
          >
            <Icon
              name="close"
              className="calculator__close-icon"
              width="10"
              height="10"
            />
          </button>
          <span className="calculator__drag" />
          <div className="calculator__container">
            <div className="calculator__output">{display}</div>
            <div className="calculator__numpad">
              <div className="calculator__numpad-row">
                {calculatorDigit('C', 'C', 'clear__digit')}
                {calculatorDigit('÷', '/', 'blue-button')}
                {calculatorDigit('+/-', 'change', 'blue-button')}
                <button
                  className="calculator__digit blue-button"
                  type="button"
                  onClick={() => handleClick('<')}
                >
                  <Icon
                    name="delete"
                    className="calculator__delete-icon"
                    width="20"
                    height="20"
                  />
                </button>
              </div>

              <div className="calculator__numpad-row">
                {calculatorDigit('7', '7', '')}
                {calculatorDigit('8', '8', '')}
                {calculatorDigit('9', '9', '')}
                {calculatorDigit('x', '*', 'blue-button')}
              </div>
              <div className="calculator__numpad-row">
                {calculatorDigit('4', '4', '')}
                {calculatorDigit('5', '5', '')}
                {calculatorDigit('6', '6', '')}
                {calculatorDigit('-', '-', 'blue-button')}
              </div>
              <div className="calculator__numpad-row">
                {calculatorDigit('1', '1', '')}
                {calculatorDigit('2', '2', '')}
                {calculatorDigit('3', '3', '')}
                {calculatorDigit('+', '+', 'blue-button')}
              </div>
              <div className="calculator__numpad-row">
                {calculatorDigit(',', '.', '')}
                {calculatorDigit('0', '0', '')}
                <button
                  type="button"
                  onClick={() => handleClick('=')}
                  className="big-button"
                  id="calculator-trigger"
                >
                  =
                </button>
              </div>
            </div>
          </div>
        </div>
      </Draggable>
    </>
  );
};

export default Calculator;
