import React from 'react';
import { Image, Row } from 'antd';

const ImageSelect = ({ url, width = 300 }) => {
  return (
    <div>
      <Row justify="right">
        <Image src={url} width={width} style={{ borderRadius: '10px' }} />
      </Row>
    </div>
  );
};

export default ImageSelect;
