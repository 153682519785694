import React, { useMemo } from 'react';

import { Table } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

import useDatasource from '../../hooks/useDatasource';

import Column from '../../helpers/Columns';
import useDictionaries from '../../hooks/useDictionaries';
import { findAll as findAllPersons } from '../../services/admin/web-services/sfs-persoane';
import { findAll as findAllDocTypes } from '../../services/taxonomies/doc-types';
import { findAll as findAllRegistrationTypes } from '../../services/taxonomies/registration-types';
import { findAll as findAllDocStatuses } from '../../services/taxonomies/doc-statuses';
import { useTableScroll } from '../../hooks/useTableScroll';

const dictionaries = {
  docTypes: findAllDocTypes,
  docStatuses: findAllDocStatuses,
  registrationTypes: findAllRegistrationTypes,
};

const SFSPersoaneList = () => {
  const { loading, pagination, content, handleChange } = useDatasource(
    findAllPersons,
    {
      showNotification: true,
    },
  );
  useTableScroll();
  const [{ docTypes, docStatuses, registrationTypes }] =
    useDictionaries(dictionaries);

  const columns = useMemo(
    () => [
      Column.text('idnp', 'IDNP', {
        width: 300,
        filter: true,
      }),
      Column.text('lastName', 'Nume', {
        width: 300,
        filter: true,
      }),
      Column.text('firstName', 'Prenume', {
        width: 300,
        filter: true,
      }),
      Column.dictionary('docTypeCode', 'Tip document', docTypes, {
        width: 200,
        dictKey: 'code',
      }),
      Column.text('docSeries', 'Serie document', {
        width: 50,
      }),
      Column.text('docNumber', 'Număr document', {
        width: 200,
      }),
      Column.date('docIssueDate', 'Data emitere', {
        width: 250,
        format: 'DD-MM-YYYY HH:mm:ss',
      }),
      Column.date('docExpirationDate', 'Data expirare', {
        width: 250,
        format: 'DD-MM-YYYY HH:mm:ss',
      }),
      Column.text('docIssuedBy', 'Emis de', {
        width: 300,
      }),
      Column.dictionary('docStatusCode', 'Statut document', docStatuses, {
        width: 200,
        dictKey: 'code',
      }),
    ],
    [docTypes, docStatuses],
  );

  const columnsRegistrations = useMemo(
    () => [
      Column.dictionary('regTypeCode', 'Tip înregistrare', registrationTypes, {
        width: 200,
        dictKey: 'code',
      }),
      Column.date('registrationDate', 'Data înregistrarii', {
        width: 100,
      }),
      Column.date('expirationDate', 'Data expirării', {
        width: 100,
      }),
      Column.text('region', 'Raion', {
        width: 100,
      }),
      Column.text('locality', 'Localitate', {
        width: 100,
      }),
      Column.text('administrativeCode', 'Cod CUATM', {
        width: 50,
      }),
      Column.text('street', 'Strada', {
        width: 200,
      }),
      Column.text('house', 'Casa', {
        width: 20,
      }),
      Column.text('block', 'Bloc', {
        width: 20,
      }),
      Column.text('flat', 'Apartament', {
        width: 20,
      }),
      Column.text('phone', 'Telefon', {
        width: 20,
      }),
      Column.text('fax', 'Fax', {
        width: 20,
      }),
      Column.text('post', 'Cod poștal', {
        width: 20,
      }),
    ],
    [registrationTypes],
  );

  return (
    <>
      <PageHeader title="Persoane" />
      <Table
        columns={columns}
        rowKey="idnp"
        loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ x: 500 }}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <PageHeader title="Domiciliu" />
              <Table
                columns={columnsRegistrations}
                dataSource={record.registrations}
                pagination={false}
                rowKey="id"
                size="small"
              />
            </>
          ),
          rowExpandable: (record) =>
            record && record.registrations && record.registrations.length > 0,
        }}
      />
    </>
  );
};

export default SFSPersoaneList;
