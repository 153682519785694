import React, { useContext, useEffect, useState } from 'react';
import { Checkbox, Spin } from 'antd';
import AuthContext from '../../auth';
import {
  getPersonsLikeName,
  getUsersByPostId,
  getUsersByPostIds,
} from '../../../services/admin/users/users';
import UsersList from '../UsersList';
import SelectHelper from '../../../services/utils/helper/SelectHelper';
import CustomsPostSelect from '../../CustomsPostSelect';
import UserFunctions from '../../../enums/UserFunctions';
import { findOne as findOfficeById } from '../../../services/admin/users/customs-office';

const ChatPopupUsersEdit = ({
  title = 'Adaugă membrii',
  onSelect,
  onClear,
  selectedUsersIds,
  initialValue = [],
  selectByPost = true,
}) => {
  const { user } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [selectedPostsIds, setSelectedPostsIds] = useState([]);
  const [centralPosts, setCentralPosts] = useState([]);

  const handlePostChange = (ids) => {
    setSelectedPostsIds(ids);
    if (ids?.length !== 0) {
      setLoadingUsers(true);
      getUsersByPostIds(ids)
        .then((res) => {
          if (res?.length !== 0) {
            onSelect(res?.map((el) => el.id));
          }
        })
        .catch()
        .finally(() => setLoadingUsers(false));
    } else {
      onClear();
    }
  };

  useEffect(() => {
    if (initialValue?.length === 0 && user?.postId) {
      getUsersByPostId(user?.postId)
        .then((res) => {
          const filtered = res?.filter((el) => !el.deleted);
          setUsers((prev) => {
            const uniqueSet = new Set(prev.map((el) => el.id));
            const filteredUnique = filtered.filter(
              (el) => !uniqueSet.has(el.id),
            );
            return [...prev, ...filteredUnique]
              .filter((el) => el.id !== user.id)
              .map((el) => ({
                ...el,
                chatter: {
                  name: el.fullname,
                  imageId: el.imageId,
                  id: el.id,
                },
              }));
          });
        })
        .catch();
    } else {
      setUsers(
        initialValue
          ?.filter((el) => el.id !== user.id)
          ?.map((el) => ({
            ...el,
            chatter: {
              name: el.fullname,
              imageId: el.imageId,
              id: el.id,
            },
          })),
      );
    }
    if (user.function === UserFunctions.CENTRAL && user.officeId) {
      findOfficeById(user?.officeId).then((res) => {
        setCentralPosts(res?.customsPosts);
      });
    }
    // eslint-disable-next-line
  }, [user?.postId, user?.id, initialValue?.length]);

  const handleSelectChat = (person) => {
    const userId = person.id;
    onSelect(userId);
  };

  const handleSelectFromSearch = (person) => {
    setUsers((prev) => [
      {
        ...person,
        chatter: {
          name: person.fullname,
          imageId: person.imageId,
          id: person.id,
        },
      },
      ...prev.filter((el) => el.id !== person.id),
    ]);
    handleSelectChat(person);
  };

  return (
    <div className="chat__group-users">
      {selectedPostsIds?.length === 0 ? (
        <>
          <div className="chat__group-members">
            <h3 className="chat__group-users--title">{title}</h3>
            <div className="chat__group-users--counter">
              {selectedUsersIds?.length} din {users?.length}
            </div>
          </div>
          <SelectHelper
            className="chat__search-users"
            placeholder="Caută pe cineva"
            onSearch={getPersonsLikeName}
            onSelect={handleSelectFromSearch}
            clearAfterSelect="true"
            fieldName="fullname"
          />
          <div className="chat__group-users--list">
            <UsersList
              onSelect={handleSelectChat}
              selectedEntityIds={selectedUsersIds}
              content={users}
            />
          </div>
        </>
      ) : (
        <h3 className="chat__group-users--title">
          Crează grup dupa post vamal
        </h3>
      )}
      {(user.function === UserFunctions.POST_HEAD ||
        user.function === UserFunctions.CENTRAL) &&
      selectByPost ? (
        <>
          <CustomsPostSelect
            value={selectedPostsIds}
            style={{ width: '100%', marginTop: '20px' }}
            mode="multiple"
            onChange={handlePostChange}
            officeId={user?.officeId}
            allowedPostIds={user?.posts?.map((el) => el.id)}
            placeholder="Crează grup per post"
          />
          {user.function === UserFunctions.CENTRAL ? (
            <Checkbox
              onClick={(e) => {
                if (e.target.checked) {
                  handlePostChange(centralPosts.map((el) => el.id));
                } else {
                  handlePostChange([]);
                }
              }}
            >
              Selectează toate
            </Checkbox>
          ) : null}
          <Spin hidden={!loadingUsers} />
        </>
      ) : null}
    </div>
  );
};

export default ChatPopupUsersEdit;
