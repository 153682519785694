import React, { useContext, useEffect, useState } from 'react';
import { Switch } from 'antd';
import { setAvatar } from '../../../services/admin/users/users';
import Icon from '../../Icon';
import AuthContext from '../../auth';
import AvatarComponent from '../../AvatarComponent';

const UserSettings = () => {
  const { user, setUser } = useContext(AuthContext);
  const [uploading, setUploading] = useState(false);

  const [sound, setSound] = useState(() => {
    const savedSound = localStorage.getItem('sound');
    return savedSound !== null ? savedSound === 'true' : true;
  });
  const [notification, setNotification] = useState(() => {
    const savedNotification = localStorage.getItem('notification');
    return savedNotification !== null ? savedNotification === 'true' : true;
  });

  useEffect(() => {
    localStorage.setItem('sound', sound);
    localStorage.setItem('notification', notification);
  }, [sound, notification]);

  const handleSound = () => {
    setSound((prev) => !prev);
  };
  const handleNotification = () => {
    setNotification((prev) => !prev);
  };

  const handleUserClick = () => {
    setUploading((prev) => !prev);
  };

  const handleSelectImage = (image) => {
    setAvatar(image)
      .then((res) => {
        setUser((prev) => ({ ...prev, imageId: res.imageId }));
      })
      .catch(() =>
        notification.error({
          message: 'A intervenit o eroare în timpul modificării imaginii.',
        }),
      );
  };

  return (
    <div className="chat__user-settings">
      <div className="chat__user-settings--footer">
        <button
          type="button"
          onClick={handleUserClick}
          className="chat__user-settings--btn"
        >
          <Icon name="chatSettings" style={{ width: '20px', height: '20px' }} />
          Setări
        </button>
      </div>
      <div
        className={`chat__user-settings--overlay ${uploading ? 'active' : ''}`}
      >
        <div className="chat__user-settings-popup">
          <button
            type="button"
            className="chat__close chat__settings-close"
            onClick={handleUserClick}
          >
            <Icon name="close" width="15" height="15" />
          </button>
          <AvatarComponent
            imageId={user?.imageId}
            base64={user?.base64Image}
            size={200}
            removable
            onUpload={handleSelectImage}
            // onClear={() => setUser((prev) => ({ ...prev, base64Image: null }))}
          />
          <h3 className="chat__user-settings--name">{user.fullname}</h3>
          <div className="chat__user-settings--menu">
            <div className="chat__user-settings--menu-item">
              <div
                className={`chat__user-settings--menu-name ${
                  sound ? 'active' : ''
                }`}
              >
                <Icon name="sound" />
                Sunet
              </div>
              <Switch checked={sound} onChange={handleSound} />
            </div>
            <div className="chat__user-settings--menu-item">
              <div
                className={`chat__user-settings--menu-name ${
                  notification ? 'active' : ''
                }`}
              >
                <Icon name="notification" />
                Notificații
              </div>
              <Switch checked={notification} onChange={handleNotification} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSettings;
