import React from 'react';

import SFSOrganizatiiList from './SFSOganizatiiList';

const SFSOrganizatii = () => {
  return (
    <>
      <SFSOrganizatiiList />
    </>
  );
};

export default SFSOrganizatii;
