import React, { useMemo, useContext, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@ant-design/pro-layout';
import { Table, notification, Tooltip, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import useDatasource from '../../../hooks/useDatasource';
import FileUploader from '../../FileUploader';
import AuthContext, { hasPermission } from '../../auth';

import { findAll, remove, create } from '../../../services/admin/guides/guides';

import Column from '../../../helpers/Columns';

import DeleteItemIcon from '../../buttons/DeleteItemIcon';
import { useTableScroll } from '../../../hooks/useTableScroll';
import AppLoader from '../../auth/AppLoader';
import { downloadFiles } from '../../../services/admin/files';

const GuidesList = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { permissions } = user;
  useTableScroll();
  const { loading, pagination, content, handleChange, reload } =
    useDatasource(findAll);

  const [loadingLoader, setLoadingLoader] = useState(false);

  const downloadFile = useCallback(
    (entity) => {
      setLoadingLoader(true);
      downloadFiles(entity.fileId)
        .catch(() =>
          notification.error({ message: t('entity.admin.guides.error') }),
        )
        .finally(() => setLoadingLoader(false));
    },
    [t],
  );

  const columns = useMemo(
    () => [
      Column.text('fileName', t('entity.admin.guides.name'), {
        width: 100,
        filter: true,
      }),
      Column.date('uploadedAt', t('entity.admin.guides.uploadedAt'), {
        width: 80,
        format: 'DD-MM-YYYY HH:mm:ss',
      }),
      Column.text('uploadedBy', t('entity.admin.guides.uploadedBy'), {
        width: 80,
      }),
      Column.actions(t('table.actions'), (record) => (
        <span style={{ textAlign: 'right' }}>
          {hasPermission(permissions, 'GUIDE_MANAGEMENT') && (
            <DeleteItemIcon
              title={t('entity.admin.guides._delete', record)}
              message={t('entity.admin.guides._deleted', record)}
              item={record}
              action={remove}
              reload={reload}
              setLoadingLoader={setLoadingLoader}
            />
          )}
          <Tooltip title="Descarcă fișier">
            <Button
              shape="circle"
              type="link"
              icon={<DownloadOutlined />}
              onClick={() => downloadFile(record)}
            />
          </Tooltip>
        </span>
      )),
    ],
    [t, reload, downloadFile, permissions],
  );

  const linkFileGuide = (fileData) => {
    const sendDTO = [];
    fileData.forEach((el) => {
      sendDTO.push({
        id: null,
        fileId: el.id,
        fileName: el.name,
      });
    });

    setLoadingLoader(true);
    create(sendDTO)
      .then((res) => {
        if (res) {
          notification.success({ message: t('entity.admin.guides.succes') });
        }
      })
      .catch((err) => {
        if (err) {
          notification.error({ message: t('entity.admin.guides.error') });
        }
      })
      .finally(() => {
        setLoadingLoader(false);
        reload();
      });
  };

  return (
    <>
      <div className={`loaderOverlay ${loadingLoader ? 'active' : ''}`}>
        <AppLoader />
      </div>

      <PageHeader
        title={t('entity.admin.guides._plural')}
        extra={[
          hasPermission(permissions, 'GUIDE_MANAGEMENT') && (
            <FileUploader
              key="uploadComponent"
              onUploadComplete={linkFileGuide}
            />
          ),
        ]}
      />
      <Table
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
      />
    </>
  );
};

export default GuidesList;
