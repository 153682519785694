import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { RightCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const NextButton = ({
  disabled,
  navigateTo,
  navigateFunc = () => {}, // if passed execute it instead of handleNavigate
  canNavigate,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const handleNavigate = () => {
    if (canNavigate) {
      history.push(navigateTo);
    }
  };

  return (
    <Button
      disabled={disabled}
      type="primary"
      htmlType="submit"
      icon={<RightCircleOutlined />}
      onClick={() => {
        if (navigateFunc && typeof navigateFunc === 'function') {
          navigateFunc();
        } else {
          handleNavigate();
        }
      }}
    >
      {t('actions.next')}
    </Button>
  );
};

export default NextButton;
