import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Col, Form, Input, Modal, notification, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { searchBy as searchTransactionBy } from '../../../services/admin/web-services/backend';
import { vinNumberValidator } from '../../../services/utils/validator/Validator';
import AuthContext from '../../auth';

const directions = {
  content: [
    { id: 'IN', code: 'IN', name: 'INTRARE' },
    { id: 'OUT', code: 'OUT', name: 'IEȘIRE' },
  ],
};
const PreSearchModal = ({
  transactionId,
  transformTransaction,
  setLoadingLoader,
  setHasGoods,
  setHasDrivingLicence,
  onVehicleTypeChange,
  handleChangeTypeTrailer,
  form,
}) => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const [preSearchModal, setPreSearchModal] = useState(false);

  const prepareDataToSearch = useCallback(() => {
    const searchFieldVIN = form?.getFieldValue('preSearchVehicleVIN');
    const searchFieldIDNP = form?.getFieldValue('preSearchIDNP');
    const searchFieldNumber = form?.getFieldValue('preSearchVehicleNumber');
    const direction = form?.getFieldValue('direction');

    if (!searchFieldVIN && !searchFieldIDNP && !searchFieldNumber) {
      notification.error({ message: 'Introduceți datele' });
      return false;
    }

    return {
      vinCode: searchFieldVIN,
      idnp: searchFieldIDNP,
      plateNumber: searchFieldNumber,
      direction,
    };
  }, [form]);

  const handlePreSearch = useCallback(() => {
    const data = prepareDataToSearch();
    if (!data) {
      return;
    }

    setLoadingLoader(true);
    searchTransactionBy(data)
      .then((res) => {
        if (!res.id) {
          notification.info({ message: 'Nu a fost gasită nicio rută' });
        } else {
          const transformed = transformTransaction(res);
          form.setFieldsValue({
            ...transformed,
            directions: data.direction,
            customsRegimeId: null,
            typeCompany: null,
            idno: null,
            nameCompany: null,
            companyCountry: null,
            loadCountryId: null,
            unloadCountryId: null,
            agentIdno: null,
            agentName: null,
            agentCountry: null,
            agentType: null,
          });
          setHasGoods(transformed.hasGoods);
          setPreSearchModal(false);
          setHasDrivingLicence(transformed.drivingLicence);
          onVehicleTypeChange(transformed.vehicleType);
          handleChangeTypeTrailer(transformed.trailerTypeId1);
        }
      })
      .catch(() => {
        notification.error({ message: t('errors.error') });
      })
      .finally(() => {
        setLoadingLoader(false);
      });
    // eslint-disable-next-line
  }, [
    handleChangeTypeTrailer,
    onVehicleTypeChange,
    transformTransaction,
    prepareDataToSearch,
    form,
    t,
  ]);

  useEffect(() => {
    if (!transactionId) {
      setPreSearchModal(true);
    }
  }, [transactionId]);

  return (
    <>
      <Modal
        maskClosable={false}
        centered
        title="Precompletează datele după o rută existentă"
        visible={preSearchModal}
        onOk={handlePreSearch}
        onCancel={() => setPreSearchModal(false)}
        cancelText="Continuă fără căutare"
      >
        <div
          style={{
            fontWeight: 600,
            fontSize: '16px',
            transform: 'translateY(-10px)',
          }}
        >
          Introdu datele rutei:
        </div>
        <Form form={form}>
          <Row justify="space-between">
            <Col xs={8} sm={8} md={8} lg={8} xl={10}>
              <Form.Item
                label="Direcție"
                name="direction"
                labelCol={{ span: 24 }}
              >
                <Select
                  defaultValue={user?.direction}
                  disabled={!!user?.direction}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) !== -1
                  }
                >
                  {directions.content.map((option) => (
                    <Select.Option key={option.code} value={option.code}>
                      {`${option.name}`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Form.Item
              label="Nr. Auto"
              name="preSearchVehicleNumber"
              labelCol={{ span: 24 }}
            >
              <Input
                maxLength={10}
                style={{
                  textTransform: 'uppercase',
                  fontWeight: '600',
                }}
              />
            </Form.Item>
          </Row>
          <Form.Item
            label="Vin Cod"
            name="preSearchVehicleVIN"
            labelCol={{ span: 24 }}
            rules={[
              {
                min: 17,
                message: 'Minim 17 caractere',
              },
            ]}
          >
            <Input
              onChange={(e) => {
                form.setFieldsValue({
                  preSearchVehicleVIN: vinNumberValidator(e.target.value),
                });
              }}
              maxLength={17}
              style={{
                textTransform: 'uppercase',
                fontWeight: '600',
              }}
            />
          </Form.Item>
          <Form.Item label="IDNP" name="preSearchIDNP" labelCol={{ span: 24 }}>
            <Input
              maxLength={13}
              style={{
                textTransform: 'uppercase',
                fontWeight: '600',
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default PreSearchModal;
