import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import ImpoundCarReasonsList from '../../../../components/admin/taxonomies/impoundCarReasons/ImpoundCarReasonsList';

const LegalForms = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="impoundCarReasons">
      <Tabs.TabPane
        tab={t('entity.admin.impoundCarReasons._plural')}
        key="impoundCarReasons"
      >
        <ImpoundCarReasonsList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default LegalForms;
