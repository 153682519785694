import React, { useMemo } from 'react';

import { Table } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

import useDatasource from '../../hooks/useDatasource';

import Column from '../../helpers/Columns';
import useDictionaries from '../../hooks/useDictionaries';
import { findAll as findAllOrganizationsDebts } from '../../services/admin/web-services/sfs-organizatii-debts';
import { findAll as findAllLegalForms } from '../../services/taxonomies/legal-forms';
import { useTableScroll } from '../../hooks/useTableScroll';

const dictionaries = {
  legalForms: findAllLegalForms,
};

const SFSOrganizatiiDebtsList = () => {
  const { loading, pagination, content, handleChange } = useDatasource(
    findAllOrganizationsDebts,
    { showNotification: true },
  );
  useTableScroll();
  const [{ legalForms }] = useDictionaries(dictionaries);

  const columns = useMemo(
    () => [
      Column.text('idno', 'IDNO', {
        width: 300,
        filter: true,
      }),
      Column.text('name', 'Denumire', {
        width: 300,
        filter: true,
      }),
      Column.date('registrationDate', 'Data inregistrare', {
        width: 250,
      }),
      Column.dictionary('legalFormCode', 'Forma organizare', legalForms, {
        width: 200,
        dictKey: 'code',
      }),
      Column.text('region', 'Raion', {
        width: 300,
        filter: true,
      }),
      Column.text('locality', 'Localitate', {
        width: 300,
        filter: true,
      }),
      Column.text('administrativeCode', 'Cod CUATM', {
        width: 50,
      }),
      Column.text('street', 'Strada', {
        width: 300,
      }),
      Column.text('house', 'Casa', {
        width: 20,
      }),
      Column.text('block', 'Bloc', {
        width: 20,
      }),
      Column.text('flat', 'Apartament', {
        width: 20,
      }),
      Column.text('phone', 'Telefon', {
        width: 20,
      }),
      Column.text('fax', 'Fax', {
        width: 20,
      }),
      Column.text('post', 'Cod poștal', {
        width: 20,
      }),
    ],
    [legalForms],
  );

  const columnsBranches = useMemo(
    () => [
      Column.text('idno', 'IDNO', {
        width: 300,
        filter: true,
      }),
      Column.text('name', 'Denumire', {
        width: 300,
        filter: true,
      }),
      Column.text('region', 'Raion', {
        width: 300,
        filter: true,
      }),
      Column.text('locality', 'Localitate', {
        width: 300,
        filter: true,
      }),
      Column.text('administrativeCode', 'Cod CUATM', {
        width: 50,
      }),
      Column.text('street', 'Strada', {
        width: 300,
      }),
      Column.text('house', 'Casa', {
        width: 20,
      }),
      Column.text('block', 'Bloc', {
        width: 20,
      }),
      Column.text('flat', 'Apartament', {
        width: 20,
      }),
      Column.text('phone', 'Telefon', {
        width: 20,
      }),
      Column.text('fax', 'Fax', {
        width: 20,
      }),
      Column.text('post', 'Cod poștal', {
        width: 20,
      }),
    ],
    [],
  );

  return (
    <>
      <PageHeader title="Unități de drept (web serviciu datornici)" />
      <Table
        columns={columns}
        rowKey="idno"
        loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ x: 500 }}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <PageHeader title="Filiale" />
              <Table
                columns={columnsBranches}
                dataSource={record.branches}
                pagination={false}
                rowKey="idno"
                size="small"
              />
            </>
          ),
          rowExpandable: (record) =>
            record && record.branches && record.branches.length > 0,
        }}
      />
    </>
  );
};

export default SFSOrganizatiiDebtsList;
