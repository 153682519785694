import { post, put, get } from '../../../helpers/api';

export const SERVICE_URI = '/backend';
export const BASE_URI = '/api/v1';
export const SAVE_SEND_API = '/save-and-send';

export const saveTransaction = (transactionDTO) =>
  post(`${SERVICE_URI}${BASE_URI}/transactions/`, transactionDTO);

export const copyTransaction = (additionalParameters) =>
  post(
    `${SERVICE_URI}${BASE_URI}/transactions/copy-transaction`,
    additionalParameters,
  );

export const getTransaction = (transactionId) =>
  get(`${SERVICE_URI}${BASE_URI}/transactions/${transactionId}`);

export const searchBy = (data) =>
  post(`${SERVICE_URI}${BASE_URI}/transactions/pre-search`, data);

export const correctTransaction = (corrections) =>
  post(
    `${SERVICE_URI}${BASE_URI}/transactions/correct-transaction`,
    corrections,
  );

export const showCopyTransaction = (transactionId) =>
  get(
    `${SERVICE_URI}${BASE_URI}//transactions/copy-transaction/${transactionId}`,
  );

export const getOldTransactionCorrections = (transactionId, vinCode) =>
  get(
    `${SERVICE_URI}${BASE_URI}/transactions/get-corrections/${transactionId}/${vinCode}`,
  );

export const saveAndSendTransaction = (transactionDTO) =>
  post(
    `${SERVICE_URI}${BASE_URI}/transactions${SAVE_SEND_API}`,
    transactionDTO,
  );

export const acceptTransaction = (transactionId) =>
  put(`${SERVICE_URI}${BASE_URI}/transactions/accept/${transactionId}`);

export const rejectTransaction = (returnTransactionDTO) =>
  put(
    `${SERVICE_URI}${BASE_URI}/transactions/reject/${returnTransactionDTO.transactionId}`,
    returnTransactionDTO,
  );

export const getConsemneBy = (personalCode, postId, direction) =>
  get(
    `${SERVICE_URI}${BASE_URI}/consemne/${personalCode}/${postId}/${direction}`,
  );

export const saveDeclarationNr = (transactionId, declarationNrs) =>
  put(
    `${SERVICE_URI}${BASE_URI}/transactions/save-declaration-nr/${transactionId}`,
    declarationNrs,
  );

export const getMessageDetails = (transactionId) =>
  get(`${SERVICE_URI}${BASE_URI}/message/${transactionId}`);
