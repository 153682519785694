import React, { useContext, useMemo } from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import useDatasource from '../../../../hooks/useDatasource';
import {
  changeStatus,
  findAll,
  remove,
} from '../../../../services/risks/risk-actions/physical-person-risks';
import Column from '../../../../helpers/Columns';
import { useTableScroll } from '../../../../hooks/useTableScroll';
import { getActionColumn, riskColumns } from '../GenericRiskColumns';
import AuthContext from '../../../auth';

const PhysicalPersonsRisksList = () => {
  useTableScroll();
  const { user } = useContext(AuthContext);
  const { permissions } = user || { permissions: [] };
  const { t } = useTranslation();
  const { loading, pagination, content, handleChange, reload } =
    useDatasource(findAll);

  const columns = useMemo(() => {
    const genericColumns = riskColumns(t);
    return [
      Column.text('id', t('entity.lucrative.physical-persons.id'), {
        width: 70,
        filter: true,
      }),
      Column.text('idnp', t('entity.lucrative.physical-persons.idnp'), {
        width: 150,
        filter: true,
      }),
      Column.text(
        'firstName',
        t('entity.lucrative.physical-persons.firstName'),
        {
          width: 150,
          filter: true,
        },
      ),
      Column.text('lastName', t('entity.lucrative.physical-persons.lastName'), {
        width: 150,
        filter: true,
      }),
      Column.text('rezident', t('entity.lucrative.physical-persons.rezident'), {
        width: 150,
      }),
      ...genericColumns,
      Column.actions(t('table.actions'), (record) =>
        getActionColumn({
          t,
          path: `/lucrative/risks-analisys/physical-persons/${record.id}`,
          trail: `/lucrative/risks-analisys/physical-persons/${record.id}/new`,
          record,
          reload,
          remove,
          permissions,
          changeStatus,
        }),
      ),
    ];
  }, [t, reload, permissions]);

  return (
    <Table
      scroll={{ x: 500 }}
      columns={columns}
      rowKey="id"
      loading={loading}
      pagination={pagination}
      dataSource={content}
      onChange={handleChange}
    />
  );
};

export default PhysicalPersonsRisksList;
