import React, { useState, useEffect, useCallback, useContext } from 'react';
import {
  Modal,
  Button,
  Spin,
  Input,
  Form,
  Row,
  Col,
  Checkbox,
  Divider,
} from 'antd';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/lib/input/TextArea';
import { getTransaction } from '../../../services/admin/web-services/backend';
import { findAll as findAllVehicleCategories } from '../../../services/taxonomies/vehicle-categories';
import { findAll as findAllCustomsRegimes } from '../../../services/taxonomies/customs-regime';
import { findAll as findAllVehicleTypes } from '../../../services/taxonomies/vehicle-types';
import { findAll as findAllTrailerTypes } from '../../../services/taxonomies/trailer-types';
import { findAll as findAllCountries } from '../../../services/admin/geo/countries';
import { findAll as findAllConstants } from '../../../services/taxonomies/constants';
import useDictionaries from '../../../hooks/useDictionaries';
import GoodsDocumentTable from '../../phisical-person-entry/generalData/goodsDocuments/GoodsDocumentsTable';
import ImpoundedCarCheckbox from '../checkboxes/ImpoundedCarCheckbox';
import UserFunctions from '../../../enums/UserFunctions';
import AuthContext from '../../auth';
import { ControlButtons } from '../../phisical-person-entry';
import {
  saveActControl,
  saveBonPlata,
  getMessageDetails,
} from '../../../services/transactions/transactions';

const directions = [
  { id: 'IN', name: 'INTRARE' },
  { id: 'OUT', name: 'IEȘIRE' },
];

const dictionaries = {
  vehicleCategories: findAllVehicleCategories,
  customsRegimes: findAllCustomsRegimes,
  vehicleTypes: findAllVehicleTypes,
  trailerTypes: findAllTrailerTypes,
  countries: findAllCountries,
  constants: findAllConstants,
};

const IdentifiedRisksViewModal = ({ visible, onClose, searchId }) => {
  const { user } = useContext(AuthContext);

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [messages, setMessage] = useState(null);
  const [isWeighingAmount] = useState(false);
  const [isAntaAmount] = useState(false);
  const [isAnsaAmount] = useState(false);
  const [
    {
      countries,
      vehicleCategories,
      vehicleTypes,
      trailerTypes,
      customsRegimes,
    },
  ] = useDictionaries(dictionaries);

  const documentParameters = (caption, modalTitle, parametersTypesMap) => {
    return {
      buttonCaption: caption,
      parametersModalTitle: modalTitle,
      parametersTypes: parametersTypesMap,
      customsRegimeId: data?.customsRegimeId,
    };
  };

  const verificationDocumentParametersMap = [
    {
      type: 'text',
      label: 'Numarul actului',
      name: 'document_number',
    },
    {
      type: 'text',
      label: 'Număr de inregistrare',
      name: 'declaration_number',
    },
    {
      type: 'text',
      label: 'A expediat lotul de marfuri',
      name: 'act_goods_owner',
    },
    {
      type: 'text',
      label: 'Adresa de expediere a marfurilor',
      name: 'act_owner_address',
    },
    {
      type: 'text',
      label: 'Au asistat la control',
      name: 'act_control_assisted',
    },
    {
      type: 'number',
      label: 'Numarul locurilor si coletelor verificate',
      name: 'act_nbr_places',
    },
    {
      type: 'text',
      label: 'Scopul controlului fizic vamal',
      name: 'act_customs_purpose',
    },
    {
      type: 'text',
      label: 'Rezultatul controlului',
      name: 'act_result',
    },
    {
      type: 'text',
      label: 'Reprezentantul agentului economic',
      name: 'economical_agent_representative',
    },
    {
      type: 'text',
      label: 'Reprezentantul transportatorului',
      name: 'transporter_representative',
    },
  ];

  const verificationDocumentParametersModalTitle = t(
    'entity.admin.reports.parametersForReport',
    {
      report: t('entity.admin.constant.documents.verification'),
    },
  );

  const paymentReceiptParametersModalTitle = t(
    'entity.admin.reports.parametersForReport',
    {
      report: t('entity.admin.constant.documents.paymentReceipt'),
    },
  );

  const fetchData = async (id) => {
    setLoading(true);
    try {
      const result = await getTransaction(id);
      setData(result);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchId) {
      fetchData(searchId);
    }
  }, [searchId]);

  useEffect(() => {
    getMessageDetails(searchId)
      .then((res) => {
        setMessage(res);
      })
      .catch()
      .finally();
  }, [searchId]);

  const isAccessRejected = useCallback(() => {
    return !user?.function || user?.function === UserFunctions.SCANN_OPERATOR;
  }, [user.function]);

  const getDirectionName = (directionId) => {
    const direction = directions.find((dir) => dir.id === directionId);
    return direction ? direction.name : '';
  };
  const getCountryName = (countryId) => {
    const country = countries.content?.find((c) => c.id === countryId);
    return country ? `${country.alpha2} - ${country.name}` : '';
  };
  const getVehicleCategoryName = (categoryCode) => {
    const category = vehicleCategories.content?.find(
      (cat) => cat.code === categoryCode,
    );
    return category ? `${category.code} - ${category.name}` : '';
  };
  const getVehicleTypeName = (typeId) => {
    const type = vehicleTypes.content?.find((vt) => vt.id === typeId);
    return type ? type.name : '';
  };
  const getTrailerTypeName = (typeId) => {
    const type = trailerTypes.content?.find((tt) => tt.id === typeId);
    return type ? type.name : '';
  };
  const getCustomsRegimeName = (regimeId) => {
    const regime = customsRegimes.content?.find((cr) => cr.id === regimeId);
    return regime ? regime.name : '';
  };
  const getResidentStatus = (isResident) => {
    return (
      isResident !== null &&
      typeof isResident === 'string' &&
      isResident.toUpperCase() === 'REZIDENT'
    );
  };
  const extractConsemne = (consemneArray) => {
    return consemneArray
      .map((consemn) => {
        const { validFromStr, riskLevelDescription, action } = consemn;
        return `(${validFromStr} - ${riskLevelDescription}) ${action}`;
      })
      .join('\n');
  };

  const renderContent = () => {
    if (loading) {
      return <Spin size="large" />;
    }
    if (data) {
      const scale = data.scale || {};
      const person =
        data.personList?.find(
          (pers) => pers.isCrossing && pers.isPhysicalPerson,
        ) || {};
      const driver =
        data.personList?.find(
          (pers) =>
            pers.drivingLicence ||
            pers.drivingLicenceCountryId ||
            pers.drivingLicenceDate,
        ) || {};
      const owner =
        data.personList?.find(
          (pers) => pers.isOwner && !pers.isPhysicalPerson,
        ) || {};
      const transporter =
        data.personList?.find(
          (pers) => pers.isCrossing && !pers.isPhysicalPerson,
        ) || {};
      const vehicle = data.vehicles?.find((veh) => !veh.isTrailer) || {};
      const trailer1 = data.vehicles?.find((veh) => veh.isTrailer) || {};
      const trailer2 =
        data.vehicles?.filter((veh) => veh.isTrailer && veh !== trailer1)[0] ||
        {};
      const consemne = data.consemne || {};
      const message = data.message || {};
      const createdBy = messages.createdBy || {};
      const createdAt = messages.createdAt || {};
      return (
        <Form layout="vertical" className="risk__preview">
          <div className="side-by-side-sections">
            <div className="content-section persoane">
              <Divider>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <span>Persoane</span>
                </div>
              </Divider>{' '}
              <Row gutter={16}>
                <Col sm={24} md={12} lg={8}>
                  <Form.Item label={t('generalData.person.direction')}>
                    <Input
                      value={getDirectionName(data.direction) || ''}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={8}>
                  <Form.Item label={t('generalData.person.type')}>
                    <Input
                      value={getCustomsRegimeName(data.customsRegimeId) || ''}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={4}>
                  <ImpoundedCarCheckbox transactionId={data.id} disabled />
                </Col>
                <Col sm={24} md={12} lg={2}>
                  <Form.Item
                    className="checkbox__label"
                    valuePropName="checked"
                    labelCol={{ span: 24 }}
                  >
                    <Checkbox checked={person.isDiplomat || false} disabled>
                      {t('generalData.person.isDiplomat')}
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={6}>
                  <Form.Item label={t('generalData.person.passportNumber')}>
                    <Input value={data.idnp || ''} disabled />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={8}>
                  <Form.Item label={t('generalData.person.nameDriver')}>
                    <Input value={data.driverFullName || ''} disabled />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={6}>
                  <Form.Item label={t('generalData.person.country')}>
                    <Input
                      value={getCountryName(person.countryId) || ''}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={4}>
                  <Form.Item
                    valuePropName="checked"
                    className="checkbox__label"
                  >
                    <Checkbox
                      checked={getResidentStatus(person.personType) || false}
                      disabled
                    >
                      {t('generalData.resident')}
                    </Checkbox>
                  </Form.Item>
                </Col>
                {driver.drivingLicenceDate && (
                  <Divider>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <span>Permis de conducere</span>
                    </div>
                  </Divider>
                )}
                {driver.drivingLicenceDate && (
                  <Col sm={24} md={12} lg={8}>
                    <Form.Item
                      label={t(
                        'generalData.person.drivingLicense.numberAndSeries',
                      )}
                    >
                      <Input value={driver.drivingLicence || ''} disabled />
                    </Form.Item>
                  </Col>
                )}
                {driver.drivingLicenceDate && (
                  <Col sm={24} md={12} lg={8}>
                    <Form.Item
                      label={t('generalData.person.drivingLicense.date')}
                    >
                      <Input value={driver.drivingLicenceDate || ''} disabled />
                    </Form.Item>
                  </Col>
                )}
                {driver.drivingLicenceDate && (
                  <Col sm={24} md={12} lg={8}>
                    <Form.Item
                      label={t('generalData.person.drivingLicense.country')}
                    >
                      <Input
                        value={
                          getCountryName(driver.drivingLicenceCountryId) || ''
                        }
                        disabled
                      />
                    </Form.Item>
                  </Col>
                )}
                {owner.personalCode && (
                  <Divider>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <span>Transportator</span>
                    </div>
                  </Divider>
                )}
                {owner.personalCode && (
                  <Col sm={24} md={12} lg={8}>
                    <Form.Item
                      label={t('generalData.person.owner.passportNumber')}
                    >
                      <Input value={owner.personalCode || ''} disabled />
                    </Form.Item>
                  </Col>
                )}
                {owner.fullName && (
                  <Col sm={24} md={12} lg={8}>
                    <Form.Item label={t('generalData.person.owner.name')}>
                      <Input value={owner.fullName || ''} disabled />
                    </Form.Item>
                  </Col>
                )}
                {owner.countryId && (
                  <Col sm={24} md={12} lg={6}>
                    <Form.Item label={t('generalData.person.owner.country')}>
                      <Input
                        value={getCountryName(owner.countryId) || ''}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                )}
                {owner.personType && (
                  <Col sm={24} md={12} lg={2}>
                    <Form.Item
                      className="checkbox__label"
                      valuePropName="checked"
                    >
                      <Checkbox
                        checked={getResidentStatus(owner.personType) || false}
                        disabled
                      >
                        {t('generalData.resident')}
                      </Checkbox>
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </div>

            <div className="content-section vehicul">
              <Divider>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <span>Vehicul și Remorci</span>
                </div>
              </Divider>
              <Row gutter={16}>
                <Col sm={24} md={12} lg={6}>
                  <Form.Item label={t('generalData.vehicle.number')}>
                    <Input value={vehicle.plateNumber || ''} disabled />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={6}>
                  <Form.Item label={t('generalData.vehicle.vinCode')}>
                    <Input value={vehicle.vinCode || ''} disabled />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={6}>
                  <Form.Item label={t('generalData.vehicle.country')}>
                    <Input
                      value={getCountryName(vehicle.countryId) || ''}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={6}>
                  <Form.Item label={t('generalData.vehicle.category')}>
                    <Input
                      value={getVehicleCategoryName(vehicle.category) || ''}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={6}>
                  <Form.Item label={t('generalData.vehicle.mark')}>
                    <Input value={vehicle.mark || ''} disabled />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={6}>
                  <Form.Item label={t('generalData.vehicle.model')}>
                    <Input value={vehicle.model || ''} disabled />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={6}>
                  <Form.Item label={t('generalData.vehicle.color')}>
                    <Input value={vehicle.color || ''} disabled />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={6}>
                  <Form.Item label={t('generalData.vehicle.typeOfTransport')}>
                    <Input
                      value={getVehicleTypeName(vehicle.vehicleTypeId) || ''}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={6}>
                  <Form.Item label={t('generalData.vehicle.towNr1')}>
                    <Input value={trailer1.plateNumber || ''} disabled />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={6}>
                  <Form.Item label={t('generalData.vehicle.vinCode')}>
                    <Input value={trailer1.vinCode || ''} disabled />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={6}>
                  <Form.Item label={t('generalData.vehicle.towType')}>
                    <Input
                      value={getTrailerTypeName(trailer1.trailerTypeId) || ''}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={6}>
                  <Form.Item label={t('generalData.vehicle.towCountry')}>
                    <Input
                      value={getCountryName(trailer1.countryId) || ''}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={6}>
                  <Form.Item label={t('generalData.vehicle.towNr2')}>
                    <Input value={trailer2.plateNumber || ''} disabled />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={6}>
                  <Form.Item label={t('generalData.vehicle.vinCode')}>
                    <Input value={trailer2.vinCode || ''} disabled />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={6}>
                  <Form.Item label={t('generalData.vehicle.towType')}>
                    <Input
                      value={getTrailerTypeName(trailer2.trailerTypeId) || ''}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={6}>
                  <Form.Item label={t('generalData.vehicle.towCountry')}>
                    <Input
                      value={getCountryName(trailer2.countryId) || ''}
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>

          <div className="content-section">
            <Divider>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <span>Transportator</span>
              </div>
            </Divider>
            <Row gutter={16}>
              <Col sm={24} md={12} lg={8}>
                <Form.Item label={t('generalData.carrier.carrierId')}>
                  <Input value={data.transporterPersonCode || ''} disabled />
                </Form.Item>
              </Col>
              <Col sm={24} md={12} lg={8}>
                <Form.Item label={t('generalData.carrier.carrierName')}>
                  <Input value={data.transporterPersonName || ''} disabled />
                </Form.Item>
              </Col>
              <Col sm={24} md={12} lg={6}>
                <Form.Item label={t('generalData.carrier.country')}>
                  <Input
                    value={getCountryName(transporter.countryId) || ''}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={12} lg={2}>
                <Form.Item className="checkbox__label" valuePropName="checked">
                  <Checkbox
                    checked={getResidentStatus(transporter.personType) || false}
                    disabled
                  >
                    {t('generalData.resident')}
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </div>

          {data.loadCountryId && data.unloadCountryId && (
            <div className="content-section">
              <Divider>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <span>Marfă</span>
                </div>
              </Divider>
              <Row gutter={16}>
                <Col sm={24} md={12} lg={8}>
                  <Form.Item label={t('generalData.goods.countryLoading')}>
                    <Input
                      value={getCountryName(data.loadCountryId) || ''}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={8}>
                  <Form.Item label={t('generalData.goods.countryUnloading')}>
                    <Input
                      value={getCountryName(data.unloadCountryId) || ''}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={3}>
                  <Form.Item
                    className="checkbox__label"
                    valuePropName="checked"
                  >
                    <Checkbox
                      checked={data.hasDangerousGoods || false}
                      disabled
                    >
                      {t('generalData.goods.dangerousGoods')}
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col sm={18} md={12} lg={3}>
                  <Form.Item
                    className="checkbox__label"
                    valuePropName="checked"
                  >
                    <Checkbox checked={vehicle.hasPerishable || false} disabled>
                      {t('generalData.goods.perishableGoods')}
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col sm={18} md={12} lg={2}>
                  <Form.Item
                    className="checkbox__label"
                    valuePropName="checked"
                  >
                    <Checkbox checked={scale.isGroupage || false} disabled>
                      {t('generalData.goods.groupage')}
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          )}

          {scale && Object.keys(scale).length !== 0 && (
            <div className="content-section">
              <Divider>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <span>Agent economic</span>
                </div>
              </Divider>
              <Row gutter={16}>
                <Col sm={24} md={12} lg={6}>
                  <Form.Item label={t('generalData.scale.idno')}>
                    <Input value={scale.agentIdno || ''} disabled />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={8}>
                  <Form.Item label={t('generalData.scale.agentName')}>
                    <Input value={scale.agentName || ''} disabled />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={6}>
                  <Form.Item label={t('generalData.goods.countryUnloading')}>
                    <Input
                      value={getCountryName(scale.agentCountryId) || ''}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={4}>
                  <Form.Item
                    className="checkbox__label"
                    valuePropName="checked"
                  >
                    <Checkbox
                      checked={getResidentStatus(scale.agentType) || false}
                      disabled
                    >
                      {t('generalData.resident')}
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={4}>
                  <Form.Item label={t('generalData.scale.carrierWeight')}>
                    <Input value={scale.goodsWeight / 1000 || ''} disabled />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={4}>
                  <Form.Item label={t('generalData.scale.loadWeight')}>
                    <Input value={scale.vehicleWeight / 1000 || ''} disabled />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={4}>
                  <Form.Item label={t('generalData.scale.axleNumber')}>
                    <Input value={scale.axisCount || ''} disabled />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={4}>
                  <Form.Item label={t('generalData.scale.axleDistance')}>
                    <Input value={scale.axisDistance || ''} disabled />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={4}>
                  <Form.Item label={t('generalData.scale.distance')}>
                    <Input value={scale.distance || ''} disabled />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          )}

          {scale && Object.keys(scale).length !== 0 && (
            <div className="content-section">
              <Divider>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <span>Acte de însoțire și mărfuri</span>
                </div>
              </Divider>
              <GoodsDocumentTable
                initial={scale?.goodsDocuments}
                buttonRender={false}
                dividerRender={false}
                actionColRender={false}
                disabled
              />
            </div>
          )}

          {consemne && Object.keys(consemne).length !== 0 && (
            <div className="content-section">
              <Divider>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <span>Date istorice și analiză</span>
                </div>
              </Divider>
              <TextArea
                className="area_irregularity"
                readOnly
                value={extractConsemne(consemne)}
                rows={6}
                style={{
                  backgroundColor: '#f1c40f',
                  width: '100%',
                  marginBottom: '20px',
                }}
              />
              <Row gutter={24}>
                <Col sm={24} md={12} lg={6}>
                  <Form.Item label="Creat de">
                    <Input value={createdBy || ''} disabled />
                  </Form.Item>
                  <Form.Item label="La data">
                    <Input value={createdAt || ''} disabled />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={18}>
                  <Form.Item
                    label="Răspuns"
                    name="irregularitiesMessageResponse"
                    initialValue={message.message}
                  >
                    <TextArea rows={4} disabled />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          )}
        </Form>
      );
    }
    return <p>No data found</p>;
  };

  // const renderContent = () => {
  //   if (loading) {
  //     return <Spin size="large" />;
  //   }
  //   if (data) {
  //     const scale = data.scale || {};
  //     const person =
  //       data.personList?.find(
  //         (pers) => pers.isCrossing && pers.isPhysicalPerson,
  //       ) || {};
  //     const driver =
  //       data.personList?.find(
  //         (pers) =>
  //           pers.drivingLicence ||
  //           pers.drivingLicenceCountryId ||
  //           pers.drivingLicenceDate,
  //       ) || {};
  //     const owner =
  //       data.personList?.find(
  //         (pers) => pers.isOwner && !pers.isPhysicalPerson,
  //       ) || {};
  //     const transporter =
  //       data.personList?.find(
  //         (pers) => pers.isCrossing && !pers.isPhysicalPerson,
  //       ) || {};
  //     const vehicle = data.vehicles?.find((veh) => !veh.isTrailer) || {};
  //     const trailer1 = data.vehicles?.find((veh) => veh.isTrailer) || {};
  //     const trailer2 =
  //       data.vehicles?.filter((veh) => veh.isTrailer && veh !== trailer1)[0] ||
  //       {};
  //     const consemne = data.consemne || {};
  //     const message = data.message || {};
  //     return (
  //       <Form layout="vertical">
  //         <Collapse
  //           defaultActiveKey={['1', '2', '3', '4', '5', '6']}
  //           className="risk__preview"
  //         >
  //           <Collapse.Panel header="Persoane" key="1">
  //             <Row gutter={16}>
  //               <Col sm={24} md={12} lg={8}>
  //                 <Form.Item label={t('generalData.person.direction')}>
  //                   <Input
  //                     value={getDirectionName(data.direction) || ''}
  //                     disabled
  //                   />
  //                 </Form.Item>
  //               </Col>
  //               <Col sm={24} md={12} lg={8}>
  //                 <Form.Item label={t('generalData.person.type')}>
  //                   <Input
  //                     value={getCustomsRegimeName(data.customsRegimeId) || ''}
  //                     disabled
  //                   />
  //                 </Form.Item>
  //               </Col>
  //               <Col sm={24} md={12} lg={6}>
  //                 <Form.Item label={t('generalData.person.passportNumber')}>
  //                   <Input value={data.idnp || ''} disabled />
  //                 </Form.Item>
  //               </Col>
  //               <Col sm={24} md={12} lg={2}>
  //                 <Form.Item
  //                   className="checkbox__label"
  //                   valuePropName="checked"
  //                   labelCol={{ span: 24 }}
  //                 >
  //                   <Checkbox checked={person.isDiplomat || false} disabled>
  //                     {t('generalData.person.isDiplomat')}
  //                   </Checkbox>
  //                 </Form.Item>
  //               </Col>
  //               <Col sm={24} md={12} lg={8}>
  //                 <Form.Item label={t('generalData.person.nameDriver')}>
  //                   <Input value={data.driverFullName || ''} disabled />
  //                 </Form.Item>
  //               </Col>
  //               <Col sm={24} md={12} lg={8}>
  //                 <Form.Item label={t('generalData.person.country')}>
  //                   <Input
  //                     value={getCountryName(person.countryId) || ''}
  //                     disabled
  //                   />
  //                 </Form.Item>
  //               </Col>
  //               <Col sm={24} md={12} lg={4}>
  //                 <Form.Item
  //                   valuePropName="checked"
  //                   className="checkbox__label"
  //                 >
  //                   <Checkbox
  //                     checked={getResidentStatus(person.personType) || false}
  //                     disabled
  //                   >
  //                     {t('generalData.resident')}
  //                   </Checkbox>
  //                 </Form.Item>
  //               </Col>
  //               {driver.drivingLicenceDate && (
  //                 <Divider>
  //                   <div
  //                     style={{
  //                       display: 'flex',
  //                       alignItems: 'center',
  //                       gap: '10px',
  //                     }}
  //                   >
  //                     <span>Permis de conducere</span>
  //                   </div>
  //                 </Divider>
  //               )}
  //               {driver.drivingLicenceDate && (
  //                 <Col sm={24} md={12} lg={8}>
  //                   <Form.Item
  //                     label={t(
  //                       'generalData.person.drivingLicense.numberAndSeries',
  //                     )}
  //                   >
  //                     <Input value={driver.drivingLicence || ''} disabled />
  //                   </Form.Item>
  //                 </Col>
  //               )}
  //               {driver.drivingLicenceDate && (
  //                 <Col sm={24} md={12} lg={8}>
  //                   <Form.Item
  //                     label={t('generalData.person.drivingLicense.date')}
  //                   >
  //                     <Input value={driver.drivingLicenceDate || ''} disabled />
  //                   </Form.Item>
  //                 </Col>
  //               )}
  //               {driver.drivingLicenceDate && (
  //                 <Col sm={24} md={12} lg={8}>
  //                   <Form.Item
  //                     label={t('generalData.person.drivingLicense.country')}
  //                   >
  //                     <Input
  //                       value={
  //                         getCountryName(driver.drivingLicenceCountryId) || ''
  //                       }
  //                       disabled
  //                     />
  //                   </Form.Item>
  //                 </Col>
  //               )}
  //               {owner.personalCode && (
  //                 <Divider>
  //                   <div
  //                     style={{
  //                       display: 'flex',
  //                       alignItems: 'center',
  //                       gap: '10px',
  //                     }}
  //                   >
  //                     <span>Transportator</span>
  //                   </div>
  //                 </Divider>
  //               )}
  //               {owner.personalCode && (
  //                 <Col sm={24} md={12} lg={8}>
  //                   <Form.Item
  //                     label={t('generalData.person.owner.passportNumber')}
  //                   >
  //                     <Input value={owner.personalCode || ''} disabled />
  //                   </Form.Item>
  //                 </Col>
  //               )}
  //               {owner.fullName && (
  //                 <Col sm={24} md={12} lg={8}>
  //                   <Form.Item label={t('generalData.person.owner.name')}>
  //                     <Input value={owner.fullName || ''} disabled />
  //                   </Form.Item>
  //                 </Col>
  //               )}
  //               {owner.countryId && (
  //                 <Col sm={24} md={12} lg={6}>
  //                   <Form.Item label={t('generalData.person.owner.country')}>
  //                     <Input
  //                       value={getCountryName(owner.countryId) || ''}
  //                       disabled
  //                     />
  //                   </Form.Item>
  //                 </Col>
  //               )}
  //               {owner.personType && (
  //                 <Col sm={24} md={12} lg={2}>
  //                   <Form.Item
  //                     className="checkbox__label"
  //                     valuePropName="checked"
  //                   >
  //                     <Checkbox
  //                       checked={getResidentStatus(owner.personType) || false}
  //                       disabled
  //                     >
  //                       {t('generalData.resident')}
  //                     </Checkbox>
  //                   </Form.Item>
  //                 </Col>
  //               )}
  //             </Row>
  //           </Collapse.Panel>
  //
  //           <Collapse.Panel header="Vehicul și Remorci" key="2">
  //             <Row gutter={16}>
  //               <Col sm={24} md={12} lg={6}>
  //                 <Form.Item label={t('generalData.vehicle.number')}>
  //                   <Input value={vehicle.plateNumber || ''} disabled />
  //                 </Form.Item>
  //               </Col>
  //               <Col sm={24} md={12} lg={6}>
  //                 <Form.Item label={t('generalData.vehicle.vinCode')}>
  //                   <Input value={vehicle.vinCode || ''} disabled />
  //                 </Form.Item>
  //               </Col>
  //               <Col sm={24} md={12} lg={6}>
  //                 <Form.Item label={t('generalData.vehicle.country')}>
  //                   <Input
  //                     value={getCountryName(vehicle.countryId) || ''}
  //                     disabled
  //                   />
  //                 </Form.Item>
  //               </Col>
  //               <Col sm={24} md={12} lg={6}>
  //                 <Form.Item label={t('generalData.vehicle.category')}>
  //                   <Input
  //                     value={getVehicleCategoryName(vehicle.category) || ''}
  //                     disabled
  //                   />
  //                 </Form.Item>
  //               </Col>
  //               <Col sm={24} md={12} lg={6}>
  //                 <Form.Item label={t('generalData.vehicle.mark')}>
  //                   <Input value={vehicle.mark || ''} disabled />
  //                 </Form.Item>
  //               </Col>
  //               <Col sm={24} md={12} lg={6}>
  //                 <Form.Item label={t('generalData.vehicle.model')}>
  //                   <Input value={vehicle.model || ''} disabled />
  //                 </Form.Item>
  //               </Col>
  //               <Col sm={24} md={12} lg={6}>
  //                 <Form.Item label={t('generalData.vehicle.color')}>
  //                   <Input value={vehicle.color || ''} disabled />
  //                 </Form.Item>
  //               </Col>
  //               <Col sm={24} md={12} lg={6}>
  //                 <Form.Item label={t('generalData.vehicle.typeOfTransport')}>
  //                   <Input
  //                     value={getVehicleTypeName(vehicle.vehicleTypeId) || ''}
  //                     disabled
  //                   />
  //                 </Form.Item>
  //               </Col>
  //               <Col sm={24} md={12} lg={6}>
  //                 <Form.Item label={t('generalData.vehicle.towNr1')}>
  //                   <Input value={trailer1.plateNumber || ''} disabled />
  //                 </Form.Item>
  //               </Col>
  //               <Col sm={24} md={12} lg={6}>
  //                 <Form.Item label={t('generalData.vehicle.vinCode')}>
  //                   <Input value={trailer1.vinCode || ''} disabled />
  //                 </Form.Item>
  //               </Col>
  //               <Col sm={24} md={12} lg={6}>
  //                 <Form.Item label={t('generalData.vehicle.towType')}>
  //                   <Input
  //                     value={getTrailerTypeName(trailer1.trailerTypeId) || ''}
  //                     disabled
  //                   />
  //                 </Form.Item>
  //               </Col>
  //               <Col sm={24} md={12} lg={6}>
  //                 <Form.Item label={t('generalData.vehicle.towCountry')}>
  //                   <Input
  //                     value={getCountryName(trailer1.countryId) || ''}
  //                     disabled
  //                   />
  //                 </Form.Item>
  //               </Col>
  //               <Col sm={24} md={12} lg={6}>
  //                 <Form.Item label={t('generalData.vehicle.towNr2')}>
  //                   <Input value={trailer2.plateNumber || ''} disabled />
  //                 </Form.Item>
  //               </Col>
  //               <Col sm={24} md={12} lg={6}>
  //                 <Form.Item label={t('generalData.vehicle.vinCode')}>
  //                   <Input value={trailer2.vinCode || ''} disabled />
  //                 </Form.Item>
  //               </Col>
  //               <Col sm={24} md={12} lg={6}>
  //                 <Form.Item label={t('generalData.vehicle.towType')}>
  //                   <Input
  //                     value={getTrailerTypeName(trailer2.trailerTypeId) || ''}
  //                     disabled
  //                   />
  //                 </Form.Item>
  //               </Col>
  //               <Col sm={24} md={12} lg={6}>
  //                 <Form.Item label={t('generalData.vehicle.towCountry')}>
  //                   <Input
  //                     value={getCountryName(trailer2.countryId) || ''}
  //                     disabled
  //                   />
  //                 </Form.Item>
  //               </Col>
  //             </Row>
  //           </Collapse.Panel>
  //
  //           <Collapse.Panel header="Transportator" key="3">
  //             <Row gutter={16}>
  //               <Col sm={24} md={12} lg={8}>
  //                 <Form.Item label={t('generalData.carrier.carrierId')}>
  //                   <Input value={data.transporterPersonCode || ''} disabled />
  //                 </Form.Item>
  //               </Col>
  //               <Col sm={24} md={12} lg={8}>
  //                 <Form.Item label={t('generalData.carrier.carrierName')}>
  //                   <Input value={data.transporterPersonName || ''} disabled />
  //                 </Form.Item>
  //               </Col>
  //               <Col sm={24} md={12} lg={6}>
  //                 <Form.Item label={t('generalData.carrier.country')}>
  //                   <Input
  //                     value={getCountryName(transporter.countryId) || ''}
  //                     disabled
  //                   />
  //                 </Form.Item>
  //               </Col>
  //               <Col sm={24} md={12} lg={2}>
  //                 <Form.Item
  //                   className="checkbox__label"
  //                   valuePropName="checked"
  //                 >
  //                   <Checkbox
  //                     checked={
  //                       getResidentStatus(transporter.personType) || false
  //                     }
  //                     disabled
  //                   >
  //                     {t('generalData.resident')}
  //                   </Checkbox>
  //                 </Form.Item>
  //               </Col>
  //               <Col sm={24} md={12} lg={8}>
  //                 <Form.Item label={t('generalData.carrier.passengers')}>
  //                   <Input value={data.persons || ''} disabled />
  //                 </Form.Item>
  //               </Col>
  //             </Row>
  //           </Collapse.Panel>
  //           {data.loadCountryId && data.unloadCountryId && (
  //             <Collapse.Panel header="Marfă" key="4">
  //               <Row gutter={16}>
  //                 <Col sm={24} md={12} lg={8}>
  //                   <Form.Item label={t('generalData.goods.countryLoading')}>
  //                     <Input
  //                       value={getCountryName(data.loadCountryId) || ''}
  //                       disabled
  //                     />
  //                   </Form.Item>
  //                 </Col>
  //                 <Col sm={24} md={12} lg={8}>
  //                   <Form.Item label={t('generalData.goods.countryUnloading')}>
  //                     <Input
  //                       value={getCountryName(data.unloadCountryId) || ''}
  //                       disabled
  //                     />
  //                   </Form.Item>
  //                 </Col>
  //                 <Col sm={24} md={12} lg={3}>
  //                   <Form.Item
  //                     className="checkbox__label"
  //                     valuePropName="checked"
  //                   >
  //                     <Checkbox
  //                       checked={data.hasDangerousGoods || false}
  //                       disabled
  //                     >
  //                       {t('generalData.goods.dangerousGoods')}
  //                     </Checkbox>
  //                   </Form.Item>
  //                 </Col>
  //                 <Col sm={18} md={12} lg={3}>
  //                   <Form.Item
  //                     className="checkbox__label"
  //                     valuePropName="checked"
  //                   >
  //                     <Checkbox
  //                       checked={vehicle.hasPerishable || false}
  //                       disabled
  //                     >
  //                       {t('generalData.goods.perishableGoods')}
  //                     </Checkbox>
  //                   </Form.Item>
  //                 </Col>
  //                 <Col sm={18} md={12} lg={2}>
  //                   <Form.Item
  //                     className="checkbox__label"
  //                     valuePropName="checked"
  //                   >
  //                     <Checkbox checked={scale.isGroupage || false} disabled>
  //                       {t('generalData.goods.groupage')}
  //                     </Checkbox>
  //                   </Form.Item>
  //                 </Col>
  //               </Row>
  //             </Collapse.Panel>
  //           )}
  //           {scale && Object.keys(scale).length !== 0 && (
  //             <Collapse.Panel header="Date cântar" key="5">
  //               <Row gutter={16}>
  //                 <Col sm={24} md={12} lg={6}>
  //                   <Form.Item label={t('generalData.scale.idno')}>
  //                     <Input value={scale.agentIdno || ''} disabled />
  //                   </Form.Item>
  //                 </Col>
  //                 <Col sm={24} md={12} lg={8}>
  //                   <Form.Item label={t('generalData.scale.agentName')}>
  //                     <Input value={scale.agentName || ''} disabled />
  //                   </Form.Item>
  //                 </Col>
  //                 <Col sm={24} md={12} lg={6}>
  //                   <Form.Item label={t('generalData.goods.countryUnloading')}>
  //                     <Input
  //                       value={getCountryName(scale.agentCountryId) || ''}
  //                       disabled
  //                     />
  //                   </Form.Item>
  //                 </Col>
  //                 <Col sm={24} md={12} lg={4}>
  //                   <Form.Item
  //                     className="checkbox__label"
  //                     valuePropName="checked"
  //                   >
  //                     <Checkbox
  //                       checked={getResidentStatus(scale.agentType) || false}
  //                       disabled
  //                     >
  //                       {t('generalData.resident')}
  //                     </Checkbox>
  //                   </Form.Item>
  //                 </Col>
  //                 <Col sm={24} md={12} lg={4}>
  //                   <Form.Item label={t('generalData.scale.carrierWeight')}>
  //                     <Input value={scale.goodsWeight / 1000 || ''} disabled />
  //                   </Form.Item>
  //                 </Col>
  //                 <Col sm={24} md={12} lg={4}>
  //                   <Form.Item label={t('generalData.scale.loadWeight')}>
  //                     <Input
  //                       value={scale.vehicleWeight / 1000 || ''}
  //                       disabled
  //                     />
  //                   </Form.Item>
  //                 </Col>
  //                 <Col sm={24} md={12} lg={4}>
  //                   <Form.Item label={t('generalData.scale.axleNumber')}>
  //                     <Input value={scale.axisCount || ''} disabled />
  //                   </Form.Item>
  //                 </Col>
  //                 <Col sm={24} md={12} lg={4}>
  //                   <Form.Item label={t('generalData.scale.axleDistance')}>
  //                     <Input value={scale.axisDistance || ''} disabled />
  //                   </Form.Item>
  //                 </Col>
  //                 <Col sm={24} md={12} lg={4}>
  //                   <Form.Item label={t('generalData.scale.distance')}>
  //                     <Input value={scale.distance || ''} disabled />
  //                   </Form.Item>
  //                 </Col>
  //               </Row>
  //             </Collapse.Panel>
  //           )}
  //           {scale && Object.keys(scale).length !== 0 && (
  //             <Collapse.Panel header="Acte de însoțire și mărfuri" key="6">
  //               <GoodsDocumentTable
  //                 initial={scale?.goodsDocuments}
  //                 buttonRender={false}
  //                 dividerRender={false}
  //                 actionColRender={false}
  //                 disabled
  //               />
  //             </Collapse.Panel>
  //           )}
  //           {consemne && Object.keys(consemne).length !== 0 && (
  //             <Collapse.Panel header="Date istorice și analiză" key="7">
  //               <TextArea
  //                 className="area_irregularity"
  //                 readOnly
  //                 value={extractConsemne(consemne)}
  //                 rows={6}
  //                 style={{
  //                   backgroundColor: '#f1c40f',
  //                   width: '100%',
  //                 }}
  //               />
  //               <br />
  //               <Form.Item
  //                 name="irregularitiesMessageResponse"
  //                 style={{ marginBottom: '20px', width: '100%' }}
  //                 initialValue={message.message}
  //               >
  //                 <TextArea rows={4} disabled />
  //               </Form.Item>
  //             </Collapse.Panel>
  //           )}
  //         </Collapse>
  //       </Form>
  //     );
  //   }
  //   return <p>No data found</p>;
  // };

  return (
    <Modal
      title="Date Generale"
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Închide
        </Button>,
      ]}
      width={2000}
    >
      {renderContent()}
      {!isAccessRejected() && (
        <ControlButtons
          vehicleNumber={data?.plateNumber}
          readOnly
          isWeighingAmount={isWeighingAmount}
          isAntaAmount={isAntaAmount}
          isAnsaAmount={isAnsaAmount}
          verificationMethod={saveActControl}
          verificationParameters={documentParameters(
            t('actions.controlDocument'),
            verificationDocumentParametersModalTitle,
            verificationDocumentParametersMap,
          )}
          receiptMethod={saveBonPlata}
          receiptParameters={documentParameters(
            t('actions.receiptNote'),
            paymentReceiptParametersModalTitle,
            [],
          )}
          customsAttributesUser={user?.customsAttributes || []}
          customsAttributesTransaction={data?.customsAttributes || []}
          transactionId={data?.id}
          returModalParameters={{
            returnData: data?.returnParameters,
          }}
          sesizariModalParameters={{
            sesizariData: data?.sesizari,
            person: data?.personList?.find(
              (el) => el.isPhysicalPerson && el.isCrossing,
            ),
          }}
          fraudReportParameters={data?.suspicionFraudReport}
          irregularities={data?.irregularities}
        />
      )}
    </Modal>
  );
};

export default IdentifiedRisksViewModal;
