const COLORS = [
  { code: 'GRI', value: '#808080' },
  { code: 'GRIO', value: '#808080' },
  { code: 'SUR', value: '#808080' },
  { code: 'SURA', value: '#808080' },
  { code: 'SURĂ', value: '#808080' },
  { code: 'BLEU-MARIN', value: '#00304E' },
  { code: 'BLEU-MARINE', value: '#00304E' },
  { code: 'BLEUMARIN', value: '#00304E' },
  { code: 'BLEUMARINE', value: '#00304E' },
  { code: 'VERDE', value: '#4FC878' },
  { code: 'VERZUIE', value: '#4FC878' },
  { code: 'VERZ', value: '#4FC878' },
  { code: 'HAKI', value: '#445C2E' },
  { code: 'HACHI', value: '#445C2E' },
  { code: 'ORANJ', value: '#e97a37' },
  { code: 'PORTOCALIU', value: '#e97a37' },
  { code: 'PORTOCALIE', value: '#e97a37' },
  { code: 'ORANGE', value: '#e97a37' },
  { code: 'ROSU', value: '#E6484B' },
  { code: 'ROȘU', value: '#E6484B' },
  { code: 'ROSIE', value: '#E6484B' },
  { code: 'ROȘIE', value: '#E6484B' },
  { code: 'ALB', value: '#FFFFFF' },
  { code: 'ALBA', value: '#FFFFFF' },
  { code: 'ALBĂ', value: '#FFFFFF' },
  { code: 'ALBASTRU', value: '#1890ff' },
  { code: 'ALBASTRUI', value: '#1890ff' },
  { code: 'ALBĂSTRUI', value: '#1890ff' },
  { code: 'ALBĂST', value: '#1890ff' },
  { code: 'ALBAST', value: '#1890ff' },
  { code: 'ALBASTRA', value: '#1890ff' },
  { code: 'ALBASTRĂ', value: '#1890ff' },
  { code: 'BLEU', value: '#1890ff' },
  { code: 'MARO', value: '#800000' },
  { code: 'CAFENIU', value: '#864402' },
  { code: 'CAFENIE', value: '#864402' },
  { code: 'BRUN', value: '#5b3c11' },
  { code: 'BRUNA', value: '#5b3c11' },
  { code: 'BRUNĂ', value: '#5b3c11' },
  { code: 'BORDO', value: '#5F021F' },
  { code: 'VISINIU', value: '#6e0b14' },
  { code: 'VISINIE', value: '#6e0b14' },
  { code: 'VIȘINIU', value: '#6e0b14' },
  { code: 'VIȘINIE', value: '#6e0b14' },
  { code: 'BEJ', value: '#DDC7A0' },
  { code: 'BEJA', value: '#DDC7A0' },
  { code: 'BEJĂ', value: '#DDC7A0' },
  { code: 'NEGRU', value: '#000000' },
  { code: 'NEAGRA', value: '#000000' },
  { code: 'NEAGRĂ', value: '#000000' },
  { code: 'NEGRA', value: '#000000' },
  { code: 'ARGINT', value: '#CECECE' },
  { code: 'ARGINTIU', value: '#CECECE' },
  { code: 'ARGINTIE', value: '#CECECE' },
  { code: 'ARGENTIU', value: '#CECECE' },
  { code: 'ARGENTIE', value: '#CECECE' },
  { code: 'AUR', value: '#D2CB0F' },
  { code: 'AURIU', value: '#D2CB0F' },
  { code: 'AURIE', value: '#D2CB0F' },
  { code: 'VIOLET', value: '#7f0ff1' },
  { code: 'VIOLETA', value: '#7f0ff1' },
  { code: 'VIOLETĂ', value: '#7f0ff1' },
  { code: 'GALBEN', value: '#f1c40f' },
  { code: 'GALBENA', value: '#f1c40f' },
  { code: 'GALBENĂ', value: '#f1c40f' },
  { code: 'LILIAC', value: '#B666D2' },
  { code: 'LILIACHIU', value: '#B666D2' },
  { code: 'LILIACHIE', value: '#B666D2' },
  { code: 'ROZ', value: '#FD6C9E' },
  { code: 'ROZA', value: '#FD6C9E' },
  { code: 'ROZĂ', value: '#FD6C9E' },
  { code: 'AMARANT', value: '#9F2B68' },
  { code: 'AMARANTA', value: '#9F2B68' },
  { code: 'AMARANTĂ', value: '#9F2B68' },
  { code: 'ARDEZIU', value: '#708090' },
  { code: 'ARDEZIE', value: '#708090' },
  { code: 'CYAN', value: '#00FFFF' },
  { code: 'FUCHSIA', value: '#FF00FF' },
  { code: 'CASTANIU', value: '#8B6C42' },
  { code: 'CASTANIE', value: '#8B6C42' },
  { code: 'CAȘTANIU', value: '#8B6C42' },
  { code: 'CAȘTANIE', value: '#8B6C42' },
  { code: 'MAGENTA', value: '#EC008C' },
  { code: 'MAGENTĂ', value: '#EC008C' },
  { code: 'MASLINIU', value: '#808000' },
  { code: 'MĂSLINIU', value: '#808000' },
  { code: 'PURPURIU', value: '#9932CC' },
  { code: 'PURPUR', value: '#9932CC' },
  { code: 'OCRU', value: '#CC7722' },
  { code: 'TURCOAZ', value: '#40E0D0' },
  { code: 'TURCUAZ', value: '#40E0D0' },
  { code: 'TURCOAZA', value: '#40E0D0' },
  { code: 'TURCOAZĂ', value: '#40E0D0' },
];

export const getColorHexCodeByName = (color) => {
  const mainColor = COLORS.find(
    (el) => el.code === color?.toUpperCase(),
  )?.value;
  if (mainColor) {
    return mainColor;
  }
  const secondColor = COLORS.find((el) =>
    color?.toUpperCase()?.includes(el.code),
  )?.value;
  if (secondColor) {
    return secondColor;
  }
  return '#000000';
};
