import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Modal, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { findOne } from '../../../services/transactions/transactions';
import { acceptTransaction } from '../../../services/admin/web-services/backend';
import { findAll as findAllConstants } from '../../../services/taxonomies/constants';
import AuthContext, { hasPermission } from '../../auth';
import useDictionaries from '../../../hooks/useDictionaries';
import { generateTrafficLight } from '../../../services/utils/TrafficLight';
import { getCountryByAlpha3 } from '../../../services/taxonomies/countries';
import WithholdingReasonModal from './WithholdingReasonModal';
import {
  getPaymentInCurrentSystem,
  getPaymentInOldSystem,
} from '../../../services/admin/web-services/payments';
import { findByTransactionId as getBonPlataByTransactionId } from '../../../services/admin/bon-plata-parameters';

const dictionaries = {
  constants: findAllConstants,
};

const LiberModal = ({
  onInit = async () => {},
  transactionId,
  readOnly,
  hasScale,
  setLoadingLoader,
  reload = () => {},
}) => {
  const { user } = useContext(AuthContext);
  const { permissions } = user;
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [transaction, setTransaction] = useState({});
  const [getDictionaries, setGetDictionaries] = useState(false);

  const [ukraineId, setUkraineId] = useState(null);
  const [withholdingReasonsModal, setWithholdingReasonsModal] = useState(false);
  const [cargoCategories, setCargoCategories] = useState([]);

  const toggleTrafficLight = () => {
    setIsModalVisible(true);
  };

  const getReceiptStatus = async () => {
    const res = await getBonPlataByTransactionId(transactionId);
    if (res.id) {
      try {
        const payResponse = await getPaymentInCurrentSystem(transactionId);
        if (payResponse?.isPaid === false) {
          const oldRes = await getPaymentInOldSystem(transactionId);
          if (oldRes?.ste !== 'PAID') {
            return false;
          }
          return true;
        }
        return true;
      } catch (err) {
        return false;
      }
    }
    return null;
  };

  const [{ constants }, , isDictionaryLoading] = useDictionaries(dictionaries, {
    allowFetcher: getDictionaries,
  });

  useEffect(() => {
    const categories =
      constants?.content
        ?.find((el) => el.code === 'TERMINAL_MARFA_CATEGORII')
        ?.value?.split(',') || [];

    setCargoCategories(categories);
  }, [constants?.content]);

  useEffect(() => {
    if (getDictionaries && ukraineId !== null)
      getCountryByAlpha3('UKR').then((res) => setUkraineId(res?.id));
  }, [getDictionaries, ukraineId]);

  const checkTransactionConsemne = (transactionData) => {
    return (
      transactionData.consemne.length > 0 && transactionData.message === null
    );
  };

  const checkTransactionTime = useCallback(
    (transactionData) => {
      if (transactionData.withholdingReason) return false;

      const isCargo = cargoCategories.includes(
        transactionData.vehicles.find(
          (el) => !el.isTrailer && el.isTrailer !== null,
        )?.category,
      );

      if (isCargo) {
        const timeForCargo =
          constants?.content?.find(
            (el) => el.code === 'TRANSACTION_PROCESSING_TIME_CARGO',
          )?.value || null;

        return timeForCargo !== null
          ? transactionData.crossingTimeSeconds > Number(timeForCargo)
          : false;
      }

      const timeForVehicles =
        constants?.content?.find(
          (el) => el.code === 'TRANSACTION_PROCESSING_TIME_AUTO',
        )?.value || null;

      return timeForVehicles !== null
        ? transactionData.crossingTimeSeconds > Number(timeForVehicles)
        : false;
    },
    // eslint-disable-next-line
    [constants?.content],
  );

  // eslint-disable-next-line
  const checkTransactionSCALE = useCallback(
    (transactionData) => {
      if (!transactionData.hasGoods || transactionData.isScaleConfirmed) {
        return false;
      }

      const vehicle = transactionData.vehicles.find(
        (el) => !el.isTrailer && el.isTrailer !== null,
      );
      return (
        cargoCategories.includes(vehicle?.category) ||
        vehicle?.category.charAt(0) === 'D'
      );
    },
    [cargoCategories],
  );

  const checkTransactionCargoMDUA = useCallback(
    (transactionData) => {
      if (
        !transactionData.hasGoods ||
        transactionData.isMdUaConfirmed ||
        transactionData.direction === 'IN'
      ) {
        return false;
      }

      const vehicle = transactionData.vehicles.find(
        (el) => !el.isTrailer && el.isTrailer !== null,
      );

      return (
        vehicle?.countryId === ukraineId &&
        cargoCategories.includes(vehicle?.category)
      );
    },
    [cargoCategories, ukraineId],
  );

  const checkTransactionANTA = useCallback(
    (transactionData) => {
      if (!transactionData.hasGoods || transactionData.isAntaConfirmed) {
        return false;
      }

      const vehicle = transactionData.vehicles.find(
        (el) => !el.isTrailer && el.isTrailer !== null,
      );
      return (
        cargoCategories.includes(vehicle?.category) ||
        vehicle?.category.charAt(0) === 'D'
      );
    },
    [cargoCategories],
  );

  const handleWithholdTransaction = () => {
    setWithholdingReasonsModal(true);
  };

  const handleCancelTrafficLightModal = () => {
    setIsModalVisible(false);
  };

  const handleCheckTransaction = useCallback(() => {
    setLoadingLoader(true);
    acceptTransaction(transaction?.id)
      .then(() => {
        notification.success({
          message: 'Automobilul este liber.',
          duration: 3,
        });
        history.push('/home');
      })
      .catch(() => {
        notification.error({
          message: 'A apărut o eroare la efectuarea operațiunii',
          duration: 3,
        });
      })
      .finally(() => {
        setLoadingLoader(false);
        reload();
      });
  }, [history, transaction?.id, setLoadingLoader, reload]);

  const handleOk = () => {
    handleCheckTransaction();
    setIsModalVisible(false);
  };

  const showModal = async () => {
    setLoadingLoader(true);
    const receiptStatus = await getReceiptStatus();
    if (receiptStatus === false) {
      notification.error({
        message: 'Bonul de plată nu a fost achitat',
        duration: 3,
      });
      setLoadingLoader(false);
      return;
    }
    findOne(transactionId)
      .then((res) => {
        setLoadingLoader(false);
        setTransaction(res);
        if (!res.isGeneralDataConfirmed) {
          notification.error({
            message: 'Confirmați Date Generale',
          });
          return;
        }
        if (user?.customsPost.hasAnta && checkTransactionANTA(res)) {
          notification.error({
            message: 'Confirmați ANTA',
          });
          return;
        }
        if (
          !res.isScaleConfirmed &&
          user?.customsPost.hasScale &&
          res.hasGoods &&
          hasScale
        ) {
          notification.error({
            message: 'Confirmați Cântar',
          });
          return;
        }
        if (user?.customsPost.hasMdUa && checkTransactionCargoMDUA(res)) {
          notification.error({
            message: 'Confirmați MDUA',
          });
          return;
        }

        if (checkTransactionTime(res)) {
          handleWithholdTransaction();
        } else if (checkTransactionConsemne(res)) {
          notification.error({
            message: 'Confirmați vizualizarea consemnelor',
          });
        } else {
          toggleTrafficLight();
        }
      })
      .catch(() => {
        setLoadingLoader(false);
        notification.error({
          message: 'A apărut o eroare la încărcarea datelor',
        });
      });
  };

  const handleRender = async () => {
    await onInit()
      .then(() => setGetDictionaries((prev) => !prev))
      .catch(() => {});
  };

  useEffect(
    () => {
      if (
        (!isDictionaryLoading && getDictionaries) ||
        (constants?.content?.length > 0 && !isDictionaryLoading)
      ) {
        showModal();
      }
    }, // eslint-disable-next-line
    [isDictionaryLoading, getDictionaries],
  );

  return (
    <>
      <Button
        id="liber"
        className="btn__liber"
        onClick={() => handleRender()}
        disabled={readOnly}
        style={{
          width: '100%',
          background: '#2ecc71',
          border: '1px solid #2ecc71',
          maxWidth: '100%',
        }}
        block
      >
        Liber
      </Button>
      <Modal
        maskClosable={false}
        centered
        title="Confirmați acțiunea?"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancelTrafficLightModal}
        okText="Liber"
        cancelText="Înapoi"
        okButtonProps={{
          disabled: !hasPermission(permissions, 'ACCEPT_TRANSACTION'),
        }}
      >
        {transaction ? generateTrafficLight(transaction) : null}
      </Modal>
      {withholdingReasonsModal && (
        <WithholdingReasonModal
          visible={withholdingReasonsModal}
          setVisible={setWithholdingReasonsModal}
          transactionId={transactionId}
          setLoadingLoader={setLoadingLoader}
          showModal={showModal}
        />
      )}
    </>
  );
};

export default LiberModal;
