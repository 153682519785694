import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Tooltip, Button, Modal } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { EyeOutlined } from '@ant-design/icons';
import { showCopyTransaction } from '../../../services/admin/web-services/backend';
import Column from '../../../helpers/Columns';
import { findAll as findAllCustomsPosts } from '../../../services/admin/users/customs-posts';
import useDictionaries from '../../../hooks/useDictionaries';

const dictionaries = {
  customsPosts: findAllCustomsPosts,
};

const directions = {
  content: [
    { id: 'IN', code: 'IN', name: 'INTRARE' },
    { id: 'OUT', code: 'OUT', name: 'IEȘIRE' },
  ],
};

const CopyTransactionTable = ({ transactionId }) => {
  const { t } = useTranslation();
  const [{ customsPosts }] = useDictionaries(dictionaries);

  const [copyTransaction, setCopyTransaction] = useState([]);
  const [copyTransactionReasonModal, setcopyTransactionReasonModal] =
    useState(false);

  useEffect(() => {
    showCopyTransaction(transactionId)
      .then((res) => {
        setCopyTransaction(res);
      })
      .catch()
      .finally();
  }, [transactionId]);

  const columns = useMemo(
    () => [
      Column.dictionary('direction', 'Direcție', directions, {
        width: 50,
        filter: false,
      }),
      Column.date('statusDate', 'Data traversării', {
        width: 50,
        format: 'DD.MM.YY HH:mm:ss',
        sort: (prev, next) => {
          return (
            new Date(prev?.statusDate).getTime() -
            new Date(next?.statusDate).getTime()
          );
        },
      }),
      Column.text('letterNumber', 'Număr Scrisoare/PV', {
        width: 100,
        sort: false,
      }),
      Column.date('letterDate', 'Data Scrisoare/PV', {
        width: 50,
        format: 'DD.MM.YY HH:mm:ss',
        sort: (prev, next) => {
          return (
            new Date(prev?.statusDate).getTime() -
            new Date(next?.statusDate).getTime()
          );
        },
      }),
      {
        title: 'Post Vamal',
        width: 200,
        render: (row) => {
          const post = customsPosts?.content?.find(
            (el) => el.id === row?.postId,
          );
          const foreignPost = (row?.foreignPost || '').toUpperCase();
          return post?.code
            ? `(${post?.code}) ${post?.name || ''}`
            : `${foreignPost}`;
        },
      },
      Column.actions(t('table.actions'), (row) => (
        <span style={{ textAlign: 'right' }}>
          <Tooltip title="Vizualizează motivul">
            <Button
              shape="circle"
              type="link"
              icon={<EyeOutlined />}
              onClick={() => setcopyTransactionReasonModal(row)}
            />
          </Tooltip>
        </span>
      )),
    ],
    [customsPosts, t],
  );

  return (
    <>
      {copyTransaction.length > 0 ? (
        <>
          <h1
            style={{
              fontWeight: 600,
              fontSize: '18px',
              lineHeight: '32px',
            }}
          >
            Închiderea declarării prin acțiune
          </h1>
          <Table
            columns={columns}
            dataSource={copyTransaction}
            pagination={false}
          />
          <Modal
            maskClosable={false}
            centered
            style={{ width: '400px' }}
            title="Motivul închiderii declarării prin acțiune"
            open={copyTransactionReasonModal}
            onOk={() => setcopyTransactionReasonModal(false)}
            onCancel={() => setcopyTransactionReasonModal(false)}
            cancelText="Înapoi"
          >
            <TextArea
              value={copyTransactionReasonModal.reason}
              maxLength={3000}
              disabled
              id="reason"
              rows={6}
            />
          </Modal>
        </>
      ) : null}
    </>
  );
};

export default CopyTransactionTable;
