import { get, post, put } from '../../helpers/api';

export const BASE_URI = '/backend/api';
export const SERVICE_URI = '/v1/act-control-parameter';

export const findOne = (id) => get(`${BASE_URI}${SERVICE_URI}/${id}`);

export const findByTransactionId = (transactionId) =>
  get(`${BASE_URI}${SERVICE_URI}/transaction/${transactionId}`);

export const create = (body) => post(`${BASE_URI}${SERVICE_URI}`, body);

export const update = (id, body) =>
  put(`${BASE_URI}${SERVICE_URI}/${id}`, body);

export const updateByTransactionId = (transactionId, body) =>
  put(`${BASE_URI}${SERVICE_URI}/transaction/${transactionId}`, body);

export const getNrAct = (postCode) =>
  get(`${BASE_URI}${SERVICE_URI}/number/${postCode}`);

export const getVehiclePlateNr = (transactionId) =>
  get(`${BASE_URI}${SERVICE_URI}/vehicle/plate-number/${transactionId}`);
