import { genericService, get, post } from '../../../helpers/api';

export const SERVICE_URI = '/chat';
export const BASE_URI = '/api/v1/group';

export const { findOne, create, update, remove } = genericService(
  SERVICE_URI,
  BASE_URI,
);

export const findAllGroupsList = () => get(`${SERVICE_URI}${BASE_URI}/all`);

export const findByChatId = (id) => get(`${SERVICE_URI}${BASE_URI}/chat/${id}`);

export const setImage = (imageBase64, groupId) =>
  post(`${SERVICE_URI}${BASE_URI}/set-image/${groupId}`, imageBase64);
