import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import CountriesList from '../../../../components/admin/taxonomies/countries/CountriesList';

const Countries = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="countries">
      <Tabs.TabPane tab={t('entity.admin.country._plural')} key="countries">
        <CountriesList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default Countries;
