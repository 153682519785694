import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import RiskApplicantsList from '../../../../components/admin/taxonomies/riskApplicants/RiskApplicantsList';

const RiskApplicants = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="riskApplicants">
      <Tabs.TabPane
        tab={t('entity.admin.riskApplicants._plural')}
        key="riskApplicants"
      >
        <RiskApplicantsList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default RiskApplicants;
