import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Checkbox, notification, Popconfirm, Select, Col } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import RequiredLabel from '../../../services/utils/RequiredLabel';
import { stopTransactionTime } from '../../../services/transactions/transactions';
import { findAll as findAllReasons } from '../../../services/taxonomies/impound-car-reasons';
import useDictionaries from '../../../hooks/useDictionaries';

const dictionaries = {
  reasons: findAllReasons,
};

const DescriptionSelect = ({
  content,
  handleSelect,
  wasStopped,
  selectProps,
}) => {
  return (
    <div style={{ maxWidth: '400px' }}>
      <div>
        {wasStopped ? (
          <>
            <div>
              Acestă mașină deja a fost reținută, reținerea repetată va șterge
              efectul reținerii precedente.
            </div>
            <div>Doriți sa continuați?</div>
          </>
        ) : (
          ''
        )}
      </div>
      {
        // TODO: put <RequireLabel>
      }
      <RequiredLabel title="Selectați motivul reținerii:" marginTop="10px" />
      <Col span={24} style={{ marginTop: '10px' }}>
        <Select
          style={{ width: '100%' }}
          onChange={handleSelect}
          {...selectProps}
        >
          {content.map((option) => (
            <Select.Option key={option.id} value={option.name}>
              {`${option.name}`}
            </Select.Option>
          ))}
        </Select>
      </Col>
    </div>
  );
};

const ImpoundedCarCheckbox = ({
  transactionId,
  checked,
  wasStopped,
  disabled,
  reload = () => {},
}) => {
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const [isChecked, setIsChecked] = useState(!!checked);
  const [selected, setSelected] = useState();
  const [{ reasons }] = useDictionaries(dictionaries, {
    allowFetcher: !isChecked,
  });
  const { content } = reasons;

  const title = useMemo(() => {
    return isChecked
      ? 'Sunteți sigur(ă) că doriți sa eliberați mașina?'
      : 'Sunteți sigur(ă) că doriți sa stopați mașina?';
  }, [isChecked]);

  const handleConfirm = useCallback(() => {
    setLoader(true);
    const reasonData = {
      transactionId,
      message: selected,
    };
    stopTransactionTime(reasonData)
      .then((res) => {
        res.json().then((data) => {
          setIsChecked(data);
        });
      })
      .catch(() => {
        notification.error({
          message: 'A apărut o eroare la efectuarea operațiunii',
        });
      })
      .finally(() => {
        setLoader(false);
        reload();
      });
  }, [transactionId, selected, reload]);

  useEffect(() => {
    if (!isChecked) {
      setSelected(null);
    }
  }, [isChecked]);
  const handleSelect = (val) => {
    setSelected(val);
  };

  return (
    <Popconfirm
      className="stopped__card-checkbox--popup"
      key="stop-car"
      disabled={disabled}
      placement="topLeft"
      description={
        !isChecked ? (
          <DescriptionSelect
            content={content?.filter((el) => !el.deleted)}
            wasStopped={wasStopped}
            disabled={disabled}
            handleSelect={handleSelect}
            selectProps={{
              allowClear: true,
              placeholder: 'Selectați motivul',
            }}
          />
        ) : null
      }
      okText="Ok"
      title={title}
      onConfirm={handleConfirm}
      okButtonProps={{ disabled: !selected && !isChecked }}
      cancelText={t('actions.cancel')}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '15px',
          marginBottom: '20px',
        }}
      >
        <Checkbox
          className="stopped__car-checkbox"
          checked={isChecked}
          disabled={disabled}
        >
          Mașină reținută
        </Checkbox>
        {loader ? (
          <LoadingOutlined
            style={{
              fontSize: 18,
              position: 'relative',
            }}
            spin
          />
        ) : null}
      </div>
    </Popconfirm>
  );
};

export default ImpoundedCarCheckbox;
