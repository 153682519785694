import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import { getBlobFile } from '../../../services/admin/files';
import ImageUploader from './ImageUploader';

const EditGroupPopup = ({
  initialImageId = null,
  groupName,
  setGroupName,
  onImageSelected = () => {},
  onImageClear = () => {},
}) => {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    if (initialImageId !== null) {
      getBlobFile(initialImageId)
        .then((res) => {
          setImageUrl(URL.createObjectURL(res));
        })
        .catch((error) => {
          console.error('Error fetching blob file:', error);
        });
    }
    return () => (imageUrl ? URL.revokeObjectURL(imageUrl) : () => {});
    // eslint-disable-next-line
  }, [initialImageId]);

  return (
    <div className="chat__group-name">
      <ImageUploader
        initial={imageUrl}
        onImageUpload={onImageSelected}
        onImageClear={onImageClear}
      />

      <div className="chat__group-input--name">
        <label>Denumirea grupei</label>

        <Input
          className="chat__group-input"
          onChange={(e) => setGroupName(e.target.value)}
          value={groupName}
        />
      </div>
    </div>
  );
};

export default EditGroupPopup;
