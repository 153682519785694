import React from 'react';
import { getColorHexCodeByName } from '../services/utils/colors';
import Icon from '../components/Icon';
import GradientCarIcon from './GradientCarIcon';

const CarColorChecker = ({ color, category, direction }) => {
  const colorCode = getColorHexCodeByName(color || 'NEGRU');

  return (
    <div
      className="car"
      style={{ transform: `${direction === 'IN' ? 'scale(-1, 1)' : ''}` }}
    >
      <div
        className="car__category"
        style={{
          transform: `${direction === 'IN' ? 'scale(-1, 1)' : ''}`,
        }}
      >
        {category}
      </div>
      {color === 'COMBINATA' || color === 'COMBINATĂ' ? (
        <GradientCarIcon category={category} />
      ) : (
        <Icon
          name={`${category}`}
          className="car__icon"
          style={{ fill: colorCode }}
        />
      )}
    </div>
  );
};

export default CarColorChecker;
