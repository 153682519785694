import React, { useCallback } from 'react';
import { ChatEntity } from './components/ChatEntity';
import EntityTypes from './enums/EntityTypes';
import ChatGroupPopup from './ChatGroupPopup';

const GroupsList = ({
  onSelect,
  selectedEntityIds,
  content,
  reload,
  setIsMenuVisible,
  rightSideChat,
  canCreate = true,
}) => {
  const handleSelectGroup = useCallback(
    (val) => {
      if (onSelect && typeof onSelect === 'function') {
        onSelect({ ...val, type: EntityTypes.GROUP });
      }
      if (rightSideChat) {
        setIsMenuVisible(false);
      }
    },
    [onSelect, setIsMenuVisible, rightSideChat],
  );

  const renderGroups = useCallback(() => {
    return content.map((chat) => {
      const group = chat.chatter || {};
      return (
        <ChatEntity
          key={chat.id}
          entityId={chat.id}
          onClick={() => handleSelectGroup(chat)}
          messageCount={chat.unreadMessages}
          entityName={group.name}
          isSelected={selectedEntityIds.includes(chat.id)}
          status={chat.status}
          entityType={EntityTypes.GROUP}
          base64Image={group.base64Image}
          imageId={group.imageId}
        />
      );
    });
  }, [content, selectedEntityIds, handleSelectGroup]);

  return (
    <>
      {canCreate ? <ChatGroupPopup reload={reload} /> : null}
      {content ? renderGroups() : null}
    </>
  );
};

export default GroupsList;
