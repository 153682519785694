import { genericService } from '../../../helpers/api';

export const SERVICE_URI = '/chat';
export const BASE_URI = '/api/v1/chat-management';
export const BASE_URI_MESSAGES = '/api/v1/chat-management/messages';

export const { findOne, findAll } = genericService(SERVICE_URI, BASE_URI);

export const { findAll: findAllMessages } = genericService(
  SERVICE_URI,
  BASE_URI_MESSAGES,
);
