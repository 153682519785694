import React, { useMemo } from 'react';
import { Button, Table, Tooltip } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Column from '../../../../helpers/Columns';
import DeleteItemIcon from '../../../buttons/DeleteItemIcon';

const MortuaryListTable = ({
  transactionId,
  loadingMortuaries,
  removeMortuary = () => {},
  content,
  reload,
  countries,
  handleChange = () => {},
  handleModalVisible = () => {},
  handleEditModal = () => {},
}) => {
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        title: 'Nume, Prenume',
        key: 'fullname',
        width: 50,
        sorter: (a, b) => a.fullname - b.fullname,
        render: (row) => {
          return <>{row.fullname?.toUpperCase()}</>;
        },
      },
      Column.text('personalCode', 'Nr. Pașaport', {
        width: 100,
      }),
      {
        sort: false,
        title: 'Țara de plecare',
        key: 'fromCountryId',
        width: 100,
        dataIndex: 'fromCountryId',
        render: (row) => {
          return countries?.content
            ?.find((el) => el.id === row)
            ?.name?.toUpperCase();
        },
      },
      {
        sort: false,
        title: 'Țara destinație',
        key: 'toCountryId',
        width: 100,
        dataIndex: 'toCountryId',
        render: (row) => {
          return countries?.content
            ?.find((el) => el.id === row)
            ?.name?.toUpperCase();
        },
      },
      Column.actions(t('table.actions'), (record) => (
        <span style={{ textAlign: 'right' }}>
          <Tooltip title={t('actions.edit')}>
            <Button
              shape="circle"
              type="link"
              icon={<EditOutlined />}
              onClick={() => {
                handleEditModal(record);
                handleModalVisible(true);
              }}
            />
          </Tooltip>
          <DeleteItemIcon
            title="Sunteți sigur(ă) că doriți să ștergeți înregistrarea?"
            item={record}
            action={removeMortuary}
            reload={reload}
            setLoadingLoader={loadingMortuaries}
          />
        </span>
      )),
    ],
    [
      t,
      countries?.content,
      removeMortuary,
      reload,
      handleModalVisible,
      handleEditModal,
      loadingMortuaries,
    ],
  );
  return (
    <>
      {transactionId && (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <span
              style={{
                fontWeight: 600,
                fontSize: '20px',
                lineHeight: '32px',
              }}
            >
              Mortuare
            </span>
            <Button
              key="mortuaryButton"
              icon={<PlusOutlined />}
              type="primary"
              onClick={() => {
                handleEditModal(null);
                handleModalVisible(true);
              }}
            >
              Adaugă mortuar
            </Button>
          </div>
          <Table
            columns={columns}
            rowKey="id"
            loading={loadingMortuaries}
            pagination={false}
            dataSource={content}
            onChange={handleChange}
          />
        </>
      )}
    </>
  );
};

export default MortuaryListTable;
