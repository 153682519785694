import React, { useCallback, useContext, useState } from 'react';
import { Button, Col, Form, Modal, notification, Popconfirm } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { WarningOutlined } from '@ant-design/icons';
import AppLoader from '../../auth/AppLoader';
import { addIrregularities } from '../../../services/transactions/transactions';
import AuthContext, { hasPermission } from '../../auth';

const IrregularitiesModal = ({
  transactionId,
  initialValue,
  readOnly,
  reload,
}) => {
  const [form] = Form.useForm();
  const [irregularitiesModal, setIrregularitiesModal] = useState(false);
  const [loadingLoader, setLoadingLoader] = useState(false);
  const { user } = useContext(AuthContext);
  const { permissions } = user;

  const handleOpenModal = () => {
    setIrregularitiesModal(true);
  };
  const handleIrregularitiesOK = useCallback(() => {
    setLoadingLoader(true);
    const value = form.getFieldValue('irregularitiesArea');
    addIrregularities({
      transactionId,
      message: value,
    })
      .then(() => {
        notification.success({
          message: 'Iregularitatea a fost adăugată',
          duration: 3,
        });
      })
      .catch(() => {
        notification.error({
          message: 'Iregularitatea nu a putut fi adăugată',
        });
      })
      .finally(() => {
        setLoadingLoader(false);
        reload();
        window.location.reload(true);
      });

    setIrregularitiesModal(false);
  }, [transactionId, reload, form]);

  return (
    <>
      <div className={`loaderOverlay ${loadingLoader ? 'active' : ''}`}>
        <AppLoader />
      </div>

      <Col xl={6} xxl={2} style={{ maxWidth: '100%' }}>
        <Popconfirm
          className="actions-column"
          placement="top"
          title={
            !readOnly
              ? 'Doriți să înscrieți o iregularitate?'
              : 'Doriți să vizualizați iregularitățile?'
          }
          onConfirm={handleOpenModal}
          okText="Da"
          cancelText="Nu"
        >
          <Button
            icon={<WarningOutlined />}
            type="primary"
            style={{
              width: '100%',
              marginBottom: '.5rem',
              color: 'white',
            }}
          >
            Iregularități (IR)
          </Button>
        </Popconfirm>
      </Col>

      <Form form={form} layout="vertical">
        <Modal
          maskClosable={false}
          centered
          title="Înregistrare iregularități"
          okButtonProps={{
            disabled:
              readOnly || !hasPermission(permissions, 'ADD_IRREGULARITIES'),
          }}
          visible={irregularitiesModal}
          onOk={handleIrregularitiesOK}
          onCancel={() => {
            form.resetFields();
            setIrregularitiesModal(false);
          }}
          okText="Salvează"
          cancelText="Înapoi"
        >
          <Form.Item
            name="irregularitiesArea"
            initialValue={initialValue}
            style={{ marginBottom: '25px' }}
          >
            <TextArea
              showCount
              maxLength={350}
              id="irregularity"
              rows={6}
              disabled={readOnly}
            />
          </Form.Item>
        </Modal>
      </Form>
    </>
  );
};

export default IrregularitiesModal;
