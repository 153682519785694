import React, { useContext, useState } from 'react';
import {
  Modal,
  Form,
  Tooltip,
  Button,
  Input,
  Divider,
  Popconfirm,
  notification,
  Row,
  Col,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useTranslation } from 'react-i18next';
import { FileTextOutlined } from '@ant-design/icons';
import CountryDropDown from '../../DropDownComponent';
import AuthContext from '../../auth';
import { savePVT } from '../../../services/admin/web-services/warehouse-transactions';
import { generatePdfInNewTab } from '../../../services/transactions/transactions';
import AppLoader from '../../auth/AppLoader';
import RequiredLabel from '../../../services/utils/RequiredLabel';
import {
  nameValidator,
  maxLengthValidator,
} from '../../../services/utils/validator/Validator';

const WarehouseDocumentModal = ({
  warehouseId,
  toVehicle,
  fromVehicle,
  document,
  reload = () => {},
  readOnly,
  initialVisible = false,
  allowGenerate = false, // only on readOnly mode
  onClose = () => {},
}) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(initialVisible);
  const [loadingLoader, setLoadingLoader] = useState(false);
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();

  const handleSave = () => {
    const data = form.getFieldsValue();
    const customPostCode = document?.customPostCode || user.customsPost?.code;
    const customPostName = document?.customPostName || user.customsPost?.name;
    savePVT({
      ...data,
      warehouseId: warehouseId || document?.warehouseId,
      customPostCode,
      customPostName,
      id: document?.id,
    })
      .then(() =>
        notification.success({ message: 'PVT a fost salvat cu succes' }),
      )
      .catch(() => notification.error({ message: 'PVT nu a fost salvat' }))
      .finally(() => {
        setVisible(false);
        reload();
      });
  };

  const handleGenerate = () => {
    setLoadingLoader(true);
    const data = form.getFieldsValue();
    const customPostCode = document?.customPostCode || user.customsPost?.code;
    const customPostName = document?.customPostName || user.customsPost?.name;
    generatePdfInNewTab(`/pvt-pdf/${warehouseId || document?.warehouseId}`, {
      ...data,
      id: document?.id,
      warehouseId: warehouseId || document?.warehouseId,
      customPostName,
      customPostCode,
    })
      .then(() => {
        notification.success({
          message: t('messages.report.generated.success'),
          duration: 3,
        });
      })
      .catch((err) => {
        if (err)
          notification.error({
            message: 'A apărut o eroare la generarea raportului',
          });
      })
      .finally(() => {
        reload();
        setLoadingLoader(false);
      });
  };

  const handleOpenModal = () => {
    setVisible(true);
  };

  const handleCloseModal = () => {
    form.resetFields();
    setVisible(false);
    onClose();
  };

  return (
    <>
      <div className={`loaderOverlay ${loadingLoader ? 'active' : ''}`}>
        <AppLoader />
      </div>
      {warehouseId ? (
        <Tooltip title="Permis Vamal de Transbordare">
          <Button
            shape="circle"
            type="link"
            icon={<FileTextOutlined />}
            onClick={handleOpenModal}
          />
        </Tooltip>
      ) : null}

      <Form
        form={form}
        layout="vertical"
        initialValues={{
          ...document,
          vehicle: fromVehicle || document?.vehicle,
          loadToVehicle: toVehicle || document?.loadToVehicle,
          customPost:
            document?.customPostCode && document?.customPostName
              ? `(${document?.customPostCode}) ${document?.customPostName}`
              : `(${user.customsPost?.code}) ${user.customsPost?.name}`,
        }}
      >
        <Modal
          width={900}
          centered
          title="Permis Vamal de Transbordare"
          visible={visible}
          onCancel={handleCloseModal}
          footer={[
            <Button key="back" onClick={handleCloseModal}>
              Înapoi
            </Button>,
            <Popconfirm
              key="generateReportPop"
              disabled={readOnly && !allowGenerate}
              placement="top"
              title="Sunteți sigur(ă) că doriți sa generați 'Permisul Vamal de Transbordare'?"
              onConfirm={handleGenerate}
              okText="Ok"
              cancelText={t('actions.cancel')}
            >
              <Button
                key="generatePVT"
                disabled={readOnly && !allowGenerate}
                style={{ marginLeft: '20px' }}
              >
                Generează PVT
              </Button>
            </Popconfirm>,
            warehouseId ? (
              <Popconfirm
                key="saveReportPop"
                disabled={readOnly}
                placement="topRight"
                title="Sunteți sigur(ă) că doriți sa salvați 'Permisul Vamal de Transbordare'?"
                onConfirm={handleSave}
                okText="Ok"
                cancelText={t('actions.cancel')}
              >
                <Button key="okModal" disabled={readOnly} type="primary">
                  Salvează
                </Button>
              </Popconfirm>
            ) : null,
          ]}
        >
          <Row gutter={15}>
            <Col span={12}>
              <Form.Item
                labelCol={{ span: 24 }}
                label="Subsemnatul"
                name="personName"
              >
                <Input
                  onChange={() => {
                    form.setFieldsValue({
                      personName: nameValidator(350),
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item labelCol={{ span: 24 }} label="IDNO" name="idno">
                <Input
                  onChange={(e) => {
                    form.setFieldsValue({
                      idno: maxLengthValidator(e.target.value, 20),
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col span={12}>
              <Form.Item
                labelCol={{ span: 24 }}
                label={<RequiredLabel title="Mijlocul de transport" />}
                name="vehicle"
              >
                <Input disabled style={{ textTransform: 'uppercase' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                labelCol={{ span: 24 }}
                label="Țara de înmatriculare"
                name="vehicleCountryId"
              >
                <CountryDropDown loadContent="true" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col span={12}>
              <Form.Item
                labelCol={{ span: 24 }}
                label="Destinația bunurilor"
                name="goodsDestination"
              >
                <Input
                  onChange={(e) => {
                    form.setFieldsValue({
                      goodsDestination: maxLengthValidator(e.target.value, 350),
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                labelCol={{ span: 24 }}
                label="Termenul de efectuare"
                name="executionTerm"
              >
                <Input
                  onChange={(e) => {
                    form.setFieldsValue({
                      executionTerm: maxLengthValidator(e.target.value, 100),
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            labelCol={{ span: 24 }}
            label="Locul transbordării"
            name="reloadingLocation"
          >
            <Input
              onChange={(e) => {
                form.setFieldsValue({
                  reloadingLocation: maxLengthValidator(e.target.value, 350),
                });
              }}
            />
          </Form.Item>
          <Divider plain> Postul Vamal </Divider>
          <Row gutter={15}>
            <Col span={12}>
              <Form.Item
                labelCol={{ span: 24 }}
                label={<RequiredLabel title="Postul vamal" />}
                name="customPost"
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                labelCol={{ span: 24 }}
                label="F.V."
                name="inspectorFunction"
              >
                <Input
                  onChange={(e) => {
                    form.setFieldsValue({
                      inspectorFunction: maxLengthValidator(
                        e.target.value,
                        100,
                      ),
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col span={12}>
              <Form.Item
                labelCol={{ span: 24 }}
                label="Transbordarea în mijlocul de transport"
                name="loadToVehicle"
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                labelCol={{ span: 24 }}
                label="Mențiuni"
                name="mention"
              >
                <Input
                  onChange={(e) => {
                    form.setFieldsValue({
                      mention: maxLengthValidator(e.target.value, 250),
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            style={{ marginBottom: 40 }}
            labelCol={{ span: 24 }}
            label="Note"
            name="note"
          >
            <TextArea showCount maxLength={1500} />
          </Form.Item>
        </Modal>
      </Form>
    </>
  );
};

export default WarehouseDocumentModal;
