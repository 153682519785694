import React from 'react';
import { Button } from 'antd';
import { SaveOutlined } from '@ant-design/icons';

const SaveButton = ({
  style,
  disabled = false,
  buttonText = 'Salvare date',
}) => {
  return (
    <Button
      style={style}
      type="primary"
      htmlType="submit"
      icon={<SaveOutlined />}
      disabled={disabled}
    >
      {buttonText}
    </Button>
  );
};

export default SaveButton;
