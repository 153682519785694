import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import DocumentTypePaymentsList from '../../../../components/admin/taxonomies/documentTypePayments/DocumentTypePaymentList';

const DocumentTypePayment = () => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="documentTypes">
      <Tabs.TabPane
        tab={t('entity.admin.documentType._plural')}
        key="documentTypes"
      >
        <DocumentTypePaymentsList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default DocumentTypePayment;
