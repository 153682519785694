import React from 'react';
import { Button, Popconfirm } from 'antd';

const CancelButton = ({ onClick, disabled, show = true }) => {
  return show ? (
    <Popconfirm
      placement="topLeft"
      title="Sunteți sigur?"
      onConfirm={onClick}
      okText="Ok"
      cancelText="Înapoi"
      disabled={disabled}
    >
      <Button
        className="cancellation__btn"
        style={{ marginTop: '10px' }}
        type="primary"
        block
        disabled={disabled}
      >
        Anulare rută
      </Button>
    </Popconfirm>
  ) : null;
};

export default CancelButton;
