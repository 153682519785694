import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  List,
  Modal,
  notification,
  Row,
  Typography,
} from 'antd';
import {
  PlayCircleOutlined,
  RightCircleOutlined,
  RollbackOutlined,
} from '@ant-design/icons';
import CancelButton from '../buttons/BackButton';
import { FormContextProvider } from '../../hooks/useFormContext';
import ImageSelect from '../ImageSelect';
import { findAll, getVideoPath } from '../../services/admin/vicos';
import useDatasource from '../../hooks/useDatasource';
import useFormErrors from '../../hooks/useFormErrors';

import { findAll as findCameras } from '../../services/admin/users/customs-security-cameras';
import VideoPlayer from '../VideoPlayer';

const { Text } = Typography;

const Vicos = ({
  transaction,
  errors,
  readOnly,
  moveNext = () => {},
  moveBack = () => {},
}) => {
  const [form] = Form.useForm();
  // const { user } = useContext(AuthContext);
  // const { permissions } = user;

  const [video, setVideo] = useState({});

  const handleNvigate = () => {
    moveNext();
  };

  const handler = useCallback(
    (...params) => {
      const param = params[0];
      param.criterias = {
        transactionId: transaction.id,
      };
      return findAll(param);
    },
    [transaction],
  );

  const { loading, pagination, content, handleChange } = useDatasource(handler);

  useFormErrors(form, errors);

  const onChangePage = (page) => {
    const pag = {
      ...pagination,
      current: page,
    };
    const sorter = false;
    const filters = { transactionId: [transaction.id] };

    handleChange(pag, filters, sorter);
  };

  const [cameras, setCameras] = useState(null);

  useEffect(() => {
    findCameras({ page: 0, pageSize: 10000 }).then((value) =>
      setCameras(value),
    );
  }, []);

  const getCamera = (id) => {
    if (cameras) {
      return Object.values(cameras.content).filter(
        (el) => el.uuid?.toUpperCase() === id?.toUpperCase(),
      )[0];
    }
    return { description: '' };
  };

  const handleGetVideoInfo = useCallback((videoId) => {
    if (videoId) {
      getVideoPath(videoId)
        .then(setVideo)
        .catch((err) => {
          if (err) {
            notification.error({
              message: 'A apărut o eroare la extragerea videoclipului',
            });
          }
        });
    }
  }, []);

  return (
    <>
      <FormContextProvider form={form}>
        <Form form={form} layout="vertical">
          <Row>
            <Col span={24}>
              <Text strong>Imagini disponibile</Text>
              <List
                itemLayout="vertical"
                grid={{
                  gutter: 16,
                  column: 5,
                }}
                pagination={{
                  onChange: onChangePage,
                  pageSize: pagination.pageSize,
                  total: pagination.total,
                }}
                loading={loading}
                dataSource={content}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <Button
                        key="play-btn"
                        onClick={() => handleGetVideoInfo(item?.videoId)}
                        shape="circle"
                        type="link"
                        icon={<PlayCircleOutlined />}
                        hidden={!item?.videoId}
                      >
                        Video
                      </Button>,
                      // hasPermission(permissions, 'VICOSES_DEL') && (
                      //   <DeleteItemIcon
                      //     key={item.id}
                      //     title="Remove image"
                      //     message="Image deleted"
                      //     item={item}
                      //     action={remove}
                      //     reload={reload}
                      //     disabled={readOnly}
                      //   />
                      // ),
                    ]}
                  >
                    {`${getCamera(item.cameraId)?.description || ''} ${
                      item.createdAtStr || ''
                    }`}
                    <Form.Item name={item.id}>
                      <ImageSelect
                        url={`data:${item.imageType};base64,${item.image}`}
                      />
                    </Form.Item>
                  </List.Item>
                )}
              />
            </Col>
            <Row style={{ gap: '10px' }}>
              <CancelButton disabled={readOnly} onCancel={moveBack} />
              <Button
                type="primary"
                icon={<RightCircleOutlined />}
                onClick={handleNvigate}
              >
                Înainte
              </Button>
            </Row>
          </Row>
        </Form>
      </FormContextProvider>
      {video?.path ? (
        <Modal
          title="Video"
          visible={video?.path}
          onCancel={() => setVideo({})}
          cancelText="Înapoi"
          cancelButtonProps={{ icon: <RollbackOutlined /> }}
          okButtonProps={{ hidden: true }}
          width={730}
          centered
        >
          <VideoPlayer path={video?.path} autoPlay width="650px" />
        </Modal>
      ) : null}
    </>
  );
};

export default Vicos;
