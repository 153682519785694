import React, { useContext, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Layout, Menu, Tooltip } from 'antd';
import {
  AlertOutlined,
  AuditOutlined,
  BranchesOutlined,
  CloudDownloadOutlined,
  CompassOutlined,
  DatabaseOutlined,
  FileDoneOutlined,
  FilePdfOutlined,
  FileProtectOutlined,
  FileSearchOutlined,
  FileSyncOutlined,
  FileTextOutlined,
  FireOutlined,
  FundProjectionScreenOutlined,
  FundViewOutlined,
  HomeOutlined,
  IssuesCloseOutlined,
  MessageOutlined,
  ProfileOutlined,
  ScheduleOutlined,
  SettingOutlined,
  SmileOutlined,
  SwapOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  WalletOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import Draggable from 'react-draggable';
import AuthContext, { hasPermission } from './auth';
import ExchangeRates from './admin/exchangeRates/ExchangeRates';
import Calculator from '../services/utils/calculator/Calculator';
import Icon from './Icon';
import { useClickOutside } from '../hooks/useClickOutside';
import UserFunctions from '../enums/UserFunctions';

const Sidebar = ({ collapsed, setCollapsed }) => {
  const { Sider } = Layout;
  const { user } = useContext(AuthContext);
  const { permissions } = user || { permissions: [] };
  const [exchange, setExchange] = useState(false);
  const [versionTooltip, setVersionTooltip] = useState(false);
  const handleVersionTooltip = () => {
    setVersionTooltip((prev) => !prev);
  };
  const closeTooltip = () => {
    setVersionTooltip(false);
  };
  const outside = useClickOutside(closeTooltip, versionTooltip);

  const { t } = useTranslation();
  const currentVersion = localStorage.getItem('client_version');
  const history = useHistory();
  const handleExchange = () => {
    setExchange((prev) => !prev);
  };

  const { pathname } = useLocation();

  const selectedKeys = useMemo(
    () =>
      pathname.split('/').map((_, idx, arr) => arr.slice(0, idx + 1).join('/')),
    [pathname],
  );

  const defaultOpenKeys = useMemo(
    () =>
      pathname
        .split('/')
        .reverse()
        .slice(1)
        .reverse()
        .map((_, idx, arr) => arr.slice(0, idx + 1).join('/')),
    [pathname],
  );

  const handleSelect = ({ key }) => key && history.push(key);

  return (
    <>
      {user?.id ? (
        <>
          <Draggable handle="ul">
            <div className={`exchange ${exchange ? 'active' : ''}`}>
              <button
                type="submit"
                className="calculator__close"
                onClick={(prev) => setExchange(!prev)}
              >
                <Icon
                  name="close"
                  className="calculator__close-icon"
                  width="10"
                  height="10"
                />
              </button>
              <ul className="exchange__drag" />
              <ExchangeRates />
            </div>
          </Draggable>
        </>
      ) : null}
      <Sider
        className="vertical__sidebar-menu"
        collapsible
        collapsed={collapsed}
        onCollapse={() => setCollapsed((prev) => !prev)}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
        }}
      >
        <Menu
          mode="inline"
          style={{
            height: '100vh',
            borderRight: 0,
            paddingTop: '64px',
            overflowX: 'hidden',
            overflow: 'auto',
            position: 'relative',
          }}
          defaultOpenKeys={(defaultOpenKeys, ['exchange'])}
          selectedKeys={selectedKeys}
          onClick={handleSelect}
        >
          {hasPermission(permissions, 'SEARCH_TRANSACTIONS') && (
            <Menu.Item
              key="/home"
              icon={
                <Icon
                  name="transactionIcon"
                  style={{
                    width: '20px',
                    height: '20px',
                  }}
                />
              }
            >
              {t('nav.transactions')}
            </Menu.Item>
          )}
          {hasPermission(permissions, 'SEARCH_OPERATIONS') && (
            <Menu.Item key="/work-history" icon={<FileDoneOutlined />}>
              {t('nav.workHistory')}
            </Menu.Item>
          )}
          {hasPermission(permissions, 'SHIFT_REPORT_MANAGEMENT') && (
            <Menu.Item key="/shift-report" icon={<FileProtectOutlined />}>
              {t('nav.shiftReport')}
            </Menu.Item>
          )}
          {(hasPermission(permissions, 'RISK_MANAGEMENT') ||
            hasPermission(permissions, 'SEARCH_VICOSES') ||
            hasPermission(permissions, 'RISK_ANALYSIS') ||
            hasPermission(permissions, 'RISK_VIEWS') ||
            hasPermission(permissions, 'FINANCE_MANAGEMENT') ||
            hasPermission(permissions, 'CREATE_TRANSACTION')) && (
            <Menu.SubMenu
              key="/lucrative"
              icon={<AlertOutlined />}
              title={t('nav.lucrative._')}
            >
              {(user?.posts?.some((item) => item.allowSARA) ||
                user?.customsPost?.allowSARA ||
                user?.function === UserFunctions.CENTRAL ||
                user?.function === UserFunctions.OFFICE_HEAD) &&
                hasPermission(permissions, 'RISK_MANAGEMENT') && (
                  <Menu.Item
                    key="/lucrative/risk-management"
                    icon={<FireOutlined />}
                  >
                    {t('nav.lucrative.sara')}
                  </Menu.Item>
                )}
              {(user?.posts?.some((item) => item.allowSARA) ||
                user?.customsPost?.allowSARA ||
                user?.function === UserFunctions.CENTRAL ||
                user?.function === UserFunctions.OFFICE_HEAD) &&
                hasPermission(permissions, 'RISK_MANAGEMENT_ACTION') && (
                  <Menu.Item
                    key="/lucrative/risk-actions"
                    icon={<FundViewOutlined />}
                  >
                    {t('nav.lucrative.sara-actions')}
                  </Menu.Item>
                )}
              {hasPermission(permissions, 'RISK_ANALYSIS') && (
                <Menu.Item
                  key="/lucrative/risk-analysis/physical-persons"
                  icon={<WarningOutlined />}
                >
                  {t('nav.lucrative.risk-analysis')}
                </Menu.Item>
              )}
              {hasPermission(permissions, 'FINANCE_MANAGEMENT') && (
                <Menu.Item
                  key="/lucrative/documents"
                  icon={<FileTextOutlined />}
                >
                  Extrase de cont
                </Menu.Item>
              )}
              {hasPermission(permissions, 'FINANCE_MANAGEMENT') && (
                <Menu.Item key="/lucrative/balance" icon={<WalletOutlined />}>
                  Solduri Persoane Juridice
                </Menu.Item>
              )}
              {hasPermission(permissions, 'RISK_VIEWS') && (
                <Menu.Item
                  key="/lucrative/transaction-risks"
                  icon={<IssuesCloseOutlined />}
                >
                  {t('nav.lucrative.transaction-risks')}
                </Menu.Item>
              )}
              {hasPermission(permissions, 'CREATE_TRANSACTION') && (
                <Menu.Item key="/lucrative/mdua" icon={<ProfileOutlined />}>
                  {t('nav.lucrative.mdua')}
                </Menu.Item>
              )}
              {hasPermission(permissions, 'CREATE_TRANSACTION') && (
                <Menu.Item
                  key="/lucrative/crossing-history"
                  icon={<BranchesOutlined />}
                >
                  {t('nav.lucrative.crossing-history')}
                </Menu.Item>
              )}
              {hasPermission(permissions, 'SPECIAL_SERVICES_REQUESTS') && (
                <Menu.Item
                  key="/lucrative/special-services-requests"
                  icon={<FileSearchOutlined />}
                >
                  Cereri servicii speciale
                </Menu.Item>
              )}
            </Menu.SubMenu>
          )}
          {hasPermission(permissions, 'DECLARATION_TRAVELER') && (
            <Menu.Item key="/declaration" icon={<FileTextOutlined />}>
              Declarația călătorului
            </Menu.Item>
          )}
          {hasPermission(permissions, 'PAYMENT_NOTICE') && (
            <Menu.Item
              key="/payments"
              icon={
                <Icon
                  name="paymentNotice"
                  style={{
                    width: '18px',
                    height: '18px',
                  }}
                />
              }
            >
              {t('nav.paymentNotice')}
            </Menu.Item>
          )}
          {hasPermission(permissions, 'CORRECT_DAYS_VIEW') && (
            <Menu.Item key="/correct-days" icon={<FileSyncOutlined />}>
              {t('nav.daysCorrection')}
            </Menu.Item>
          )}
          {hasPermission(permissions, 'WAREHOUSE_ADD') && (
            <Menu.Item key="/warehouse" icon={<HomeOutlined />}>
              {t('nav.warehouse')}
            </Menu.Item>
          )}
          {hasPermission(permissions, 'SIDEBAR_INTERCONNECTIONS') && (
            <Menu.SubMenu
              key="/interconnections"
              icon={<CompassOutlined />}
              title={t('nav.interconnections')}
            >
              {hasPermission(permissions, 'SEARCH_ORGANIZATIONS') && (
                <Menu.Item
                  key="/interconnections/sfs-rsud"
                  icon={<CompassOutlined />}
                >
                  {t('nav.sfsRsud')}
                </Menu.Item>
              )}
              {hasPermission(permissions, 'SEARCH_PERSONS') && (
                <Menu.Item
                  key="/interconnections/sfs-rsp"
                  icon={<CompassOutlined />}
                >
                  {t('nav.sfsRsp')}
                </Menu.Item>
              )}
              {hasPermission(
                permissions,
                'SEARCH_IRRESPONSIBLE_ORGANIZATIONS',
              ) && (
                <Menu.Item
                  key="/interconnections/sfs-irresponsibles"
                  icon={<CompassOutlined />}
                >
                  {t('nav.sfsIrresponsibles')}
                </Menu.Item>
              )}
              {hasPermission(permissions, 'SEARCH_ORGANIZATIONS_DEBTS') && (
                <Menu.Item
                  key="/interconnections/sfs-rsud-debts"
                  icon={<CompassOutlined />}
                >
                  {t('nav.sfsRsudDebts')}
                </Menu.Item>
              )}
              {hasPermission(permissions, 'SEARCH_DEBTS') && (
                <Menu.Item
                  key="/interconnections/sfs-debts"
                  icon={<CompassOutlined />}
                >
                  {t('nav.sfsDebts_')}
                </Menu.Item>
              )}
              {hasPermission(permissions, 'SEARCH_TRANSPORT_AUTHORIZATION') && (
                <Menu.Item
                  key="/interconnections/anta-transport-authorization"
                  icon={<CompassOutlined />}
                >
                  {t('nav.antaTransportAuthorization_')}
                </Menu.Item>
              )}
            </Menu.SubMenu>
          )}
          <Menu.SubMenu
            key="/admin"
            icon={<SettingOutlined />}
            title={t('nav.admin._')}
          >
            {hasPermission(permissions, 'IMPORT_TRANSACTIONS') && (
              <Menu.Item key="/import-transactions" icon={<UploadOutlined />}>
                {t('nav.imporTransactions')}
              </Menu.Item>
            )}
            {hasPermission(permissions, 'SIDEBAR_MANUAL_INTERPELLATION') && (
              <Menu.Item key="/wsm" icon={<CloudDownloadOutlined />}>
                {t('nav.wsmName')}
              </Menu.Item>
            )}
            {hasPermission(permissions, 'SCHEDULER_MANAGEMENT') && (
              <Menu.Item
                key="/web-services-scheduler"
                icon={<ScheduleOutlined />}
              >
                {t('nav.webServicesScheduler')}
              </Menu.Item>
            )}
            {hasPermission(permissions, 'USERS_MANAGEMENT') && (
              <Menu.Item key="/admin/users" icon={<UserOutlined />}>
                {t('nav.admin.users')}
              </Menu.Item>
            )}

            {hasPermission(permissions, 'POSTS_MANAGEMENT') && (
              <Menu.Item key="/admin/customs-posts" icon={<SwapOutlined />}>
                {t('nav.admin.customsPostsAndOffices')}
              </Menu.Item>
            )}
            {hasPermission(permissions, 'SEARCH_VICOSES') && (
              <Menu.Item key="/admin/vicos" icon={<VideoCameraOutlined />}>
                VICOS
              </Menu.Item>
            )}
            {hasPermission(permissions, 'CHAT_MANAGEMENT') && (
              <Menu.Item
                key="/admin/chat-management"
                icon={<MessageOutlined />}
              >
                {t('nav.admin.chat-management')}
              </Menu.Item>
            )}
            {hasPermission(permissions, 'USERS_CHANGE_PASSWORD') && (
              <Menu.Item key="/admin/user/password" icon={<SmileOutlined />}>
                Date personale
              </Menu.Item>
            )}
            {hasPermission(permissions, 'TAXONOMIES_MANAGEMENT') && (
              <Menu.Item key="/admin/taxonomies" icon={<DatabaseOutlined />}>
                {t('nav.admin.taxonomies')}
              </Menu.Item>
            )}
            {hasPermission(permissions, 'REPORT_MANAGEMENT') && (
              <Menu.Item key="/admin/reports" icon={<FilePdfOutlined />}>
                {t('nav.admin.reports')}
              </Menu.Item>
            )}

            <Menu.Item
              key="/admin/guides"
              icon={<FundProjectionScreenOutlined />}
            >
              {t('nav.admin.guide')}
            </Menu.Item>

            {hasPermission(permissions, 'AUDIT_VIEW') && (
              <Menu.Item key="/admin/audit" icon={<AuditOutlined />}>
                {t('nav.admin.audit')}
              </Menu.Item>
            )}
          </Menu.SubMenu>

          <Calculator collapsed={collapsed} />

          {collapsed ? (
            <Tooltip title="Curs valutar" placement="right">
              <button
                type="button"
                className="menu__exchange-table"
                onClick={handleExchange}
              >
                <div className="menu__exchange-title">
                  <Icon
                    name="exchange"
                    className="menu__exchange-icon"
                    width="15"
                    height="15"
                  />
                </div>
              </button>
            </Tooltip>
          ) : (
            <Menu.SubMenu
              key="exchange"
              icon={
                <Icon
                  name="exchange"
                  className="menu__exchange-icon"
                  width="15"
                  height="15"
                />
              }
              title="Curs valutar"
            >
              <div style={{ marginTop: '10px' }}>
                <ExchangeRates collapsed={collapsed} />
              </div>
            </Menu.SubMenu>
          )}

          <div className={`app__version ${collapsed ? 'cpllapsed' : ''}`}>
            <Tooltip
              className="app__version-tooltip"
              visible={versionTooltip}
              title="Versiunea aplicației"
              placement="topLeft"
            >
              <button
                type="button"
                ref={outside}
                onClick={handleVersionTooltip}
                className={`app__version-tooltip--btn ${
                  versionTooltip ? 'disabled' : ''
                }`}
              >
                <Icon
                  name="info"
                  className="app__version-tooltip--icon"
                  style={{ width: '15px', height: '15px' }}
                />
              </button>
            </Tooltip>
            <div className="app__version-info">v{currentVersion}</div>
          </div>
        </Menu>
      </Sider>
    </>
  );
};

export default Sidebar;
