import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Table, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { EyeOutlined } from '@ant-design/icons';
import { findAll as findAllConsemne } from '../../../services/risks/consemne';
import useDatasource from '../../../hooks/useDatasource';
import Column from '../../../helpers/Columns';
import { useTableScroll } from '../../../hooks/useTableScroll';
import { Directions } from '../../../enums/Directions';
import { RiskSearchTypes } from './RiskSearchTypes';
import IdentifiedRisksViewModal from '../../admin/modals/IdentifiedRisksViewModal';

const TransactionRisksList = ({ searchType }) => {
  const { t } = useTranslation();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentTransactionId, setCurrentTransactionId] = useState(null);

  const showModal = useCallback((transactionId) => {
    setCurrentTransactionId(transactionId);
    setIsModalVisible(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalVisible(false);
    setCurrentTransactionId(null);
  }, []);

  const handler = useCallback(
    (...params) => {
      const spec = params[0];
      if (!spec?.sort) {
        spec.sort = ['id', 'desc'];
      }
      spec.criterias = {
        ...spec.criterias,
      };

      if (searchType === RiskSearchTypes.PHYSICAL_PERSONS) {
        spec.criterias.searchPhysicalPerson = true;
      }
      if (searchType === RiskSearchTypes.LEGAL_PERSONS) {
        spec.criterias.searchLegalPerson = true;
      }
      if (searchType === RiskSearchTypes.TRANSPORT_UNITS) {
        spec.criterias.searchTransportUnit = true;
      }
      return findAllConsemne(spec);
    },
    [searchType],
  );

  const { loading, pagination, content, handleChange, reload } =
    useDatasource(handler);

  useEffect(() => {
    if (searchType !== RiskSearchTypes.NONE) {
      reload();
    }
  }, [reload, searchType]);

  useTableScroll();

  const columns = useMemo(() => {
    const personColumns =
      searchType === RiskSearchTypes.PHYSICAL_PERSONS
        ? [
            Column.text('idnp', t('entity.lucrative.transaction-risks.idnp'), {
              width: 150,
              filter: true,
            }),
            Column.text(
              'lastName',
              t('entity.lucrative.transaction-risks.lastName'),
              {
                width: 150,
                filter: true,
              },
            ),
            Column.text(
              'firstName',
              t('entity.lucrative.transaction-risks.firstName'),
              {
                width: 150,
                filter: true,
              },
            ),
          ]
        : [];

    const companyColumns =
      searchType === RiskSearchTypes.LEGAL_PERSONS
        ? [
            Column.text('idno', t('entity.lucrative.transaction-risks.idno'), {
              width: 150,
              filter: true,
            }),
            Column.text('name', t('entity.lucrative.transaction-risks.name'), {
              width: 150,
              filter: true,
            }),
          ]
        : [];

    const vehicleColumns =
      searchType === RiskSearchTypes.TRANSPORT_UNITS
        ? [
            Column.text(
              'vinCode',
              t('entity.lucrative.transaction-risks.vinCode'),
              {
                width: 150,
                filter: true,
              },
            ),
            Column.text(
              'vehicleNumber',
              t('entity.lucrative.transaction-risks.vehicleNumber'),
              {
                width: 150,
                filter: true,
              },
            ),
          ]
        : [];

    return [
      Column.text('id', t('entity.lucrative.transaction-risks.id'), {
        width: 100,
      }),
      Column.text(
        'transactionId',
        t('entity.lucrative.transaction-risks.transactionId'),
        {
          width: 100,
          filter: true,
        },
      ),
      Column.text(
        'thirdPartyID',
        t('entity.lucrative.transaction-risks.thirdPartyID'),
        {
          width: 100,
          filter: true,
        },
      ),
      Column.text(
        'riskLevelDescription',
        t('entity.lucrative.transaction-risks.riskLevelDescription'),
        {
          width: 150,
          filter: true,
        },
      ),
      Column.bool(
        'frontiera',
        t('entity.lucrative.transaction-risks.frontiera'),
        {
          width: 100,
          filter: true,
          inverted: false,
          labels: [
            t('table.filter.all'),
            'DA',
            'NU',
            t('entity._.deleted.unknown'),
          ],
        },
      ),
      Column.bool('sms', t('entity.lucrative.transaction-risks.sms'), {
        width: 150,
        filter: true,
        inverted: false,
        labels: [
          t('table.filter.all'),
          'DA',
          'NU',
          t('entity._.deleted.unknown'),
        ],
      }),
      Column.other(
        'direction',
        t('entity.lucrative.transaction-risks.direction'),
        (code, row) => {
          return <>{Directions[row.direction] || row.direction}</>;
        },
        {
          width: 100,
        },
      ),
      ...personColumns,
      ...companyColumns,
      ...vehicleColumns,
      Column.other(
        'action',
        t('entity.lucrative.transaction-risks.action'),
        (code, row) => {
          let action = row.action || ' ';
          if (action?.length > 50) {
            action = `${action.substring(0, 30)}...`;
          }
          return <Tooltip title={row.action}>{action}</Tooltip>;
        },
        {
          filter: true,
          width: 150,
        },
      ),
      Column.date(
        'validFrom',
        t('entity.lucrative.transaction-risks.validFrom'),
        {
          width: 150,
          format: 'DD-MM-YYYY HH:mm:ss',
        },
      ),
      Column.date('validTo', t('entity.lucrative.transaction-risks.validTo'), {
        width: 150,
        format: 'DD-MM-YYYY HH:mm:ss',
      }),
      Column.date(
        'createdAt',
        t('entity.lucrative.transaction-risks.createdAt'),
        {
          width: 170,
          format: 'DD-MM-YYYY HH:mm:ss',
        },
      ),
      Column.text(
        'createdBy',
        t('entity.lucrative.transaction-risks.createdBy'),
        {
          width: 170,
        },
      ),
      Column.actions(t('table.actions'), (record) => (
        <span style={{ textAlign: 'right' }}>
          <Tooltip title={t('actions.view')}>
            <Button
              shape="circle"
              type="link"
              icon={<EyeOutlined />}
              onClick={() => showModal(record.transactionId)}
            />
          </Tooltip>
        </span>
      )),
    ];
  }, [searchType, t, showModal]);

  return (
    <>
      {searchType !== RiskSearchTypes.NONE ? (
        <Table
          rowKey="id"
          loading={loading}
          columns={columns}
          dataSource={content}
          onChange={handleChange}
          pagination={pagination}
        />
      ) : (
        <Table rowKey="id" columns={columns} />
      )}
      <IdentifiedRisksViewModal
        searchId={currentTransactionId}
        visible={isModalVisible}
        onClose={closeModal}
      />
    </>
  );
};

export default TransactionRisksList;
