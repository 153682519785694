import React, { useEffect, useState } from 'react';
import { Form, Button } from 'antd';
import 'flag-icons/css/flag-icons.min.css';
import CurrencyFlag from 'react-currency-flags';
import { ClearOutlined } from '@ant-design/icons';
import { getValutes } from '../../../services/admin/exchange-rates/exchange-rates';
import { FormContextProvider } from '../../../hooks/useFormContext';
import InputDebounce from '../../InputDebounce';
import { dotValidator } from '../../../services/utils/validator/Validator';
import { findAll as findAllConstants } from '../../../services/taxonomies/constants';
import useDictionaries from '../../../hooks/useDictionaries';
import RateStatus from '../../../enums/ExchangeRatesStatuses';
import Icon from '../../Icon';

const dictionaries = {
  constants: findAllConstants,
};

const ExchangeRates = () => {
  const [form] = Form.useForm();
  const [allowedValutes, setAllowedValutes] = useState([]);
  const [valuesRates, setValuesRates] = useState([]);
  const [{ constants }] = useDictionaries(dictionaries);

  useEffect(() => {
    const valutes =
      constants?.content
        ?.find((el) => el.code === 'EXCHANGE_RATES_VALUTES')
        ?.value?.toUpperCase()
        .split(',')
        .map((el) => el.trim()) || [];

    setAllowedValutes(valutes);
  }, [constants?.content]);

  useEffect(() => {
    const result = valuesRates.reduce((acc, curr) => {
      if (curr.value) {
        acc[curr.charCode] = curr.value;
      }
      return acc;
    }, {});

    form.setFieldsValue(result);
  }, [form, valuesRates]);

  const handleGetValutes = (value, code) => {
    const val = String(value).replace(/[,]/g, '.');
    const pattern = /[^\d.]+/g;
    if (pattern.test(val)) {
      return;
    }
    if (val && code) {
      const data = { code, quantity: val };
      getValutes(data)
        .then((res) => setValuesRates(res))
        .catch()
        .finally();
    }
  };

  useEffect(() => {
    handleGetValutes(1000, 'MDL');
    form.setFieldsValue({ MDL: 1000 });
    // eslint-disable-next-line
  }, []);

  const handleClearForm = () => {
    form.resetFields();
  };

  const renderStatusArrow = (code) => {
    const status = valuesRates?.find((el) => el.charCode === code)?.rateStatus;

    if (status === RateStatus.INCREASE) {
      return (
        <Icon
          name="exchangeArrow"
          className="currency__arrow increase"
          width="10"
          height="10"
        />
      );
    }
    if (status === RateStatus.DECREASE) {
      return (
        <Icon
          name="exchangeArrow"
          className="currency__arrow decrease"
          width="10"
          height="10"
        />
      );
    }
    if (status === RateStatus.NO_CHANGES) {
      return (
        <Icon
          name="exchangeNochange"
          className="currency__arrow nochange"
          width="10"
          height="10"
        />
      );
    }
    return null;
  };

  const getRate = (code) => {
    const rate = valuesRates?.find((el) => el.charCode === code)?.rate;
    return rate || null;
  };

  const renderInputs = () => {
    return allowedValutes.map((currency) => (
      <div key={`div${currency}`} className="exchange__content">
        <div
          className="exchange__valute"
          style={{ display: 'flex', marginBottom: '10px' }}
        >
          <CurrencyFlag currency={`${currency}`} width={20} />
          <div className="currency__label">
            <div>{currency}</div>
            <span style={{ color: '#858B95', fontWeight: '600' }}>
              {getRate(currency)}
            </span>
          </div>
          {renderStatusArrow(currency)}
        </div>

        <Form.Item
          key={`item${currency}`}
          name={currency}
          style={{ marginLeft: 'auto', marginRight: '10px' }}
        >
          <InputDebounce
            onClick={() => {
              form.setFieldsValue({
                [currency]: null,
              });
            }}
            placeholder={currency}
            delay={250}
            onDebounceChange={(val) => handleGetValutes(val, currency)}
            onChange={(e) => {
              form.setFieldsValue({
                [currency]: dotValidator(e.target.value),
              });
            }}
          />
        </Form.Item>
      </div>
    ));
  };

  return (
    <>
      <div className="exchangeRates">
        <FormContextProvider form={form}>
          <Form form={form} layout="vertical" wrapperCol={{ span: 22 }}>
            {renderInputs()}
          </Form>
        </FormContextProvider>
      </div>
      {allowedValutes.length ? (
        <Button
          style={{ marginTop: '10px', float: 'right' }}
          type="primary"
          className="exhange__clear"
          icon={<ClearOutlined />}
          onClick={handleClearForm}
        >
          Curăță
        </Button>
      ) : null}
    </>
  );
};

export default ExchangeRates;
