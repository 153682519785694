import React, { useCallback } from 'react';
import { Timeline } from 'antd';
import dayjs from 'dayjs';

const CrossingsTimeline = ({ crossings, show }) => {
  const timeFormatter = (dateStr) => {
    if (dateStr) {
      return dayjs(dateStr).format('DD.MM.YYYY HH:mm:ss');
    }
    return '-';
  };

  const totalCrossingsColor = useCallback(() => {
    return crossings?.some((el) => !(el.vehicle && el.person)) ? 'red' : 'blue';
  }, [crossings]);

  return show ? (
    <Timeline id="vehicleDriverCrossings" mode="left">
      <Timeline.Item label="Traversări Vehicul" color={totalCrossingsColor()}>
        Traversări Șofer
      </Timeline.Item>
      {crossings && crossings.length > 0
        ? crossings.map((crossing) => (
            <Timeline.Item
              key={crossing.id}
              label={
                crossing.vehicle
                  ? `${timeFormatter(new Date(crossing.crossDate))}
                                  ${crossing.post ? `${crossing.post}` : ''} ${
                      crossing.personalCode
                    } ${crossing.fullName?.toUpperCase()} ${
                      crossing.direction === 'IN' ? '(INTRARE)' : '(IEȘIRE)'
                    }`
                  : '-'
              }
              color={crossing.vehicle && crossing.person ? 'blue' : 'red'}
            >
              {crossing.person
                ? `${timeFormatter(new Date(crossing.crossDate))} ${
                    crossing.post ? `${crossing.post}` : ''
                  } ${crossing.direction === 'IN' ? '(INTRARE)' : '(IEȘIRE)'}`
                : '-'}
            </Timeline.Item>
          ))
        : null}
    </Timeline>
  ) : null;
};

export default CrossingsTimeline;
