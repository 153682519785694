import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

import Column from '../../../helpers/Columns';

import EditItemIcon from '../../buttons/EditItemIcon';
import AuthContext, { hasPermission } from '../../auth';
import { useTableScroll } from '../../../hooks/useTableScroll';

const TaxonomiesList = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { permissions } = user;

  const content = [
    {
      id: 1,
      name: 'TAX_AUTHORIZATION_TYPES',
      title: t('taxonomies.TAX_AUTHORIZATION_TYPES'),
      path: 'authorization-types',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 2,
      name: 'TAX_COUNTRIES',
      title: t('taxonomies.TAX_COUNTRIES'),
      path: 'countries',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 3,
      name: 'TAX_DOCUMENT_STATUSES',
      title: t('taxonomies.TAX_DOCUMENT_STATUSES'),
      path: 'document-statuses',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 4,
      name: 'TAX_DOCUMENT_TYPES',
      title: t('taxonomies.TAX_DOCUMENT_TYPES'),
      path: 'document-types',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 5,
      name: 'TAX_LEGAL_FORMS',
      title: t('taxonomies.TAX_LEGAL_FORMS'),
      path: 'legal-forms',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 6,
      name: 'TAX_LICENSED_ACTIVITIES',
      title: t('taxonomies.TAX_LICENSED_ACTIVITIES'),
      path: 'licensed-activities',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 7,
      name: 'TAX_LOCALITIES',
      title: t('taxonomies.TAX_LOCALITIES'),
      path: 'localities',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 8,
      name: 'TAX_REGIONS',
      title: t('taxonomies.TAX_REGIONS'),
      path: 'regions',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 9,
      name: 'TAX_REGISTRATION_TYPES',
      title: t('taxonomies.TAX_REGISTRATION_TYPES'),
      path: 'registration-types',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 10,
      name: 'TAX_TRAFFIC_COUNTRIES',
      title: t('taxonomies.TAX_TRAFFIC_COUNTRIES'),
      path: 'traffic-countries',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 11,
      name: 'TAX_UNLICENSED_ACTIVITIES',
      title: t('taxonomies.TAX_UNLICENSED_ACTIVITIES'),
      path: 'unlicensed-activities',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 12,
      name: 'TAX_SERVICES_DESCRIPTION_ANTA',
      title: t('taxonomies.TAX_SERVICES_DESCRIPTION_ANTA'),
      path: 'services-description-anta',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 13,
      name: 'TAX_SERVICES_DESCRIPTION_ANSA',
      title: t('taxonomies.TAX_SERVICES_DESCRIPTION_ANSA'),
      path: 'services-description-ansa',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 14,
      name: 'TAX_VEHCILE_CATEGORIES',
      title: t('taxonomies.TAX_VEHCILE_CATEGORIES'),
      path: 'vehicle-category',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 15,
      name: 'TAX_MAPPING_SVM_POST_TO_SG_POST',
      title: t('taxonomies.TAX_MAPPING_SVM_POST_TO_SG_POST'),
      path: 'mapping-svm-post-to-sg-post',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 16,
      name: 'TAX_CONSTANTS',
      title: t('taxonomies.TAX_CONSTANTS'),
      path: 'constants',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 18,
      name: 'TAX_CUSTOMS_DOCUMENTS_TYPES',
      title: t('taxonomies.TAX_CUSTOMS_DOCUMENTS_TYPES'),
      path: 'customs-documents-type',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 19,
      name: 'TAX_CONTROL_LOCATIONS',
      title: t('taxonomies.TAX_CONTROL_LOCATIONS'),
      path: 'control-location',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 20,
      name: 'TAX_CONTROL_REASONS',
      title: t('taxonomies.TAX_CONTROL_REASONS'),
      path: 'control-reason',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 21,
      name: 'TAX_RETURN_REASONS',
      title: t('taxonomies.TAX_RETURN_REASONS'),
      path: 'return-reasons',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 22,
      name: 'TAX_WITHHOLDING_REASONS',
      title: t('taxonomies.TAX_WITHHOLDING_REASONS'),
      path: 'withholding-reasons',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 23,
      name: 'TAX_MAPPING_SVM_POST_TO_RO_POST',
      title: t('taxonomies.TAX_MAPPING_SVM_POST_TO_RO_POST'),
      path: 'mapping-svm-post-to-ro-post',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 24,
      name: 'TAX_ENDOWMENT',
      title: t('taxonomies.TAX_ENDOWMENT'),
      path: 'endowment',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 25,
      name: 'TAX_VEHICLE_TYPES',
      title: t('taxonomies.TAX_VEHICLE_TYPES'),
      path: 'vehicle-types',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 26,
      name: 'TAX_VIGNETTE_ERROR_CODES',
      title: t('taxonomies.TAX_VIGNETTE_ERROR_CODES'),
      path: 'vignette-error-code',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 27,
      name: 'TAX_VIGNETTE_TARIFF_POSITION',
      title: t('taxonomies.TAX_VIGNETTE_TARIFF_POSITION'),
      path: 'vignette-tariff-position',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 28,
      name: 'TAX_IMPOUND_CAR_REASONS',
      title: t('taxonomies.TAX_IMPOUND_CAR_REASONS'),
      path: 'impound-car-reasons',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 29,
      name: 'TAX_CUSTOMS_REGIME',
      title: t('taxonomies.TAX_CUSTOMS_REGIME'),
      path: 'customs-regime',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 30,
      name: 'TAX_RISK_APPLICANTS',
      title: t('taxonomies.TAX_RISK_APPLICANTS'),
      path: 'risk-applicants',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 31,
      name: 'TAX_RISK_LEVELS',
      title: t('taxonomies.TAX_RISK_LEVELS'),
      path: 'risk-levels',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 32,
      name: 'TAX_RISK_PERSONS',
      title: t('taxonomies.TAX_RISK_PERSONS'),
      path: 'risk-persons',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 33,
      name: 'TAX_RISK_TYPES',
      title: t('taxonomies.TAX_RISK_TYPES'),
      path: 'risk-types',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 34,
      name: 'TAX_MEASURING_UNITS',
      title: t('taxonomies.TAX_MEASURING_UNITS'),
      path: 'measuring-units',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 35,
      name: 'TAX_CUSTOMS_PAYMENTS',
      title: t('taxonomies.TAX_CUSTOMS_PAYMENTS'),
      path: 'customs-payments',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 36,
      name: 'TAX_DOCUMENT_TYPE_PAYMENT',
      title: t('taxonomies.TAX_DOCUMENT_TYPE_PAYMENT'),
      path: 'document-type-payment',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 37,
      name: 'TAX_TRAIN_TYPES',
      title: t('taxonomies.TAX_TRAIN_TYPES'),
      path: 'train-types',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 38,
      name: 'TAX_FINAL_DESTINATION',
      title: t('taxonomies.TAX_FINAL_DESTINATION'),
      path: 'final-destinations',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 39,
      name: 'TAX_FUEL_TYPE',
      title: t('taxonomies.TAX_FUEL_TYPE'),
      path: 'fuel-types',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 40,
      name: 'TAX_TRAVELER_DECLARATION_VEHICLE_CATEGORY',
      title: t('taxonomies.TAX_TRAVELER_DECLARATION_VEHICLE_CATEGORY'),
      path: 'vehicle-category-declarations',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
    {
      id: 40,
      name: 'TAX_VEHICLE_BRAND',
      title: t('taxonomies.TAX_VEHICLE_BRAND'),
      path: 'vehicle-brand',
      visible: hasPermission(permissions, 'TAXONOMIES_LIST'),
    },
  ];

  useTableScroll();

  const columns = useMemo(
    () => [
      Column.text('title', t('entity.admin.taxonomy.name'), {
        width: 100,
      }),
      Column.actions(t('table.actions'), (record) => (
        <span style={{ textAlign: 'right' }}>
          <EditItemIcon path={`/admin/taxonomies/${record.path}`} />
        </span>
      )),
    ],
    [t],
  );

  return (
    <>
      <PageHeader title={t('entity.admin.taxonomy._plural')} />
      <Table
        columns={columns}
        rowKey="id"
        dataSource={content
          .filter((el) => el.visible)
          .sort((a, b) => a.title.localeCompare(b.title))}
      />
    </>
  );
};

export default TaxonomiesList;
