import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Tabs } from 'antd';
import AuthContext, { hasPermission } from '../../../components/auth';
import LegalPersonsRisks from './legal-persons/LegalPersonsRisks';
import PhysicalPersonsRisks from './physical-persons/PhysicalPersonsRisks';
import TransportUnitsRisks from './transport-units/TransportUnitsRisks';

const RiskAnalysis = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { permissions } = user;
  const { key } = useParams();

  return (
    <Tabs defaultActiveKey={key}>
      {hasPermission(permissions, 'RISK_ANALYSIS') && (
        <Tabs.TabPane
          tab={t('entity.lucrative.physical-persons._plural')}
          key="physical-persons"
        >
          <PhysicalPersonsRisks />
        </Tabs.TabPane>
      )}
      {hasPermission(permissions, 'RISK_ANALYSIS') && (
        <Tabs.TabPane
          tab={t('entity.lucrative.legal-persons._plural')}
          key="legal-persons"
        >
          <LegalPersonsRisks />
        </Tabs.TabPane>
      )}
      {hasPermission(permissions, 'RISK_ANALYSIS') && (
        <Tabs.TabPane
          tab={t('entity.lucrative.transport-units._plural')}
          key="transport-units"
        >
          <TransportUnitsRisks />
        </Tabs.TabPane>
      )}
    </Tabs>
  );
};

export default RiskAnalysis;
