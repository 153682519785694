import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AuthContextWrapper, PrivateRoute } from './components/auth';
import {
  ANTATransportAuthorization,
  Customs,
  EditPersonPhysicalEntry,
  EditRole,
  EditUser,
  EditUserPassword,
  Home2,
  Login,
  NotFound,
  Scheduler,
  SFSDebts,
  SFSIresponsabili,
  SFSOrganizatii,
  SFSOrganizatiiDebts,
  SFSPersoane,
  ShiftReport,
  Users,
  WebServiceManagement,
  WorkHistory,
} from './pages';

import EditCustomsPost from './pages/admin/customsPosts/EditCustomsPost';
import EditCustomsOffice from './pages/admin/customsOffices/EditCustomsOffices';
import EditCustomsTrack from './pages/admin/customsTracks/EditCustomsTrack';
import ViewPermission from './pages/admin/users/ViewPermission';
import Taxonomies from './pages/admin/taxonomies/Taxonomies';
import Countries from './pages/admin/taxonomies/countries/Countries';
import EditCountry from './pages/admin/taxonomies/countries/EditCountry';
import Constants from './pages/admin/taxonomies/constants/Constants';
import EditConstants from './pages/admin/taxonomies/constants/EditConstants';
import AuthorizationTypes from './pages/admin/taxonomies/authorizationTypes/AuthorizationTypes';
import EditAuthorizationType from './pages/admin/taxonomies/authorizationTypes/EditAuthorizationType';
import DocumentStatuses from './pages/admin/taxonomies/documentStatuses/DocumentStatuses';
import EditDocumentStatus from './pages/admin/taxonomies/documentStatuses/EditDocumentStatus';
import DocumentTypes from './pages/admin/taxonomies/documentTypes/DocumentTypes';
import EditDocumentType from './pages/admin/taxonomies/documentTypes/EditDocumentType';
import DocumentTypePayment from './pages/admin/taxonomies/documentTypePayment/DocumentTypes';
import EditDocumentTypePayment from './pages/admin/taxonomies/documentTypePayment/EditDocumentTypePayment';
import LegalForms from './pages/admin/taxonomies/legalForms/LegalForms';
import EditLegalForm from './pages/admin/taxonomies/legalForms/EditLegalForm';
import EditLicensedActivity from './pages/admin/taxonomies/licensedActivities/EditLicensedActivity';
import LicensedActivities from './pages/admin/taxonomies/licensedActivities/LicensedActivities';
import Localities from './pages/admin/taxonomies/localities/Localities';
import EditLocality from './pages/admin/taxonomies/localities/EditLocality';
import Regions from './pages/admin/taxonomies/regions/Regions';
import EditRegion from './pages/admin/taxonomies/regions/EditRegion';
import RegistrationTypes from './pages/admin/taxonomies/registrationTypes/RegistrationTypes';
import EditRegistrationType from './pages/admin/taxonomies/registrationTypes/EditRegistrationType';
import TrafficCountries from './pages/admin/taxonomies/trafficCountries/TrafficCountries';
import EditTrafficCountry from './pages/admin/taxonomies/trafficCountries/EditTrafficCountry';
import UnlicensedActivities from './pages/admin/taxonomies/unlicensedActivities/UnlicensedActivities';
import EditUnlicensedActivity from './pages/admin/taxonomies/unlicensedActivities/EditUnlicensedActivity';
import EditServiceDescriptionANTA from './pages/admin/taxonomies/servicesDescriptionANTA/EditServiceDescriptionANTA';
import ServicesDescriptionANTA from './pages/admin/taxonomies/servicesDescriptionANTA/ServicesDescriptionANTA';
import EditServiceDescriptionANSA from './pages/admin/taxonomies/servicesDescriptionANSA/EditServiceDescriptionANSA';
import ServicesDescriptionANSA from './pages/admin/taxonomies/servicesDescriptionANSA/ServicesDescriptionANSA';
import EditVehicleCategory from './pages/admin/taxonomies/vehicleCategory/EditVehicleCategory';
import VehicleCategories from './pages/admin/taxonomies/vehicleCategory/VehicleCategories';
import EditVehicleType from './pages/admin/taxonomies/vehicleTypes/EditVehicleType';
import VehicleTypes from './pages/admin/taxonomies/vehicleTypes/VehicleTypes';
import EditControlLocation from './pages/admin/taxonomies/controlLocation/EditControlLocation';
import ControlLocations from './pages/admin/taxonomies/controlLocation/ControlLocations';
import EditControlReason from './pages/admin/taxonomies/controlReason/EditControlReason';
import ControlReasons from './pages/admin/taxonomies/controlReason/ControlReasons';
import EditCustomsDocumentsType from './pages/admin/taxonomies/customsDocumentsTypes/EditCustomsDocumentsType';
import CustomsDocumentsTypes from './pages/admin/taxonomies/customsDocumentsTypes/CustomsDocumentsTypes';
import EditMappingSvmPostToWeighingPost from './pages/admin/taxonomies/mappingSvmPostToWeighingPost/EditMappingSvmPostToWeighingPost';
import MappingSvmPostToWeighingPosts from './pages/admin/taxonomies/mappingSvmPostToWeighingPost/MappingSvmPostToWeighingPosts';
import EditMappingSvmPostToSgPost from './pages/admin/taxonomies/mappingSvmPostToSgPost/EditMappingSvmPostToSgPost';
import MappingSvmPostToSgPosts from './pages/admin/taxonomies/mappingSvmPostToSgPost/MappingSvmPostToSgPosts';
import EditMappingSvmPostToRoPost from './pages/admin/taxonomies/mappingSvmPostToRoPost/EditMappingSvmPostToRoPost';
import MappingSvmPostToRoPosts from './pages/admin/taxonomies/mappingSvmPostToRoPost/MappingSvmPostToRoPosts';
import CustomsTracks from './pages/admin/customsTracks/CustomsTracks';
import JasperReports from './pages/admin/reports/JasperReports';
import Guides from './pages/admin/guides/Guides';
import EmptyMpass from './layouts/EmptyMpass';
import EditPersonPhysicalEntryWeight from './pages/physical-person-entry/EditPersonPhysicalEntryWeight';
import EditPersonPhysicalEntryAnta from './pages/physical-person-entry/EditPersonPhysicalEntryAnta';
import EditPersonPhysicalEntryAnsa from './pages/physical-person-entry/EditPersonPhysicalEntryAnsa';
import EditPersonPhysicalEntryWarehouse from './pages/physical-person-entry/EditPersonPhysicalEntryWarehouse';
import EditPersonPhysicalEntryScan from './pages/physical-person-entry/EditPersonPhysicalEntryScan';
import EditPersonPhysicalEntryMDUA from './pages/physical-person-entry/EditPersonPhysicalEntryMDUA';
import AuditData from './pages/admin/audit/AuditData';
import ReturnReasons from './pages/admin/taxonomies/returnReasons/ReturnReasons';
import EditReturnReason from './pages/admin/taxonomies/returnReasons/EditReturnReasons';
import WithholdingReasons from './pages/admin/taxonomies/withholdingReasons/WithholdingReasons';
import EditWithholdingReasons from './pages/admin/taxonomies/withholdingReasons/EditWithholdingReasons';
import Endowment from './pages/admin/taxonomies/endowment/Endowment';
import EditEndowment from './pages/admin/taxonomies/endowment/EditEndowment';
import QRCodeServices from './components/web-services/QRCodeServices';
import Logout from './pages/Logout';
import EditClosingDocumentDeclaration from './pages/admin/taxonomies/closingDocumentDeclaration/EditClosingDocumentDeclaration';
import ClosingDocumentDeclaration from './pages/admin/taxonomies/closingDocumentDeclaration/ClosingDocumentDeclaration';
import EditVignetteErrorCode from './pages/admin/taxonomies/vignetteErrorCode/EditVignetteErrorCode';
import VignetteErrorCode from './pages/admin/taxonomies/vignetteErrorCode/VignetteErrorCode';
import VignetteTariffPosition from './pages/admin/taxonomies/vignetteTariffPosition/VignetteTariffPosition';
import EditVignetteTariffPosition from './pages/admin/taxonomies/vignetteTariffPosition/EditVignetteTariffPosition';
import ImpoundCarReason from './pages/admin/taxonomies/impoundCarReasons/ImpoundCarReason';
import EditImpoundCarReason from './pages/admin/taxonomies/impoundCarReasons/EditImpoundCarReason';
import CustomsRegime from './pages/admin/taxonomies/customsRegime/CustomsRegime';
import EditCustomsRegime from './pages/admin/taxonomies/customsRegime/EditCustomsRegime';
import { Default } from './layouts';
import EditDaysCorrections from './pages/admin/daysCorrections/EditDaysCorrections';
import DaysCorrections from './pages/admin/daysCorrections/DaysCorrections';
import Transshipments from './pages/admin/warehouse/Transshipments';
import EditTransshipment from './pages/admin/warehouse/EditTransshipment';
import RisksView from './pages/lucrative/risks/RisksView';
import ImportTransactions from './pages/import-transactions/ImportTransactions';
import PortalInfo from './components/phisical-person-entry/PortalInfo';
import RisksActions from './pages/lucrative/risks/RisksActions';
import RiskAnalysis from './pages/lucrative/risk-analysis/RiskAnalysis';
import LegalPersonsRisk from './pages/lucrative/risk-analysis/legal-persons/LegalPersonsRisk';
import TransportUnitsRisk from './pages/lucrative/risk-analysis/transport-units/TransportUnitsRisk';
import PhysicalPersonsRisk from './pages/lucrative/risk-analysis/physical-persons/PhysicalPersonsRisk';
import RiskApplicants from './pages/admin/taxonomies/riskApplicants/RiskApplicants';
import EditRiskApplicants from './pages/admin/taxonomies/riskApplicants/EditRiskApplicants';
import EditRiskLevels from './pages/admin/taxonomies/riskLevels/EditRiskLevels';
import RiskLevels from './pages/admin/taxonomies/riskLevels/RiskLevels';
import RiskPersons from './pages/admin/taxonomies/riskPersons/RiskPersons';
import EditRiskPersons from './pages/admin/taxonomies/riskPersons/EditRiskPersons';
import RiskTypes from './pages/admin/taxonomies/riskTypes/RiskTypes';
import EditRiskTypes from './pages/admin/taxonomies/riskTypes/EditRiskTypes';
import TransactionRisks from './pages/lucrative/transaction-risks/TransactionRisks';
import BalanceInformationList from './pages/admin/BalanceInformationList';
import CrossingHistory from './pages/lucrative/CrossingHistory';
import DeclarationsList from './pages/admin/declaration/DeclarationList';
import EditDeclaration from './pages/admin/declaration/EditDeclaration';
import EditTransactionDeclaration from './pages/admin/declaration/EditTransactiondeclaration';
import MeasuringUnits from './components/admin/taxonomies/measuringUnits/MeasuringUnits';
import EditMeasuringUnits from './components/admin/taxonomies/measuringUnits/EditMeasuringUnits';
import PaymentNotice from './pages/payments/payment-notice/PaymentNotice';
import EditCustomsPayments from './pages/admin/taxonomies/customsPayments/EditCustomsPayments';
import CustomsPayments from './pages/admin/taxonomies/customsPayments/CustomsPayments';
import PaymentsNotices from './pages/payments/payment-notice/PaymentsNotices';
import TrainTypes from './pages/admin/taxonomies/trainTypes/TrainTypes';
import EditTrainTypes from './pages/admin/taxonomies/trainTypes/EditTrainTypes';
import TrezorarieDocumentList from './pages/admin/TrezorarieDocumentList';
import Vicoses from './components/admin/vicos/Vicoses';
import FuelTypes from './pages/admin/taxonomies/fuelTypes/FuelTypes';
import EditFuelTypes from './pages/admin/taxonomies/fuelTypes/EditFuelTypes';
import FinalDestinations from './pages/admin/taxonomies/finalDestinations/FinalDestinations';
import EditFinalDestinations from './pages/admin/taxonomies/finalDestinations/EditFinalDestinations';
import VehicleCategoryTravelers from './pages/admin/taxonomies/vehicleCategoryTravelers/VehicleCategoryTravelers';
import EditVehicleCategoryTraveler from './pages/admin/taxonomies/vehicleCategoryTravelers/EditVehicleCategoryTravelers';
import VehicleBrands from './pages/admin/taxonomies/vehicleBrands/VehicleBrands';
import EditVehicleBrand from './pages/admin/taxonomies/vehicleBrands/EditVehicleBrand';
import Chats from './components/Chat/pages/admin/Chats';
import Chat from './components/Chat/pages/admin/Chat';
import SpecialServiceRequests from './pages/lucrative/special-services/SpecialServiceRequests';
import SpecialServiceRequest from './pages/lucrative/special-services/SpecialServiceRequest';

const App = () => (
  <>
    <AuthContextWrapper>
      <BrowserRouter>
        <Default>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route
              exact
              path="/login-result/:sessionIndex"
              component={EmptyMpass(Login)}
            />
            <Route path="/QRCodeServices/" component={QRCodeServices} />
            <PrivateRoute exact path="/home" component={Home2} />
            <PrivateRoute
              exact
              path="/lucrative/risk-management"
              component={RisksView}
            />
            <PrivateRoute exact path="/admin/vicos" component={Vicoses} />
            <PrivateRoute
              exact
              path="/lucrative/risk-actions"
              component={RisksActions}
            />
            <PrivateRoute
              exact
              path="/lucrative/risk-analysis/:key"
              component={RiskAnalysis}
            />
            <PrivateRoute
              exact
              path="/lucrative/risks-analisys/physical-persons/:id"
              component={PhysicalPersonsRisk}
            />
            <PrivateRoute
              exact
              path="/lucrative/risks-analisys/physical-persons/:copyId/:id"
              component={PhysicalPersonsRisk}
            />
            <PrivateRoute
              exact
              path="/lucrative/risks-analisys/legal-persons/:id"
              component={LegalPersonsRisk}
            />
            <PrivateRoute
              exact
              path="/lucrative/risks-analisys/legal-persons/:copyId/:id"
              component={LegalPersonsRisk}
            />
            <PrivateRoute
              exact
              path="/lucrative/risks-analisys/transport-units/:id"
              component={TransportUnitsRisk}
            />
            <PrivateRoute
              exact
              path="/lucrative/risks-analisys/transport-units/:copyId/:id"
              component={TransportUnitsRisk}
            />
            <PrivateRoute
              exact
              path="/lucrative/transaction-risks"
              component={TransactionRisks}
            />
            <PrivateRoute exact path="/lucrative/mdua" component={PortalInfo} />
            <PrivateRoute
              exact
              path="/lucrative/crossing-history"
              component={CrossingHistory}
            />
            <PrivateRoute exact path="/payments" component={PaymentsNotices} />
            <PrivateRoute
              exact
              path="/payments/payments-notice/:id"
              component={PaymentNotice}
            />
            <PrivateRoute
              exact
              path="/payments/payments-notice/:id/:serviceRequestId"
              component={PaymentNotice}
            />
            <PrivateRoute exact path="/logout" component={Logout} />
            <PrivateRoute exact path="/work-history" component={WorkHistory} />
            <PrivateRoute exact path="/shift-report" component={ShiftReport} />
            <PrivateRoute exact path="/warehouse" component={Transshipments} />
            <PrivateRoute
              exact
              path="/warehouse/:id"
              component={EditTransshipment}
            />
            <PrivateRoute
              exact
              path="/correct-days"
              component={DaysCorrections}
            />
            <PrivateRoute
              exact
              path="/correct-days/:id"
              component={EditDaysCorrections}
            />
            <PrivateRoute exact path="/wsm" component={WebServiceManagement} />
            <PrivateRoute
              exact
              path="/interconnections/sfs-rsud-debts"
              component={SFSOrganizatiiDebts}
            />
            <PrivateRoute
              exact
              path="/interconnections/sfs-debts"
              component={SFSDebts}
            />
            <PrivateRoute
              exact
              path="/interconnections/sfs-rsud"
              component={SFSOrganizatii}
            />
            <PrivateRoute
              exact
              path="/interconnections/sfs-rsp"
              component={SFSPersoane}
            />
            <PrivateRoute
              exact
              path="/interconnections/sfs-irresponsibles"
              component={SFSIresponsabili}
            />
            <PrivateRoute
              exact
              path="/interconnections/anta-transport-authorization"
              component={ANTATransportAuthorization}
            />
            <PrivateRoute
              exact
              path="/declaration"
              component={DeclarationsList}
            />
            <PrivateRoute
              exact
              path="/declaration/:id"
              component={EditDeclaration}
            />
            <PrivateRoute
              exact
              path="/physical-person-entry/:transactionId/declaration/:id"
              component={EditTransactionDeclaration}
            />
            <PrivateRoute
              exact
              path="/web-services-scheduler"
              component={Scheduler}
            />
            <PrivateRoute exact path="/admin/users" component={Users} />
            <PrivateRoute exact path="/admin/users/:key" component={Users} />
            <PrivateRoute
              exact
              path="/admin/users/roles/:id"
              component={EditRole}
            />
            <PrivateRoute
              exact
              path="/admin/users/users/:id"
              component={EditUser}
            />
            <PrivateRoute
              exact
              path="/admin/user/password"
              component={EditUserPassword}
            />
            <PrivateRoute
              exact
              path="/admin/users/permissions/:id"
              component={ViewPermission}
            />
            <PrivateRoute
              exact
              path="/admin/customs-posts"
              component={Customs}
            />
            <PrivateRoute
              exact
              path="/admin/customs-posts/:key"
              component={Customs}
            />
            <PrivateRoute
              exact
              path="/admin/customs-posts/customs/:id"
              component={EditCustomsPost}
            />
            <PrivateRoute
              exact
              path="/admin/customs-office/:id"
              component={EditCustomsOffice}
            />
            <PrivateRoute
              exact
              path="/admin/customs-posts/tracks/:id"
              component={EditCustomsTrack}
            />
            <PrivateRoute
              exact
              path="/admin/chat-management"
              component={Chats}
            />
            <PrivateRoute
              exact
              path="/admin/chat-management/:id"
              component={Chat}
            />
            <PrivateRoute
              exact
              path="/lucrative/documents"
              component={TrezorarieDocumentList}
            />
            <PrivateRoute
              exact
              path="/lucrative/special-services-requests"
              component={SpecialServiceRequests}
            />
            <PrivateRoute
              exact
              path="/lucrative/special-services-requests/:id"
              component={SpecialServiceRequest}
            />
            <PrivateRoute
              exact
              path="/lucrative/documents"
              component={TrezorarieDocumentList}
            />
            <PrivateRoute
              exact
              path="/lucrative/balance"
              component={BalanceInformationList}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies"
              component={Taxonomies}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/countries"
              component={Countries}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/countries/:id"
              component={EditCountry}
            />

            <PrivateRoute
              exact
              path="/admin/taxonomies/closing-document-declaration"
              component={ClosingDocumentDeclaration}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/closing-document-declaration/:id"
              component={EditClosingDocumentDeclaration}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/return-reasons"
              component={ReturnReasons}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/return-reason/:id"
              component={EditReturnReason}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/risk-applicants"
              component={RiskApplicants}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/risk-applicants/:id"
              component={EditRiskApplicants}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/risk-levels"
              component={RiskLevels}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/risk-levels/:id"
              component={EditRiskLevels}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/risk-persons"
              component={RiskPersons}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/risk-persons/:id"
              component={EditRiskPersons}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/risk-types"
              component={RiskTypes}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/risk-types/:id"
              component={EditRiskTypes}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/customs-payments"
              component={CustomsPayments}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/customs-payments/:id"
              component={EditCustomsPayments}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/withholding-reasons"
              component={WithholdingReasons}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/withholding-reasons/:id"
              component={EditWithholdingReasons}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/constants"
              component={Constants}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/constants/:id"
              component={EditConstants}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/authorization-types"
              component={AuthorizationTypes}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/authorization-types/:id"
              component={EditAuthorizationType}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/document-statuses"
              component={DocumentStatuses}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/document-statuses/:id"
              component={EditDocumentStatus}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/document-types"
              component={DocumentTypes}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/document-types/:id"
              component={EditDocumentType}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/document-type-payment"
              component={DocumentTypePayment}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/document-type-payment/:id"
              component={EditDocumentTypePayment}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/legal-forms"
              component={LegalForms}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/legal-forms/:id"
              component={EditLegalForm}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/licensed-activities"
              component={LicensedActivities}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/licensed-activities/:id"
              component={EditLicensedActivity}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/localities"
              component={Localities}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/localities/:id"
              component={EditLocality}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/regions"
              component={Regions}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/regions/:id"
              component={EditRegion}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/registration-types"
              component={RegistrationTypes}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/registration-types/:id"
              component={EditRegistrationType}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/traffic-countries"
              component={TrafficCountries}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/traffic-countries/:id"
              component={EditTrafficCountry}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/customs-regime"
              component={CustomsRegime}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/customs-regime/:id"
              component={EditCustomsRegime}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/unlicensed-activities"
              component={UnlicensedActivities}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/unlicensed-activities/:id"
              component={EditUnlicensedActivity}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/services-description-anta"
              component={ServicesDescriptionANTA}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/services-description-anta/:id"
              component={EditServiceDescriptionANTA}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/vignette-error-code"
              component={VignetteErrorCode}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/vignette-error-code/:id"
              component={EditVignetteErrorCode}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/vignette-tariff-position"
              component={VignetteTariffPosition}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/vignette-tariff-position/:id"
              component={EditVignetteTariffPosition}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/impound-car-reasons"
              component={ImpoundCarReason}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/impound-car-reasons/:id"
              component={EditImpoundCarReason}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/services-description-ansa"
              component={ServicesDescriptionANSA}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/services-description-ansa/:id"
              component={EditServiceDescriptionANSA}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/vehicle-category"
              component={VehicleCategories}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/vehicle-category/:id"
              component={EditVehicleCategory}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/vehicle-types"
              component={VehicleTypes}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/vehicle-types/:id"
              component={EditVehicleType}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/control-location"
              component={ControlLocations}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/control-location/:id"
              component={EditControlLocation}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/control-reason"
              component={ControlReasons}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/control-reason/:id"
              component={EditControlReason}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/customs-documents-type"
              component={CustomsDocumentsTypes}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/customs-documents-type/:id"
              component={EditCustomsDocumentsType}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/measuring-units"
              component={MeasuringUnits}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/measuring-units/:id"
              component={EditMeasuringUnits}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/mapping-svm-post-to-weighing-post"
              component={MappingSvmPostToWeighingPosts}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/mapping-svm-post-to-weighing-post/:id"
              component={EditMappingSvmPostToWeighingPost}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/mapping-svm-post-to-sg-post"
              component={MappingSvmPostToSgPosts}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/mapping-svm-post-to-sg-post/:id"
              component={EditMappingSvmPostToSgPost}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/mapping-svm-post-to-ro-post"
              component={MappingSvmPostToRoPosts}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/mapping-svm-post-to-ro-post/:id"
              component={EditMappingSvmPostToRoPost}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/endowment"
              component={Endowment}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/endowment/:id"
              component={EditEndowment}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/train-types"
              component={TrainTypes}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/train-types/:id"
              component={EditTrainTypes}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/fuel-types"
              component={FuelTypes}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/vehicle-brand/:id"
              component={EditVehicleBrand}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/vehicle-brand"
              component={VehicleBrands}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/fuel-types/:id"
              component={EditFuelTypes}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/final-destinations"
              component={FinalDestinations}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/final-destinations/:id"
              component={EditFinalDestinations}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/vehicle-category-declarations"
              component={VehicleCategoryTravelers}
            />
            <PrivateRoute
              exact
              path="/admin/taxonomies/vehicle-category-declarations/:id"
              component={EditVehicleCategoryTraveler}
            />
            <PrivateRoute
              exact
              path="/physical-person-entry/:id"
              component={EditPersonPhysicalEntry}
            />
            <PrivateRoute
              exact
              path="/physical-person-entry/weight/:id"
              component={EditPersonPhysicalEntryWeight}
            />
            <PrivateRoute
              exact
              path="/physical-person-entry/anta/:id"
              component={EditPersonPhysicalEntryAnta}
            />
            <PrivateRoute
              exact
              path="/physical-person-entry/ansa/:id"
              component={EditPersonPhysicalEntryAnsa}
            />
            <PrivateRoute
              exact
              path="/physical-person-entry/warehouse/:id"
              component={EditPersonPhysicalEntryWarehouse}
            />
            <PrivateRoute
              exact
              path="/physical-person-entry/scan/:id"
              component={EditPersonPhysicalEntryScan}
            />
            <PrivateRoute
              exact
              path="/physical-person-entry/mdua/:id"
              component={EditPersonPhysicalEntryMDUA}
            />
            <PrivateRoute
              exact
              path="/admin/customs-posts/tracks"
              component={CustomsTracks}
            />
            <PrivateRoute
              exact
              path="/admin/reports"
              component={JasperReports}
            />
            <PrivateRoute
              exact
              path="/import-transactions"
              component={ImportTransactions}
            />
            <PrivateRoute exact path="/admin/guides" component={Guides} />
            <PrivateRoute exact path="/admin/audit" component={AuditData} />
            <Route component={NotFound} />
          </Switch>
        </Default>
      </BrowserRouter>
    </AuthContextWrapper>
    {window._env_.VERSION && (
      <div
        style={{
          position: 'fixed',
          bottom: '0',
          right: '0',
          opacity: '0.2',
          padding: '0 12px',
          margin: '4px',
          background: 'black',
          color: 'white',
          fontSize: '10px',
          zIndex: '65535',
        }}
      >
        {new Date(
          (parseInt(window._env_.VERSION, 10) || 0) * 1000,
        ).toISOString()}
      </div>
    )}
  </>
);

export default App;
